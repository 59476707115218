import React from "react";
import { message, Form, Icon, Input, Button, Divider } from "antd";
import "@css/login.scss";
import cookie from "react-cookies";
import { login, switchApp } from "@service/getData";
import PropTypes from "prop-types";
import Captcha from "../common/Captcha";

class LoginForm extends React.Component {
    constructor() {
        super();
        LoginForm.contextTypes = {
            router: PropTypes.object.isRequired
        };
        this.state = {
            real_capcha: null,
            input_capcha: null
        }

    }

    async login(user_name, password) {
        // 登陆之前清理掉之前掉所有cookie
        if (document.cookie) {
            const newCookieStr = document.cookie.replace(/\s/g, '');
            const cookieObj = {};
            const cookieArr = newCookieStr.split(';');
            cookieArr.forEach((item) => {
                const itemArr = item.split('=');
                cookieObj[itemArr[0]] = itemArr[1];
            });
            Object.keys(cookieObj).forEach((item) => {
                console.log(item);
                cookie.remove(item);
            });
        }
        let res = await login(user_name, password);
        if (res.success) {
            let current_app_id = res.result.admin_user.current_app_id,
                admin_user_id = res.result.admin_user.admin_user_id,
                is_admin = +res.result.admin_user.is_admin,
                mini_app_list = [...res.result.admin_user.mini_app_list];
            if (mini_app_list.length && !current_app_id) {
                this.switchApp(mini_app_list, admin_user_id);
                current_app_id = current_app_id ? current_app_id : mini_app_list[0].mini_app_id;
            }
            if (current_app_id && mini_app_list.length) {
                let currentApp = mini_app_list.find(item => +item.mini_app_id === +current_app_id);
                cookie.save("mini_app_type", currentApp.mini_app_type);
                cookie.save("app_apply_id", currentApp.app_apply_id);
                cookie.save("default_community_id", currentApp.default_community_id);
            }
            cookie.save("is_admin", is_admin);
            cookie.save("admin_user_id", admin_user_id);
            cookie.save("mini_app_id", current_app_id);
            cookie.save("shop_id", res.result.admin_user.current_shop_id);
            cookie.save("user_name", user_name);
            cookie.save("mini_app_length", res.result.admin_user.mini_app_list.length || res.result.admin_user.app_apply_num);
            cookie.save("mch_apply_num", res.result.admin_user.mch_apply_num)
            if (is_admin === 1) {
                cookie.save("defaultSelectedKeys", "0")
                this.context.router.history.push("/home");
                return
            }
            if (+res.result.admin_user.app_apply_num === 0 && +res.result.admin_user.mini_app_list.length === 0) {
                cookie.save("defaultSelectedKeys", "appletManage")
                // this.context.router.history.push("/appletManage");
                this.context.router.history.push("/home");

            } else if (+res.result.admin_user.mch_apply_num === 0) {
                cookie.save("defaultSelectedKeys", "merchantManage")
                // this.context.router.history.push("/merchantManage");
                this.context.router.history.push("/home");


            } else {
                cookie.save("defaultSelectedKeys", "0")
                this.context.router.history.push("/home");
            }

        } else {
            message.error(res.msg);
        }
    }

    async switchApp(mini_app_list, admin_user_id) {
        let mini_app_id = mini_app_list[0].mini_app_id;
        await switchApp({
            admin_user_id,
            mini_app_id
        });
    }

    handleSubmit = e => {
        e.preventDefault();
        const { input_capcha } = this.state
        if (!input_capcha) {
            message.error('请输入验证码');
            return;
        }
        if (this.state.real_capcha.toLowerCase() !== this.state.input_capcha.toLowerCase()) {
            this.setState(preState => ({
                status: preState.status ? 0 : 1
            }))
            return message.error("验证码输入错误")
        }
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.login(values.user_name, values.password);
            }
        });
    };

    componentDidMount() {
        cookie.remove();
    }

    changeChapcha = (real_capcha, input_capcha) => {
        this.setState({
            real_capcha: real_capcha,
            input_capcha: input_capcha

        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form className="login-form">
                <Form.Item>
                    {getFieldDecorator("user_name", {
                        rules: [{ required: true, message: "请输入用户名!" }]
                    })(
                        <Input
                            prefix={
                                <>
                                    <Icon type="user" style={{ color: "#969799", fontSize: 20, marginRight: 5 }} />
                                    <Divider type="vertical" style={{ marginRight: 10 }} />
                                </>
                            }
                            placeholder="用户名"
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator("password", {
                        rules: [{ required: true, message: "请输入密码 !" }]
                    })(
                        <Input
                            prefix={
                                <>
                                    <Icon type="lock" style={{ color: "#969799", fontSize: 20, marginRight: 5 }} />
                                    <Divider type="vertical" style={{ marginRight: 10 }} />
                                </>
                            }
                            type="password"
                            placeholder="密码"
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    <Captcha change={this.changeChapcha} confirm={this.handleSubmit} status={this.state.status} />
                </Form.Item>
                <Form.Item>
                    <Button
                        onClick={this.handleSubmit}
                        type="primary"
                        className="login-form-button"
                    >
                        马上登录
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

const WrappedLoginForm = Form.create({ name: "normal_login" })(LoginForm);
export default WrappedLoginForm;
