import React from 'react'
import { Button, Table, Modal, Form, Input, Avatar, Upload, message, Icon, Popconfirm, Tooltip } from 'antd'
import UploadButton from "@/view/component/common/UploadButton";
import UploadText from "@/view/component/common/UploadText";
import '@css/groupGoodsManage.scss'
import { getCatList, createOrUpdateCat, exchangeCatSortNum, topCat } from '@service/getData'
import cookie from 'react-cookies'
import config from "@config/config";
import PropTypes from "prop-types";
class GroupGoodsManage extends React.Component {
    constructor() {
        GroupGoodsManage.contextTypes = {
            router: PropTypes.object.isRequired
        };
        super()
        this.state = {
            cat_id: null,
            cat_name: null,
            columns: [
                {
                    title: "序号",
                    dataIndex: "index",
                    render: (text, record, index) => {
                        return index + 1
                    }
                }, {
                    title: "分类名称",
                    dataIndex: "cat_name",
                    render: (text, record) => {
                        return (
                            <div>
                                {
                                    text
                                }
                                {record.cat_name !== '推荐' &&
                                    <Popconfirm
                                        title={
                                            <Input
                                                style={{ "width": "84px", "borderRadius": "100px" }}
                                                value={this.state.cat_name}
                                                onChange={this.changeCatName}
                                            />
                                        }
                                        onConfirm={this.createOrUpdateCat.bind(this, record.cat_id, 1, null, this.state.cat_name)}
                                        onCancel={this.cancel}
                                        okText="确认"
                                        cancelText="取消"
                                        icon={null}>
                                        <i className="iconfont  text-note icon-bianji" ></i>
                                    </Popconfirm>
                                }
                            </div>
                        )


                    }
                }, {
                    title: "分类图片",
                    dataIndex: "cat_image",
                    render: (text, record) => (
                        <div>
                            <Avatar
                                shape={"square"}
                                size={"large"}
                                src={text} />
                            <UploadText
                                createOrUpdateCat={this.createOrUpdateCat}
                                catId={record.cat_id} />
                        </div>
                    )
                }, {
                    title: "包含商品数量",
                    dataIndex: "goods_num"
                }, {
                    title: "包含商品",
                    dataIndex: "goods",
                    render: (text, record) => <div className="text-note" onClick={this.manageGoods.bind(this, record.cat_id)}>管理商品</div>
                }, {
                    title: '顺序',
                    dataIndex: 'sort',
                    render: (text, record, index) => {
                        if (record.cat_name === '推荐') {
                            return null;
                        }
                        return (
                            <div>
                                {index !== 0 && index !== 1 ? (
                                    <Icon
                                        className="text-note mr15"
                                        onClick={this.exchangeCatSortNum.bind(this, index, -1, null)}
                                        type="arrow-up"
                                    />) : null}
                                {index !== this.state.cat_list.length - 1 ? (
                                    <Icon
                                        onClick={this.exchangeCatSortNum.bind(this, index, 1, null)}
                                        className="text-note"
                                        type="arrow-down"
                                    />) : null}
                                {index !== 0 ? ((<p
                                    className="text-note"
                                    onClick={this.topCat.bind(this, record.cat_id)}
                                    type="arrow-up"
                                >
                                    置顶
                                </p>)) : null}
                            </div>
                        )
                    }
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    render: (text, record) => {
                        if (record.cat_name === '推荐') {
                            return (
                                <p style={{ margin: 0 }}>
                                    <Popconfirm
                                        title={this.state.recShow ? '确定不展示推荐吗？' : '确定展示推荐吗？'}
                                        onConfirm={this.toggleRec.bind(this, record)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <i style={{ color: this.state.recShow ? '#1b90ff' : '#797A7E' }} className={`iconfont ${this.state.recShow ? 'icon-yanjing' : 'icon-biyan'}`}></i>
                                    </Popconfirm>
                                </p>
                            )
                        }
                        return (
                            <p>
                                <Popconfirm
                                    title="确定要删除此分类吗?"
                                    onConfirm={this.createOrUpdateCat.bind(this, record.cat_id, 0, null, null)}
                                    okText="确定"
                                    cancelText="取消"
                                >
                                    <span className="text-note">删除</span>
                                </Popconfirm>
                                <span className="text-note" onClick={this.showModal.bind(this, null, "2", record.cat_id)}>+添加二级分类</span>
                            </p>
                        )
                    }
                }
            ],
            visible: false,
            cat_list: [],
            file_list: [],
            request_url: config.baseUrl + "/ossFile/upload",
            preview_gallery_image: null,
            preview_gallery_visible: false,
            cat: null,
            second_cat_list: [],
            level: null,
            parent_id: null,
            recShow: true,
        }
    }
    getCatList = async () => {
        let data = {
            mini_app_id: cookie.load("mini_app_id")
        }
        let res = await getCatList(data);
        if (res.success) {
            this.props.form.resetFields();
            this.setState({
                cat_list: res.result.cat_list,
                cat: null,
                file_list: [],
                recShow: res.result.cat_list[0].is_visible
            })
        }
    }
    createOrUpdateCat = async (cat_id, status, cat_image = null, cat_name) => {
        let data = {
            cat_id: cat_id,
            status: status,
            cat_image,
            cat_name,
            parent_id: this.state.level === "2" ? this.state.parent_id : 0,
            level: this.state.level,
            mini_app_id: cookie.load("mini_app_id")
        }
        let res = await createOrUpdateCat(data);
        if (res.success) {
            message.success("操作成功")
            this.setState({
                visible: false,
                cat_name: null,
                cat_id: null
            }, () => { this.getCatList() })
        }
    }
    changeCatName = (e) => {
        this.setState({
            cat_name: e.target.value
        })

    }
    handleGalleryChange = ({ fileList }) => {
        this.setState({ file_list: fileList })
    }
    handleGalleryPreview = (file) => {
        this.setState({
            preview_gallery_image: file.url || file.thumbUrl,
            preview_gallery_visible: true,
        });
    }
    showModal = (cat, level, parent_id) => {
        this.props.form.resetFields();

        let file_list = [];
        if (cat !== null) {
            if (cat.cat_image !== null && cat.cat_image !== '') {
                file_list = [
                    {
                        uid: '-1',
                        name: 'xxx.png',
                        status: 'done',
                        url: cat.cat_image
                    },
                ];
            }
        }
        this.setState({
            cat: cat,
            file_list: file_list,
            level,
            parent_id
        }, () => {
            this.setState({
                visible: true
            })
        })
    }
    handleOK = () => {
        let form = this.props.form.getFieldsValue();
        let flag = false
        this.props.form.validateFields(err => {
            if (err) {
                flag = true
            }
        });
        if (flag) return
        let image = ''
        this.state.file_list.forEach(item => {
            if (item.response && item.response.success) {
                image = item.response.result.oss_file_url
            } else {
                image = item.url
            }
        })
        if (form.level === "2" && !image) return message.error('请选择图片')
        this.createOrUpdateCat(this.state.cat ? this.state.cat.cat_id : null, 1, image, form.cat_name || form.second_cat_name)
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            cat: null,
            file_list: []
        })
    }
    expandedRowRender = (item) => {
        const parent_index = this.state.cat_list.findIndex(cat => cat.cat_id === item.cat_id)
        const second_cat_list = this.state.cat_list[parent_index].cat_list
        if (!second_cat_list.length) {
            return null;
        }
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                render: (text, record, index) => {
                    return `${parent_index + 1}.${index + 1}`
                }
            },
            {
                title: "分组名称",
                dataIndex: "cat_name",
                render: (text, record) => {
                    return (
                        <div>
                            {
                                text
                            }
                            <Popconfirm
                                title={
                                    <Input
                                        style={{ "width": "84px", "borderRadius": "100px" }}
                                        value={this.state.cat_name}
                                        onChange={this.changeCatName}
                                    />
                                }
                                onConfirm={this.createOrUpdateCat.bind(this, record.cat_id, 1, null, this.state.cat_name)}
                                onCancel={this.cancel}
                                okText="确认"
                                cancelText="取消"
                                icon={null}>
                                <i className="iconfont  text-note icon-bianji" ></i>
                            </Popconfirm>
                        </div>
                    )


                }
            },
            {
                title: "分类图片",
                dataIndex: "cat_image",
                render: (text, record) => (
                    <div>
                        <Avatar
                            shape={"square"}
                            size={"large"}
                            src={text} />
                        <UploadText
                            createOrUpdateCat={this.createOrUpdateCat}
                            catId={record.cat_id} />
                    </div>
                )
            }
            ,
            {
                title: "包含商品数量",
                dataIndex: "goods_num"
            }, {
                title: "包含商品",
                dataIndex: "goods",
                render: (text, record) => <div className="text-note" onClick={this.manageGoods.bind(this, record.cat_id)}>管理商品</div>
            },
            {
                title: '顺序',
                dataIndex: 'sort',
                render: (text, record, index) =>
                    (
                        <div>
                            {index !== 0 ? (
                                <Icon
                                    className="text-note mr15"
                                    onClick={this.exchangeCatSortNum.bind(this, index, -1, record)}
                                    type="arrow-up"
                                />) : null}
                            {index !== second_cat_list.length - 1 ? (
                                <Icon
                                    onClick={this.exchangeCatSortNum.bind(this, index, 1, record)}
                                    className="text-note"
                                    type="arrow-down"
                                />) : null}
                            {index !== 0 ? ((<p
                                className="text-note"
                                onClick={this.topCat.bind(this, record.cat_id)}
                                type="arrow-up"
                            >
                                置顶
                            </p>)) : null}
                        </div>
                    )
            },
            {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => (
                    <p>
                        <Popconfirm
                            title="确定要删除此分类吗?"
                            onConfirm={this.createOrUpdateCat.bind(this, record.cat_id, 0, null, null)}
                            okText="确定"
                            cancelText="取消"
                        >
                            <span className="text-note">删除</span>
                        </Popconfirm>
                    </p>
                )
            }
        ]


        return <Table
            className="components-table-demo-nested"
            rowKey={(record, index) => index}
            columns={columns}
            dataSource={second_cat_list}
            pagination={false} />;
    }
    exchangeCatSortNum = async (index, isUp, record) => {
        let list = []
        if (record !== null) {
            list = this.state.cat_list.find(cat => cat.cat_id === record.parent_id).cat_list
        } else {
            list = this.state.cat_list

        }
        let from_cat_id = list[index].cat_id, to_cat_id;

        if (isUp < 0) {
            to_cat_id = list[index - 1].cat_id;
        } else {
            to_cat_id = list[index + 1].cat_id;
        }
        let res = await exchangeCatSortNum({
            from_cat_id,
            to_cat_id
        });
        if (res.success) {
            message.success("操作成功");
            this.getCatList()
        }
    }
    topCat = async (cat_id) => {
        let data = {
            cat_id: cat_id
        }
        let res = await topCat(data)
        if (res.success) {
            message.success("操作成功");
            this.getCatList()
        }
    }
    handleGalleryCancel = () => {
        this.setState({
            preview_gallery_visible: false
        })
    }
    manageGoods = (cat_id) => {
        this.context.router.history.push("/goodsManage", cat_id);
    }
    componentDidMount() {
        this.getCatList()
    }
    toggleRec(record) {
        let data = {
            cat_id: record.cat_id,
            is_visible: this.state.recShow ? 0 : 1,
        }
        let res = createOrUpdateCat(data);
        if (res.success) {
            message.success('操作成功');
        }
        this.setState((prevState) => ({ recShow: !prevState.recShow }));
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="group-goods-manage">
                <div className="content">
                    <div className="action">
                        <Button type="primary" shape="round" onClick={this.showModal.bind(this, null, '1', null)}>新建一级分类</Button>
                        <Tooltip title={
                            <img alt=""
                                width={256}
                                src="http://udh.oss-cn-hangzhou.aliyuncs.com/bc73abea-9ee7-4345-a628-7ada847e6385" />}>
                            <span className="text-note">示例图片</span>
                        </Tooltip>

                    </div>
                    <Table rowKey={(record, index) => index}
                        columns={this.state.columns}
                        dataSource={this.state.cat_list}
                        pagination={false}
                        rowClassName={record => record.cat_list && record.cat_list.length > 0 ? "expand" : 'no-expand'}
                        expandedRowRender={this.expandedRowRender}
                    />
                </div>
                <Modal
                    className="cat-add-modal"
                    visible={this.state.visible}
                    title={this.state.cat === null ? "新建分类" : "编辑分类"}
                    onOk={this.handleOK}
                    onCancel={this.handleCancel}
                    okButtonProps={{ shape: 'round' }}
                    cancelButtonProps={{ shape: 'round' }}
                >
                    <Form>
                        {
                            this.state.level === "1" ? (
                                <Form.Item label="一级分类名称">
                                    {getFieldDecorator('cat_name', {
                                        initialValue: this.state.cat ? this.state.cat.cat_name : null,
                                        rules: [{
                                            required: true,
                                            message: "请填写一级分类名称!"
                                        }]

                                    })(
                                        <Input className="cat-input" />

                                    )}
                                </Form.Item>
                            ) : (
                                    <Form.Item label="二级分类名称">
                                        {getFieldDecorator('second_cat_name', {
                                            initialValue: this.state.cat ? this.state.cat.cat_name : null,
                                            rules: [{
                                                required: true,
                                                message: "请填写二级分类名称!"
                                            }]

                                        })(
                                            <Input className="cat-input" />
                                        )}

                                    </Form.Item>
                                )
                        }

                        <Form.Item label={(this.state.level === "2" ? "二级" : "一级") + "分类图片"}
                            extra={
                                <div className="text-remind">
                                    建议尺寸
                                       {
                                        this.state.level === "2" ? "1:1" : "258px*91px"
                                    }
                                     ，仅支持jpg与png两种格式，图片大小不超过3.0MB
                                   </div>}>
                            {getFieldDecorator('pic', {
                                initialValue: this.state.cat ? this.state.cat.cat_image : null,
                                rules: [{
                                    required: this.state.level === "2" ? true : false,
                                    message: "请选择图片!"
                                }]

                            })(
                                <div className="iamge-upload">
                                    <Upload
                                        accept="image/jpeg,image/jpg,image/png"
                                        withCredentials={true}
                                        action={this.state.request_url}
                                        listType="picture-card"
                                        fileList={this.state.file_list}
                                        onPreview={this.handleGalleryPreview}
                                        onChange={this.handleGalleryChange}
                                    >

                                        {this.state.file_list.length >= 1 ? null : <UploadButton />}
                                    </Upload>
                                    <Modal visible={this.state.preview_gallery_visible} footer={null}
                                        onCancel={this.handleGalleryCancel}>
                                        <img alt="example" style={{ width: '100%' }}
                                            src={this.state.preview_gallery_image} />
                                    </Modal>
                                </div>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}

const groupGoodsManageForm = Form.create({ name: 'group_goods_form' })(GroupGoodsManage);

export default groupGoodsManageForm;
