import React from "react";
import { Modal, message } from "antd";
import "@css/iconchoose.scss";
class IconChoose extends React.Component {
  constructor() {
    super();
    this.state = {
      style_icon_list: [
        {
          title: "风格1：",
          icon_list: [
            {
              className: "icon-iconfontshouye-",
              unicode: "&#xe735;"
            },
            {
              className: "icon-Batchfolding",
              unicode: "&#xe712;"
            },
            {
              className: "icon-gouwuche3",
              unicode: "&#xe733;"
            },
            {
              className: "icon-my_s",
              unicode: "&#xe73e;"
            }
          ]
        },
        {
          title: "风格2：",
          icon_list: [
            {
              className: "icon-shouye13",
              unicode: "&#xe73b;"
            },
            {
              className: "icon-fenlei-copy",
              unicode: "&#xe72f;"
            },
            {
              className: "icon-gouwuche2",
              unicode: "&#xe731;"
            },
            {
              className: "icon-wo1",
              unicode: "&#xe742;"
            }
          ]
        },
        {
          title: "风格3：",
          icon_list: [
            {
              className: "icon-shouye12",
              unicode: "&#xe73d;"
            },
            {
              className: "icon-fenlei3",
              unicode: "&#xe730;"
            },
            {
              className: "icon-gouwuche",
              unicode: "&#xe6a5;"
            },
            {
              className: "icon-wo2",
              unicode: "&#xe741;"
            }
          ]
        },
        {
          title: "风格4：",
          icon_list: [
            {
              className: "icon-shouye",
              unicode: "&#xe6db;"
            },
            {
              className: "icon-fenlei2",
              unicode: "&#xe72d;"
            },
            {
              className: "icon-cshy-cart-fill",
              unicode: "&#xe727;"
            },
            {
              className: "icon-wode",
              unicode: "&#xe6e9;"
            }
          ]
        },
        {
          title: "风格5：",
          icon_list: [
            {
              className: "icon-shouye1",
              unicode: "&#xe6d8;"
            },
            {
              className: "icon-fenlei",
              unicode: "&#xe6a2;"
            },
            {
              className: "icon-gouwucheman",
              unicode: "&#xe6ab;"
            },
            {
              className: "icon-wowodetouxiang",
              unicode: "&#xe747;"
            }
          ]
        },
        {
          title: "风格6：",
          icon_list: [
            {
              className: "icon-shouye11",
              unicode: "&#xe73a;"
            },
            {
              className: "icon-fenlei1",
              unicode: "&#xe72e;"
            },
            {
              className: "icon-gouwuche1",
              unicode: "&#xe6ac;"
            },
            {
              className: "icon-wode2",
              unicode: "&#xe6ed;"
            }
          ]
        },
        {
          title: "风格7：",
          icon_list: [
            {
              className: "icon-shouyeweixuanzhong",
              unicode: "&#xe745;"
            },
            {
              className: "icon-fenleiweixuanzhong",
              unicode: "&#xe732;"
            },
            {
              className: "icon-gouwucheweixuanzhong",
              unicode: "&#xe737;"
            },
            {
              className: "icon-wodeweixuanzhong",
              unicode: "&#xe74a;"
            }
          ]
        }
      ],
      another_icon_list: [
        {
          className: "icon-shouye13",
          unicode: "&#xe73b;"
        },
        {
          className: "icon-quanbushangpin",
          unicode: "&#xe6dd;"
        },
        {
          className: "icon-zhaijinbangdan",
          unicode: "&#xe714;"
        },
        {
          className: "icon-gerenzhongxin",
          unicode: "&#xe6a6;"
        }
      ],
      active_icon: null,
      className: null
    };
  }
  onClick = (active_icon, className) => {
    this.setState({
      active_icon,
      className
    });
  };
  onOk = () => {
    if (!this.state.active_icon) {
      return message.error("请选择图标");
    }
    this.props.onOk(this.state.active_icon, this.state.className);
  };
  render() {
    return (
      <Modal
        className="icon-choose"
        visible={this.props.visible}
        title="选择图标"
        onOk={this.onOk}
        onCancel={this.props.onCancel}
        okButtonProps={{ shape: "round" }}
        cancelButtonProps={{ shape: "round" }}>
        <div className="content">
          {this.state.style_icon_list.map((item, index) => (
            <div className="icon-list" key={index}>
              <span className="icon-title">{item.title}</span>
              {item.icon_list.map((item, index) => (
                <div
                  className={`icon-content ${this.state.active_icon === item.unicode ? "icon-content-active" : null}`}
                  key={index}
                  onClick={this.onClick.bind(this, item.unicode, item.className)}>
                  <i className={`iconfont ${item.className}`} />
                  <div className="pop">64*64</div>
                  {this.state.active_icon === item.unicode ? (
                    <div className="active">
                      <i className="iconfont icon-duihao"></i>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="icon-list">
          <span className="icon-title">其他：</span>
          {this.state.another_icon_list.map((item, index) => (
            <div
              className={`icon-content ${this.state.active_icon === item.unicode ? "icon-content-active" : null}`}
              key={index}
              onClick={this.onClick.bind(this, item.unicode, item.className)}>
              <i className={`iconfont ${item.className}`} />
              <div className="pop">64*64</div>
              {this.state.active_icon === item.unicode ? (
                <div className="active">
                  <i className="iconfont icon-duihao"></i>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </Modal>
    );
  }
}

export default IconChoose;
