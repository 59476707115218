import React, { Fragment } from "react";
import { Divider, Input, DatePicker, Radio, Button, Icon, Upload, Modal, Checkbox, message, Tooltip } from "antd";
import NavBar from "@/view/component/common/NavBar.js";
import { Form } from "antd/lib/index";
import { connect } from "react-redux";
import "@css/fullReduction.scss";
import { mergeList, deleteItem, formatNumber, formatInt, getQueryString } from "@common/common";
import CouponSelectModal from "@/view/component/Campaign/CouponSelectModal";
import GoodsDragSort from "@/view/component/GoodsDragSort/index";
import GoodsChoose from "@/view/component/Goods/GoodsChoose";
import UploadButton from "@/view/component/common/UploadButton";
import CheckAddress from '@/view/component/common/CheckAddress'
import config from "@config/config";
import cookie from "react-cookies";
import PropTypes from "prop-types";
import moment from "moment";
import { createOrUpdateCoupon, queryCouponDetail, queryRegionDetail } from "@service/getData";
class FullReduction extends React.Component {
	constructor() {
		super();
		FullReduction.contextTypes = {
			router: PropTypes.object.isRequired
		};
		this.state = {
			coupon_res: {},
			coupon_select_visible: false,
			coupon_list: [],
			goods_select_visible: false,
			goods_list: [],
			goods_type: "all_goods",
			requestUrl: config.baseUrl + "/ossFile/upload",
			gallery_list: [],
			preview_gallery_image: null,
			preview_gallery_visible: false,
			discount_amount: null,
			pay_amount: null,
			goods_num: null,
			coupon_content: [],
			show_advance: false,
			coupon_id: null,
			// 默认选择整单包邮
			freeShippingType: 1,
			// 地址选择弹窗控制
			adressVisible: false,
			template: {
				area_list: [],
				province_list: [],
				city_list: [],
				district_list: [],
			},
			// 传给后端的地址选择数组
			freeAreaList: [],
			// 展示给用户的地址列表
			shipping_template_item_desc: [],
			// 所有地区
			area_res_list: [],
			// 打折
			discount_rate: null,
		};
	}

	componentDidMount() {
		let coupon_id = getQueryString("coupon_id");
		this.setState(
			{
				coupon_id
			},
			async () => {
				if (coupon_id !== null) {
					await this.queryRegionDetail();
					this.queryCouponDetail(coupon_id);
				}
			}
		);
	}

	async queryRegionDetail() {
		let res = await queryRegionDetail({});
		let areaData = res.result.area_res_list;
		areaData.forEach(item => {
			item.region_res_list.forEach(province => {
				province.area_id = item.area_id;
				province.region_res_list.forEach(city => {
					city.area_id = item.area_id;
					city.province_id = province.region_id;
					city.region_res_list.forEach(district => {
						district.area_id = item.area_id;
						district.province_id = province.region_id;
						district.city_id = city.region_id
					})
				})
			})
		})
		if (res.success) {
			this.setState({
				area_res_list: areaData,
			});
		}
	}

	handleGalleryPreview = file => {
		this.setState({
			preview_gallery_image: file.url || file.thumbUrl || file,
			preview_gallery_visible: true
		});
	};
	changeVisible = attr => {
		// 防止点击添加优惠券触发反选
		if (attr === 'coupon_select_visible') {
			this.setState((preState) => ({
				coupon_content: preState.coupon_content.indexOf('reward_coupon_flag') > -1 ? preState.coupon_content : [...preState.coupon_content, 'reward_coupon_flag']
			}));
		}
		this.setState(preState => ({
			[attr]: !preState[attr]
		}));
	};
	onCouponOk = list => {
		list = list.map(item => {
			let data = {
				...item
			};
			data.reward_coupon_id = item.coupon_id;
			return data;
		});
		let coupon_list = mergeList(list, this.state.coupon_list, "reward_coupon_id");
		this.setState({
			coupon_list,
			coupon_select_visible: false
		});
		// 防止点击添加优惠券反选
		this.setState((preState) => ({
			coupon_content: preState.coupon_content.indexOf('reward_coupon_flag') > -1 ? preState.coupon_content : [...preState.coupon_content, 'reward_coupon_flag']
		}));
	};
	onGoodsOk = list => {
		let goods_list = mergeList(list, this.state.goods_list, "goods_id");
		this.setState({
			goods_list,
			goods_select_visible: false
		});
	};
	deleteCoupon = coupon_id => {
		let coupon_list = deleteItem(this.state.coupon_list, coupon_id, "coupon_id", "reward_id");
		this.setState({
			coupon_list
		});
	};
	delGoods = index => {
		let goods_id = this.state.goods_list[index].goods_id;
		let goods_list = deleteItem(this.state.goods_list, goods_id, "goods_id", "coupon_goods_id");
		this.setState({
			goods_list
		});
	};
	changeGoodsType = e => {
		this.props.form.setFieldsValue({ goods_type: e.target.value });
		this.setState({
			goods_type: e.target.value
		});
	};
	changeTitleType = e => {
		this.props.form.setFieldsValue({ title_type: e.target.value });
	};
	handleGalleryChange = ({ fileList }) => {
		let last = fileList[fileList.length - 1];
		last.url = last.response && last.response.result && last.response.result.oss_file_url;
		this.setState({ gallery_list: fileList });
	};
	saveCoupon = () => {
		let form = this.props.form.getFieldsValue();
		this.props.form.setFieldsValue({ activity_type: true });
		if (form.full_type) {
			if (
				(form.full_type === "pay_amount" && !this.state.pay_amount && Number(this.state.pay_amount) !== 0) ||
				((form.full_type === "goods_num" || form.full_type === "discount_num") && !this.state.goods_num && !this.state.pay_amount && Number(this.state.pay_amount) !== 0) ||
				!this.state.coupon_content.length
			) {
				this.props.form.setFieldsValue({ activity_type: null });
			}
			this.state.coupon_content.forEach(item => {
				if (item === "reduce_flag" && !this.state.discount_amount) {
					this.props.form.setFieldsValue({ activity_type: null });
				}
			});
		}
		if (this.state.goods_type === "general" && !this.state.goods_list.filter(item => item.status === 1).length) {
			this.props.form.setFieldsValue({ goods_type: null });
		} else {
			this.props.form.setFieldsValue({ goods_type: this.state.goods_type });
		}
		let flag = false;
		this.props.form.validateFields(err => {
			if (err) {
				flag = true;
			}
		});
		if (flag) return;
		if (this.state.coupon_content.indexOf('reward_coupon_flag') > -1 && !this.state.coupon_list.some(item => item.status === 1)) {
			message.error('勾选增券后，请选择优惠券');
			return;
		}
		if (this.state.coupon_content.indexOf('free_shipping') > -1) {
			if (!this.state.shipping_template_item_desc.length) {
				message.error('请选择包邮地区！');
				return;
			}
		}
		if (this.state.coupon_content.includes('discount')) {
			const reg = /^[1-9]{1}$|^[0-9]{1}\.[0-9]{1}$/;
			if (!reg.test(this.state.discount_rate)) {
				message.error('折扣数需为大于0小于10的整数或1位小数');
				return;
			}
		}
		let image_url = null;
		if (this.state.gallery_list.length > 0) {
			if (this.state.gallery_list[0].url) {
				image_url = this.state.gallery_list[0].url;
			} else if (this.state.gallery_list[0].response) {
				image_url = this.state.gallery_list[0].response.result.oss_file_url;
			}
		}
		if (form.title_type === "custom" && !image_url) {
			return message.error("请上传图片");
		}
		let reward_req_list = this.state.coupon_list.map(item => {
			return {
				...item,
				reward_type: "coupon"
			};
		});
		// 选择了 满x元减/赠
		const choosePayAmount = form.full_type === 'pay_amount';
		// 选择了 满x件减/赠
		const chooseGoodsNum = form.full_type === 'goods_num';
		// 选择了 满x件折
		const chooseDiscountNum = form.full_type === 'discount_num';
		// 选择了 减X元
		const chooseReduceFlag = this.state.coupon_content.includes('reduce_flag');
		// 选择了 赠优惠券
		const chooseRewardCouponFlag = this.state.coupon_content.includes('reward_coupon_flag');
		// 选择了 包邮
		const chooseFreeShipping = this.state.coupon_content.includes('free_shipping');
		// 选择了 打X折
		const chooseDiscount = this.state.coupon_content.includes('discount');

		// 选择
		let data = {
			coupon_req: {
				coupon_id: this.state.coupon_res.coupon_id,
				coupon_category: "activity_coupon",
				coupon_type: "full_coupon",
				mini_app_id: cookie.load("mini_app_id"),
				coupon_name: form.coupon_name,
				start_time: Array.isArray(form.coupon_time) ? form.coupon_time[0].format("YYYY-MM-DD HH:mm:ss") : null,
				end_time: Array.isArray(form.coupon_time) ? form.coupon_time[1].format("YYYY-MM-DD HH:mm:ss") : null,
				full_type: form.full_type === 'discount_num' ? 'goods_num' : form.full_type,
				// 选择了 满x元减/赠 取pay_amount 否则取goods_num
				use_min_amount: choosePayAmount ? Math.round(this.state.pay_amount * 100) : this.state.goods_num,
				// 选择了 满x元减/赠 或者 满x件减/赠 并且选择了 减X元
				discount_amount: ((choosePayAmount || chooseGoodsNum) && chooseReduceFlag)
					? Math.round(this.state.discount_amount * 100)
					: null,
				// 选择了 满x件折 并 选择了 打X折
				discount_rate: (chooseDiscountNum && chooseDiscount) ? this.state.discount_rate * 10 : null,
				reduce_flag: chooseReduceFlag ? 1 : 0,
				reward_coupon_flag: chooseRewardCouponFlag ? 1 : 0,
				// 选择了 赠优惠券 才传值
				reward_req_list: chooseRewardCouponFlag ? reward_req_list : null,
				goods_type: form.goods_type,
				coupon_goods_req_list: form.goods_type === "general" ? this.state.goods_list : null,
				receive_type: form.receive_type,
				title_type: form.title_type,
				titleImageUrl: form.title_type === "custom" ? image_url : null,
				show_advance: this.state.show_advance ? 1 : 0,
				// 选择了 满x元减/赠 或者 满x件减/赠 并且选择了 包邮
				freeShippingType: ((choosePayAmount || chooseGoodsNum) && chooseFreeShipping)
					? this.state.freeShippingType
					: null,
				// 选择了 满x元减/赠 或者 满x件减/赠 并且选择了 包邮
				freeAreaList: ((choosePayAmount || chooseGoodsNum) && chooseFreeShipping)
					? this.state.freeAreaList
					: [],
			}
		};
		this.createOrUpdateCoupon(data);
	};
	createOrUpdateCoupon = async data => {
		let res = await createOrUpdateCoupon(data);
		if (res.success) {
			message.success(data.coupon_id ? "更新成功" : "创建成功");
			this.context.router.history.push("/market");
		} else {
			message.error(res.msg);
		}
	};
	queryCouponDetail = async () => {
		let data = {
			coupon_id: this.state.coupon_id,
			coupon_category: 'activity_coupon',
		};
		let res = await queryCouponDetail(data);
		if (res.success) {
			let { coupon_goods_res_list, coupon_res } = res.result;

			let { area_list: freeAreaList, area_str } = coupon_res;

			let goods_list = coupon_goods_res_list.map(item => {
				return {
					...item,
					...item.group_res,
					...item.group_res.goods_res
				};
			});
			let coupon_content = [],
				coupon_list = [],
				template = {
					area_list: [],
					province_list: [],
					city_list: [],
					district_list: [],
				},
				discount_amount,
				discount_rate,
				pay_amount,
				goods_num;
			coupon_list = coupon_res.reward_res_list.map(item => {
				return {
					...item,
					...item.reward_coupon_res
				};
			});
			if (coupon_res.discount_rate) {
				discount_rate = coupon_res.discount_rate / 10;
			}
			if (coupon_res.reduce_flag) {
				coupon_content.push("reduce_flag");
				discount_amount = coupon_res.discount_amount / 100;
			}
			if (freeAreaList.length) {
				coupon_content.push('free_shipping');
			}
			if (coupon_res.reward_coupon_flag) {
				coupon_content.push("reward_coupon_flag");
			}
			if (coupon_res.full_type === "pay_amount") {
				pay_amount = coupon_res.use_min_amount / 100;
			} else {
				// 打折活动
				if (discount_rate) {
					coupon_res.full_type = 'discount_num';
					coupon_content.push('discount');
				}
				goods_num = coupon_res.use_min_amount;
			}
			const allAreaList = this.state.area_res_list;
			let temp = [];
			freeAreaList.forEach((area) => {
				// 单独的县,districtList
				if (area.district_id) {
					const theDistrict =
						allAreaList.find((allArea) => allArea.area_id === area.area_id)
							.region_res_list.find((province) => province.region_id === area.province_id)
							.region_res_list.find((city) => city.region_id === area.city_id)
							.region_res_list.find((district) => district.region_id === area.district_id);

					template.district_list.push(theDistrict);
				}
				// 单独的市,cityList
				else if (area.city_id) {
					const theCity =
						allAreaList.find((allArea) => allArea.area_id === area.area_id)
							.region_res_list.find((province) => province.region_id === area.province_id)
							.region_res_list.find((city) => city.region_id === area.city_id);

					template.city_list.push(theCity);
				}
				// 有可能是选了地区，也有可能是单选了某个省
				else {
					let obj = temp.find((tp) => tp.area_id === area.area_id);
					if (obj) {
						obj.provincIdList.push(area.province_id);
					} else {
						temp.push({
							area_id: area.area_id,
							provincIdList: [area.province_id],
						})
					}
				}
			});
			temp.forEach((item) => {
				const provinceLength =
					allAreaList.find((allArea) => allArea.area_id === item.area_id)
						.region_res_list.length;
				// 选了整个地区
				if (provinceLength === item.provincIdList.length) {
					const theArea = allAreaList.find((allArea) => allArea.area_id === item.area_id);
					template.area_list.push(theArea);
				} else {
					item.provincIdList.forEach((id) => {
						const theProvince =
							allAreaList.find((allArea) => allArea.area_id === item.area_id)
								.region_res_list.find((province) => province.region_id === id);
						template.province_list.push(theProvince);
					})
				}
			});
			this.setState({
				goods_list,
				coupon_res,
				coupon_content,
				discount_amount,
				discount_rate,
				coupon_list,
				pay_amount,
				goods_num,
				goods_type: coupon_res.goods_type,
				show_advance: coupon_res.show_advance,
				template,
				freeShippingType: coupon_res.free_shipping_type || 1,
				shipping_template_item_desc: area_str.split(','),
			}, () => {
				// render函数里，一开始initialValue并没有取到后端返回的值，会取到默认值。导致form.full_type与this.state.coupon_res.full_type不一致的情况。
				// 这里解决里这一问题
				let coupon_id = getQueryString("coupon_id");
				if (coupon_id) {
					this.props.form.setFields({
						full_type: {
							value: this.state.coupon_res.full_type,
						},
					});
				}
			});
		}
	};
	goto = () => {
		this.context.router.history.push("/market");
	};
	changeNum = (type, e) => {
		let value = e.target.value;
		if (type !== "goods_num") {
			value = formatNumber(value);
		} else {
			value = formatInt(value);
		}
		this.setState({
			[type]: value
		});
	};
	changeValue = (value) => {
		let form = this.props.form.getFieldsValue();
		// 选择了 满x件折
		const chooseDiscountNum = form.full_type === 'discount_num';
		this.setState((preState) => {
			// 之前勾选了打X折，现在切换了活动类型，不能因为改变减X元，影响打X折的勾选状态
			if (preState.coupon_content.includes('discount') && !chooseDiscountNum) {
				value.push('discount');
			}
			// // 同理，打X折的勾选动作不能影响减X元
			if (preState.coupon_content.includes('reduce_flag') && chooseDiscountNum) {
				value.push('"reduce_flag"');
			}
			return (
				{
					coupon_content: value
				}
			)
		});
	};
	changeShowAdvance = e => {
		this.setState({
			show_advance: e.target.checked
		});
	};
	freeShippingTypeChange(e) {
		this.setState({
			freeShippingType: e.target.value,
		});
	}
	showAdressModal = e => {
		this.setState({
			adressVisible: true,
		});
	};
	handleAdressOk = (areaList, provinceList, cityList, districtList) => {
		let freeAreaList = [];
		let shipping_template_item_desc = [];
		if (areaList.length) {
			areaList.forEach((area) => {
				area.region_res_list.forEach((region) => {
					freeAreaList.push({
						areaId: region.area_id,
						province_id: region.region_id,
						cityId: null,
						districtId: null,
					});
					shipping_template_item_desc.push(region.region_name);
				});
			});
		}
		if (provinceList.length) {
			provinceList.forEach((province) => {
				freeAreaList.push({
					areaId: province.area_id,
					province_id: province.region_id,
					cityId: null,
					districtId: null,
				});
				shipping_template_item_desc.push(province.region_name);
			});
		}
		if (cityList.length) {
			cityList.forEach((city) => {
				freeAreaList.push({
					areaId: city.area_id,
					province_id: city.province_id,
					cityId: city.region_id,
					districtId: null,
				});
				shipping_template_item_desc.push(city.region_name);
			});
		}
		if (districtList.length) {
			districtList.forEach((district) => {
				freeAreaList.push({
					areaId: district.area_id,
					province_id: district.province_id,
					cityId: district.city_id,
					districtId: district.region_id,
				});
				shipping_template_item_desc.push(district.region_name);
			});
		}
		this.setState({
			freeAreaList,
			shipping_template_item_desc,
			adressVisible: false,
		});
	};
	handleAdressCancel = e => {
		this.setState({
			adressVisible: false,
		});
	};
	render() {
		let { menu_code } = this.props;
		const { getFieldDecorator } = this.props.form;
		// 过期的优惠活动不可编辑
		const isExpiredEvent = this.state.coupon_res.admin_coupon_status > 1;

		let form = this.props.form.getFieldsValue();
		// 选择了 满x元减/赠
		const choosePayAmount = form.full_type === 'pay_amount';;
		// 选择了 满x件减/赠
		const chooseGoodsNum = form.full_type === 'goods_num';
		// 选择了 满x件折
		const chooseDiscountNum = form.full_type === 'discount_num';
		// 选择了 减X元
		const chooseReduceFlag = this.state.coupon_content.includes('reduce_flag');
		// 选择了 赠优惠券
		const chooseRewardCouponFlag = this.state.coupon_content.includes('reward_coupon_flag');
		// 选择了 包邮
		const chooseFreeShipping = this.state.coupon_content.includes('free_shipping');
		// 选择了 打X折
		const chooseDiscount = this.state.coupon_content.includes('discount');

		return (
			<div className="page full-reduction">
				<NavBar
					icon="icon-bofang"
					child={this.state.coupon_id ? "编辑活动" : "新建活动"}
					father={menu_code.full_reduction}
					router="market"
				/>
				<div className="content">
					<div className="shopDataTitle">
						<i className="titleSign" />
            			基本信息
          			</div>
					<Divider />
					<Form>
						<Form.Item label="活动名称">
							{getFieldDecorator("coupon_name", {
								initialValue: this.state.coupon_res.coupon_name,
								rules: [
									{
										required: true,
										message: "请输入活动名称"
									}
								]
							})(
								<Input
									disabled={this.state.coupon_res && isExpiredEvent}
									maxLength={15}
									className="input-short"
								/>
							)}
						</Form.Item>
						<Form.Item
							label="活动时间"
							extra={
								<Checkbox
									disabled={this.state.coupon_res && isExpiredEvent}
									onChange={this.changeShowAdvance}
									checked={this.state.show_advance}>
									提前显示活动预告
                				</Checkbox>
							}>
							{getFieldDecorator("coupon_time", {
								initialValue: this.state.coupon_res.start_time
									? [
										moment(this.state.coupon_res.start_time, "YYYY-MM-DD HH:mm:ss"),
										moment(this.state.coupon_res.end_time, "YYYY-MM-DD HH:mm:ss")
									]
									: null,
								rules: [
									{
										required: true,
										message: "请输入活动时间"
									}
								]
							})(
								<DatePicker.RangePicker
									disabled={this.state.coupon_res && isExpiredEvent}
									className="date-time-limit-buy-item"
									format="YYYY-MM-DD HH:mm:ss"
									showTime
								/>
							)}
						</Form.Item>
						<Form.Item label="活动类型">
							{getFieldDecorator("full_type", {
								initialValue: this.state.coupon_res.full_type || "pay_amount",
								rules: [
									{
										required: true,
										message: "请选择活动类型"
									}
								]
							})(
								<Radio.Group disabled={this.state.coupon_res && isExpiredEvent}>
									<Radio value="pay_amount">满x元减/赠</Radio>
									<Radio value="goods_num">满x件减/赠</Radio>
									<Radio value="discount_num">满x件折/赠</Radio>
								</Radio.Group>
							)}
						</Form.Item>
						<Form.Item label="优惠设置">
							{getFieldDecorator("activity_type", {
								initialValue: null,
								rules: [
									{
										required: true,
										message: "请选择优惠设置"
									}
								]
							})(
								<div className="activity-condition-setting">
									<div>
										参与条件
                    					<div>
											{choosePayAmount ? (
												<Fragment>
													满
													<Input
														disabled={this.state.coupon_res && isExpiredEvent}
														value={this.state.pay_amount}
														onChange={this.changeNum.bind(this, "pay_amount")}
														className="input-short"
														suffix="元"
													/>
												</Fragment>
											) : (
													<Fragment>
														满
														<Input
															disabled={this.state.coupon_res && isExpiredEvent}
															value={this.state.goods_num}
															onChange={this.changeNum.bind(this, "goods_num")}
															className="input-short"
															suffix="件"
														/>
													</Fragment>
												)}
										</div>
									</div>
									<Divider type="vertical" />
									<div>
										优惠内容
                    					<div>
											<Checkbox.Group
												disabled={this.state.coupon_res && isExpiredEvent}
												value={this.state.coupon_content}
												onChange={this.changeValue.bind(this)}>
												{chooseDiscountNum ? (
													<Checkbox
														value="discount"
													>
														打
														<Input
															value={this.state.discount_rate}
															onChange={this.changeNum.bind(this, "discount_rate")}
															disabled={
																!this.state.coupon_content.includes('discount') ||
																(this.state.coupon_res && isExpiredEvent)
															}
															className="input-short"
															suffix="折"
														/>
													</Checkbox>
												) : null}
												{(choosePayAmount || chooseGoodsNum) ? (
													<Checkbox
														value="reduce_flag"
													>
														减
														<Input
															value={this.state.discount_amount}
															onChange={this.changeNum.bind(this, "discount_amount")}
															disabled={
																!this.state.coupon_content.includes('reduce_flag') ||
																(this.state.coupon_res && isExpiredEvent)
															}
															className="input-short"
															suffix="元"
														/>
													</Checkbox>
												) : null}
												<Checkbox
													disabled={this.state.coupon_res && isExpiredEvent}
													value="reward_coupon_flag"
												>
													赠优惠券
													{this.state.coupon_content.indexOf('reward_coupon_flag') > -1 ? (
														<span className="text-note add-coupon" onClick={this.changeVisible.bind(this, "coupon_select_visible")}>
															添加优惠券
														</span>
													) : null}
												</Checkbox>
												{this.state.coupon_content.find(item => item === "reward_coupon_flag") ? (
													<div className="coupon-list-show">
														{this.state.coupon_list
															.filter(item => item.status === 1)
															.map(item => (
																<div className="coupon" key={item.coupon_id}>
																	<div className="coupon-content">{item.coupon_name}</div>
																	{this.state.coupon_res && isExpiredEvent ? null : (
																		<Icon
																			type="close-circle"
																			theme="filled"
																			className="icon-delete"
																			onClick={this.deleteCoupon.bind(this, item.coupon_id)}
																		/>
																	)}
																</div>
															))}
													</div>
												) : null}
												{chooseDiscountNum ? null : (
													<Checkbox
														value="free_shipping"
													>
														包邮
													</Checkbox>
												)}
												{((choosePayAmount || chooseGoodsNum) && chooseFreeShipping) ? (
													<Fragment>
														<Radio.Group
															className="shippingRadioGrooup"
															onChange={this.freeShippingTypeChange.bind(this)}
															value={this.state.freeShippingType}
														>
															<Radio value={1}>整单包邮</Radio>
															<Tooltip placement="right" title="同一订单，若部分商品包邮，则整笔订单包邮">
																<Icon type="question-circle" theme="filled" />
															</Tooltip>
															<br />
															{/* <Radio value={2}>仅活动商品包邮</Radio> */}
														</Radio.Group>
														<p className="selectArea" onClick={this.showAdressModal.bind(this)}>选择包邮区域</p>
														{this.state.shipping_template_item_desc.length > 0 ? (
															<p className="area">{this.state.shipping_template_item_desc.join('、')}</p>
														) : (
																<p className="selectAddressTip">请选择包邮地区</p>
															)}
													</Fragment>
												) : null}
											</Checkbox.Group>
										</div>
									</div>
								</div>
							)}
						</Form.Item>
					</Form>
					<div className="shopDataTitle">
						<i className="titleSign" />
            			选择商品
          			</div>
					<Divider />
					<Form>
						<Form.Item label="适用商品">
							{getFieldDecorator("goods_type", {
								initialValue: this.state.coupon_res.goods_type || "all_goods",
								rules: [
									{
										required: true,
										message: "请选择适用商品!"
									}
								]
							})(
								<Fragment>
									<Radio.Group
										disabled={this.state.coupon_res && isExpiredEvent}
										value={this.state.goods_type}
										onChange={this.changeGoodsType}>
										<Radio value="all_goods">全部商品</Radio>
										<Radio value="general">指定商品</Radio>
									</Radio.Group>
									<div className="coupon-goods-list">
										<div className="text-remind">
											{this.state.goods_type === "general" ? "最多添加200个商品，" : null}
											单个商品仅允许同时参与一个满减/赠/折活动
                    					</div>
										<div className="integral-goods" style={{ display: this.state.goods_type === "general" ? "flex" : "none" }}>
											{this.state.coupon_res && isExpiredEvent ? null : (
												<div
													className="integral-goods-add"
													disabled={this.state.coupon_res && isExpiredEvent}
													onClick={this.changeVisible.bind(this, "goods_select_visible")}>
													+添加商品
												</div>
											)}
											<GoodsDragSort
												list={this.state.goods_list.filter(item => item.status !== 0)}
												handlePreview={() => { }}
												editable={true}
												delete={this.delGoods}
												changeList={list => {
													this.setState({
														goods_list: list
													});
												}}
											/>
										</div>
									</div>
								</Fragment>
							)}
						</Form.Item>
					</Form>
					<div className="shopDataTitle">
						<i className="titleSign" />
            			其他
          			</div>
					<Divider />
					<Form>
						<Form.Item label="领取优惠的方式">
							{getFieldDecorator("receive_type", {
								initialValue: this.state.coupon_res.receive_type || "auto",
								rules: [
									{
										required: true,
										message: "请选择领取优惠的方式"
									}
								]
							})(
								<Radio.Group disabled={this.state.coupon_res && isExpiredEvent}>
									<Radio value="auto">自动领取</Radio>
									<Radio value="share">
										分享后领取
                    					<span className="text-remind">用户分享活动后才能享受优惠券</span>
									</Radio>
								</Radio.Group>
							)}
						</Form.Item>

						<Form.Item label="活动规则说明">
							{getFieldDecorator("title_type", {
								initialValue: this.state.coupon_res.title_type || "general",
								rules: [
									{
										required: true,
										message: "请选择活动规则说明"
									}
								]
							})(
								<Fragment>
									<Radio.Group
										disabled={this.state.coupon_res && isExpiredEvent}
										value={this.props.form.getFieldsValue().title_type}
										onChange={this.changeTitleType}>
										<Radio value="general">默认文案</Radio>
										<Radio value="custom" disabled>
											自定义图片
                      						<span className="text-remind">可上传自定义图片在活动页顶部展示</span>
											<span className="text-note">示例</span>
										</Radio>
									</Radio.Group>
									{this.props.form.getFieldsValue().title_type === "custom" ? (
										<div className="iamge-upload">
											<Upload
												accept="image/jpeg,image/jpg,image/png"
												withCredentials={true}
												action={this.state.requestUrl}
												listType="picture-card"
												fileList={this.state.gallery_list}
												onPreview={this.handleGalleryPreview}
												onChange={this.handleGalleryChange}>
												{this.state.gallery_list.length >= 1 ? null : <UploadButton />}
											</Upload>

											<Modal
												visible={this.state.preview_gallery_visible}
												footer={null}
												onCancel={this.changeVisible.bind(this, "preview_gallery_visible")}>
												<img alt="example" style={{ width: "100%" }} src={this.state.preview_gallery_image} />
											</Modal>
										</div>
									) : null}
								</Fragment>
							)}
						</Form.Item>
					</Form>
					<footer>
						<Button
							disabled={this.state.coupon_res && isExpiredEvent}
							type="primary"
							shape="round"
							onClick={this.saveCoupon}>
							保存
            			</Button>
						<Button disabled={this.state.coupon_res && isExpiredEvent} shape="round" onClick={this.goto}>
							取消
           				 </Button>
					</footer>
				</div>
				<CouponSelectModal
					visible={this.state.coupon_select_visible}
					onCancel={this.changeVisible.bind(this, "coupon_select_visible")}
					onOk={this.onCouponOk}
				/>
				<GoodsChoose
					visible={this.state.goods_select_visible}
					handleCancel={this.changeVisible.bind(this, "goods_select_visible")}
					handleOk={this.onGoodsOk}
					activityType={"coupon"}
				/>
				<CheckAddress
					visible={this.state.adressVisible}
					handleOk={this.handleAdressOk}
					handleCancel={this.handleAdressCancel}
					areaList={this.state.template.area_list}
					provinceList={this.state.template.province_list}
					cityList={this.state.template.city_list}
					districtList={this.state.template.district_list}
					freeShipping={true}
				/>
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		menu_code: state.menuCode,
		activity_subtype: state.activitySubtype
	};
};
const FullReductionForm = Form.create({ name: "full-reduction-from" })(FullReduction);

export default connect(mapStateToProps)(FullReductionForm);
