import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavBar from "@/view/component/common/NavBar.js";
import './basicInfo.scss';
import { Divider, message } from 'antd';
import { getData } from '@service/getData';
import cookie from "react-cookies";
import config from "@config/config.js";

class BasicInfo extends Component {
    constructor(props) {
        super(props);
        BasicInfo.contextTypes = {
            router: PropTypes.object.isRequired,
        };
        this.state = {
            app_name: '',
            app_icon: '',
            qr_code: '',
            company: '',
            category_list: [],
        };
    }

    componentDidMount() {
        this.getData();
        const shopId = cookie.load('shop_id');
        if (shopId) {
            this.setState({
                qr_code: `${config.baseUrl}/qrCode/detail?shop_id=${shopId}&page=pages/home/index`
            });
        }
    }

    async getData() {
        const url = '/appApply/queryAppById';
        const data = {
            mini_app_id: cookie.load('mini_app_id'),
        };
        let res = await getData(url, data);
        if (!res.success) {
            message.error('获取数据失败');
            return;
        }
        const {
            app_name,
            app_icon,
            company,
            category_list,
            mini_app_id,
        } = res.result;
        this.setState({
            app_name,
            app_icon,
            company,
            category_list,
            mini_app_id,
        });
    }
    render() {
        let { menu_code } = this.props;
        return (
            <div className="basicInfo">
                <NavBar
                    icon="icon-shezhi"
                    father={menu_code.applet_manage}
                    router='basicInfo'
                    child={menu_code.basic_info}
                />
                <div className="content">
                    <div className="titleWrap">
                        <div className="asideBar"></div>
                        <span className="title">小程序基本信息</span>
                    </div>
                    <Divider></Divider>
                    <div className="main">
                        <div className="infoItem">
                            <span className="name">小程序名称:</span>
                            <span className="value">{this.state.app_name}</span>
                        </div>
                        <div className="infoItem top">
                            <span className="name">小程序头像:</span>
                            <img className="appletQrcode" src={this.state.app_icon} alt="小程序头像" />
                        </div>
                        <div className="infoItem top">
                            <span className="name">小程序码及线下物料下载:</span>
                            <img className="appletQrcode" src={this.state.qr_code} alt="小程序码及物料下载" />
                        </div>
                        <div className="infoItem">
                            <span className="name">主体信息:</span>
                            <span className="value">{this.state.company}</span>
                        </div>
                        <div className="infoItem">
                            <span className="name">服务类目:</span>
                            {
                                this.state.category_list && this.state.category_list.length > 0 &&
                                <span className="value">{this.state.category_list.join('/')}</span>
                            }
                        </div>
                        <div className="infoItem">
                            <span className="name">APPID:</span>
                            <span className="value">{this.state.mini_app_id}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        menu_code: state.menuCode,
    };
};
export default connect(mapStateToProps)(BasicInfo);
