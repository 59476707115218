import React from 'react';
import '@css/shopData.scss'
import PropTypes from "prop-types";


class ShopData extends React.Component {
    constructor(props) {
        super(props);
        ShopData.contextTypes = {
            router: PropTypes.object.isRequired
        };
    }
    jump(index, e) {
        if (index === 0) {
            this.context.router.history.push('/orderManage', { order_status: "1" });
        } else {
            this.context.router.history.push('/afterSaleManage');
        }
    }
    render() {
        return (
            <div className="shopData">
                <div className="title">店铺实时数据</div>
                <div className="wrap">
                    <ul className="shopUl">
                        {this.props.shopData.map((item, index) =>
                            <li key={index}>
                                <i className={"iconfont " + item.icon} style={{ color: item.color }}></i>
                                <p className="dataTitle">{item.title}</p>
                                <p className="dataTotal">{item.total}</p>
                                <p className="datayesterDay">昨日:{item.yesterday_total}</p>
                            </li>
                        )}
                    </ul>
                    <ul className="orderUl">
                        {this.props.orderData.map((item, index) =>
                            <li key={index} onClick={(e) => this.jump(index, e)}>
                                <i className={"iconfont " + item.icon} style={{ color: item.color }}></i>
                                <p className="dataTitle">{item.title}</p>
                                <p className="dataTotal">{item.total}</p>
                                <p className="datayesterDay">暂无数据</p>
                            </li>
                        )}
                    </ul>
                </div>
                <div className="line"></div>
            </div>
        );
    }
}

export default ShopData;
