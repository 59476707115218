import React from "react";
import NavBar from "@/view/component/common/NavBar.js";
import { connect } from "react-redux";
import "@css/userManage.scss";
import {
  Form,
  Table,
  Input,
  Button,
  Modal,
  message,
  DatePicker,
  Select,
  Switch,
  Pagination
} from "antd";
import moment from "moment";
import cookie from "react-cookies";
import PropTypes from "prop-types";
import {
  queryUserList,
  createOrUpdateAdminUser,
  freezeAdminUser,
  changeUserTrial
} from "@service/getData";
const { Option } = Select;

const CollectionCreateForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      const formLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 18 },
      };
      return (
        <Modal
          width="610px"
          visible={visible}
          title="创建新用户"
          okText="确认创建"
          onCancel={onCancel}
          onOk={onCreate}
          okButtonProps={{shape:'round'}}
          cancelButtonProps={{shape:'round'}}
        >
          <Form {...formLayout}>
            <Form.Item label="账户名称">
              {getFieldDecorator("user_name", {
                rules: [
                  {
                    required: true,
                    message: "请输入账户名称！"
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="密码">
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "请输入密码！"
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="真实姓名">
              {getFieldDecorator("real_name", {
                rules: [
                  {
                    required: true,
                    message: "请输入真实姓名！"
                  }
                ]
              })(<Input />)}
            </Form.Item>
            <Form.Item label="有效时间">
              {getFieldDecorator("expired_at", {
                rules: [
                  {
                    required: true,
                    message: "请输入有效时间！"
                  }
                ],
                initialValue: moment().add("month", 3)
              })(<DatePicker showTime />)}
            </Form.Item>
            <Form.Item label="是否试用">
              {getFieldDecorator("trial", {
                valuePropName: "checked",
                initialValue: false
              })(<Switch />)}
            </Form.Item>
            {/* <Form.Item label="备注">
              {getFieldDecorator("description")(<Input type="textarea" />)}
            </Form.Item> */}
          </Form>
        </Modal>
      );
    }
  }
);
class UserManage extends React.Component {
  constructor(props) {
    super();
    UserManage.contextTypes = {
      router: PropTypes.object.isRequired
    };
    this.state = {
      total: 0,
      page_no: 1,
      page_size: 10,
      user_name: null,
      real_name: null,
      freeze: null,
      user_list: [],
      selectedRowKeys: [],
      selectedRows: [],
      visible: false,
      loading: false,
      columns: [
        {
          title: "账户名称",
          dataIndex: "user_name"
        },
        {
          title: "真实姓名",
          dataIndex: "real_name"
        },
        {
          title: "有效时间",
          dataIndex: "expired_at"
        },
        {
          title: "是否试用",
          dataIndex: "trial",
          render: (text, record) => (
            <div>
              <Switch
                checkedChildren="是"
                unCheckedChildren="否"
                defaultChecked={Boolean(+text)}
                onChange={this.changeTrailStatus.bind(
                  this,
                  record.admin_user_id
                )}
              />
            </div>
          )
        },
        {
          title: "是否冻结",
          dataIndex: "freeze",
          render: (text, record) => (
            <div>
              <Switch
                checkedChildren="冻结"
                unCheckedChildren="开启"
                defaultChecked={Boolean(+text)}
                onChange={this.changeFreezeStatus.bind(
                  this,
                  record.admin_user_id
                )}
              />
            </div>
          )
        }
      ]
    };
  }
  componentDidMount() {
    let is_admin = +cookie.load("is_admin");
    if (!is_admin) {
      Modal.error({
        title: "温馨提示",
        content: "这是管理员操作页面！",
        onOk: () => {
          this.context.router.history.goBack();
        }
      });
    }
    this.queryUserList();
  }
  queryUserList = async () => {
    this.setState({
      loading: true
    });
    let res = await queryUserList({
      page_no: this.state.page_no,
      page_size: this.state.page_size,
      user_name: this.state.user_name,
      real_name: this.state.real_name,
      freeze: this.state.freeze
    });
    this.setState(
      {
        user_list: []
      },
      () => {
        this.setState({
          user_list: [...res.result.list],
          total: res.result.total,
          loading: false
        });
      }
    );
  };
  filterData = () => {
    let form = this.props.form.getFieldsValue();
    let data = {
      user_name: form.user_name || "",
      real_name: form.real_name || "",
      freeze: form.freeze || ""
    };
    this.setState(data);
    setTimeout(() => {
      this.queryUserList();
    });
  };
  clearFilter = () => {
    this.props.form.resetFields();
    let data = {
      user_name: "",
      real_name: "",
      freeze: 0
    };
    this.setState(data);
    setTimeout(() => {
      this.queryUserList();
    });
  };
  changeFreezeStatus = async (admin_user_id, freeze) => {
    freeze = freeze ? 1 : 0;
    let res = await freezeAdminUser({
      admin_user_id,
      freeze
    });
    if (res.success) {
      message.success(freeze ? "已启用" : "已停用");
      this.queryUserList();
    }
  };
  changeTrailStatus = async (admin_user_id, trial) => {
    trial = trial ? 1 : 0;
    let res = await changeUserTrial({
      admin_user_id,
      trial
    });
    if (res.success) {
      message.success(trial ? "已开启试用" : "已停止试用");
      this.queryUserList();
    }
  };
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys,
      selectedRows
    });
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState({
      page_size: pageSize
    });
    setTimeout(() => {
      this.queryUserList();
    });
  };
  onShowPageChange = page_no => {
    this.setState({
      page_no: page_no
    });
    setTimeout(() => {
      this.queryUserList();
    });
  };
  addUserHandle = () => {
    this.setState({ visible: true });
  };
  handleCancel = () => {
    this.setState({ visible: false });
  };
  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.createOrUpdateAdminUser(values);
      this.setState({ visible: false });
    });
  };
  createOrUpdateAdminUser = async data => {
    const form = this.formRef.props.form;
    let { user_name, real_name, password, trial, expired_at } = data;
    expired_at = expired_at.format("YYYY-MM-DD hh:mm:ss");
    let trial_data = {
      user_name,
      real_name,
      password,
      expired_at,
      status: 1,
      trial: trial ? 1 : 0,
      is_admin: 0
    };
    const hide = message.loading("正在创建用户...", 0);
    let res = await createOrUpdateAdminUser(trial_data);
    if (res.success) {
      form.resetFields();
      setTimeout(hide, 0);
      message.success("创建成功");
      this.queryUserList();
    }
  };
  saveFormRef = formRef => {
    this.formRef = formRef;
  };
  render() {
    let { menu_code } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection
    };
    return (
      <div className="user-manage">
        <div className="content">
          <NavBar icon="icon-guanliyuanshezhi" child={menu_code.user_message} />
          <div className="user-manage-content">
            <div className="action">
              <Button shape="round" type="primary" onClick={this.addUserHandle}>
                创建新账户
              </Button>
            </div>
            <div className="filter-content">
              <Form className="filter-form ant-vertical" layout="inline">
                <Form.Item label="账号名称">
                  {getFieldDecorator("user_name")(
                    <Input className="user-name" placeholder="输入账户名称" />
                  )}
                </Form.Item>
                <Form.Item label="真实姓名">
                  {getFieldDecorator("real_name")(
                    <Input className="real-name" placeholder="输入真实姓名" />
                  )}
                </Form.Item>
                <Form.Item label="是否冻结">
                  {getFieldDecorator("freeze", {
                    initialValue: ""
                  })(
                    <Select style={{ width: 170 }}>
                      <Option value="">全部</Option>
                      <Option value="0">开启</Option>
                      <Option value="1">冻结</Option>
                    </Select>
                  )}
                </Form.Item>
              </Form>
              <div className="line">
                <Button shape="round" type="primary" onClick={this.filterData}>
                  查询
                </Button>
                <span className="text-note" onClick={this.clearFilter}>
                  清空筛选条件
                </span>
              </div>
            </div>
            <div className="action">
              <Button
                disabled={this.state.selectedRows.length ? false : true}
                shape="round"
                type="primary"
              >
                停用已选用户
              </Button>
              <span className="text">
                当前已选择{this.state.selectedRows.length}项
              </span>
            </div>
            <div className="table-content">
              <Table
                loading={this.state.loading}
                rowSelection={rowSelection}
                rowKey={(record, index) => index}
                columns={this.state.columns}
                dataSource={this.state.user_list}
                pagination={false}
                bordered={true}
              />
              <Pagination
                className="pagenation"
                onChange={this.onShowPageChange}
                onShowSizeChange={this.onShowSizeChange}
                defaultCurrent={this.state.page_no}
                total={this.state.total}
                showTotal={total => `共 ${total} 条`}
                pageSizeOptions={["10", "30", "50", "100"]}
                showSizeChanger
              />
            </div>
          </div>
        </div>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};

const UserManageForm = Form.create({ name: "user_manage_form" })(UserManage);

export default connect(mapStateToProps)(UserManageForm);
