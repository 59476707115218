import React from 'react'
import {Tabs} from 'antd'
import GoodsDetail from './GoodsDetail'
import GroupDetail from'../../group/children/GroupDetail'
import NavBar from '@/view/component/common/NavBar.js'
import {connect} from "react-redux";
import  '@css/goodsGroupDetail.scss'
import {getQueryString} from "@common/common";
class GoodsGroupDetail extends React.Component{
    constructor(){
        super()
        this.state={
            goods_id:null,
            edit:false,
            group_open_record_goods_id:null
        }
    }
    componentDidMount(){
        let goods_id=getQueryString("goods_id");
        let edit=getQueryString("eidt");
        let group_open_record_goods_id=getQueryString("group_open_record_goods_id")
        this.setState({
            goods_id:goods_id?goods_id:null,
            edit:edit?edit:null,
            group_open_record_goods_id:group_open_record_goods_id?group_open_record_goods_id:null
        })
    }
    render(){
        let { menu_code} = this.props
        return(
            <div className="goods-group-detail">
                <NavBar icon="icon-shangpinguanli" father={menu_code.goods_publish} child={menu_code.goods_detail} router="goodsManage"/>
                <div className="content">
                    {
                        this.state.group_open_record_goods_id?(
                            <Tabs

                                defaultActiveKey="1"
                                tabBarStyle={{backgroundColor:"#ffffff",borderRadius:"4px"}}
                            >
                                <Tabs.TabPane tab="商品详情" key="1">
                                    <GoodsDetail goods_id={this.state.goods_id} edit={this.state.edit} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="团详情" key="2">
                                    <GroupDetail group_open_record_goods_id={this.state.group_open_record_goods_id}/>
                                </Tabs.TabPane>

                            </Tabs>
                        ):(
                            <Tabs

                                defaultActiveKey="1"
                                tabBarStyle={{backgroundColor:"#ffffff",borderRadius:"4px"}}
                            >
                                <Tabs.TabPane tab="商品详情" key="1">
                                    <GoodsDetail goods_id={this.state.goods_id} edit={this.state.edit} />
                                </Tabs.TabPane>
                            </Tabs>
                        )
                    }
                </div>
            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        menu_code:state.menuCode
    }
}
export default connect(mapStateToProps) (GoodsGroupDetail);
