import React, { Fragment } from "react";
import NavBar from "@/view/component/common/NavBar.js";
import UploadComponent from "@/view/component/common/Upload";
import PopOverTips from '@/view/component/common/PopOverTips/PopOverTips';
import config from "@config/config.js";
import { connect } from "react-redux";
import * as QrCode from "qrcode.react";
import "@css/appletManage.scss";
import {
	Form,
	Table,
	Input,
	Button,
	Modal,
	message,
	Pagination,
	Radio,
	Select,
	Tooltip,
	Icon,
	Divider
} from "antd";
import cookie from "react-cookies";
import PropTypes from "prop-types";
import {
	getPreAuthUrl,
	queryAppApplyList,
	deleteAppApply,
	createOrUpdateAppApply,
	queryAppApplyDetail,
	createAppCreateOrder,
	queryAdminOrderDetail,
	queryAllCategories,
	getData
} from "@service/getData";
const RadioGroup = Radio.Group;
const Option = Select.Option;

const statusList = [
	'授权中',
	'授权失败',
	'发布中',
	'发布成功',
	'发布失败',
];

const statusColor = [
	'#6699FF',
	'#FF6600',
	'#6699FF',
	'#66CC66',
	'#FF0000',
];

const CollectionCreateForm = Form.create({ name: "form_in_modal" })(
	// eslint-disable-next-line
	class extends React.Component {
		render() {
			const {
				qualify_index,
				firstCity,
				secondCity,
				exter_list,
				provinceData,
				cities,
				qualifyFile,
				app_apply,
				visible,
				confirmLoading,
				onCancel,
				onCreate,
				onPreview,
				onChange1,
				onChange2,
				onChange3,
				onChange4,
				onChange5,
				onChange6,
				onProvinceChange,
				onSecondCityChange,
				onChangeQualifyFile,
				onChangeQualifyRadio,
				form,
				fileList,
				fileList2,
				fileList3,
				fileList4,
				fileList5,
				fileList6,
				err_code,
			} = this.props;
			const { getFieldDecorator } = form;
			const formLayout = {
				labelCol: { span: 8 },
				wrapperCol: { span: 14 }
			};
			return (
				<Modal
					style={{ top: 50 }}
					width={800}
					visible={visible}
					className="modal2"
					okText={app_apply.app_apply_id ? "编辑小程序" : "创建小程序"}
					onCancel={onCancel}
					onOk={onCreate}
					confirmLoading={confirmLoading}
					okButtonProps={{ shape: 'round' }}
					cancelButtonProps={{ shape: 'round' }}
				>
					<div className="modal-top">
						<div className="title">注册小程序信息填写</div>
					</div>
					<Form {...formLayout} className="form-content">
						<div className="divideWrap">
							<div className="divideTitle">
								<div className="bar"></div>
								<span className="title">注册小程序信息</span>
							</div>
						</div>
						<Form.Item label="法人微信号">
							{getFieldDecorator("legal_persona_wechat", {
								rules: [
									{
										required: true,
										message: "请输入法人微信号！"
									}
								],
								initialValue: app_apply.legal_persona_wechat
							})(<Input style={{ width: '320px' }} placeholder="微信将发送人脸识别验证信息到该微信号" />)}
						</Form.Item>
						<Form.Item label="法人姓名">
							{getFieldDecorator("legal_persona_name", {
								rules: [
									{
										required: true,
										message: "请输入法人姓名！"
									}
								],
								initialValue: app_apply.legal_persona_name
							})(<Input style={{ width: '320px' }} placeholder="与营业执照一致" />)}
						</Form.Item>
						<Form.Item label="企业名称">
							{getFieldDecorator("name", {
								rules: [
									{
										required: true,
										message: "请输入企业名称！"
									}
								],
								initialValue: app_apply.name
							})(<Input style={{ width: '320px' }} placeholder="与营业执照一致" />)}
						</Form.Item>
						<Form.Item label="企业信用代码">
							{getFieldDecorator("code", {
								rules: [
									{
										required: true,
										message: "请输入企业信用代码！"
									},
									{
										len: 18,
										message: '企业信用代码有误',
									}
								],
								initialValue: app_apply.code
							})(<Input style={{ width: '320px' }} placeholder="营业执照上18位信用代码" maxLength={18} />)}
						</Form.Item>
						<Form.Item label="营业执照照片">
							{getFieldDecorator("license", {
								rules: [
									{
										required: true,
										message: "请上传营业执照照片！"
									}
								],
								initialValue: app_apply.license
							})(
								<div className="flex">
									<UploadComponent
										fileList={fileList}
										onPreview={onPreview}
										onChange={onChange1}
										btn_text="上传照片"
									/>
									<div className="text-note">
										<span>
											需年检章齐全(当年成立公司除外),
											照片需为彩色图片并小于2M、文件格式为bmp、png、jpeg、jpg或gif
                    					</span>
										{fileList.length ? (
											<div>
												<a
													href={
														config.baseUrl +
														"/file/download?url=" +
														fileList[0].url
													}
													download="license.jpg"
												>
													下载
                        						</a>
											</div>
										) : (
												""
											)}
									</div>
								</div>
							)}
						</Form.Item>
						<Form.Item required label="服务类目">
							<div>
								<Select
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.props.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									value={firstCity}
									style={{ width: 165 }}
									onChange={onProvinceChange}
								>
									{firstCity === "请选择" ? (
										<Option key="请选择">请选择</Option>
									) : null}
									{provinceData.map(province => (
										<Option key={province.id}>{province.name}</Option>
									))}
								</Select>
								<Select
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.props.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									style={{ width: 165, marginLeft: 10 }}
									value={secondCity}
									onChange={onSecondCityChange}
								>
									{secondCity === "请选择" ? (
										<Option key="请选择">请选择</Option>
									) : null}
									{cities.map(city => (
										<Option key={city.id}>{city.name}</Option>
									))}
								</Select>
							</div>
						</Form.Item>
						{exter_list.length ? (
							<div>
								{exter_list.length > 1 ? (
									<Form.Item
										className="white-normal pr40"
										label={"选择上传资质(" + exter_list.length + "选1即可)"}
									>
										<RadioGroup
											onChange={onChangeQualifyRadio}
											value={qualify_index}
										>
											{exter_list.map((item, index) => {
												return (
													<Radio
														key={index}
														value={index}
														className="white-normal-radio"
													>
														{item.title}
													</Radio>
												);
											})}
										</RadioGroup>
									</Form.Item>
								) : null}
								{exter_list[qualify_index].inner_list.length ? (
									<div>
										{exter_list[qualify_index].inner_list.map((item, index) => {
											return (
												<Form.Item
													key={index}
													label={item.name}
													className="white-normal"
												>
													{getFieldDecorator(`license${index}`)(
														<div className="flex">
															<UploadComponent
																fileList={
																	qualifyFile[item.name].length
																		? qualifyFile[item.name]
																		: []
																}
																onPreview={onPreview}
																onChange={onChangeQualifyFile.bind(
																	this,
																	item.name
																)}
																btn_text="上传照片"
															/>
															<div className="text-note">
																<div>
																	上传原件或复印件，复印件务必加盖公司公章
																</div>
																<div>
																	文件格式为jpg、jpeg、bmp、gif或png，文件大小不超过5M。
																</div>
																{item.name === "小程序申请者的承诺书" ? (
																	<div>
																		<a
																			className="note"
																			href="http://mmbiz.qpic.cn/mmbiz_png/sUeh34ANf2NuPTkEUe8uMNWQgbxOtftm2wVstTia6ZzLJCUyibdqujolOmRnMNaHpwiaxDPe0eqXPx1Xh662Qnc3w/0?wx_fmt=png"
																			target="_blank"
																			rel="noopener noreferrer"
																		>
																			查看示例
																		</a>
																	</div>
																) : null}
																{qualifyFile[item.name].length ? (
																	<div>
																		<a
																			href={
																				config.baseUrl +
																				"/file/download?url=" +
																				qualifyFile[item.name][0].url
																			}
																			download="license.jpg"
																		>
																			下载
																		</a>
																	</div>
																) : (
																		""
																	)}
															</div>
														</div>
													)}
												</Form.Item>
											);
										})}
									</div>
								) : null}
							</div>
						) : null}
						<Form.Item label="小程序名称">
							{getFieldDecorator("app_name", {
								rules: [
									{
										required: true,
										message: "请输入小程序名称！"
									}
								],
								initialValue: app_apply.app_name
							})(<Input style={{ width: '320px' }} />)}
						</Form.Item>
						<Form.Item label="小程序头像">
							{getFieldDecorator("app_icon", {
								rules: [
									{
										required: true,
										message: "请上传小程序头像！"
									}
								],
								initialValue: app_apply.app_icon
							})(
								<div className="flex">
									<UploadComponent
										fileList={fileList2}
										onPreview={onPreview}
										onChange={onChange2}
										btn_text="上传照片"
									/>
									<div className="text-note">
										<span>图片小于2M、文件格式为bmp、png、jpeg、jpg或gif</span>
										{(fileList2.length > 0 && fileList2[0].url) ? (
											<div>
												<a
													href={
														config.baseUrl +
														"/file/download?url=" +
														fileList2[0].url
													}
													download="app_icon.jpg"
												>
													下载
                        						</a>
											</div>
										) : (
												""
											)}
									</div>
								</div>
							)}
						</Form.Item>
						{err_code * 1 === 7 &&
							<Fragment>
								<div className="divideWrap">
									<div className="divideTitle">
										<div className="bar"></div>
										<span className="title">补充商标信息授权资料</span>
									</div>
								</div>
								<Form.Item label="商标注册证（照片）">
									{getFieldDecorator('trad_reg_cert', {
										rules: [
											{
												required: true,
												message: '请上传商标注册证',
											},
										],
										initialValue: app_apply.trad_reg_cert
									})(
										<div className="flex">
											<UploadComponent
												fileList={fileList3}
												onPreview={onPreview}
												onChange={onChange3}
												btn_text="上传照片"
											/>
											<div className="text-note">
												<span>
													需年检章齐全(当年成立公司除外),
													照片需为彩色图片并小于2M、文件格式为bmp、png、jpeg、jpg或gif
											</span>
												{(fileList3.length > 0 && fileList3[0].url) ? (
													<div>
														<a
															href={
																config.baseUrl +
																"/file/download?url=" +
																fileList3[0].url
															}
															download="app_icon.jpg"
														>
															下载
													</a>
													</div>
												) : (
														""
													)}
											</div>
										</div>
									)}
								</Form.Item>
								<Form.Item label={<PopOverTips styleType='inner' inners='商持方的授权使用书（照片）' content='商标被授权方注册时必填' />}>
									{getFieldDecorator('trad_auth_use')(
										<div className="flex">
											<UploadComponent
												fileList={fileList4}
												onPreview={onPreview}
												onChange={onChange4}
												btn_text="上传照片"
											/>
											<div className="text-note">
												<span>
													需年检章齐全(当年成立公司除外),
													照片需为彩色图片并小于2M、文件格式为bmp、png、jpeg、jpg或gif
											</span>
												{(fileList4.length > 0 && fileList4[0].url) ? (
													<div>
														<a
															href={
																config.baseUrl +
																"/file/download?url=" +
																fileList4[0].url
															}
															download="app_icon.jpg"
														>
															下载
													</a>
													</div>
												) : (
														""
													)}
											</div>
										</div>
									)}
								</Form.Item>
								<Form.Item label={<PopOverTips styleType='inner' inners='小程序开店授权书（照片）' content='商标持有方授权给第三方时必填' />} extra="小程序主体与品牌主体不一致时必填">
									{getFieldDecorator('mini_shop_auth')(
										<div className="flex">
											<UploadComponent
												fileList={fileList5}
												onPreview={onPreview}
												onChange={onChange5}
												btn_text="上传照片"
											/>
											<div className="text-note">
												<span>
													需年检章齐全(当年成立公司除外),
													照片需为彩色图片并小于2M、文件格式为bmp、png、jpeg、jpg或gif
											</span>
												{(fileList5.length > 0 && fileList5[0].url) ? (
													<div>
														<a
															href={
																config.baseUrl +
																"/file/download?url=" +
																fileList5[0].url
															}
															download="app_icon.jpg"
														>
															下载
													</a>
													</div>
												) : (
														""
													)}
											</div>
										</div>
									)}
								</Form.Item>
								<Form.Item label={<PopOverTips styleType='inner' inners='律所鉴定书（照片）' content='商标被授权方授权给第三方时必填' />}>
									{getFieldDecorator('mini_shop_auth')(
										<div className="flex">
											<UploadComponent
												fileList={fileList6}
												onPreview={onPreview}
												onChange={onChange6}
												btn_text="上传照片"
											/>
											<div className="text-note">
												<span>
													需年检章齐全(当年成立公司除外),
													照片需为彩色图片并小于2M、文件格式为bmp、png、jpeg、jpg或gif
											</span>
												{(fileList6.length > 0 && fileList6[0].url) ? (
													<div>
														<a
															href={
																config.baseUrl +
																"/file/download?url=" +
																fileList6[0].url
															}
															download="app_icon.jpg"
														>
															下载
													</a>
													</div>
												) : (
														""
													)}
											</div>
										</div>
									)}
								</Form.Item>
							</Fragment>
						}
					</Form>
				</Modal>
			);
		}
	}
);
class AppletManage extends React.Component {
	constructor(props) {
		super();
		AppletManage.contextTypes = {
			router: PropTypes.object.isRequired
		};
		this.state = {
			total: 0,
			page_no: 1,
			page_size: 10,
			applet_list: [],
			selectedRows: [],
			step: 0,
			expired_at: "",
			loading: false,
			radio_value: 1,
			fileList: [],
			fileList2: [],
			fileList3: [],
			fileList4: [],
			fileList5: [],
			fileList6: [],
			previewVisible: false,
			previewImage: "",
			less_time: "30:00",
			qr_code: null,
			admin_order_id: null,
			provinceData: [],
			cityData: [],
			cities: [],
			qualify_index: 0,
			firstCity: null,
			secondCity: null,
			defaultValue: "请选择",
			exter_list: [],
			qualifyFile: {},
			confirmLoading: false,
			veriRemVisible: false,
			isEdit: false,
			err_code: null,
			app_apply: {
				app_apply_id: "",
				name: "",
				code: "",
				legal_persona_wechat: "",
				legal_persona_name: "",
				app_name: "",
				app_icon: "",
				license: ""
			},
			apply_status_tips: {
				1: "未提交",
				2: "刷新查看结果，持续显示申请中，请法人进行微信验证。",
				3: "创建成功，待DTC Mall发布代码。",
				4: "创建失败，请及时修改资料重新提交。",
				5: "代码发布中，微信审核需要1~3天。",
				6: "代码发布中，微信审核需要1~3天。",
				7: "代码发布中，微信审核需要1~3天。",
				8: "发布成功，可扫二维码查看正式店铺。",
				9: "发布失败，待DTC Mall重新发布。"
			},
			columns: [
				{
					title: "小程序名称",
					dataIndex: "app_name",
					render: (text, record) => (
						<div>
							{text}
							{record.type === "auth" && record.apply_status >= 3
								? "(授)"
								: null}
						</div>
					)
				},
				{
					title: "授权方式",
					dataIndex: "type",
					render: text => (
						<div>{text === "auth" ? "直接授权" : "代理注册"}</div>
					)
				},
				{
					title: "更新时间",
					dataIndex: "gmt_modified"
				},
				{
					title: (
						<div>
							状态
							<Tooltip
								title="发布成功，小程序与商户号关联成功时，小程序设置操作全部完毕。"
							>
								<Icon type="question-circle" theme="twoTone" />
							</Tooltip>
						</div>
					),
					dataIndex: "authority_status",
					render: (text, record) => (
						<div style={{ color: statusColor[text] }}>
							{statusList[text]}
							{text === 4
								?
								<Tooltip
									title="调整后，请再次操作发布"
								>
									<Icon type="question-circle" theme="twoTone" />
								</Tooltip>
								:
								null
							}
						</div>
					)
				},
				{
					title: "详情",
					width: 300,
					dataIndex: "detail",
					render: (text, record) => {
						const status = record.authority_status;
						const code = record.app_apply_err_code;
						if (status === 0) {
							return (
								<span>等待注册资料通过审核和法人验证</span>
							)
						}
						if (status === 1) {
							if (code === 1) {
								return (
									<span>其他原因，请咨询客服</span>
								)
							}
							if (code === 2) {
								return (
									<span>
										注册资料出错
										<span className="seeHelp" onClick={this.updateAppApply.bind(this, record.app_apply_id)}>修改资料</span>
									</span>
								)
							}
							if (code === 3) {
								return (
									<span>
										审核超时，请重新提交资料
										<span className="seeHelp" onClick={this.updateAppApply.bind(this, record.app_apply_id)}>修改资料</span>
									</span>
								)
							}
						}
						if (status === 4) {
							return (
								<span>{text}<span className="seeHelp" onClick={this.seeHelp.bind(this)}>查看帮助</span></span>
							)
						}
						if (status === 2) {
							return (
								<>
									<span>预计7个工作日内完成审核</span>
									<Tooltip
										title="审核过程中，你当前版本的小程序功能可正常使用"
									>
										<Icon type="question-circle" theme="twoTone" />
									</Tooltip>
								</>
							)
						}
						if (status === 3) {
							return null;
						}
					}
				},
				{
					title: "支付状态",
					dataIndex: "paid",
					render: (text, record) => {
						const status = record.authority_status;
						if (status !== 0 && status !== 1) {
							// 这里需要字段判断是否已经关联商户号成功
							if (+record.mch_related === 1 && +record.xmd_mch_related === 4) {
								return (
									<span>微信支付</span>
								)
							} else {
								return (
									<span style={{ cursor: 'pointer' }} onClick={this.merchantAdd.bind(this)}>去设置</span>
								)
							}
						} else {
							return (
								<span style={{ color: 'rgb(181 175 175)' }}>去设置</span>
							)
						}
					}
				},
				{
					title: "操作",
					dataIndex: "apply_status",
					render: (text, record) => (
						<div className="option-list">
							<span onClick={this.goBasicInfo.bind(this)} style={{ marginRight: "0px" }} className="text-note">
								基本设置
              				</span>
							<Divider type="vertical" />
							<span onClick={this.goAppOrder.bind(this)} style={{ marginRight: "0px" }} className="text-note">
								付费
              				</span>
							{record.authority_status === 4 &&
								<>
									<Divider type="vertical" />
									<span className="text-note" onClick={this.releaseLastVersion.bind(this, record)}>
										发布
									</span>
								</>
							}
						</div>
					)
				}
			]
		};
	}
	componentDidMount() {
		this.queryAppApplyList();
		this.queryAllCategories(cookie.load("mini_app_id"));
		// 开店指引跳转过来
		if (this.props.location.state && this.props.location.state.step === 2) {
			if (this.props.location.state.isEdit) {
				this.updateAppApply(cookie.load('app_apply_id'));
			} else {
				this.startToCreate();
			}
		}
	}
	queryAppApplyList = async () => {
		this.setState({
			loading: true
		});
		let res = await queryAppApplyList({
			page_no: this.state.page_no,
			page_size: this.state.page_size
		});
		if (res.result.total) {
			cookie.save("mini_app_length", res.result.total);
		}
		this.setState({
			applet_list: [...res.result.list],
			total: res.result.total,
			loading: false
		});
	};
	deleteAppApply = async app_apply_id => {
		let res = await deleteAppApply({
			app_apply_id
		});
		if (res.success) {
			message.success("删除成功");
			this.queryAppApplyList();
		}
	};
	// 编辑
	updateAppApply = async app_apply_id => {
		let res = await queryAppApplyDetail({
			app_apply_id
		});
		let app_apply = res.result.app_apply,
			categories = res.result.app_apply.categories,
			fileList = [
				{
					uid: "-1",
					name: "xxx",
					status: "done",
					url: app_apply.license
				}
			],
			fileList2 = [
				{
					uid: "-2",
					name: "xxx",
					status: "done",
					url: app_apply.app_icon
				}
			],
			// 商标注册证
			fileList3 = [
				{
					uid: "-3",
					name: "xxx",
					status: "done",
					url: app_apply.trad_reg_cert
				}
			],
			// 商持方的授权使用书
			fileList4 = [
				{
					uid: "-4",
					name: "xxx",
					status: "done",
					url: app_apply.trad_auth_use
				}
			],
			fileList5 = [
				{
					uid: '-5',
					name: 'xxx',
					status: 'done',
					url: app_apply.mini_shop_auth
				}
			],
			fileList6 = [
				{
					uid: '-6',
					name: 'xxx',
					status: 'done',
					url: app_apply.law_cert
				}
			],
			qualifyFile = {},
			err_code = res.result.app_apply.err_code;
		if (categories.certicates) {
			categories.certicates.forEach(item => {
				qualifyFile[item.key] = item.value;
			});
		}
		this.setState({
			app_apply,
			firstCity: categories.first,
			secondCity: categories.second,
			qualifyFile,
			fileList,
			fileList2,
			fileList3,
			fileList4,
			fileList5,
			fileList6,
			err_code,
		}, () => {
			this.setStep(2);
			this.showError();
		});
	};
	showError() {
		const errorCode = this.state.err_code === '' ? '' : this.state.err_code * 1;
		const errorMessage = [
			'企业名称无效',
			'企业信用代码无效',
			'法人姓名与证件不符',
			'营业执照无效',
			'许可证无效',
			'名称被占用',
			'名称不合法',
			'名称包含商标信息',
		];
		const fieldNames = [
			'name',
			'code',
			'legal_persona_name',
			'license',
			'license0',
			'app_name',
			'app_name',
			'app_name',
		];
		const form = this.formRef.props.form;
		errorCode !== '' && form.setFields({
			[fieldNames[errorCode]]: {
				errors: [new Error(errorMessage[errorCode])]
			}
		});
	}
	queryAllCategories = async (mini_app_id = null) => {
		let data = {};
		if (mini_app_id) {
			data.mini_app_id = mini_app_id;
		}
		let res = await queryAllCategories({});
		if (res.success) {
			let provinceData = [],
				cityData = {};
			let list = [...res.result.categories_list.categories];
			list.forEach(item => {
				if (item.level === 1) {
					provinceData.push(item);
					cityData[item.id] = [];
				} else if (item.level > 1) {
					if (cityData[item.father]) {
						cityData[item.father].push(item);
					} else {
						cityData[item.father] = [item];
					}
				}
			});
			this.setState({
				provinceData,
				firstCity: this.state.defaultValue,
				secondCity: this.state.defaultValue,
				cityData
			});
		}
	};
	handleProvinceChange = value => {
		this.setState({
			firstCity: value,
			cities: this.state.cityData[value],
			secondCity: this.state.defaultValue
		});
	};
	handleSecondCityChange = value => {
		let option = this.state.cities.filter(item => {
			return +item.id === +value;
		});
		let exter_list = option[0].qualify.exter_list;
		let qualifyFile = this.state.qualifyFile;
		exter_list.forEach(item => {
			item.title = "";
			item.inner_list.forEach(elem => {
				item.title += elem.name;
				qualifyFile[elem.name] = [];
			});
		});
		this.setState({
			secondCity: value,
			qualifyFile,
			exter_list
		});
	};
	startToCreate = () => {
		this.clearFormData();
		this.setStep(2);
	};
	clearFormData = () => {
		const form = this.formRef.props.form;
		form.resetFields();
		this.setState({
			firstCity: this.state.defaultValue,
			secondCity: this.state.defaultValue,
			fileList: [],
			fileList2: [],
			fileList3: [],
			fileList4: [],
			fileList5: [],
			fileList6: [],
			app_apply: {
				name: "",
				code: "",
				legal_persona_wechat: "",
				legal_persona_name: "",
				app_name: "",
				app_icon: "",
				license: ""
			}
		});
	};
	createOrUpdateAppApply = async data => {
		let {
			name,
			code,
			legal_persona_wechat,
			legal_persona_name,
			app_name
		} = data;
		let app_apply = {
			name,
			code,
			legal_persona_wechat,
			legal_persona_name,
			app_name,
			license: this.state.fileList[0].url,
			app_icon: this.state.fileList2[0].url,
			trad_reg_cert: this.state.fileList3.length ? this.state.fileList3[0].url : null,
			trad_auth_use: this.state.fileList4.length ? this.state.fileList4[0].url : null,
			mini_shop_auth: this.state.fileList5.length ? this.state.fileList5[0].url : null,
			law_cert: this.state.fileList6.length ? this.state.fileList6[0].url : null,
		};
		if (this.state.app_apply.app_apply_id) {
			app_apply.app_apply_id = this.state.app_apply.app_apply_id;
		}
		if (
			this.state.firstCity === this.state.defaultValue ||
			this.state.secondCity === this.state.defaultValue
		) {
			message.warning("请选择服务类目");
			return;
		}
		let categories = {
			first: this.state.firstCity,
			second: this.state.secondCity,
			certicates: []
		},
			inner_list = [],
			certicates_list = [],
			qualifyFile = this.state.qualifyFile;
		if (this.state.exter_list.length) {
			inner_list = this.state.exter_list[this.state.qualify_index].inner_list;
		}
		for (let i = 0; i < inner_list.length; i++) {
			let item = inner_list[i],
				value_arr = qualifyFile[item.name];
			if (value_arr.length === 0) {
				message.warning("请上传" + item.name + "文件");
				break;
			}
			let obj = {
				key: item.name,
				value: value_arr[0].url
			};
			certicates_list.push(obj);
		}
		if (inner_list.length && certicates_list.length === 0) {
			return;
		}
		categories.certicates = certicates_list;
		app_apply.categories = [categories];
		this.setState({
			confirmLoading: true
		});
		let res = await createOrUpdateAppApply({ app_apply });
		this.setState({
			confirmLoading: false
		});
		if (res.success) {
			// 清楚数据
			this.clearFormData();
			// 关闭填写资料的弹窗
			this.setStep(0);
			// 弹出法人验证提醒弹窗
			message.success(app_apply.app_apply_id ? "编辑成功" : "创建成功", 1, () => {
				this.setState({
					veriRemVisible: true,
				});
			});
		} else {
			return message.error(`出了点小问题${res.msg}`);
		}
	};
	createAppCreateOrder = async app_apply_id => {
		let return_url = window.location.href;
		let res = await createAppCreateOrder({
			admin_Order: {
				app_apply_id
			},
			return_url
		});
		if (res.result) {
			let code_url = JSON.parse(res.result.pay_form).code_url;
			this.setState(
				{
					qr_code: code_url,
					admin_order_id: res.result.admin_order_id
				},
				() => this.showModal()
			);
		}
	};
	async queryAdminOrderDetail(data) {
		let res = await queryAdminOrderDetail(data);
		if (res.success) {
			if (
				+res.result.admin_order.order_status === 2 ||
				+res.result.admin_order.order_status === 3
			) {
				message.success("充值成功");
				this.handleCancel();
			}
		}
	}
	showModal = () => {
		this.setStep(3);
		let allTime = 60 * 30;
		this.timer = setInterval(() => {
			--allTime;
			this.setState({
				less_time:
					this.returnNum(Math.floor(allTime / 60)) +
					":" +
					this.returnNum(allTime % 60)
			});
			let data = {
				admin_order_id: this.state.admin_order_id
			};
			this.queryAdminOrderDetail(data);
			if (allTime === 0) {
				clearInterval(this.timer);
			}
		}, 1000);
	};
	returnNum = num => {
		return num < 10 ? "0" + num : num;
	};

	onShowSizeChange = (current, page_size) => {
		this.setState({
			page_size
		});
		setTimeout(() => {
			this.queryAppApplyList();
		});
	};
	onShowPageChange = page_no => {
		this.setState({
			page_no
		});
		setTimeout(() => {
			this.queryAppApplyList();
		});
	};
	addUserHandle = () => {
		this.setState({ visible: true });
	};
	handleOk = e => {
		clearInterval(this.timer);
		this.setStep(0);
	};
	handleCancel = () => {
		clearInterval(this.timer);
		this.setStep(0);
	};
	handleCreate = () => {
		const form = this.formRef.props.form;
		form.validateFields((err, values) => {
			if (err) {
				return;
			}
			if (this.state.fileList.length === 0) {
				message.warning("请上传营业执照照片");
				return;
			}
			if (this.state.fileList2.length === 0) {
				message.warning("请上传小程序头像");
				return;
			}
			this.createOrUpdateAppApply(values);
		});
	};
	setStep = num => {
		this.setState({ step: num });
	};
	saveFormRef = formRef => {
		this.formRef = formRef;
	};
	onChangeRadio = e => {
		this.setState({
			radio_value: e.target.value
		});
	};
	async getPreAuthUrl() {
		let res = await getPreAuthUrl();
		if (res.success) {
			let url =
				"https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid=" +
				config.component_appid +
				"&pre_auth_code=preAuthCode&redirect_uri=" +
				config.frontUrl +
				"&auth_type=3";
			url = url.replace("preAuthCode", res.result.pre_auth_code);
			window.open(url);
		}
	}
	handleCancel2 = () => this.setState({ previewVisible: false });
	handlePreview = file => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true
		});
	};
	handleChange1 = info => {
		let fileList = info.fileList;
		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.result.oss_file_url;
			}
			return file;
		});
		this.setState({ fileList });
	};
	handleChange2 = info => {
		let fileList = info.fileList;
		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.result.oss_file_url;
			}
			return file;
		});
		this.setState({ fileList2: fileList });
	};
	handleChange3 = info => {
		let fileList = info.fileList;
		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.result.oss_file_url;
			}
			return file;
		});
		this.setState({ fileList3: fileList });
	};
	handleChange4 = info => {
		let fileList = info.fileList;
		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.result.oss_file_url;
			}
			return file;
		});
		this.setState({ fileList4: fileList });
	};
	handleChange5 = info => {
		let fileList = info.fileList;
		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.result.oss_file_url;
			}
			return file;
		});
		this.setState({ fileList5: fileList });
	};
	handleChange6 = info => {

		let fileList = info.fileList;
		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.result.oss_file_url;
			}
			return file;
		});
		this.setState({ fileList6: fileList });
	};
	handleChangeQualifyFile = (name, info) => {
		let fileList = info.fileList;
		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.result.oss_file_url;
			}
			return file;
		});
		let qualifyFile = this.state.qualifyFile;
		qualifyFile[name] = fileList;
		this.setState({ qualifyFile });
	};
	handleChangeQualifyRadio = e => {
		this.setState({
			qualify_index: e.target.value
		});
	};
	goBasicInfo() {
		this.context.router.history.push('/basicInfo');
	}
	goAppOrder() {
		this.context.router.history.push('/appOrder');
	}
	seeHelp() {
		window.open('http://udh.oss-cn-hangzhou.aliyuncs.com/717f8453-c0f9-45bd-ab79-fcc9dc145972');
	}
	// 跳转支付设置
	merchantAdd() {
		this.context.router.history.push("/merchantAdd");
	}
	async releaseLastVersion(record) {
		const url = '/mini_app_audit/releaseLastVersion';
		const data = {
			mini_app_id: record.mini_app_id,
			mini_app_audit_id: record.mini_app_audit_id,
		};
		let res = await getData(url, data);
		if (res.success) {
			this.queryAppApplyList();
			this.queryAllCategories(cookie.load("mini_app_id"));
		}
	}
	goDash() {
		this.setState({ veriRemVisible: false });
		// 如果是开店指引跳转过来的，统一跳回开店指引
		if (this.props.location.state && this.props.location.state.step === 2) {
			this.context.router.history.push("/dashboard");
		} else {
			this.queryAppApplyList();
		}
	}
	render() {
		let { menu_code } = this.props;
		return (
			<div className="applet-manage">
				<div className="content">
					<NavBar
						icon="icon-shezhi"
						child={menu_code.applet_manage}
					/>
					<div className="applet-manage-content">
						<div className="filter-content">
							<i className="iconfont icon-xiaochengxu" />
							<div className="text-content">
								<p className="title">添加小程序</p>
								<p>①选择一种方式授权小程序，授权完成后将自动绑定为店铺。</p>
								<p>②授权成功后，请在【支付设置】中设置支付方式</p>
							</div>
							<div className="btnWrap">
								<div className="btnItem">
									<span className="btnLabel">已有小程序</span>
									<Button
										type="primary"
										onClick={this.getPreAuthUrl}
									>
										直接授权
                  					</Button>
								</div>
								<div className="btnItem">
									<span className="btnLabel">无小程序</span>
									<Button
										type="primary"
										onClick={this.startToCreate}
									>
										代理注册
                  					</Button>
								</div>
							</div>
						</div>
						<div className="table-content">
							<div className="table-header">我的小程序：</div>
							<Table
								loading={this.state.loading}
								rowKey={(record, index) => index}
								columns={this.state.columns}
								dataSource={this.state.applet_list}
								pagination={false}
								bordered={true}
							/>
							<Pagination
								className="pagenation"
								onChange={this.onShowPageChange}
								onShowSizeChange={this.onShowSizeChange}
								defaultCurrent={this.state.page_no}
								total={this.state.total}
								showTotal={total => `共 ${total} 条`}
								pageSizeOptions={["10", "30", "50", "100"]}
								showSizeChanger
							/>
						</div>
					</div>
				</div>
				<Modal
					className="recharge-modal"
					title="微信支付"
					visible={this.state.step === 3}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					okButtonProps={{ shape: 'round' }}
					cancelButtonProps={{ shape: 'round' }}
				>
					<div className="wxscan">
						<div className="scan">
							<div className="tip" />
							<div className="codeimg">
								<QrCode value={this.state.qr_code} size={220} />
							</div>

							<p className="tip2">
								为避免系统自动取消订单，请在
                <i id="order-countdown" className="s-fc6">
									{this.state.less_time}
								</i>
                内进行付款
              </p>
						</div>
						<div className="rphone" />
					</div>
				</Modal>
				<Modal
					visible={this.state.step === 1}
					footer={null}
					className="modal1"
					onCancel={this.handleCancel}
					okButtonProps={{ shape: 'round' }}
					cancelButtonProps={{ shape: 'round' }}
				>
					<div className="modal-top">
						<div>
							<i className="iconfont icon-xiaochengxu" />
						</div>
						<div className="title">授权小程序</div>
						<div>请选择以下一种方式授权小程序，授权完成后将自动绑定为店铺</div>
					</div>
					<div className="radio-content">
						<RadioGroup
							onChange={this.onChangeRadio}
							value={this.state.radio_value}
						>
							<Radio value={1}>已有小程序，直接授权</Radio>
							<Radio value={2}>无小程序，立即注册</Radio>
						</RadioGroup>
					</div>
					{this.state.radio_value === 1 ? (
						<div className="btn-content">
							<Button shape="round" type="primary" onClick={this.getPreAuthUrl}>
								立即授权
              				</Button>
						</div>
					) : (
							<div className="btn-content">
								<Button shape="round" type="primary" onClick={this.startToCreate}>
									立即注册
              					</Button>
							</div>
						)}
				</Modal>
				<CollectionCreateForm
					qualify_index={this.state.qualify_index}
					qualifyFile={this.state.qualifyFile}
					provinceData={this.state.provinceData}
					cities={this.state.cities}
					exter_list={this.state.exter_list}
					firstCity={this.state.firstCity}
					secondCity={this.state.secondCity}
					app_apply={this.state.app_apply}
					wrappedComponentRef={this.saveFormRef}
					fileList={this.state.fileList}
					fileList2={this.state.fileList2}
					fileList3={this.state.fileList3}
					fileList4={this.state.fileList4}
					fileList5={this.state.fileList5}
					fileList6={this.state.fileList6}
					visible={this.state.step === 2}
					confirmLoading={this.state.confirmLoading}
					err_code={this.state.err_code}
					onCancel={this.handleCancel}
					onCreate={this.handleCreate}
					onChange1={this.handleChange1}
					onChange2={this.handleChange2}
					onChange3={this.handleChange3}
					onChange4={this.handleChange4}
					onChange5={this.handleChange5}
					onChange6={this.handleChange6}
					onPreview={this.handlePreview}
					onProvinceChange={this.handleProvinceChange}
					onSecondCityChange={this.handleSecondCityChange}
					onChangeQualifyFile={this.handleChangeQualifyFile}
					onChangeQualifyRadio={this.handleChangeQualifyRadio}
				/>
				<Modal
					visible={this.state.previewVisible}
					footer={null}
					onCancel={this.handleCancel2}
					okButtonProps={{ shape: 'round' }}
					cancelButtonProps={{ shape: 'round' }}
				>
					<img
						alt="example"
						style={{ width: "100%" }}
						src={this.state.previewImage}
					/>
				</Modal>
				<Modal
					title="温馨提示"
					okText="我知道了"
					cancelText="关闭"
					visible={this.state.veriRemVisible}
					onCancel={this.goDash.bind(this)}
					onOk={this.goDash.bind(this)}
				>
					<p>审核时长5分钟左右，法人微信将收到【验证消息】，请在24h之内进行验证否则将注册失效，需重新审核。</p>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		menu_code: state.menuCode
	};
};

const AppletManageForm = Form.create({ name: "applet_manage_form" })(
	AppletManage
);

export default connect(mapStateToProps)(AppletManageForm);
