import React from 'react'
import NavBar from '@/view/component/common/NavBar.js'
import {Tabs} from 'antd'
import {connect} from "react-redux";
import '@css/bonusSettle.scss'
import BonusSettleContent from '@/view/component/Money/BonusSettleContent'
import {queryShopBonusSummaryDetail} from '@service/getData'
import cookie from "react-cookies";
class  BonusSettle extends React.Component{
    constructor(props){
        super(props)
        this.state={
            balance:null,
            reserve_balance:0
        }
    }
    async queryShopBonusSummaryDetail(){
        if(!cookie.load("shop_id")&&+cookie.load("is_admin")===1){
            return
        }
        let data={
            shop_id:cookie.load("shop_id")
        }
        let res=await queryShopBonusSummaryDetail(data)
        if(res.success){
            this.setState({
                balance:res.result.shop_bonus_summary.balance/100,
                reserve_balance:res.result.shop_bonus_summary.reserve_balance/100
            })
        }
    }
    queryBalance=()=>{
        this.queryShopBonusSummaryDetail();
    }
    componentDidMount(){
        this.queryBalance();
    }
    render(){
        let {menu_code} = this.props
        return(
            <div className="bonus-settle">
                <NavBar icon="icon-zichan" child={menu_code.bonus_settle}/>
                <div className="content">
                    <div className="bonus-tab">
                        <Tabs defaultActiveKey="1" >
                            <Tabs.TabPane tab="未审核佣金" key="1">
                                <BonusSettleContent approve_status={0}  refreshBalance={this.queryBalance}/>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="已审核佣金" key="2">
                                <BonusSettleContent approve_status={1} refreshBalance={this.queryBalance} />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        menu_code:state.menuCode

    }
}
export default connect(mapStateToProps) (BonusSettle);
