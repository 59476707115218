import React from 'react'
import '@css/campaignShow.scss'
class CampaignIconLinkList extends React.Component{
    constructor(){
        super()
        this.state={
            style:[
                'big-icon-link-list',
                'big-icon-link-list',
                'medium-icon-link-list',
                'small-icon-link-list',
                'small-icon-link-list'
            ]
        }
    }
    render(){
        return (
            <div className={`campagin-icon-link-list ${this.state.style[this.props.activity_list.length-1]}`}>
                {
                    this.props.activity_list.map((item,index)=>(
                        <div className="box" key={index}>
                            <img alt="" className="icon-link-img" src={item.thumbnail_url}/>
                            <div className="campagin-icon-link-list-title">{item.activity_name}</div>
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default CampaignIconLinkList
