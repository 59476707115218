import React from "react";
import { connect } from "react-redux";
import NavBar from "@/view/component/common/NavBar.js";
import "@css/merchantManage.scss";
import { Form, Table, Button, Pagination } from "antd";
import cookie from "react-cookies";
import PropTypes from "prop-types";
import { queryMchApplyList } from "@service/getData";

class MerchantManage extends React.Component {
	constructor(props) {
		super();
		MerchantManage.contextTypes = {
			router: PropTypes.object.isRequired
		};
		this.state = {
			total: 0,
			page_no: 1,
			page_size: 10,
			merchant_list: [],
			loading: false,
			apply_status: {
				1: "未审核",
				2: "审核中",
				3: "审核失败",
				4: "已入驻"
			},
			apply_status_tips: {
				1: "待DTC Mall向微信提交申请。",
				2: "资料审核中，微信审核需要1~5天。",
				3: "微信审核失败，请修改资料重新提交。",
				4: "微信审核成功，微信商户已成功入驻。"
			},
			columns: [
				{
					title: "微信支付商户全称",
					dataIndex: "mch_short_name"
				},
				{
					title: "商户号",
					dataIndex: "mch_id"
				},
				{
					title: '更新时间',
					dataIndex: 'gmt_modified'
				},
				{
					title: '状态',
					dataIndex: 'pay_apply_status',
					render: (text, record) => {
						if (text === 2 || text === 1) {
							return (
								<span style={{ color: '#0066FF' }}>审核中</span>
							)
						}
						if (text === 3) {
							return (
								<span style={{ color: '#FF0000' }}>审核失败</span>
							)
						}
						return (
							<span style={{ color: '#00CC66' }}>已入驻</span>
						)
					}
				},
				{
					title: '详情',
					dataIndex: 'err_msg',
					render: (text, row) => {
						const status = row.pay_apply_status;
						const code = row.mch_apply_err_code;
						if (status === 1 || status === 2) {
							return (
								<>
									<span>微信扫码核对申请单信息</span>
									<img style={{ width: '160px', height: '160px' }} src={row.apply_qr_code} alt="二维码" />
								</>
							)
						}
						if (status === 3) {
							if (code === 1) {
								return (
									<span>未知原因，请联系客服</span>
								)
							}
							if (code === 2) {
								return (
									<span>
										资料有误
										<span className="seeHelp" onClick={this.updateAppApply.bind(this, row.mch_apply_id)}>修改资料</span>
									</span>
								)
							}
							if (code === 3) {
								return (
									<span>
										超过7天未验证，需重新提交申请
										<span className="seeHelp" onClick={this.updateAppApply.bind(this, row.mch_apply_id)}>修改资料</span>
									</span>
								)
							}
						}
						return (
							<span>将为授权的微信小程序开通微信支付</span>
						)
					}
				},
				{
					title: "已关联小程序",
					dataIndex: "mini_app_name"
				},
			]
		};
	}

	componentDidMount() {
		this.queryMchApplyList();
	}

	queryMchApplyList = async () => {
		this.setState({
			loading: true
		});
		let res = await queryMchApplyList({
			page_no: this.state.page_no,
			page_size: this.state.page_size
		});
		cookie.save("mch_apply_num", res.result.total);
		if (!res.result.total) {
			cookie.save("defaultSelectedKeys", "merchantManage");
		} else {
			if (cookie.load("defaultSelectedKeys") !== "0") {
				window.location.reload();
			}
			cookie.save("defaultSelectedKeys", "0");
		}
		this.setState({
			merchant_list: [...res.result.list],
			total: res.result.total,
			loading: false,
		});
	};
	onShowSizeChange = (current, page_size) => {
		this.setState({
			page_size
		});
		setTimeout(() => {
			this.queryMchApplyList();
		});
	};
	onShowPageChange = page_no => {
		this.setState({
			page_no
		});
		setTimeout(() => {
			this.queryMchApplyList();
		});
	};
	// 编辑
	updateAppApply = mch_apply_id => {
		this.context.router.history.push(
			`/merchantAdd?mch_apply_id=${mch_apply_id}`
		);
	};
	goMerchantAdd = () => {
		this.context.router.history.push("/merchantAdd");
	};
	componentWillUnmount() {
		this.setState = () => false;
	}
	render() {
		let { menu_code } = this.props;
		return (
			<div className="merchant-manage">
				<div className="content">
					<NavBar
						icon="icon-shezhi"
						child={menu_code.manage_manage}
					/>
					<div className="merchant-manage-content">
						<div className="filter-content">
							<div className="icon-content">
								<i className="iconfont icon-ren" />
							</div>
							<div className="text-content">
								<div className="title">
									支付设置-微信支付
                				</div>
								<div>
									①申请商户号
									<br />
									②等待商户号与小程序关联
									<br />
									注意：一个公司主体只能创建一个商户号；申请微信商户后，店铺所产生的资金流水将直接进入您的微信商户中，T+1结算。
                				</div>
							</div>
							<Button shape="round" type="primary" onClick={this.goMerchantAdd}>
								创建商户号
              				</Button>
						</div>
						<div className="table-content">
							<div className="table-header">我的商户号：</div>
							<Table
								loading={this.state.loading}
								rowKey={(record, index) => index}
								columns={this.state.columns}
								dataSource={this.state.merchant_list}
								pagination={false}
								bordered={true}
							/>
							<Pagination
								className="pagenation"
								onChange={this.onShowPageChange}
								onShowSizeChange={this.onShowSizeChange}
								defaultCurrent={this.state.page_no}
								total={this.state.total}
								showTotal={total => `共 ${total} 条`}
								pageSizeOptions={["10", "30", "50", "100"]}
								showSizeChanger
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		menu_code: state.menuCode
	};
};

const MerchantManageForm = Form.create({ name: "merchant_manage_form" })(
	MerchantManage
);

export default connect(mapStateToProps)(MerchantManageForm);
