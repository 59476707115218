import React from 'react'
import PropTypes from 'prop-types'
import '@css/goodsGroupAdd.scss'
import { Form, Collapse, Icon, Checkbox, Input, Table, Modal, message, DatePicker } from "antd";
import cookie from "react-cookies";
import { queryUnopenedGoodsList, queryCommunityUserList, createGroupOpenRecord, onsaleGoodsList } from '@service/getData'
import { formatNumber } from '@common/common'
class GoodsGroupAdd extends React.Component {
    constructor() {
        super()
        this.state = {
            selectedLeaderRowKeys: [],
            selectedLeaderRows: [],
            selectedGoodsRowKeys: [],
            selectedGoodsRows: [],
            can_group_shipment: false,
            group_shipment_fee: null,
            can_self_shipment: false,
            self_shipment_fee: null,
            chooseLeaderColumns: [
                {
                    title: '社群名称',
                    dataIndex: 'community_name'
                },
                {
                    title: '团长姓名',
                    dataIndex: 'nick_name'
                },
                {
                    title: '电话',
                    dataIndex: 'mobile'
                }
            ],
            chooseLeaderData: [],
            leaderVisble: false,
            goodsVisble: false,
            leaderColumns: [
                {
                    title: '社群名称',
                    dataIndex: 'community_name'
                },
                {
                    title: '团长姓名',
                    dataIndex: 'nick_name'
                },
                {
                    title: '电话',
                    dataIndex: 'mobile'
                }
            ],
            leaderData: [],
            chooseGoodsColumns: [
                {
                    title: '开团商品',
                    width: 300,
                    dataIndex: 'goods_name',
                    render: (text, record) => (
                        <div className="goods-info">
                            <div className="goods-info-img">
                                <img alt="" width="60" height="60" src={JSON.parse(record.gallery_urls)[0]} />
                            </div>
                            <div className="goods-info-detail">
                                <div className="goods-info-name" title={record.goods_name}>
                                    {record.goods_name}
                                </div>
                                <div>
                                    ￥{record.current_price / 100}
                                </div>
                            </div>
                        </div>
                    ),
                },
                {
                    title: '商品ID',
                    dataIndex: 'goods_id',

                },
                {
                    title: '库存',
                    dataIndex: 'balance',

                }
            ],
            chooseGoodsData: [],
            goodsColumns: [
                {
                    title: '开团商品',
                    width: 500,
                    dataIndex: 'goods_name',
                    render: (text, record) => (
                        <div className="goods-info">
                            <div className="goods-info-img">
                                <img alt="" width="60" height="60" src={JSON.parse(record.gallery_urls)[0]} />
                            </div>
                            <div className="goods-info-detail">
                                <div className="goods-info-name" title={record.goods_name}>
                                    {record.goods_name}
                                </div>
                                <div>
                                    ￥{record.current_price / 100}
                                </div>
                            </div>
                        </div>
                    ),
                },
                {
                    title: '商品ID',
                    width: 200,
                    dataIndex: 'goods_id',

                },
                {
                    title: '库存',
                    dataIndex: 'balance',
                    width: 180,

                }],
            goodsData: [],
            goods_id: null,
            mobile: null,
            not_group_goods: 0,
            publish_start_date: null,
            publish_end_date: null

        }

        GoodsGroupAdd.contextTypes = {
            router: PropTypes.object.isRequired
        }
    }
    onShowTimeChange = (date, dateStr) => {
        this.setState({
            publish_start_date: dateStr[0],
            publish_end_date: dateStr[1],
        })
    }

    onChangeShipmentType = (checkedList) => {
        let can_group_shipment = false, can_self_shipment = false;

        checkedList.forEach(item => {
            if (item === 2) {
                can_self_shipment = true
            }
            else if (item === 1) {
                can_group_shipment = true
            }
        })
        this.setState({
            can_group_shipment: can_group_shipment,
            can_self_shipment: can_self_shipment,
        })

    }
    handleLeader = (e) => {
        this.setState({
            leaderVisble: true,
        });
    }

    handleLeaderOk = (e) => {
        let arr = this.state.leaderData.concat(this.state.selectedLeaderRows);
        let data = Array.from(new Set(arr));
        this.setState(prevState => ({
            leaderVisble: false,
            leaderData: data
        }))
    }

    handleLeaderCancel = (e) => {
        this.setState({
            leaderVisble: false,
        });
    }

    handleGoods = (e) => {
        this.setState({
            goodsVisble: true,
        });
    }

    handleGoodsOk = (e) => {
        let arr = this.state.goodsData.concat(this.state.selectedGoodsRows);
        let data = Array.from(new Set(arr));
        this.setState(prevState => ({
            goodsVisble: false,
            goodsData: data
        }))

    }

    handleGoodsCancel = (e) => {
        this.setState({
            goodsVisble: false,
        });
    }

    async queryUnopenedGoodsList(goods_id) {
        let data = {
            shop_id: cookie.load("shop_id"),
        }
        if (goods_id) {
            data.goods_id = goods_id
        }
        if (this.state.not_group_goods) {
            data.not_group_goods = this.state.not_group_goods
        }

        let res = await queryUnopenedGoodsList(data)
        if (res.success) {
            this.setState({
                chooseGoodsData: res.result.list,
                goodsData: res.result.list,
            })
        }

    }

    async onsaleGoodsList(data, group) {
        let onsaledata = {
            goods_id_list: data.goods_ids,
            onsale_status: 1
        }
        let res = await onsaleGoodsList(onsaledata);
        if (res.success) {
            this.createGroupOpenRecord({ group_open_record: data }, group);
        } else {
            message.error(res.msg)
        }
    }
    async queryCommunityUserList() {
        let data = {
            shop_id: cookie.load("shop_id")

        }
        if (this.state.mobile) {
            data.mobile = this.state.mobile
        }
        let res = await queryCommunityUserList(data)
        if (res.success) {
            this.setState({
                chooseLeaderData: res.result.list
            })
        }
    }

    async createGroupOpenRecord(data, group) {

        let res = await createGroupOpenRecord(data);
        if (res.success) {
            message.success(group === 'noGroup' ? '商品上架成功' : "上架并成功开团");
            this.setState({
                can_group_shipment: false,
                group_shipment_fee: null,
                can_self_shipment: false,
                self_shipment_fee: null,
                leaderData: [],
                goodsData: [],
                selectedLeaderRowKeys: []
            })
            this.props.form.resetFields();
            this.props.handleFalse();
        }
    }

    onSelectLeaderChange = (selectedLeaderRowKeys, selectedLeaderRows) => {
        this.setState({
            selectedLeaderRowKeys,
            selectedLeaderRows
        });
    }

    onSelectGoodsChange = (selectedGoodsRowKeys, selectedGoodsRows) => {
        this.setState({
            selectedGoodsRowKeys,
            selectedGoodsRows
        });
    }
    handelGoodsDel = (index) => {
        let goodsData = this.state.goodsData;
        let selectedGoodsRowKeys = this.state.selectedGoodsRowKeys;

        goodsData.splice(index, 1)
        selectedGoodsRowKeys.splice(index, 1)
        this.setState({
            goodsData: goodsData,
            selectedGoodsRowKeys: selectedGoodsRowKeys

        })
    }
    handelLeaderDel = (index) => {
        let leaderData = this.state.leaderData;
        let selectedLeaderRowKeys = this.state.selectedLeaderRowKeys;
        leaderData.splice(index, 1)
        selectedLeaderRowKeys.splice(index, 1)
        this.setState({
            leaderData: leaderData,
            selectedLeaderRowKeys: selectedLeaderRowKeys

        })
    }
    componentDidMount() {
        this.props.onRef && this.props.onRef(this)
    }
    UNSAFE_componentWillReceiveProps(data) {
        if (data.goods_id) {
            this.queryUnopenedGoodsList(data.goods_id)
        }
        if (data.goods_list) {
            this.setState({
                chooseGoodsData: data.goods_list,
                goodsData: data.goods_list,
            })
        }
        if (data.visible) {
            this.queryCommunityUserList()
        }
    }


    changeGoodsId = (e) => {
        this.setState({
            goods_id: e.target.value
        }, () => {
            this.queryUnopenedGoodsList()
        })


    }

    changeNotGroupGoods = (e) => {
        this.setState(prevState => ({
            not_group_goods: prevState.not_group_goods === 1 ? 0 : 1
        }), () => {
            this.queryUnopenedGoodsList()
        })

    }
    changeMobile = (e) => {
        this.setState({
            mobile: e.target.value
        }, () => {
            this.queryCommunityUserList()
        })
    }
    clearGoods = () => {
        this.setState({
            goodsData: [],
            selectedGoodsRowKeys: [],
            selectedGoodsRows: []
        })

    }
    createGroup = (group) => {
        let form = this.props.form.getFieldsValue();
        let data = {
            shop_id: cookie.load("shop_id")
        }
        if (!this.state.goodsData.length) {
            return message.info("请选择商品")
        }
        data.goods_ids = this.state.goodsData.map(item => item.goods_id);

        if (!this.state.leaderData.length && cookie.load("mini_app_type") !== "flagship_version") {
            return message.info("请选择团长")
        }

        data.is_all_leader = 2
        if (cookie.load("mini_app_type") === "flagship_version") {
            data.community_ids = [cookie.load("default_community_id")]
            data.group_type = 2
        } else {
            data.community_ids = this.state.leaderData.map(item => item.community_id)
        }
        if (!form.shipment_type.length) {
            return false
        }
        if (form.shipment_type.indexOf(1) > -1 && form.shipment_type.indexOf(2) > -1) {
            data.group_type = 3
        } else if (form.shipment_type.indexOf(2) > -1) {
            data.group_type = 2
        } else if (form.shipment_type.indexOf(1) > -1) {
            data.group_type = 1
        }
        if (this.state.publish_start_date) {
            data.start_time = this.state.publish_start_date;
        }
        if (this.state.publish_end_date) {
            data.expire_time = this.state.publish_end_date;
        }
        this.onsaleGoodsList(data, group)


    }

    changeGroupShipmentFee = (e) => {
        this.setState({
            group_shipment_fee: e.target.value
        })
    }
    changeSelfShipmentFee = (e) => {
        this.setState({
            self_shipment_fee: formatNumber(e.target.value)
        })
    }
    handelFalse = () => {
        this.setState({
            selectedLeaderRowKeys: [],
            selectedLeaderRows: []
        }, () => { this.props.handleFalse() })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { selectedLeaderRowKeys } = this.state;
        const rowSelection = {
            selectedLeaderRowKeys,
            onChange: this.onSelectLeaderChange,
            hideDefaultSelections: true,
        };
        return (
            <Modal className="goods-group-add"
                visible={this.props.visible}
                onOk={this.createGroup}
                title="开团"
                onCancel={this.handelFalse}
                closable={false}
                destroyOnClose={true}
                okButtonProps={{ shape: 'round' }}
                cancelButtonProps={{ shape: 'round' }}
            >
                <div className="content">
                    <div className="goods-title">
                        <i className="title-sign"></i>
                        添加商品基本信息
                    </div>
                    <Collapse defaultActiveKey={['1', '2', '3', '4']}
                        expandIcon={({ isActive }) => <Icon className="caret" type="caret-right"
                            rotate={isActive ? 90 : 0} />}>
                        <Collapse.Panel header={
                            <div className="panel-title">
                                选择商品(必填)
                            </div>
                        } key="1">
                            <Table rowKey={(record, index) => index}
                                columns={this.state.goodsColumns}
                                dataSource={this.state.goodsData}
                                pagination={false}
                                scroll={{ y: 240 }} />
                        </Collapse.Panel>
                        <Collapse.Panel header={
                            <div className="panel-title">
                                团类型/运费设置(必填)
                            </div>
                        } key="2">
                            <Form>
                                <Form.Item label="团类型">
                                    {getFieldDecorator('shipment_type', {
                                        initialValue: [2],
                                    })(
                                        <Checkbox.Group style={{ width: '100%' }} onChange={this.onChangeShipmentType}>
                                            <Checkbox value={2}>
                                                邮寄
                                            </Checkbox>
                                            <Checkbox value={1} style={{ display: cookie.load("mini_app_type") === "flagship_version" ? "none" : true }}>
                                                自提
                                            </Checkbox>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>
                            </Form>
                        </Collapse.Panel>
                        <Collapse.Panel style={{ display: cookie.load("mini_app_type") === "flagship_version" ? "none" : true }} header={
                            <div className="panel-title">
                                推送至团长(必填)
                            </div>
                        } key="3">
                            <Form>
                                <Form.Item label="推送至团长">
                                    <span onClick={this.handleLeader} className="text-note">选择团长</span>
                                </Form.Item>
                            </Form>
                        </Collapse.Panel>
                        <Collapse.Panel style={{ display: cookie.load("mini_app_type") === "flagship_version" ? "none" : true }} header={
                            <div className="panel-title" >
                                开团时间(非必填)
                            </div>
                        } key="4">
                            <Form>
                                <Form.Item label="开团时间">
                                    {getFieldDecorator('date')(
                                        <DatePicker.RangePicker className="form-input-long"
                                            onChange={this.onShowTimeChange}
                                            showTime
                                            style={{ marginTop: 10 }} />
                                    )}
                                </Form.Item>
                            </Form>
                        </Collapse.Panel>
                    </Collapse>
                </div>
                <Modal
                    title="选择团长"
                    visible={this.state.leaderVisble}
                    onOk={this.handleLeaderOk}
                    onCancel={this.handleLeaderCancel}
                    width={736}
                    className="leader-modal"
                    okButtonProps={{ shape: 'round' }}
                    cancelButtonProps={{ shape: 'round' }}

                >
                    <div>
                        <Form layout="inline" className="modal-form">
                            <Form.Item label="搜索团长">
                                <Input placeholder="请输入手机号" value={this.state.mobile} onChange={this.changeMobile} />
                            </Form.Item>
                        </Form>
                        <Table rowKey={(record, index) => record.community_id}
                            rowSelection={rowSelection}
                            columns={this.state.chooseLeaderColumns}
                            dataSource={this.state.chooseLeaderData}
                            pagination={false}
                            scroll={{ y: 240 }} />
                    </div>
                </Modal>
            </Modal>
        )

    }
}

const GoodsGroupAddForm = Form.create({ name: 'goods_group_add_form' })(GoodsGroupAdd);

export default GoodsGroupAddForm;
