import React, { Component } from 'react';
import './formTitle.scss';

class FormTitle extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="formTitle">
                <i className="titleSign"></i>
                <span className="title">{this.props.title}</span>
            </div>
        );
    }
}

export default FormTitle;