import React, { Fragment } from 'react'
import {
    Input,
    Radio,
    Checkbox,
    Form,
    Divider,
    DatePicker,
    Button,
    message,
    Table,
    Upload,
    Modal,
    Tooltip
} from 'antd'
import NavBar from '@/view/component/common/NavBar.js'
import { connect } from "react-redux";
import '@css/popUppromotion.scss'
import PopUpCoupon from './PopUpCoupon'
import cookie from "react-cookies";
import { createOrUpdateActivity, queryActivityDetail } from '@service/getData'
import moment from 'moment';
import CouponSelectModal from '@/view/component/Campaign/CouponSelectModal'
import TargetTypeModal from '@/view/component/Campaign/TargetTypeModal'
import UploadButton from "@/view/component/common/UploadButton";
import config from "@config/config";
import PropTypes from 'prop-types'

class CreateOrUpdatePopUp extends React.Component {
    constructor() {
        super()
        CreateOrUpdatePopUp.contextTypes = {
            router: PropTypes.object.isRequired
        }
        this.state = {
            requestUrl: config.baseUrl + "/ossFile/upload",
            visible: false,
            coupon_list: [],
            columns: [{
                title: '优惠券名称',
                dataIndex: 'coupon_name'
            }, {
                title: '优惠内容',
                dataIndex: 'use_min_amount',
                render: (text, record) => {
                    return `满${text / 100}减${record.discount_amount / 100}`
                }
            }, {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => <div className="text-note"
                    onClick={this.deleteCoupon.bind(this, record.coupon_id)}>删除</div>
            }],
            activity_res: {},
            activity_id: null,
            preview_gallery_visible: false,
            preview_gallery_visible2: false,
            gallery_list: [],
            preview_gallery_image: '',
            custom_style: 0,
            image_url: null,
            parent_id: null,
            type: null,
            ad_visible: false,
            target_type_obj: {},
            desc: null,
            target_type_desc: {
                lucky: "抽奖活动",
                checkin: "签到",
                pointsStore: "积分商城",
                free_order: "抢免单",
                rank_lucky: '线上拼团',
            },
        }
    }

    onCouponCancel = () => {
        this.setState({
            visible: false,
            ad_visible: false
        })
    }
    onShow = () => {
        this.setState({
            visible: true
        })
    }
    onCouponOK = (coupon_list) => {
        let current_coupon_list = this.state.coupon_list
        current_coupon_list.forEach(item => {
            let index = coupon_list.findIndex(row => row.coupon_id === item.coupon_id);
            if (index > -1) {
                coupon_list.splice(index, 1)
                item.status = 1
            }
        })
        coupon_list = current_coupon_list.concat(coupon_list)
        if (coupon_list.filter(item => item.status === 1).length > 10) {
            return message.error("最多添加10张优惠券")
        }
        coupon_list.forEach((item, index) => {
            item.index = index
        }
        )
        this.setState({
            visible: false,
            coupon_list
        }, () => {
            if (this.state.coupon_list.filter(item => item.status === 1).length < 1) {
                this.props.form.setFieldsValue({ "coupon": null })
            } else {
                this.props.form.setFieldsValue({ "coupon": true })
            }
        })
    }
    onOK = (data) => {
        this.props.form.setFieldsValue({ "target_type": data.target_type })
        if (data.target_type === 'coupon' || data.target_type === "activity_coupon") {
            data.dest_coupon_id = data.coupon_id
        }
        this.setState({
            ad_visible: false,
            target_type_obj: data,
            desc: data.desc
        })
    }
    deleteCoupon = (coupon_id) => {
        let coupon_list = [...this.state.coupon_list]
        let coupon = coupon_list.find(item => coupon_id === item.coupon_id)
        let index = coupon_list.findIndex(item => coupon_id === item.coupon_id)
        if (coupon.activity_coupon_id) {
            coupon.status = 0;
        } else {
            coupon_list.splice(index, 1)
        }
        coupon_list.forEach((item, index) => {
            item.index = index
        })
        this.setState({
            coupon_list
        }, () => {
            if (this.state.coupon_list.filter(item => item.status === 1).length < 1) {
                this.props.form.setFieldsValue({ "coupon": null })
            } else {
                this.props.form.setFieldsValue({ "coupon": true })
            }
        })
    }
    createOrUpdateActivity = async () => {
        let form = this.props.form.getFieldsValue();
        let flag = false, length = this.state.coupon_list.filter(item => item.status === 1).length;
        if (length < 1) {
            this.props.form.setFieldsValue({ "coupon": null })
        } else {
            this.props.form.setFieldsValue({ "coupon": true })
        }
        if ((this.state.type === 'adPopup' || this.state.custom_style === 1) && this.state.gallery_list.length === 0) {
            this.props.form.setFieldsValue({ "image_url": null })

        } else {
            this.props.form.setFieldsValue({ "image_url": true })
        }
        this.props.form.validateFields(err => {
            if (err) {
                flag = true
            }
        });
        if (flag) return
        if (this.state.custom_style === 1 && this.state.gallery_list.length === 0) {
            return message.error("请选择图片")
        }
        let image_url = 'http://udh.oss-cn-hangzhou.aliyuncs.com/27dd65fe-f8e1-46a1-ae94-8eb1d86cb44d';
        switch (this.state.type === 'adPopup' ? 1 : this.state.custom_style) {
            case 0:
                if (length > 1) image_url = "http://udh.oss-cn-hangzhou.aliyuncs.com/6fdd827c-fac6-4742-9adb-a70c5e74242c"
                break;
            case 1:
                if (this.state.gallery_list[0].url) {
                    image_url = this.state.gallery_list[0].url
                } else if (this.state.gallery_list[0].response) {
                    image_url = this.state.gallery_list[0].response.result.oss_file_url
                }
                break;
            default: break;
        }
        let data = {
            activity_req: {
                activity_id: this.state.activity_id,
                mini_app_id: cookie.load("mini_app_id"),
                activity_type: "popup",
                activity_name: form.activity_name,
                start_time: form.date[0].format('YYYY-MM-DD HH:mm:ss'),
                end_time: form.date[1].format('YYYY-MM-DD HH:mm:ss'),
                target_type: this.state.type === 'adPopup' ? form.target_type : 'couponList',
                user_type: form.user_type || 'all_user',
                activity_subtype: 'homePopup',
                custom_style: this.state.custom_style,
                parent_id: this.state.parent_id,
                activity_coupon_req_list: this.state.coupon_list,
                image_url,
                ...this.state.target_type_obj
            }
        }

        let res = await createOrUpdateActivity(data);
        if (res.success) {
            message.success(this.state.activity_id ? "编辑成功" : '创建成功')
            this.goToPopUpList()
        } else {
            message.error(res.msg)
        }

    }
    queryActivityDetail = async () => {
        let data = {
            activity_id: this.state.activity_id
        }
        let res = await queryActivityDetail(data)
        if (res.success) {
            let { activity_coupon_res_list } = res.result.activity_res
            let coupon_list = activity_coupon_res_list.map(item => {
                return {
                    activity_coupon_id: item.activity_coupon_id,
                    coupon_id: item.coupon_id,
                    ...item.coupon_res
                }
            })
            let activity_res = res.result.activity_res
            let gallery_list = []
            if (res.result.activity_res.image_url && (res.result.activity_res.custom_style === 1 || this.state.type === "adPopup")) {
                gallery_list = [
                    {
                        uid: '-1',
                        name: 'xxx.png',
                        status: 'done',
                        url: res.result.activity_res.image_url
                    },
                ];
            }
            let desc = ''
            if (this.state.type === "adPopup") {
                switch (activity_res.target_type) {
                    case 'campaign':
                        desc = `店铺页面:${activity_res.campaign_res.campaign_name}`
                        break;
                    case 'goods':
                        desc = `商品:${activity_res.goods_res.goods_name}`
                        break;
                    case 'tag':
                        desc = `商品列表页:${activity_res.tag_name}`
                        break;
                    case 'coupon':
                        desc = `优惠券:${activity_res.dest_coupon_res.coupon_name}`
                        break;
                    case 'activity_coupon':
                        desc = `满减/满赠:${activity_res.dest_coupon_res.coupon_name}`
                        break;
                    case 'activity_goods':
                        desc = `活动商品:${activity_res.dest_activity_goods_res.goods_res.goods_name}`
                        break;
                    default:
                        desc = `营销活动:${this.state.target_type_desc[activity_res.target_type]}`
                        break;
                }
            }
            this.setState({
                desc,
                activity_res: res.result.activity_res,
                coupon_list,
                custom_style: res.result.activity_res.custom_style,
                gallery_list,
                image_url: res.result.activity_res.custom_style === 1 || this.state.type === "adPopup" ? res.result.activity_res.image_url : null

            })
        }
    }
    handleGalleryChange = ({ fileList }) => {
        let image_url = null
        if (fileList.length === 1) {
            image_url = fileList[0].url || fileList[0].response ? fileList[0].response.result.oss_file_url : false || fileList[0]
        }
        this.setState({
            gallery_list: fileList,
            image_url
        })
    }
    showModal = () => {
        this.setState({
            ad_visible: true
        })
    }
    handleCancel = () => this.setState({
        visible: false,
        preview_gallery_visible: false
    });
    handleGalleryPreview = (file) => {
        this.setState({
            preview_gallery_image: file.url || file.response.result.oss_file_url || file,
            preview_gallery_visible: true,
        });
    }
    onchangeCustomType = (e) => {
        this.props.form.setFieldsValue({ "custom_style": e.target.value })
        this.setState({
            custom_style: e.target.value
        })
    }
    goToPopUpList = () => {
        this.context.router.history.push(`popUpPromotion?activity_id=${this.state.parent_id}`, this.state.type)
    }
    componentDidMount() {
        this.setState({
            activity_id: this.props.location.state.split(',')[0] !== "0" ? this.props.location.state.split(',')[0] : null,
            parent_id: this.props.location.state.split(',')[1],
            type: this.props.location.state.split(',')[2],
        }, () => {
            if (this.state.activity_id) {
                this.queryActivityDetail()
            }
        })
    }

    render() {
        let { menu_code } = this.props
        const { getFieldDecorator } = this.props.form;

        return (
            <div className="create-update-pop">
                <NavBar icon="icon-yingxiao"
                    father={menu_code.pop_up_promotion}
                    child={this.state.activity_id ? this.state.activity_res.popup_status === 0 || this.state.activity_res.popup_status === 3 ? "查看弹窗" : "编辑弹窗" : "创建弹窗"}
                    router={`popUpPromotion?activity_id=${this.state.parent_id}`}
                    state={this.state.type}
                />
                <div className="content">
                    <div className="shopDataTitle">
                        <i className="titleSign"></i>
                        {this.state.type === "couponList" ? "进店发券" : '图片广告'}弹窗信息
                    </div>
                    <Divider />
                    <main>
                        <Form>
                            <Form.Item label="弹窗名称"
                                extra={
                                    (<div className="form-upload-remind">15个字以内</div>)
                                }
                            >
                                {getFieldDecorator('activity_name', {
                                    initialValue: this.state.activity_res.activity_name,
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入弹窗名称"
                                        }
                                    ],

                                })(
                                    <Input className="form-item" maxLength={15}
                                        disabled={this.state.activity_res.popup_status === 0 || this.state.activity_res.popup_status === 3} />
                                )}
                            </Form.Item>
                            <Form.Item label="弹窗时间">
                                {getFieldDecorator('date', {
                                    initialValue: this.state.activity_res.start_time ? [moment(this.state.activity_res.start_time, 'YYYY-MM-DD HH:mm:ss'), moment(this.state.activity_res.end_time, 'YYYY-MM-DD HH:mm:ss')] : null,
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入弹窗时间"
                                        }
                                    ],
                                })(
                                    <DatePicker.RangePicker
                                        showTime
                                        format="YYYY-MM-DD HH:mm:ss"
                                        disabled={this.state.activity_id ? true : false} />
                                )}
                            </Form.Item>
                            {
                                this.state.type === 'adPopup' ? (<Fragment>
                                    <Form.Item label="跳转路径">
                                        {getFieldDecorator('target_type', {
                                            initialValue: this.state.activity_res.target_type,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请选择跳转路径"
                                                }
                                            ],
                                        })(
                                            <div>
                                                {this.state.desc === null ? (<span className="text-note"
                                                    onClick={this.showModal}>选择跳转路径</span>) : (
                                                        <div>
                                                            <span>
                                                                {this.state.desc}
                                                            </span>
                                                            {
                                                                (this.state.activity_res.popup_status === 0 || this.state.activity_res.popup_status === 3) || (this.state.activity_id !== null && this.state.desc === null) ? null :
                                                                    <span className="text-note"
                                                                        onClick={this.showModal}>修改</span>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )}
                                    </Form.Item>
                                    <Form.Item label="图片"
                                        extra={<div className="text-remind">
                                            建议尺寸为宽度600像素，高度800像素；若为氛围型广告，建议使用png图片
                                                   </div>}
                                    >
                                        {getFieldDecorator('image_url', {
                                            initialValue: null,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请上传图片"
                                                }
                                            ],
                                        })(
                                            <div className="iamge-upload">
                                                <Upload
                                                    disabled={this.state.activity_res.popup_status === 0 || this.state.activity_res.popup_status === 3}
                                                    accept="image/jpeg,image/jpg,image/png"
                                                    withCredentials={true}
                                                    action={this.state.requestUrl}
                                                    listType="picture-card"
                                                    fileList={this.state.gallery_list}
                                                    onPreview={this.handleGalleryPreview}
                                                    onChange={this.handleGalleryChange}
                                                >
                                                    {this.state.gallery_list.length >= 1 ? null : <UploadButton />}
                                                </Upload>
                                                <Tooltip
                                                    placement="bottom"
                                                    autoAdjustOverflow="false"
                                                    title={
                                                        <img alt=""
                                                            width="200px"
                                                            src="http://udh.oss-cn-hangzhou.aliyuncs.com/6e97982a-66ab-4e63-a301-ef5cd5800225" />
                                                    }>
                                                    <div className="photo-show">示例图</div>
                                                </Tooltip>
                                                <Modal visible={this.state.preview_gallery_visible} footer={null}
                                                    onCancel={this.handleCancel}>
                                                    <img alt="example" style={{ width: '100%' }}
                                                        src={this.state.preview_gallery_image} />
                                                </Modal>
                                            </div>
                                        )}
                                    </Form.Item>
                                </Fragment>) :
                                    <Fragment>
                                        <Form.Item label="优惠券">
                                            {getFieldDecorator('coupon', {
                                                initialValue: true,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请选择优惠券"
                                                    }
                                                ],
                                            })(
                                                <div>
                                                    <Button shape="round" type='primary' onClick={this.onShow}
                                                        disabled={this.state.activity_res.popup_status === 0 || this.state.activity_res.popup_status === 3}>选择优惠券</Button>
                                                    {
                                                        this.state.coupon_list.filter(item => item.status === 1).length > 0 ? (
                                                            <div className="coupon-list">
                                                                <Table
                                                                    scroll={{ y: 250 }}
                                                                    rowKey={record => record.coupon_id}
                                                                    title={() => {
                                                                        return (
                                                                            <div className="coupon-title">已选优惠券</div>)
                                                                    }}
                                                                    columns={this.state.columns}
                                                                    dataSource={this.state.coupon_list.filter(item => item.status === 1)}
                                                                    pagination={false}
                                                                />

                                                            </div>
                                                        ) : null
                                                    }

                                                </div>
                                            )}
                                        </Form.Item>
                                        <Form.Item label="样式"
                                            extra={
                                                this.state.custom_style === 1 ? (
                                                    <div className="text-remind">
                                                        建议尺寸600*800，仅支持 jpg、 png 2种格式, 图片大小不超过2MB
                                                    </div>
                                                ) : null
                                            }>
                                            {getFieldDecorator('custom_style', {
                                                initialValue: this.state.activity_res.custom_style ? this.state.activity_res.custom_style : 0,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请选择弹窗样式"
                                                    }
                                                ],
                                            })(
                                                <div>
                                                    <Radio.Group onChange={this.onchangeCustomType}
                                                        value={this.state.custom_style}
                                                        disabled={this.state.activity_res.popup_status === 0 || this.state.activity_res.popup_status === 3}>
                                                        <Radio value={0}>系统默认</Radio>
                                                        <Radio value={1}>自定义</Radio>
                                                    </Radio.Group>
                                                    {
                                                        this.state.custom_style === 1 ? (
                                                            <div className="iamge-upload">
                                                                <Upload
                                                                    disabled={this.state.activity_res.popup_status === 0 || this.state.activity_res.popup_status === 3}
                                                                    accept="image/jpeg,image/jpg,image/png"
                                                                    withCredentials={true}
                                                                    action={this.state.requestUrl}
                                                                    listType="picture-card"
                                                                    fileList={this.state.gallery_list}
                                                                    onPreview={this.handleGalleryPreview}
                                                                    onChange={this.handleGalleryChange}
                                                                >
                                                                    {this.state.gallery_list.length >= 1 ? null :
                                                                        <UploadButton />}
                                                                </Upload>

                                                                <Modal visible={this.state.preview_gallery_visible}
                                                                    footer={null}
                                                                    onCancel={this.handleCancel}>
                                                                    <img alt="example" style={{ width: '100%' }}
                                                                        src={this.state.preview_gallery_image} />
                                                                </Modal>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                            )}
                                        </Form.Item>
                                        <Form.Item label="推送人群">
                                            {getFieldDecorator('user_type', {
                                                initialValue: this.state.activity_res.user_type,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请选择推送人群"
                                                    }
                                                ],
                                            })(
                                                <Radio.Group disabled={this.state.activity_id ? true : false}>
                                                    <Radio value="all_user">全部人群</Radio>
                                                    <Radio value="new_user">新用户</Radio>
                                                    <Radio value="old_user">老用户</Radio>
                                                </Radio.Group>
                                            )}
                                        </Form.Item>

                                    </Fragment>
                            }


                            <Form.Item label="应用页面">
                                {getFieldDecorator('activity_subtype', {
                                    initialValue: ['homePopup'],
                                    rules: [
                                        {
                                            required: true,
                                            message: "请选择应用页面"
                                        }
                                    ],
                                })(
                                    <Checkbox.Group
                                        disabled={this.state.activity_res.popup_status === 0 || this.state.activity_res.popup_status === 3}>
                                        <Checkbox value="homePopup">首页</Checkbox>
                                        <Checkbox value="2" disabled>装修页面</Checkbox>
                                        <Checkbox value="3" disabled>商品详情页</Checkbox>
                                        <Checkbox value="4" disabled>商品列表页</Checkbox>
                                    </Checkbox.Group>
                                )}
                            </Form.Item>
                        </Form>
                        <aside>
                            <div className="title">{this.state.type === 'couponList' ? "优惠券" : "图片广告"}展示</div>
                            <div className="pop-up-promotion-show">
                                <PopUpCoupon
                                    style={this.state.type === 'couponList' ? this.state.custom_style : 1}
                                    image_url={this.state.image_url}
                                    coupon_list={this.state.coupon_list.filter(item => item.status === 1)} />
                            </div>
                        </aside>
                    </main>
                    <Divider />
                    {this.state.activity_res.popup_status === 0 || this.state.activity_res.popup_status === 3 ? null : (
                        <div className="activity-footer">
                            <Button type="primary" shape="round" onClick={this.createOrUpdateActivity}>保存</Button>
                            <Button shape="round" onClick={this.goToPopUpList}>取消</Button>
                        </div>
                    )}

                </div>
                <CouponSelectModal
                    visible={this.state.visible}
                    onOk={this.onCouponOK}
                    onCancel={this.onCouponCancel}
                />
                <TargetTypeModal
                    isPromote={true}
                    visible={this.state.ad_visible}
                    onOK={this.onOK}
                    onCancel={this.onCouponCancel}

                />
            </div>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode
    }
}
const CreateOrUpdatePopUpForm = Form.create({ name: 'create-update-pop-form' })(CreateOrUpdatePopUp);

export default connect(mapStateToProps)(CreateOrUpdatePopUpForm);
