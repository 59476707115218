import React  from 'react'
import {connect} from "react-redux";
import {Form,Button,Input,Table,message} from "antd";
import '@css/repeatPrintShipment.scss'
import PrintModal from '@/view/component/common/PrintModal'
import {queryGroupShipmentOrderList} from "@service/getData";
import cookie from "react-cookies";
class  RepeatPrintShipment extends React.Component{
    constructor(props){
        super()
        this.state={
            order_history_list:[],
            order:{},
            ship_columns: [{
                title: '配送单号',
                dataIndex: 'shipment_sn',
                render: (value, row)  => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.row_span!=null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },

            } ,{

                title: '订单编号',
                dataIndex: 'order_sn',
                render: (value, row)  => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.order_goods_rowspan!=null) {
                        obj.props.rowSpan = row.order_goods_rowspan
                    }
                    return obj;
                },

            }, {

                title: '商品名称',
                dataIndex: 'goods_name',
            }, {

                title: '规格',
                dataIndex: 'sku',
            }, {

                title: '售后',
                dataIndex: 'refund_status',
                render:(text)=>{
                    return text===1?"售后完成":text===0?"售后中":"无售后"
                }
            }, {
                title: '单价|数量',
                dataIndex: 'num',
                render: (text, record) => (
                    <div>
                        <p>¥{record.current_price / 100}</p>
                        <p>{record.quantity}件</p>
                    </div>
                )
            }, {

                title: '付款时间',
                dataIndex: 'pay_time',
                render: (value, row)  => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.order_goods_rowspan!=null) {
                        obj.props.rowSpan = row.order_goods_rowspan
                    }
                    return obj;
                },
            }, {

                title: '社区信息',
                dataIndex: 'comunity',
                render: (value, row)  => {
                    let obj = {
                        children: (
                            <div>
                                <p>{row.community_name}</p>
                                <p>{row.receiver_name}{row.mobile}</p>
                                <p>{row.address}</p>
                            </div>
                        ),
                        props: {},
                    };
                    if (row.row_span!=null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },
            }, {
                title: '类型',
                dataIndex: 'shipment_type',
                render: (value, row)  => {
                    let obj = {
                        children: (<p>{value === "group_shipment" ? "自提" : "邮寄"}</p>),
                        props: {},
                    };
                    if (row.row_span!=null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },
            },{
                title: '操作',
                dataIndex: 'action',
                render: (value, row)  => {
                    let obj = {
                        children: (
                            <div>
                                <p className="text-note"onClick={this.showPrintModal.bind(this,row)}>补打印</p>
                            </div>),
                        props: {},
                    };
                    if (row.row_span!=null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },
            }
            ],
            shipment_type: "group_shipment",
            print_visible:false

        }
    }
    clearFilter = () => {
        this.props.form.resetFields();
    };
    hideModal=()=>{
        this.setState({
            visible:false,
            print_visible:false
        })
    }
    filterOrder = () => {
        if(!cookie.load("shop_id")&&+cookie.load("is_admin")===1){
            return
        }
        let form = this.props.form.getFieldsValue();
        let data = {
            shop_id: cookie.load("shop_id"),
            page_size: this.state.page_size,
            page_no: this.state.page,
            shipment_type:this.state.shipment_type,
            status: 1
        }
        if(!form.shipment_sn){
            return message.error("请输入配送单号")

        }
        data.shipment_sn=form.shipment_sn
        this.queryGroupShipmentOrderList(data)
        window.scrollTo(0,0);
    }
    showPrintModal=(row)=>{
        this.setState({
            print_visible:true,
            order:row
        })
    }
    confirmPrint=()=>{
        message.success("打印成功")
        this.setState({
            print_visible:false
        })
    }

    async queryGroupShipmentOrderList(data) {
        let order_history_list = []
        let res = await queryGroupShipmentOrderList(data);
        if (res.success) {
            res.result.shipment_res_list.forEach(item => {
                let position=order_history_list.length;
                let row_span=0;
                let ship_index=0,goods_list=[];
                item.order_res_list.forEach(order=>{
                    row_span+=order.order_goods_res_list.length
                    order.order_goods_res_list.forEach((goods,index)=>{
                        let data = {
                            logistics: item.logistics,
                            order_sn:order.order_sn,
                            pay_time:order.pay_time,
                            receiver_name: item.receiver_name,
                            shipping_time: item.shipping_time,
                            order_status: order.order_status,
                            shipment_type: order.shipment_type,
                            shipment_sn: item.shipment_sn,
                            shipment_id: item.shipment_id,
                            goods_name:goods.goods_res.goods_name,
                            quantity:goods.quantity,
                            current_price:goods.sku_res.current_price,
                            community_name:item.community_res.community_name,
                            community_id:item.community_res.community_id,
                            mobile:item.mobile,
                            tel_number:order.user_res.mobile,
                            nick_name:order.user_res.nick_name,
                            refund_status:goods.order_goods_refund_status?this.props.refund_status_list[goods.order_goods_refund_status].status:null,
                            address:item.province+item.district+item.address,
                            row_span:0
                        }
                        if(index===0){
                            ship_index=order_history_list.length;
                            data.order_goods_rowspan=order.order_goods_res_list.length
                            data.order_goods_list=goods_list
                        }else{
                            data.order_goods_rowspan=0
                        }
                        let sku=""
                        goods.sku_res.attribute_value_res_list.forEach(item=>{
                            sku+=item.attribute_value
                        })
                        data.sku=sku
                        order_history_list.push(data)
                        if(!data.refund_status){
                            goods_list.push(data)
                        }

                    })
                })
                order_history_list[ship_index].order_goods_list=goods_list
                order_history_list[position].row_span=row_span?row_span:1
            })
            this.setState({
                order_history_list: order_history_list,
                total: res.result.total?res.result.total:0
            })
        }
    }
    render(){
        const {getFieldDecorator} = this.props.form;
        return(
            <div className="repeat-print">
                <div className="filter-content">
                    <Form layout="inline">
                        <Form.Item label="配送单号">
                            {getFieldDecorator('shipment_sn')(
                                <Input className="form-input" placeholder="输入配送单号"/>
                            )}
                        </Form.Item>
                    </Form>
                    <div className="action">
                        <Button shape="round" type="primary" onClick={this.filterOrder}>筛选</Button>
                        <span className="text-note" onClick={this.clearFilter}>清空筛选条件</span>
                    </div>
                </div>
                <Table rowKey={(record, index) => index}

                       columns={this.state.ship_columns}
                       dataSource={this.state.order_history_list}
                       pagination={false} bordered={true}
                />
                <PrintModal  visible={this.state.print_visible}  handleSelfOk={this.confirmPrint} handleSelfCancel={this.hideModal} order={this.state.order}/>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
        order_status_list: state.orderStatusList,
        refund_status_list:state.refundStatusList
    }
}

const RepeatPrintShipmentForm = Form.create({name: 'repeat_print_shipment_form'})(RepeatPrintShipment);

export default connect(mapStateToProps)(RepeatPrintShipmentForm);