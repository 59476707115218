import { Spin, Tabs } from 'antd';
import React, { Component } from 'react';
import './scss/gradeManage.scss';
import NavBar from "@/view/component/common/NavBar";
import Grade from './children/Grade';
import Task from './children/Task';

class GradeManage extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
        };
    }

    render() {
        return (
            <div className="gradeManage">
                <Spin spinning={this.state.loading}>
                    <NavBar icon="icon-kehu" child="等级管理" />
                    <div className="content">
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="等级管理" key="1">
                                <Grade></Grade>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="任务管理" key="2">
                                <Task></Task>
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </Spin>
            </div>
        );
    }
}

export default GradeManage;