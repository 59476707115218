import React from 'react'
import '@css/marketData.scss'
import { Tooltip, Icon, message } from 'antd'
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/chart/line';
import { queryActivityDashBoard, queryActivityDetail } from '@service/getData'
import cookie from "react-cookies";
import { getQueryString, formatDate } from "@common/common";
import RangePicker from '@/view/component/search/RangePicker';
class MarketData extends React.Component {
    constructor() {
        super()
        this.state = {
            data: [],
            option: {
                xAxis: {
                    data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                    axisLabel: {
                        interval: 0,
                        rotate: -30,//倾斜度 -90 至 90 默认为0
                        margin: 10,
                        color: "#555555"
                    },
                },
                yAxis: {
                    type: 'value',
                    name: '人数(人)',
                    axisLabel: {
                        color: "#555555"
                    },
                    axisLine: {
                        "show": false

                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    extraCssText: 'box-shadow:0px 0px 4px rgba(0,0,0,0.16)',
                    textStyle: {
                        width: 160,
                        color: '#555555',
                        fontSize: 14
                    },
                    formatter: function (params) {
                        var str = '';

                        str += '<div style="width:160px;margin-bottom:5px">' + params[0].name + '</div>'; //显示日期的函数

                        for (var i = 0; i < params.length; i++) { //图显示的数据较多时需要循环数据，这样才可以成功的给多条数据添加单位

                            str += '<span style="display:inline-block;margin-right:5px;border-radius:4px;width:4px;height:4px;background-color:#0D87EB"></span><span style="margin-right:5px">' + params[i].seriesName + '</span><span>' + params[i].data + '</span>';

                        }
                        return str;
                    }
                },
                series: [
                    {
                        name: '人数(人)',
                        smooth: true,  //这个是把线变成曲线
                        type: 'line',   //这个是展示数据的类型，是柱状图还是折线图，饼状图
                        data: [1000, 2000, 1500, 3000, 2000, 1200, 800],
                        color: '#0D87EB'
                    }
                ]
            },
            tempOption: {},
            activity_subtype: null,
            activity_id: null
        }
    }
    queryActivityDashBoard = async () => {
        let params = {
            mini_app_id: cookie.load("mini_app_id")
        }
        let res = await queryActivityDashBoard(params)
        if (res.success) {
            let list = res.result.daily_stats_res_list;
            let x_data = list.map(item => {
                return formatDate(new Date(item.stats_date).getTime(), 'M/D')
            })
            let y_data = list.map(item => {
                return this.state.activity_subtype === "lucky" ? item.user_lucky_num : this.state.activity_subtype === "rank_lucky" ? item.user_rank_lucky_num : item.user_checkin_num
            })
            let xAxis = {
                data: x_data,
                axisLabel: {
                    interval: 0,
                    rotate: -30,//倾斜度 -90 至 90 默认为0
                    margin: 10,
                    color: "#555555"
                },
            };
            let series = [
                {
                    name: '人数(人)',
                    smooth: true,  //这个是把线变成曲线
                    type: 'line',   //这个是展示数据的类型，是柱状图还是折线图，饼状图
                    data: y_data,
                    color: '#0D87EB'
                }
            ]
            let option = Object.assign({}, this.state.option, { xAxis, series });
            let data = []
            if (this.state.activity_subtype === 'checkin') {
                // 签到
                data = [
                    {
                        title: '总签到用户数（人）',
                        data: res.result.total_checkin_user,
                        desc: '历史签到用户数'
                    },
                    {
                        title: '总签到次数（次）',
                        data: res.result.total_checkin_num,
                        desc: '签到总次数'
                    },
                    {
                        title: '总签到积分数（分）',
                        data: res.result.total_checkin_points,
                        desc: '通过签到发放的积分数'
                    },
                    {
                        title: '订阅签到提醒用户数（人）',
                        data: res.result.message_checkin_user,
                        desc: null
                    }
                ]
            } else if (this.state.activity_subtype === "rank_lucky") {
                // 线上拼团
                data = [
                    {
                        title: '参与人数（人）',
                        data: res.result.total_rank_lucky_user,
                        desc: "参与抽奖总人数"
                    },
                    {
                        title: '成团率',
                        data: `${(res.result.total_rank_lucky_success_rate * 100).toFixed(2)}%`,
                        desc: '成团数/开团数'
                    }
                ]
            } else {
                // 拼团抽奖
                data = [
                    {
                        title: '参与人数（人）',
                        data: res.result.total_lucky_user,
                        desc: "参与抽奖总人数"
                    },
                    {
                        title: '成团率',
                        data: `${(res.result.total_lucky_success_rate * 100).toFixed(2)}%`,
                        desc: '成团数/开团数'
                    }
                ]
            }
            this.setState({
                option,
                data,
            }, () => {
                var myChart = echarts.init(document.getElementById('main'));
                myChart.setOption(this.state.option);
            });
            this.setState({
                tempOption: JSON.parse(JSON.stringify(this.state.option))
            });
        }
    }
    queryActivityDetail = async () => {
        let data = {
            activity_id: this.state.activity_id
        }
        let res = await queryActivityDetail(data);
        if (res.success) {
            this.setState({
                activity_subtype: res.result.activity_res.activity_subtype
            }, () => {
                this.queryActivityDashBoard()
            })
        }
    }

    componentDidMount() {
        let activity_id = getQueryString("activity_id")
        this.setState({
            activity_id,
        }, () => {
            if (activity_id) {
                this.queryActivityDetail();
            }
        })
    }
    datePick(date, dateStr) {
        this.setState({
            option: JSON.parse(JSON.stringify(this.state.tempOption)),
        }, () => {
            let arr = [];
            arr = date.map((item) => {
                let dayStr = item.date() < 10 ? `0${item.date()}` : item.date();
                let monthStr = (item.month() + 1) < 10 ? `0${item.month() + 1}` : (item.month() + 1);
                return `${monthStr}/${dayStr}`;
            });
            let beginIndex = this.state.tempOption.xAxis.data.indexOf(arr[0]);
            let endIndex = this.state.tempOption.xAxis.data.indexOf(arr[1]);
            if (beginIndex === -1 || endIndex === -1) {
                message.error('选择的时间不在近30天的范围内，请重新选择');
                return;
            }
            let _option = this.state.option;
            _option.xAxis.data = _option.xAxis.data.slice(beginIndex, endIndex + 1);
            _option.series[0].data = _option.series[0].data.slice(beginIndex, endIndex + 1);
            this.setState({
                option: _option,
            }, () => {
                var myChart = echarts.init(document.getElementById('main'));
                myChart.setOption(this.state.option);
            });
        });
    }
    render() {
        return (
            <div className="market-data">
                <div className="market-info">
                    {
                        this.state.data.map((item, index) => (
                            <div className="market-info-conetnt" key={index}>
                                <div className="market-info-data">{
                                    item.data
                                }</div>
                                <div className="market-info-title">
                                    {item.title}
                                    {
                                        item.desc ? (
                                            <Tooltip title={item.desc}>
                                                <Icon type="question-circle" theme="twoTone" />
                                            </Tooltip>
                                        ) : null
                                    }
                                </div>
                            </div>))
                    }
                </div>
                <div className="shopDataTitle">
                    <i className="titleSign"></i>
                    近30日{this.state.activity_subtype === "lucky" ? "抽奖" : "签到"}用户趋势
                </div>
                <div className="pickerWrapWrap">
                    <span className="label">筛选时间范围</span>
                    <RangePicker allowClear={false} showTime={false} transferValue={this.datePick.bind(this)} />
                </div>
                <div id="main" style={{ height: 400 }}></div>
            </div>
        )
    }

}
export default MarketData
