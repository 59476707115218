import React from 'react';
import '@css/login.scss';
import LoginForm from '@/view/component/Login/LoginForm'
function Login() {
    return (
        <div className="login">
            <div className="bg-login"></div>
            <div className="content">
                <div className="form-box">
                    {/* <img alt=""
                        className="logo-login"
                        src={require("../../../images/logo_login.png")}
                        height="40"
                    /> */}
                    <div className="logo_box">
                        <div className="logo_text">PLEGO</div>
                        <div className="logo_text_des">官方商城管理后台</div>
                    </div>
                    <div class="text-login-in-store">登录到您的商店</div>
                    <LoginForm/>
                </div>
            </div>
        </div>
    );
}

export default Login;
