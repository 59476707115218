import React from 'react';
import './Poster.scss'


class Poster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="posterWrap">
                {this.props.children}
                <div className="content">
                    <div className="posterLeft">
                        {this.props.left}
                    </div>
                    <div className="posterRight">
                        {this.props.right}
                    </div>
                </div>
            </div>
        );
    }
}

export default Poster;