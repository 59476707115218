import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import './groupActivitys.scss';
import NavBar from '@/view/component/common/NavBar.js'
import { Tabs, Table, Pagination, message, Divider, Tooltip, Form, Input, Button, } from 'antd';
import { queryActivityList, getData, getCode } from '@service/getData';
import cookie from "react-cookies";
import html2canvas from 'html2canvas';
import copy from 'copy-to-clipboard';

const { TabPane } = Tabs;

class GroupActivitys extends Component {
    constructor(props) {
        super(props);
        GroupActivitys.contextTypes = {
            router: PropTypes.object.isRequired,
        };
        this.state = {
            colums: [
                {
                    title: '商品名称',
                    dataIndex: 'good_name',
                },
                {
                    title: '有效时间',
                    dataIndex: 'start_time',
                    render: (text, row, index) => (
                        <span>{text} - {row.end_time}</span>
                    )
                },
                {
                    title: '活动类型',
                    dataIndex: 'activity_sub_type',
                    render: (text, row, index) => {
                        const obj = {
                            lucky: '拼团抽奖',
                            rank_lucky: '线上拼团',
                        };
                        return (
                            <span>{obj[text]}</span>
                        )
                    }
                },
                {
                    title: '状态',
                    dataIndex: 'activity_status',
                    render: (text, row, index) => {
                        const arr = ['未开始', '进行中', '已结束'];
                        return (
                            <span style={{ color: text === 1 ? 'green' : null }}>{arr[text]}</span>
                        )
                    }
                },
                {
                    title: '拼团价格',
                    dataIndex: 'partake_price',
                    render: (text) => (
                        <span>{text / 100}</span>
                    )
                },
                {
                    title: '成团人数',
                    dataIndex: 'group_member_num',
                },
                {
                    title: '操作',
                    dataIndex: 'activity_goods_id',
                    render: (text, row, index) => {
                        const shop_id = cookie.load('shop_id');
                        let value;
                        let page;
                        if (row.activity_sub_type === 'lucky') {
                            page = 'pages/boothActivityList/index';
                        }
                        if (row.activity_sub_type === 'rank_lucky') {
                            page = 'pages/groupPurchasing/index';
                        }
                        value = `${page}?activity_id=${row.activity_id}`
                        return (
                            <Fragment>
                                {/* <span className="text-note">删除</span>
                                <Divider type="vertical" /> */}
                                <Tooltip
                                    overlayClassName="tooltip-link-qrcode"
                                    arrowPointAtCenter
                                    placement="left"
                                    autoAdjustOverflow={false}
                                    onVisibleChange={(visible) => {
                                        if (visible) {
                                            this.getCode({
                                                activity_id: row.activity_id,
                                                page,
                                                shop_id,
                                            })
                                        }
                                    }}
                                    title={
                                        <Form>
                                            <Form.Item label="推广小程序路径：">
                                                <Input.Search
                                                    readOnly
                                                    value={value}
                                                    size="small"
                                                    placeholder="input search text"
                                                    enterButton={
                                                        <Button
                                                            type="primary"
                                                            shape="round">复制</Button>
                                                    }
                                                    onSearch={this.copy}
                                                />
                                            </Form.Item>
                                            <Form.Item label="">
                                                <div className="tooltip-qrcode">
                                                    <img alt=""
                                                        onError={(target) => {
                                                            this.setState({ url: "http://udh.oss-cn-hangzhou.aliyuncs.com/144c1feb-c3ed-46f7-9dac-f0b2527b4dea" })
                                                            target.onerrοr = null;
                                                        }
                                                        }
                                                        width="120"
                                                        src={this.state.url}
                                                    />
                                                    <Button
                                                        className="btn-qrcode"
                                                        type="primary"
                                                        shape="round"
                                                        onClick={this.downLoadPost}
                                                    > <a download="qrcode.png" href={this.state.url}>下载小程序码</a></Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    }
                                >
                                    <span className="text-note">推广</span>
                                </Tooltip>
                            </Fragment>
                        )
                    }
                },
            ],
            data: [],
            groups: [
                {
                    title: '拼团抽奖',
                    desc: '拼团成功后随机抽奖',
                    type: 'lucky',
                    backgroundImage: '//udh.oss-cn-hangzhou.aliyuncs.com/f71be3e6-ad81-4466-a280-34f9de62e3ab',
                },
                {
                    title: '线上拼团',
                    desc: '拼团成功后按名次发奖',
                    type: 'rank_lucky',
                    backgroundImage: '//udh.oss-cn-hangzhou.aliyuncs.com/8d6d91c1-dafd-45db-be50-2d5844003adb',
                },
            ],
            // 分页
            page: 1,
            page_size: 10,
            total: 0,
            // 推广二维码
            url: '',
        };
    }
    // init
    componentDidMount() {
        this.queryActivityList();
        this.getData();
    }

    async getData() {
        const { page_size, page: page_no } = this.state;
        const params = {
            mini_app_id: cookie.load('mini_app_id'),
            page_size,
            page_no,
        };
        let res = await getData('/activity/queryActivityLuckyGoodsList', params);
        if (res.success) {
            const data = res.result && res.result.list;
            this.setState({
                data,
                page: res.result.page_no,
                page_size: res.result.page_size,
                total: res.result.total,
            })
        }
        else {
            message.error('获取数据失败');
        }
    }

    async queryActivityList() {
        let data = {
            mini_app_id: cookie.load("mini_app_id"),
            activity_type_list: ["booth"]
        };
        let res = await queryActivityList(data);
        if (res.success) {
            const groups = this.state.groups.map((item) => {
                const activity = res.result.activity_res_list.find((act) => act.activity_subtype === item.type);
                if (activity) {
                    item.activityId = activity.activity_id;
                }
                return item;
            });
            this.setState({
                groups,
            });
        } else {
            message.error('获取数据出错');
        }
    }

    groupClick(item) {
        const open = this.context.router.history.push;
        open(`/marketTab?type=edit&activity_id=${item.activityId}`);
    }

    onShowPageChange(page) {
        this.setState({
            page,
        }, () => {
            this.getData();
        });
    }

    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.getData();
        })
    }
    copy = (value) => {
        copy(value)
        message.success('复制成功')
    }
    async getCode(data) {
        const res = await getCode(data);
        this.setState({
            url: res,
        });
    }
    downLoadPost() {
        window.pageYOffset = 0;
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        html2canvas(document.getElementById('post'), {
            useCORS: true, // 【重要】开启跨域配置
            taintTest: false,//是否在渲染前测试图片,
            dpi: 192,
            scale: 8,
        }).then(function (canvas) {
            var context = canvas.getContext('2d');
            context.mozImageSmoothingEnabled = false;
            context.webkitImageSmoothingEnabled = false;
            context.msImageSmoothingEnabled = false;
            context.imageSmoothingEnabled = false;
            var imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // 获取生成的图片的url
            var a = document.createElement("a");
            a.href = imgUri;
            a.download = 'qrcode.png';
            a.click();
        })

    }
    render() {
        const { menu_code } = this.props

        return (
            <div className="groupActivitys">
                <NavBar
                    icon='icon-yingxiao'
                    router='marketSetting'
                    father={menu_code.market_setting}
                    child='拼团活动'
                />
                <div className="content">
                    <div className="introduction">
                        <div className="iconWrap">
                            <i className="iconfont icon-pintuan"></i>
                        </div>
                        <div className="incRight">
                            <p className="rightTop">
                                <span className="shareCoupon">拼团活动</span>
                            </p>
                        </div>
                    </div>
                    <div className="groups">
                        {
                            this.state.groups.map((item, index) => (
                                <div
                                    className="groupItem"
                                    key={index}
                                    style={
                                        {
                                            backgroundImage: `url(${item.backgroundImage})`
                                        }
                                    }
                                    onClick={this.groupClick.bind(this, item)}
                                >
                                    <p className="title">{item.title}</p>
                                    <p className="desc">{item.desc}</p>
                                    <div className="btn">编辑活动</div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="tabsWrap">
                        <Tabs defaultActiveKey='0'>
                            <TabPane tab="全部拼团商品" key="0" />
                        </Tabs>
                    </div>
                    <div className="tableWrap">
                        <Table
                            rowKey={(record, index) => index}
                            columns={this.state.colums}
                            dataSource={this.state.data}
                            bordered
                            pagination={false}
                        />
                    </div>
                    <Pagination
                        className="pagenation"
                        onChange={this.onShowPageChange}
                        onShowSizeChange={this.onShowSizeChange}
                        defaultCurrent={this.state.page}
                        total={this.state.total}
                        showTotal={(total) => `共 ${total} 条`}
                        pageSizeOptions={["10", "30", "50", "100"]}
                        showSizeChanger
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode
    }
}
// const GroupActivitysForm = Form.create({ name: "group_activitys" })(GroupActivitys);

export default connect(mapStateToProps)(GroupActivitys);