import React from 'react'
import { Form, Input, Divider, Button, Select, message } from 'antd'
import NavBar from '@/view/component/common/NavBar.js'
import { connect } from "react-redux";
import '@css/promoteMonitor.scss'
import TargetTypeModal from '@/view/component/Campaign/TargetTypeModal'
import ChanelModal from '@/view/component/common/ChannelModal'
import PropTypes from "prop-types";
import cookie from 'react-cookies'
import { createOrUpdateLink, queryChannelList, queryLinkDetail } from '@service/getData'
class PromoteMonitor extends React.Component {
    constructor() {
        super()
        PromoteMonitor.contextTypes = {
            router: PropTypes.object.isRequired
        };
        this.state = {
            visible: false,
            desc: null,
            channel_list: [],
            create_visible: false,
            channel_id: null,
            target_type_obj: {},
            link_res: {},
            target_type_desc: {
                lucky: "抽奖活动",
                checkin: "签到",
                pointsStore: "积分商城",
                free_order: "抢免单",
                rank_lucky: '线上拼团',
            },
        }
    }
    onOK = (data) => {
        this.props.form.setFieldsValue({ "target_type": data.target_type })
        if (data.target_type === 'campaign') {
            data.campaign_id = data.dest_campaign_id
        }
        this.setState({
            visible: false,
            target_type_obj: data,
            desc: data.desc
        })
    }
    onCancel = () => {
        this.setState({
            visible: false
        })
    }
    showModal = () => {
        this.setState({
            visible: true
        })
    }
    createOrUpdateLink = async () => {
        let form = this.props.form.getFieldsValue()
        let flag = false
        this.props.form.validateFields(err => {
            if (err) {
                flag = true
            }
        });
        if (flag) return
        let data = {
            link_req: {
                link_id: this.state.link_res.link_id,
                mini_app_id: cookie.load("mini_app_id"),
                channel_id: form.channel_id,
                link_name: form.link_name,
                remarks: form.remarks,
                ...this.state.target_type_obj
            }
        }
        let res = await createOrUpdateLink(data)
        if (res.success) {
            message.success(this.state.link_res.link_id ? '编辑成功' : "创建成功")
            this.gotoPromoteManage()
        }

    }
    gotoPromoteManage = () => {
        this.context.router.history.push('/promoteManage')
    }
    queryChannelList = async () => {
        let data = {
            mini_app_id: cookie.load("mini_app_id")
        }
        let res = await queryChannelList(data)
        if (res.success) {
            this.setState({
                channel_list: res.result.channel_res_list
            })

        }
    }
    onCreateOk = () => {
        this.setState({
            create_visible: false,

        }, () => {
            this.queryChannelList()
        })
    }
    changeCreateVisible = () => {
        this.setState(preState => ({
            create_visible: !preState.create_visible
        }))
    }
    queryLinkDetail = async () => {
        let data = {
            link_id: this.props.location.state
        }
        let res = await queryLinkDetail(data)

        if (res.success) {
            let { link_res } = res.result;
            let desc = ''
            switch (link_res.target_type) {
                case 'campaign':
                    desc = `店铺页面:${link_res.campaign_res.campaign_name}`
                    break;
                case 'goods':
                    desc = `商品:${link_res.goods_res.goods_name}`
                    break;
                case 'tag':
                    desc = `商品列表页:${link_res.tag_res.tag_name}`
                    break;
                case 'coupon':
                    desc = `优惠券:${link_res.coupon_res.coupon_name}`
                    break;
                case 'activity_goods':
                    desc = `活动商品:${link_res.activity_goods_res.goods_res.goods_name}`
                    break;
                default:
                    desc = `营销活动:${this.state.target_type_desc[link_res.target_type]}`
                    break;
            }
            this.setState({
                link_res: link_res,
                desc,
                channel_id: link_res.channel_id
            })
        }
    }
    componentDidMount() {
        this.queryChannelList()
        if (this.props.location.state) {
            this.queryLinkDetail()
        }
    }
    changeChannelId = (e) => {
        this.props.form.setFieldsValue({ "channel_id": e })
        this.setState({
            channel_id: e
        })
    }
    cancelEdit = () => {
        this.props.form.resetFields();
        this.setState({
            desc: null,
            channel_list: [],
            create_visible: false,
            channel_id: null,
            target_type_obj: {},
            link_res: {}
        }, () => {
            if (this.props.location.state) {
                this.queryLinkDetail()
            }
        })

    }
    onShowPageChange = (page) => {
        this.setState({
            page: page
        }, () => {
            this.queryLinkDetail()
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.queryLinkDetail()
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        let { menu_code } = this.props
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        return (
            <div className="promote-monitor">
                <NavBar
                    icon="icon-guanliyuanshezhi"
                    child={menu_code.promote_monitor}
                    father={menu_code.promote_manage}
                    router="promoteManage"
                />
                <div className='content'>
                    <div className="shopDataTitle">
                        <i className="titleSign"></i>
                       基本信息
                   </div>
                    <Divider />
                    <Form {...formItemLayout}>
                        <Form.Item label="推广监控名称">
                            {getFieldDecorator('link_name', {
                                initialValue: this.state.link_res.link_name,
                                rules: [{
                                    required: true,
                                    message: "请输入推广监控名称"
                                }]
                            })(
                                <Input maxLength={30} className="goods-id" placeholder="输入推广监控名称" />
                            )}
                        </Form.Item>
                        <Form.Item label="推广页面">
                            {getFieldDecorator('target_type', {
                                initialValue: this.state.link_res.target_type,
                                rules: [{
                                    required: true,
                                    message: "请选择推广页面"
                                }]
                            })(<div>
                                {this.state.desc === null ? (<span className="text-note" onClick={this.showModal}>选择页面</span>) : (
                                    <div>
                                        <span>
                                            {this.state.desc}
                                        </span>
                                        {
                                            this.state.link_res.link_id ? null : <span className="text-note" onClick={this.showModal}>修改</span>
                                        }
                                    </div>
                                )
                                }
                            </div>)}
                        </Form.Item>
                        <Form.Item label="推广渠道">
                            {getFieldDecorator('channel_id', {
                                initialValue: this.state.link_res.channel_id,
                                rules: [{
                                    required: true,
                                    message: "请选择推广渠道"
                                }]
                            })(
                                <div>
                                    <Select
                                        disabled={!!this.state.link_res.link_id}
                                        className="goods-id" value={this.state.channel_id} onChange={this.changeChannelId}>
                                        {
                                            this.state.channel_list.map(item =>
                                                <Select.Option value={item.channel_id} key={item.channel_id}>
                                                    {item.channel_name}
                                                </Select.Option>)
                                        }

                                    </Select>
                                    <span className="text-note" onClick={this.changeCreateVisible}>新建</span>
                                </div>
                            )}
                        </Form.Item>
                        <Form.Item label="备注">
                            {getFieldDecorator('remarks', {
                                initialValue: this.state.link_res.remarks,
                            })(
                                <Input.TextArea
                                    className="remarks"
                                    placeholder="输入备注"
                                    rows={3}
                                />
                            )}
                        </Form.Item>
                    </Form>
                    <footer>
                        <Divider />
                        <Button type='primary' shape="round" onClick={this.createOrUpdateLink}>保存</Button>
                        <Button shape="round" onClick={this.gotoPromoteManage}>取消</Button>
                    </footer>
                </div>
                <TargetTypeModal
                    isPromote={true}
                    title="推广页面"
                    onOK={this.onOK}
                    onCancel={this.onCancel}
                    visible={this.state.visible}
                />
                <ChanelModal
                    visible={this.state.create_visible}
                    onOk={this.onCreateOk}
                    onCancel={this.changeCreateVisible}
                />
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        menu_code: state.menuCode
    };
};
const PromoteMonitorForm = Form.create({ name: 'promote_monitor_form' })(PromoteMonitor);

export default connect(mapStateToProps)(PromoteMonitorForm)
