import React, { Fragment } from "react";
import { Spin, message, Tag, Switch } from "antd";
import NavBar from "@/view/component/common/NavBar.js";
import { connect } from "react-redux";
import "@css/campainSetting.scss";
import { exchangeActivitySortNum, topActivity, queryActivityList, createOrUpdateActivity } from "@service/getData";
import cookie from "react-cookies";
import PropTypes from "prop-types";
class MarketSetting extends React.Component {
  constructor() {
    super();
    MarketSetting.contextTypes = {
      router: PropTypes.object.isRequired
    };
    this.state = {
      loading: false,
      visible: false,
      activity_list: [],
      activity: {
        activity_name: "优惠券",
        activity_id: null
      },
      data: [],
      campaign: {},
      campaign_show_visible: false,
      market_activity_list: [
        {
          activity_name: "拉新",
          activity_desc: "拉新成本低",
          activity_sub_list: [
            {
              icon: "iconfont icon-user-coupon",
              backgroundColor: "#f05b5b",
              type: "coupon",
              name: "优惠券",
              tag_list: ["提升下单率", "提升客单价"],
              desc: "通过设置优惠金额和使用门槛，刺激客户购买",
              url: "/couponManage",
              is_new: true
            },
            {
              icon: "iconfont icon-pintuan",
              backgroundColor: "#2B97F0",
              type: "lucky",
              name: "拼团",
              tag_list: ["拉新成本低", "攒人气"],
              desc: "拼团后随机抽奖或者按规则发奖",
              url: "/marketTab?type=edit&activity_id=undefined"
            },
          ]
        },
        {
          activity_name: "留存",
          activity_desc: "留住老用户",
          activity_sub_list: [
            {
              icon: "iconfont icon-qiandao",
              backgroundColor: "#FD964A",
              type: "checkin",
              name: "签到",
              tag_list: ["增加店铺访问", "提升活跃"],
              desc: "签到得积分，提升顾客留存和活跃程度"
            },
            {
              icon: "iconfont icon-pintuan",
              backgroundColor: "#2B97F0",
              type: "lucky",
              name: "抽奖",
              tag_list: ["拉新成本低", "攒人气"],
              desc: "拼团后随机抽奖或者按规则发奖",
              url: "/marketTab?type=edit&activity_id=45"
            },
            {
              icon: "iconfont icon-liwu",
              backgroundColor: "#9a5be7",
              type: "enterGroup",
              name: "进群有礼",
              tag_list: ["微信群裂变", "增加用户留存"],
              desc: "设置微信群二维码，引导用户到群进行裂变营销"
            },
            {
              icon: "iconfont icon-tuangou",
              backgroundColor: "#67c163",
              type: "group_order",
              name: "社群宝",
              tag_list: ["提升销量利器", "增加销售渠道"],
              desc: "招募社区团长，快速扩大经营范围和销量",
              url: "/leaderManage"
            }
          ]
        },
        {
          activity_name: "转化",
          activity_desc: "转化比例高",
          activity_sub_list: [
            {
              icon: "iconfont icon-bofang",
              backgroundColor: "#2B97F0",
              type: "roomInfoList",
              name: "直播",
              tag_list: ["粉丝互动", "转化率高"],
              desc: "通过直播互动的方式卖货",
              url: "/liveList",
              is_new: true
            },
            {
              icon: "iconfont icon-lianhe4",
              backgroundColor: "#f05b5b",
              type: "full_reduce",
              name: "满减/满赠/满折",
              tag_list: ["提升客单价", "清库存"],
              desc: "丰富的优惠活动，鼓励用户凑单多买",
              url: "/market",
              is_new: true
            },
            {
              icon: "iconfont icon-tuiguang",
              backgroundColor: "#f05b5b",
              type: "popupList",
              name: "弹窗广告",
              tag_list: ["定向发券", "精准推广"],
              desc: "支持进店自动发券，定向锁定用户"
            },
          ]
        },
        {
          activity_name: "裂变",
          activity_desc: "个个都是增长黑客",
          activity_sub_list: [
            {
              icon: "iconfont icon-youhuijuan1",
              backgroundColor: "#f05b5b",
              type: "coupon",
              name: "裂变优惠券",
              tag_list: ["提升下单率", "拉新"],
              desc: "优惠券组合，好友同享优惠促转化",
              url: "/fissionCoupon",
              is_new: true
            }
          ]
        },
        {
          activity_name: "复购",
          activity_desc: "提升回访，用户买了又买",
          activity_sub_list: [
            {
              icon: "iconfont icon-jifen",
              backgroundColor: "#FD964A",
              type: "pointsStore",
              name: "积分中心",
              tag_list: ["回馈老用户", "提升用户粘性"],
              desc: "可设置换购商品与消耗积分，激励用户购物赚积分"
            },
            {
              icon: "iconfont icon-huiyuan",
              backgroundColor: "#FD964A",
              type: "checkin",
              name: "会员营销",
              tag_list: ["会员权益", "会员等级"],
              desc: "绑定用户，增强用户粘性",
              url: "/vipMarketing",
              is_new: true
            },
          ]
        },
        // {
        //   activity_name: "促销工具",
        //   activity_desc: "让利促销，清减库存",
        //   activity_sub_list: [
        //     {
        //       icon: "iconfont icon-bofang",
        //       backgroundColor: "#2B97F0",
        //       type: "roomInfoList",
        //       name: "直播",
        //       tag_list: ["粉丝互动", "转化率高"],
        //       desc: "通过直播互动的方式卖货",
        //       url: "/liveList",
        //       is_new: true
        //     },
        //     {
        //       icon: "iconfont icon-user-coupon",
        //       backgroundColor: "#f05b5b",
        //       type: "coupon",
        //       name: "优惠券",
        //       tag_list: ["提升下单率", "提升客单价"],
        //       desc: "通过设置优惠金额和使用门槛，刺激客户购买",
        //       url: "/couponManage",
        //       is_new: true
        //     },
        //     {
        //       icon: "iconfont icon-lianhe4",
        //       backgroundColor: "#f05b5b",
        //       type: "full_reduce",
        //       name: "满减/满赠/满折",
        //       tag_list: ["提升客单价", "清库存"],
        //       desc: "丰富的优惠活动，鼓励用户凑单多买",
        //       url: "/market",
        //       is_new: true
        //     },
        //     {
        //       icon: "iconfont icon-youhuijuan1",
        //       backgroundColor: "#f05b5b",
        //       type: "coupon",
        //       name: "分享优惠券",
        //       tag_list: ["提升下单率", "拉新"],
        //       desc: "优惠券组合，好友同享优惠促转化",
        //       url: "/fissionCoupon",
        //       is_new: true
        //     }
        //   ]
        // },
        // {
        //   activity_name: "裂变增长",
        //   activity_desc: "个个都是增长黑客",
        //   activity_sub_list: [
        //     {
        //       icon: "iconfont icon-pintuan",
        //       backgroundColor: "#2B97F0",
        //       type: "lucky",
        //       name: "拼团活动",
        //       tag_list: ["拉新成本低", "攒人气"],
        //       desc: "拼团后随机抽奖或者按规则发奖"
        //     },
        //     {
        //       icon: "iconfont icon-lianhe1",
        //       backgroundColor: "#EB2F96",
        //       type: "free_order",
        //       name: "抢免单",
        //       tag_list: ["拉新神器", "迅速传播裂变"],
        //       desc: "设置免单商品，激励用户转发邀请新用户参与"
        //     },
        //     {
        //       icon: "iconfont icon-tuangou",
        //       backgroundColor: "#67c163",
        //       type: "group_order",
        //       name: "社区团购",
        //       tag_list: ["提升销量利器", "增加销售渠道"],
        //       desc: "招募社区团长，快速扩大经营范围和销量",
        //       url: "/leaderManage"
        //     }
        //   ]
        // },
        // {
        //   activity_name: "人群运营",
        //   activity_desc: "提升回访，用户买了又买",
        //   activity_sub_list: [
        //     {
        //       icon: "iconfont icon-huiyuan",
        //       backgroundColor: "#FD964A",
        //       type: "checkin",
        //       name: "权益卡",
        //       tag_list: ["会员权益", "会员等级"],
        //       desc: "绑定用户，增强用户粘性",
        //       url: "/cardManage",
        //       is_new: true
        //     },
        //     {
        //       icon: "iconfont icon-qiandao",
        //       backgroundColor: "#FD964A",
        //       type: "checkin",
        //       name: "签到",
        //       tag_list: ["增加店铺访问", "提升活跃"],
        //       desc: "签到得积分，提升顾客留存和活跃程度"
        //     },
        //     {
        //       icon: "iconfont icon-jifen",
        //       backgroundColor: "#FD964A",
        //       type: "pointsStore",
        //       name: "积分商城",
        //       tag_list: ["回馈老用户", "提升用户粘性"],
        //       desc: "可设置换购商品与消耗积分，激励用户购物赚积分"
        //     },
        //     {
        //       icon: "iconfont icon-tuiguang",
        //       backgroundColor: "#f05b5b",
        //       type: "popupList",
        //       name: "弹窗推广",
        //       tag_list: ["定向发券", "精准推广"],
        //       desc: "支持进店自动发券，定向锁定用户"
        //     },
        //     {
        //       icon: "iconfont icon-liwu",
        //       backgroundColor: "#9a5be7",
        //       type: "enterGroup",
        //       name: "进群有礼",
        //       tag_list: ["微信群裂变", "增加用户留存"],
        //       desc: "设置微信群二维码，引导用户到群进行裂变营销"
        //     },
        //     {
        //       icon: "iconfont icon-zhouqigou",
        //       class: "activity-cyclePurchase",
        //       backgroundColor: "#f05b5b",
        //       type: "cyclePurchase",
        //       name: "周期购",
        //       tag_list: ["提升粘性"],
        //       desc: "支持一次下单，商家周期配送商品",
        //       url: "/cyclePurchase"
        //     },
        //     {
        //       icon: "iconfont icon-changjingyingxiao",
        //       class: "activity-changjingyingxiao",
        //       backgroundColor: "#FC964B",
        //       type: "sceneMarketing",
        //       name: "场景营销",
        //       tag_list: ["提升忠诚度"],
        //       desc: "支持会员日营销、生日营销",
        //       url: "/sceneMarketing"
        //     }
        //   ]
        // }
      ]
    };
  }
  queryActivityList = async () => {
    let data = {
      mini_app_id: cookie.load("mini_app_id"),
      activity_type_list: ["personCenter", "booth", "drogue", "popupList"]
    };
    let res = await queryActivityList(data);
    let activity_res_list = res.result.activity_res_list;
    let market_activity_list = [...this.state.market_activity_list].map(elem => {
      elem.activity_sub_list.forEach(item => {
        if (item.type !== "coupon") {
          let activity_res = activity_res_list.find(activity => activity.activity_subtype === item.type);
          if (activity_res) {
            item.activity_id = activity_res.activity_id;
            item.status = activity_res.status;
          }
        } else {
          item.activity_id = null;
        }
      });
      return elem;
    });
    if (res.success) {
      this.setState({
        loading: false,
        market_activity_list
      });
    }
  };
  exchangeActivitySortNum = async (index, isUp) => {
    this.setState({
      loading: true
    });
    let list = [...this.state.data],
      from_activity_id = list[index].activity_id,
      to__activity_id;
    if (isUp < 0) {
      to__activity_id = list[index - 1].activity_id;
    } else {
      to__activity_id = list[index + 1].activity_id;
    }
    let res = await exchangeActivitySortNum({
      from_activity_id,
      to__activity_id
    });
    if (res.success) {
      message.success("操作成功");
      this.queryActivityList();
    }
  };
  topActivity = async activity_id => {
    this.setState({
      loading: true
    });
    let res = await topActivity({ activity_id });
    if (res.success) {
      message.success("置顶成功");
      this.queryActivityList();
    }
  };
  createOrUpdateActivity = async (activity_id, status) => {
    let data = {
      activity_req: {
        mini_app_id: cookie.load("mini_app_id"),
        activity_id: activity_id,
        status
      }
    };
    let res = await createOrUpdateActivity(data);
    if (res.success) {
      if (status === 1) {
        message.success("已开启，将在首页展示", 0.8);
      } else {
        message.success("已关闭，将在首页隐藏", 0.8);
      }
      this.queryActivityList();
    }
  };
  cancel = e => {
    message.error("取消删除成功");
  };
  goToActivity = type => {
    this.context.router.history.push(`/market?type=${type}`);
  };
  goToActivityDetail = item => {
    let activity_id = item.activity_id;
    let type = item.type;
    const open = this.context.router.history.push;
    if (item.url) {
      return open(item.url);
    } else if (activity_id) {
      if (type === "lucky") {
        return open("/groupActivitys");
      } else if (type === "free_order" || type === "checkin") {
        return open(`/marketTab?type=edit&activity_id=${activity_id}`);
      } else if (type === "popupList") {
        return open(`/popUpPromotion?activity_id=${activity_id}`);
      }
      open(`/market?activity_id=${activity_id}`);
    } else {
      message.error("此营销活动未初始化");
    }
  };
  changeSwitch = (activity_id, e) => {
    if (!activity_id) {
      return message.error("此营销活动未初始化");
    }
    e.stopPropagation();
    this.createOrUpdateActivity(activity_id, e.target.checked ? 0 : 1);
  };
  componentDidMount() {
    this.queryActivityList();
  }
  render() {
    let { menu_code } = this.props;
    return (
      <div className="market-setting">
        <Spin spinning={this.state.loading}>
          <NavBar icon="icon-yingxiao" child={menu_code.market_setting} />
          <div className="content">
            {this.state.market_activity_list.map(elem => (
              <Fragment key={elem.activity_name}>
                <div className="shopDataTitle">
                  <i className="titleSign" />
                  {elem.activity_name}
                  <span className="sub-title">{elem.activity_desc}</span>
                </div>
                <div className="tools">
                  {elem.activity_sub_list.map((item, index) => (
                    <div className="market-activity-block" key={index} onClick={this.goToActivityDetail.bind(this, item)}>
                      {item.is_new ? <div className="new-tag">NEW</div> : null}
                      <div className="market-activity-block-icon">
                        <div className="iconBac" style={{ backgroundColor: item.backgroundColor }}>
                          <i className={item.icon} />
                        </div>
                      </div>
                      <div className="market-activity-content">
                        <div className="activity-title">
                          {item.name}
                          {(cookie.load("mini_app_type") === "flagship_version" && item.type === "enterGroup") ||
                          (cookie.load("mini_app_type") !== "flagship_version" &&
                            item.type !== "coupon" &&
                            item.type !== "pointsStore" &&
                            item.type !== "group_order" &&
                            item.type !== "popupList") ? (
                            <span className="market-activity-switch" onClick={this.changeSwitch.bind(this, item.activity_id)}>
                              <Switch checked={item.status ? true : false} />
                            </span>
                          ) : null}
                        </div>
                        <div className="activity-tag">
                          {item.tag_list.map((qitem, qindex) => (
                            <Tag key={qindex}>{qitem}</Tag>
                          ))}
                        </div>
                        <div className="market-activity-desc">{item.desc}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </Fragment>
            ))}
          </div>
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};
export default connect(mapStateToProps)(MarketSetting);
