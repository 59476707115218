import React from "react";
import NavBar from "@/view/component/common/NavBar.js";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { Button, Form, Input, Divider, Radio, Table, message, Icon, Select, Checkbox } from "antd";
import { queryVipGradeList, querySceneActivityDetail, createOrUpdateSceneActivity } from "@service/getData";
import { getQueryString } from "@common/common";
import CouponSelectModal from "@/view/component/Campaign/CouponSelectModal";
import PopOverTips from "@/view/component/common/PopOverTips/PopOverTips.js";
import "@css/sceneMarketing.scss";
const { Option } = Select;

class SceneMarketingDetail extends React.Component {
  constructor() {
    SceneMarketingDetail.contextTypes = {
      router: PropTypes.object.isRequired
    };
    super();
    this.state = {
      activity_id: "",
      activity_type: "",
      formData: {
        name: "",
        activity_time_type: "day",
        activity_time_value: "",
        notify_before_time: "",
        vip_determine: false,
        reward_package_list: [],
        status: 1
      },
      start_date: null,
      end_date: null,
      check_days: [],
      vip_grade_list: [],
      columns: [
        {
          title: "会员等级",
          dataIndex: "grade_name",
          render: text => {
            return text || "所有等级";
          }
        },
        {
          title: <span>积分回馈</span>,
          dataIndex: "point_feedback_rate",
          width: "200px",
          render: (text, record, index) => {
            return <Input addonAfter="倍" onChange={this.InputHandle2.bind(this, "point_feedback_rate", index)} value={text}></Input>;
          }
        },
        {
          title: <span> 送积分</span>,
          dataIndex: "point_num",
          width: "200px",
          render: (text, record, index) => {
            return <Input addonAfter="分" onChange={this.InputHandle2.bind(this, "point_num", index)} value={text}></Input>;
          }
        },
        {
          title: (
            <span>
              <PopOverTips content="多次发放的优惠券领取次数请设为不限次数" inners="送优惠券" />
            </span>
          ),
          dataIndex: "coupon_list",
          render: (text, record, index) => {
            return record.coupon_list.length ? (
              <>
                {record.coupon_list.length}张
                <Button type="link" onClick={this.showCouponVisiable.bind(this, record, index)}>
                  修改
                </Button>
              </>
            ) : (
              <Button type="link" onClick={this.showCouponVisiable.bind(this, record, index)} style={{ paddingLeft: 0 }}>
                添加优惠券
              </Button>
            );
          }
        }
      ],
      tableData: [
        {
          grade_name: "所有等级",
          point_feedback_rate: "",
          point_num: "",
          coupon_list: []
        }
      ],
      select_grade_index: 0,
      selected_coupon_list: [],
      coupon_visible: false,
      no_data: false
    };
  }
  componentDidMount() {
    let activity_id = getQueryString("activity_id") || "",
      activity_type = getQueryString("activity_type");

    this.setState(
      {
        activity_id,
        activity_type
      },
      () => {
        this.queryVipGradeList().then(res => {
          if (activity_id) {
            this.querySceneActivityDetail();
          }
        });
      }
    );
  }
  async queryVipGradeList() {
    let res = await queryVipGradeList({
      mini_app_id: cookie.load("mini_app_id")
    });
    let vip_grade_list = res.result;
    if (vip_grade_list.length === 0) {
      this.setState({
        no_data: true
      });
    }
    this.setState({
      vip_grade_list
    });
  }
  reGetVipGrageList = () => {
    this.queryVipGradeList().then(res => {
      this.changeTableData();
    });
  };
  async querySceneActivityDetail() {
    let res = await querySceneActivityDetail({
      mini_app_id: cookie.load("mini_app_id"),
      activity_id: this.state.activity_id
    });
    let formData = this.state.formData;
    for (let item in formData) {
      formData[item] = res.result[item];
    }
    let start_date, end_date, check_days;
    if (this.state.activity_type === "vip_day" && formData.activity_time_type) {
      let activity_time_value = formData.activity_time_value;
      let date_arr = activity_time_value.split(",");
      if (formData.activity_time_type === "month") {
        start_date = date_arr[0];
        end_date = date_arr[1];
      } else if (formData.activity_time_type === "week") {
        check_days = date_arr;
      }
    }
    this.setState(
      {
        formData,
        start_date,
        end_date,
        check_days
      },
      () => {
        this.changeTableData();
      }
    );
  }
  changeTimeType(data) {
    let formData = this.state.formData;
    formData.activity_time_type = data.target.value;
    this.setState({
      formData
    });
  }
  InputHandle(name, event) {
    event.persist();
    let formData = this.state.formData;
    formData[name] = event.target.value;
    this.setState({
      formData
    });
  }
  InputHandle2(name, index, event) {
    event.persist();
    let tableData = this.state.tableData;
    let value = event.target.value;
    if (isNaN(+value)) {
      return;
    }
    tableData[index][name] = value.trim();
    this.setState({
      tableData
    });
  }
  changeVipDetermine(data) {
    let formData = this.state.formData;
    formData.vip_determine = +data.target.value === 1 ? true : false;
    formData.reward_package_list = [];
    this.setState(
      {
        formData
      },
      () => {
        this.changeTableData();
      }
    );
  }
  changeTableData() {
    let tableData = [];
    let vip_grade_list = this.state.vip_grade_list,
      reward_package_list = this.state.formData.reward_package_list;
    if (this.state.formData.vip_determine) {
      //不同会员等级赠送不同的礼包
      if (reward_package_list.length !== vip_grade_list.length) {
        tableData = vip_grade_list.map((item, index) => ({
          coupon_list: (reward_package_list[index] && reward_package_list[index].coupon_list) || [],
          grade_level: item.grade_level,
          grade_name: item.grade_name,
          point_feedback_rate: (reward_package_list[index] && reward_package_list[index].point_feedback_rate) || "",
          point_num: (reward_package_list[index] && reward_package_list[index].point_num) || ""
        }));
      } else {
        tableData = [...reward_package_list];
      }
    } else if (reward_package_list.length) {
      tableData = [...reward_package_list];
    } else {
      tableData = [
        {
          grade_name: "所有等级",
          point_feedback_rate: "",
          point_num: "",
          coupon_list: []
        }
      ];
    }
    this.setState({
      tableData
    });
  }
  showCouponVisiable(item, index) {
    this.setState({
      select_grade_index: index,
      selected_coupon_list: [...item.coupon_list],
      coupon_visible: true
    });
  }
  onCouponOK = coupon_list => {
    let tableData = this.state.tableData,
      select_grade_index = this.state.select_grade_index;
    if (coupon_list.length > 10) {
      return message.error("最多添加10张优惠券");
    }
    tableData[select_grade_index].coupon_list = [...coupon_list];
    this.setState({
      coupon_visible: false,
      tableData
    });
  };
  onCouponCancel = () => {
    this.setState({
      coupon_visible: false
    });
  };
  deleteCoupon(index, index2) {
    let tableData = this.state.tableData;
    tableData[index].coupon_list.splice(index2, 1);
    this.setState({
      tableData
    });
  }
  async createOrUpdateSceneActivity() {
    let errMsg = "";
    if (!this.state.formData.name) {
      errMsg = "活动名称必填";
    }
    let activity_time_type = this.state.formData.activity_time_type;
    let data = {
      activity_time_type,
      notify_before_time: 24 + 9, //暂时写死前一天9点
      name: this.state.formData.name,
      status: this.state.formData.status,
      mini_app_id: cookie.load("mini_app_id"),
      activity_type: this.state.activity_type,
      reward_package_list: this.state.tableData,
      vip_determine: this.state.formData.vip_determine
    };
    if (this.state.activity_id) {
      data.activity_id = this.state.activity_id;
    }
    if (this.state.activity_type === "vip_day") {
      let activity_time_value = "";
      if (activity_time_type === "month") {
        if (!this.state.start_date || !this.state.end_date) {
          errMsg = "活动时间每月开始结束时间必填";
        }
        activity_time_value = this.state.start_date + "," + this.state.end_date;
      } else if (activity_time_type === "week") {
        if (this.state.check_days.length === 0) {
          errMsg = "活动时间每周至少勾选一项";
        }
        activity_time_value = this.state.check_days.join(",");
      }
      data.activity_time_value = activity_time_value;
    }
    if (errMsg) {
      message.warning(errMsg);
      return;
    }
    let res = await createOrUpdateSceneActivity(data);
    if (res.success) {
      message.success(this.state.activity_id ? "修改成功" : "新建成功");
      this.goBack();
    }
  }
  goBack() {
    this.context.router.history.push(`/sceneMarketing`);
  }
  handleChangeStartDate = date => {
    this.setState({
      start_date: date
    });
  };
  handleChangeEndDate = date => {
    this.setState({
      end_date: date
    });
  };
  onChangeDays = days => {
    this.setState({
      check_days: days
    });
  };
  goToGradeManage = () => {
    window.open(window.location.origin + "/gradeManage", "_blank");
  };
  render() {
    let { menu_code } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        span: 3
      },
      wrapperCol: {
        span: 9
      }
    };
    const formItemLayout2 = {
      labelCol: {
        span: 3
      },
      wrapperCol: {
        span: 18
      }
    };
    const radioStyle = {
      display: "block",
      width: "100px",
      height: "30px",
      lineHeight: "30px",
      marginBottom: "10px"
    };
    const date_list = [];
    for (let i = 1; i < 32; i++) {
      date_list.push(i);
    }
    const options = [
      { label: "周一", value: "1" },
      { label: "周二", value: "2" },
      { label: "周三", value: "3" },
      { label: "周四", value: "4" },
      { label: "周五", value: "5" },
      { label: "周六", value: "6" },
      { label: "周日", value: "7" }
    ];
    return (
      <div className="page scene-marketing">
        <NavBar
          icon="icon-yingxiao"
          father={menu_code.scene_marketing}
          child={
            this.state.activity_id
              ? "编辑" + (this.state.activity_type === "birth" ? "生日" : "会员日") + "营销"
              : "新建" + (this.state.activity_type === "birth" ? "生日" : "会员日") + "营销"
          }
          router="sceneMarketing"
        />
        <div className="content">
          <div className="shopDataTitle">
            <i className="titleSign" />
            基本信息
          </div>
          <Divider />
          <div className="form-content">
            <Form {...formItemLayout} className="form">
              <Form.Item label="活动名称" extra={"30个字以内"} required>
                <Input
                  value={this.state.formData.name}
                  className="form-long-content"
                  placeholder="请输入活动名称"
                  maxLength={30}
                  onChange={this.InputHandle.bind(this, "name")}
                />
              </Form.Item>
              <Form.Item label="活动时间" required>
                <Radio.Group value={this.state.formData.activity_time_type} onChange={this.changeTimeType.bind(this)}>
                  {this.state.activity_type === "vip_day" ? (
                    <>
                      <Radio style={radioStyle} value="month">
                        每月
                        <Select
                          value={this.state.start_date}
                          onChange={this.handleChangeStartDate.bind(this)}
                          style={{ width: 100, margin: "0 10px" }}
                          disabled={this.state.formData.activity_time_type !== "month"}>
                          {date_list.map(item => {
                            return !this.state.end_date || (this.state.end_date && item < this.state.end_date) ? (
                              <Option key={item}>{item}</Option>
                            ) : null;
                          })}
                        </Select>
                        日至
                        <Select
                          value={this.state.end_date}
                          onChange={this.handleChangeEndDate.bind(this)}
                          style={{ width: 100, margin: "0 10px" }}
                          disabled={this.state.formData.activity_time_type !== "month"}>
                          {date_list.map(item => {
                            return !this.state.start_date || (this.state.start_date && item > this.state.start_date) ? (
                              <Option key={item}>{item}</Option>
                            ) : null;
                          })}
                        </Select>
                        日
                      </Radio>
                      <Radio style={radioStyle} value="week">
                        每周
                        <Checkbox.Group
                          value={this.state.check_days}
                          disabled={this.state.formData.activity_time_type !== "week"}
                          style={{ marginLeft: "20px" }}
                          options={options}
                          onChange={this.onChangeDays}
                        />
                      </Radio>
                    </>
                  ) : (
                    <>
                      <Radio style={radioStyle} value="day">
                        <PopOverTips
                          styleType="inner"
                          content="设置按天的享受生日权益，明天起过生日的用户将可以享受权益"
                          inners="生日当天"
                        />
                      </Radio>
                      <Radio style={radioStyle} value="week">
                        <PopOverTips styleType="inner" content="设置按周享受生日权益，下周起过生日的用户将可以享受权益" inners="生日当周" />
                      </Radio>
                      <Radio style={radioStyle} value="month">
                        <PopOverTips styleType="inner" content="设置按月享受生日权益，下月起过生日的用户将可以享受权益" inners="生日当月" />
                      </Radio>
                    </>
                  )}
                </Radio.Group>
              </Form.Item>
            </Form>
          </div>
          <div className="shopDataTitle">
            <i className="titleSign" />
            权益礼包
          </div>
          <Divider />
          <div className="form-content">
            <Form className="form">
              <Form.Item label="会员等级规则" {...formItemLayout2}>
                <Radio.Group value={this.state.formData.vip_determine ? "1" : "0"} onChange={this.changeVipDetermine.bind(this)}>
                  <Radio style={{ display: "block", width: "100%" }} value="0">
                    <span className="mb20">所有等级会员，同一规则</span>
                  </Radio>
                  <Radio style={{ display: "block", width: "100%" }} value="1">
                    <span className="mb20">不同等级会员，不同规则</span>
                  </Radio>
                </Radio.Group>
                <Table
                  columns={this.state.columns}
                  pagination={false}
                  dataSource={this.state.no_data ? [] : this.state.tableData}
                  rowKey={record => record.grade_name}
                  bordered={true}
                  locale={{
                    emptyText: (
                      <div>
                        您尚未配置过会员等级，请先去配置
                        <Button type="link" onClick={this.goToGradeManage.bind(this)} style={{ paddingLeft: 0 }}>
                          等级管理
                        </Button>
                        <span className="text-note" onClick={this.reGetVipGrageList}>
                          刷新
                        </span>
                      </div>
                    )
                  }}
                />
              </Form.Item>
            </Form>
          </div>
          <div className="shopDataTitle">
            <i className="titleSign" />
            通知设置
          </div>
          <Divider />
          <Form {...formItemLayout2}>
            <Form.Item label="通知渠道">
              <div>小程序订阅消息</div>
              <div className="message-content">
                <div className="flex">
                  <div className="label">消息内容：</div>
                  <div className="right-content">
                    <div>尊敬的XX会员，您的生日快到啦，特意给您准备了专属权益：XX优惠劵、XX优惠劵和XX积分；</div>
                    <div>已准时到账，记得及时使用哦！</div>
                  </div>
                </div>
                <div className="flex">
                  <div className="label">跳转路径：</div>
                  <div className="right-content">
                    <div>我的优惠劵</div>
                  </div>
                </div>
              </div>
            </Form.Item>
            <Form.Item label="发放权益、通知时间" required>
              <div className="flex">
                在活动生效 <div>前一天</div> 的 <div>09:00</div> 开始通知会员
              </div>
              <div className="tips">如果没有选择通知渠道，系统将不会给客户发送消息通知，但是仍然会发放权益</div>
            </Form.Item>
          </Form>
          <footer>
            <Button type="primary" shape="round" onClick={this.createOrUpdateSceneActivity.bind(this)}>
              保存
            </Button>
            <Button shape="round" onClick={this.goBack.bind(this)}>
              取消
            </Button>
          </footer>
        </div>
        <CouponSelectModal
          selected_coupon_list={this.state.selected_coupon_list}
          visible={this.state.coupon_visible}
          onOk={this.onCouponOK}
          onCancel={this.onCouponCancel}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};
const SceneMarketingDetailForm = Form.create({ name: "coupon_form" })(SceneMarketingDetail);
export default connect(mapStateToProps)(SceneMarketingDetailForm);
