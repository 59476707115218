import React, { Component } from 'react';
import './scss/campaignVideo.scss'
class CampaignVideo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="campaignVideo">
                <video
                    src={this.props.url}
                    className="video"
                    preload='true'
                    muted
                    controls
                    playsInline
                    webkit-playsinline='true'
                    x-webkit-airplay="allow"
                    x5-playsinline='true'
                    poster={this.props.poster}
                />
            </div>
        );
    }
}

export default CampaignVideo;