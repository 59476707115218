import React, { Fragment } from "react";
import NavBar from "@/view/component/common/NavBar.js";
import { connect } from "react-redux";
import "@css/activitySetting.scss";
import { Button, message, Spin } from "antd";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import { getQueryString } from "@common/common";
import CampaignShowPhone from "@/view/component/Campaign/CampaignShowPhone";
import Activity from "./children/Activity";
import LiveActivity from "./children/LiveActivity";
import Banner from "./children/Banner";
import GoodsActivity from "./children/GoodsActivity";
import VideoActivity from './children/VideoActivity'
import {
	queryActivityList,
	createOrUpdateActivity,
	exchangeActivitySortNum,
	topActivity,
	createOrUpdateBannerActivity,
	createOrUpdateImageAdActivity,
	dragActivity,
	queryCouponList
} from "@service/getData";
let callBackFn;
class ActivitySetting extends React.Component {
	constructor() {
		super();
		ActivitySetting.contextTypes = {
			router: PropTypes.object.isRequired
		};
		this.state = {
			activity_list: [],
			loading: true,
			campaign_id: null,
			campaign_show: {},
			campaign_show_visible: false,
			campaign: {},
			visible: false,
			banner_visible: false,
			component: {},
			title: null,
			refresh: false,
			activity_subtype_desc: {
				buyEveryDay: "每日必买",
				memberDay: "会员日",
				timeLimitBuy: "限时秒杀",
				advertisement: "图片广告",
				goodsListSlide: "商品滑动列表",
				scroll: "轮播海报",
				coupon: "优惠券",
				iconLinkList: "图文导航",
				roomInfoList: "直播",
				video: '视频组件',
			},

			activity_id: null,
			activity_subtype: null,
			display: "none",
			common_components: [
				{
					type: "scroll",
					icon: "icon-banner",
					title: "轮播海报",
					support: ["base_version", "special_version", "flagship_version"]
				},
				{
					type: "advertisement",
					icon: "icon-guanggao",
					title: "图片广告",
					support: ["base_version", "special_version", "flagship_version"]
				},
				{
					type: "iconLinkList",
					icon: "icon-tuwendaohang",
					title: "图文导航",
					support: ["flagship_version"]
				},
				{
					type: 'video',
					icon: 'icon-shipin',
					title: '视频',
					support: ['base_version', 'special_version', 'flagship_version'],
				},
				{
					type: "goodsListSlide",
					icon: "icon-huadong",
					title: "商品滑动",
					support: ["base_version", "special_version", "flagship_version"]
				},
			],
			market_components: [
				// {
				// 	type: "buyEveryDay",
				// 	icon: "icon-meiri",
				// 	title: "每日必买",
				// 	support: ["base_version", "special_version", "flagship_version"]
				// },
				// {
				// 	type: "timeLimitBuy",
				// 	icon: "icon-Artboard",
				// 	title: "限时秒杀",
				// 	support: ["base_version", "special_version", "flagship_version"]
				// },
				// {
				// 	type: "memberDay",
				// 	icon: "icon-huiyuan",
				// 	title: "会员日",
				// 	support: ["base_version", "special_version", "flagship_version"]
				// },
				{
					type: "coupon",
					icon: "icon-youhuiquan",
					title: "优惠券",
					support: ["flagship_version"]
				},
				{
					type: "roomInfoList",
					icon: "icon-bofang",
					title: "直播",
					support: ["flagship_version"]
				}
			],
			show_components: [],
			window_height: document.documentElement.clientHeight - 190,
		};
	}

	ComponentShow = type => {
		let title = this.state.activity_subtype_desc[type];
		this.setState({
			visible: type !== "scroll" ? true : false,
			banner_visible: type === "scroll" ? true : false,
			title,
			activity_subtype: type,
			activity_id: null,
			display: true
		});
	};
	editComponentShow = (activity_subtype, activity_id) => {
		let title = this.state.activity_subtype_desc[activity_subtype];
		this.setState(
			{
				activity_subtype,
				activity_id,
				title,
				display: true
			},
			() => {
				if (activity_subtype === "advertisement" || activity_subtype === "scroll" || activity_subtype === "iconLinkList") {
					this.banner.queryActivityDetail(activity_id);
				}
				else if (activity_subtype === "coupon") {
					this.activity.queryActivityDetail(activity_id);
				}
				else if (activity_subtype === "roomInfoList") {
					this.liveActivity.queryActivityDetail(activity_id);
				}
				else if (activity_subtype === 'video') {
					this.VideoActivity.queryActivityDetail(activity_id);
				}
				else {
					this.goodsActivity.queryActivityDetail(activity_id);
				}
			}
		);
	};
	onOk = () => {
		if (this.state.activity_subtype === "advertisement" || this.state.activity_subtype === "scroll" || this.state.activity_subtype === "iconLinkList") {
			this.banner.queryActivityDetail(this.state.activity_id);
		}
		else if (this.state.activity_subtype === "coupon") {
			this.activity.queryActivityDetail(this.state.activity_id);
		}
		else if (this.state.activity_subtype === "video") {
			this.VideoActivity.queryActivityDetail(this.state.activity_id)
		}
		else {
			this.goodsActivity.queryActivityDetail(this.state.activity_id);
		}
		this.queryActivityList(null);
	};
	queryActivityList = async status => {
		let data = {
			mini_app_id: cookie.load("mini_app_id"),
			activity_type: "campaign",
			campaign_id: this.state.campaign_id
		};
		let res = await queryActivityList(data);
		if (res.success) {
			let activity_list = res.result.activity_res_list;
			activity_list.forEach(item => {
				switch (item.activity_subtype) {
					case "scroll":
						if (item.activity_res_list.length) {
							item.thumbnail_url = item.activity_res_list[0].thumbnail_url;
						} else {
							item.thumbnail_url = "http://udh.oss-cn-hangzhou.aliyuncs.com/ba1f25aa-21bf-4628-94e1-8cc5f7d72970";
						}
						break;
					case "advertisement":
						if (!item.activity_res_list.length) {
							item.activity_res_list = [{ thumbnail_url: "http://udh.oss-cn-hangzhou.aliyuncs.com/510dd008-0397-4821-9ae5-a4bb1584d103" }];
						}
						break;
					case "goodsListSlide":
						if (!item.thumbnail_url) {
							item.thumbnail_url = "http://udh.oss-cn-hangzhou.aliyuncs.com/56647312-7c59-4a93-bdb9-fb2a3e029e24";
						}
						break;
					case "coupon":
						item.thumbnail_url = "http://udh.oss-cn-hangzhou.aliyuncs.com/efe69fb1-f0fc-41bb-b9bc-e0145d56aec3";
						break;
					default:
						if (!item.thumbnail_url) {
							item.thumbnail_url = "http://udh.oss-cn-hangzhou.aliyuncs.com/510dd008-0397-4821-9ae5-a4bb1584d103";
						}
				}
			});
			this.setState(
				{
					activity_list,
					loading: false
				},
				() => {
					if (1 === status) {
						if (
							this.state.activity_subtype === "advertisement" ||
							this.state.activity_subtype === "scroll" ||
							this.state.activity_subtype === "iconLinkList"
						) {
							this.banner.queryActivityDetail(this.state.activity_id);
						} else if (this.state.activity_subtype === "coupon") {
							this.activity.queryActivityDetail(this.state.activity_id);
						} else if (this.state.activity_subtype === "video") {
							this.VideoActivity.queryActivityDetail(this.state.activity_id);
						} else {
							this.goodsActivity.queryActivityDetail(this.state.activity_id);
						}
						let div = document.getElementsByClassName("campaign-show-phone")[0];
						div.scrollTop = div.scrollHeight;
					}
					if (callBackFn) {
						callBackFn();
						callBackFn = null;
					}
				}
			);
		}
	};
	createOrUpdateActivity = async (data, index) => {
		let res = await createOrUpdateActivity(data);
		if (res.success) {
			if (data.activity_req.status === 1) {
				message.success("成功添加组件");
				let title = this.state.activity_subtype_desc[data.activity_req.activity_subtype];
				this.setState(
					{
						activity_subtype: data.activity_req.activity_subtype,
						activity_id: res.result.activity_id,
						title,
						display: true
					},
					() => {
						this.queryActivityList(1);
					}
				);
			} else {
				message.success("成功删除组件");
				let activity_list = this.state.activity_list;
				activity_list.splice(index, 1);
				this.setState({
					activity_id: null,
					display: "none",
					title: null,
					activity_subtype: null,
					activity_list
				});
			}
		}
	};
	deleteActivity = (activity_id, index) => {
		let data = {
			activity_req: {
				mini_app_id: cookie.load("mini_app_id"),
				activity_id: activity_id,
				status: 0,
				activity_type: "campaign",
				campaign_id: this.state.campaign_id
			}
		};
		this.createOrUpdateActivity(data, index);
	};
	createOrUpdateBannerActivity = async type => {
		let data = {
			activity_req: {
				mini_app_id: cookie.load("mini_app_id"),
				campaign_id: this.state.campaign_id,
				activity_name: type === "banner" ? "轮播海报" : "图文导航",
				activity_type: "campaign",
				activity_subtype: type,
				status: 1,
				sort_num: 1
			}
		};
		let res = await createOrUpdateBannerActivity(data);
		if (res.success) {
			message.success("组件创建成功");
			let title = this.state.activity_subtype_desc[type];
			this.setState(
				{
					activity_subtype: type,
					activity_id: res.result.activity_id,
					title,
					display: true
				},
				() => {
					this.queryActivityList(1);
				}
			);
		}
	};
	createOrUpdateImageAdActivity = async (type = "advertisement") => {
		let data = {
			activity_req: {
				mini_app_id: cookie.load("mini_app_id"),
				campaign_id: this.state.campaign_id,
				activity_name: "图片广告",
				activity_type: "campaign",
				activity_subtype: type,
				template_type: 1,
				status: 1,
				sort_num: 1
			}
		};
		let res = await createOrUpdateImageAdActivity(data);
		if (res.success) {
			message.success("组件创建成功");
			let title = this.state.activity_subtype_desc[type];
			this.setState(
				{
					activity_subtype: type,
					activity_id: res.result.activity_id,
					title,
					display: true
				},
				() => {
					this.queryActivityList(1);
				}
			);
		}
	};
	queryCouponList = async () => {
		let data = {
			mini_app_id: cookie.load("mini_app_id"),
			// 5: 进行中 和 未开始
			// 1: 进行中
			status: 5,
			page: 1,
			page_size: 10
		};
		let res = await queryCouponList(data);
		if (res.success) {
			let coupon_list = res.result.coupon_res_list.map(item => {
				return {
					coupon_id: item.coupon_id,
					status: 1
				};
			});
			let image_url =
				coupon_list.length === 1
					? "http://udh.oss-cn-hangzhou.aliyuncs.com/4ac14e7d-eb7b-4016-99ad-8e031b4f6026"
					: "http://udh.oss-cn-hangzhou.aliyuncs.com/f5554772-c6e5-466a-be34-131c3303b9e9";
			let activity = {
				activity_req: {
					mini_app_id: cookie.load("mini_app_id"),
					campaign_id: this.state.campaign_id,
					activity_name: "优惠券",
					activity_type: "campaign",
					activity_subtype: "coupon",
					status: 1,
					is_auto: 1,
					activity_coupon_req_list: coupon_list,
					image_url
				}
			};
			this.createOrUpdateActivity(activity);
		}
	};
	createActivity = (type, callback) => {
		if (callback) {
			callBackFn = callback;
		}
		if (type === "advertisement") {
			return this.createOrUpdateImageAdActivity();
		}
		if (type === "scroll" || type === "iconLinkList") {
			return this.createOrUpdateBannerActivity(type);
		}
		if (type === "coupon") {
			return this.queryCouponList();
		}
		if (type === 'video') {
			return this.createVideo();
		}
		let target_type = null;
		let activity = this.state.common_components.find(item => item.type === type);
		if (type === "goodsListSlide") {
			target_type = "goodsList";
		}
		if (!activity) {
			activity = this.state.market_components.find(item => item.type === type);
			if (activity.activity_subtype !== "") {
				target_type = "roomInfoList";
			}
		}
		let data = {
			activity_req: {
				mini_app_id: cookie.load("mini_app_id"),
				activity_name: activity.title,
				activity_type: "campaign",
				activity_subtype: type,
				status: 1,
				target_type,
				campaign_id: this.state.campaign_id
			}
		};
		if (target_type === "roomInfoList") {
			data.button_back_value = "#0D87EB";
			data.button_back_style = 0;
		}
		this.createOrUpdateActivity(data);
	};
	// 将视频组件拖入时触发
	createVideo() {
		const data = {
			activity_req: {
				activity_name: '视频',
				activity_subtype: 'video',
				mini_app_id: cookie.load('mini_app_id'),
				campaign_id: this.state.campaign_id,
				activity_type: 'campaign',
				status: 1,
			},
		};
		this.createOrUpdateActivity(data);
	}
	exchangeActivitySortNum = async (index, isUp) => {
		this.setState({
			loading: true
		});
		let list = [...this.state.activity_list],
			from_activity_id = list[index].activity_id,
			to_activity_id;

		if (isUp < 0) {
			to_activity_id = list[index - 1].activity_id;
		} else {
			to_activity_id = list[index + 1].activity_id;
		}
		let res = await exchangeActivitySortNum({
			from_activity_id,
			to_activity_id
		});
		if (res.success) {
			message.success("操作成功");
			this.queryActivityList(null);
		}
	};
	topActivity = async activity_id => {
		this.setState({
			loading: true
		});
		let res = await topActivity({ activity_id });
		if (res.success) {
			message.success("置顶成功");
			this.queryActivityList(null);
		}
	};
	cancel = e => {
		message.error("取消删除成功");
	};
	dragActivity = async (toIndex, fromIndex, list, hidesuccess = 0) => {
		let activity_list = [...this.state.activity_list],
			from_activity_id = activity_list[fromIndex].activity_id,
			to_activity_id =
				fromIndex > toIndex
					? activity_list[toIndex].activity_id
					: (activity_list[toIndex + 1] && activity_list[toIndex + 1].activity_id) || null;
		let data = { from_activity_id };
		if (to_activity_id) {
			data.to_activity_id = to_activity_id;
		}
		let res = await dragActivity(data);
		if (res.success) {
			if (!hidesuccess) {
				message.success("操作成功");
			}
			this.setState({
				activity_list: list
			});
		}
	};
	onRef(name, ref) {
		switch (name) {
			case "activity":
				this.activity = ref;
				break;
			case "banner":
				this.banner = ref;
				break;
			case "goodsActivity":
				this.goodsActivity = ref;
				break;
			case "liveActivity":
				this.liveActivity = ref;
				break;
			case "VideoActivity":
				this.VideoActivity = ref;
				break;
			default:
				break;
		}
	}
	dragstart_handler = ev => {
		ev.dataTransfer.dropEffect = "copy";
		ev.dataTransfer.setData("activity_type", ev.target.dataset.type);
	};

	componentDidMount() {
		let show_components = [
			{ title: "基础组件", list: this.state.common_components },
			{ title: "营销组件", list: this.state.market_components }
		];
		let campaign_id = getQueryString("campaign_id");
		this.setState(
			{
				show_components,
				campaign_id,
				campaign: {
					campaign_id
				}
			},
			() => {
				this.queryActivityList(null);
				let copyDragList = document.getElementById("copyDragList");
				copyDragList.addEventListener("dragstart", this.dragstart_handler);
			}
		);
	}
	render() {
		let { menu_code } = this.props;
		return (
			<div className="activity-setting">
				<Spin spinning={this.state.loading}>
					<NavBar icon="icon-dianpu2" child="编辑页面" father={menu_code.activity_setting} router="shopPainting" />
					<div className="content">
						<div className="component-add">
							<div className="title">
								第一步：选择组件：
                				<span className="component-kind">（拖拽添加）</span>
							</div>
							<div className="components" id="copyDragList">
								{this.state.show_components.map((item, index) => (
									<Fragment key={item.title}>
										<div className={index !== 0 ? "market-title" : ""}>
											<span className="title">{item.title}</span>
										</div>
										<div className="drag-btn-list">
											{item.list.map(qitem =>
												qitem.support.find(zitem => zitem === cookie.load("mini_app_type")) ? (
													<Button style={{ cursor: "move" }} data-type={qitem.type} key={qitem.title} draggable={true} type="primary" ghost>
														<i className={`iconfont ${qitem.icon}`} />
														{qitem.title}
													</Button>
												) : null
											)}
										</div>
									</Fragment>
								))}
							</div>
						</div>
						<div className="activity-show">
							<div className="title">
								第二步：效果预览
                				<span className="component-kind">（选中组件可以进行编辑组件内容）</span>
							</div>
							<CampaignShowPhone
								activity_list={this.state.activity_list}
								window_height={this.state.window_height}
								campaign={this.state.campaign}
								editComponentShow={this.editComponentShow}
								activity_id={this.state.activity_id}
								exchangeActivitySortNum={this.exchangeActivitySortNum}
								deleteActivity={this.deleteActivity}
								cancel={this.props.cancel}
								dragActivity={this.dragActivity}
								createActivity={this.createActivity}
							/>
						</div>
						<div className="component-content">
							<div className="title">
								第三步：编辑组件内容
                				<span className="component-kind">（提交后可在预览区查看效果）</span>
							</div>
							<div className="component-edit" style={{ height: this.state.window_height }}>
								<div className="component-edit-content" style={{ display: this.state.display }}>
									<div className="component-edit-title">
										<span>{this.state.title}</span>
										{this.state.activity_subtype === "coupon" ? <span className="text-danger">最多添加10张</span> : null}
										{this.state.activity_subtype === "roomInfoList" || this.state.activity_subtype === "scroll" ? (
											<span className="text-danger">最多添加10个</span>
										) : null}
										{this.state.activity_subtype === "iconLinkList" ? <span className="text-danger">最多添加5个导航</span> : null}
										<span className="go-to">查看教程 →</span>
									</div>
									{this.state.activity_subtype === "coupon" ? (
										<Activity
											onOK={this.onOk}
											title={this.state.title}
											activity_id={this.state.activity_id}
											my_activity_subtype={this.state.activity_subtype}
											campaign_id={this.state.campaign_id}
											onRef={this.onRef.bind(this)}
										/>
									) : this.state.activity_subtype === "advertisement" ||
										this.state.activity_subtype === "scroll" ||
										this.state.activity_subtype === "iconLinkList" ? (
												<Banner
													onOK={this.onOk}
													title={this.state.title}
													activity_id={this.state.activity_id}
													activity_subtype={this.state.activity_subtype}
													campaign_id={this.state.campaign_id}
													onRef={this.onRef.bind(this)}
												/>
											) : this.state.activity_subtype === "roomInfoList" ? (
												<LiveActivity
													onOK={this.onOk}
													title={this.state.title}
													activity_id={this.state.activity_id}
													activity_subtype={this.state.activity_subtype}
													campaign_id={this.state.campaign_id}
													onRef={this.onRef.bind(this)}
												/>
											) : this.state.activity_subtype === 'video' ? (
												<VideoActivity
													onOK={this.onOk}
													title={this.state.title}
													onRef={this.onRef.bind(this)}
													activity_id={this.state.activity_id}
													campaign_id={this.state.campaign_id}
												/>
											) : (
														<GoodsActivity
															title={this.state.title}
															onOK={this.onOk}
															activity_id={this.state.activity_id}
															my_activity_subtype={this.state.activity_subtype}
															campaign_id={this.state.campaign_id}
															onRef={this.onRef.bind(this)}
														/>
													)
									}
								</div>
							</div>
						</div>
					</div>
				</Spin>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		menu_code: state.menuCode
	};
};
export default connect(mapStateToProps)(ActivitySetting);
