import React from 'react'
import {Tooltip,Switch,Input,Button,Form,Tag,message} from 'antd'
import '@css/bonusRecharge.scss'
import {queryMiniAppPageConfigList,createOrUpdateMiniAppPageConfig} from'@service/getData'
import cookie from 'react-cookies'
import {formatNumber} from '@common/common'
class IntegralSetting extends React.Component{
    constructor(){
        super()
        this.state={
            mini_app_page_config_res:{},
            mini_app_page_config_res_list:[],
            money:null,
            page_config_id:null
        }
    }
    queryMiniAppPageConfigList = async ()=>{
        let data={
            config_type:"with_draw",
            mini_app_id:cookie.load("mini_app_id")
        }
        let res=await queryMiniAppPageConfigList(data)
        if(res.success){
            let {mini_app_page_config_res,mini_app_page_config_res_list,page_config_id}=res.result
            this.setState({
                mini_app_page_config_res,
                mini_app_page_config_res_list,
                page_config_id
            })

        }
    }
    createOrUpdateMiniAppPageConfig=async (data)=>{
        let res=await createOrUpdateMiniAppPageConfig(data)
        if(res.success){
            message.success(`${!data.mini_app_page_config_req.mini_app_page_config_id?"成功添加":"更新成功"}`)
            this.setState({
                money:null
            },()=>{
                this.queryMiniAppPageConfigList()
            })

        }

    }
    onClose=(mini_app_page_config_id,e)=>{
        e.preventDefault();
        let data={
            mini_app_page_config_req:{
                mini_app_id:cookie.load("mini_app_id"),
                page_config_id:this.state.page_config_id,
                status:0,
                mini_app_page_config_id

            }
        }
        this.createOrUpdateMiniAppPageConfig(data)
    }

    onChange=(checked)=>{
        if(checked&&this.state.mini_app_page_config_res_list.length===0){
            return message.error("至少添加一个提现金额")
        }
        let {mini_app_page_config_id,page_config_id}=this.state.mini_app_page_config_res
        let data={
            mini_app_page_config_req:{
                mini_app_page_config_id,
                page_config_id,
                effect:checked?1:0
            }
        }
        this.createOrUpdateMiniAppPageConfig(data)

    }
    onChangeMoney=(e)=>{
        this.setState({
            money:formatNumber(e.target.value)
        })


    }
    submitMoney=()=>{
        let money=Math.floor(this.state.money*100)
        if(money===0){
            return message.error("至少添加一个提现金额")
        }
        let page_config=this.state.mini_app_page_config_res_list.find(item=>item.page_config_value===money)
        if(page_config){
            return message.error("已存在该金额")
        }
        let data={
            mini_app_page_config_req:{
                mini_app_id:cookie.load("mini_app_id"),
                page_config_id:this.state.page_config_id,
                status:1,
                page_config_value:money
            }
        }
        this.createOrUpdateMiniAppPageConfig(data)
    }
    componentDidMount(){
        this.queryMiniAppPageConfigList()
    }

    render(){
        return (
            <div className="integral-setting">
                <div className="icon-filter-content">
                    <div
                        className="activity-withdraw">
                        <i className="icon-tubiao11 iconfont"/>
                    </div>
                    <div className="text-content">
                        <div
                            className="title">
                            提现
                            <Tooltip overlayClassName="market-tooltip" placement="bottom" title={
                                <img alt=""
                                    width="256"
                                    src=""
                                />}>
                                        <span className="text-note pic-show">
                                            示例图片
                                        </span>
                            </Tooltip>
                        </div>
                        <div>已结算现金买家可提现且无手续费，可设置提现门槛，达到提现金额可以提现。</div>
                    </div>
                </div>
                <Form>
                    <Form.Item label="是否开启自定义提现">
                        <Switch  checked={this.state.mini_app_page_config_res!==null&&this.state.mini_app_page_config_res.effect===1?true:false} onChange={this.onChange}/>

                    </Form.Item>
                    <Form.Item label="提现金额">
                        <div>
                            <div>
                                <Input  value={this.state.money}className="input-money" placeholder="输入其他金额" onChange={this.onChangeMoney} />
                                <Button type="primary" shape="round" onClick={this.submitMoney}>+其他金额</Button>
                            </div>
                        </div>
                        <div className="withdraw-choose">
                            {
                                this.state.mini_app_page_config_res_list.map((item,index)=>(
                                    <Tag
                                        key={index}
                                        className="frozen-money"
                                        closable
                                        onClose={this.onClose.bind(this,item.mini_app_page_config_id)}
                                    >
                                        {
                                            (item.page_config_value/100).toFixed(2)
                                        }

                                    </Tag>
                                ))
                            }
                        </div>

                    </Form.Item>

                </Form>
            </div>
        )
    }


}


export default IntegralSetting
