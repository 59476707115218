import React from 'react';
import '@css/codeManage.scss'
import PropTypes from 'prop-types'
import {Form, Input, Table, Button, DatePicker, Pagination, Select, Modal, message, Tooltip, Divider } from 'antd';
import {
    getTemplate,
    getAppAuditList,
    createOrUpdateMiniAppAudit,
    getExQrCode,
    getQrCodeGroup,
    getMiniAppQrCode,
    getCategory,
    submitMiniAppAudit,
    cancelSubmitMiniAppAudit,
    releaseMiniAppAudit,
    revertReleaseMiniAppAudit,
    bindTester
} from '@service/getData'
import NavBar from '@/view/component/common/NavBar.js'
import {connect} from "react-redux";
import cookie from "react-cookies";
import config from '@config/config'
const {RangePicker} = DatePicker;
const Option = Select.Option;
class CodeManage extends React.Component {
    constructor(props) {
        super();
        CodeManage.contextTypes = {
            router: PropTypes.object.isRequired
        }
        this.state = {
            my_qrcode:config.baseUrl+"/qrCode/detail?shop_id=",
            selectedRowKeys: [],
            data: [],
            template_list: [],
            publish_start_date: null,
            publish_end_date: null,
            page: 1,
            page_size: 50,
            total: 0,
            selectedRows: [],
            visible: false,
            submitVisible: false,
            testerVisible:false,
            category_list: [],
            mini_app_audit: {},
            ex_qrcode_url: null,
            ex_qrcode_group_url: null,
            qrcode_url: null,
            tester:null,
            user_version:null,
            mini_app_id:null,
            columns: [{
                title: '小程序名',
                dataIndex: 'mini_app_name',
                render: (text, record) => (
                    <span>{text}
        </span>
                ),
            }, {
                title: '测试版本',
                dataIndex: 'user_version',
            },{
                title: '正式版本',
                dataIndex: 'release_user_version',
            },{
                title: '最新版本',
                dataIndex: 'new_user_version ',
                render:()=>{
                    return this.state.template_list.length?this.state.template_list[0].user_version:null
                }
            }, {
                title: '状态',
                dataIndex: 'status',
                render: (text, record) => (
                    <div>
                        <span style={{"display": record.status === 0 ? true : 'none'}}>上传代码成功</span>
                        <span style={{"display": record.status === 1 ? true : 'none'}}>提交审核中</span>
                        <span style={{"display": record.status === 2 ? true : 'none'}}>取消审核</span>
                        <span style={{"display": record.status === 3 ? true : 'none'}}>审核失败</span>
                        <span style={{"display": record.status === 4 ? true : 'none'}}>审核成功</span>
                        <span style={{"display": record.status === 5 ? true : 'none'}}>发布失败</span>
                        <span style={{"display": record.status === 6 ? true : 'none'}}>发布成功</span>
                        <span style={{"display": record.status === 7 ? true : 'none'}}>发布退回</span>
                    </div>
                ),
            },
                {
                    title: '最后发布时间',
                    dataIndex: 'updated_at',
                },
                {
                    title: '查看小程序二维码',
                    dataIndex: 'qrcode',
                    render: (text, record) => (
                        <span>
            {
                record.status > -1 ? (<span>
                    <Tooltip
                        onVisibleChange={this.showToolTip.bind(this, record.mini_app_id)}
                        overlayClassName="combined-shape"
                        placement="topLeft"
                        title={<img alt="" width="112px" height="103" src={this.state.ex_qrcode_url}/>}
                    >
                        <span className="text-note">体验版</span>
                    </Tooltip>
                     <Tooltip
                         onVisibleChange={this.showToolTip.bind(this, record.mini_app_id)}
                         overlayClassName="combined-shape"
                         placement="topLeft"
                         title={<img alt="" width="112px" height="103" src={this.state.ex_qrcode_group_url}/>}
                     >
                        <span className="text-note">体验版(团长)</span>
                    </Tooltip>


                </span>) : null
            }{
                            record.updated_at ? (
                                <Tooltip
                                    onVisibleChange={this.showQrcodeQToolTip.bind(this, record.mini_app_id)}
                                    overlayClassName="combined-shape"
                                    placement="topLeft"
                                    title={<img alt="" width="112px" height="103" src={this.state.my_qrcode+record.shop_id+"&page=pages/home/index"}/>}
                                >
                                    <span className="text-note official" >正式版</span>
                                </Tooltip>
                            ) : null
                        }

            </span>
                    ),
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    render: (text, record) => (
                        <div>
                            <span style={{"display": record.status < 0 ? true : 'none'}}
                                  className="text-note"
                                  onClick={this.showModal.bind(this,record.mini_app_id)}
                            >上传代码</span>
                            <span style={{"display": record.status === 0 ? true : 'none'}} className="text-note"
                                  onClick={this.showSubmitModal.bind(this, record)}>提交审核</span>
                            <span style={{"display": record.status === 1 ? true : 'none'}} className="text-note"
                                  onClick={this.cancelSubmitMiniAppAudit.bind(this, record)}>取消审核</span>
                            <span style={{"display": record.status === 2 || record.status === 3 ? true : 'none'}}
                                  className="text-note" type="primary"
                                  onClick={this.showSubmitModal.bind(this, record)}>再次审核</span>
                            <span style={{"display": record.status === 4 ? true : 'none'}} className="text-note"
                                  type="primary"
                                  onClick={this.releaseMiniAppAudit.bind(this, record.mini_app_audit_id)}>发布代码</span>
                            <span style={{"display": record.status === 5 ? true : 'none'}} className="text-note"
                                  type="primary"
                                  onClick={this.releaseMiniAppAudit.bind(this, record.mini_app_audit_id)}
                            >再次发布</span>
                            <span className="text-note" onClick={this.showTesterModal.bind(this, record)} >绑定体验者</span>
                        </div>
                    ),

                }]
        }
    }

    async getTemplate() {
        let res = await getTemplate();
        let template_list = [...res.result.template_list].sort((a,b)=>{
            return  b.template_id - a.template_id
        });
        if (res.success) {
            this.setState({
                template_list
            })
        }
    }

    async getAppAuditList(data) {
        let res = await getAppAuditList(data);
        if (res.success) {
            this.setState({
                data: res.result.mini_app_list,
                total: res.result.total,
                selectedRows:[],
                selectedRowKeys:[],
                mini_app_id:null
            })
        }
    }

    async createOrUpdateMiniAppAudit(data) {

        const hide = message.loading('代码发布中..', 0);
        let res = await createOrUpdateMiniAppAudit(data);
        if (res.success) {
            setTimeout(hide, 0);
            message.success("成功：" + res.result.success_count + "个，失败：" + res.result.failed_count + "个")
        }

        this.handleSubmit();
    }

    async getExQrCode(mini_app_id) {
        let res = await getExQrCode(mini_app_id);
        this.setState({
            ex_qrcode_url: res
        })

    }
    async getQrCodeGroup(mini_app_id) {
        let res = await getQrCodeGroup(mini_app_id);
        this.setState({
            ex_qrcode_group_url: res
        })

    }

    async getCategory(mini_app_id) {
        let data = {
            mini_app_id: mini_app_id
        }

        let res = await getCategory(data);
        if (res.success) {
            this.setState({
                category_list: res.result.category_list
            })
        }
    }

    async submitMiniAppAudit(data) {
        let res = await submitMiniAppAudit(data);
        if (res.success) {
            message.success("提交审核成功")
        } else {
            message.error(res.msg)
        }
        this.handleSubmit();
    }

    async cancelSubmitMiniAppAudit(mini_app_audit) {
        let data = {
            mini_app_id: mini_app_audit.mini_app_id,
            mini_app_audit_id: mini_app_audit.mini_app_audit_id
        }
        let res = await cancelSubmitMiniAppAudit(data);
        if (res.success) {
            message.success("取消成功")
        } else {
            message.error(res.msg)
        }
        this.handleSubmit();
    }

    async releaseMiniAppAudit(mini_app_audit_id) {
        let mini_app_audit_list = []
        this.state.selectedRows.forEach(item => {

            mini_app_audit_list.push(item.mini_app_audit_id)
        })
        if (mini_app_audit_id) {
            mini_app_audit_list.push(mini_app_audit_id)
        }
        let data = {
            mini_app_audit_list: [...new Set(mini_app_audit_list)]
        }
        let res = await releaseMiniAppAudit(data);
        if (res.success) {
            message.success("成功：" + res.result.success_count + "个，失败：" + res.result.failed_count + "个")
        }
        this.handleSubmit();
    }

    async revertReleaseMiniAppAudit(mini_app_audit_id) {
        let res = await revertReleaseMiniAppAudit();
        if (res.success) {
            message.info("小程序发布退回成功")
        }
        this.handleSubmit();
    }

    componentDidMount() {
        this.getTemplate();
        this.handleSubmit();
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys,
            selectedRows
        });
    }
    handleSubmit = (e) => {
        if (e) {
            e.preventDefault();
        }
        let form = this.props.form.getFieldsValue();
        let data = {
            mini_app_name: form.mini_app_name ? form.mini_app_name : null,
            status: form.status ? form.status : null,
            app_id: form.app_id ? form.app_id : null,
            page_size: this.state.page_size,
            page_no: this.state.page,
            publish_start_date: this.state.publish_start_date ? this.state.publish_start_date : null,
            publish_end_date: this.state.publish_end_date ? this.state.publish_end_date : null
        }
        this.getTemplate();
        this.getAppAuditList(data);


    }
    showModal = (mini_app_id,e) => {
        this.setState({
            visible: true,
            mini_app_id:mini_app_id?mini_app_id:null
        });
    }

    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        },()=>{
            this.handleSubmit()
        })
    }
    onShowPageChange = (page) => {
        this.setState({
            page: page
        },()=>{
            this.handleSubmit()
        })
    }
    onShowTimeChange = (date, dateStr) => {
        this.setState({
            publish_start_date: dateStr[0],
            publish_end_date: dateStr[1],
        })
    }
    handleOk = (e) => {
        this.setState({
            visible: false,
        });
        let form = this.props.form.getFieldsValue();
        let dataList = [];
        let template=null
        this.state.template_list.forEach(item=>{
            if(item.template_id===form.template_id){
                template=item
            }
        })
        this.state.selectedRows.forEach(item => {
            let data = {
                mini_app_id: item.mini_app_id,
                template_id: template.template_id,
                user_version: template.user_version,
                user_desc: template.user_desc,
                ext_json: JSON.stringify({extAppid: item.app_id})
            }
            dataList.push(data)

        })
        if(this.state.mini_app_id){
            let data={
                mini_app_id: this.state.mini_app_id,
                template_id: template.template_id,
                user_version: template.user_version,
                user_desc: template.user_desc,
                ext_json: JSON.stringify({ext_json: {extAppid: this.state.mini_app_id}})
            }
            dataList.push(data)

        }
        this.createOrUpdateMiniAppAudit(dataList)
    }

    showSubmitModal = (data, e) => {
        this.props.form.resetFields();
        this.getCategory(data.mini_app_id);
        this.setState({
            submitVisible: true,
            mini_app_audit: data
        });
    }
    showTesterModal = (data, e) => {
        this.props.form.resetFields();
        this.setState({
            testerVisible: true,
            mini_app_audit: data
        });
    }
    handleSubmitOk = (e) => {
        this.setState({
            submitVisible: false,
        });
        let form = this.props.form.getFieldsValue();
        let data = {
            mini_app_audit_id: this.state.mini_app_audit.mini_app_audit_id,
            mini_app_id: this.state.mini_app_audit.mini_app_id,
            item_list: [{
                first_id: this.state.category_list[form.category].first_id,
                first_class: this.state.category_list[form.category].first_class,
                second_id: this.state.category_list[form.category].second_id,
                second_class: this.state.category_list[form.category].second_class,
                address: form.address,
                tag: form.tag,
                title: form.title
            }
            ]
        }
        this.submitMiniAppAudit(data)

    }
    handleTestOk = (e) => {
        this.setState({
            submitVisible: false,
        });
        let form = this.props.form.getFieldsValue();
        let data = {
            tester: form.tester,
            mini_app_id: this.state.mini_app_audit.mini_app_id,

        }
        this.bindTester(data)

    }


    handleCancel = (e) => {
        this.setState({
            visible: false,
            mini_app_id:null
        });
    }

    handleSubmitCancel = (e) => {
        this.setState({
            submitVisible: false,
            testerVisible:false
        })
        this.props.form.resetFields();
    }

    showToolTip = (e) => {
        this.getExQrCode(e)
        this.getQrCodeGroup(e)
    }

    gotoDetail(data, e) {
        if (data != null) {
            this.context.router.history.push("/codeDetail?mini_app_id=" + data)
        }
    }
    async getMiniAppQrCode() {
        let res = await getMiniAppQrCode(cookie.load("mini_app_id"));
        if (res) {
            this.setState({
                qrcode_url: res.replace("/0","/640")
            });
        }
    }
    showQrcodeQToolTip = (e) => {
        this.getMiniAppQrCode();
    }
    bindTester=async(data)=>{
        let res=await bindTester(data)
        if(res.success){
            message.success("绑定体验者成功")
            this.handleSubmitCancel()
        }else{
            message.error(res.msg)
        }

    }


    render() {
        const {getFieldDecorator} = this.props.form;
        const {selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true,
            onSelection: this.onSelection,
        };
        let {menu_code} = this.props
        return (
            <div className="codeManage">
                <NavBar icon="icon-guanliyuanshezhi" child={menu_code.code_manage}/>
                <div className="dataContent">
                    <div className="search-content">
                        <Form className="ant-vertical" layout="inline" onSubmit={this.handleSubmit}>
                            <Form.Item label="小程序名称">
                                {getFieldDecorator('mini_app_name')(
                                    <Input className="form-input" placeholder="输入小程序名称"/>
                                )}
                            </Form.Item>
                            <Form.Item label="AppID：">
                                {getFieldDecorator('app_id')(
                                    <Input className="form-input" placeholder="输入小程序Id"/>
                                )}
                            </Form.Item>
                            <Form.Item label="状态：">
                                {getFieldDecorator('status')(
                                    <Select className="form-input">
                                        <Option value="">全部</Option>
                                        <Option value="1">代码审核中</Option>
                                        <Option value="2">代码审核成功</Option>
                                        <Option value="3">代码审核失败</Option>
                                        <Option value="4">发布成功</Option>
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label="最后发布时间：">
                                {getFieldDecorator('date')(
                                    <RangePicker className="form-input-long" onChange={this.onShowTimeChange}/>
                                )}
                            </Form.Item>
                        </Form>
                        <div className="action">
                            <Button type="primary" onClick={this.onShowPageChange.bind(this,1)} shape="round">
                                查询
                            </Button>
                        </div>
                    </div>
                    <div className="action">
                        <Form layout="inline" onSubmit={this.onShowPageChange.bind(this,1)}>
                            <Form.Item>
                                <Button
                                    onClick={this.showModal.bind(this,null)}
                                    className="login-form-button" shape="round"
                                    disabled={this.state.selectedRows.length ? false : true}
                                >
                                    批量上传代码
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button className="login-form-button"
                                        shape="round"
                                        onClick={this.releaseMiniAppAudit.bind(this, null)}
                                        disabled={this.state.selectedRows.length ? false : true}
                                >
                                    批量发布
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <Table rowKey={record => record.mini_app_id} rowSelection={rowSelection}
                           columns={this.state.columns}
                           dataSource={this.state.data} pagination={false}/>
                    <Pagination
                        className="pagenation"
                        current={this.state.page}
                        pageSize={this.state.page_size}
                        total={this.state.total}
                        onChange={this.onShowPageChange}
                        onShowSizeChange={this.onShowSizeChange}
                        showTotal={(total) => `共 ${total} 条`}
                        pageSizeOptions={["50", "100", "150", "200"]}
                        showSizeChanger
                    />
                </div>
                <Modal
                    title="上传代码"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okButtonProps={{shape:'round'}}
                    cancelButtonProps={{shape:'round'}}
                >
                    <div className="commit_box">
                        <Form layout="inline">
                            <Form.Item label="小程序模版">
                                {getFieldDecorator('template_id',{
                                    initialValue: this.state.template_list[0]&&this.state.template_list[0].template_id
                                })(
                                    <Select style={{width: 400}}>
                                        {this.state.template_list.map((item, index) =>
                                            <Option key={item.template_id}
                                                    value={item.template_id}>
                                                {item.template_id}
                                                <Divider type="vertical" />
                                                {item.user_desc}
                                                <Divider type="vertical" />
                                                {item.user_version}
                                                </Option>
                                        )}
                                    </Select>
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    title="审核代码"
                    visible={this.state.submitVisible}
                    onOk={this.handleSubmitOk}
                    onCancel={this.handleSubmitCancel}
                    okButtonProps={{shape:'round'}}
                    cancelButtonProps={{shape:'round'}}
                >
                    <div className="commit_box">
                        <Form>
                            <Form.Item label="小程序模版">
                                {getFieldDecorator('category')(
                                    <Select style={{width: 200}}>
                                        {this.state.category_list.map((item, index) =>
                                            <Option key={index} value={index}>{item.second_class}</Option>
                                        )}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item label="page：" >
                                {getFieldDecorator('address',{
                                    initialValue: "pages/home/index",
                                })(
                                    <Input style={{width: 200}}  placeholder="输入page地址"/>
                                )}
                            </Form.Item>
                            <Form.Item label="标签：">
                                {getFieldDecorator('tag',{
                                    initialValue: "社区",
                                })(
                                    <Input style={{width: 200}} placeholder="输入标签"/>
                                )}
                            </Form.Item>
                            <Form.Item label="标题：">
                                {getFieldDecorator('title',{
                                    initialValue: "首页",
                                })(
                                    <Input style={{width: 200}} placeholder="输入标题"/>
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
                <Modal
                    title="绑定体验者"
                    visible={this.state.testerVisible}
                    onOk={this.handleTestOk}
                    onCancel={this.handleSubmitCancel}
                    okButtonProps={{shape:'round'}}
                    cancelButtonProps={{shape:'round'}}
                >
                    <div className="commit_box">
                        <Form>
                            <Form.Item label="微信号">
                                {getFieldDecorator('tester')(
                                    <Input style={{width: 200}} placeholder="输入微信号"/>
                                )}
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
            </div>
        );
    }
}

const CodeManageForm = Form.create({name: 'code_manage_form'})(CodeManage);

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode
    }
}
export default connect(mapStateToProps)(CodeManageForm);
