import React from "react";
import { Modal } from "antd";
import { queryCouponList } from "@service/getData";
import { Form, Input, Table, Button, Icon, Divider } from "antd/lib/index";
import { formatDate } from "@common/common";
import cookie from "react-cookies";
import "@css/couponSelectModal.scss";
import PropTypes from "prop-types";
class CouponSelectModal extends React.Component {
  constructor(props) {
    super(props);
    CouponSelectModal.contextTypes = {
      router: PropTypes.object.isRequired
    };
    this.state = {
      columns: [
        {
          title: "id",
          dataIndex: "coupon_id"
        },
        {
          title: "优惠券名称",
          dataIndex: "coupon_name"
        },
        {
          title: "优惠内容",
          dataIndex: "use_min_amount",
          render: (text, record) => {
            return `满${text / 100}减${record.discount_amount / 100}`;
          }
        },
        {
          title: "适用商品",
          dataIndex: "coupon_category",
          render: text => {
            return text === "goods_coupon" ? "指定商品" : "全部商品";
          }
        },
        {
          title: "有效时间",
          dataIndex: "start_time",
          render: (text, record) => {
            return record.effect_day
              ? `按领取之日起${record.effect_day}天内可用`
              : `${formatDate(new Date(text).getTime(), "Y-M-D")} 至 ${formatDate(new Date(record.end_time).getTime(), "Y-M-D")}`;
          }
        },
        {
          title: "剩余",
          dataIndex: "remain_num"
        },
        {
          title: "状态",
          dataIndex: "admin_coupon_status",
          render: text => {
            switch (text) {
              case 0:
                return "未开始";
              case 1:
                return <span className="text-green">进行中</span>;
              case 2:
                return <span className="text-remind">已过期</span>;
              case 3:
                return <span className="text-danger">已停止</span>;
              default:
                break;
            }
          }
        }
      ],
      selected_row_keys: [],
      coupon_list: [],
      selected_row_list: [],
      value: null
    };
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.selected_coupon_list && props.selected_coupon_list.length) {
      let selected_row_keys = props.selected_coupon_list.map(item => item.coupon_id);
      this.onSelectedRowKeysChange(selected_row_keys, props.selected_coupon_list);
    } else {
      this.setState({
        selected_row_keys: [],
        selected_row_list: []
      });
    }
  }
  queryCouponList = async () => {
    let data = {
      mini_app_id: cookie.load("mini_app_id"),
      // 5: 进行中 和 未开始
      // 1: 进行中
      status: 5,
      like_condition: this.state.value
    };

    let res = await queryCouponList(data);
    if (res.success) {
      this.setState({
        coupon_list: res.result.coupon_res_list
      });
    }
  };
  onSelectedRowKeysChange = (selected_row_keys, selected_row_list) => {
    if (this.props.single) {
      selected_row_keys = selected_row_keys.slice(selected_row_keys.length - 1);
      selected_row_list = selected_row_list.slice(selected_row_list.length - 1);
    }
    this.setState({
      selected_row_keys,
      selected_row_list
    });
  };
  onChange = e => {
    this.setState({
      value: e.target.value
    });
  };
  goToCouponList = () => {
    window.open(window.location.origin + "/couponManage", "_blank");
  };
  onOk = () => {
    this.props.onOk(this.state.selected_row_list);
    this.setState({
      selected_row_keys: []
    });
  };
  componentDidMount() {
    this.queryCouponList();
  }
  deleteCoupon(index) {
    let selected_row_keys = [...this.state.selected_row_keys],
      selected_row_list = [...this.state.selected_row_list];
    selected_row_list.splice(index, 1);
    selected_row_keys.splice(index, 1);
    this.setState({
      selected_row_keys,
      selected_row_list
    });
  }
  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selected_row_keys,
      onChange: this.onSelectedRowKeysChange,
      hideDefaultSelections: true,
      columnTitle: "选择"
    };
    return (
      <Modal
        className="coupon-select-modal"
        onCancel={this.props.onCancel}
        onOk={this.onOk}
        visible={this.props.visible}
        okButtonProps={{ shape: "round" }}
        cancelButtonProps={{ shape: "round" }}>
        {this.props.selected_coupon_list && this.props.selected_coupon_list.length ? (
          <>
            <div className="selected-coupon-list">
              <span>已选优惠券:</span>
              {this.state.selected_row_list.map((item, index) => (
                <span className="selected-coupon" key={item.coupon_id}>
                  {item.coupon_name}
                  <Icon onClick={this.deleteCoupon.bind(this, index)} className="img-close-icon" type="close-circle" theme="filled" />
                </span>
              ))}
            </div>
            <Divider />
          </>
        ) : null}
        <Form layout="inline">
          <Form.Item label="优惠券名称/序号：">
            <Input className="form-input" value={this.state.value} onChange={this.onChange} />
          </Form.Item>
          <Form.Item>
            <Button shape="round" onClick={this.queryCouponList}>
              搜索
            </Button>
          </Form.Item>
        </Form>
        <div className="action">
          <Button type="primary" shape="round" onClick={this.goToCouponList}>
            管理优惠券
          </Button>
          <span className="text-note" onClick={this.queryCouponList}>
            刷新
          </span>
        </div>
        <Table
          columns={this.state.columns}
          rowKey={record => record.coupon_id}
          bordered={true}
          rowSelection={rowSelection}
          dataSource={this.state.coupon_list}
        />
      </Modal>
    );
  }
}
export default CouponSelectModal;
