import React from 'react'
import { Spin } from 'antd'
import NavBar from "@/view/component/common/NavBar.js";

export default class Home extends React.Component {
  render () {
    return <div className="activity-setting">
      <Spin spinning={false}>
        <NavBar icon="icon-shouye1" child="首页" router="home" />
        <div className="content">
          <h3>PLEGO 官方网站商城首页</h3>
        </div>
      </Spin>
    </div>
  }
}