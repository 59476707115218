import React from 'react'
import {Modal,Form,Table,Radio,Input,Select} from 'antd'
import {queryVehicleList} from '@service/getData'
import cookie from 'react-cookies'
import  '@css/groupShipmentModal.scss'
class GroupShipmentModal extends  React.Component{
    constructor(props){
        super(props)
        this.state={
            columns:[
                {

                    title: '商品名称',
                    dataIndex: 'goods_name'
                }, {

                    title: '规格',
                    dataIndex: 'sku'
                }, {
                    title: '单价|数量',
                    dataIndex: 'num',
                    render: (text, record) => (
                        <div>
                            <p>¥{record.current_price / 100}</p>
                            <p>{record.quantity}件</p>
                        </div>
                    )
                }
                ],
            order_shipment_info:{
                shipment_type:null,
                driver:null,
                tracking_number:null,
                logistics:null


            },
            vehicle_list:[

            ]

        }
    }
    changeDriver=(e)=>{
        this.setState(prevState => ({
            order_shipment_info: {
                ...this.state.order_shipment_info,
                shipment_type:prevState.order_shipment_info.shipment_type,
                driver: e,
                logistics:prevState.order_shipment_info.logistics,
                tracking_number:prevState.order_shipment_info.tracking_number,
            }
        }))

    }
    changeLogistics=(e)=>{
        e.persist()
        this.setState(prevState => ({
            order_shipment_info: {
                ...this.state.order_shipment_info,
                shipment_type:prevState.order_shipment_info.shipment_type,
                driver:prevState.order_shipment_info.driver,
                logistics: e.target.value,
                tracking_number:prevState.order_shipment_info.tracking_number,
            }
        }))

    }

    changeTrackingNumber=(e)=>{
        e.persist()
        this.setState(prevState => ({
            order_shipment_info: {
                ...this.state.order_shipment_info,
                shipment_type:prevState.order_shipment_info.shipment_type,
                driver:prevState.order_shipment_info.driver,
                logistics:prevState.order_shipment_info.logistics,
                tracking_number: e.target.value
            }
        }))
    }
    onChange=(e)=>{
        this.setState(prevState => ({
            order_shipment_info: {
                ...this.state.order_shipment_info,
                shipment_type: e.target.value,
                driver:prevState.order_shipment_info.driver,
                logistics:prevState.order_shipment_info.logistics,
                tracking_number:prevState.order_shipment_info.tracking_number,
            }
        }))

    }
    async queryVehicleList(data){
        let res=await queryVehicleList(data)
        if(res.success){
            this.setState({
                vehicle_list:res.result.vehicle_res_list
            })
        }
    }
    componentDidMount(){
        let data={
            shop_id:cookie.load("shop_id")
        }
        this.queryVehicleList(data)
    }

    render(){
        return (
            <Modal
                className="group-shipment-modal"
                title="自提订单发货"
                visible={this.props.visible}
                onOk={this.props.handleSelfOk.bind(this,this.state.order_shipment_info)}
                onCancel={this.props.handleSelfCancel}
                okButtonProps={{shape:'round'}}
                cancelButtonProps={{shape:'round'}}

            >
                <div className="choose-goods">
                    <div className="goods-title">
                        <i className="title-sign"></i>
                        选择商品
                    </div>
                    <Table rowKey={(record,index) => index} columns={this.state.columns}
                           dataSource={this.props.order.order_goods_list} pagination={false} bordered={true}/>
                </div>
                <div className="shipment-info">
                    <div className="goods-title">
                        <i className="title-sign"></i>
                        配送信息
                    </div>
                    <span className="address">配送方式：快递邮寄</span>
                    <span className="address">收货人：{this.props.order.receiver_name}{this.props.order.mobile}</span>
                    <span className="address">收货地址：{this.props.order.address}</span>
                </div>
                <div className="shipment-info">
                    <div className="goods-title">
                        <i className="title-sign"></i>
                        发货方式
                    </div>
                    <Radio.Group onChange={this.onChange} value={this.state.order_shipment_info.shipment_type}>
                        <Radio value={"vehicle_shipment"}>司机送货</Radio>
                        <Radio value={"express_shipment"}>邮寄发货</Radio>
                    </Radio.Group>
                    <div className="shipment-info" style={{"display":this.state.order_shipment_info.shipment_type==="vehicle_shipment"?true:'none'}}>
                        <Form  layout="inline">
                            <Form.Item label="选择司机">
                                <Select
                                    style={{ width: '200px' }}
                                    vlaue={this.state.driver}
                                    onChange={this.changeDriver}
                                >{
                                    this.state.vehicle_list.map(item=>
                                        <Select.Option key={item.vehicle_id}  value={item.vehicle_id}>{item.driver_name}</Select.Option>
                                    )
                                }
                                </Select>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="shipment-info" style={{"display":this.state.order_shipment_info.shipment_type==="express_shipment"?true:'none'}}>
                        <p className="text-danger">请仔细填写物流公司及快递单号，发货后24小时内仅支持做一次更正，预逾期不可更改</p>
                        <Form layout="inline">
                            <Form.Item label="快递公司">
                                <Input value={this.state.order_shipment_info.logistics} onChange={this.changeLogistics}/>
                            </Form.Item>
                            <Form.Item label="填写单号">
                                <Input value={this.state.order_shipment_info.tracking_number} onChange={this.changeTrackingNumber}/>
                            </Form.Item>
                        </Form>
                    </div>


                </div>
            </Modal>
        )
    }

}
const GroupShipmentModalForm = Form.create({name: 'group_shipment_modal_form'})(GroupShipmentModal);

export default GroupShipmentModalForm;
