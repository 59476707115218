import React from 'react'
import { Form, Spin, Table, Button, Modal, Input, message, Popconfirm, Tag, Radio, DatePicker, Upload } from "antd";
import { connect } from "react-redux";
import '@css/campainSetting.scss'
import {
    queryCampaignList,
    setFirstPageCampaign,
    createOrUpdateCampaign,
    createOrUpdateActivity,
    queryMiniAppDetail,
    createOrUpdateBannerActivity
} from '@service/getData'
import cookie from 'react-cookies'
import PropTypes from 'prop-types'
import CampaignShow from '@/view/component/Campaign/CampaignShow'
import TargetTypeModal from "@/view/component/Campaign/TargetTypeModal";
import UploadButton from "@/view/component/common/UploadButton";
import config from '@config/config';

const requestUrl = config.baseUrl + "/ossFile/upload";

class CampainSetting extends React.Component {
    constructor() {
        super()
        CampainSetting.contextTypes = {
            router: PropTypes.object.isRequired
        }
        this.state = {
            loading: false,
            page_setting_visible: false,
            columns: [
                {
                    title: '页面名称',
                    dataIndex: 'campaign_name',
                    render: (text, record) => (
                        <div>
                            {
                                text
                            }
                            {record.is_first_page ? (
                                <Tag color="#1890ff">店铺首页</Tag>
                            ) : null}
                        </div>
                    )
                },
                {
                    title: '操作',
                    dataIndex: 'campaign_id',
                    render: (text, record) => (
                        <div>
                            <span className="text-note"
                                onClick={this.goToActivityList.bind(this, text)}
                            >编辑</span>
                            <Popconfirm
                                title="确定要删除此页面吗?"
                                onConfirm={this.createOrUpdateCampaign.bind(this, text, 0, null)}
                                onCancel={this.cancel}
                                okText="确定"
                                cancelText="取消"
                            >
                                <span className="text-note">删除</span>
                            </Popconfirm>

                            <span style={{ visibility: record.is_first_page === 1 ? "hidden" : true }}
                                className="text-note"
                                onClick={this.setFirstPageCampaign.bind(this, text)}
                            >
                                设为首页
                            </span>
                            <span className="text-note"
                                onClick={this.showCampaignVisible.bind(this, record)}
                            >
                                页面预览
                            </span>
                            <span className="text-note"
                                onClick={this.handlePageSettingShow.bind(this, record)}
                            >
                                页面设置
                            </span>
                        </div>
                    )
                }
            ],
            data: [],
            homeData: {},
            campaign: {},
            campaign_show_visible: false,
            template_choose: 1,
            activity_req_list: [{
                activity_name: "商品滑动列表",
                activity_type: "campaign",
                activity_subtype: "goodsListSlide",
                status: 1,
                target_type: "goodsList",
                thumbnail_url: "http://udh.oss-cn-hangzhou.aliyuncs.com/f7730a4b-2a65-4ec1-aa8d-803f4329a583",

            },
            {
                activity_name: "图片广告",
                activity_type: "campaign",
                activity_subtype: "advertisement",
                status: 1,
                thumbnail_url: "http://udh.oss-cn-hangzhou.aliyuncs.com/6c69fa39-87ce-4974-9fc7-89b66f042ee2",
                image_url: "http://udh.oss-cn-hangzhou.aliyuncs.com/b7ad8552-6690-4bfb-8450-ee86d5be2985",

            }

            ],
            mini_app_name: null,
            currentPageName: '选择页面',
            target_type_visible: false,
            shareFileList: [],
            previewImage: '',
            previewVisible: false,
            target_campaign_id: null,
            replace_time: '',
        }
    }
    async queryMiniAppDetail() {
        let mini_app_id = cookie.load("mini_app_id");
        let res = await queryMiniAppDetail({
            mini_app_id
        });
        this.setState({
            mini_app_name: res.result.mini_app.mini_app_name,
        });
    }
    queryCampaignList = async () => {
        let data = {
            mini_app_id: cookie.load("mini_app_id")
        }
        let res = await queryCampaignList(data);
        if (res.success && res.result) {
            const homeData = res.result.campaign_res_list.find((item) => item.is_first_page === 1);
            this.setState({
                data: res.result.campaign_res_list,
                homeData,
                page_setting_visible: false
            })
        }
    }
    setFirstPageCampaign = async (campaign_id) => {
        let data = {
            campaign_id: campaign_id
        }
        let res = await setFirstPageCampaign(data)
        if (res.success) {
            message.success("成功设置为首页")
            this.queryCampaignList()
        }

    }
    createOrUpdateCampaign = async (campaign_id, status, template_choose) => {
        let data = {}
        if (status === 0) {
            let campaign_req = {
                campaign_id: campaign_id,
                status: 0,
                mini_app_id: cookie.load("mini_app_id")
            }
            data.campaign_req = campaign_req;

        } else if (status === 1) {
            let form = this.props.form.getFieldsValue();
            let campaign_req = {
                campaign_name: form.campaign_name,
                status: 1,
                mini_app_id: cookie.load("mini_app_id")
            }
            data.campaign_req = campaign_req;
        } else if (status === -1) {
            let form = this.props.form.getFieldsValue();
            let campaign_req = {
                campaign_name: form.campaign_name,
                transfer_title: form.transfer_title,
                campaign_id: this.state.campaign.campaign_id,
                mini_app_id: cookie.load("mini_app_id"),
                transfer_pic: this.state.previewImage,
            }
            data.campaign_req = campaign_req;
        } else if (status === 2) {
            const campaign_req = {
                mini_app_id: cookie.load('mini_app_id'),
                campaign_id: this.state.homeData.campaign_id,
                target_campaign_id: this.state.target_campaign_id,
                replace_time: this.state.replace_time,
                is_finished: 0,
            }
            data.campaign_req = campaign_req;
        }
        let res = await createOrUpdateCampaign(data)
        if (res.success) {
            message.success("操作成功")

            if (template_choose === 2 && status !== 0) {
                this.createOrUpdateBannerActivity(res.result.campaign_id);

            }
            if (template_choose != null) {
                setTimeout(() => {
                    this.setState({
                        visible: false
                    })
                    this.goToActivityList(res.result.campaign_id);
                }, 2000);
            } else {
                this.onClose();
                this.queryCampaignList()
            }
        }
    }
    createOrUpdateActivity = async (data) => {
        let res = await createOrUpdateActivity(data)
        if (!res.success) {
            message.error("初始化模版错误，请联系客服")
        }

    }
    createOrUpdateBannerActivity = async (campaign_id) => {
        let data = {
            activity_req: {
                mini_app_id: cookie.load("mini_app_id"),
                campaign_id: campaign_id,
                activity_name: "banner",
                activity_type: "campaign",
                activity_subtype: "scroll",
                status: 1,
                sort_num: 1,
                activity_req_list: [
                    {
                        activity_name: "banner",
                        activity_type: "campaign",
                        activity_subtype: "topScroll",
                        status: 1,
                        thumbnail_url: "http://udh.oss-cn-hangzhou.aliyuncs.com/22e3834d-13e1-4cbc-9782-f896ba58576b",
                        image_url: "",
                        mini_app_id: cookie.load("mini_app_id"),
                        campaign_id: this.props.campaign_id,

                    },
                    {
                        activity_name: "banner",
                        activity_type: "campaign",
                        activity_subtype: "topScroll",
                        status: 1,
                        thumbnail_url: "http://udh.oss-cn-hangzhou.aliyuncs.com/2b451276-a7ec-4fe5-bc7b-b09f48d33478",
                        image_url: "",
                        mini_app_id: cookie.load("mini_app_id"),
                        campaign_id: this.props.campaign_id,

                    },
                    {
                        activity_name: "banner",
                        activity_type: "campaign",
                        activity_subtype: "topScroll",
                        status: 1,
                        thumbnail_url: "http://udh.oss-cn-hangzhou.aliyuncs.com/423f74bd-7ebb-406a-bb35-71596e130dca",
                        image_url: "",
                        mini_app_id: cookie.load("mini_app_id"),
                        campaign_id: this.props.campaign_id,

                    }
                ],
            }
        }
        let res = await createOrUpdateBannerActivity(data)
        if (res.success) {
            this.state.activity_req_list.forEach((item, index) => {
                let obj = Object.assign(item)
                obj.mini_app_id = cookie.load("mini_app_id")
                obj.campaign_id = campaign_id
                obj.title = this.state.mini_app_name;
                obj.transfer_title = this.state.mini_app_name
                obj.sort_num = index + 2;
                this.createOrUpdateActivity({ activity_req: obj })
            })
            if (!res.success) {
                message.error("初始化模版错误，请联系客服")
            }
        }
    }
    showModal = () => {
        this.setState({
            visible: true
        })
    }
    handleOk = () => {
        let flag = false
        this.props.form.validateFields(err => {
            if (err) {
                flag = true
            }
        });
        if (flag) return
        this.createOrUpdateCampaign(null, 1, this.state.template_choose);
    }
    handleFlase = () => {
        this.setState({
            visible: false,
            template_choose: 1
        })
    }
    handlePageSettingFlase = () => {
        this.setState({
            page_setting_visible: false
        })
    }
    handlePageSettingShow = (campaign) => {
        const shareFileList = [{
            uid: 0,
            name: campaign.transfer_pic,
            status: 'done',
            url: campaign.transfer_pic,
        }];
        this.setState({
            campaign,
            shareFileList,
            page_setting_visible: true,
        })
    }

    goToActivityList = (campaign_id) => {
        this.context.router.history.push(`/activitySetting?campaign_id=${campaign_id}`)
    }
    showCampaignVisible = (campaign) => {
        this.setState({
            campaign,
            campaign_show_visible: true
        })
    }
    showCampaignVisibleCancel = () => {
        this.setState({
            campaign_show_visible: false
        })
    }

    componentDidMount() {
        this.queryCampaignList()
        this.queryMiniAppDetail()
    }

    changeTemplate = (e) => {
        this.setState({
            template_choose: e.target.value
        })
    }
    onChangeHomePage() {
        this.createOrUpdateCampaign(null, 2, null);
    }
    onClose() {
        this.setState({
            changeHomePageVisible: false,
        });
    }
    onTimeChange(value, dateString) {
        this.setState({
            replace_time: dateString,
        });
    }
    onTimeOk(value) { }
    onShowChangeHomePageVisible() {
        this.setState({
            changeHomePageVisible: true,
        });
    }
    onTargetOk(data) {
        this.setState({
            target_campaign_id: data.dest_campaign_id,
            currentPageName: data.desc,
            target_type_visible: false,
        });
    }
    onTargetCancel() {
        this.setState({
            target_type_visible: false,
        });
    }
    onShowTarget() {
        this.setState({
            target_type_visible: true,
        });
    }
    handlePreview = () => {
        this.setState({
            previewVisible: true,
        });
    }
    handleShareChange = ({ fileList }) => {
        let previewImage = this.state.previewImage;
        // 删除图片的时候，fileList为空数组。做兼容。
        if (fileList.length) {
            let last = fileList[fileList.length - 1]
            previewImage = last.response && last.response.result && last.response.result.oss_file_url;
        }
        this.setState({
            shareFileList: [...fileList],
            previewImage,
        });
    }
    onPreviewCancel() {
        this.setState({
            previewVisible: false
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="campain-setting">
                <Spin spinning={this.state.loading}>
                    <div className="content">
                        <div className="homePageSetting">
                            <p className="top">
                                <span className="pageName">{this.state.homeData.campaign_name}</span>
                                <span className="tag">店铺首页</span>
                            </p>
                            <p className="bottom">
                                <span onClick={this.goToActivityList.bind(this, this.state.homeData.campaign_id)}>编辑</span>
                                <span onClick={this.showCampaignVisible.bind(this, this.state.homeData)}>页面预览</span>
                                <span onClick={this.handlePageSettingShow.bind(this, this.state.homeData)}>页面设置</span>
                                <span onClick={this.onShowChangeHomePageVisible.bind(this)}>定时更换</span>
                            </p>
                        </div>
                        <div className="action">
                            <Button type="primary" shape="round" onClick={this.showModal}>新建页面</Button>
                        </div>
                        <Table
                            rowKey={(record, index) => index}
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={{
                                showTotal: (total) => `共 ${total} 条`,
                                pageSizeOptions: ["10", "30", "50", "100"],
                                showSizeChanger: true
                            }}
                        />
                    </div>
                </Spin>
                <Modal
                    visible={this.state.visible}
                    title={"新建页面"}
                    onOk={this.handleOk}
                    onCancel={this.handleFlase}
                    className="add-page"
                    okText="下一步"
                    okButtonProps={{ shape: 'round' }}
                    cancelButtonProps={{ shape: 'round' }}
                >
                    <Form>
                        <Form.Item label="页面名称">
                            {getFieldDecorator('campaign_name', {
                                initialValue: null,
                                rules: [{
                                    required: true,
                                    message: "请填写名称!"
                                }]
                            })(
                                <Input className="input-name" />
                            )}
                        </Form.Item>
                        <Form.Item label="选择页面">
                            {getFieldDecorator('choose-page', {
                                initialValue: 1,
                                rules: [{
                                    required: true,
                                    message: "请填写名称!"
                                }]
                            })(
                                <div>
                                    <div className="choose-page">
                                        <div className="blank-page"></div>
                                        <div className="makeup-page"></div>
                                    </div>
                                    <Radio.Group value={this.state.template_choose} onChange={this.changeTemplate}>
                                        <Radio value={1}>
                                            空白模版</Radio>
                                        <Radio value={2}>美妆-首页模版</Radio>
                                    </Radio.Group>
                                </div>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    visible={this.state.page_setting_visible}
                    title="页面设置"
                    onOk={this.createOrUpdateCampaign.bind(this, null, -1, null)}
                    onCancel={this.handlePageSettingFlase}
                    className="add-page"
                    destroyOnClose={true}
                    okButtonProps={{ shape: 'round' }}
                    cancelButtonProps={{ shape: 'round' }}

                >
                    <Form>
                        <Form.Item label="页面名称">
                            {getFieldDecorator('campaign_name', {
                                initialValue: this.state.campaign ? this.state.campaign.campaign_name : null,
                                rules: [{
                                    required: true,
                                    message: "请填写名称!"
                                }]
                            })(
                                <Input className="input-name" />
                            )}
                        </Form.Item>
                        <Form.Item label="分享文案">
                            {getFieldDecorator('transfer_title', {
                                initialValue: this.state.campaign ? this.state.campaign.transfer_title : null,
                                rules: [{
                                    required: false,
                                }]
                            })(
                                <Input className="input-name" />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="分享图片"
                            extra={<span>建议尺寸500*400，仅支持jpg、png2种格式，<br />单张图片大小不超过3.0MB</span>}
                        >
                            <Upload
                                accept="image/jpeg,image/jpg,image/png"
                                withCredentials={true}
                                action={requestUrl}
                                listType="picture-card"
                                fileList={this.state.shareFileList}
                                onPreview={this.handlePreview}
                                onChange={this.handleShareChange}
                            >
                                {this.state.shareFileList.length > 0 ? null : <UploadButton />}
                            </Upload>
                        </Form.Item>
                    </Form>
                </Modal>
                <CampaignShow
                    campaign={this.state.campaign}
                    visible={this.state.campaign_show_visible}
                    onOk={this.showCampaignVisibleCancel}
                />
                <Modal
                    visible={this.state.changeHomePageVisible}
                    title="定时更换"
                    okButtonProps={{
                        shape: 'round'
                    }}
                    cancelButtonProps={{
                        shape: 'round'
                    }}
                    onOk={this.onChangeHomePage.bind(this)}
                    onCancel={this.onClose.bind(this)}
                >
                    <Form>
                        <Form.Item label="店铺首页">
                            <span className="campaignName">{this.state.homeData.campaign_name}</span>
                        </Form.Item>
                        <Form.Item label="更换为">
                            <div onClick={this.onShowTarget.bind(this)} className="choosePage">
                                {this.state.currentPageName}
                            </div>
                        </Form.Item>
                        <Form.Item label="更换时间">
                            <DatePicker
                                className="changeHomePageTime"
                                showTime
                                placeholder="选择时间"
                                onChange={this.onTimeChange.bind(this)}
                                onOk={this.onTimeOk.bind(this)}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
                <TargetTypeModal
                    visible={this.state.target_type_visible}
                    onOK={this.onTargetOk.bind(this)}
                    onCancel={this.onTargetCancel.bind(this)}
                />
                <Modal visible={this.state.previewVisible} footer={null} onCancel={this.onPreviewCancel.bind(this)}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
        activity_subtype: state.activitySubtype

    }
}
const CampainSettingForm = Form.create({ name: 'campain-setting-form' })(CampainSetting);
export default connect(mapStateToProps)(CampainSettingForm);
