import React from "react";
import NavBar from '@/view/component/common/NavBar.js'

class ShippingTemplate extends React.Component {
  render () {
    return <div className="userDashboard">
      <NavBar icon="icon-yemian" child={'运费模版'} />
      <div style={{ backgroundColor: '#fff', padding: '20px' }}>
        <h3>运费计费规则</h3>
        <h4>一.商品邮费设置</h4>
        <ul>
          <li>1、邮费设置：每件商品，仅可设置一种运费收取方式，即：统一运费，或指定运费模板；</li>
          <li>2、统一运费：按商品一次计收运费；如，统一运费 10 元，买家单笔订单购买n 件，均只收取 10 元运费；</li>
          <li>3、运费模板：选择相应的运费模板，即可指定该商品，可配送至相应区域，并按规则收取相应运费。</li>
        </ul>
        <h4>二.运费计算方式</h4>
        <p>规则：分别计算出来商品使用模板的运费，和统一运费的最大值，再进行累加。</p>
        <ul>
          <li>（1）不同或相同的商品，设置同一运费模板：按该模板设置的规则计算，不足续件或者续重的数目的时候，仍然按照续件或者续重的数目进行计算。例如商品 A，B 都是用模板 M（首件 10 块，续 2 件 5 块），如果购买商品 A 和 B，各一件，则一共购买两件，运费=10+5=15 元。</li>
          <li>（2）多种商品，分别设置不同金额的统一运费：以最高运费金额收取。例如商品 A，B，C 的统一运费分别为 1 元，2 元和 3 元，一期购买这三个商品，则运费为 3 元。</li>
          <li>（3）不同的商品，设置不同的运费模板：分别计算每个运费模板规则应收运费，再累加计算合计运费；例如商品 A（1kg）使用用模板 M（首 1kg，10块，续 2kg，5 块），商品 B（0.5kg）使用模板 N（首 2kg，12 块，续1kg，5 块），如果购买商品 A 和 B，各 2 件，则运费=模板 M 的运费+模板N 的运费=（10+5）+（12）=27 元。</li>
          <li>（4）统一运费商品，和运费模板商品一同结算：单独计算统一运费商品应收运费，再累加运费模板应收运费；例如：商品 A 和 B 使用统一运费，分别为 2 元 和 10 元，商品 C 使用模板 M（首 1 件，10 块，续 2 件，5 块），商品 D （1kg）使用模板 N（首 2kg，12 块，续 1kg，5 块），购买 A，B，C 和 D各两件。此时统一运费（商品 A 和 B）=10 元；运费模板运费（商品 C 和 D） =模板 M 的运费+模板 N 的运费=（10+5）+（12）=27 元。则总运费=统一运费+运费模板运费=（10）+（27）=37 元。</li>
        </ul>
      </div>
    </div>
  }
}

export default ShippingTemplate;