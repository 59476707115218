import React from "react";
import "@css/orderManage.scss";
import NavBar from "@/view/component/common/NavBar.js";
import { connect } from "react-redux";
import Order from "./children/Order";
class OrderManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { menu_code } = this.props;
    return (
      <div className="order-manage">
        <NavBar icon="icon-dingdan" child={menu_code.order_manage} />
        <div className="order-content">
          <Order index="1" order_status={this.props.location.state && this.props.location.state.order_status} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu_code: state.menuCode,
    order_status_list: state.orderStatusList
  };
};

export default connect(mapStateToProps)(OrderManage);
