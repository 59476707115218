import React from "react";
import "@css/balanceManage.scss";
import "@css/assetsManage.scss";
import NavBar from "@/view/component/common/NavBar.js";
import { Button, Form } from "antd";
import BonusSettleContent from "@/view/component/Money/BonusSettleContent";
import { connect } from "react-redux";
import { queryShopBonusSummaryDetail } from "@service/getData";
import PropTypes from "prop-types";
import cookie from "react-cookies";
class AssetsManage extends React.Component {
  constructor() {
    AssetsManage.contextTypes = {
      router: PropTypes.object.isRequired
    };
    super();
    this.state = {
      wait_balance: null,
      give_out_balance: null
    };
  }
  queryShopBonusSummaryDetail = async () => {
    if (!cookie.load("shop_id") && +cookie.load("is_admin") === 1) {
      return;
    }
    let data = {
      shop_id: cookie.load("shop_id")
    };
    let res = await queryShopBonusSummaryDetail(data);
    if (res.success) {
      this.setState({
        balance: res.result.shop_bonus_summary.balance / 100,
        give_out_balance: res.result.shop_bonus_summary.give_out_balance / 100,
        wait_balance: res.result.shop_bonus_summary.wait_balance / 100
      });
    }
  };
  goTo = url => {
    this.context.router.history.push(url);
  };
  componentDidMount() {
    this.queryShopBonusSummaryDetail();
  }
  render() {
    let { menu_code } = this.props;
    return (
      <div className="assets-manage page">
        <NavBar icon="icon-zichan" child={menu_code.assets_manage} />
        <div className="content">
          <div className="shopDataTitle">
            <i className="titleSign"></i>
            账户概况
          </div>
          <div className="integral-dashboard">
            <div className="integral-dashboard-data">
              <div className="integral-title">
                可用余额
                <span className="recharge-link" onClick={this.goTo.bind(this, "rechargeHistory")}>
                  充值记录
                </span>
                <span className="recharge-link" onClick={this.goTo.bind(this, "withdrawHistory")}>
                  提现记录{" "}
                </span>
              </div>
              <div className="integral-data">
                {this.state.balance}
                <Button className="btn-recharge" type="primary" shape="round" onClick={this.goTo.bind(this, "bonusRecharge")}>
                  充值
                </Button>
              </div>
            </div>
            <div className="integral-dashboard-data">
              <div className="integral-title">待结算余额</div>
              <div className="integral-data">{this.state.wait_balance}</div>
            </div>
            <div className="integral-dashboard-data">
              <div className="integral-title">已结算余额</div>
              <div className="integral-data">{this.state.give_out_balance}</div>
            </div>
          </div>
          <div className="shopDataTitle">
            <i className="titleSign"></i>
            交易记录
          </div>
          <BonusSettleContent />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};
const AssetsManageForm = Form.create({ name: "assets-manage-from" })(AssetsManage);

export default connect(mapStateToProps)(AssetsManageForm);
