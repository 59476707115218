import React from 'react'
import '@css/balanceManage.scss'
import NavBar from '@/view/component/common/NavBar.js'
import { connect } from "react-redux";
import { queryQohList, modifyQoh } from '@service/getData'
import { Form, Select, Input, Button, Table, Popconfirm, message, Pagination } from 'antd'
import cookie from 'react-cookies'
import PropTypes from 'prop-types'
import { formatNumber, formatIntAndZero } from "@common/common";
class BalanceManage extends React.Component {
    constructor(props) {
        super(props);
        BalanceManage.contextTypes = {
            router: PropTypes.object.isRequired
        }
        this.state = {
            page: 1,
            page_size: 10,
            total: 0,
            small_price: null,
            big_price: null,
            columns: [
                {
                    title: '商品名称',
                    dataIndex: 'goods_name',
                    width: "24.3%",
                    render: (value, row) => {
                        let obj = {
                            children:
                                <div className="goods-info">
                                    <div>{value}</div>
                                </div>,
                            props: {},
                        };
                        if (row.rowSpan != null) {
                            obj.props.rowSpan = row.rowSpan
                        }
                        return obj;
                    },
                },
                {
                    title: '商品Id',
                    dataIndex: 'goods_id',
                    width: "5.2%",
                    render: (value, row) => {
                        let obj = {
                            children: value,
                            props: {},
                        };
                        if (row.rowSpan != null) {
                            obj.props.rowSpan = row.rowSpan
                        }
                        return obj;
                    },
                },
                {
                    title: '商品规格',
                    width: "10.5%",
                    dataIndex: 'attribute_values',
                }, {
                    title: '规格编码',
                    dataIndex: 'sku_code',
                    width: "10.5%"
                }, {
                    title: '库存',
                    width: "10.5%",
                    dataIndex: 'balance',
                    render: (text, record) => (
                        <div>
                            {text}
                            <Popconfirm
                                overlayClassName="flexPop"
                                title={
                                    <Input
                                        style={{ "width": "84px", "borderRadius": "100px" }}
                                        value={this.state.balance}
                                        onChange={this.changeBalance}
                                    />
                                }
                                onConfirm={this.confirm.bind(this, record.sku_id)}
                                onCancel={this.cancel}
                                okText="确认"
                                cancelText="取消"
                                icon={null}>
                                <i className="iconfont icon-bianji" ></i>
                            </Popconfirm>
                        </div>
                    )
                }, {
                    title: '缺货',
                    width: "5.5%",
                    dataIndex: 'goods_limit',
                    render: (text) =>
                        (<span className="text-danger">{text}</span>)
                }, {
                    title: '价格',
                    width: "10.5%",
                    dataIndex: 'current_price',
                    render: (text) =>
                        (<p>{text / 100}</p>)
                }, {
                    title: '状态',
                    dataIndex: 'status',
                    width: "10.5%",
                    render: (value, row) => {
                        let obj = {
                            children: value,
                            props: {},
                        };
                        if (row.rowSpan != null) {
                            obj.props.rowSpan = row.rowSpan
                        }
                        return obj;
                    },
                }, {
                    title: '操作',
                    dataIndex: 'action',
                    width: "18%",
                    render: (value, row) => {
                        let obj = {
                            children: (
                                <div>
                                    <p className="text-note" onClick={this.goGoodsEdit.bind(this, row)}>编辑</p>
                                    <p className="text-note" onClick={this.goGoodsDetail.bind(this, row)}>查看详情</p>
                                </div>),
                            props: {},
                        };
                        if (row.rowSpan != null) {
                            obj.props.rowSpan = row.rowSpan
                        }
                        return obj;
                    },
                }

            ],
            goods_list: [],
            balance: null
        }

    }
    onChangeBigPrice = (e) => {
        let value = formatNumber(e.target.value)
        this.setState({
            big_price: value
        })
    }
    onChangeSmallPrice = (e) => {
        let value = formatNumber(e.target.value)
        this.setState({
            small_price: value
        })
    }
    filterData = () => {
        if (!cookie.load("shop_id") && cookie.load("is_admin") === 1) {
            return
        }
        let form = this.props.form.getFieldsValue();
        let data = {
            shop_id: cookie.load("shop_id"),
            page_no: this.state.page,
            page_size: this.state.page_size
        }

        if (form.onsale != null) {
            data.onsale = form.onsale === -1 ? null : form.onsale
        }
        if (form.goods_id) {
            data.goods_id = form.goods_id
        }
        if (form.goods_name) {
            data.goods_name = form.goods_name
        }
        if (form.sku_code) {
            data.sku_code = form.sku_code
        }
        if (this.state.small_price) {
            data.begin_price = Math.round(this.state.small_price * 100)
        }
        if (this.state.big_price) {
            data.end_price = Math.round(this.state.big_price * 100)
        }
        let big_price = this.state.big_price, small_price = this.state.small_price;
        if (!!this.state.small_price && !!this.state.big_price && Number(big_price) < Number(small_price)) {
            small_price = this.state.big_price;
            big_price = this.state.small_price;
            data.small_price = Math.round(small_price * 100);
            data.big_price = Math.round(big_price * 100)
        }
        this.setState({
            small_price,
            big_price
        })

        this.queryQohList(data)

    }
    confirm = (sku_id) => {
        let data = {
            sku_id: sku_id,
            quantity: this.state.balance
        }
        this.modifyQoh(data)
        this.setState({
            balance: null
        })
    }

    cancel = (e) => {
        this.setState({
            balance: null
        })
    }
    clearFilter = () => {
        this.props.form.resetFields();
        this.setState({
            small_price: null,
            big_price: null
        })
    }
    goGoodsDetail = (data) => {
        let url = "/goodsGroupDetail?goods_id=" + data.goods_id
        if (data.group_open_record_goods_res) {
            url += '&group_open_record_goods_id=' + data.group_open_record_goods_res.group_open_record_goods_id
        }
        this.context.router.history.push(url)
    }
    goGoodsEdit = (data) => {
        let url = "/goodsGroupDetail?eidt=1&goods_id=" + data.goods_id
        if (data.group_open_record_goods_res) {
            url += '&group_open_record_goods_id=' + data.group_open_record_goods_res.group_open_record_goods_id
        }
        this.context.router.history.push(url)
    }
    changeBalance = (e) => {
        let value = formatIntAndZero(e.target.value)
        this.setState({
            balance: value
        })
    }
    onShowPageChange = (page) => {
        this.setState({
            page: page
        }, () => { this.filterData() })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => { this.filterData() })
    }
    componentDidMount() {
        this.filterData()
    }
    async queryQohList(data) {
        let res = await queryQohList(data)
        let goods_list = []
        if (res.success) {
            res.result.qoh_list.forEach(item => {
                item.sku_list.forEach((sku, index) => {
                    let goods = Object.assign(sku);
                    goods.goods_name = item.goods_name;
                    goods.goods_id = item.goods_id;
                    goods.status = +item.on_sale === 1 ? "已上架" : "未上架"
                    goods.goods_limit = sku.goods_limit ? "缺货" : null
                    if (index === 0) {
                        goods.rowSpan = item.sku_list.length
                    } else {
                        goods.rowSpan = 0
                    }
                    goods.visible = false;
                    goods_list.push(goods)
                })
            })
            this.setState({
                goods_list: goods_list,
                total: res.result.total
            })
            window.scrollTo(0, 0);

        }
    }
    async modifyQoh(data) {
        let res = await modifyQoh(data);
        if (res.success) {
            message.success("库存修改成功")
            this.filterData()
        }
    }
    render() {
        let { menu_code } = this.props
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="balance-manage">
                <div className="content">
                    <NavBar icon="icon-shangpinguanli" child={menu_code.balance_manage} />
                    <div className="balance-content">
                        <div className="filter-content">
                            <Form className="ant-vertical" layout="inline">
                                <Form.Item label="上架状态：">
                                    {getFieldDecorator('onsale')(
                                        <Select className="form-input">
                                            <Select.Option value="-1">全部</Select.Option>
                                            <Select.Option value="1">已上架</Select.Option>
                                            <Select.Option value="2">未上架</Select.Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="商品ID">
                                    {getFieldDecorator('goods_id')(
                                        <Input className="form-input" placeholder="请输入商品ID" />
                                    )}
                                </Form.Item>
                                <Form.Item label="规格编码">
                                    {getFieldDecorator('sku_code')(
                                        <Input className="form-input" placeholder="请输入规格编码" />
                                    )}
                                </Form.Item>
                                <Form.Item label="商品价格：">
                                    {getFieldDecorator('goods_start_price')(
                                        <div>
                                            <Input className="form-input"
                                                suffix="元"
                                                value={this.state.small_price}
                                                onChange={this.onChangeSmallPrice} />
                                            <span className='goods-price-center'>至</span>
                                            <Input className="form-input"
                                                suffix="元"
                                                value={this.state.big_price}
                                                onChange={this.onChangeBigPrice} />
                                        </div>
                                    )}
                                </Form.Item>
                                <Form.Item label="商品名称：">
                                    {getFieldDecorator('goods_name')(
                                        <Input className="form-input-long" placeholder="输入商品名称" />
                                    )}
                                </Form.Item>
                            </Form>
                            <div className="action">
                                <Button shape="round" type="primary" onClick={this.onShowPageChange.bind(this, 1)} >筛选</Button>
                                <span className="text-note" onClick={this.clearFilter}>清空筛选条件</span>
                            </div>
                        </div>
                        <Table rowKey={(record, index) => index} columns={this.state.columns}
                            dataSource={this.state.goods_list} pagination={false} bordered={true} />
                        <Pagination
                            className="pagenation"
                            onChange={this.onShowPageChange}
                            onShowSizeChange={this.onShowSizeChange}
                            defaultCurrent={this.state.page}
                            total={this.state.total}
                            showTotal={(total) => `共 ${total} 条`}
                            pageSizeOptions={["10", "30", "50", "100"]}
                            showSizeChanger
                        />
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
        order_status_list: state.orderStatusList
    }
}

const BalanceManageForm = Form.create({ name: 'balance_manage_form' })(BalanceManage);

export default connect(mapStateToProps)(BalanceManageForm);
