import React from 'react'
import NavBar from '@/view/component/common/NavBar.js'
import cookie from "react-cookies";
import {Switch,Button,Tooltip,Icon,Table,Divider,Pagination,message,Popconfirm} from 'antd';
import '@css/integralManage.scss'
import PropTypes from "prop-types";
import {
    queryActivityList,
    queryMiniAppDetail,
    createOrUpdateMiniApp,
    queryPointsRuleList,
    createOrUpdatePointsRule
} from '@service/getData'

class IntegralManage extends React.Component {
    constructor() {
        IntegralManage.contextTypes = {
            router: PropTypes.object.isRequired
        };
        super()
        this.state = {
            activity_id: null,
            mini_app: {},
            page_info: {
                cashManage: {
                    title: "现金管理",
                    all_points_amount: "总发放现金",
                    not_use_points_amount: "未提现金",
                    in_use_points_amount: "已提现金",
                    rule_title: "现金规则",
                    rules: [
                        '现金有效期：不清空',
                        '现金发放活动：抢免单',
                        '现金发放类型：免单商品返现',
                        '现金到账时间：确认收货立即发放'
                    ],
                    desc: [{
                        desc: '想用现金做活动？',
                        goto: '前往营销活动-抢免单',
                    },
                        {
                            desc: '想要发放现金',
                        }
                    ]


                },
                redBagManage: {
                    title: '红包管理',
                    all_points_amount: "总发放红包",
                    not_use_points_amount: "未使用红包",
                    in_use_points_amount: "已使用红包",
                    rule_title: "红包规则",
                    rules: [
                        '红包有效期：自发放始30天后清零',
                        '红包发放活动：抢免单',
                        '红包发放类型：分享免单活动奖励0.1元、为好友助力免单奖励0.5元',
                        '红包到账时间：立即发放'
                    ],
                    desc: [{
                        desc: '想用红包做活动？',
                        goto: '前往营销活动抢免单',
                    }
                    ]
                },
                integralManage: {
                    title: '积分管理',
                    all_points_amount: "总发放积分",
                    not_use_points_amount: "未使用积分",
                    in_use_points_amount: "已使用积分",
                    rule_title: "积分规则",
                    rules: [
                        '积分有效期：不清零',
                        '积分到账时间：确认收货后积分到账'
                    ],
                    desc: [{
                        desc: '想用积分做活动？',
                        goto: '前往积分兑换商城',
                    },
                        {
                            desc: '支持积分兑换商品、优惠券、抽奖'
                        }
                    ]
                },
                commissionManage: {
                    title: '佣金管理',
                    all_points_amount: "总发放佣金",
                    not_use_points_amount: "未使用佣金",
                    in_use_points_amount: "已使用佣金",
                    rule_title: "佣金规则",
                    rules: [
                        '佣金有效期：不清零',
                        '佣金发放活动：社区团购',
                        '佣金发放类型：佣金返现',
                        '佣金到账时间：商家结算后发放'
                    ],
                    desc: [{
                        desc: '想用佣金做活动？',
                        goto: '前往营销活动-社区团购',
                    }]
                },
            },
            location: null,
            columns: [{
                title:
                    <div>
                        奖励条件
                        <Tooltip title="如果商品参加多个积分规则，会重复送积分；发生部分退款时, 按照实际支付金额扣除应退积分。">
                        <Icon type="question-circle" theme="twoTone" />
                        </Tooltip>
                    </div>,
                dataIndex:'id',
                render:(text,record)=>{
                        return `每购买金额${(record.buy_amount/100).toFixed(2)}元获得${record.reward_amount}分`
                }
            },{
                dataIndex: 'updated_at',
                title: '更新时间'
            },{
                dataIndex: 'goods_type',
                title: '参加商品',
                render:(text,record)=>{
                    return text==="general"?`${record.points_rule_goods_res_list.length}个商品`:'全部商品'
            }
            },{
                dataIndex: 'action',
                title: '操作',
                render: (text, record) => (
                    <div>
                        <span className="text-note" onClick={this.createOrUpdatePointsRule.bind(this, record.points_rule_id)}>编辑</span>
                        <Divider type="vertical" />
                        <Popconfirm
                            title="确定要删除此积分规则?"
                            onConfirm={this.delPointsRule.bind(this, record.points_rule_id)}
                            okText="确定"
                            cancelText="取消"
                            placement="topRight"
                        >
                            <span className="text-note">删除</span>
                        </Popconfirm>
                    </div>
                )
            }],
            points_rule_res_list:[],
            total:0,
            page_size:10,
            page_no:1

        }
    }

    queryActivityList = async () => {
        let data = {
            mini_app_id: cookie.load("mini_app_id"),
            activity_type_list: ["personCenter", "booth", "drogue"]
        }
        let res = await queryActivityList(data);
        if (res.success) {
            let activity_list = res.result.activity_res_list;
            let activity=null
            if(this.state.location==="integralManage"){
                activity=activity_list.find(item=>item.activity_subtype==="pointsStore")
            }else{
                activity=activity_list.find(item=>item.activity_subtype==="free_order")
            }
            if(activity){
                this.setState({
                    activity_id:activity.activity_id
                })
            }
        }
    }
    queryMiniAppDetail = async () => {
        let data = {
            mini_app_id: cookie.load("mini_app_id"),

        }
        let res = await queryMiniAppDetail(data)
        if (res.success) {
            let obj={}
            let mini_app=res.result.mini_app
            switch (this.state.location) {
                case 'cashManage':
                    obj={
                        all_points_amount:mini_app.all_cash_amount/100,
                        in_use_points_amount:mini_app.in_use_cash_amount/100,
                        not_use_points_amount:mini_app.not_use_cash_amount/100,
                        wait_cash_amount:mini_app.wait_cash_amount/100
                    }
                    break
                case 'redBagManage':
                    obj={
                        all_points_amount:mini_app.all_redpack_amount/100,
                        in_use_points_amount:mini_app.in_use_redpack_amount/100,
                        not_use_points_amount:mini_app.not_use_redpack_amount/100
                    }
                    break
                case 'integralManage':
                    obj={
                        all_points_amount:mini_app.all_points_amount,
                        in_use_points_amount:mini_app.in_use_points_amount,
                        not_use_points_amount:mini_app.not_use_points_amount
                    }
                    break;
                default:
            }
            obj.open_points=mini_app.open_points
            this.setState({
                mini_app: obj
            })
        }
    }
    createOrUpdateMiniApp = async (checked) => {
        let data = {
            mini_app: {
                mini_app_id: cookie.load("mini_app_id"),
                open_points: checked ? 1 : 0
            }


        }
        let res = await createOrUpdateMiniApp(data)
        if (res.success) {
            this.queryMiniAppDetail()
        }
    }
    goToIntegral = () => {
        let url=null
        if(this.state.location==="integralManage"){
            url=`/market?type=edit&activity_id=${this.state.activity_id}`
        }else{
            url=`/marketTab?type=edit&activity_id=${this.state.activity_id}`
        }
        this.context.router.history.push(url);
    }
    queryPointsRuleList=async()=>{
        let data={
            mini_app_id:cookie.load('mini_app_id'),
            page_no:this.state.pag_no,
            page_size:this.state.page_size
        }
        let res=await queryPointsRuleList(data)
        if(res.success){
            let{points_rule_res_list,total}=res.result;
            this.setState({
                points_rule_res_list,
                total
            })
        }
    }
    delPointsRule=async(points_rule_id)=>{
        let data={
            points_rule_req:{
                mini_app_id:cookie.load("mini_app_id"),
                points_rule_id,
                status:0
            }
        };
        let res=await createOrUpdatePointsRule(data);
        if(res.success){
            message.success("成功删除该积分规则")
            this.queryPointsRuleList()
        }
    }
    onShowSizeChange = (current, page_size) => {
        this.setState({
            page_size
        },()=>{
            this.queryPointsRuleList();
        });
    };
    onShowPageChange = page_no => {
        this.setState({
            page_no
        },()=>{
            this.queryPointsRuleList();
        });
    };
    createOrUpdatePointsRule=(points_rule_id)=>{
        if(points_rule_id){
            return window.open(`${window.location.origin}/integral?points_rule_id=${points_rule_id}`, '_blank');
        }
        this.context.router.history.push({pathname:'/integral'});

    }
    componentDidMount() {
        let location = this.context.router.route.location.pathname.replace(/[/||\\]/g, '');
        this.setState({
            location
        }, () => {
            this.queryActivityList()
            this.queryMiniAppDetail()
            if(this.state.location==='integralManage'){
                this.queryPointsRuleList()
            }
        })
    }

    render() {
        return (
            <div className="page">
                {
                    this.state.location==='cashManage'?null:(
                        <NavBar icon="icon-zichan" child= {
                            this.state.location ? this.state.page_info[this.state.location].title : null
                        }/>
                    )
                }
                <div className="content">
                    <div className="shopDataTitle">
                        <i className="titleSign"></i>
                        {
                            this.state.location ? this.state.page_info[this.state.location].title : null
                        }
                    </div>
                    {
                        this.state.location==='integralManage'?(
                            <div>
                                <Switch checked={this.state.mini_app.open_points === 1} onChange={this.createOrUpdateMiniApp}/>
                                <span className="switch-desc">积分功能已{this.state.mini_app.open_points ? "启用" : "关闭"}
                                </span>
                            </div>
                        ): null
                    }
                    <div className='integral-dashboard'>
                        <div className="integral-dashboard-data">
                            <div className="integral-title">
                                {
                                    this.state.location ? this.state.page_info[this.state.location].all_points_amount : null
                                }
                            </div>
                            <div className="integral-data">{this.state.mini_app.all_points_amount}</div>
                        </div>
                        {
                            this.state.location==="cashManage"?(
                                <div className="integral-dashboard-data">
                                    <div className="integral-title">
                                        待发放现金
                                    </div>
                                    <div className="integral-data">{this.state.mini_app.wait_cash_amount}</div>
                                </div>

                            ):null
                        }
                        <div className="integral-dashboard-data">
                            <div className="integral-title">
                                {
                                    this.state.location ? this.state.page_info[this.state.location].not_use_points_amount : null
                                }
                            </div>
                            <div className="integral-data">{this.state.mini_app.not_use_points_amount}</div>
                        </div>
                        <div className="integral-dashboard-data">
                            <div className="integral-title">
                                {
                                    this.state.location ? this.state.page_info[this.state.location].in_use_points_amount : null
                                }
                            </div>
                            <div className="integral-data">{this.state.mini_app.in_use_points_amount}</div>
                        </div>
                    </div>
                    <div className="integral-rule">
                        <div className="integral-desc-title">
                            {
                                this.state.location ? this.state.page_info[this.state.location].rule_title : null
                            }
                        </div>
                        {
                            this.state.location ? this.state.page_info[this.state.location].rules.map((item, index) =>
                                (<div className="integral-desc" key={index}>
                                    {item}
                                </div>)
                            ) : null
                        }
                    </div>
                    <div className="goto-integral-market">
                        {
                            this.state.location ? this.state.page_info[this.state.location].desc.map((item, index) =>
                                item.goto?(
                                        <div className="integral-desc-title" key={index}>

                                            {item.desc}
                                            <span className="text-note" onClick={this.goToIntegral}> {item.goto}</span>
                                        </div>
                                ):(
                                    <div className="integral-desc" key={index}>
                                        {item.desc}
                                    </div>
                                )) : null
                        }

                    </div>
                    {
                        this.state.location==='integralManage'?(
                            <div>
                                <div className="action">
                                    <Button type="primary" shape="round" onClick={this.createOrUpdatePointsRule.bind(this,null)}>新建积分规则</Button>
                                </div>
                                <Table
                                    rowKey={record => record.points_rule_goods_id}
                                    pagination={false}
                                    columns={this.state.columns}
                                    dataSource={this.state.points_rule_res_list}
                                    bordered={true}

                                />
                                <Pagination
                                    className="pagenation"
                                    onChange={this.onShowPageChange}
                                    onShowSizeChange={this.onShowSizeChange}
                                    defaultCurrent={this.state.page_no}
                                    total={this.state.total}
                                    showTotal={total => `共 ${total} 条`}
                                    pageSizeOptions={["10", "30", "50", "100"]}
                                    showSizeChanger
                                />
                            </div>

                        ):null
                    }
                </div>
            </div>
        )
    }
}

export default IntegralManage;

