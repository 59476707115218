import React from 'react'
import {querySelfShipmentOrderDueList, shipmentBatchXlsTask, shippingOutSelfOrder,querySelfShipmentCjOrderList} from '@service/getData'
import {Form, Button, Input, DatePicker, Table, Pagination, message, Modal, Upload,Popconfirm,Radio} from 'antd'
import cookie from "react-cookies";
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import SelfShipmentModal from '../common/SelfShipmentModal'
import config from "@config/config";
import GoodsRemind from '../common/GoodsRemind'

class SelfOrderShipment extends React.Component {
    constructor(props) {
        super(props)
        SelfOrderShipment.contextTypes = {
            router: PropTypes.object.isRequired
        }
        this.state = {
            page: 1,
            page_size: 10,
            total: 0,
            columns: [{
                title: '订单号',
                width:"7.7%",
                dataIndex: 'order_sn',
                render: (value, row) => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },
            },{
                title: '微信商户订单号',
                width: "7.7%",
                dataIndex: 'transaction_id',
                render: (value, row) => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },
            },{
                title: '微信名|收货人|电话',
                width:"10.4%",
                dataIndex: 'user',
                render: (value, row) => {
                    let obj = {
                        children: (
                            <div>
                                <p>{row.nick_name}</p>
                                <p>{row.receiver_name}</p>
                                <p>{row.mobile}</p>
                            </div>
                        ),
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },
            }, {
                title: '地址',
                width:"10.95%",
                dataIndex: 'address',
            }, {
                title: '付款时间',
                width:"9.47%",
                dataIndex: 'pay_time',
                sorter: true,
                defaultSortOrder: 'descend',
                render: (value, row) => {
                    let obj = {
                            children: value?(<div><div>{value.slice(0,11)}</div><div>{value.slice(11,value.length)}</div></div>):null,
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },
            }, {
                title: '类型',
                width:"4.69%",
                dataIndex: 'shipment_type',
                render: (value, row) => {
                    let obj = {
                        children: value === "group_shipment" ? "自提" : "邮寄",
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },
            }, {
                title: '商品',
                dataIndex: 'goods_name',
                width:"14.78%",
            }, {
                title: '规格',
                dataIndex: 'sku',
                width:"6.6%",
            }, {
                title: '单价|数量',
                dataIndex: 'num',
                width:"6.86%",
                render: (text, record) => (
                    <div>
                        <p>¥{record.current_price / 100}</p>
                        <p>{record.quantity?record.quantity:1}件</p>
                    </div>

                )
            }, {
                title: '订单状态',
                width:"7.07%",
                dataIndex: 'order_status_desc',
                render: (value, row) => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },
            }, {
                title: '付款金额',
                width:"7.04%",
                dataIndex: 'pay_amount',
                render: (value, row) => {
                    let obj = {
                        children: value / 100,
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },
            }, {
                title: '操作',
                width: "6.86%",
                dataIndex: 'action',
                render: (value, row) => {
                    let obj = {
                        children: (
                            <div>
                                <p className="text-note"
                                   onClick={this.gotoOrderDetail.bind(this, row.order_id)}>查看详情</p>
                                <p className="text-note" onClick={this.showModal.bind(this, row)}>发货</p>
                            </div>
                        ),
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },

            }
            ],
            order_list: [],
            visible: false,
            batch_visible: false,
            order: {},
            fileList: [],
            action: {
                action: config.baseUrl + "/ossFile/upload",
                withCredentials: true
            },
            request_url: config.baseUrl + "/shipment/exportSelfShipmentOrderDueList",
            cj_request_url: config.baseUrl + "/cjGroup/exportSelfShipmentCjOrderList",
            goodsRemindVisible:false,
            goodsRemindList:[],
            cj_columns: [{
                title: '订单号',
                width:"7.7%",
                dataIndex: 'order_sn',
                render: (value, row) => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },
            },{
                title: '微信名|收货人|电话',
                width:"13.4%",
                dataIndex: 'user',
                render: (value, row) => {
                    let obj = {
                        children: (
                            <div>
                                <p>{row.nick_name}</p>
                                <p>{row.receiver_name}</p>
                                <p>{row.mobile}</p>
                            </div>
                        ),
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },
            }, {
                title: '地址',
                width:"10.95%",
                dataIndex: 'address',
            }, {
                title: '付款时间',
                width:"9.47%",
                dataIndex: 'pay_time',
                render: (value, row) => {
                    let obj = {
                        children: value?(<div><div>{value.slice(0,11)}</div><div>{value.slice(11,value.length)}</div></div>):null,
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },
            }, {
                title: '类型',
                width:"4.69%",
                dataIndex: 'shipment_type',
                render: (value, row) => {
                    let obj = {
                        children: value === "group_shipment" ? "自提" : "邮寄",
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },
            }, {
                title: '商品',
                dataIndex: 'goods_name',
                width:"14.78%",
            }, {
                title: '规格',
                dataIndex: 'sku',
                width:"6.6%",
            }, {
                title: '单价|数量',
                dataIndex: 'num',
                width:"6.86%",
                render: (text, record) => (
                    <div>
                        <p>¥{record.current_price / 100}</p>
                        <p>{record.quantity?record.quantity:1}件</p>
                    </div>

                )
            }, {
                title: '订单状态',
                width:"7.07%",
                dataIndex: 'order_status_desc',
                render: (value, row) => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },
            }, {
                title: '付款金额',
                width:"7.04%",
                dataIndex: 'pay_amount',
                render: (value, row) => {
                    let obj = {
                        children: value / 100,
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },
            }, {
                title: '操作',
                width: "6.86%",
                dataIndex: 'action',
                render: (value, row) => {
                    let obj = {
                        children: (
                            <div>
                                <p className="text-note"
                                   onClick={this.gotoOrderDetail.bind(this, row.order_id)}>查看详情</p>
                                <p className="text-note" onClick={this.showModal.bind(this, row)}>发货</p>
                            </div>
                        ),
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    return obj;
                },

            }
            ],
            order_by_condition:'payTimeDesc'

        }
    }
    querySelfShipmentCjOrderList=async (data)=>{
        let res=await querySelfShipmentCjOrderList(data);
        if(res.success){
            let order_list=[];
            res.result.cj_order_res_list.forEach(item=>{
                let order = {
                    order_id: item.cj_order_id,
                    order_sn: item.cj_order_sn,
                    sku: "",
                    pay_time: item.pay_time,
                    order_status: item.order_status,
                    order_status_desc: this.props.cj_order_status_list[item.order_status].order_status_short_desc,
                    pay_amount: item.pay_amount,
                    nick_name: item.user_res.nick_name,
                    mobile: item.address_res.tel_number,
                    shipment_type: item.shipment_type,
                    receiver_name: item.address_res.receiver_name,
                    quantity: item.goods_res.quantity,
                    address: item.address_res.province_name + item.address_res.city_name + item.address_res.county_name + item.address_res.detail_info,
                    goods_name:item.goods_res.goods_name,
                    current_price :item.sku_res.current_price,

                }
                item.sku_res.attribute_value_res_list.forEach(item => {
                    order.sku += item.attribute_value
                })
                order.order_goods_list=[
                    {
                        goods_res:item.goods_res,
                        sku_res:item.sku_res
                    }
                ]
                order_list.push(order)
            })
            this.setState({
                order_list: order_list,
                total:res.result.total
            })
        }

    }

    async shipmentBatchXlsTask(data) {
        let res = await shipmentBatchXlsTask(data);
        if (res.success) {
            this.setState({
                batch_visible: false,
                fileList: [],
                goodsRemindVisible:res.result.goods_limit_notice_list.length?true:false,
                goodsRemindList:res.result.goods_limit_notice_list.length?res.result.goods_limit_notice_list:[]
            });
            if(!res.result.goods_limit_notice_list.length){
                message.success("已成功发送" + res.result.shipment_success_num + "条记录")
            }
        }
    }

    async querySelfShipmentOrderDueList(data) {
        data.order_by_condition=this.state.order_by_condition
        let res = await querySelfShipmentOrderDueList(data)
        let order_list = []
        if (res.success) {
            res.result.order_res_list.forEach(item => {
                    item.order_goods_res_list.forEach((goods, index) => {
                        let order = {
                            order_id: item.order_id,
                            order_sn: item.order_sn,
                            sku: "",
                            pay_time: item.pay_time,
                            transaction_id:item.transaction_id,
                            order_status: item.order_status,
                            order_status_desc: this.props.order_status_list[item.order_status].order_status_short_desc,
                            pay_amount: item.pay_amount,
                            nick_name: item.user_res.nick_name,
                            mobile: item.address_res.tel_number,
                            shipment_type: item.shipment_type,
                            receiver_name: item.address_res.receiver_name,
                            quantity: goods.quantity,
                            address: item.address_res.province_name + item.address_res.city_name + item.address_res.county_name + item.address_res.detail_info,
                        }
                        order.goods_name = goods.goods_res.goods_name
                        order.current_price = goods.sku_res.current_price
                        goods.sku_res.attribute_value_res_list.forEach(item => {
                            order.sku += item.attribute_value
                        })
                        if (index === 0) {
                            order.rowSpan = item.order_goods_res_list.length;
                            order.order_goods_list = item.order_goods_res_list
                        } else {
                            order.rowSpan = 0
                        }

                        order_list.push(order)
                })
            })
            this.setState({
                order_list: order_list,
                total:res.result.total
            })
        }
    }

    async shippingOutSelfOrder(data) {
        let res = await shippingOutSelfOrder(data)
        if (res.success) {
            if(!res.result.goods_limit_notice_list.length){
                message.success("发货成功")
            }
            this.setState({
                visible: false,
                order: {},
                goodsRemindVisible:res.result.goods_limit_notice_list.length?true:false,
                goodsRemindList:res.result.goods_limit_notice_list.length?res.result.goods_limit_notice_list:[]
            })
            this.filterOrder(1)
        }

    }

    filterOrder = (status) => {
        if(!cookie.load("shop_id")&&Number(cookie.load("is_admin"))===1){
            return
        }
        let form = this.props.form.getFieldsValue();
        let data = {
            shop_id: cookie.load("shop_id"),
            page_size: this.state.page_size,
            page_no: this.state.page,
        }
        if (form.order_sn) {
            this.props.index==="1"?data.order_sn = form.order_sn:data.cj_order_sn = form.order_sn
        }
        if(form.transaction_id){
            data.transaction_id=form.transaction_id
        }
        if (form.receiver_name) {
            data.received_name = form.receiver_name
        }
        if (form.mobile) {
            var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
            if (!myreg.test(form.mobile)) {
                return message.error("手机号码格式错误")
            } else {
                data.tel_number = form.mobile
            }
        }
        if (form.goods_name) {
            data.goods_name = form.goods_name
        }
        if (this.state.start_order_time) {
            data.start_pay_time = this.state.start_order_time
        }
        if (this.state.end_order_time) {
            data.end_pay_time = this.state.end_order_time
        }

        this.props.index==="1"?this.querySelfShipmentOrderDueList(data):this.querySelfShipmentCjOrderList(data)
        if(status===1){
            window.scrollTo(0,0);
        }
    }
    clearFilter = () => {
        this.props.form.resetFields();
    };
    onShowTimeChange = (date, dateStr) => {
        this.setState({
            start_order_time: dateStr[0],
            end_order_time: dateStr[1],
        })
    }
    gotoOrderDetail = (order_id) => {
        let arr=this.props.index==="1"?"order_id":"cj_order_id"
        this.context.router.history.push(`/orderDetail?${arr}=${order_id}&type=orderShipment`)
    }
    gotoBatch = () => {
        this.context.router.history.push("/batchShipment")
    }
    showModal = (row) => {
        this.setState({
            visible: true,
            order: row
        })
    }
    hideModal = () => {
        this.setState({
            visible: false
        })
    }
    confirmShipment = (info) => {

        let data = {
            order_id: this.props.index==="1"?this.state.order.order_id:null,
            cj_order_id: this.props.index==="2"?this.state.order.order_id:null,
            logistics: info.logistics,
            tracking_number: info.tracking_number
        }
        this.shippingOutSelfOrder(data)

    }
    showBatchModal = () => {
        this.setState({
            batch_visible: true
        });
    }
    handleBatchOk = (e) => {
        if (this.state.fileList.length < 1) {
            return message.success("请上传文件之后再操作")
        }
        let data = {
            shop_id: cookie.load("shop_id"),
            oss_file_url: this.state.fileList[0].response.result.oss_file_url
        }
        this.shipmentBatchXlsTask(data)
    }

    handleBatchCancel = (e) => {
        this.setState({
            batch_visible: false,
            fileList: []
        });
    }
    onChange = (info) => {
        this.setState({
            fileList:info.fileList
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size:pageSize
        }, ()=> {
            this.filterOrder(1)
        })

    }
    handleGoodsRemindOk = (e) => {
        this.setState({
            goodsRemindVisible:false

        })
        this.context.router.history.push("/balanceManage")
    }
    handleGoodsRemindFalse = (e) => {
        this.setState({
            goodsRemindVisible:false

        })
    }
    onShowPageChange = (page) => {
        this.setState({
            page:page
        },()=> {
            this.filterOrder(1)
        })

    }

    onExport = () => {
        let form = this.props.form.getFieldsValue();
        let url = this.state.request_url + "?shop_id=" + cookie.load("shop_id")
        if (form.order_sn) {
            url += "&order_sn=" + form.order_sn
        }
        if (form.receiver_name) {
            url += "&received_name=" + form.receiver_name
        }
        if (form.transaction_id) {
            url += "&transaction_id=" + form.transaction_id
        }
        if (form.mobile) {
            var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
            if (!myreg.test(form.mobile)) {
                return message.error("手机号码格式错误")
            } else {
                url += "&telNumber=" + form.mobile
            }
        }
        if (form.goods_name) {
            url += "&goods_name=" + form.goods_name
        }
        if (this.state.start_order_time) {
            url += "&start_pay_time=" + this.state.start_order_time
        }
        if (this.state.end_order_time) {
            url += "&end_pay_time=" + this.state.end_order_time
        }
        if(form.export_type){
            url+="&export_type="+form.export_type
        }
        window.open(url)
    }

    onCjExport = () => {
        let form = this.props.form.getFieldsValue();
        let url = this.state.cj_request_url + "?shop_id=" + cookie.load("shop_id")
        if (form.order_sn) {
            url += "&order_sn=" + form.order_sn
        }
        if (form.receiver_name) {
            url += "&received_name=" + form.receiver_name
        }
        if (form.mobile) {
            var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
            if (!myreg.test(form.mobile)) {
                return message.error("手机号码格式错误")
            } else {
                url += "&telNumber=" + form.mobile
            }
        }
        if (form.goods_name) {
            url += "&goods_name=" + form.goods_name
        }
        if (this.state.start_order_time) {
            url += "&start_pay_time=" + this.state.start_order_time
        }
        if (this.state.end_order_time) {
            url += "&end_pay_time=" + this.state.end_order_time
        }
        if(form.export_type){
            url+="&export_type="+form.export_type
        }
        window.open(url)
    }
    handleTableChange=(pagination, filters, sorter)=>{
        if(sorter.order==="ascend"){

        }
        this.setState({
            order_by_condition:sorter.order==="descend"?"payTimeDesc":"payTimeAsc"
        },()=>{
            this.filterOrder(0)
        })
    }

    componentDidMount() {
        this.filterOrder(1)
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div className="self-order-shipment">
                <div className="filter-content">
                    <Form className="ant-vertical" layout="inline">
                        <Form.Item label="订单号">
                            {getFieldDecorator('order_sn')(
                                <Input className="form-input" placeholder="输入订单号"/>
                            )}
                        </Form.Item>
                        <Form.Item label="收货人">
                            {getFieldDecorator('receiver_name')(
                                <Input className="form-input" placeholder="输入收货人姓名"/>
                            )}
                        </Form.Item>
                        <Form.Item label="手机号 ">
                            {getFieldDecorator('mobile')(
                                <Input className="form-input" placeholder="输入收货手机号码"/>
                            )}
                        </Form.Item>
                        <Form.Item label="付款时间">
                            {getFieldDecorator('date')(
                                <DatePicker.RangePicker className="form-input-long" onChange={this.onShowTimeChange}
                                                        showTime/>
                            )}
                        </Form.Item>
                        <Form.Item label="商品名称">
                            {getFieldDecorator('goods_name')(
                                <Input className="form-input-long" placeholder="输入商品名称"/>
                            )}
                        </Form.Item>
                    </Form>
                    <Form.Item label="微信商户号订单"
                               style={{display:this.props.index==="1"?true:'none'}}
                    >
                        {getFieldDecorator('transaction_id')(
                            <Input className="form-input-long" placeholder="微信商户号订单"/>
                        )}
                    </Form.Item>
                    <div className="action">
                        <Button shape="round" type="primary" onClick={this.onShowPageChange.bind(this,1)}>筛选</Button>
                        {
                            this.props.index==="1"?(<Button shape="round" type="primary" onClick={this.showBatchModal}>批量发货</Button>):null
                        }
                        <Popconfirm
                            icon={null}
                            title={
                                (
                                    <Form>
                                        <Form.Item>
                                            {getFieldDecorator('export_type',{
                                                initialValue: "base",

                                            })(
                                                <Radio.Group>
                                                    <Radio value="base">打单订单导出模版订单</Radio>
                                                    <Radio value="special">erp订单导出模版订单</Radio>
                                                </Radio.Group>
                                            )}
                                        </Form.Item>
                                    </Form>
                                )
                            }
                            onConfirm={this.props.index==="1"?this.onExport:this.onCjExport}
                            okText="确定"
                            cancelText="取消"
                        >
                            <Button shape="round" type="primary">导出</Button>
                        </Popconfirm>
                        <span className="text-note" onClick={this.clearFilter}>清空筛选条件</span>
                    </div>
                </div>
                <Table rowKey={(record, index) => index} columns={this.props.index==="1"?this.state.columns:this.state.cj_columns}
                       dataSource={this.state.order_list} pagination={false} bordered={true}  onChange={this.handleTableChange}/>
                <Pagination
                    className="pagenation"
                    onChange={this.onShowPageChange}
                    onShowSizeChange={this.onShowSizeChange}
                    defaultCurrent={this.state.page}
                    total={this.state.total}
                    showTotal={(total) =>  `共 ${total} 条`}
                    pageSizeOptions={["10","30","50","100"]}
                    showSizeChanger
                />
                <SelfShipmentModal visible={this.state.visible} handleSelfOk={this.confirmShipment}
                                   handleSelfCancel={this.hideModal} order={this.state.order}/>
                <Modal
                    title="批量发货"
                    visible={this.state.batch_visible}
                    onOk={this.handleBatchOk}
                    onCancel={this.handleBatchCancel}
                    okText="批量导入"
                    okButtonProps={{shape:'round'}}
                    cancelButtonProps={{shape:'round'}}
                >
                    <div className="batch-modal-content">
                        <Upload
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                            {...this.state.action}
                            onChange={this.onChange}
                            fileList={this.state.fileList}
                        >
                            <Button disabled={this.state.fileList.length > 0 ? true : false} shape="round">上传</Button>
                        </Upload>
                        <div className="text-note">
                            <a href={config.baseUrl + "/file/shipmentBatchDownload"}>下载批量发货模版</a>
                        </div>
                    </div>
                </Modal>
                <GoodsRemind
                    visible={this.state.goodsRemindVisible}
                    data={this.state.goodsRemindList}
                    title="以下商品缺货请即时补充库存"
                    handleOk={this.handleGoodsRemindOk}
                    handleCancel={this.handleGoodsRemindFalse}
                >
                </GoodsRemind>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
        order_status_list: state.orderStatusList,
        cj_order_status_list: state.cjOrderStatusList
    }
}

const SelfOrderShipmentForm = Form.create({name: 'self_order_shipment_form'})(SelfOrderShipment);

export default connect(mapStateToProps)(SelfOrderShipmentForm);
