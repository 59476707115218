import React from "react";
import { Modal, Form, Table, Input, Button } from "antd";
import { queryGoodsList } from "@service/getData";
import cookie from "react-cookies";
class GoodsChoose extends React.Component {
	constructor() {
		super();
		this.state = {
			loading2: false,
			goods_columns: [
				{
					title: "商品id",
					dataIndex: "goods_id"
				},
				{
					title: "商品图片",
					dataIndex: "thumbnail_url",
					render: (text, record) => (
						<div>
							<img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="100" height="100" />
						</div>
					)
				},
				{
					title: "商品名称",
					dataIndex: "goods_name"
				},
				{
					title: "价格",
					dataIndex: "current_price",
					render: text => <div>￥{text / 100}</div>
				}
			],
			goods_res_list: [],
			search_value: null,
			selected_row_keys: [],
			current_select_goods_res: [],
			coupon_columns: [
				{
					title: "商品id",
					dataIndex: "goods_id"
				},
				{
					title: "商品图片",
					dataIndex: "thumbnail_url",
					render: (text, record) => (
						<div>
							<img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="100" height="100" />
						</div>
					)
				},
				{
					title: "商品名称",
					dataIndex: "goods_name"
				},
				{
					title: "价格",
					dataIndex: "current_price",
					render: text => <div>￥{text / 100}</div>
				},
				{
					title: "参加的活动",
					dataIndex: "activity_coupon_res_list",
					render: text => {
						return <div>{Array.isArray(text) && text.length > 0 ? text[0].coupon_name : null}</div>;
					}
				}
			]
		};
	}
	onSelectedRowKeysChange = (selected_row_keys, record) => {
		// 只能选择一个商品的情况，传参 single={true}
		if (this.props.single && selected_row_keys.length > 1) {
			const key = selected_row_keys.slice(selected_row_keys.length - 1);
			const current = record.slice(record.length - 1);
			this.setState({
				selected_row_keys: key,
				current_select_goods_res: current,
			});
			return;
		}
		this.setState({
			selected_row_keys: selected_row_keys,
			current_select_goods_res: record
		});
	};
	queryGoodsList = async () => {
		this.setState({
			loading2: true
		});
		let data = {
			shop_id: cookie.load("shop_id"),
			onsale: 1
		},
			value = this.state.search_value;
		if (isNaN(+value)) {
			data.goods_name = value;
		} else {
			data.goods_id = value;
		}
		let res = await queryGoodsList(data);
		if (res.success) {
			this.setState({
				loading2: false,
				goods_res_list: res.result.goods_res_list
			});
		}
	};
	componentWillReceiveProps(props) {
		if (props.visible === true) {
			this.setState(
				{
					goods_res_list: [],
					search_value: null,
					selected_row_keys: [],
					current_select_goods_res: []
				},
				() => {
					this.queryGoodsList();
				}
			);
		}
	}
	searchHandle = e => {
		this.setState({
			search_value: e.target.value
		});
	};
	render() {
		const rowSelection = {
			selectedRowKeys: this.state.selected_row_keys,
			onChange: this.onSelectedRowKeysChange,
			hideDefaultSelections: true,
			columnTitle: "选择"
		};
		return (
			<Modal
				width="1000px"
				style={{ top: 50 }}
				visible={this.props.visible}
				onCancel={this.props.handleCancel}
				onOk={this.props.handleOk.bind(this, this.state.current_select_goods_res)}
				okButtonProps={{ shape: "round" }}
				cancelButtonProps={{ shape: "round" }}>
				<div className="table-content">
					<div className="table-header">
						<Form
							labelCol={{
								span: 12
							}}
							wrapperCol={{
								span: 12
							}}
							layout="inline">
							<Form.Item label="商品名称/商品id">
								<Input value={this.state.search_value} onChange={this.searchHandle} />
							</Form.Item>
							<Form.Item>
								<Button shape="round" onClick={this.queryGoodsList.bind(this)}>
									搜索
                				</Button>
							</Form.Item>
						</Form>
					</div>
					<Table
						scroll={{ y: 400 }}
						loading={this.state.loading2}
						className="select-goods-modal"
						rowKey={record => record.goods_id}
						bordered={true}
						rowSelection={rowSelection}
						columns={this.props.activityType === "coupon" ? this.state.coupon_columns : this.state.goods_columns}
						dataSource={this.state.goods_res_list}
					/>
				</div>
			</Modal>
		);
	}
}
export default GoodsChoose;
