import React from "react";
import { connect } from "react-redux";
import "@css/shopManage.scss";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import { queryMiniAppDetail, createOrUpdateMiniApp } from "../../../service/getData";
import { Form, Modal, Button, message, Popover, Input } from "antd";
import UploadComponent from "../../component/common/Upload";

import StyleSetting from './children/StyleSetting';


class ShopManage extends React.Component {
    constructor(props) {
        super();
        ShopManage.contextTypes = {
            router: PropTypes.object.isRequired
        };
        this.state = {
            key: "1",
            cardPreviewVisible: false,
            cardPreviewImage: "",
            logoPreviewVisible: false,
            logoPreviewImage: "",
            home_forward_title: "",
            cardFileList: [],
            logoFileList: [],
            mini_app: {}
        };
    }
    // 钩子函数
    componentDidMount() {
        this.queryMiniAppDetail();
    }
    // 异步接口获取表单信息
    async queryMiniAppDetail() {
        this.setState({
            loading: true,
        });
        let mini_app_id = cookie.load("mini_app_id");
        // 确保获取到了数据
        let res = await queryMiniAppDetail({
            mini_app_id
        });
        this.setState({
            // 小程序信息
            mini_app: res.result.mini_app,
            home_forward_title: res.result.mini_app.home_forward_title,
            // 小程序卡片图上传组件初始化信息
            cardFileList: [
                {
                    uid: "-1",
                    name: "xxx",
                    status: "done",
                    url: res.result.mini_app.home_forward_image
                },
            ],
            // 小程序logo图上传组件初始化信息
            logoFileList: [
                {
                    uid: "-1",
                    name: "xxx",
                    status: "done",
                    url: res.result.mini_app.logo
                },
            ],
            loading: false,
        });
    }
    // 修改小程序标题
    changeTitle = e => {
        this.setState({
            home_forward_title: e.target.value
        });
    }
    // 小程序卡片预览
    cardHandlePreview = file => {
        this.setState({
            cardPreviewImage: file.url || file.thumbUrl,
            cardPreviewVisible: true
        });
    };
    // logo预览
    logoHandlePreview = (file) => {
        this.setState({
            logoPreviewImage: file.url || file.thumbUrl,
            logoPreviewVisible: true,
        });
    }
    // 修改logo
    logoHandleChange = info => {
        let logoFileList = info.fileList;
        logoFileList = logoFileList.map(file => {
            if (file.response) {
                file.url = file.response.result.oss_file_url;
            }
            return file;
        });
        this.setState({ logoFileList });
    };
    // 修改小程序卡片
    cardHandleChange = info => {
        let cardFileList = info.fileList;
        cardFileList = cardFileList.map(file => {
            if (file.response) {
                file.url = file.response.result.oss_file_url;
            }
            return file;
        });
        this.setState({ cardFileList });
    };
    handleCancelCard() {
        this.setState({
            cardPreviewVisible: false,
        });
    }
    handleCancelLogo() {
        this.setState({
            logoPreviewVisible: false,
        });
    }
    // 保存
    createOrUpdateMiniApp = async () => {
        if (this.state.logoFileList.length === 0) {
            message.warning("请上传logo图");
            return;
        }
        let logo = this.state.logoFileList[0].url;
        let home_forward_image = this.state.cardFileList[0] && this.state.cardFileList[0].url;
        let res = await createOrUpdateMiniApp({
            miniApp: {
                mini_app_id: cookie.load("mini_app_id"),
                home_forward_image,
                home_forward_title: this.state.home_forward_title,
                logo,
            }
        });
        if (res.success) {
            message.success("保存成功");
        }
    };
    onAllSave() {
        this.createOrUpdateMiniApp();
        this.myRef.createOrUpdateMiniAppPageConfigList();
    }
    onRef = (ref) => {
        this.myRef = ref;
    }
    render() {
        const formLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
        };
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="shop-manage">
                <div className="content">
                    <Form {...formLayout}>
                        <Form.Item>
                            <span className="title">分享设置</span>
                            <Popover
                                content={
                                    <img
                                        src="http://udh.oss-cn-hangzhou.aliyuncs.com/136a1a64-9bcf-43ad-b404-31e875bd9546"
                                        width="300"
                                        height="242"
                                        alt="示例图片"
                                    />
                                }
                            >
                                <span className="text-note2">示例</span>
                            </Popover>
                        </Form.Item>
                        <Form.Item label="小程序分享文案">
                            <Input
                                value={this.state.home_forward_title}
                                onInput={this.changeTitle.bind(this)}
                            />
                        </Form.Item>
                        <Form.Item label="小程序分享图片">
                            {getFieldDecorator("logo", {
                                initialValue: this.state.logo
                            })(
                                <div>
                                    <UploadComponent
                                        showWH={true}
                                        fileList={this.state.cardFileList}
                                        onPreview={this.cardHandlePreview}
                                        onChange={this.cardHandleChange}
                                        btn_text="上传图片"
                                    />
                                    <p className="text-note">建议尺寸宽500，高400</p>
                                    <p className="text-note">
                                        仅支持jpeg,png 2种格式,且只能上传一张，小于2M。
                                    </p>
                                </div>
                            )}
                        </Form.Item>
                        <Form.Item>
                            <span className="title">基本信息设置设置</span>
                            <Popover
                                content={
                                    <img alt="示例图片"
                                        src="http://udh.oss-cn-hangzhou.aliyuncs.com/224a161c-1dc7-408c-bea2-8775977dc1c4"
                                        className="dropimg"
                                        width="220"
                                        height="480"
                                    />
                                }
                                placement="topLeft"
                            >
                                <span className="text-note2">示例</span>
                            </Popover>
                        </Form.Item>
                        <Form.Item label="店铺名称">
                            <div>{this.state.mini_app.mini_app_name}</div>
                        </Form.Item>
                        <Form.Item label="上传logo">
                            {getFieldDecorator("logo", {
                                initialValue: this.state.logo
                            })(
                                <div>
                                    <UploadComponent
                                        showWH={true}
                                        fileList={this.state.logoFileList}
                                        onPreview={this.logoHandlePreview}
                                        onChange={this.logoHandleChange}
                                        btn_text="上传logo"
                                    />
                                    <p className="text-note">
                                        建议使用透底logo图，仅支持png格式，且只能上传一张，小于2M。
                                    </p>
                                </div>
                            )}
                        </Form.Item>
                    </Form>
                </div>
                <StyleSetting onRef={this.onRef} />
                <Button shape="round" type="primary" className="saveBtn" onClick={this.onAllSave.bind(this)}>保存</Button>
                <Modal
                    className="black-modal"
                    visible={this.state.cardPreviewVisible}
                    footer={null}
                    onCancel={() => this.handleCancelCard()}
                    okButtonProps={{ shape: 'round' }}
                    cancelButtonProps={{ shape: 'round' }}
                >
                    <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={this.state.cardPreviewImage}
                    />
                </Modal>
                <Modal
                    className="black-modal"
                    visible={this.state.logoPreviewVisible}
                    footer={null}
                    onCancel={() => this.handleCancelLogo()}
                    okButtonProps={{ shape: 'round' }}
                    cancelButtonProps={{ shape: 'round' }}
                >
                    <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={this.state.logoPreviewImage}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        menu_code: state.menuCode
    };
};
const ShopManageForm = Form.create({ name: "shop_manage_form" })(ShopManage);

export default connect(mapStateToProps)(ShopManageForm);
