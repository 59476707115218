import React from 'react'
import { Form, Button, Input, DatePicker, Table, Pagination, message } from 'antd'
import { queryGroupShipmentOrderList, queryGroupShipmentOrderDueList, createShipment, printShipment, shippingOutGroupOrder } from '@service/getData'
import cookie from 'react-cookies'
import { connect } from "react-redux";
import '@css/groupOrderShipment.scss'
import PropTypes from 'prop-types'
import GroupShipmentModal from '@/view/component/common/GroupShipmentModal'
import PrintModal from '@/view/component/common/PrintModal'
import GoodsRemind from '@/view/component/common/GoodsRemind'

class GroupOrderShipment extends React.Component {
    constructor() {
        GroupOrderShipment.contextTypes = {
            router: PropTypes.object.isRequired
        }
        super();
        this.state = {
            order_history_list: [],
            columns: [{
                title: '配送单号',
                dataIndex: 'shipment_sn',
                render: (value, row) => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.row_span != null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },

            }, {

                title: '订单编号',
                dataIndex: 'order_sn',
                render: (value, row) => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.order_goods_rowspan != null) {
                        obj.props.rowSpan = row.order_goods_rowspan
                    }
                    return obj;
                },

            }, {

                title: '商品名称',
                dataIndex: 'goods_name',
            }, {

                title: '规格',
                dataIndex: 'sku',
            }, {

                title: '售后',
                dataIndex: 'refund_status',
                render: (text) => {
                    return text === 1 ? "售后完成" : text === 0 ? "售后中" : "无售后"
                }
            }, {
                title: '单价|数量',
                dataIndex: 'num',
                render: (text, record) => (
                    <div>
                        <p>¥{record.current_price / 100}</p>
                        <p>{record.quantity}件</p>
                    </div>
                )
            }, {

                title: '付款时间',
                dataIndex: 'pay_time',
                render: (value, row) => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.order_goods_rowspan != null) {
                        obj.props.rowSpan = row.order_goods_rowspan
                    }
                    return obj;
                },
            }, {

                title: '社区信息',
                dataIndex: 'comunity',
                render: (value, row) => {
                    let obj = {
                        children: (
                            <div>
                                <p>{row.community_name}</p>
                                <p>{row.receiver_name}{row.mobile}</p>
                                <p>{row.address}</p>
                            </div>
                        ),
                        props: {},
                    };
                    if (row.row_span != null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },
            }, {
                title: '类型',
                dataIndex: 'shipment_type',
                render: (value, row) => {
                    let obj = {
                        children: (<p>{value === "group_shipment" ? "自提" : "邮寄"}</p>),
                        props: {},
                    };
                    if (row.row_span != null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },
            }
            ],
            shipment_columns: [
                {

                    title: '社区信息',
                    dataIndex: 'comunity',
                    render: (value, row) => {
                        let obj = {
                            children: (
                                <div>
                                    <p>{row.community_name}</p>
                                    <p>{row.receiver_name}{row.mobile}</p>
                                    <p>{row.address}</p>
                                </div>
                            ),
                            props: {},
                        };
                        if (row.row_span != null) {
                            obj.props.rowSpan = row.row_span
                        }
                        return obj;
                    },
                },
                {

                    title: '商品名称',
                    dataIndex: 'goods_name',
                }, {

                    title: '规格',
                    dataIndex: 'sku',
                }, {

                    title: '售后',
                    dataIndex: 'refund_status',
                    render: (text) => {
                        return text === 1 ? "售后完成" : text === 0 ? "售后中" : "无售后"
                    }
                }, {
                    title: '单价|数量',
                    dataIndex: 'num',
                    render: (text, record) => (
                        <div>
                            <p>¥{record.current_price / 100}</p>
                            <p>{record.quantity}件</p>
                        </div>
                    )
                }, {

                    title: '订单编号',
                    dataIndex: 'order_sn',
                    render: (value, row) => {
                        let obj = {
                            children: value,
                            props: {},
                        };
                        if (row.order_goods_rowspan != null) {
                            obj.props.rowSpan = row.order_goods_rowspan
                        }
                        return obj;
                    },

                }, {

                    title: '付款时间',
                    dataIndex: 'pay_time',
                    render: (value, row) => {
                        let obj = {
                            children: value,
                            props: {},
                        };
                        if (row.order_goods_rowspan != null) {
                            obj.props.rowSpan = row.order_goods_rowspan
                        }
                        return obj;
                    },
                }, {
                    title: '类型',
                    dataIndex: 'shipment_type',
                    render: (value, row) => {
                        let obj = {
                            children: (<p>{value === "group_shipment" ? "自提" : "邮寄"}</p>),
                            props: {},
                        };
                        if (row.row_span != null) {
                            obj.props.rowSpan = row.row_span
                        }
                        return obj;
                    },
                }
            ],
            ship_columns: [{
                title: '配送单号',
                dataIndex: 'shipment_sn',
                render: (value, row) => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.row_span != null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },

            }, {

                title: '订单编号',
                dataIndex: 'order_sn',
                render: (value, row) => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.order_goods_rowspan != null) {
                        obj.props.rowSpan = row.order_goods_rowspan
                    }
                    return obj;
                },

            }, {

                title: '商品名称',
                dataIndex: 'goods_name',
            }, {

                title: '规格',
                dataIndex: 'sku',
            }, {

                title: '售后',
                dataIndex: 'refund_status',
                render: (text) => {
                    return text === 1 ? "售后完成" : text === 0 ? "售后中" : "无售后"
                }
            }, {
                title: '单价|数量',
                dataIndex: 'num',
                render: (text, record) => (
                    <div>
                        <p>¥{record.current_price / 100}</p>
                        <p>{record.quantity}件</p>
                    </div>
                )
            }, {

                title: '付款时间',
                dataIndex: 'pay_time',
                render: (value, row) => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.order_goods_rowspan != null) {
                        obj.props.rowSpan = row.order_goods_rowspan
                    }
                    return obj;
                },
            }, {

                title: '社区信息',
                dataIndex: 'comunity',
                render: (value, row) => {
                    let obj = {
                        children: (
                            <div>
                                <p>{row.community_name}</p>
                                <p>{row.receiver_name}{row.mobile}</p>
                                <p>{row.address}</p>
                            </div>
                        ),
                        props: {},
                    };
                    if (row.row_span != null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },
            }, {
                title: '类型',
                dataIndex: 'shipment_type',
                render: (value, row) => {
                    let obj = {
                        children: (<p>{value === "group_shipment" ? "自提" : "邮寄"}</p>),
                        props: {},
                    };
                    if (row.row_span != null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },
            }, {
                title: '操作',
                dataIndex: 'action',
                render: (value, row) => {
                    let obj = {
                        children: (
                            <div>
                                <p className="text-note" style={{ display: this.props.status === 0 ? "none" : true }} onClick={this.showModal.bind(this, row)}>发货</p>
                                <p className="text-note" style={{ display: this.props.status === 1 ? "none" : true }} onClick={this.showPrintModal.bind(this, row)}>打印</p>
                            </div>),
                        props: {},
                    };
                    if (row.row_span != null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },
            }
            ],
            order_shipment_type: "group_shipment",
            start_order_time: null,
            end_order_time: null,
            page_size: 10,
            page: 1,
            total: 0,
            selectedRowKeys: [],
            selectedRows: [],
            visible: false,
            print_visible: false,
            order: {},
            goodsRemindVisible: false,
            goodsRemindList: []
        }
    }
    gotoDriver = () => {
        this.context.router.history.push("/driverManage")
    }
    async queryGroupShipmentOrderList(data) {
        let order_history_list = []
        let res = await queryGroupShipmentOrderList(data);
        if (res.success) {
            res.result.shipment_res_list.forEach(item => {
                let position = order_history_list.length;
                let row_span = 0;
                let ship_index = 0, goods_list = [];
                item.order_res_list.forEach(order => {
                    row_span += order.order_goods_res_list.length
                    order.order_goods_res_list.forEach((goods, index) => {
                        let data = {
                            logistics: item.logistics,
                            order_sn: order.order_sn,
                            pay_time: order.pay_time,
                            receiver_name: item.receiver_name,
                            shipping_time: item.shipping_time,
                            order_status: order.order_status,
                            shipment_type: order.shipment_type,
                            shipment_sn: item.shipment_sn,
                            shipment_id: item.shipment_id,
                            goods_name: goods.goods_res.goods_name,
                            quantity: goods.quantity,
                            current_price: goods.sku_res.current_price,
                            community_name: item.community_res.community_name,
                            community_id: item.community_res.community_id,
                            mobile: item.mobile,
                            tel_number: order.user_res.mobile,
                            nick_name: order.user_res.nick_name,
                            refund_status: goods.order_goods_refund_status ? this.props.refund_status_list[goods.order_goods_refund_status].status : null,
                            address: item.province + item.district + item.address,
                            row_span: 0
                        }
                        if (index === 0) {
                            ship_index = order_history_list.length;
                            data.order_goods_rowspan = order.order_goods_res_list.length
                            data.order_goods_list = goods_list
                        } else {
                            data.order_goods_rowspan = 0
                        }
                        let sku = ""
                        goods.sku_res.attribute_value_res_list.forEach(item => {
                            sku += item.attribute_value
                        })
                        data.sku = sku
                        order_history_list.push(data)
                        goods_list.push(data)


                    })
                })
                order_history_list[ship_index].order_goods_list = goods_list
                order_history_list[position].row_span = row_span ? row_span : 1
            })
            this.setState({
                order_history_list: order_history_list,
                total: res.result.total ? res.result.total : 0
            })
        }
    }

    async queryGroupShipmentOrderDueList(data) {
        let res = await queryGroupShipmentOrderDueList(data);
        if (res.success) {
            let order_history_list = []
            res.result.community_res_list.forEach(item => {
                let position = order_history_list.length;
                let row_span = 0;
                item.order_res_list.forEach(order => {
                    row_span += order.order_goods_res_list.length
                    order.order_goods_res_list.forEach((goods, index) => {
                        let data = {
                            logistics: item.logistics,
                            order_sn: order.order_sn,
                            order_id: order.order_id,
                            pay_time: order.pay_time,
                            shipping_time: item.shipping_time,
                            order_status: order.order_status,
                            shipment_type: order.shipment_type,
                            goods_name: goods.goods_res.goods_name,
                            quantity: goods.quantity,
                            community_name: item.community_name,
                            current_price: goods.sku_res.current_price,
                            receiver_name: item.address_res.receiver_name,
                            mobile: item.address_res.tel_number,
                            order_goods_refund_status: goods.order_goods_refund_status,
                            refund_desc: goods.order_goods_refund_status ? this.props.refund_status_list[goods.order_goods_refund_status].value : null,
                            refund_status: goods.order_goods_refund_status ? this.props.refund_status_list[goods.order_goods_refund_status].status : null,
                            address: item.address_res.province_name + item.address_res.city_name + item.address_res.county_name + item.address_res.detail_info,
                            row_span: 0
                        }
                        let goods_list = []
                        order.order_goods_res_list.forEach(goods => {
                            goods.sku_res.attribute_value_res_list.forEach(item => {
                                sku += item.attribute_value
                            })
                            let data = goods
                            data.sku = sku
                            data.order_sn = order.order_sn
                            goods_list.push(data)
                        })
                        if (index === 0) {
                            data.order_goods_rowspan = order.order_goods_res_list.length
                            data.order_goods_list = goods_list
                        } else {
                            data.order_goods_rowspan = 0
                        }
                        let sku = ""
                        goods.sku_res.attribute_value_res_list.forEach(item => {
                            sku += item.attribute_value
                        })
                        data.sku = sku
                        order_history_list.push(data)
                    })
                })
                order_history_list[position].row_span = row_span ? row_span : 1
            })
            this.setState({
                order_history_list: order_history_list,
                total: res.result.total ? res.result.total : 0
            })
        }
    }

    async createShipment(data) {
        let res = await createShipment(data)
        if (res.success) {
            message.success("成功生成配送单")
            this.filterOrder()
        }
    }
    createShip = () => {
        let order_id_list = []
        let community_id = this.state.selectedRows[0].community_id
        this.state.selectedRows.forEach(item => {
            order_id_list.push(item.order_id)
            if (+community_id !== +item.community_id) {
                return message.error("不同社区的商品不能生成同一个配送单")
            }
            if (item.refund_status === 1) {
                return message.error("您选择的商品中包含售后成功的商品")
            }
        }
        )
        let data = {
            order_id_list: order_id_list
        }
        this.createShipment(data)
    }
    printShip = () => {

        const res = new Map();
        let shipment_id_list = this.state.selectedRows.map(item =>
            item.shipment_id
        )
        let data = {
            shipment_id_list: shipment_id_list.filter((a) => !res.has(a) && res.set(a, 1))
        }
        this.printShipment(data)

    }
    async printShipment(data) {
        let res = await printShipment(data)
        if (res.success) {
            message.success("打印成功")
            this.setState({
                print_visible: false
            })
            this.filterOrder()
        }


    }
    confirmShipment = (info) => {
        info.shipment_id = this.state.order.shipment_id
        this.shippingOutGroupOrder(info)

    }

    confirmPrint = () => {
        let data = {
            shipment_id_list: [this.state.order.shipment_id]
        }
        this.printShipment(data)

    }
    async shippingOutGroupOrder(data) {
        let res = await shippingOutGroupOrder(data)
        if (res.success) {
            if (!res.result.goods_limit_notice_list.length) {
                message.success("发货成功")
            }
            this.setState({
                visible: false,
                goodsRemindVisible: res.result.goods_limit_notice_list.length ? true : false,
                goodsRemindList: res.result.goods_limit_notice_list.length ? res.result.goods_limit_notice_list : []
            })
            this.filterOrder()
        }
    }

    showModal = (row) => {
        this.setState({
            visible: true,
            order: row
        })
    }
    showPrintModal = (row) => {
        this.setState({
            print_visible: true,
            order: row
        })
    }
    hideModal = () => {
        this.setState({
            visible: false,
            print_visible: false
        })
    }

    filterOrder = () => {
        if (!cookie.load("shop_id") && cookie.load("is_admin") === "1") {
            return
        }
        let form = this.props.form.getFieldsValue();
        let data = {
            shop_id: cookie.load("shop_id"),
            page_size: this.state.page_size,
            page_no: this.state.page,
            status: this.props.status === -1 ? null : this.props.status
        }
        data.shipment_type = this.state.order_shipment_type
        if (this.state.start_order_time) {
            if (this.props.status === -1) {
                data.start_pay_time = this.state.start_order_time
            } else {
                data.start_created_time = this.state.start_order_time
            }
        }
        if (this.state.end_order_time) {
            if (this.props.status === -1) {
                data.end_pay_time = this.state.end_order_time
            } else {
                data.end_created_time = this.state.end_order_time
            }
        }
        if (form.community_name) {
            data.community_name = form.community_name
        }
        if (form.leader_name) {
            data.leader_name = form.leader_name
        }
        if (form.mobile) {
            var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
            if (!myreg.test(form.mobile)) {
                return message.error("手机号码格式错误")
            } else {
                data.tel_number = form.mobile
            }
        }
        if (form.shipment_sn) {
            data.shipment_sn = form.shipment_sn
        }
        if (this.props.status === -1) {
            this.queryGroupShipmentOrderDueList(data)
        } else {
            this.queryGroupShipmentOrderList(data)
        }
        window.scrollTo(0, 0);
    }

    clearFilter = () => {
        this.props.form.resetFields();
    };

    onShowPageChange = (page) => {
        this.setState({
            page: page
        }, () => {
            this.filterOrder()
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.filterOrder()
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys,
            selectedRows
        });
    }
    onShowTimeChange = (date, dateStr) => {
        this.setState({
            start_order_time: dateStr[0],
            end_order_time: dateStr[1],
        })
    }
    handleGoodsRemindOk = (e) => {
        this.setState({
            goodsRemindVisible: false

        })
        this.context.router.history.push("/balanceManage")
    }
    handleGoodsRemindFalse = (e) => {
        this.setState({
            goodsRemindVisible: false

        })
    }
    UNSAFE_componentWillReceiveProps() {
        this.filterOrder()
    }
    componentDidMount() {
        this.filterOrder()
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true,
            onSelection: this.onSelection,
        };
        return (
            <div className="order-history">
                <div className="filter-content">
                    <Form layout="inline">
                        <Form.Item label="配送单号"
                            style={{ display: this.props.status === "-1" ? "none" : true }}>
                            {getFieldDecorator('shipment_sn')(
                                <Input className="form-input" placeholder="输入配送单号" />
                            )}
                        </Form.Item>
                        <Form.Item label={this.props.status === "-1" ? "付款时间" : "配送单生成时间"}>
                            {getFieldDecorator('date')(
                                <DatePicker.RangePicker className="form-input-long" onChange={this.onShowTimeChange} showTime />
                            )}
                        </Form.Item>
                        <Form.Item label="社群名称" style={{ display: this.props.status !== "-1" ? "none" : true }}>
                            {getFieldDecorator('community_name')(
                                <Input className="form-input" placeholder="输入社群名称" />
                            )}
                        </Form.Item>
                        <Form.Item label="团长姓名" style={{ display: this.props.status !== "-1" ? "none" : true }}>
                            {getFieldDecorator('leader_name')(
                                <Input className="form-input" placeholder="输入团长姓名" />
                            )}
                        </Form.Item>
                        <Form.Item label="手机号 " style={{ display: this.props.status !== "-1" ? "none" : true }}>
                            {getFieldDecorator('mobile')(
                                <Input className="form-input" placeholder="输入收货手机号码" />
                            )}
                        </Form.Item>
                    </Form>
                    <div className="action">
                        <Button shape="round" type="primary" onClick={this.onShowPageChange.bind(this, 1)}>筛选</Button>
                        <Button style={{ display: +this.props.status !== 1 ? "none" : true }} shape="round" type="primary" onClick={this.gotoDriver}>添加司机</Button>
                        <span className="text-note" onClick={this.clearFilter}>清空筛选条件</span>
                    </div>
                </div>
                <div className="action" style={{ display: +this.props.status !== -1 ? "none" : true }}>
                    <Button
                        disabled={this.state.selectedRows.length ? false : true}
                        shape="round"
                        onClick={+this.props.status === -1 ? this.createShip : +this.props.status === 0 ? this.printShip : null}
                    >
                        {+this.props.status === -1 ? "生成配送单" : +this.props.status === 0 ? "打印配送单" : "发货"}
                    </Button>
                    <span className="choose-num">当前已选择{this.state.selectedRows.length}项</span>
                </div>
                <Table rowKey={(record, index) => index}

                    columns={+this.props.status === -1 ? this.state.shipment_columns : +this.props.status !== -1 ? this.state.ship_columns : this.state.columns}
                    dataSource={this.state.order_history_list}
                    pagination={false} bordered={true}
                    rowSelection={+this.props.status !== -1 ? null : rowSelection}
                />
                <Pagination
                    className="pagenation"
                    onChange={this.onShowPageChange}
                    onShowSizeChange={this.onShowSizeChange}
                    defaultCurrent={this.state.page}
                    total={this.state.total}
                    showTotal={(total) => `共 ${total} 条`}
                    pageSizeOptions={["10", "30", "50", "100"]}
                    showSizeChanger
                />
                <GroupShipmentModal visible={this.state.visible} handleSelfOk={this.confirmShipment} handleSelfCancel={this.hideModal} order={this.state.order} />
                <PrintModal visible={this.state.print_visible} handleSelfOk={this.confirmPrint} handleSelfCancel={this.hideModal} order={this.state.order} />
                <GoodsRemind
                    visible={this.state.goodsRemindVisible}
                    data={this.state.goodsRemindList}
                    title="以下商品缺货请即时补充库存"
                    handleOk={this.handleGoodsRemindOk}
                    handleCancel={this.handleGoodsRemindFalse}
                >
                </GoodsRemind>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
        order_status_list: state.orderStatusList,
        refund_status_list: state.refundStatusList
    }
}

const GroupOrderShipmentForm = Form.create({ name: 'group_order_shipment_form' })(GroupOrderShipment);

export default connect(mapStateToProps)(GroupOrderShipmentForm);