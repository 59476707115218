import React from 'react'
import '@css/campaignShow.scss'
import { formatDate } from "@common/common";
class CampaignCoupon extends React.Component {
    constructor() {
        super()
        this.state = {
            url: [
                'http://udh.oss-cn-hangzhou.aliyuncs.com/4ac14e7d-eb7b-4016-99ad-8e031b4f6026',
                'http://udh.oss-cn-hangzhou.aliyuncs.com/f5554772-c6e5-466a-be34-131c3303b9e9'
            ]
        }
    }
    getClass = () => {
        switch (this.props.coupon_list.length) {
            case 1: return '';
            case 2: return 'coupon-list';
            default: return 'coupon-more-list'
        }
    }

    render() {
        return (
            <div className="campain-coupon">
                <div className={this.getClass()}>
                    {
                        this.props.coupon_list.map((item, index) => (
                            <div style={{ backgroundImage: `url(${this.props.url || this.state.url[this.props.coupon_list.length === 1 ? 0 : 1]})` }} className={this.props.coupon_list.length > 1 ? "show-more-coupon" : "show-coupon"} key={index}>
                                <div className="coupon-money">
                                    <div className="coupon-price">¥{item.coupon_res.discount_amount / 100}</div>
                                    <div className="coupon-discount">满{item.coupon_res.use_min_amount / 100}元可用</div>
                                </div>
                                {
                                    this.props.coupon_list.length === 1 ? (
                                        <div className="couon-info">
                                            <div className="coupon-name">满减券</div>
                                            <div className="coupon-use">{item.coupon_res.coupon_name}</div>
                                            <div className="coupon-date">{formatDate(new Date(item.coupon_res.start_time).getTime(), 'Y-M-D')} 至 {formatDate(new Date(item.coupon_res.end_time).getTime(), 'Y-M-D')}</div>
                                        </div>
                                    ) : null
                                }
                                <div className="coupon-button">
                                    立即<br />领取
                                   </div>
                            </div>
                        )
                        )
                    }
                </div>
            </div>
        )
    }
}
export default CampaignCoupon
