import React, { Component } from 'react';
import { Button, DatePicker, Form, Input, Table, Pagination } from 'antd';
import './searchAndTable.scss';
import moment from "moment";
import { getData } from '@service/getData';
import cookie from 'react-cookies';

class searchAndTable extends Component {
    constructor() {
        super();
        this.state = {
            columns: [
                {
                    title: '订单号',
                    dataIndex: 'order_sn',
                },
                {
                    title: '发票类型',
                    dataIndex: 'invoice_type',
                    render: (text) => (
                        <span>{text === 0 ? '普通发票' : null}</span>
                    )
                },
                {
                    title: '发票金额',
                    dataIndex: 'amount',
                    render: (text) => (
                        <span>{(text / 100).toFixed(2)}</span>
                    )
                },
                {
                    title: '开票方式',
                    dataIndex: 'content_type',
                    render: (text) => (
                        <span>{text === 0 ? '商品名称' : null}</span>
                    )
                },
                {
                    title: '发票抬头',
                    dataIndex: 'title',
                },
                {
                    title: '邮件地址',
                    dataIndex: 'email',
                },
                {
                    title: '申请时间',
                    dataIndex: 'apply_time',
                },
            ],
            data: [],
            // 分页
            page: 1,
            page_size: 10,
            total: 0,
            start_time: null,
            end_time: null,
            orderSn: null,
        }
    }

    componentDidMount() {
        if (this.props.type === 1) {
            this.search(1);
        }
        if (this.props.type === 2) {
            const temp = [...this.state.columns];
            temp[temp.length - 1].title = '开票时间';
            // const colum = {
            //     title: '发票号',
            //     dataIndex: 'h',
            // }
            const active = {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => (
                    <a target="_blank" className="text-note" href={record.invoice_url}>下载发票</a>
                )
            }
            // temp.unshift(colum);
            temp.push(active);
            this.setState({
                columns: temp,
            }, () => {
                this.search(2);
            });
        }
    }

    async search(type) {
        let data = {
            miniAppId: cookie.load('mini_app_id'),
            orderSn: this.state.orderSn,
            startTime: this.state.start_time,
            endTime: this.state.end_time,
            pageSize: this.state.page_size,
            pageNo: this.state.page,
        };
        let url;
        let res;
        if (type === 1) {
            url = '/invoice/applyList';
        }
        if (type === 2) {
            url = '/invoice/completeList';
        }
        res = await getData(url, data);
        if (res && res.success && res.result && res.result.list) {
            this.setState({
                data: res.result.list,
                page: res.result.page_no,
                page_size: res.result.page_size,
                total: res.result.total,
            });
        }
    }

    onShowPageChange(page) {
        this.setState({
            page,
        }, () => {
            this.search(this.props.type);
        });
    }

    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.search(this.props.type);
        })
    }
    // 有效时间
    onShowTimeChange = (date, dateStr) => {
        this.setState({
            start_time: `${dateStr[0]} 00:00:01`,
            end_time: `${dateStr[1]} 23:59:59`,
        });
    };

    numChange(e) {
        this.setState({
            orderSn: e.target.value,
        });
    }

    clear() {
        this.setState({
            total: 0,
            page: 1,
            page_size: 10,
            end_time: null,
            start_time: null,
            orderSn: null,
        });
    }

    render() {
        const type = this.props.type;
        return (
            <div className="searchAndTable">
                <Form>
                    <Form.Item
                        label="订单号"
                    >
                        <Input value={this.state.orderSn} onChange={this.numChange.bind(this)} placeholder="输入订单号" />
                    </Form.Item>
                    <Form.Item
                        label={type === 1 ? '申请时间' : '开票时间'}
                    >
                        <DatePicker.RangePicker
                            onChange={this.onShowTimeChange.bind(this)}
                            value={
                                this.state.start_time
                                    ? [moment(this.state.start_time, "YYYY-MM-DD"), moment(this.state.end_time, "YYYY-MM-DD")]
                                    : null
                            }
                            format="YYYY-MM-DD"
                        ></DatePicker.RangePicker>
                    </Form.Item>
                </Form>
                <Button type="primary" shape="round" onClick={this.search.bind(this, this.props.type)}>筛选</Button>
                <span className="clear" onClick={this.clear.bind(this)}>清空筛选条件</span>

                <Table
                    rowKey={(record, index) => index}
                    columns={this.state.columns}
                    dataSource={this.state.data}
                    bordered
                    pagination={false}
                />

                <Pagination
                    className="pagenation"
                    onChange={this.onShowPageChange.bind(this)}
                    onShowSizeChange={this.onShowSizeChange.bind(this)}
                    defaultCurrent={this.state.page}
                    total={this.state.total}
                    showTotal={(total) => `共 ${total} 条`}
                    pageSizeOptions={["10", "30", "50", "100"]}
                    showSizeChanger
                />
            </div>
        );
    }
}

export default searchAndTable;