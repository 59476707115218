import React from "react";
import NavBar from "@/view/component/common/NavBar.js";
import { Form, Input, Table, Button, Pagination, message, TreeSelect, Modal, Upload } from "antd";
import { connect } from "react-redux";
import "@css/goodsListPageDetail.scss";
import { getQueryString } from "@common/common";
import PropTypes from "prop-types";
import {
  queryTagDetail,
  exchangeTagGoodsSortNum,
  deleteTagGoods,
  queryGoodsList,
  getCatList,
  createOrUpdateTagGoods,
  tagGoodsBatchXlsTask
} from "@service/getData";
import cookie from "react-cookies";
import config from "@config/config";
class GoodsListPageDetail extends React.Component {
  constructor() {
    super();
    GoodsListPageDetail.contextTypes = {
      router: PropTypes.object.isRequired
    };
    this.state = {
      requestUrl: config.baseUrl + "/file/download?url=",
      columns: [
        {
          title: "商品id",
          dataIndex: "goods_id",
          render: (value, record) => {
            return record.goods_res.goods_id;
          }
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          render: (value, record) => {
            return record.goods_res.goods_name;
          }
        },
        {
          title: "商品图片",
          dataIndex: "thumbnail_url",
          render: (text, record) => (
            <div className="relative wd100">
              <img alt="" className="object-cover" src={JSON.parse(record.goods_res.gallery_urls)[0]} width="100" height="100" />
              {record.goods_res.onsale !== 1 ? <div className="unsale">已下架</div> : null}
            </div>
          )
        },
        {
          title: "价格",
          dataIndex: "current_price",
          render: (value, record) => {
            return record.goods_res.current_price / 100;
          }
        },
        {
          title: "排序",
          dataIndex: "tag_goods_id",
          render: (text, record, index) => (
            <div>
              {index !== 0 ? (
                <i className="iconfont icon-shang-copy-copy" onClick={this.exchangeTagGoodsSortNum.bind(this, text, index - 1)} />
              ) : null}
              {index !== this.state.tag_goods_list.length - 1 ? (
                <i className="iconfont icon-xia" onClick={this.exchangeTagGoodsSortNum.bind(this, text, index + 1)} />
              ) : null}
              <span className="text-note" onClick={this.deleteTagGoods.bind(this, text)}>
                删除
              </span>
            </div>
          )
        }
      ],
      goods_columns: [
        {
          title: "商品id",
          dataIndex: "goods_id"
        },
        {
          title: "商品图片",
          dataIndex: "thumbnail_url",
          render: (text, record) => (
            <div className="relative wd100">
              <img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="100" height="100" />
              {record.onsale !== 1 ? <div className="unsale">已下架</div> : null}
            </div>
          )
        },
        {
          title: "商品名称",
          dataIndex: "goods_name"
        },
        {
          title: "价格",
          dataIndex: "current_price",
          render: text => <div>￥{text / 100}</div>
        }
      ],
      goods_list: [],
      tag_id: null,
      tag_name: null,
      status: null,
      page: 1,
      page_size: 10,
      total: 0,
      tag_goods_list: [],
      cat_list: [],
      selectedGoodsRowKeys: [],
      selectedGoodsRows: [],
      batch_visible: false,
      action: {
        action: config.baseUrl + "/ossFile/upload",
        withCredentials: true
      },
      fileList: []
    };
  }
  queryTagDetail = async () => {
    if (this.state.status === "1") {
      return this.queryGoodsList();
    }
    let form = this.props.form.getFieldsValue();
    let data = {
      tag_id: this.state.tag_id,
      like_condition: form.like_condition,
      page_no: this.state.page,
      page_size: this.state.page_size
    };
    let res = await queryTagDetail(data);
    if (res.success) {
      this.setState({
        tag_goods_list: res.result.tag_goods_res_list,
        page: res.result.page_no,
        total: res.result.total
      });
    }
  };
  exchangeTagGoodsSortNum = async (from_tag_goods_id, index) => {
    let to_tag_goods_id = this.state.tag_goods_list[index].tag_goods_id;
    let data = {
      from_tag_goods_id,
      to_tag_goods_id
    };
    let res = await exchangeTagGoodsSortNum(data);
    if (res.success) {
      message.success("修改位置成功");
      this.queryTagDetail();
    }
  };
  deleteTagGoods = async tag_goods_id => {
    let data = {
      tag_goods_id
    };
    let res = await deleteTagGoods(data);
    if (res.success) {
      message.success("删除商品成功");
      this.queryTagDetail();
    }
  };
  async getCatList() {
    let data = {
      mini_app_id: cookie.load("mini_app_id")
    };
    let res = await getCatList(data);
    if (res.success) {
      this.setState({
        cat_list: res.result.cat_list
      });
    }
  }
  queryGoodsList = async () => {
    let form = this.props.form.getFieldsValue();
    let data = {
      page_no: this.state.page,
      page_size: this.state.page_size,
      shop_id: cookie.load("shop_id"),
      onsale: 1,
      cat_id: form.cat_id,
      goods_name_and_id: form.like_condition
    };
    let res = await queryGoodsList(data);
    if (res.success) {
      this.setState({
        goods_list: res.result.goods_res_list,
        total: res.result.total
      });
    }
  };
  createOrUpdateTagGoods = async () => {
    let tag_goods_req_list = this.state.selectedGoodsRowKeys.map(item => {
      return {
        tag_id: this.state.tag_id,
        goods_id: item,
        status: 1
      };
    });
    let data = {
      tag_goods_req_list
    };
    let res = await createOrUpdateTagGoods(data);
    if (res.success) {
      message.success("成功添加商品");
      this.setState({
        selectedGoodsRowKeys: [],
        selectedGoodsRows: []
      });
      this.goGoodsDetail(this.state.tag_id, this.tag_name, 0);
    }
  };
  tagGoodsBatchXlsTask = async data => {
    let res = await tagGoodsBatchXlsTask(data);
    if (res.success) {
      message.success("批量导入商品成功");
      this.setState({
        batch_visible: false
      });
      this.goGoodsDetail(this.state.tag_id, this.tag_name, 0);
    }
  };
  clearFilter = () => {
    this.props.form.resetFields();
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        page_size: pageSize
      },
      () => {
        if (this.state.status === "1") {
          this.queryGoodsList();
        } else if (this.state.status === "0") {
          this.queryTagDetail();
        }
      }
    );
  };
  onShowPageChange = page => {
    this.setState(
      {
        page: page
      },
      () => {
        if (this.state.status === "1") {
          this.queryGoodsList();
        } else if (this.state.status === "0") {
          this.queryTagDetail();
        }
      }
    );
  };
  onSelectChange = (selectedGoodsRowKeys, selectedGoodsRows) => {
    this.setState({
      selectedGoodsRowKeys,
      selectedGoodsRows
    });
  };
  goGoodsDetail = (tag_id, tag_name, status) => {
    let url = `/goodsListPageDetail?tag_id=${tag_id}&tag_name=${tag_name}&status=${status}`;
    this.setState(
      {
        page: 1,
        page_size: 10,
        selectedGoodsRowKeys: [],
        selectedGoodsRows: [],
        status
      },
      () => {
        this.queryTagDetail();
      }
    );
    this.context.router.history.push(url);
  };
  cancelAdd = () => {
    this.setState({
      selectedGoodsRowKeys: [],
      selectedGoodsRows: []
    });
  };
  handleBatchOk = e => {
    if (this.state.fileList.length < 1) {
      return message.success("请上传文件之后再操作");
    }
    let data = {
      tag_id: this.state.tag_id,
      oss_file_url: this.state.fileList[0].response.result.oss_file_url
    };
    this.tagGoodsBatchXlsTask(data);
  };

  handleBatchCancel = e => {
    this.setState({
      batch_visible: false,
      fileList: []
    });
  };
  showBatch = () => {
    this.setState({
      batch_visible: true
    });
  };
  onChange = info => {
    this.setState({
      fileList: info.fileList
    });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    let status = getQueryString("status");
    if (status !== this.state.status) {
      this.setState(
        {
          status
        },
        () => {
          this.queryTagDetail();
        }
      );
    }
  }
  componentDidMount() {
    this.getCatList();
    let tag_id = getQueryString("tag_id");
    let tag_name = getQueryString("tag_name");
    let status = getQueryString("status");
    this.setState(
      {
        tag_id,
        tag_name,
        status
      },
      () => {
        this.queryTagDetail();
      }
    );
  }
  render() {
    let { menu_code } = this.props;
    const { getFieldDecorator } = this.props.form;
    const rowSelection = {
      selectedRowKeys: this.state.selectedGoodsRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true
    };
    return (
      <div className="page goods-list-page-detail">
        <NavBar
          icon="icon-shangpinguanli"
          father={menu_code.goods_list_page}
          child={`${this.state.status === "1" ? "添加" : "查看"}"${this.state.tag_name}"包含的商品`}
          router="goodsListPage"
        />
        <div className="content">
          <div className="filter-content">
            <Form layout="inline">
              <Form.Item label="商品名称/商品id：">
                {getFieldDecorator("like_condition")(<Input className="form-input" placeholder="输入商品名称/商品id" />)}
              </Form.Item>
              {this.state.status === "1" ? (
                <Form.Item label="商品分类">
                  {getFieldDecorator("cat_id")(
                    <TreeSelect
                      showSearch
                      style={{ width: 400 }}
                      dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                      allowClear
                      treeDefaultExpandAll>
                      {this.state.cat_list.map(item => (
                        <TreeSelect.TreeNode
                          disabled={item.cat_list.length > 0}
                          value={item.cat_id}
                          title={item.cat_name}
                          key={item.cat_id}>
                          {item.cat_list.map(cat => (
                            <TreeSelect.TreeNode value={cat.cat_id} title={cat.cat_name} key={cat.cat_id} />
                          ))}
                        </TreeSelect.TreeNode>
                      ))}
                    </TreeSelect>
                  )}
                </Form.Item>
              ) : null}
            </Form>
            <div className="filter-action">
              <Button shape="round" type="primary" onClick={this.queryTagDetail}>
                筛选
              </Button>
              <span className="text-note" onClick={this.clearFilter}>
                清空筛选条件
              </span>
            </div>
          </div>
          {this.state.status === "0" ? (
            <div className="action">
              <Button shape="round" type="primary" onClick={this.goGoodsDetail.bind(this, this.state.tag_id, this.state.tag_id, 1)}>
                添加商品
              </Button>
            </div>
          ) : (
              <div className="action">
                <Button shape="round" type="primary" onClick={this.showBatch}>
                  批量导入
              </Button>
                <span className="choose-num">当前已选择{this.state.selectedGoodsRowKeys.length}项</span>
              </div>
            )}
          <Table
            rowKey={this.state.status === "0" ? (record, index) => index : record => record.goods_id}
            rowSelection={this.state.status === "0" ? null : rowSelection}
            dataSource={this.state.status === "0" ? this.state.tag_goods_list : this.state.goods_list}
            columns={this.state.status === "0" ? this.state.columns : this.state.goods_columns}
            pagination={false}
          />
          <Pagination
            className="pagenation"
            onChange={this.onShowPageChange}
            onShowSizeChange={this.onShowSizeChange}
            defaultCurrent={this.state.page}
            total={this.state.total}
            showTotal={total => `共 ${total} 条`}
            pageSizeOptions={["10", "30", "50", "100"]}
            showSizeChanger
          />
        </div>
        {this.state.status === "1" ? (
          <div className="goods-add-button-box">
            <Button shape="round" type="primary" onClick={this.createOrUpdateTagGoods}>
              保存
            </Button>
            <Button shape="round" onClick={this.cancelAdd}>
              取消
            </Button>
          </div>
        ) : null}
        <Modal
          title="批量导入商品"
          visible={this.state.batch_visible}
          onOk={this.handleBatchOk}
          onCancel={this.handleBatchCancel}
          okText="批量导入"
          okButtonProps={{ shape: "round" }}
          cancelButtonProps={{ shape: "round" }}>
          <div className="batch-modal-content">
            <Upload
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              {...this.state.action}
              onChange={this.onChange}
              fileList={this.state.fileList}>
              <Button disabled={this.state.fileList.length > 0 ? true : false} shape="round">
                上传
              </Button>
            </Upload>
            <div className="text-note">
              <a href={this.state.requestUrl + "http://udh.oss-cn-hangzhou.aliyuncs.com/9584fcc6-52a5-4f99-ae37-8c49d03dcb33"}>
                下载批量导入模版
              </a>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};
const GoodsListPageailDetForm = Form.create({ name: "goods_list__page_detail_form" })(GoodsListPageDetail);

export default connect(mapStateToProps)(GoodsListPageailDetForm);
