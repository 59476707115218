import React, { Component } from 'react';
import PropTypes from "prop-types";
import '../scss/openingGuide.scss';
import { Button, Modal, message } from 'antd';
import { getPreAuthUrl, getData, getExQrCode } from '@service/getData';
import config from "@config/config.js";
import cookie from 'react-cookies';
import { getQueryString } from "@common/common";

class OpeningGuide extends Component {
    constructor(props) {
        super(props);
        OpeningGuide.contextTypes = {
            router: PropTypes.object.isRequired,
        };
        this.state = {
            // 是直接授权还是代理注册
            type: null,
            // 授权状态
            authority_status: 5,
            // 支付状态
            pay_apply_status: 1,
            // 授权/发布错误信息
            err_message: '',
            // 商户号绑定错误信息
            pay_apply_err_msg: '',
            // 测试码
            testQrCode: '',
            // 正式码
            qrCode: '',
            // 已有小程序的情况下，直接授权，授权成功与否的提示弹窗
            visible: false,
            // 是否个人
            isPersonal: true,
            // 查看申请状态的二维码
            pay_status_qr_code: '',
            app_name: '',
            mch_apply_id: null,
            // 授权失败原因 区分码 1：未知原因 2L资料有误 2:超过24小时未验证
            app_apply_err_code: null,
            // 绑定支付失败原因 区分码 1:未知原因 2：资料有误 2L超过7天未验证
            mch_apply_err_code: null,
        };
    }
    componentDidMount() {
        const shopId = cookie.load('shop_id');
        const app_apply_id = cookie.load('app_apply_id');
        // 没有shop_id说明当前没有店铺
        if (shopId === 'null') {
            // 有app_apply_id说明提交了注册资料，但是法人还没有验证
            // 因为没有shop_id不能调用后端接口，这里手动设置状态
            if (app_apply_id) {
                this.setState({
                    authority_status: 0,
                });
            }
            // 设置状态后，结束。
            return;
        }
        this.getData();
        // 二维码
        this.getExQrCode();
        let auth_code = getQueryString("auth_code");
        if (auth_code) {
            this.setState({
                visible: true,
            });
        }
    }
    componentWillUnmount() {
        this.setState = () => false;
    }
    async getData() {
        const data = {
            shopId: cookie.load('shop_id') === 'null' ? null : cookie.load('shop_id'),
            app_apply_id: cookie.load('app_apply_id') * 1,
        };
        let res = await getData('/dashBoard/queryShopMina', data);
        if (res.success) {
            this.setStatus(res.result);
        }
    }
    async getExQrCode() {
        const miniAppId = cookie.load('mini_app_id');
        const shopId = cookie.load('shop_id');
        if (miniAppId) {
            let res = await getExQrCode(miniAppId);
            this.setState({
                testQrCode: res,
            });
        }
        if (shopId) {
            this.setState({
                qrCode: `${config.baseUrl}/qrCode/detail?shop_id=${shopId}&page=pages/home/index`
            });
        }
    }
    setStatus(result) {
        const {
            authority_status,
            type,
            err_message,
            pay_apply_err_msg,
            pay_apply_status,
            pay_status_qr_code,
            mini_type,
            app_name,
            mch_apply_id,
            app_apply_err_code,
        } = result;
        this.setState({
            authority_status,
            type,
            err_message,
            pay_apply_err_msg,
            pay_apply_status,
            pay_status_qr_code,
            isPersonal: mini_type === 0,
            app_name,
            mch_apply_id,
            app_apply_err_code,
        });
    }
    async getPreAuthUrl() {
        let res = await getPreAuthUrl();
        if (res.success) {
            let url =
                "https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid=" +
                config.component_appid +
                "&pre_auth_code=preAuthCode&redirect_uri=" +
                config.frontUrl +
                "&auth_type=2";
            url = url.replace("preAuthCode", res.result.pre_auth_code);
            window.open(url);
        }
    }
    // 跳转代理注册
    startToCreate() {
        this.context.router.history.push('/appletManage', { step: 2 });
    }
    // 跳转支付设置
    merchantAdd() {
        const status = this.state.authority_status;
        const mchStatus = this.state.pay_apply_status;
        if (status === 2 || status === 3 || status === 4) {
            // 未提交，点击新增
            if (mchStatus === 1) {
                this.context.router.history.push("/merchantAdd");
            }
            // 审核中，点击无效
            else if (mchStatus === 2) {
                message.warn('审核中不能修改哦');
            }
            // 审核失败，点击去编辑修改
            else if (mchStatus === 3) {
                this.context.router.history.push(`/merchantAdd?mch_apply_id=${this.state.mch_apply_id}`);
            }
        } else {
            message.warn('请先完成授权');
        }
    }
    // 跳转修改小程序注册信息
    goModify() {
        // 只有授权失败了，让修改
        if (this.state.authority_status !== 1) {
            message.warn('不能修改了哦');
            return;
        }
        this.context.router.history.push('/appletManage', { step: 2, isEdit: true });
    }
    // 授权状态
    statusVal() {
        const authority_status = this.state.authority_status;
        const detailNode = () => {
            const node = (detail, { detailColor, tip } = { detailColor: '#333333', tip: '查看帮助' }) => {
                return (
                    <p className="detail" style={{ color: detailColor }}>{detail}<span className="doSomthing" onClick={this.tipClick.bind(this)}>{tip}</span></p>
                )
            }
            // 授权中
            if (authority_status === 0) {
                return node('（资料审核中，待法人验证身份）', { detailColor: '#1790FF', tip: '' });
            }
            // 授权失败
            if (authority_status === 1) {
                const authErrCode = this.state.app_apply_err_code;
                // 未知原因
                if (authErrCode === 1) {
                    return node('其他原因，请咨询客服', { tip: '' })
                }
                // 资料出错
                else if (authErrCode === 2) {
                    return node('注册资料出错', { tip: '修改资料' });
                }
                // 超时未验证
                else if (authErrCode === 3) {
                    return node('审核超时，请重新提交资料', { tip: '修改资料' });
                }
            }
            // 4（发布失败） 会走到这里
            return (
                <>
                    <p className="detail" style={{ color: '#333333' }} dangerouslySetInnerHTML={{ __html: this.state.err_message }}></p>
                    <p style={{ cursor: 'pointer', color: '#1790FF' }} onClick={this.tipClick.bind(this)}>查看帮助</p>
                </>
            )

        }
        const status = (color, text) => {
            return (
                <>
                    <p className="status" style={{ color }}>{text}</p>
                    {(authority_status === 2 || authority_status === 3)
                        ?
                        <img className="appletImg" src={authority_status === 2 ? this.state.testQrCode : this.state.qrCode} alt="小程序码" />
                        :
                        detailNode()
                    }
                </>
            )
        }

        switch (authority_status) {
            case 0:
                return status('#1790FF', '授权中');
            case 1:
                return status('#E42317', '授权失败');
            case 2:
                return status('#1790FF', '发布中（微信7天内审核）');
            case 3:
                return status('#4AAE1B', '发布成功');
            case 4:
                return status('#E42317', '发布失败');
            default:
                return null;
        }
    }
    // 支付关联商户状态
    payStatusVal() {
        // 审核中
        if (this.state.pay_apply_status === 2) {
            return (
                <>
                    <p className="status" style={{ color: '#1790FF' }}>商户号资料审核中，请超级管理员扫码跟进申请单</p>
                    <img className="appletImg" src={this.state.pay_status_qr_code} alt="二维码" />
                </>
            )
        }
        // 审核失败
        if (this.state.pay_apply_status === 3) {
            const payNode = (code) => {
                if (code === 1) {
                    return (
                        <span>
                            未知原因，请联系客服
                        </span>
                    )
                }
                else if (code === 2) {
                    return (
                        <span>
                            资料有误
                            <span onClick={this.merchantAdd.bind(this)} className="doSomthing">修改资料</span>
                        </span>
                    )
                }
                else if (code === 3) {
                    return (
                        <span>
                            超过7天未验证，需重新提交申请
                            <span onClick={this.merchantAdd.bind(this)} className="doSomthing">修改资料</span>
                        </span>
                    )
                }
            }
            return (
                <>
                    <p className="status" style={{ color: '#E42317' }}>审核失败</p>
                    <p className="detail">{payNode(this.state.app_apply_err_code)}</p>
                </>
            )
        }
        return null;
    }
    // 弹窗内容
    modalVal() {
        // 个人小程序
        if (this.state.isPersonal) {
            return (
                <div className="openingGuidemodalWrap">
                    <i className="iconfont icon-duigou"></i>
                    <p className="successTitle personnal">恭喜！授权成功</p>
                    <p className="tip">未认证的微信小程序无法发布，请尽快前往<span style={{ color: '#1790ff' }}>微信小程序后台</span>完成微信小程序认证</p>
                    <Button type="primary" onClick={() => { this.setState({ visible: false }) }}>确定</Button>
                </div>
            )
        }
        return (
            <div className="openingGuidemodalWrap">
                <i className="iconfont icon-duigou"></i>
                <p className="successTitle">恭喜！授权成功</p>
                <Button type="primary" onClick={() => { this.setState({ visible: false }) }}>确定</Button>
            </div>
        )
    }
    tipClick() {
        const status = this.state.authority_status;
        if (status === 1) {
            this.goModify();
        } else {
            window.open('http://udh.oss-cn-hangzhou.aliyuncs.com/717f8453-c0f9-45bd-ab79-fcc9dc145972');
        }
    }
    isAuthorized() {
        // 直接授权 或者 代理授权且状态为发布成功
        // 这两种情况下
        // 1. 右侧按钮展示“已授权” 2. 步骤条颜色改变
        if (this.state.type === 0 || (this.state.type === 1 && this.state.authority_status === 3)) {
            return true;
        }
        return false;
    }
    render() {
        return (
            <div className="openingGuide">
                <div className="titleWrap">
                    <div className="asideBar"></div>
                    <span className="title">开店指引</span>
                </div>
                <div className="content">
                    <div className="authorizeStepWrap">
                        <div className="stepWrap">
                            <div className="stepTitleWrap">
                                <div className="index" style={this.isAuthorized() ? { backgroundColor: '#1790FF' } : null}>1</div>
                                <span className="stepTitle">{this.isAuthorized() ? '您已授权小程序' : '您尚未授权小程序'}</span>
                            </div>
                            {this.state.authority_status === 5 ? null :
                                <div className="statusWrap">
                                    <p className="stepSubtitle">{this.state.type === 0 ? '以下是自动过程，根据指引操作即可，不影响设置支付方式' : '以下是注册流程，请根据指引进行操作'}</p>
                                    {this.statusVal.bind(this)()}
                                </div>
                            }
                        </div>
                        {
                            this.isAuthorized()
                                ?
                                <div className="btnWrap">
                                    <div className="authorized">
                                        <i className="iconfont icon-duigou"></i>
                                        <span className="authorizedText">已授权</span>
                                    </div>
                                    <div className="appletName">{this.state.app_name}</div>
                                </div>
                                :
                                <div className="btnWrap">
                                    <div className="hadApp">
                                        <span className="btnText">已有小程序</span>
                                        <Button type="primary" onClick={this.getPreAuthUrl}>立即授权</Button>
                                    </div>
                                    <div className="noApp">
                                        <span className="btnText">无小程序</span>
                                        {this.state.authority_status === 5
                                            ?
                                            <Button type="primary" onClick={this.startToCreate.bind(this)}>代理注册</Button>
                                            :
                                            <span className="noAppTip" onClick={this.goModify.bind(this)}>注册资料已提交<span className="change">修改</span></span>
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="connectLine" style={this.isAuthorized() ? { backgroundColor: '#1790FF' } : null}></div>
                    <div className="payStepWrap">
                        <div className="stepWrap">
                            <div className="stepTitleWrap">
                                <div className="index" style={this.state.pay_apply_status === 4 ? { backgroundColor: '#1790FF' } : null}>2</div>
                                <span className="stepTitle">设置支付方式-微信支付</span>
                            </div>
                            <div className="statusWrap">
                                <p className="stepSubtitle">设置支付方式，需先申请商户号</p>
                                {this.payStatusVal.bind(this)()}
                            </div>
                        </div>
                        <div className="btnWrap">
                            {this.state.pay_apply_status === 4
                                ?
                                <div className="settled">
                                    <i className="iconfont icon-duigou"></i>
                                    <span className="settledText">已入驻</span>
                                </div>
                                :
                                <Button type="primary" onClick={this.merchantAdd.bind(this)}>{this.state.pay_apply_status === 3 ? '立即修改' : '立即设置'}</Button>
                            }
                        </div>
                    </div>
                </div>
                <Modal
                    visible={this.state.visible}
                    width={600}
                    closable={false}
                    footer={null}
                >
                    {this.modalVal.bind(this)()}
                </Modal>
            </div>
        );
    }
}

export default OpeningGuide;