import React from "react";
import NavBar from "@/view/component/common/NavBar.js";
import { Avatar, Tag, message, Spin, Checkbox } from "antd";
import "@css/clientManage.scss";
import { queryUserDashBoard, getUserGiftCardList } from "@service/getData";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getQueryString } from "@common/common";
import cookie from "react-cookies";
class Client extends React.Component {
  constructor() {
    super();
    Client.contextTypes = {
      router: PropTypes.object.isRequired
    };
    this.state = {
      user_res: {
        user_label_res_list: []
      },
      user_id: null,
      loading: true,
      user_asset: {},
      asset_list: [
        {
          name: "可用权益卡",
          value: "usable_gift_card_count"
        },
        {
          name: "现金",
          value: "cash_amount",
          need_divide: true
        },
        {
          name: "红包",
          value: "red_packet_amount",
          need_divide: true
        },
        {
          name: "可用积分",
          value: "point_amount"
        },
        {
          name: "可用优惠券",
          value: "usable_coupon_count"
        }
      ],
      userInfos: [
        {
          name: '性别',
          value: 'gender'
        },
        {
          name: '生日',
          value: 'birthday',
        },
        {
          name: '地区',
          value: 'area',
        },
        {
          name: 'unionid',
          value: 'union_id'
        },
      ],
      assetInformations: [
        {
          name: '累计消费金额（元）',
          value: 'total_pay_amount',
          need_divide: true,
        },
        {
          name: '累计消费订单数',
          value: 'total_order_amount',
        },
        {
          name: '客单价',
          value: 'kedanjia',
          need_divide: true,
        },
        {
          name: '最近下单时间',
          value: 'last_pay_time',
        },
        {
          name: '累计退款金额（元）',
          value: 'refund_total_amount',
          need_divide: true,
        },
        {
          name: '累计退款订单数',
          value: 'refund_order_amount',
        },
      ],
      user_gift_card_list: [],
      total_num: 0,
      right_list: [],
    };
  }
  queryUserDashBoard = async () => {
    let data = {
      user_id: this.props.location.state || this.state.user_id
      // user_id: 4112,
    };
    let res = await queryUserDashBoard(data);
    if (res.success) {
      let { user_res, right_list } = res.result;
      user_res.address_info = user_res.province_name + user_res.city_name + user_res.county_name + user_res.detail_info;
      this.setState(
        {
          user_res,
          user_asset: user_res.user_asset,
          loading: false,
          right_list,
        },
        () => {
          this.getUserCardList();
        }
      );
    } else {
      message.error(res.msg);
    }
  };
  gotoRefererDetail = () => {
    window.open(`${window.location.origin}/client?user_id=${this.state.user_res.referer_id}&type=orderManage`, "_blank");
  };
  componentDidMount() {
    let user_id = getQueryString("user_id");
    this.setState(
      {
        user_id
      },
      () => {
        this.queryUserDashBoard();
      }
    );
  }
  getUserCardList = async () => {
    let data = {
      mini_app_id: cookie.load("mini_app_id"),
      mobile: this.state.user_res.mobile
    };
    let res = await getUserGiftCardList(data);
    let list = res.result.user_gift_card_list;
    this.setState({
      user_gift_card_list: list
    });
  };
  onChangeCheckbox = value => {
    let total_num = this.state.user_gift_card_list.reduce((acc, item) => {
      let num = 0;
      if (value.indexOf(item.user_gift_card_id) !== -1) {
        num = +item.redeemable_sku_count;
      }
      return acc + num;
    }, 0);
    this.setState({
      total_num
    });
  };
  render() {
    let { menu_code } = this.props;
    return (
      <div className="client">
        <Spin spinning={this.state.loading}>
          <NavBar icon="icon-kehu1" father={menu_code.client_manage} child={menu_code.client} router="clientManage" />
          <div className="client-info">
            <div className="content base-info">
              <div className="shopDataTitle">
                <i className="titleSign" />
                基本信息
              </div>
              <div className="user-avatar">
                <Avatar size={80} src={this.state.user_res.avatar} />
                <div className="user-name">
                  <span className="name">{this.state.user_res.nick_name}</span>
                  {this.state.user_res.user_label_res_list.map(item =>
                    item.user_label_name === "购买客户" ? (
                      <Tag color="green" key={item.user_label_id}>
                        购买客户
                      </Tag>
                    ) : item.user_label_name === "直播客户" ? (
                      <Tag color="red" key={item.user_label_id}>
                        直播客户
                      </Tag>
                    ) : null
                  )}
                </div>
              </div>
              <div className="client-info-detail">
                <div className="client-info-address">
                  <div className="info-block">
                    <span className="label">手机号：</span>
                    <span className="value">{this.state.user_res.mobile}</span>
                  </div>
                  <div className="info-block">
                    <span className="label">成为用户：</span>
                    <span className="value">{this.state.user_res.created_at}</span>
                  </div>
                  <div className="info-block">
                    <span className="label">来源渠道：</span>
                    <span className="value">{this.state.user_res.channel_name}</span>
                  </div>
                  <div className="info-block">
                    <span className="label">最近浏览直播间id：</span>
                    <span className="value">{this.state.user_res.room_info_id}</span>
                  </div>
                  <div className="info-block client-address">
                    <span className="label">最近收货地址：</span>
                    <span className="value">{this.state.user_res.address_info}</span>
                  </div>
                  <div className="info-block">
                    <span className="label">分享人：</span>
                    <span className="text-note value" onClick={this.gotoRefererDetail}>
                      {this.state.user_res.referer_nick_name}
                    </span>
                  </div>
                  <div className="info-block client-address">
                    <span className="label">成长值：</span>
                    <span className="value">{this.state.user_res.growth_value}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="content right-content">
              <div className="asset-info">
                <div className="shopDataTitle">
                  <i className="titleSign" />
                  资产信息
                </div>
                <div className="asset-detail">
                  {this.state.asset_list.map(item => (
                    <div className="asset-item" key={item.name}>
                      <span className="item-value">
                        {(item.need_divide ? this.state.user_asset[item.value] / 100 : this.state.user_asset[item.value]) || 0}
                      </span>
                      <span className="item-name">{item.name}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="asset-info">
                <div className="shopDataTitle">
                  <i className="titleSign" />
                  客户资料
                </div>
                <div className="asset-detail userInfo">
                  {this.state.userInfos.map(item => (
                    <div className="asset-item" key={item.name}>
                      {item.value !== 'area' ? (
                        <span className="item-value">
                          {item.name}: {this.state.user_res[item.value] || '未知'}
                        </span>
                      ) : (
                          <span className="item-value">
                            {item.name}: {this.state.user_res['country']}{this.state.user_res['province']}{this.state.user_res['city']}
                          </span>
                        )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="vip-info">
            <div className="shopDataTitle">
              <i className="titleSign" />
              会员权益
            </div>
            <div className="rights-info">
              <div className="info-block">
                <span className="label">会员等级：</span>
                <div className="value">
                  {this.state.user_res.grade_name}
                </div>
              </div>
              <div className="info-block">
                <span className="label">权益卡：</span>
                <div className="value">
                  <Checkbox.Group style={{ width: "100%" }} onChange={this.onChangeCheckbox}>
                    {this.state.user_gift_card_list.map(item => (
                      <Checkbox key={item.user_gift_card_id} value={item.user_gift_card_id}>
                        {item.gift_card_name}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </div>
              </div>
              <div className="info-block">
                <span className="label">权益内容：</span>
                <div className="value">
                  <div className="rights-item">
                    <span className="index">1</span>
                    <span>兑换商品，剩余次数：{this.state.total_num}</span>
                  </div>
                  {this.state.right_list.map((item, index) => (
                    <div key={index} className="rights-item">
                      <span className="index">{index + 2}</span>
                      <span>{item.amount}倍积分回馈率</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="assetInformation">
            <div className="shopDataTitle">
              <i className="titleSign" />
              资产信息
            </div>
            <div className="asset-detail">
              {this.state.assetInformations.map(item => (
                <div className="asset-item" key={item.name}>
                  <span className="item-name">{item.name}</span>
                  <span className="item-value">
                    {(item.need_divide ? this.state.user_asset[item.value] / 100 : this.state.user_asset[item.value]) || 0}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};
export default connect(mapStateToProps)(Client);
