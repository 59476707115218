import React from "react";
import "@css/campaignShow.scss";
import { Divider, Button, Icon } from "antd";

class CampaignFixed extends React.Component {
  constructor() {
    super();
    this.state = {
      goods_list: [],
      demo_list: [
        {
          goods_id: 306,
          goods_res: {
            current_price: 100,
            goods_name: "秒杀商品秒杀商品",
            original_price: 9900,
            thumbnail_url: "http://udh.oss-cn-hangzhou.aliyuncs.com/510dd008-0397-4821-9ae5-a4bb1584d103"
          }
        }
      ]
    };
  }

  render() {
    return (
      <div className="campaign-fixed">
        <div className="buyEveryDay" style={{ display: this.props.campaign.activity_subtype === "buyEveryDay" ? true : "none" }}>
          <div>
            <span className="title">每日必买</span>
            <Divider type="vertical" />
            <span className="desc">便宜好货</span>
            <Button size="small">更多好物></Button>
          </div>
          <div className="content">
            <div className="goods">
              <div
                className="pic"
                style={{
                  background:
                    this.props.campaign.activity_goods_res_list.length >= 1
                      ? `url(${JSON.parse(this.props.campaign.activity_goods_res_list[0].goods_res.gallery_urls)[0]} )`
                      : "#E6E6E6"
                }}>
                {this.props.campaign.activity_goods_res_list.length >= 1 ? null : <Icon type="picture" />}
              </div>
              <div className="price">
                ¥
                {this.props.campaign.activity_goods_res_list.length >= 1
                  ? this.props.campaign.activity_goods_res_list[0].goods_res.current_price / 100
                  : null}
              </div>
            </div>
            <div className="goods">
              <div
                className="pic"
                style={{
                  background:
                    this.props.campaign.activity_goods_res_list.length >= 2
                      ? `url(${JSON.parse(this.props.campaign.activity_goods_res_list[1].goods_res.gallery_urls)[0]} )`
                      : "#E6E6E6"
                }}>
                {this.props.campaign.activity_goods_res_list.length >= 2 ? null : <Icon type="picture" />}
              </div>
              <div className="price">
                ¥
                {this.props.campaign.activity_goods_res_list.length >= 2
                  ? this.props.campaign.activity_goods_res_list[1].goods_res.current_price / 100
                  : null}
              </div>
            </div>
            <div className="goods">
              <div
                className="pic"
                style={{
                  background:
                    this.props.campaign.activity_goods_res_list.length >= 3
                      ? `url(${JSON.parse(this.props.campaign.activity_goods_res_list[2].goods_res.gallery_urls)[0]} )`
                      : "#E6E6E6"
                }}>
                {this.props.campaign.activity_goods_res_list.length >= 3 ? null : <Icon type="picture" />}
              </div>
              <div className="price">
                ¥
                {this.props.campaign.activity_goods_res_list.length >= 3
                  ? this.props.campaign.activity_goods_res_list[2].goods_res.current_price / 100
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div className="memberDay" style={{ display: this.props.campaign.activity_subtype === "memberDay" ? true : "none" }}>
          <div>
            <span className="title">会员日</span>
            <Button size="small">火速抢购></Button>
          </div>
          <div className="content">
            <div className="goods">
              <div
                className="pic"
                style={{
                  background:
                    this.props.campaign.activity_goods_res_list.length >= 1
                      ? `url(${JSON.parse(this.props.campaign.activity_goods_res_list[0].goods_res.gallery_urls)[0]} )`
                      : "#E6E6E6"
                }}>
                {this.props.campaign.activity_goods_res_list.length >= 1 ? null : <Icon type="picture" />}
              </div>
              <div className="price">
                ¥
                {this.props.campaign.activity_goods_res_list.length >= 1
                  ? this.props.campaign.activity_goods_res_list[0].goods_res.current_price / 100
                  : null}
              </div>
            </div>
            <div className="goods-group">
              <div className="side-goods">
                <div
                  className="pic"
                  style={{
                    background:
                      this.props.campaign.activity_goods_res_list.length >= 2
                        ? `url(${JSON.parse(this.props.campaign.activity_goods_res_list[1].goods_res.gallery_urls)[0]} )`
                        : "#E6E6E6"
                  }}>
                  {this.props.campaign.activity_goods_res_list.length >= 2 ? null : <Icon type="picture" />}
                </div>
                <div className="side-goods-group-detail">
                  今日仅售
                  <div className="price">
                    ¥
                    {this.props.campaign.activity_goods_res_list.length >= 2
                      ? this.props.campaign.activity_goods_res_list[1].goods_res.current_price / 100
                      : null}
                  </div>
                </div>
              </div>
              <div className="side-goods">
                <div
                  className="pic"
                  style={{
                    background:
                      this.props.campaign.activity_goods_res_list.length >= 3
                        ? `url(${JSON.parse(this.props.campaign.activity_goods_res_list[2].goods_res.gallery_urls)[0]} )`
                        : "#E6E6E6"
                  }}>
                  {this.props.campaign.activity_goods_res_list.length >= 3 ? null : <Icon type="picture" />}
                </div>
                <div className="side-goods-group-detail">
                  今日仅售
                  <div className="price">
                    ¥
                    {this.props.campaign.activity_goods_res_list.length >= 3
                      ? this.props.campaign.activity_goods_res_list[2].goods_res.current_price / 100
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={"timeLimitBuy type" + (this.props.show_type || this.props.campaign.pattern_type)}
          style={{ display: this.props.campaign.activity_subtype === "timeLimitBuy" ? true : "none" }}>
          {(this.props.show_type || this.props.campaign.pattern_type) === 1 ? (
            <>
              <div>
                <span className="title">限时秒杀</span>
                <Button size="small">会员专享></Button>
              </div>
              <div className="time-show">
                <span className="time">2</span>
                <span className="time">3</span>
                <span>:</span>
                <span className="time">5</span>
                <span className="time">9</span>
                <span>:</span>
                <span className="time">5</span>
                <span className="time">9</span>
              </div>
              <div className="content">
                <div className="goods">
                  <div
                    className="pic"
                    style={{
                      background:
                        this.props.campaign.activity_goods_res_list.length >= 1
                          ? `url(${JSON.parse(this.props.campaign.activity_goods_res_list[0].goods_res.gallery_urls)[0]} )`
                          : "#E6E6E6"
                    }}>
                    {this.props.campaign.activity_goods_res_list.length >= 1 ? null : <Icon type="picture" />}
                  </div>
                  <div className="price">
                    ¥
                    {this.props.campaign.activity_goods_res_list.length >= 1
                      ? this.props.campaign.activity_goods_res_list[0].goods_res.current_price / 100
                      : null}
                  </div>
                </div>
                <div className="goods">
                  <div
                    className="pic"
                    style={{
                      background:
                        this.props.campaign.activity_goods_res_list.length >= 2
                          ? `url(${JSON.parse(this.props.campaign.activity_goods_res_list[1].goods_res.gallery_urls)[0]} )`
                          : "#E6E6E6"
                    }}>
                    {this.props.campaign.activity_goods_res_list.length >= 2 ? null : <Icon type="picture" />}
                  </div>
                  <div className="price">
                    ¥
                    {this.props.campaign.activity_goods_res_list.length >= 2
                      ? this.props.campaign.activity_goods_res_list[1].goods_res.current_price / 100
                      : null}
                  </div>
                </div>
                <div className="goods">
                  <div
                    className="pic"
                    style={{
                      background:
                        this.props.campaign.activity_goods_res_list.length >= 3
                          ? `url(${JSON.parse(this.props.campaign.activity_goods_res_list[2].goods_res.gallery_urls)[0]} )`
                          : "#E6E6E6"
                    }}>
                    {this.props.campaign.activity_goods_res_list.length >= 3 ? null : <Icon type="picture" />}
                  </div>
                  <div className="price">
                    ¥
                    {this.props.campaign.activity_goods_res_list.length >= 3
                      ? this.props.campaign.activity_goods_res_list[2].goods_res.current_price / 100
                      : null}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="time-limit-buy-goods-list">
              {(this.props.campaign.activity_goods_res_list.length
                ? this.props.campaign.activity_goods_res_list
                : this.state.demo_list
              ).map(item => (
                <div className="time-limit-buy-goods-item goods" key={item.goods_id}>
                  <div
                    className="goods-image pic"
                    style={{
                      background: `url(${item.goods_res.thumbnail_url} )`
                    }}>
                    <div className="time-limit">倒计时</div>
                  </div>
                  <div className="goods-info">
                    <div className="goods-name">{item.goods_res.goods_name}</div>
                    <div className="goods-price">
                      <span>秒杀价:</span>
                      <span className="current-price">￥{item.goods_res.current_price / 100}</span>
                      <span className="original-price">{item.goods_res.original_price / 100}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CampaignFixed;
