import React from 'react'
import { Collapse, Form, Input, Modal, Upload, Select, Radio, Button, Icon, Table, message, Checkbox, Divider, Dropdown, TreeSelect, Tooltip, Switch } from 'antd';
import UploadButton from "@/view/component/common/UploadButton";
import GoodsGroupAdd from "@/view/component/common/GoodsGroupAdd";
import GroupGoodAdd from "@/view/component/Goods/GroupGoodAdd";
import '@css/goodsAdd.scss'
import {
    queryAttributeList,
    getCatList,
    createOrUpdateGoodsAndSku,
    createOrUpdateAttributeValue,
    queryShippingTemplateList,
    onsaleGoodsList,
    createGroupOpenRecord
} from '@service/getData'
import config from '@config/config'
import GoodsAttr from "@/view/component/Goods/GoodsAttr";
import NavBar from '@/view/component/common/NavBar.js'
import cookie from 'react-cookies'
import { connect } from "react-redux";
import { formatNumber, formatInt, formatIntAndZero } from '@common/common'
import PropTypes from 'prop-types'
import GoodsDragSort from "@/view/component/GoodsDragSort/index";
class GoodsAdd extends React.Component {
    constructor(props) {
        GoodsAdd.contextTypes = {
            router: PropTypes.object.isRequired
        }
        super();
        this.state = {
            previewVisible: false,
            previewGalleryVisible: false,
            previewImage: '',
            previewGalleryImage: '',
            fileList: [],
            galleryList: [],
            shareFileList: [],
            visible: false,
            requestUrl: config.baseUrl + "/ossFile/upload",
            attributeList: [],
            attrList: [{ attribute_id: null, attribute_value_res_list: [] }, {
                attribute_id: null,
                attribute_value_res_list: []
            }, { attribute_id: null, attribute_value_res_list: [] }],
            attrTime: [],
            attrValueList: [],
            editable: true,
            columns: [
                {
                    title: <div><span className="text-danger">*</span>价格</div>,
                    dataIndex: 'current_price',
                    key: 'current_price',
                    render: (text, record) => (
                        <Input className="goods-add-input" value={text}
                            onChange={this.changeMoney.bind(this, record, 'current_price')} />
                    ),
                }, {
                    title: <div><span className="text-danger">*</span>库存</div>,
                    dataIndex: 'balance',
                    key: 'balance',
                    render: (text, record) => (
                        <Input className="goods-add-input" value={text}
                            onChange={this.changeMoney.bind(this, record, 'balance')} />
                    ),
                }, {
                    title:
                        <div>
                            规格编码
                            <Tooltip
                                title="可自定义规格编码，如货号、sku编码">
                                <Icon type="question-circle" theme="twoTone" />
                            </Tooltip>
                        </div>,
                    dataIndex: 'sku_code',
                    key: 'sku_code',
                    render: (text, record) => (
                        <Input className="goods-add-input" value={text}
                            onChange={this.changeMoney.bind(this, record, 'sku_code')} />
                    )
                }, {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    render: (text, record, index) => (
                        <Switch
                            disabled={!this.state.editable ? true : false}
                            checkedChildren="启用"
                            unCheckedChildren="禁用"
                            checked={record.status === 1}
                            onClick={this.handleSkuStatus.bind(this, index)}
                        />
                    )
                }],
            weightColumns: [
                {
                    title: '重量(KG)',
                    dataIndex: 'weight',
                    key: 'weight',
                    render: (text, record) => (
                        <Input className="goods-add-input" value={text}
                            onChange={this.changeMoney.bind(this, record, 'weight')} />
                    ),
                }],
            dataSource: [],
            checked: false,
            templateList: [],
            template: null,
            allWeight: null,
            directFee: null,
            videoList: [],
            cat_list: [],
            goods_visible: false,
            buy_limit: "",
            show_more: true,
            info_columns: [
                {
                    title: <div><span className="text-danger">*</span>价格(元)</div>,
                    dataIndex: 'current_price',
                    key: 'current_price',
                    render: (text, record) => (

                        <Input value={text} className="shipment-fee" placeholder="输入价格"
                            onChange={this.onChangeInfo.bind(this, 'current_price')} />

                    ),

                }, {
                    title: <div><span className="text-danger">*</span>库存</div>,
                    dataIndex: 'balance',
                    key: 'balance',
                    render: (text, record) => (
                        <Input value={text} onChange={this.onChangeInfo.bind(this, 'balance')} className="shipment-fee"
                            placeholder="输入库存" />

                    ),

                }
            ],
            info: {
                balance: null,
                goods_code: null,
                current_price: null
            }
        }
    }
    handleCancel = () => {
        this.setState({
            previewVisible: false
        })
    }
    handleGalleryCancel = () => {
        this.setState({
            previewGalleryVisible: false
        })
    }

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl || file,
            previewVisible: true,
        });
    }
    handleGalleryPreview = (file) => {
        this.setState({
            previewGalleryImage: file.url || file.thumbUrl || file,
            previewGalleryVisible: true,
        });
    }

    handleChange = ({ fileList }) => {
        let last = fileList[fileList.length - 1]
        last.url = last.response && last.response.result && last.response.result.oss_file_url
        this.setState({ fileList })
    }
    handleShareChange = ({ fileList }) => {
        // 删除图片的时候，fileList为空数组。做兼容。
        if (fileList.length) {
            let last = fileList[fileList.length - 1]
            last.url = last.response && last.response.result && last.response.result.oss_file_url
        }
        this.setState({ shareFileList: [...fileList] });
    }
    handleGalleryChange = ({ fileList }) => {
        let last = fileList[fileList.length - 1];
        last.url = last.response && last.response.result && last.response.result.oss_file_url;
        this.setState({ galleryList: fileList })
    }
    handleVideoChange = ({ fileList }) => {
        this.setState({ videoList: fileList })
    }
    addAttr = () => {
        if (this.state.attributeList.length < 1) {
            this.queryAttributeList();
        }
        this.setState(prevState => ({
            attrTime: prevState.attrTime.concat(1),
        }));
    }
    modifyValue = (data, e) => {
        this.setState(prevState => ({
            attrList: prevState.attrList.map(
                (item, _index) => _index == data ? {
                    ...item,
                    ["attribute_id"]: e,
                    ["attribute_value_res_list"]: []
                } : item) // eslint-disable-next-line

        }));
        let attr = null;
        this.state.attributeList.forEach(item => {
            if (item.attribute_id == e) {
                attr = {
                    title: item.attribute_name,
                    dataIndex: item.attribute_id,
                    key: item.attribute_id,
                }
            }
        })
        let curCulumns = this.state.columns.splice(0);
        let weightColumns = this.state.weightColumns.splice(0)

        if (attr) {
            if (curCulumns.length - 4 - data) {
                curCulumns.splice(data, 1, attr);

            } else {
                curCulumns.splice(data, 0, attr);
            }
            if (weightColumns.length - 1 - data) {
                weightColumns.splice(data, 1, attr);

            } else {
                weightColumns.splice(data, 0, attr);
            }

            this.setState(prevState => ({
                columns: curCulumns,
                weightColumns: weightColumns
            }), this.changedDataSource);
        }
    }
    modifyAttrValue = (data, e, list) => {
        this.setState(prevState => ({
            attrList: prevState.attrList.map(
                (item, _index) => _index == data ? { ...item, ["attribute_value_res_list"]: list } : item) // eslint-disable-next-line

        }
        ), this.changedDataSource);
    }

    changedDataSource = () => {
        let dataSoureList = []
        if (this.state.attrList[0].attribute_value_res_list.length > 0) {
            let key = this.state.attrList[0].attribute_id;
            let list = [];
            this.state.attributeList.forEach(item => {
                if (item.attribute_id == key) {
                    list = item.attribute_value_res_list;
                }
            })
            this.state.attrList[0].attribute_value_res_list.forEach(item => {
                let dataSoure = {
                    current_price: null,
                    balance: null,
                    sku_code: null,
                    weight: null
                }
                list.forEach(value => {
                    if (value.attribute_value_id == item.attribute_value_id) {
                        dataSoure[key] = value.attribute_value
                        dataSoure.key1Value = value.attribute_value_id
                        dataSoure.key1 = key
                        dataSoure.status = 1;
                    }
                })
                if (this.state.attrList[1].attribute_value_res_list.length > 0) {

                    let key = this.state.attrList[1].attribute_id;
                    let list = [];
                    this.state.attributeList.forEach(item => {
                        if (item.attribute_id == key) {
                            list = item.attribute_value_res_list;
                        }
                    })
                    this.state.attrList[1].attribute_value_res_list.map(item => {
                        let dataSoure1 = JSON.parse(JSON.stringify(dataSoure))
                        list.forEach(value => {
                            if (value.attribute_value_id == item.attribute_value_id) {
                                dataSoure1[key] = value.attribute_value
                                dataSoure1.key2Value = value.attribute_value_id
                                dataSoure1.key2 = key
                                dataSoure1.status = 1;
                            }
                        })
                        if (this.state.attrList[2].attribute_value_res_list.length > 0) {
                            let key = this.state.attrList[2].attribute_id;
                            let list = [];
                            this.state.attributeList.forEach(item => {
                                if (item.attribute_id == key) {
                                    list = item.attribute_value_res_list;
                                }
                            })
                            this.state.attrList[2].attribute_value_res_list.map(item => {
                                let dataSoure2 = JSON.parse(JSON.stringify(dataSoure1))
                                list.map(value => {
                                    if (value.attribute_value_id == item.attribute_value_id) {
                                        dataSoure2[key] = value.attribute_value
                                        dataSoure2.key3Value = value.attribute_value_id
                                        dataSoure2.key3 = key
                                        dataSoure2.status = 1;
                                    }
                                })
                                dataSoureList.push(dataSoure2)
                            })
                        } else {
                            dataSoureList.push(dataSoure1)
                        }
                    })
                } else {
                    dataSoureList.push(dataSoure)
                }
            })
        }
        dataSoureList.forEach((item, index) => {
            item.key = index
        })
        this.setState({
            dataSource: dataSoureList
        })
    }

    deleteAttr = (data, e) => {
        let times = [...this.state.attrTime]
        let attr = this.state.attrList[data];
        let columns = [...this.state.columns];
        let weightColumns = [...this.state.weightColumns]
        let attrList = [...this.state.attrList];
        let arrIndex = null;
        columns.forEach((item, index) => {
            if (item.dataIndex == attr.attribute_id) {
                arrIndex = index
            }
        })
        let weightIndex = null;
        weightColumns.forEach((item, index) => {
            if (item.dataIndex == attr.attribute_id) {
                weightIndex = index
            }
        })
        if (arrIndex !== null) {
            columns.splice(arrIndex, 1)
        }
        if (weightIndex !== null) {
            weightColumns.splice(weightIndex, 1)
        }
        attrList.splice(data, 1)
        attrList.push({ attribute_id: null, attribute_value_res_list: [] })
        times.pop()
        let checked = this.state.checked
        if (data === 0) {
            checked = !checked
        }
        this.setState(prevState => ({
            attrTime: times,
            attrList: attrList,
            columns: columns,
            weightColumns: weightColumns,
            checked
        }), () => {
            this.changedDataSource()
            this.forceUpdate();
        });

    }

    async queryAttributeList() {
        let res = await queryAttributeList({});
        if (res.success) {
            this.setState({
                attributeList: res.result.attribute_res_list
            })
        }


    }

    async getCatList() {
        let data = {
            mini_app_id: cookie.load("mini_app_id")
        }
        let res = await getCatList(data);
        if (res.success) {
            this.setState({
                cat_list: res.result.cat_list
            })
        }
    }

    async createOrUpdateAttributeValue(data) {
        let res = await createOrUpdateAttributeValue(data)
        if (res.success) {
            this.queryAttributeList();
        }
    }
    modifyThumbnail = (data, list) => {
        this.setState(prevState => ({
            attrList: prevState.attrList.map(
                (item, _index) => _index == data ? { ...item, ["attribute_value_res_list"]: list } : item) // eslint-disable-next-line
        }
        ));
    }
    createAttrValue = (e, attr_id, value) => {
        let data = {
            attribute_value_req: {
                shop_id: cookie.load("shop_id"),
                attribute_id: attr_id,
                attribute_value: value,
                status: 1
            }
        }
        this.createOrUpdateAttributeValue(data)
    }
    handleSkuStatus = (index, status) => {
        let dataSource = [...this.state.dataSource];
        dataSource[index].status = status ? 1 : -1;
        this.setState({
            dataSource
        });
    };
    changeMoney = (data, index, e) => {
        e.persist()
        let value = e.target.value
        if (index == "current_price") {
            value = formatNumber(value)
        } else if (index == "sku_code" || index == "goods_code") {
            value = value.replace(/[^0-9a-zA-z]/g, '')
        } else if (index === 'balance') {
            value = formatIntAndZero(value)
        } else {
            value = formatInt(value)
        }
        this.setState(prevState => ({
            dataSource: prevState.dataSource.map(
                item => data.key == item.key ? { ...item, [index]: value } : item)

        }))
    }
    getCatList = async () => {
        let data = {
            mini_app_id: cookie.load("mini_app_id")
        }
        let res = await getCatList(data)
        if (res.success) {
            this.setState({
                cat_list: res.result.cat_list
            })
        }
    }

    componentDidMount() {
        this.queryShippingTemplateList()
        this.getCatList()
    }

    submitGoods = (isCreateGroup, e) => {
        let form = this.props.form.getFieldsValue();
        let goods_req = {
            cat_id: form.cat_id
        }
        goods_req.shop_id = cookie.load("shop_id")
        if (!form.goods_name) {
            return message.error('商品名称为必填项，请填写商品名称');

        }
        goods_req.forward_title = form.forward_title;
        goods_req.goods_name = form.goods_name;
        goods_req.goods_short_name = form.goods_short_name;
        goods_req.goods_code = this.state.info.goods_code;
        if (!form.on_sale) {
            return message.error('请选择上架设置');

        }
        goods_req.onsale = form.on_sale;
        if (typeof form.limit_flag == 'boolean') {
            goods_req.limit_flag = form.limit_flag ? 1 : 0;
        }
        if (typeof form.limit_flag == 'boolean' && form.limit_flag == true && !this.state.buy_limit) {
            return message.error("请输入限购件数")
        }
        goods_req.buy_limit = this.state.buy_limit
        if (!form.shipping_fee_type) {
            return message.error("请选择运费类型")

        }
        goods_req.shipping_fee_type = form.shipping_fee_type
        if (form.shipping_fee_type == "direct_fee" && (this.state.directFee == null || this.state.directFee.toString().replace(/\s*/g, "").length === 0)) {
            return message.error("请填写统一运费")

        } else if (form.shipping_fee_type == "direct_fee") {
            goods_req.shipping_fee_value = Math.round(this.state.directFee * 100)
        } else if (!this.state.template) {
            return message.error("请选择运费模版")
        } else {
            goods_req.shipping_fee_value = this.state.template.shipping_template_id
        }
        goods_req.goods_desc = form.goods_desc;
        let ret = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
        let reg = /^\d+$/
        if (form.promotion_amount_rate == null || !reg.test(form.promotion_amount_rate)) {
            return message.error('佣金比例不正确');

        }
        goods_req.promotion_amount_rate = form.promotion_amount_rate;
        goods_req.status = 1;
        if (form.original_price && !ret.test(form.original_price)) {
            return message.error('下划价不正确');
        }
        goods_req.original_price = form.original_price ? Math.round(form.original_price * 100) : null;
        if (this.state.attrTime.length < 1) {
            if (!this.state.info.balance || !reg.test(this.state.info.balance)) {
                return message.error('库存不正确');

            }
            goods_req.balance = this.state.info.balance;
            if (!this.state.info.current_price || !ret.test(this.state.info.current_price)) {
                return message.error('商品价格不正确');

            }
            goods_req.current_price = Math.round(this.state.info.current_price * 100);
            if (this.state.template && this.state.template.shipping_fee_type == "weight" && this.props.form.getFieldsValue().shipment_weight_type == "1" && !this.state.allWeight) {
                return message.error("请填写统一重量")
            }
            if (this.props.form.getFieldsValue().shipment_weight_type == "2" && this.state.attrTime.length === 0) {
                return message.error("请选择商品重量")
            }
            if (this.state.template && this.state.template.shipping_fee_type == "weight") {
                goods_req.weight = Math.round(this.state.allWeight * 1000)
            }
        } else {
            let attribute_req_list = []
            let temp = false
            let photo_flag = false;
            this.state.attrList.forEach((item, index) => {
                if (item.attribute_id && item.attribute_value_res_list.length > 0) {
                    for (let i = 0; i < item.attribute_value_res_list.length; i++) {
                        if (!item.attribute_value_res_list[i].attribute_value_id || !item.attribute_value_res_list[i].value) {
                            temp = true
                        }
                    }
                    let data = {
                        attribute_id: item.attribute_id,
                        attribute_value_req_list: item.attribute_value_res_list
                    }
                    if (index == 0 && this.state.checked) {
                        item.attribute_value_res_list.map(attr_value_item => {
                            if (!attr_value_item.goods_attribute_value_thumbnail) {
                                photo_flag = true
                            }

                        })
                    } else if (index == 0 && !this.state.checked) {
                        item.attribute_value_res_list.map(attr_value_item => {
                            attr_value_item.goods_attribute_value_thumbnail = null
                        })
                    }

                    if (index == 0) {
                        data.is_main = 1;
                    }
                    attribute_req_list.push(data)
                }
            })
            if (photo_flag) {
                return message.error('请完整的上传图片');
            }
            if (!attribute_req_list.length || temp) {
                return message.error('请完整的填写商品规格');
            }
            goods_req.attribute_req_list = attribute_req_list;
            let flag = false;
            this.state.dataSource.forEach(item => {
                for (var key in item) {
                    if (key === "key") continue
                    if (key === "weight" && (this.props.form.getFieldsValue().shipping_fee_type === "direct_fee" || (this.state.template && (this.state.template.shipping_fee_type === "number" || this.props.form.getFieldsValue().shipment_weight_type.toString() === "1")))) continue
                    if (item[key] == null || item[key] == "") {
                        flag = true;
                    }
                }
            })
            if (this.state.template && this.state.template.shipping_fee_type === "weight" && this.props.form.getFieldsValue().shipment_weight_type.toString() === "1" && !this.state.allWeight) {
                return message.error("请填写统一重量")
            }
            if (flag) {
                return message.error('请完整的填写规格明细');
            } else {
                let attr_value_list = [];
                this.state.dataSource.forEach(item => {
                    let attr_value = {
                        balance: item.balance,
                        current_price: Math.round(item.current_price * 100),
                        status: item.status,
                        sku_code: item.sku_code,
                        goods_code: item.goods_code,
                        sku_attribute_value_req_list: []
                    }
                    if (this.state.template && this.state.template.shipping_fee_type == "weight" && this.props.form.getFieldsValue().shipment_weight_type == "2") {
                        attr_value.weight = Math.round(item.weight * 1000)
                    } else if (this.state.template && this.state.template.shipping_fee_type == "weight" && this.props.form.getFieldsValue().shipment_weight_type == "1") {
                        attr_value.weight = Math.round(this.state.allWeight * 1000)
                    }
                    if (item.key1) {
                        attr_value.sku_attribute_value_req_list.push({ attribute_value_id: item.key1Value })
                    }
                    if (item.key2) {
                        attr_value.sku_attribute_value_req_list.push({ attribute_value_id: item.key2Value })
                    }
                    if (item.key3) {
                        attr_value.sku_attribute_value_req_list.push({ attribute_value_id: item.key3Value })
                    }
                    attr_value_list.push(attr_value)
                })
                goods_req.sku_req_list = attr_value_list
            }
        }
        goods_req.position = 50;
        if (!this.state.galleryList.length) {
            return message.error('请添加轮播图');
        }
        let gallery_urls = []
        this.state.galleryList.map(item => {
            if (item.response.success) {
                gallery_urls.push(item.response.result.oss_file_url)
            }
        })
        goods_req.gallery_urls = gallery_urls
        let image_url = []
        this.state.fileList.map(item => {
            if (item.response.success) {
                image_url.push(item.response.result.oss_file_url)
            }
        })
        goods_req.image_url = image_url
        let video_url = []
        this.state.videoList.map(item => {
            if (item.response.success) {
                video_url.push(item.response.result.oss_file_url)
            }
        })
        goods_req.video_url = video_url

        let share_image_url = [];
        this.state.shareFileList.map(item => {
            if (item.response.success) {
                share_image_url.push(item.response.result.oss_file_url)
            }
        });
        goods_req.share_image_url = share_image_url[0];

        let data = {
            goods_req: goods_req
        }
        this.createOrUpdateGoodsAndSku(data, isCreateGroup)

    }
    onsaleGoodsList = async (goods_id, onsale_status) => {
        let onsaledata = {
            goods_id_list: [goods_id],
            onsale_status
        }
        let res = await onsaleGoodsList(onsaledata);
        if (res.success) {
            message.success(onsale_status ? "开团并上架成功" : "开团成功并保存在下架商品列表中~")
            this.resetFormAndState()
        } else {
            message.error(res.msg)
        }
    }
    createGroupOpenRecord = async (goods_id, onsale_status) => {
        let data = {
            group_open_record: {
                shop_id: cookie.load("shop_id"),
                goods_ids: [goods_id],
                is_all_leader: 2,
                group_type: 2,
                community_ids: [cookie.load("default_community_id")]
            }
        }
        let res = await createGroupOpenRecord(data);
        if (res.success) {
            this.onsaleGoodsList(goods_id, onsale_status)
        }
    }
    async createOrUpdateGoodsAndSku(data, isCreateGroup) {
        let res = await createOrUpdateGoodsAndSku(data);
        if (res.success) {
            if (cookie.load("mini_app_type") == "flagship_version") {
                if (isCreateGroup) {
                    //自动上架并开团
                    this.createGroupOpenRecord(res.result.goods_id, 1)
                } else {
                    //自动开团并保存
                    this.createGroupOpenRecord(res.result.goods_id, 0)
                }
                return
            } else {
                if (!isCreateGroup) {
                    message.success('保存成功')
                }
            }
            this.resetFormAndState()
            this.setState({
                visible: isCreateGroup ? true : false,
                goods_id: isCreateGroup ? res.result.goods_id : null,
            })
        }
    }
    resetFormAndState() {
        this.props.form.resetFields();
        this.props.form.setFieldsValue({ "limit_flag": false })
        this.setState({
            attrList: [{ attribute_id: null, attribute_value_res_list: [] }, {
                attribute_id: null,
                attribute_value_res_list: []
            }, { attribute_id: null, attribute_value_res_list: [] }],
            attrTime: [],
            attrValueList: [],
            fileList: [],
            galleryList: [],
            shareFileList: [],
            dataSource: [],
            allWeight: null,
            directFee: null,
            template: null,
            buy_limit: "",
            columns: [
                {
                    title: <div><span className="text-danger">*</span>价格</div>,
                    dataIndex: 'current_price',
                    key: 'current_price',
                    render: (text, record) => (
                        <Input className="goods-add-input" value={text}
                            onChange={this.changeMoney.bind(this, record, 'current_price')} />
                    ),
                }, {
                    title: <div><span className="text-danger">*</span>库存</div>,
                    dataIndex: 'balance',
                    key: 'balance',
                    render: (text, record) => (
                        <Input className="goods-add-input" value={text}
                            onChange={this.changeMoney.bind(this, record, 'balance')} />
                    ),
                }, {
                    title:
                        <div>
                            规格编码
                            <Tooltip
                                title="可自定义规格编码，如货号、sku编码">
                                <Icon type="question-circle" theme="twoTone" />
                            </Tooltip>
                        </div>,
                    dataIndex: 'sku_code',
                    key: 'sku_code',
                    render: (text, record) => (
                        <Input className="goods-add-input" value={text}
                            onChange={this.changeMoney.bind(this, record, 'sku_code')} />
                    ),
                }, {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    render: (text, record, index) => (
                        <Switch
                            disabled={!this.state.editable ? true : false}
                            checkedChildren="启用"
                            unCheckedChildren="禁用"
                            checked={record.status === 1}
                            onClick={this.handleSkuStatus.bind(this, index)}
                        />
                    )
                }],
            weightColumns: [
                {
                    title: '重量(KG)',
                    dataIndex: 'weight',
                    key: 'weight',
                    render: (text, record) => (
                        <Input className="goods-add-input" value={text}
                            onChange={this.changeMoney.bind(this, record, 'weight')} />
                    ),
                }],
            info: {}
        })
    }
    queryShippingTemplateList = async () => {
        let data = {
            shop_id: cookie.load("shop_id"),
            page_no: 1,
            page_size: 10000
        }
        let res = await queryShippingTemplateList(data)
        if (res.success) {
            this.setState({
                templateList: res.result.shipping_template_res_list,
            })
        }
    }
    onChangeChecked = e => {
        this.setState({
            checked: e.target.checked,
        });
    };
    handleClose = () => {
        this.setState({
            visible: false,
            goods_id: null
        })
    }
    gotoTemplate = () => {
        window.open(window.location.origin + "/shippingManage", '_blank');
    }
    ShippingFeeTYpeChange = (id) => {
        let template = this.state.templateList.find(item => item.shipping_template_id == id)
        this.setState({
            template: template
        })
    }
    ChangeAllWeight = (e) => {
        this.setState({
            allWeight: e.target.value
        })
    }
    ChangeDirectFee = (e) => {
        this.setState({
            directFee: formatNumber(e.target.value)
        })
    }
    changeBuyLimit = (e) => {

        this.setState({
            buy_limit: formatIntAndZero(e.target.value)
        })
    }

    radioChange = (e) => {
        let value = e.target.value;
        if (value == "direct_fee") {
            this.setState({
                template: null,
                allWeight: null
            })
        }
    }
    goodsOk = () => {
        this.getCatList()
        this.setState(prevState => ({
            goods_visible: !prevState.goods_visible
        }));

    }
    goodsVisibleChange = () => {
        this.setState(prevState => ({
            goods_visible: !prevState.goods_visible
        }));
    }
    changeShowMore = () => {
        this.setState(prevState => ({
            show_more: !prevState.show_more
        }))
    }
    onChangeInfo = (type, e) => {
        let info = { ...this.state.info }
        let value = e.target.value
        if (type == "current_price") {
            value = formatNumber(value)
        } else if (type == "goods_code") {
            value = value.replace(/[^0-9a-zA-z-]/g, '')
            this.props.form.setFieldsValue({ goods_code: value })
        } else if (type === 'balance') {
            value = formatIntAndZero(value)
        }
        info[type] = value;
        this.setState({
            info
        })
    }
    beforeUpload(file) {
        const isJpgOrPng = (file.type === 'image/jpeg' || file.type === 'image/png') || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('仅支持 JPG/PNG 格式');
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.error('图片大小不能超过3MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    render() {
        const { previewVisible, previewImage, fileList, galleryList, videoList } = this.state;
        const { getFieldDecorator } = this.props.form;
        let { menu_code } = this.props
        return (
            <div className="goods-add">
                <NavBar icon="icon-shangpinguanli" father={menu_code.goods_publish} child={menu_code.goods_publish_new}
                    router="goodsManage" />
                <div className="goods-content">
                    <div className="goods-title">
                        <i className="title-sign"></i>
                        添加商品基本信息
                    </div>
                    <Collapse defaultActiveKey={['1', '2', '3', '4', '5', '6']}
                        expandIcon={({ isActive }) => <Icon className="caret" type="caret-right"
                            rotate={isActive ? 90 : 0} />}>

                        <Collapse.Panel header={
                            <div className="panel-title">
                                基本信息(必填)
                                <span className="panel-desc">展开/折叠</span>
                            </div>
                        } key="1">
                            <Form>
                                <Form.Item label="商品名称">
                                    {getFieldDecorator('goods_name', {
                                        rules: [{
                                            required: true,
                                            message: "请输入商品名称!"
                                        }],
                                    })(
                                        <Input placeholder="请输入商品名称" />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="商品轮播图"
                                    extra={
                                        <div className="text-remind">
                                            建议尺寸为1:1，仅支持jpg/png两种格式，单张图片大小不超过3.0MB，最多上传9张图，第一张为主图
                                        </div>
                                    }
                                >
                                    {getFieldDecorator('gallery_urls', {
                                        rules: [{
                                            required: true,
                                            message: "请上传商品轮播图"
                                        }]
                                    })(
                                        <div style={{ display: "flex" }}>
                                            <GoodsDragSort
                                                list={galleryList}
                                                handlePreview={this.handlePreview}
                                                editable={true}
                                                changeList={list => {
                                                    this.setState({
                                                        galleryList: list
                                                    })
                                                }}
                                            />
                                            {galleryList.length < 9 ?
                                                <Upload
                                                    accept="image/jpeg,image/jpg,image/png"
                                                    withCredentials={true}
                                                    action={this.state.requestUrl}
                                                    listType="picture-card"
                                                    fileList={galleryList}
                                                    showUploadList={false}
                                                    onPreview={this.handleGalleryPreview}
                                                    onChange={this.handleGalleryChange}
                                                >
                                                    <UploadButton />
                                                </Upload> : null}
                                        </div>
                                    )}
                                </Form.Item>
                            </Form>
                            <div className={`${this.state.show_more ? null : "no-show"} text-note`}
                                onClick={this.changeShowMore}>
                                填写更多图文详情
                                <Icon className='info-collapse' type="down-circle" />
                            </div>
                            <Form className={`${this.state.show_more ? 'no-show' : null}`}>
                                <Form.Item label={
                                    <span>
                                        商品编码
                                        <Tooltip
                                            title="对每个商品做的唯一编码">
                                            <Icon type="question-circle" theme="twoTone" />
                                        </Tooltip>
                                    </span>
                                }>
                                    {getFieldDecorator('goods_code')(
                                        <div>
                                            <Input placeholder="请输入商品编码" value={this.state.info.goods_code} onChange={this.onChangeInfo.bind(this, 'goods_code')} />
                                        </div>
                                    )}
                                </Form.Item>
                                <Form.Item label="商品简称">
                                    {getFieldDecorator('goods_short_name')(
                                        <Input placeholder="请输入商品简称" />
                                    )}
                                </Form.Item>
                                <Form.Item label="商品分类"
                                    extra={<span className="text-note"
                                        onClick={this.goodsVisibleChange}>新建</span>}
                                >
                                    {getFieldDecorator('cat_id')(
                                        <TreeSelect
                                            multiple
                                            showSearch
                                            style={{ width: 400 }}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            allowClear
                                            treeDefaultExpandAll

                                        >
                                            {
                                                this.state.cat_list.map(item => (
                                                    <TreeSelect.TreeNode disabled={item.cat_list.length > 0}
                                                        value={item.cat_id} title={item.cat_name}
                                                        key={item.cat_id}>
                                                        {
                                                            item.cat_list.map(cat => (
                                                                <TreeSelect.TreeNode value={cat.cat_id}
                                                                    title={cat.cat_name}
                                                                    key={cat.cat_id} />
                                                            ))
                                                        }
                                                    </TreeSelect.TreeNode>
                                                ))
                                            }
                                        </TreeSelect>
                                    )}
                                </Form.Item>
                                <Form.Item label="商品转发标题" extra={
                                    <Dropdown
                                        overlay={
                                            <img
                                                src="http://udh.oss-cn-hangzhou.aliyuncs.com/8aedcb9b-92bc-44b9-a02a-352c60393bff"
                                                width="300"
                                            />
                                        }
                                        placement="bottomLeft"
                                    >
                                        <span className="text-note">查看示例</span>
                                    </Dropdown>
                                }>
                                    {getFieldDecorator('forward_title')(
                                        <Input placeholder="请输入转发标题，如：原价85元大米，现在仅35元！速抢！" />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="商品分享图片"
                                    extra={
                                        <div>
                                            <Tooltip title={
                                                <img width="240" src="http://udh.oss-cn-hangzhou.aliyuncs.com/4183c930-f1ce-45dc-b66e-47e390e09f59" />
                                            }>
                                                <span className="text-note">示例图片</span>
                                            </Tooltip>
                                            <p>建议尺寸为1:1，仅支持png,jpg两种格式。单张图片大小不超过3M</p>
                                        </div>
                                    }
                                >
                                    {getFieldDecorator('share_image')(
                                        <div style={{ display: 'flex' }}>
                                            <Upload
                                                accept="image/jpeg,image/jpg,image/png"
                                                withCredentials={true}
                                                action={this.state.requestUrl}
                                                beforeUpload={this.beforeUpload}
                                                listType="picture-card"
                                                fileList={this.state.shareFileList}
                                                onPreview={this.handlePreview}
                                                onChange={this.handleShareChange}
                                            >
                                                {this.state.shareFileList.length > 0 ? null : <UploadButton />}
                                            </Upload>
                                        </div>
                                    )}
                                </Form.Item>
                                <Form.Item label="推荐理由">
                                    {getFieldDecorator('goods_desc')(
                                        <Input.TextArea placeholder="输入推荐理由" />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="商品详情图"
                                    extra={
                                        <div className="text-remind">
                                            建议宽度375，高度不限，仅支持jpg/png两种格式，单张图片大小不超过3.0MB，最多上传9张图，第一张为主图
                                        </div>
                                    }
                                >
                                    {getFieldDecorator('goods_iamges')(
                                        <div style={{ display: "flex" }}>
                                            <GoodsDragSort
                                                list={fileList}
                                                handlePreview={this.handlePreview}
                                                editable={true}
                                                changeList={list => {
                                                    this.setState({
                                                        fileList: list
                                                    })
                                                }}
                                            />
                                            {fileList.length < 9 ?
                                                <Upload
                                                    accept="image/jpeg,image/jpg,image/png"
                                                    withCredentials={true}
                                                    action={this.state.requestUrl}
                                                    listType="picture-card"
                                                    fileList={fileList}
                                                    showUploadList={false}
                                                    onPreview={this.handlePreview}
                                                    onChange={this.handleChange}
                                                >
                                                    <UploadButton />
                                                </Upload> : null}
                                        </div>
                                    )}
                                </Form.Item>
                                <Form.Item label="商品视频"
                                    extra={
                                        <div className="text-remind">
                                            建议上传mp4格式视频，视频大小不超过4MB
                                               </div>
                                    }
                                >
                                    {getFieldDecorator('video_url')(
                                        <div>
                                            <Upload
                                                withCredentials={true}
                                                action={this.state.requestUrl}
                                                fileList={videoList}
                                                onChange={this.handleVideoChange}
                                                data={{
                                                    file_type: "video"
                                                }}
                                            >
                                                {videoList.length >= 1 ? null : <Button type="default">上传视频</Button>}
                                            </Upload>
                                        </div>
                                    )}
                                </Form.Item>
                            </Form>
                            <div className={`${!this.state.show_more ? null : "no-show"} text-note`}
                                onClick={this.changeShowMore}>
                                收起
                                <Icon className="info-collapse" type="up-circle" />
                            </div>
                        </Collapse.Panel>
                        <Collapse.Panel header={
                            <div className="panel-title">
                                价格库存(必填)
                                <span className="panel-desc">展开/折叠</span>
                            </div>
                        } key="2">
                            <Form layout="inline">
                                <Form.Item label="商品规格">
                                    <div>
                                        <span className="text-remind">最多添加3个商品规格，第一个商品规格可添加规格图片</span>
                                        <Button disabled={this.state.attrTime.length < 3 ? false : true}
                                            shape="round"
                                            size="small"
                                            type="primary"
                                            className="margin-left-20"
                                            onClick={this.addAttr}>
                                            添加规格名
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                            {
                                this.state.attrTime.map((item, index) =>
                                    (
                                        <div key={index}>
                                            <GoodsAttr
                                                modifyThumbnail={this.modifyThumbnail}
                                                addAttrValue={this.createAttrValue}
                                                arrValue={this.state.attrList[index].attribute_value_res_list}
                                                editable={true}
                                                deletable={index == this.state.attrTime.length - 1}
                                                attr_id={this.state.attrList[index].attribute_id}
                                                key={index}
                                                index={index}
                                                checked={this.state.checked && index == 0 ? true : false}
                                                attrList={this.state.attrList}
                                                attr={this.state.attributeList}
                                                change={this.modifyValue.bind(this, index)}
                                                valueChange={this.modifyAttrValue.bind(this, index)}
                                                deleteAttr={this.deleteAttr.bind(this, index)}
                                                onChangeChecked={this.onChangeChecked}
                                            />
                                        </div>
                                    )
                                )
                            }
                            <Form >
                                <Form.Item label="规格明细" style={{ "display": this.state.attrTime.length > 0 ? true : 'none' }}>
                                    <div>
                                        <Table rowKey={(record, index) => index} columns={this.state.columns}
                                            dataSource={this.state.dataSource} pagination={false} />
                                    </div>
                                </Form.Item>
                                {
                                    this.state.attrTime.length === 0 ? (
                                        <Form.Item label="价格及库存">
                                            {getFieldDecorator('current_price', {
                                                rules: [{
                                                    required: true,
                                                    message: "请输入商品价格!"
                                                }]
                                            })(
                                                <Table rowKey={(record, index) => index} pagination={false} columns={this.state.info_columns}
                                                    dataSource={[this.state.info]} />
                                            )}
                                        </Form.Item>
                                    ) : null
                                }
                                <Form.Item label="划线价">
                                    {getFieldDecorator('original_price', {
                                        rules: []
                                    })(
                                        <Input placeholder="输入价格" />
                                    )}
                                </Form.Item>
                                <Form.Item label="佣金百分比">
                                    {getFieldDecorator('promotion_amount_rate', {
                                        initialValue: cookie.load("mini_app_type") == "flagship_version" ? 0 : null,
                                        rules: [{
                                            required: true,
                                            message: "请输入佣金百分比!"
                                        }]
                                    })(
                                        <Input
                                            disabled={cookie.load("mini_app_type") == "flagship_version" ? true : false}
                                            placeholder="请输入佣金百分比" />
                                    )}
                                </Form.Item>
                            </Form>

                        </Collapse.Panel>
                        <Collapse.Panel header={
                            <div className="panel-title">
                                物流设置(必填)
                                <span className="panel-desc">展开/折叠</span>
                            </div>
                        } key="4">
                            <Form>
                                <Form.Item label="邮费设置">
                                    {getFieldDecorator('shipping_fee_type', {
                                        initialValue: "direct_fee",
                                        rules: [{
                                            required: true
                                        }]
                                    })(
                                        <Radio.Group onChange={this.radioChange}>
                                            <Radio className="radio-block" value={"direct_fee"}>统一邮费:<Input
                                                value={this.state.directFee} onChange={this.ChangeDirectFee.bind(this)}
                                                disabled={this.props.form.getFieldsValue().shipping_fee_type == "template_fee" ? true : false}
                                                className="shipment-fee" />元</Radio>
                                            <Radio className="radio-block" value={"template_fee"}>运费模版:
                                                <Select className="shipment-fee"
                                                    value={this.state.template && this.state.template.shipping_template_id}
                                                    onChange={this.ShippingFeeTYpeChange.bind(this)}
                                                    disabled={this.props.form.getFieldsValue().shipping_fee_type == "direct_fee" ? true : false}>
                                                    {
                                                        this.state.templateList.map((item, index) => (
                                                            <Select.Option key={index}
                                                                value={item.shipping_template_id}>{item.shipping_fee_type == "number" ? "计件" : "计重"}:{item.shipping_template_name}</Select.Option>
                                                        )
                                                        )
                                                    }
                                                </Select>
                                                <span className="text-note"
                                                    onClick={this.queryShippingTemplateList}>刷新</span>
                                                <Divider type="vertical" />
                                                <span className="text-note" onClick={this.gotoTemplate}>新建</span>
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <Form.Item label="商品重量"
                                    style={{ display: this.state.template && this.state.template.shipping_fee_type == "weight" ? true : "none" }}>
                                    {getFieldDecorator('shipment_weight_type', {
                                        initialValue: 1,
                                        rules: [{
                                            required: true
                                        }]
                                    })(
                                        <Radio.Group>
                                            <Radio className="radio-block" value={1}>
                                                所有商品规格统一重量(KG):<Input
                                                    disabled={this.props.form.getFieldsValue().shipment_weight_type == "2" ? true : false}
                                                    className="shipment-fee" value={this.state.allWeight}
                                                    onChange={this.ChangeAllWeight.bind(this)} />
                                            </Radio>
                                            <Radio className="radio-block" value={2}
                                                style={{ display: this.state.dataSource.length ? true : "none" }}>
                                                不同商品规格单独设置重量:
                                            </Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Form>
                            <Table rowKey={(record, index) => index} columns={this.state.weightColumns}
                                dataSource={this.state.dataSource}
                                pagination={false}
                                style={{ display: this.state.attrTime.length > 0 && this.props.form.getFieldsValue().shipping_fee_type == "template_fee" && this.state.template && this.state.template.shipping_fee_type == "weight" && this.props.form.getFieldsValue().shipment_weight_type == "2" ? true : "none" }} />
                        </Collapse.Panel>
                        <Collapse.Panel header={
                            <div className="panel-title">
                                上架设置(必填)
                                <span className="panel-desc">展开/折叠</span>
                            </div>
                        } key="5">
                            <Form>
                                <Form.Item>
                                    {getFieldDecorator('on_sale', {
                                        initialValue: 1,
                                        rules: [{
                                            required: true
                                        }]
                                    })(
                                        <Radio.Group>
                                            <Radio value={1}>立即上架</Radio>
                                            <Radio value={2}>暂不上架</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Form>

                        </Collapse.Panel>
                        <Collapse.Panel header={
                            <div className="panel-title">
                                更多设置(选填)
                                <span className="panel-desc">展开/折叠</span>
                            </div>
                        } key="6">
                            <Form>
                                <Form.Item>
                                    {getFieldDecorator('limit_flag', {
                                        initialValue: false

                                    })(
                                        <Checkbox checked={this.props.form.getFieldsValue().limit_flag}>限购
                                            <span
                                                style={{ display: this.props.form.getFieldsValue().limit_flag ? true : "none" }}> 每人最多购买 <Input
                                                    value={this.state.buy_limit} onChange={this.changeBuyLimit.bind(this)}
                                                    className="buy-limit" />件</span>
                                        </Checkbox>
                                    )}
                                </Form.Item>
                            </Form>
                        </Collapse.Panel>
                    </Collapse>
                    <div className="goods-add-button-box">
                        <Button shape="round" type="primary"
                            style={{ display: this.props.form.getFieldValue("on_sale") == 2 ? true : "none", margin: 0 }}
                            onClick={this.submitGoods.bind(this, 0)}>
                            {cookie.load("mini_app_type") == "flagship_version" ? <span>开团并保存</span> : <span>保存</span>}
                        </Button>
                        <Button shape="round" type="primary"
                            style={{ display: this.props.form.getFieldValue("on_sale") == 1 ? true : "none" }}
                            onClick={this.submitGoods.bind(this, 1)}>
                            开团并上架
                        </Button>
                    </div>
                </div>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
                <GoodsGroupAdd
                    visible={this.state.visible}
                    goods_id={this.state.goods_id}
                    handleFalse={this.handleClose}></GoodsGroupAdd>
                <GroupGoodAdd visible={this.state.goods_visible} goodsOk={this.goodsOk}
                    onCancel={this.goodsVisibleChange} />
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode

    }
}
const goodsAddForm = Form.create({ name: 'goods_add' })(GoodsAdd);

export default connect(mapStateToProps)(goodsAddForm);
