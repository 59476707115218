import { Button, Divider, message, Modal, Switch, Table, Icon } from 'antd';
import React, { Component } from 'react';
import '../scss/gradeManage.scss';
import PropTypes from "prop-types";
import { getData } from '@service/getData';
import cookie from 'react-cookies';

// 勾选了需要用户信息的最低等级
let editUserInfoLevel = null;
class Grade extends Component {
    constructor() {
        super();
        Grade.contextTypes = {
            router: PropTypes.object.isRequired,
        }
        this.state = {
            isOpen: false,
            columns: [
                {
                    title: '会员等级',
                    dataIndex: 'level',
                },
                {
                    title: '名称',
                    dataIndex: 'level_name',
                },
                {
                    title: '获得等级条件',
                    dataIndex: 'condition',
                    render: (text) => (
                        <pre>{text}</pre>
                    )
                },
                {
                    title: '会员权益',
                    dataIndex: 'rights',
                    render: (text) => (
                        <pre>{text}</pre>
                    )
                },
                {
                    title: '礼包内容',
                    dataIndex: 'upgrade_package_info',
                    render: (text) => (
                        <pre>{text}</pre>
                    )
                },
                {
                    title: '启用状态',
                    dataIndex: 'status',
                    render: (text) => (
                        <span>{text === 1 ? '已启用' : text === 0 ? '待配置' : '已停用'}</span>
                    )
                },
                {
                    title: '卡面',
                    dataIndex: 'background_color',
                    render: (text, record) => {
                        if (record.background_url) {
                            return (
                                <div style={{ backgroundImage: `url(${record.background_url})` }} className="color bac"></div>
                            )
                        }
                        return (
                            <div style={{ backgroundColor: text }} className="color"></div>
                        )
                    }
                },
                {
                    title: '会员数',
                    dataIndex: 'member_num',
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    render: (text, record, index) => (
                        <span>
                            {record.status !== 0 ? (
                                <>
                                    <span onClick={this.goEdit.bind(this, record.vip_grade_id, record.level, index)} className="text-note">编辑</span>
                                    <Divider type="vertical" />
                                </>
                            ) : null}
                            {(record.status !== 0 && ((index === this.state.dataSource.length - 1) || (this.state.dataSource[index + 1].status !== 1)) && (index === 0 || (this.state.dataSource[index - 1].status !== 2))) ? (
                                <>
                                    <span className="text-note" onClick={this.onChangeThisLevelStatus.bind(this, record.status, record.level, record.vip_grade_id)}>{record.status === 1 ? '停用' : '启用'}</span>
                                    <Divider type="vertical" />
                                </>
                            ) : null}
                            {(record.status === 0 && ((index === 0) || (this.state.dataSource[index - 1].status === 1))) ? (
                                <span onClick={this.goEdit.bind(this, record.vip_grade_id, record.level, index)} className="text-note">待配置</span>
                            ) : null}
                        </span>
                    )
                },
            ],
            dataSource: [],
            visible: false,
            modalTitle: '',
            modalText: '',
            showNum: null,
            tempShowNum: null,
            okBtnEventType: null,
            vip_system_id: null,
            // 停用，启用时的等级对应的id
            vip_grade_id: null,
            // 停用，启用时的等级对应的当前状态
            level_status: null,
            // 是否更新完成
            syncStatus: null,
            update_status: false, //是否更新了规则
        };
    }

    componentDidMount() {
        this.init();
    }
    async init() {
        const url = '/vip/level/list';
        const data = {
            mini_app_id: cookie.load('mini_app_id'),
        };
        let res = await getData(url, data);
        if (res.success && res.result) {
            const theItem = res.result.list.find((item, index) => item.need_user_info);
            theItem && (editUserInfoLevel = theItem.level);
            let showNum = -1;
            res.result.list.every((item) => {
                showNum++;
                return item.status !== 0;
            });
            this.setState({
                dataSource: res.result.list,
                isOpen: res.result.vip_system_status === 1 ? true : false,
                vip_system_id: res.result.vip_system_id,
                showNum,
                tempShowNum: showNum,
                syncStatus: res.result.sync_status,
                update_status: res.result.update_status
            });
        }
    }
    goEdit(vip_grade_id, level, index) {
        let lastLevelGrowth;
        if (index === 0) {
            lastLevelGrowth = -1;
        } else {
            lastLevelGrowth = this.state.dataSource[index - 1].growth_value;
        }
        this.context.router.history.push(`/editLevel?vip_system_id=${this.state.vip_system_id}&vip_grade_id=${vip_grade_id}&level=${level}&editUserInfoLevel=${editUserInfoLevel}&lastLevelGrowth=${lastLevelGrowth}`);
    }
    async onOk() {
        if (this.state.okBtnEventType === 'switch') {
            const url = '/vip/system/changeState';
            const data = {
                vip_system_id: this.state.vip_system_id,
                status: this.state.isOpen ? 2 : 1,
            };
            let res = await getData(url, data);
            if (res.success) {
                message.success(`正在${this.state.isOpen ? '关闭' : '开启'}会员体系，开启和关闭需要时间重新计算等级，请等待`);
                this.setState({
                    isOpen: !this.state.isOpen,
                }, () => {
                    this.state.isOpen && (
                        this.setState({
                            syncStatus: 2,
                        })
                    )
                });
            }
        }
        if (this.state.okBtnEventType === 'stopLevel') {
            const url = '/vip/level/createOrUpdate';
            const data = {
                vip_grade_id: this.state.vip_grade_id,
                vip_system_id: this.state.vip_system_id,
                status: this.state.level_status === 1 ? 2 : 1,
            };
            let res = await getData(url, data);
            if (res.success) {
                message.success(`正在${this.state.level_status === 1 ? '停用' : '启用'}该会员等级，启用和停用需要时间重新计算等级，请等待`);
                this.init();
            }
        }
        this.colseModal();
    }

    onSwitchChange(checked) {
        const openText = '开启会员后，所有客户将立即按新的配置重新计算等级';
        const closeText = '关闭会员后，会员等级相关功能将失效';
        this.setState({
            modalTitle: checked ? '启用会员提示' : '关闭会员提示',
            modalText: checked ? openText : closeText,
            okText: checked ? '启用' : '关闭',
            okBtnEventType: 'switch',
        }, () => {
            this.openModal();
        });
    }
    openModal() {
        this.setState({
            visible: true,
        });
    }
    colseModal() {
        this.setState({
            visible: false,
        });
    }
    onChangeShowNum() {
        this.setState((preState) => ({
            showNum: preState.showNum > preState.tempShowNum ? preState.tempShowNum : 9999,
        }));
    }

    onChangeThisLevelStatus(status, level, vip_grade_id) {
        const openText = `启用等级${level}后，会员等级将重新计算。原会员等级可能发生变动，将在一段时间内完成。`;
        const closeText = '停用等级将对已有会员造成影响，如需停用请提前公告，以免造成不必要客诉，请谨慎操作。';
        this.setState({
            modalTitle: status === 1 ? '停用等级' : '启用等级',
            modalText: status === 1 ? closeText : openText,
            okText: status === 1 ? '停用' : '启用',
            okBtnEventType: 'stopLevel',
            vip_grade_id,
            level_status: status,
        }, () => {
            this.openModal();
        });
    }

    async onPublish() {
        const url = '/vip/system/publish';
        const data = {
            vip_system_id: this.state.vip_system_id,
        };
        let res = await getData(url, data);
        if (res.success) {
            message.success('正在更新，需要时间');
            this.setState({
                syncStatus: 2,
            });
        }
    }

    render() {
        return (
            <div className="grade">
                <Switch checked={this.state.isOpen} onChange={this.onSwitchChange.bind(this)} />
                <span className="openStatus">{this.state.isOpen ? '会员等级已开启' : '会员等级已关闭'}</span>
                <Button loading={this.state.syncStatus !== 0} disabled={this.state.syncStatus !== 0} className="updateBtn" shape="round" type="primary" onClick={this.onPublish.bind(this)}>{this.state.syncStatus === 0 ? '更新等级规则' : '更新中...'}</Button>
                {this.state.update_status?
                    (<p className="updateBtnTip red">你已手动修改了等级规则，如需小程序端生效，请更新等级规则</p>)
                    :
                    (<p className="updateBtnTip">修改过以下等级规则后，点击更新才能应用到小程序端</p>)
                }
                <Table
                    rowKey={(record, index) => index}
                    rowClassName={(record, index) => {
                        if (index <= this.state.showNum) {
                            return 'show'
                        }
                        return 'hidden'
                    }}
                    columns={this.state.columns}
                    dataSource={this.state.dataSource}
                    bordered
                    pagination={false}
                />
                {this.state.tempShowNum >= 9 ? null : (
                    <p className="expendAll" onClick={this.onChangeShowNum.bind(this)}>
                        <span style={{ marginRight: '6px' }}>{this.state.showNum > this.state.tempShowNum ? '收起' : '展开所有等级'}</span>
                        {this.state.showNum > this.state.tempShowNum ? (
                            <Icon type="up-circle" theme="filled" />
                        ) : (
                                <Icon type="down-circle" theme="filled" />
                            )}
                    </p>
                )}
                <Modal
                    visible={this.state.visible}
                    title={this.state.modalTitle}
                    okText={this.state.okText}
                    cancelText="取消"
                    onCancel={this.colseModal.bind(this)}
                    onOk={this.onOk.bind(this)}
                    okButtonProps={{
                        shape: "round"
                    }}
                    cancelButtonProps={{
                        shape: "round"
                    }}
                >
                    <p className="modalText">{this.state.modalText}</p>
                </Modal>
            </div>
        );
    }
}

export default Grade;