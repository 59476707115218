import React from 'react'
import NavBar from '@/view/component/common/NavBar.js'
import {connect} from "react-redux";
import {Switch} from 'antd'
import '@css/activityTypeSetting.scss'
import {queryCampaignActivitySubtype,createOrUpdateCampaignActivitySubtype} from '@service/getData'
import {Table} from 'antd'
class ActivityTypeSetting extends React.Component{
    constructor(){
        super()
        this.state={
            columns:[
                {
                    title: '活动类型',
                    dataIndex: 'activity_subtype',
                },
                {
                    title: '活动名称',
                    dataIndex: 'activity_subtype_name',
                },
                {
                    title: '活动描述',
                    dataIndex: 'activity_subtype_desc',

                },
                {
                    title: 'app_id',
                    dataIndex: 'app_id',

                },
                {
                    title: '自动初始化',
                    dataIndex: 'auto_create',
                    render:(text,record)=>(
                        <Switch
                            defaultChecked={Boolean(record.auto_create)}
                            onChange={this.createOrUpdateCampaignActivitySubtype.bind(this,"auto_create",record)} />
                    )
                },
                {
                    title: '个体户版',
                    dataIndex: 'base_version',

                    render:(text,record)=>(
                        <Switch
                            defaultChecked={Boolean(record.base_version)}
                            onChange={this.createOrUpdateCampaignActivitySubtype.bind(this,"base_version",record)} />
                    )
                },
                {
                    title: '商家版',
                    dataIndex: 'special_version',
                    render:(text,record)=>(
                        <Switch
                            defaultChecked={Boolean(record.special_version)}
                            onChange={this.createOrUpdateCampaignActivitySubtype.bind(this,"special_version",record)} />
                    )
                },
                {
                    title: '品牌版',
                    dataIndex: 'flagship_version',
                    render:(text,record)=>(
                        <Switch
                            defaultChecked={Boolean(record.flagship_version)}
                            onChange={this.createOrUpdateCampaignActivitySubtype.bind(this,"flagship_version",record)} />
                    )
                }
            ],
            data_source:[]
        }
    }
    queryCampaignActivitySubtype=async ()=>{
        let res=await queryCampaignActivitySubtype({})
        if(res.success){
            this.setState({
                data_source:res.result.campaign_activity_subtype_res_list
            })
        }
    }
    createOrUpdateCampaignActivitySubtype=async(index,record,bol)=>{
        let data={
            campaignActivitySubtypeReq:{
                campaign_activity_subtype_id:record.campaign_activity_subtype_id
            }
        }
        data.campaignActivitySubtypeReq[index]=bol?1:0;
        let res=await createOrUpdateCampaignActivitySubtype(data);
        if(res.success){
            this.queryCampaignActivitySubtype()
        }

    }
    componentDidMount(){
        this.queryCampaignActivitySubtype()
    }
    render(){
        let { menu_code } = this.props
        return (
            <div className="activity-type-setting">
                <NavBar icon="icon-guanliyuanshezhi"  child={menu_code.activity_type_setting}/>
                <div className="content">
                    <div className="shopDataTitle">
                        <i className="titleSign"></i>
                        活动类型管理
                    </div>
                    <Table  rowKey={(record,index) => index}
                            columns={this.state.columns}
                            dataSource={this.state.data_source}/>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        menu_code:state.menuCode
    }
}
export default connect(mapStateToProps) (ActivityTypeSetting);