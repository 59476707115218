import React from "react";
import ColorPicker from "@/view/component/common/ColorPicker";
import IconChoose from "@/view/component/common/IconChoose";
import { Form, message, Icon, Button } from "antd";
import "@css/shopNav.scss";
import BannerContent from "../../activitySetting/children/BannerContent";
import TargetTypeModal from "@/view/component/Campaign/TargetTypeModal";
import cookie from "react-cookies";
import { createOrUpdateBannerActivity, queryMiniAppPageConfigList, queryActivityDetail } from "@service/getData";
class ShopNav extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      target_type_visible: false,
      nav_list: [],
      property: null,
      activity_list: [],
      icon_visible: false,
      target_type_desc: {
        lucky: "抽奖活动",
        checkin: "签到",
        pointsStore: "积分商城",
        free_order: "抢免单",
        rank_lucky: '线上拼团',
      },
      system_pages: {
        homePages: "店铺首页",
        categoryPages: "分类页面",
        shopcarPages: "购物车",
        myPages: "个人中心",
        vip_center: '会员中心',
      },
      head_back_color: null,
      head_font_color: null
    };
  }
  handleOK = color => {
    if (this.state.property === "core_color" && (color.toLowerCase() === "#ffffff" || color.toLowerCase() === "#fff")) {
      return message.error("不能选择白色");
    }
    this.props.form.setFieldsValue({ [this.state.property]: color });
    this.setState({
      visible: false
    });
  };
  handleIconChoose = (thumbnail_url, image_url) => {
    let activity_list = this.state.activity_list.concat();
    activity_list[this.state.current_index].thumbnail_url = thumbnail_url;
    activity_list[this.state.current_index].image_url = image_url;
    this.setState({
      icon_visible: false
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false,
      icon_visible: false
    });
  };
  showModal = property => {
    this.setState({
      visible: true,
      property
    });
  };
  eidtActivityList = (index, status) => {
    let activity_list = this.state.activity_list.concat();
    let activity = activity_list[index];
    if (status === 0) {
      if (activity.activity_id) {
        activity_list[index].status = 0;
      } else {
        activity_list.splice(index, 1);
      }
    } else if (status === 1) {
      activity_list.push({
        mini_app_id: cookie.load("mini_app_id"),
        activity_name: null,
        activity_type: "shopGuide",
        activity_subtype: "guide",
        target_type: null,
        status: 1,
        exchange_disabled: false,
        desc: null
      });
    }
    activity_list.forEach((item, zindex) => {
      item.index = zindex;
    });
    this.setState({
      activity_list
    });
  };
  onOk = data => {
    let activity_list = this.state.activity_list.concat();
    activity_list[this.state.current_index].target_type = data.target_type;
    switch (data.target_type) {
      case "campaign":
        activity_list[this.state.current_index].dest_campaign_id = data.dest_campaign_id;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      case "goods":
        activity_list[this.state.current_index].goods_id = data.goods_id;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      case "tag":
        activity_list[this.state.current_index].tag_id = data.tag_id;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      case "roomInfo":
        activity_list[this.state.current_index].room_info_id = data.room_info_id;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      case "activity_goods":
        activity_list[this.state.current_index].activity_goods_id = data.activity_goods_id;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      case "coupon":
        activity_list[this.state.current_index].coupon_id = data.coupon_id;
        activity_list[this.state.current_index].dest_coupon_id = data.coupon_id;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      case "customPages":
        activity_list[this.state.current_index].path_url = data.path_url;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      default:
        activity_list[this.state.current_index].desc = data.desc;
        break;
    }
    this.setState({
      target_type_visible: false,
      activity_list
    });
  };
  onCancel = () => {
    this.setState({
      target_type_visible: false
    });
  };
  queryMiniAppPageConfigList = async () => {
    let data = {
      mini_app_id: cookie.load("mini_app_id"),
      config_type: "style"
    };
    let res = await queryMiniAppPageConfigList(data);
    if (res.success) {
      let head_back_color, head_font_color;
      res.result.mini_app_page_config_res_list.forEach(item => {
        if (item.config_name === "head_back_color") {
          head_back_color = item.page_config_value;
        } else if (item.config_name === "head_font_color") {
          head_font_color = item.page_config_value;
        }
      });
      this.setState({
        head_back_color,
        head_font_color
      });
    }
  };
  showTargetTypeModal = current_index => {
    this.setState({
      target_type_visible: true,
      current_index
    });
  };
  showIconChooseModal = current_index => {
    this.setState({
      icon_visible: true,
      current_index
    });
  };
  createOrUpdateBannerActivity = async () => {
    let form = this.props.form.getFieldsValue();
    let flag = false;

    this.props.form.validateFields(err => {
      if (err) {
        flag = true;
      }
    });
    if (flag) return;
    this.state.activity_list.forEach(item => {
      if (!item.thumbnail_url) {
        flag = true;
      }
      for (let key in item) {
        if (key === "target_type") {
          switch (item[key]) {
            case "goods":
              if (!item.goods_id && !item.goods_res.goods_id) {
                flag = true;
              }
              break;
            case "campaign":
              if (!item.dest_campaign_id) {
                flag = true;
              }
              break;
            case "goodsList":
              if (!item.tag_id) {
                flag = true;
              }
              break;
            case "":
              flag = true;
              break;
            default:
              break;
          }
        }
      }
    });
    if (flag) {
      return message.error("信息填写不全");
    }
    this.state.activity_list.forEach((item, index) => {
      item.sort_num = index;
    });
    let data = {
      activity_req: {
        activity_id: this.state.activity ? this.state.activity.activity_id : null,
        mini_app_id: cookie.load("mini_app_id"),
        activity_type: "shopGuide",
        activity_subtype: "guideList",
        status: 1,
        theme_color: form.theme_color,
        icon_choose_color: form.icon_choose_color,
        icon_no_choose_color: form.icon_no_choose_color,
        activity_req_list: this.state.activity_list
      }
    };
    let res = await createOrUpdateBannerActivity(data);
    if (res.success) {
      message.success("修改成功");
      this.queryActivityDetail();
    }
  };
  queryActivityDetail = async () => {
    let data = {
      mini_app_id: cookie.load("mini_app_id"),
      activity_type: "shopGuide",
      activitySubtype: "guideList"
    };
    let res = await queryActivityDetail(data);
    if (res.success) {
      let activity = res.result.activity_res;
      let { icon_choose_color, theme_color, icon_no_choose_color } = activity;
      this.props.form.setFieldsValue({ icon_choose_color });
      this.props.form.setFieldsValue({ theme_color });
      this.props.form.setFieldsValue({ icon_no_choose_color });

      let activity_list = activity.activity_res_list.map((item, index) => {
        switch (item.target_type) {
          case "campaign":
            item.desc = `店铺页面:${item.campaign_res.campaign_name}`;
            break;
          case "goods":
            item.desc = `商品:${item.goods_res.goods_name}`;
            break;
          case "tag":
            item.desc = `商品列表页:${item.tag_name}`;
            break;
          case "coupon":
            item.desc = `优惠券:${item.dest_coupon_res.coupon_name}`;
            break;
          case "activity_goods":
            item.desc = `活动商品:${item.dest_activity_goods_res.goods_res.goods_name}`;
            break;
          case "roomInfo":
            item.desc = `直播间:${item.dest_room_info_res.name}`;
            break;
          case "customPages":
            item.desc = `自定义路径${item.path_url}`;
            break;
          default:
            if (this.state.target_type_desc[item.target_type]) {
              item.desc = `营销活动:${this.state.target_type_desc[item.target_type]}`;
            } else {
              item.desc = `系统页面:${this.state.system_pages[item.target_type]}`;
            }
            break;
        }
        return {
          ...item,
          index
        };
      });
      this.setState({
        activity,
        activity_list
      });
    }
  };
  deleteTargetType = index => {
    let activity_list = this.state.activity_list.concat();
    activity_list[index].target_type = "";

    this.setState({
      activity_list
    });
  };
  submitForm = (index, key, value) => {
    let activity_list = this.state.activity_list.concat();
    activity_list[index][key] = value;
    if (key === "gallery_list") {
      let gallery_url = null;
      value.forEach(item => {
        if (item.response && item.response.success) {
          gallery_url = item.response.result.oss_file_url;
        } else if (item.url) {
          gallery_url = item.url;
        }
      });
      activity_list[index].thumbnail_url = gallery_url;
    }
    if (key === "gallery_list2") {
      let gallery_url = null;
      value.forEach(item => {
        if (item.response && item.response.success) {
          gallery_url = item.response.result.oss_file_url;
        } else if (item.url) {
          gallery_url = item.url;
        }
      });
      activity_list[index].image_url = gallery_url;
    }
    activity_list.forEach((item, zindex) => {
      item.index = zindex;
    });
    this.setState({
      activity_list
    });
  };
  sortActivityList = (index, position) => {
    let activity_list = this.state.activity_list.concat();
    if (position === -1) {
      activity_list.splice(index - 1, 1, ...activity_list.splice(index, 1, activity_list[index - 1]));
    } else if (position === 1) {
      activity_list.splice(index, 1, ...activity_list.splice(index + 1, 1, activity_list[index]));
    } else {
      activity_list.splice(0, 0, ...activity_list.splice(index, 1));
    }
    activity_list.forEach((item, zindex) => {
      item.index = zindex;
    });
    this.setState({
      activity_list
    });
  };
  // UNSAFE_componentWillReceiveProps() {
  //   this.queryMiniAppPageConfigList();
  // }
  componentDidMount() {
    this.queryMiniAppPageConfigList();
    this.queryActivityDetail();
  }
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="shop-nav">
        <div className="phone">
          <div style={{ backgroundColor: this.state.head_back_color, color: this.state.head_font_color }} className="phone-title">
            <span>页面标题</span>
          </div>
          <div className="phone-tab-bar" style={{ background: this.props.form.getFieldValue("theme_color") }}>
            {this.state.activity_list.map((item, index) => (
              <div key={index} style={{ color: this.props.form.getFieldValue("icon_no_choose_color") }}>
                <i className={`iconfont ${item.image_url}`} style={{ color: this.props.form.getFieldValue("icon_no_choose_color") }} />
                <div className="title">{item.activity_name}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="phone-content">
          <div className="phone-content-title">
            <span>店铺导航</span>
            <span className="text-danger">最多添加5个导航</span>
            <span className="go-to">查看教程 →</span>
          </div>
          <div className="nav-color">
            <Form>
              <Form.Item label="未选中颜色">
                {getFieldDecorator("icon_no_choose_color", {
                  rules: [
                    {
                      required: true,
                      message: "请选择颜色!"
                    }
                  ]
                })(
                  <div className="color-choose" onClick={this.showModal.bind(this, "icon_no_choose_color")}>
                    <span className="color-desc">{this.props.form.getFieldValue("icon_no_choose_color")}</span>
                    <span className="color" style={{ background: this.props.form.getFieldValue("icon_no_choose_color") }} />
                    <Icon className="color-picker-icon" type="caret-down" />
                  </div>
                )}
              </Form.Item>
              <Form.Item label="选中颜色">
                {getFieldDecorator("icon_choose_color", {
                  rules: [
                    {
                      required: true,
                      message: "请选择颜色!"
                    }
                  ]
                })(
                  <div className="color-choose" onClick={this.showModal.bind(this, "icon_choose_color")}>
                    <span className="color-desc">{this.props.form.getFieldValue("icon_choose_color")}</span>
                    <span className="color" style={{ background: this.props.form.getFieldValue("icon_choose_color") }} />
                    <Icon className="color-picker-icon" type="caret-down" />
                  </div>
                )}
              </Form.Item>
              <Form.Item label="背景颜色">
                {getFieldDecorator("theme_color", {
                  rules: [
                    {
                      required: true,
                      message: "请选择颜色!"
                    }
                  ]
                })(
                  <div className="color-choose" onClick={this.showModal.bind(this, "theme_color")}>
                    <span className="color-desc">{this.props.form.getFieldValue("theme_color")}</span>
                    <span className="color" style={{ background: this.props.form.getFieldValue("theme_color") }} />
                    <Icon className="color-picker-icon" type="caret-down" />
                  </div>
                )}
              </Form.Item>
            </Form>
          </div>
          {this.state.activity_list
            .filter(item => item.status === 1)
            .map((item, index) => (
              <BannerContent
                deleteTargetType={this.deleteTargetType}
                key={index}
                list_key={index}
                title={`导航`}
                submitForm={this.submitForm}
                gallery_list={item.gallery_list}
                gallery_list2={item.gallery_list2}
                activity_goods_list={item.activity_goods_list}
                target_type={item.target_type}
                eidtActivityList={this.eidtActivityList}
                activity_id={item.activity_id}
                exchange_disabled={item.exchange_disabled}
                index={item.index}
                showTargetTypeModal={this.showTargetTypeModal}
                showIconChooseModal={this.showIconChooseModal}
                length={this.state.activity_list.filter(qitem => qitem.status === 1).length}
                sortActivityList={this.sortActivityList}
                desc={item.desc}
                activity_subtype={item.activity_subtype}
                activity_name={item.activity_name}
                thumbnail_url={item.thumbnail_url}
                image_url={item.image_url}
              />
            ))}
          <Button
            type="dashed"
            disabled={this.state.activity_list.filter(item => item.status === 1).length === 5}
            className="button-activity-goods"
            onClick={this.eidtActivityList.bind(this, 0, 1)}>
            +添加导航
          </Button>
          <div className="action-save">
            <Button type="primary" className="btn-save" onClick={this.createOrUpdateBannerActivity}>
              提交
            </Button>
          </div>
        </div>
        <TargetTypeModal visible={this.state.target_type_visible} onOK={this.onOk} onCancel={this.onCancel} />
        <ColorPicker visible={this.state.visible} onOk={this.handleOK} onCancel={this.handleCancel} />
        <IconChoose visible={this.state.icon_visible} onOk={this.handleIconChoose} onCancel={this.handleCancel} />
      </div>
    );
  }
}
const ShopNavForm = Form.create({ name: "shop-nav-form" })(ShopNav);

export default ShopNavForm;
