import { Card, Switch, Form, Modal, Upload, Button, message, Tooltip } from 'antd';
import React, { Component } from 'react';
import './centerStyle.scss';
import UploadButton from "@/view/component/common/UploadButton";
import config from '@config/config';
import { getData } from '@service/getData';
import cookie from 'react-cookies';
import BannerContent from "../../activitySetting/children/BannerContent";
import IconChoose from "@/view/component/common/IconChoose";
import TargetTypeModal from "@/view/component/Campaign/TargetTypeModal";

class CartStyle extends Component {
    constructor() {
        super();
        this.state = {
            requestUrl: config.baseUrl + "/ossFile/upload",
            bacImgFileList: [],
            posterFileList: [],
            previewImage: '',
            previewVisible: false,
            activity_id: null,
            thumbnail_url: '',
            poster: '',
            centerBacImage: {},
            show: 0,
            vip_service_enable: false,
            activity_list: [],
            target_type_visible: false,
        };
    }


    deleteTargetType = index => {
        let activity_list = this.state.activity_list.concat();
        activity_list[index].target_type = '';
        this.setState({
            activity_list
        });
    };

    submitForm = (index, key, value) => {
        let activity_list = this.state.activity_list.concat();
        activity_list[index][key] = value;
        if (key === "gallery_list") {
            let gallery_url = null;
            value.forEach(item => {
                if (item.response && item.response.success) {
                    gallery_url = item.response.result.oss_file_url;
                } else if (item.url) {
                    gallery_url = item.url;
                }
            });
            activity_list[index].thumbnail_url = gallery_url;
        }
        if (key === "gallery_list2") {
            let gallery_url = null;
            value.forEach(item => {
                if (item.response && item.response.success) {
                    gallery_url = item.response.result.oss_file_url;
                } else if (item.url) {
                    gallery_url = item.url;
                }
            });
            activity_list[index].image_url = gallery_url;
        }
        activity_list.forEach((item, qindex) => {
            item.index = qindex;
        });
        this.setState({
            activity_list
        });
    };

    eidtActivityList = (index, status) => {
        let activity_list = this.state.activity_list.concat();
        if (status === 0) {
            activity_list[index] = { ...activity_list[index], status: 0 }
        } else if (status === 1) {
            activity_list.push({
                mini_app_id: cookie.load("mini_app_id"),
                activity_name: null,
                target_type: null,
                activity_goods_list: [],
                status: 1,
                thumbnail_url: null,
                image_url: null,
                goods_id: null,
                exchange_disabled: false,
                dest_campaign_id: null,
                desc: null,
                index,
            });
        }
        this.setState({
            activity_list
        });
    };

    showTargetTypeModal = current_index => {
        this.setState({
            target_type_visible: true,
            current_index
        });
    };

    showIconChooseModal = current_index => {
        this.setState({
            icon_visible: true,
            current_index
        });
    };

    sortActivityList = (index, position) => {
        let activity_list = this.state.activity_list.concat();
        if (position === -1) {
            activity_list.splice(index - 1, 1, ...activity_list.splice(index, 1, activity_list[index - 1]));
        } else if (position === 1) {
            activity_list.splice(index, 1, ...activity_list.splice(index + 1, 1, activity_list[index]));
        } else {
            activity_list.splice(0, 0, ...activity_list.splice(index, 1));
        }
        activity_list.forEach((item, zindex) => {
            item.index = zindex;
        });
        this.setState({
            activity_list
        });
    };

    onOk = data => {
        let activity_list = this.state.activity_list.concat();
        activity_list[this.state.current_index].target_type = data.target_type;
        switch (data.target_type) {
            case "campaign":
                activity_list[this.state.current_index].dest_campaign_id = data.dest_campaign_id;
                activity_list[this.state.current_index].desc = data.desc;
                break;
            case "goods":
                activity_list[this.state.current_index].goods_id = data.goods_id;
                activity_list[this.state.current_index].desc = data.desc;
                break;
            case "tag":
                activity_list[this.state.current_index].tag_id = data.tag_id;
                activity_list[this.state.current_index].desc = data.desc;
                break;
            case "roomInfo":
                activity_list[this.state.current_index].room_info_id = data.room_info_id;
                activity_list[this.state.current_index].desc = data.desc;
                break;
            case "activity_goods":
                activity_list[this.state.current_index].activity_goods_id = data.activity_goods_id;
                activity_list[this.state.current_index].desc = data.desc;
                break;
            case "coupon":
                activity_list[this.state.current_index].coupon_id = data.coupon_id;
                activity_list[this.state.current_index].dest_coupon_id = data.coupon_id;
                activity_list[this.state.current_index].desc = data.desc;
                break;
            case "customPages":
                activity_list[this.state.current_index].path_url = data.path_url;
                activity_list[this.state.current_index].desc = data.desc;
                break;
            default:
                activity_list[this.state.current_index].desc = data.desc;
                break;
        }
        this.setState({
            target_type_visible: false,
            activity_list
        });
    };

    onCancel = () => {
        this.setState({
            target_type_visible: false
        });
    };

    handleIconChoose = (thumbnail_url, image_url) => {
        let activity_list = this.state.activity_list.concat();
        activity_list[this.state.current_index].thumbnail_url = thumbnail_url;
        activity_list[this.state.current_index].image_url = image_url;
        this.setState({
            icon_visible: false
        });
    };

    handIconleCancel = () => {
        this.setState({
            visible: false,
            icon_visible: false
        });
    }

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl || file,
            previewVisible: true,
        });
    }

    handleBacChange = ({ fileList }) => {
        let thumbnail_url;
        let centerBacImage = {};
        // 删除图片的时候，fileList为空数组。做兼容。
        if (fileList.length) {
            let last = fileList[fileList.length - 1]
            last.url = last.response && last.response.result && last.response.result.oss_file_url;
            thumbnail_url = last.url;
            centerBacImage.backgroundImage = `url(${thumbnail_url})`;
        } else {
            thumbnail_url = '';
        }
        this.setState({
            bacImgFileList: [...fileList],
            centerBacImage,
            thumbnail_url,
        });
    }

    handlePosterChange = ({ fileList }) => {
        let poster = this.state.poster;
        // 删除图片的时候，fileList为空数组。做兼容。
        if (fileList.length) {
            let last = fileList[fileList.length - 1]
            last.url = last.response && last.response.result && last.response.result.oss_file_url;
            poster = last.url;
        }
        this.setState({
            posterFileList: [...fileList],
            poster,
        });
    }

    handleCancel = () => {
        this.setState({
            previewVisible: false
        })
    }
    onSwitchChange(checked) {
        this.setState({
            show: checked ? 1 : 0,
        });
    }
    onVipSwitchChange(checked) {
        this.setState({
            vip_service_enable: checked,
        }, () => {
            this.onVipSwitch(checked);
        });
    }

    async onVipSwitch(checked) {
        const url = '/vip/updateServiceEnable';
        const data = {
            mini_app_id: cookie.load('mini_app_id'),
            status: checked ? 1 : 2,
        };
        const res = await getData(url, data);
        if (res.success) {
            message.success(checked ? '已开启会员服务' : '已关闭会员服务');
        }
    }

    async getData() {
        const data = {
            mini_app_id: cookie.load('mini_app_id'),
            target_type: 'myPages',
        };
        const url = '/activity/querySystemPage';
        const res = await getData(url, data);
        if (res.success) {
            let activity_id = this.state.activity_id;
            let thumbnail_url = this.state.thumbnail_url;
            if (res.result.activity_res) {
                activity_id = res.result.activity_res.activity_id;
                thumbnail_url = res.result.activity_res.thumbnail_url;
            }
            let bacImgFileList = [];
            let posterFileList = [];
            let centerBacImage = {};
            if (res.result.poster) {
                posterFileList = [
                    {
                        uid: 0,
                        name: res.result.poster,
                        status: 'done',
                        url: res.result.poster,
                    }
                ];
            }
            if (thumbnail_url) {
                bacImgFileList = [
                    {
                        uid: 0,
                        name: thumbnail_url,
                        status: 'done',
                        url: thumbnail_url,
                    }
                ];
                centerBacImage = {
                    backgroundImage: `url(${thumbnail_url})`,
                };
            }
            res.result.vip_service_config_list.forEach((item, index) => {
                let gallery_list;
                if (item.icon_url) {
                    gallery_list = [
                        {
                            uid: "-1",
                            name: "xxx.png",
                            status: "done",
                            url: item.icon_url
                        }
                    ];
                }
                item.gallery_list = gallery_list;
                item.activity_name = item.title;
                item.index = index;
                item.sort_no = index;
            });
            this.setState({
                activity_id,
                thumbnail_url,
                poster: res.result.poster,
                bacImgFileList,
                posterFileList,
                show: res.result.show,
                centerBacImage,
                activity_list: res.result.vip_service_config_list,
                vip_service_enable: res.result.vip_service_enable,
            });
        }
    }
    async onSubmit() {
        if (this.state.show && !this.state.poster) {
            message.error('请上传公众号海报图');
            return;
        }
        const data = {
            mini_app_id: cookie.load('mini_app_id'),
            target_type: 'myPages',
            poster: this.state.poster,
            show: this.state.show,
            activity_req: {
                thumbnail_url: this.state.thumbnail_url,
                activity_id: this.state.activity_id,
            },
        };
        const url = '/activity/modifySystemPage';
        const list = this.state.activity_list.map((item) => {
            return {
                ...item,
                icon_url: item.thumbnail_url,
                title: item.activity_name,
                sort_no: item.index,
            }
        });
        const vipurl = '/vip/updateServiceList';
        const vipdata = {
            mini_app_id: cookie.load('mini_app_id'),
            list,
        };
        const res = await getData(url, data);
        const vipres = await getData(vipurl, vipdata);
        if (res.success && vipres.success) {
            message.success('保存成功', () => {
                this.getData();
            });
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="centerStyle">
                <div className="left">
                    <img width="375" src="http://udh.oss-cn-hangzhou.aliyuncs.com/e6a06f7a-13a0-4a26-8508-d2d75857208e" alt="" />
                    <div className="centerBac" style={this.state.centerBacImage}>
                        <div className="avatar">头像</div>
                        <span className="nick">用户昵称</span>
                    </div>
                    <img className="negativeMargin" width="375" src="http://udh.oss-cn-hangzhou.aliyuncs.com/d294999c-9128-4e31-9b93-8b7df9cae12516055186476219.png" alt="" />
                    {this.state.vip_service_enable && this.state.activity_list.length ? (
                        <div className="vipServiceShow">
                            {this.state.activity_list.map((item, index) => (
                                <div key={index} className="vipItem">
                                    <img className="vipIcon" src={item.icon_url} alt="" />
                                    <span className="vipText">{item.title}</span>
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <img className="widthJus" src="http://udh.oss-cn-hangzhou.aliyuncs.com/91bdceef-109d-4c45-8bbf-0ff6c48dae6e16055186476794.png" alt="" />
                    <img width="375" src="http://udh.oss-cn-hangzhou.aliyuncs.com/25c21927-d3e9-4281-9c39-97cba91ac5ff" alt="" />
                </div>
                <div className="right">
                    <p className="hightLight">个人中心设置</p>
                    <Card
                        title="头部背景图"
                    >
                        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} labelAlign="left">
                            <Form.Item
                                label="背景图片"
                                extra="建议宽度375，高度180，仅支持jpg,png2种格式，且只能上传一张，小于2M"
                            >
                                <Upload
                                    accept="image/jpeg,image/jpg,image/png"
                                    withCredentials={true}
                                    action={this.state.requestUrl}
                                    listType="picture-card"
                                    fileList={this.state.bacImgFileList}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleBacChange}
                                >
                                    {this.state.bacImgFileList.length > 0 ? null : <UploadButton />}
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Card>
                    <Card
                        title={
                            <span>
                                关注公众号
                                <Tooltip title={
                                    <img width="200" src="http://udh.oss-cn-hangzhou.aliyuncs.com/b732c7fe-6384-4bdb-8c40-8ae2ed49deca编组 7.png" />
                                }>
                                    <span className="shili">示例</span>
                                </Tooltip>
                            </span>
                        }
                        extra={<Switch checked={this.state.show === 0 ? false : true} onChange={this.onSwitchChange.bind(this)}></Switch>}
                    >
                        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} labelAlign="left">
                            <Form.Item
                                label="公众号海报"
                                extra="建议宽度375，高度不限，仅支持jpg,png2种格式，且只能上传一张，小于2M"
                            >
                                <Upload
                                    accept="image/jpeg,image/jpg,image/png"
                                    withCredentials={true}
                                    action={this.state.requestUrl}
                                    listType="picture-card"
                                    fileList={this.state.posterFileList}
                                    onPreview={this.handlePreview}
                                    onChange={this.handlePosterChange}
                                >
                                    {this.state.posterFileList.length > 0 ? null : <UploadButton />}
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Card>
                    <div className="vipService">
                        <span>会员服务</span>
                        <Switch checked={this.state.vip_service_enable} onChange={this.onVipSwitchChange.bind(this)}></Switch>
                    </div>
                    {this.state.activity_list
                        .filter(item => item.status === 1)
                        .map((item, index) => (
                            <BannerContent
                                deleteTargetType={this.deleteTargetType}
                                key={index}
                                list_key={index}
                                title='服务'
                                uploadText="图标"
                                submitForm={this.submitForm}
                                gallery_list={item.gallery_list}
                                target_type={item.target_type}
                                eidtActivityList={this.eidtActivityList}
                                index={item.index}
                                showTargetTypeModal={this.showTargetTypeModal}
                                length={this.state.activity_list.filter(qitem => qitem.status === 1).length}
                                sortActivityList={this.sortActivityList}
                                desc={item.desc}
                                activity_subtype='vip'
                                activity_name={item.activity_name}
                                path_url={item.path_url}
                            />
                        ))}
                    <Button
                        type="dashed"
                        disabled={this.state.activity_list.filter(item => item.status === 1).length === 8}
                        className="button-activity-goods"
                        onClick={this.eidtActivityList.bind(this, this.state.activity_list.length, 1)}>
                        +添加服务
                    </Button>
                    <Button onClick={this.onSubmit.bind(this)} className="submit" type="primary">提交</Button>
                </div>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel.bind(this)}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
                <TargetTypeModal visible={this.state.target_type_visible} onOK={this.onOk} onCancel={this.onCancel} />
                <IconChoose visible={this.state.icon_visible} onOk={this.handleIconChoose} onCancel={this.handIconleCancel} />
            </div>
        );
    }
}

export default CartStyle;