import React from "react";
import { message, Button, Card, Form, Radio, Carousel } from "antd/lib/index";
import "@css/activity.scss";
import BannerContent from "./BannerContent";
import TargetTypeModal from "@/view/component/Campaign/TargetTypeModal";
import cookie from "react-cookies";
import {
  queryActivityDetail,
  queryGoodsList,
  queryCampaignActivitySubtype,
  queryCampaignList,
  queryMiniAppDetail,
  createOrUpdateImageAdActivity,
  createOrUpdateBannerActivity
} from "@service/getData";
import config from "@config/config";
// swiper.js
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// install Swiper components
SwiperCore.use([Autoplay, Pagination])

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      loading2: false,
      requestUrl: config.baseUrl + "/ossFile/upload",
      campaign_activity_subtype_res_list: [],
      visible: false,
      search_value: null,
      goods_res_list: [],
      selected_row_keys: [],
      select_goods_res: [],
      current_select_goods_res: [],
      start_time: null,
      end_time: null,
      activity_id: null,
      activity_res: {},
      exchange_disabled: false,
      loading: false,
      campaign_id: null,
      campaign_list: [],
      activity_list: [],
      current_index: null,
      target_type_desc: {
        lucky: "抽奖活动",
        checkin: "签到",
        pointsStore: "积分商城",
        free_order: "抢免单",
        rank_lucky: '线上拼团',
      },
      system_pages: {
        homePages: "店铺首页",
        categoryPages: "分类页面",
        shopcarPages: "购物车",
        myPages: "个人中心",
        vip_center: '会员中心',
      },
      ad_type: 1,
      banner_interval: 3,
      pattern_type: 1,
    };
  }

  async queryMiniAppDetail() {
    let mini_app_id = cookie.load("mini_app_id");
    let res = await queryMiniAppDetail({
      mini_app_id
    });
    if (res.success) {
      this.setState({
        mini_app_name: res.result.mini_app.mini_app_name
      });
    }
  }

  queryActivityDetail = async activity_id => {
    let data = {
      activity_id
    };
    let res = await queryActivityDetail(data);
    if (res.success) {
      let activity_list = res.result.activity_res.activity_res_list;
      let template_type = res.result.activity_res.template_type || 1;
      let banner_interval = res.result.activity_res.banner_interval || 3;
      const pattern_type = res.result.activity_res.pattern_type || 1;
      if (activity_list.length) {
        activity_list.forEach((item, index) => {
          item.activity_goods_list = item.activity_goods_res_list;
          item.exchange_disabled = false;
          item.index = index;
          let gallery_list = [];
          if (item.thumbnail_url) {
            gallery_list = [
              {
                uid: "-1",
                name: "xxx.png",
                status: "done",
                url: item.thumbnail_url
              }
            ];
          }
          item.gallery_list = gallery_list;
          item.activity_goods_list = item.activity_goods_res_list.map(citem => {
            return {
              goods_id: citem.goods_res.goods_id,
              goods_name: citem.goods_res.goods_name,
              current_price: citem.goods_res.current_price,
              start_time: citem.start_time,
              end_time: citem.end_time,
              activity_id: citem.activity_id,
              activity_goods_id: citem.activity_goods_id,
              status: 1,
              gallery_urls: citem.goods_res.gallery_urls,
              loading: false
            };
          });
          if (item.target_type === "goods") {
            item.activity_goods_list = [item.goods_res];
            item.goods_id = item.goods_res.goods_id;
          }
          switch (item.target_type) {
            case "campaign":
              item.desc = `店铺页面:${item.campaign_res.campaign_name}`;
              break;
            case "goods":
              item.desc = `商品:${item.goods_res.goods_name}`;
              break;
            case "tag":
              item.desc = `商品列表页:${item.tag_name}`;
              break;
            case "coupon":
              item.desc = `优惠券:${item.dest_coupon_res.coupon_name}`;
              break;
            case "activity_coupon":
              item.desc = `满减/满赠:${item.dest_coupon_res.coupon_name}`;
              break;
            case "activity_goods":
              item.desc = `活动商品:${item.dest_activity_goods_res.goods_res.goods_name}`;
              break;
            case "roomInfo":
              item.desc = `直播间:${item.dest_room_info_res.name}`;
              break;
            case "customPages":
              item.desc = `自定义路径:${item.path_url}`;
              break;
            default:
              if (this.state.target_type_desc[item.target_type]) {
                item.desc = `营销活动:${this.state.target_type_desc[item.target_type]}`;
              } else {
                item.desc = `系统页面:${this.state.system_pages[item.target_type]}`;
              }
              break;
          }
        });
      }
      this.setState(
        {
          activity_res: res.result.activity_res,
          ad_type: template_type,
          banner_interval,
          pattern_type,
          loading: false,
          activity_list
        },
        () => {
          if (activity_list.length === 0 && this.props.activity_subtype === "advertisement") {
            this.eidtActivityList(0, 1);
          }
        }
      );
    }
  };
  queryGoodsList = async () => {
    this.setState({
      loading2: true
    });
    let data = {
      shop_id: cookie.load("shop_id"),
      onsale: 1
    },
      value = this.state.search_value;
    if (isNaN(+value)) {
      data.goods_name = value;
    } else {
      data.goods_id = value;
    }
    let res = await queryGoodsList(data);
    if (res.success) {
      this.setState({
        loading2: false,
        goods_res_list: res.result.goods_res_list
      });
    }
  };
  createOrUpdateBannerActivity = async () => {
    let flag = false;
    let activity_list = this.state.activity_list.concat([]);
    activity_list.forEach(item => {
      item.campaign_id = this.props.campaign_id;
      item.title = this.state.mini_app_name;
      item.activity_subtype = this.props.activity_subtype === "iconLinkList" ? "iconLink" : this.props.activity_subtype;
      item.transfer_title = this.state.mini_app_name;
      if (!item.thumbnail_url) {
        flag = true;
      }
      for (let key in item) {
        if (key === "target_type") {
          switch (item[key]) {
            case "goods":
              if (!item.goods_id) {
                flag = true;
              }
              break;
            case "campaign":
              if (!item.dest_campaign_id) {
                flag = true;
              }
              break;
            case "goodsList":
              if (!item.tag_id) {
                flag = true;
              }
              break;
            case "coupon":
              if (!item.coupon_id) {
                flag = true;
              }
              break;
            case "activity_coupon":
              if (!item.coupon_id) {
                flag = true;
              }
              break;
            case "roomInfo":
              if (!item.room_info_id) {
                flag = true;
              }
              break;

            default:
              break;
          }
        }
      }
    });
    if (flag) {
      return message.error("信息填写不全");
    }
    activity_list.forEach((item, index) => {
      item.sort_num = index;
    });
    let data = {
      activity_req: {
        mini_app_id: cookie.load("mini_app_id"),
        campaign_id: this.props.campaign_id,
        activity_type: "campaign",
        activity_name: this.props.activity_subtype === "scroll" ? "banner" : "图文导航",
        activity_subtype: this.props.activity_subtype,
        status: 1,
        activity_req_list: activity_list,
        activity_id: this.state.activity_res ? this.state.activity_res.activity_id : null,
        banner_interval: this.state.banner_interval,
        pattern_type: this.state.pattern_type,
      }
    };
    let res = "";
    if (this.props.activity_subtype === "advertisement") {
      data.activity_req.template_type = this.state.ad_type;
      data.activity_req.activity_name = "图片广告";
      res = await createOrUpdateImageAdActivity(data);
    } else {
      res = await createOrUpdateBannerActivity(data);
    }
    if (res.success) {
      if (this.state.activity_res && this.state.activity_res.activity_id) {
        message.success("组件更新成功");
      } else {
        message.success("成功添加组件");
      }
      this.props.onOK();
    }
  };
  handleCancel = () => this.setState({ visible: false });
  handleOk = () => {
    let select_goods_res = [...this.state.select_goods_res],
      current_select_goods_res = [...this.state.current_select_goods_res];
    select_goods_res.forEach(item => {
      let index = current_select_goods_res.findIndex(row => row.goods_id === item.goods_id);
      if (index > -1) {
        current_select_goods_res.splice(index, 1);
      }
    });
    select_goods_res = select_goods_res.concat(current_select_goods_res);
    this.setState({
      select_goods_res: select_goods_res,
      visible: false,
      exchange_disabled: true
    });
  };
  searchHandle = e => {
    this.setState({
      search_value: e.target.value
    });
  };
  showModal = () => {
    this.queryGoodsList();
    this.setState({
      visible: true
    });
  };
  onSelectedRowKeysChange = (selected_row_keys, record) => {
    this.setState(
      {
        selected_row_keys: selected_row_keys,
        current_select_goods_res: record
      },
      () => {
        if (record.length === 1 && this.props.form.getFieldValue("target_type") === "goods") {
          this.handleOk();
        }
      }
    );
  };
  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  queryCampaignList = async () => {
    let data = {
      mini_app_id: cookie.load("mini_app_id")
    };
    let res = await queryCampaignList(data);
    if (res.success) {
      let campaign_list = res.result.campaign_res_list;
      campaign_list = campaign_list.filter(item => item.campaign_id !== Number(this.state.campaign_id));
      this.setState({
        campaign_list
      });
    }
  };
  confirmOk = () => {
    this.props.onOk();
  };
  queryCampaignActivitySubtype = async () => {
    let data = {};
    let res = await queryCampaignActivitySubtype(data);
    if (res.success) {
      this.setState({
        campaign_activity_subtype_res_list: res.result.campaign_activity_subtype_res_list,
        loading: this.state.type === "edit" ? true : false
      });
    }
  };
  eidtActivityList = (index, status, addNum = 1) => {
    let activity_list = this.state.activity_list.concat();
    let activity = activity_list[index];
    if (status === 0) {
      if (activity.activity_id) {
        activity_list[index].status = 0;
      } else {
        activity_list.splice(index, 1);
      }
    } else if (status === 1) {
      for (let i = 0; i < addNum; i++) {
        activity_list.push({
          mini_app_id: cookie.load("mini_app_id"),
          activity_name: this.props.activity_subtype === "banner" ? "banner" : null,
          activity_type: "campaign",
          activity_subtype: this.props.activity_subtype === "iconLinkList" ? "iconLink" : this.props.activity_subtype,
          target_type: null,
          activity_goods_list: [],
          status: 1,
          thumbnail_url: null,
          image_url: null,
          goods_id: null,
          exchange_disabled: false,
          dest_campaign_id: null,
          desc: null
        });
      }
    }
    activity_list.forEach((item, index) => {
      item.index = index;
    });
    this.setState({
      activity_list
    });
  };
  sortActivityList = (index, position) => {
    let activity_list = this.state.activity_list.concat();
    if (position === -1) {
      activity_list.splice(index - 1, 1, ...activity_list.splice(index, 1, activity_list[index - 1]));
    } else if (position === 1) {
      activity_list.splice(index, 1, ...activity_list.splice(index + 1, 1, activity_list[index]));
    } else {
      activity_list.splice(0, 0, ...activity_list.splice(index, 1));
    }
    activity_list.forEach((item, index) => {
      item.index = index;
    });
    this.setState({
      activity_list
    });
  };
  submitForm = (index, key, value) => {
    let activity_list = this.state.activity_list.concat();
    activity_list[index][key] = value;
    if (key === "gallery_list") {
      let gallery_url = null;
      value.forEach(item => {
        if (item.response && item.response.success) {
          gallery_url = item.response.result.oss_file_url;
        } else if (item.url) {
          gallery_url = item.url;
        }
      });
      activity_list[index].thumbnail_url = gallery_url;
    }
    if (key === "gallery_list2") {
      let gallery_url = null;
      value.forEach(item => {
        if (item.response && item.response.success) {
          gallery_url = item.response.result.oss_file_url;
        } else if (item.url) {
          gallery_url = item.url;
        }
      });
      activity_list[index].image_url = gallery_url;
    }
    activity_list.forEach((item, index) => {
      item.index = index;
    });
    this.setState({
      activity_list
    });
  };
  componentDidMount() {
    this.queryCampaignActivitySubtype();
    this.queryCampaignList();
    this.queryMiniAppDetail();
    this.props.onRef("banner", this);
  }
  onOk = data => {
    let activity_list = this.state.activity_list.concat();
    activity_list[this.state.current_index].target_type = data.target_type;
    switch (data.target_type) {
      case "campaign":
        activity_list[this.state.current_index].dest_campaign_id = data.dest_campaign_id;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      case "goods":
        activity_list[this.state.current_index].goods_id = data.goods_id;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      case "tag":
        activity_list[this.state.current_index].tag_id = data.tag_id;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      case "roomInfo":
        activity_list[this.state.current_index].room_info_id = data.room_info_id;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      case "activity_goods":
        activity_list[this.state.current_index].activity_goods_id = data.activity_goods_id;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      case "coupon":
        activity_list[this.state.current_index].coupon_id = data.coupon_id;
        activity_list[this.state.current_index].dest_coupon_id = data.coupon_id;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      case "activity_coupon":
        activity_list[this.state.current_index].coupon_id = data.coupon_id;
        activity_list[this.state.current_index].dest_coupon_id = data.coupon_id;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      case "customPages":
        activity_list[this.state.current_index].path_url = data.path_url;
        activity_list[this.state.current_index].desc = data.desc;
        break;
      default:
        activity_list[this.state.current_index].desc = data.desc;
        break;
    }
    this.setState({
      visible: false,
      activity_list
    });
  };
  deleteTargetType = index => {
    let activity_list = this.state.activity_list.concat();
    activity_list[index].target_type = "";

    this.setState({
      activity_list
    });
  };
  onCancel = () => {
    this.setState({
      visible: false
    });
  };
  showTargetTypeModal = current_index => {
    this.setState({
      visible: true,
      current_index
    });
  };
  onScrollSpaceChange = item => {
    let value = +item.target.value;
    this.setState({
      banner_interval: value
    });
  };
  onScrollStyleChange(e) {
    const value = e.target.value;
    this.setState({
      pattern_type: value,
    });
  }
  onAdStyleChange = item => {
    let value = +item.target.value,
      n_ad_num = value === 4 ? 3 : value,
      o_ad_num = this.state.ad_type === 4 ? 3 : this.state.ad_type;
    this.changeAdModel(n_ad_num - o_ad_num);
    this.setState({
      ad_type: value
    });
  };
  changeAdModel(change_num) {
    if (change_num === 0) return;
    if (change_num > 0) {
      this.eidtActivityList(0, 1, Math.abs(change_num));
    } else {
      let activity_list = this.state.activity_list.concat();
      activity_list.splice(change_num, Math.abs(change_num));
      activity_list.forEach((item, index) => {
        item.index = index;
      });
      this.setState({
        activity_list
      });
    }
  }
  render() {
    const adPlainOptions = [
      { id: 1, name: "默认一个" },
      { id: 2, name: "一行两个" },
      { id: 3, name: "一行三个" },
      { id: 4, name: "一上两下" }
    ];
    const scrollPlainOptions = [
      { id: 3, name: "3秒" },
      { id: 4, name: "4秒" },
      { id: 5, name: "5秒" }
    ];
    return (
      <div className="banner-add">
        {this.props.activity_subtype === "advertisement" ? (
          <Card className="card-info">
            <Form>
              <Form.Item label="选择样式">
                <Radio.Group name="ad_type_group" onChange={this.onAdStyleChange} value={this.state.ad_type}>
                  {adPlainOptions.map(item => (
                    <Radio key={item.id} value={item.id} style={{ marginBottom: "6px" }}>
                      {item.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <div className={"demo-image-list type" + this.state.ad_type}>
                <div className="demo-image-item">图片1</div>
                {this.state.ad_type > 1 ? <div className="demo-image-item">图片2</div> : null}
                {this.state.ad_type > 2 ? <div className="demo-image-item">图片3</div> : null}
              </div>
            </Form>
          </Card>
        ) : this.props.activity_subtype === "scroll" ? (
          <Card className="card-info">
            <Form>
              <Form.Item label="选择样式">
                <Radio.Group onChange={this.onScrollStyleChange.bind(this)} value={this.state.pattern_type}>
                  <Radio value={1}>样式一</Radio>
                  <Radio value={2}>样式二</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="选择轮播时间">
                <Radio.Group name="srcoll_type_group" onChange={this.onScrollSpaceChange} value={this.state.banner_interval}>
                  {scrollPlainOptions.map(item => (
                    <Radio key={item.id} value={item.id} style={{ marginBottom: "6px" }}>
                      {item.name}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Form>
            {this.state.pattern_type === 1 ? (
              <Carousel
                autoplay
                className="bannerSchematicDiagram"
              >
                <div>1</div>
                <div>2</div>
                <div>3</div>
              </Carousel>
            ) : (
                <Swiper
                  slidesPerView={3}
                  grabCursor={true}
                  autoplay={true}
                  centeredSlides={true}
                  centeredSlidesBounds={true}
                  spaceBetween={-23}
                  loop={true}
                  pagination={{ clickable: true }}
                >
                  <SwiperSlide>1</SwiperSlide>
                  <SwiperSlide>2</SwiperSlide>
                  <SwiperSlide>3</SwiperSlide>
                </Swiper>
              )}
          </Card>
        ) : null}
        {this.state.activity_list
          .filter(item => item.status === 1)
          .map((item, index) => (
            <BannerContent
              deleteTargetType={this.deleteTargetType}
              key={index}
              list_key={index}
              title={`${this.props.activity_subtype === "scroll"
                ? "banner"
                : this.props.activity_subtype === "advertisement"
                  ? "图片广告"
                  : "导航图片"
                }`}
              uploadText={`${this.props.activity_subtype === "scroll"
                ? "banner"
                : this.props.activity_subtype === "advertisement"
                  ? "图片广告"
                  : "导航图片"
                }`}
              submitForm={this.submitForm}
              gallery_list={item.gallery_list}
              gallery_list2={item.gallery_list2}
              activity_goods_list={item.activity_goods_list}
              target_type={item.target_type}
              eidtActivityList={this.eidtActivityList}
              activity_id={item.activity_id}
              exchange_disabled={item.exchange_disabled}
              campaign_list={this.state.campaign_list}
              dest_campaign_id={item.dest_campaign_id}
              index={item.index}
              showTargetTypeModal={this.showTargetTypeModal}
              length={this.state.activity_list.filter(item => item.status === 1).length}
              sortActivityList={this.sortActivityList}
              desc={item.desc}
              activity_subtype={this.props.activity_subtype}
              activity_name={item.activity_name}
              path_url={item.path_url}
            />
          ))}
        {this.props.activity_subtype !== "advertisement" ? (
          <Button
            type="dashed"
            disabled={
              (this.state.activity_list.filter(item => item.status === 1).length === 5 && this.props.activity_subtype === "iconLinkList") ||
              (this.state.activity_list.filter(item => item.status === 1).length === 10 && this.props.activity_subtype === "scroll")
            }
            className="button-activity-goods"
            onClick={this.eidtActivityList.bind(this, 0, 1, 1)}>
            +添加一张{this.props.activity_subtype === "scroll" ? "banner" : "导航"}图
          </Button>
        ) : null}
        <div className="action-save">
          <Button type="primary" className="btn-save" onClick={this.createOrUpdateBannerActivity}>
            提交
          </Button>
        </div>
        <TargetTypeModal visible={this.state.visible} onOK={this.onOk} onCancel={this.onCancel} />
      </div>
    );
  }
}

export default Banner;
