import React, { Component } from 'react';
import { Popover, Icon } from 'antd';
import '../../scss/popOverTips.scss';

class PopOverTips extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <div className="popOverTips" style={{display: this.props.styleType === 'inner' ? 'inline-block' : 'block'}}>
                <span className="innerText">{this.props.inners}</span>
                <Popover placement="right" content={this.props.content}>
                    <Icon className="quesIcon" type="question-circle" theme="filled" />
                </Popover>
            </div>
        );
    }
}

export default PopOverTips;