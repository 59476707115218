import React from 'react'
import '@css/appOrder.scss'
import AppOrderItem from './children/AppOrderItem'
import {connect} from "react-redux";
import NavBar from "@/view/component/common/NavBar.js";
class AppOrder extends  React.Component{
    constructor(){
        super();
        this.state={

        }
    }
    render(){
        let {menu_code}= this.props
        return (
            <div className="app-order">
                <NavBar
                    icon="icon-shezhi"
                    child={menu_code.verson_manage}
                />
                <div className="content">
                    <div className="app-version">
                        {
                            this.props.app_version.map((item,index)=>
                                <AppOrderItem key={index} app={item}/>
                            )
                        }
                    </div>
                </div>

            </div>
        )
    }


}
const mapStateToProps = state => {
    return {
        menu_code: state.menuCode,
        app_version: state.appVersion
    };
};


export default connect(mapStateToProps) (AppOrder);