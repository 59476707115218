import React from 'react'
import { Modal, Form, Table } from 'antd'
import '@css/refundMoneyModal.scss'

class RefundOrderMoneyModal extends React.Component {
    constructor() {
        super()
        this.state = {
            columns: [
                {

                    title: '商品名称',
                    dataIndex: 'goods_name',
                    width: "250px",
                    render: (text, record) => (
                        <p>
                            {record.goods_name}
                        </p>
                    )
                }, {

                    title: '规格',
                    width: "100px",
                    dataIndex: 'sku',

                }, {
                    title: '数量',
                    width: "90px",
                    dataIndex: 'quantity',
                    render: (text) => (
                        <p>{text}件</p>
                    )
                }, {
                    title: '价格',
                    width: "95px",
                    dataIndex: 'current_price',
                    render: (text,) => (
                        <p>¥{text}</p>
                    )
                },
                {
                    title: '总价',
                    width: "90px",
                    dataIndex: 'total',
                    render: (text, record) => (
                        <div>
                            <p>¥{record.current_price * record.quantity}</p>
                        </div>
                    )
                }
            ],
        }
    }

    render() {
        const modalProps = {};
        this.props.hideBtn && (modalProps.footer = null);
        return (
            <Modal
                title="售后退款 "
                visible={this.props.visible}
                onOk={this.props.handleOk.bind(this, this.state.refund_money)}
                onCancel={this.props.handleCancel}
                destroyOnClose={true}
                className="refound-modal"
                okButtonProps={{ shape: 'round' }}
                cancelButtonProps={{ shape: 'round' }}
                {...modalProps}
            >
                <p className="refundMoney">申请退款金额：{this.props.refund_amount} {this.props.refund_ship_fee ? `+运费${this.props.refund_ship_fee}` : null}</p>
                <Table
                    rowKey={(record, index) => index}
                    rowClassName={(record) => {
                        if (!record.in_cur_refund_order) {
                            return 'notCut';
                        }
                        return null;
                    }}
                    columns={this.state.columns}
                    dataSource={this.props.order_goods_list}
                    pagination={false}
                    bordered={true}
                />
                <footer>
                    <span>商品总价：￥{this.props.original_price}</span>
                    <span>运费：￥{this.props.shipment_fee ? this.props.shipment_fee : 0}</span>
                    <span>订单优惠：￥{this.props.discount_amount ? this.props.discount_amount : 0}</span>
                    <span>订单金额：￥{this.props.pay_amount}</span>
                    {this.props.already_refund_amount ? (
                        <span>已退{this.props.already_refund_amount}元</span>
                    ) : null}
                </footer>
            </Modal>
        )
    }

}

const RefundModalForm = Form.create({ name: 'refund_modal_form' })(RefundOrderMoneyModal);

export default RefundModalForm;
