import React from 'react'
import { connect } from "react-redux";
import NavBar from '@/view/component/common/NavBar.js'
import '@css/dataStatistics.scss'
import { Table, Pagination, DatePicker, Icon } from 'antd'
import { queryLinkDailyStatsList, queryAdminOrderList } from '@service/getData'
import config from "@config/config";
import { formatDate } from '@common/common'
import echarts from "echarts/lib/echarts";
import 'echarts/lib/chart/line';
import 'echarts/lib/component/legend';
import cookie from 'react-cookies';
class DataStatistics extends React.Component {
    constructor() {
        super()
        this.state = {
            request_url: config.baseUrl + "/link/exportLinkDailyStatsList",
            order_request_url: config.baseUrl + "/order/exportAdminOrderList",
            columns: [
                {
                    title: '时间',
                    dataIndex: 'stats_date',
                    render: (text) => {
                        return formatDate(new Date(text).getTime(), "Y-M-D")
                    }
                }, {
                    title: '到达浏览量',
                    dataIndex: 'page_view_count'
                }, {
                    title: '到达访客数',
                    dataIndex: 'unique_visitor_count'
                }, {
                    title: '新访客数',
                    dataIndex: 'new_visitor_count'
                }, {
                    title: '页面跳出率',
                    dataIndex: 'bounce_rate'
                },
            ],
            orderColumns: [
                {
                    title: '订单号',
                    dataIndex: 'order_sn',
                },
                {
                    title: '商品名称',
                    dataIndex: 'goods_name',
                    render: (value, row) => (
                        <span>{row.order_goods_res_list[0].goods_res.goods_name}</span>
                    )
                },
                {
                    title: '单价（元）/数量',
                    dataIndex: 'current_price',
                    render: (value, row) => (
                        <div>
                            <div>¥{row.order_goods_res_list[0].goods_res.current_price / 100}</div>
                            <div>{row.quantity}件</div>
                        </div>
                    )
                },
                {
                    title: '下单时间',
                    dataIndex: 'order_time'
                },
                {
                    title: '买家/收件人',
                    dataIndex: 'nick_name',
                    render: (value, row) => (
                        <div>
                            <div>{row.user_res.nick_name}</div>
                            <div>{row.user_res.mobile}</div>
                        </div>
                    )
                },
                {
                    title: '支付金额',
                    dataIndex: 'pay_amount',
                    render: (text) => (
                        <span>{(text / 100).toFixed(2)}</span>
                    )
                },
                {
                    title: '订单状态',
                    dataIndex: 'order_status',
                    render: (value) => (
                        <span>{this.props.order_status_list[value].order_status_short_desc}</span>
                    )
                },
            ],
            orderList: [],
            data: [],
            option: {},
            link_res: {},
            link_daily_stats_res_list: [],
            page: 1,
            page_size: 10,
            total: 0,
            date: [],
            target_type_desc: {
                lucky: "抽奖活动",
                checkin: "签到",
                pointsStore: "积分商城",
                free_order: "抢免单",
                rank_lucky: '线上拼团',
            },
            system_pages: {
                homePages: '店铺首页',
                categoryPages: '分类页面',
                shopcarPages: '购物车',
                myPages: '个人中心',
                vip_center: '会员中心',
            },
            orderPage: 1,
            orderPageSize: 10,
            orderTotal: 0,
            start_order_time: null,
            end_order_time: null,
        }
    }
    queryLinkDailyStatsList = async () => {
        let data = {
            link_id: this.props.location.state,
            create_at_small: this.state.date.length > 0 ? this.state.date[0] : null,
            create_at_big: this.state.date.length > 0 ? this.state.date[1] : null
        }
        let res = await queryLinkDailyStatsList(data)
        if (res.success) {
            let { link_res, link_daily_stats_res_list } = res.result

            switch (link_res.target_type) {
                case 'campaign':
                    link_res.desc = `店铺页面:${link_res.campaign_res.campaign_name}`
                    break;
                case 'goods':
                    link_res.desc = `商品:${link_res.goods_res.goods_name}`
                    break;
                case 'tag':
                    link_res.desc = `商品列表页:${link_res.tag_res.tag_name}`
                    break;
                case 'coupon':
                    link_res.desc = `优惠券:${link_res.coupon_res.coupon_name}`
                    break;
                case 'activity_coupon':
                    link_res.desc = `满减/满赠:${link_res.dest_coupon_res.coupon_name}`
                    break;
                case 'activity_goods':
                    link_res.desc = `活动商品:${link_res.dest_activity_goods_res.goods_res.goods_name}`
                    break;
                case 'roomInfo':
                    link_res.desc = `直播间:${link_res.dest_room_info_res.name}`
                    break;
                case "customPages":
                    link_res.desc = `自定义页面`;
                    break;
                default:
                    if (this.state.target_type_desc[link_res.target_type]) {
                        link_res.desc = `营销活动:${this.state.target_type_desc[link_res.target_type]}`
                    } else {
                        link_res.desc = `系统页面:${this.state.system_pages[link_res.target_type]}`
                    }
                    break;
            }
            let data = link_daily_stats_res_list.map(item => {
                return formatDate(new Date(item.stats_date).getTime(), "M/D")
            })
            let page_view_count = link_daily_stats_res_list.map(item => {
                return item.page_view_count
            })
            let unique_visitor_count = link_daily_stats_res_list.map(item => {
                return item.unique_visitor_count
            })
            let option = {
                legend: {
                    show: true,
                    left: 'center',
                    icon: "rect",
                    data: ['到达浏览量', '到达访客数']
                },
                xAxis: {
                    data: data,
                    axisLabel: {
                        interval: 0,
                        rotate: -30,//倾斜度 -90 至 90 默认为0
                        margin: 10,
                        color: "#555555"
                    },
                },
                yAxis: {
                    type: 'value',
                    name: '人数(人)',
                    axisLabel: {
                        color: "#555555"
                    },
                    axisLine: {
                        "show": false

                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        }
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: 'rgba(255,255,255,0.9)',
                    extraCssText: 'box-shadow:0px 0px 4px rgba(0,0,0,0.16)',
                    textStyle: {
                        width: 160,
                        color: '#555555',
                        fontSize: 14
                    },
                    formatter: function (params) {
                        var str = '';

                        str += '<div style="width:160px;margin-bottom:5px">' + params[0].name + '</div>'; //显示日期的函数

                        for (var i = 0; i < params.length; i++) { //图显示的数据较多时需要循环数据，这样才可以成功的给多条数据添加单位

                            str += '<span style="display:inline-block;margin-right:5px;border-radius:4px;width:4px;height:4px;background-color:#0D87EB"></span><span style="margin-right:5px">' + params[i].seriesName + '</span><span>' + params[i].data + '</span>';

                        }
                        return str;
                    }
                },
                series: [
                    {
                        name: '到达浏览量',
                        type: 'line',
                        smooth: true,
                        data: page_view_count
                    },
                    {
                        name: '到达访客数',
                        type: 'line',
                        smooth: true,
                        data: unique_visitor_count
                    },
                ]
            }
            var myChart = echarts.init(document.getElementById('main'));
            myChart.setOption(option, true);
            this.setState({
                link_res,
                link_daily_stats_res_list,
                total: link_daily_stats_res_list.length,
                option
            })
        }
    }
    onShowPageChange = (page) => {
        this.setState({
            orderPage: page
        }, () => {
            this.queryAdminOrderList();
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            orderPageSize: pageSize
        }, () => {
            this.queryAdminOrderList();
        })
    }
    onShowTimeChange = (date, dateStr) => {
        this.setState({
            date: dateStr
        }, () => {
            this.queryLinkDailyStatsList()
        })
    }
    async queryAdminOrderList() {
        const data = {
            page_no: this.state.orderPage,
            page_size: this.state.orderPageSize,
            shop_id: cookie.load('shop_id'),
            link_id: this.props.location.state,
            start_order_time: this.state.start_order_time,
            end_order_time: this.state.end_order_time,
        }
        const res = await queryAdminOrderList(data);
        if (res.success) {
            this.setState({
                orderList: res.result.order_res_list,
                orderPage: res.result.page_no,
                orderPageSize: res.result.page_size,
                orderTotal: res.result.total,
            });
        }
    }
    onOrderShowTimeChange(data, dateStr) {
        this.setState({
            start_order_time: dateStr[0],
            end_order_time: dateStr[1],
        }, () => {
            this.queryAdminOrderList();
        });
    }
    componentDidMount() {
        if (this.props.location.state) {
            this.queryLinkDailyStatsList();
            this.queryAdminOrderList();
        }
    }
    onExport = () => {
        let url = this.state.request_url + "?link_id=" + this.props.location.state
        if (this.state.date.length === 2) {
            url += "&create_at_small=" + this.state.date[0]
            url += "&create_at_big=" + this.state.date[1]
        }
        window.open(url)
    }
    onOrderExport() {
        let url = `${this.state.order_request_url}?shop_id=${cookie.load("shop_id")}&link_id=${this.props.location.state}`;
        if (this.state.start_order_time && this.state.end_order_time) {
            url = `${url}&start_order_time=${this.state.start_order_time}&end_order_time=${this.state.end_order_time}`
        }
        window.open(url);
    }
    render() {
        let { menu_code } = this.props
        return (
            <div className="data-statistics">
                <NavBar
                    icon="icon-tuiguang"
                    child={menu_code.promote_data_statistics}
                    father={menu_code.promote_manage}
                    router="promoteManage"
                />
                <div className="content">
                    <div className="shopDataTitle">
                        <i className="titleSign"></i>
                        {this.state.link_res.link_name}
                    </div>
                    <div className='integral-dashboard'>
                        <div className="integral-dashboard-data">
                            <div className="integral-title">
                                推广页面
                            </div>
                            <div className="integral-data">
                                {this.state.link_res.desc}
                            </div>
                        </div>
                        <div className="integral-dashboard-data">
                            <div className="integral-title">
                                推广渠道
                            </div>
                            <div className="integral-data">
                                {this.state.link_res.channel_name}
                            </div>
                        </div>
                        <div className="integral-dashboard-data">
                            <div className="integral-title">
                                创建时间
                            </div>
                            <div className="integral-data">
                                {this.state.link_res.created_at}
                            </div>
                        </div>
                    </div>
                    <div className="shopDataTitle">
                        <i className="titleSign"></i>
                            监控数据
                        <div className='filter-date'>
                            <DatePicker.RangePicker
                                className="form-input-long"
                                onChange={this.onShowTimeChange}
                            />
                            <span style={{ cursor: 'pointer' }} onClick={this.onExport}>
                                <Icon type="download" />
                            导出
                           </span>
                        </div>
                    </div>
                    <Table
                        columns={this.state.columns}
                        dataSource={this.state.link_daily_stats_res_list}
                        pagination={false}
                        rowKey={(record) => record.created_at}
                    />
                    <Pagination
                        className="pagenation"
                        onChange={this.onShowPageChange}
                        onShowSizeChange={this.onShowSizeChange}
                        defaultCurrent={this.state.page}
                        total={this.state.total}
                        showTotal={(total) => `共 ${total} 条`}
                        pageSizeOptions={["10", "30", "50", "100"]}
                        showSizeChanger
                    />
                    <div className="shopDataTitle">
                        <i className="titleSign"></i>
                        订单数据
                        <div className='filter-date'>
                            <DatePicker.RangePicker
                                className="form-input-long"
                                onChange={this.onOrderShowTimeChange}
                            />
                            <span style={{ cursor: 'pointer' }} onClick={this.onOrderExport.bind(this)}>
                                <Icon type="download" />
                            导出
                           </span>
                        </div>
                    </div>
                    <Table
                        columns={this.state.orderColumns}
                        dataSource={this.state.orderList}
                        pagination={false}
                        rowKey={(record) => record.order_id}
                    />
                    <Pagination
                        className="pagenation"
                        onChange={this.onShowPageChange}
                        onShowSizeChange={this.onShowSizeChange}
                        defaultCurrent={this.state.orderPage}
                        total={this.state.orderTotal}
                        showTotal={(total) => `共 ${total} 条`}
                        pageSizeOptions={["10", "30", "50", "100"]}
                        showSizeChanger
                    />
                    <div className="shopDataTitle">
                        <i className="titleSign"></i>
                        数据趋势
                    </div>
                    <div id="main" style={{ height: '400px' }}></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        menu_code: state.menuCode,
        order_status_list: state.orderStatusList,
    };
};
export default connect(mapStateToProps)(DataStatistics)
