import React, { Component, Fragment } from 'react';
import '../scss/fissionCouponSetting.scss';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavBar from "@/view/component/common/NavBar.js";
import { getQueryString, deleteItem, mergeList } from "@common/common";
import { Form, Divider, Button, Input, DatePicker, Radio, Modal, Col, message, Row } from 'antd'
import FormTitle from '@/view/component/common/FormTitle/FormTitle';
import PopOverTips from './components/PopOverTips';
import GoodsChoose from "@/view/component/Goods/GoodsChoose";
import GoodsDragSort from "@/view/component/GoodsDragSort/index";
import UploadComponent from "@/view/component/common/Upload";
import cookie from "react-cookies";
import { getData } from '@service/getData';
import moment from "moment";

const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: {
        xs: {
            span: 6,
        },
        sm: {
            span: 10,
        },
        md: {
            span: 12,
        },
        lg: {
            span: 16,
        },
        xl: {
            span: 18,
        },
        xxl: {
            span: 20,
        },
    }
};

const SHARE_TEXT = '送你一张优惠券，快来看看吧～';

// 是否是有效的数字（包括小数，不包括负数）
const isNumber = (val) => {
    val = val.toString();
    const reg = new RegExp(/^\d+(\.\d+)?$/);
    return reg.test(val);
}

const imgContent = (
    <img width="200" height="323" src="http://udh.oss-cn-hangzhou.aliyuncs.com/3f6eee7d-44b4-488d-b3d1-f65d75acc47e" className="detailImg" />
)

class FissionCouponSetting extends Component {
    constructor(props) {
        super(props);
        FissionCouponSetting.contextTypes = {
            router: PropTypes.object.isRequired,
        };
        this.state = {
            coupon_id: '',
            // 消费金额
            useMinAmount: '',
            // 减免金额
            discountAmount: '',
            // 金额设置的校验提醒
            amountofconsumptionErrorMesg: '',
            // 开始时间
            start_time: null,
            // 结束时间
            end_time: null,
            // 领取者，需消费金额
            recevieUseCount: null,
            // 领取者，可减免金额
            recevieReliefCount: null,
            // 商品选择弹窗
            goods_select_visible: false,
            // 适用的商品列表
            goods_list: [],
            // 商详开关
            detailShow: 1,
            // 分享设置图片
            // 文件列表
            cardFileList: [],
            // 图片
            cardPreviewImage: '',
            // 分享者券ID
            share_coupon_id: null,
            // 领取者券ID
            booster_coupon_id: null,
        };
    }
    // 钩子函数
    componentDidMount() {
        let coupon_id = getQueryString("coupon_id");
        if (coupon_id) {
            this.setState({
                coupon_id,
            }, () => {
                this.queryCouponDetail(coupon_id);
            });
        }

    }
    // 编辑：获取数据
    async queryCouponDetail(coupon_id) {
        let data = {
            coupon_id,
            mini_app_id: cookie.load("mini_app_id"),
        }
        const url = '/fission_coupon/queryFissionCouponDetail';
        let res = await getData(url, data);
        if (res.success) {
            const { setFieldsValue } = this.props.form;
            const { coupon_res, coupon_goods_res_list } = res.result.sharer_coupon_detail_result;
            const { coupon_res: booster_coupon_res } = res.result.booster_coupon_detail_result;
            let list = [];
            if (coupon_goods_res_list && coupon_goods_res_list.length) {
                list = coupon_goods_res_list.map(item => {
                    return {
                        coupon_goods_id: item.coupon_goods_id,
                        goods_id: item.goods_id,
                        ...item.group_res.goods_res
                    };
                });
            }
            setFieldsValue({ coupon_name: coupon_res.coupon_name });
            setFieldsValue({ nums: coupon_res.total_num });
            // 编辑的时候，发放数量做限制。必须大于先前数量。此处存储先前数量。
            this.setState({
                total_num: coupon_res.total_num * 1,
            });
            setFieldsValue({ person: coupon_res.booster_num * 1 - 1 });
            setFieldsValue({ goods_type: coupon_res.goods_type });
            setFieldsValue({ choose: coupon_res.show_advance });
            setFieldsValue({ shareText: coupon_res.forward_title });

            this.setState({
                useMinAmount: coupon_res.use_min_amount / 100,
                discountAmount: coupon_res.discount_amount / 100,
                start_time: coupon_res.start_time,
                end_time: coupon_res.end_time,
                recevieUseCount: booster_coupon_res.use_min_amount / 100,
                recevieReliefCount: booster_coupon_res.discount_amount / 100,
                goods_list: list,
                cardFileList: [
                    {
                        uid: "-1",
                        name: "xxx",
                        status: "done",
                        url: coupon_res.forward_image,
                    },
                ],
                share_coupon_id: coupon_res.coupon_id,
                booster_coupon_id: booster_coupon_res.coupon_id,
            });
        }
    }
    // 弹窗控制
    changeVisible(attr) {
        this.setState((prevState) => ({
            [attr]: !prevState[attr]
        }));
    }
    // 分享者券使用门槛
    aocChange(e) {
        this.setState({
            useMinAmount: e.target.value,
        });
    }
    // 分享者券优惠金额
    daChange(e) {
        this.setState({
            discountAmount: e.target.value,
        });
    }
    // 有效时间
    onShowTimeChange = (date, dateStr) => {
        this.setState({
            start_time: `${dateStr[0]} 00:00:01`,
            end_time: `${dateStr[1]} 23:59:59`,
        });
    };
    // 领取者券使用门槛
    rUCChange(e) {
        this.setState({
            recevieUseCount: e.target.value,
        });
    }
    // 领取者券优惠金额
    rRCChange(e) {
        this.setState({
            recevieReliefCount: e.target.value,
        });
    }
    // 分享者券：使用条件校验
    handleAoCVal(a, val, callback) {
        if (!this.state.useMinAmount || !this.state.discountAmount) {
            callback('请输入使用条件');
        }
        // 填写的不是合规的数字格式或者填写了负数
        else if (!isNumber(this.state.useMinAmount) || !isNumber(this.state.discountAmount)) {
            callback('请输入合规的数字');
        }
        // 填写的免减金额大于消费金额
        else if ((parseInt(this.state.useMinAmount) < parseInt(this.state.discountAmount)) || (this.state.discountAmount * 1) === 0) {
            callback('消费额需大于等于减免金额且不能为0');
        }
        callback();
    }
    // 领取者券：使用条件校验
    handleRecevieCountVal(a, val, callback) {
        // 没有填写
        if (!this.state.recevieUseCount || !this.state.recevieReliefCount) {
            callback('请填写使用条件');
        }
        // 填写的不符合规定
        else if (!isNumber(this.state.recevieUseCount) || !isNumber(this.state.recevieReliefCount)) {
            callback('请输入合规的数字');
        }
        // 填写的免减金额大于消费金额
        else if ((parseInt(this.state.recevieUseCount) < parseInt(this.state.recevieReliefCount)) || (this.state.recevieReliefCount * 1) === 0) {
            callback('消费额需大于等于减免金额且不能为0');
        }
        callback();
    }
    // 编辑时，发放数量校验
    handleNumsVal(a, val, callback) {
        if ((val * 1) < this.state.total_num) {
            callback(`必须大于等于${this.state.total_num}`);
        }
        callback()
    }
    // 选择商品校验
    handleGoodsListVal(a, val, callback) {
        const { getFieldValue } = this.props.form;
        // 未选择
        if (!getFieldValue('goods_type')) {
            callback('请选择商品');
        }
        // 选择了部分商品，但是没有选择商品
        else if (getFieldValue('goods_type') === 'general' && !this.state.goods_list.length) {
            callback('请添加指定商品');
        }
        callback();
    }
    timeShow() {
        if (this.state.start_time) {
            return (
                <span>{this.state.start_time} ~ {this.state.end_time}</span>
            )
        } else {
            return null;
        }
    }
    onGoodsOk(list) {
        let goods_list = mergeList(list, this.state.goods_list, "goods_id");
        this.setState({
            goods_list,
            goods_select_visible: false,
        });
    }
    delGoods = index => {
        let goods_id = this.state.goods_list[index].goods_id;
        let goods_list = deleteItem(this.state.goods_list, goods_id, "goods_id", "coupon_goods_id");
        this.setState({
            goods_list
        });
    };
    // 上传的图片预览
    cardHandlePreview = file => {
        this.setState({
            cardPreviewImage: file.url || file.thumbUrl,
            cardPreviewVisible: true
        });
    };
    // 修改分享图片
    cardHandleChange = info => {
        let cardFileList = info.fileList;
        cardFileList = cardFileList.map(file => {
            if (file.response) {
                file.url = file.response.result.oss_file_url;
            }
            return file;
        });
        this.setState({ cardFileList });
    };
    handleCancelCard() {
        this.setState({
            cardPreviewVisible: false,
        });
    }
    // 保存
    handleSubmit = async e => {
        e.preventDefault();
        const { getFieldValue } = this.props.form;
        let canContinue = true;
        this.props.form.validateFieldsAndScroll({
            force: true,
        }, (err, values) => {
            if (err) {
                canContinue = false;
            }
        });
        if (!canContinue) {
            return;
        }
        let data = {
            sharerCoupon: {
                // 编辑的时候，需要传coupon_id，否则为新建
                coupon_id: this.state.share_coupon_id,
                mini_app_id: cookie.load("mini_app_id"),
                coupon_name: getFieldValue('coupon_name'),
                coupon_type: "full_reduce",
                start_time: this.state.start_time,
                end_time: this.state.end_time,
                receive_type: 'fission',
                use_min_amount: Math.floor(this.state.useMinAmount * 100),
                discount_amount: Math.floor(this.state.discountAmount * 100),
                total_num: getFieldValue('nums') * 1,
                // 填的为分享人数，加上团长（分享者 ）为成团数
                boosterNum: getFieldValue('person') * 1 + 1,
                new_user_receive: 0,
                receive_limit_num: 1,
                goods_type: getFieldValue('goods_type'),
                coupon_goods_req_list: getFieldValue('goods_type') === 'general' ? this.state.goods_list : null,
                show_advance: getFieldValue('choose'),
                forwardTitle: getFieldValue('shareText') || SHARE_TEXT,
                forwardImage: this.state.cardFileList.length ? this.state.cardFileList[0].url : null,
                coupon_category: getFieldValue('goods_type') === 'general' ? 'goods_coupon' : 'shop_coupon',
            },
            boosterCoupon: {
                coupon_id: this.state.booster_coupon_id,
                mini_app_id: cookie.load("mini_app_id"),
                coupon_name: getFieldValue('coupon_name'),
                coupon_type: "full_reduce",
                start_time: this.state.start_time,
                end_time: this.state.end_time,
                receive_type: 'fission',
                use_min_amount: Math.floor(this.state.recevieUseCount * 100),
                discount_amount: Math.floor(this.state.recevieReliefCount * 100),
                // 领取者券为裂变而来的券，数量为分享者券 * 分享人数
                total_num: getFieldValue('nums') * getFieldValue('person'),
                // 填的为分享人数，加上团长（分享者 ）为成团数
                boosterNum: getFieldValue('person') * 1 + 1,
                new_user_receive: 0,
                receive_limit_num: 1,
                goods_type: getFieldValue('goods_type'),
                coupon_goods_req_list: getFieldValue('goods_type') === 'general' ? this.state.goods_list : null,
                show_advance: getFieldValue('choose'),
                forwardTitle: getFieldValue('shareText') || SHARE_TEXT,
                forwardImage: this.state.cardFileList.length ? this.state.cardFileList[0].url : null,
                coupon_category: getFieldValue('goods_type') === 'general' ? 'goods_coupon' : 'shop_coupon',
            },
        };
        const url = '/fission_coupon/createOrUpdateFissionCoupon';
        let res = await getData(url, data);
        if (res.success) {
            this.state.coupon_id ? message.success('编辑成功') : message.success('新建成功');
            this.context.router.history.push({ pathname: "/fissionCoupon" });
        }
    }
    cancle() {
        this.context.router.history.push({ pathname: "/fissionCoupon" });
        window.scrollTo(0, 0);
    }
    render() {
        let { menu_code } = this.props;
        const { getFieldDecorator, getFieldValue } = this.props.form;

        return (
            <div className="fissionCouponSetting">
                <NavBar
                    icon="icon-youhuijuan1"
                    father={menu_code.fission_coupon}
                    child={this.state.coupon_id ? '编辑分享优惠券' : '新建分享优惠券'}
                    router="fissionCoupon"
                ></NavBar>
                <div className="content">

                    <FormTitle title="优惠券信息" />
                    <Divider />
                    <Form {...formItemLayout}>
                        <Form.Item extra="15个字以内" wrapperCol={{ span: 6 }} required label="优惠券名称">
                            {getFieldDecorator('coupon_name', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入优惠券名称',
                                    },
                                    {
                                        max: 15,
                                        message: '名称长度不能超过15个字',
                                    },
                                ],
                            })(
                                <Input placeholder="请输入优惠券名称" />
                            )}
                        </Form.Item>
                    </Form>

                    <PopOverTips content="活动发起者完成分享任务后获得的优惠券" inners="分享者优惠券" />
                    <Divider />
                    <Form {...formItemLayout}>
                        <Form.Item required label="使用条件">
                            {getFieldDecorator('Amountofconsumption', {
                                rules: [
                                    {
                                        validator: this.handleAoCVal.bind(this)
                                    },
                                ],
                            })(
                                <Fragment>
                                    <label htmlFor="useMinAmount" className="innerLabel">消费金额满</label>
                                    <Input id="useMinAmount" disabled={this.state.coupon_id ? true : false} value={this.state.useMinAmount} onChange={this.aocChange.bind(this)} suffix="元"></Input>
                                    <br />
                                    <label htmlFor="discountAmount" className="innerLabel">减免金额</label>
                                    <Input id="discountAmount" disabled={this.state.coupon_id ? true : false} value={this.state.discountAmount} onChange={this.daChange.bind(this)} suffix="元"></Input>
                                </Fragment>
                            )}
                        </Form.Item>
                        <Form.Item required label="使用时间">
                            <DatePicker.RangePicker
                                disabled={this.state.coupon_id ? true : false}
                                value={
                                    this.state.start_time
                                        ? [moment(this.state.start_time, "YYYY-MM-DD"), moment(this.state.end_time, "YYYY-MM-DD")]
                                        : null
                                }
                                format="YYYY-MM-DD"
                                onChange={this.onShowTimeChange.bind(this)}
                            ></DatePicker.RangePicker>
                        </Form.Item>
                        <Form.Item required label="发放数量">
                            {getFieldDecorator('nums', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请填写发放数量',
                                    },
                                    {
                                        pattern: new RegExp(/^\d+$/),
                                        message: '请填写正整数',
                                    },
                                    {
                                        validator: this.handleNumsVal.bind(this)
                                    }
                                ],
                            })(
                                <Input suffix="张"></Input>
                            )}
                        </Form.Item>
                        <Form.Item extra="最多不超过5人" required label="分享数量">
                            {getFieldDecorator('person', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请填写分享人数',
                                    },
                                    {
                                        pattern: new RegExp(/^[1-5]{1}$/),
                                        message: '请填写正整数,且不超过5人',
                                    },
                                ],
                            })(
                                <Input disabled={this.state.coupon_id ? true : false} suffix="人"></Input>
                            )}
                        </Form.Item>
                        <Form.Item label="领取人限制">
                            <span>不限制，所有人可用</span>
                        </Form.Item>
                        <Form.Item label="每人限领">
                            <span>1张</span>
                        </Form.Item>
                    </Form>

                    <PopOverTips content="分享者分享给好友的优惠券" inners="被分享者优惠券" />
                    <Divider />
                    <Form {...formItemLayout}>
                        <Form.Item extra={<span style={{ paddingLeft: '70px' }}>建议优惠力度小于分享者优惠券</span>} required label="使用条件">
                            {getFieldDecorator('recevieCount', {
                                rules: [
                                    {
                                        validator: this.handleRecevieCountVal.bind(this)
                                    },
                                ],
                            })(
                                <Fragment>
                                    <label htmlFor="recevieUseCount" className="innerLabel">消费金额满</label>
                                    <Input
                                        id="recevieUseCount"
                                        disabled={this.state.coupon_id ? true : false}
                                        suffix="元"
                                        value={this.state.recevieUseCount}
                                        onChange={this.rUCChange.bind(this)}
                                    ></Input>
                                    <br />
                                    <label htmlFor="recevieReliefCount" className="innerLabel">减免金额</label>
                                    <Input
                                        id="recevieReliefCount"
                                        disabled={this.state.coupon_id ? true : false}
                                        suffix="元"
                                        value={this.state.recevieReliefCount}
                                        onChange={this.rRCChange.bind(this)}
                                    ></Input>
                                </Fragment>
                            )}
                        </Form.Item>
                        <Form.Item extra="根据分享者时间自动生成" label="使用时间">
                            {this.timeShow.bind(this)()}
                        </Form.Item>
                        <Form.Item extra="根据分享者优惠券发放数量自动计算" label="发放数量">
                            {getFieldValue('nums') && getFieldValue('person') &&
                                <span>{getFieldValue('nums') * getFieldValue('person')}张</span>
                            }
                        </Form.Item>
                        <Form.Item label="被领取人限制">
                            <span>不限制，所有人可领取</span>
                        </Form.Item>
                        <Form.Item label="每人限领">
                            <span>1张</span>
                        </Form.Item>
                    </Form>

                    <FormTitle title="适用商品" />
                    <Divider />
                    <Form {...formItemLayout}>
                        <Form.Item
                            extra={
                                getFieldValue('goods_type') === 'general' &&
                                <p style={{ marginBottom: '12px' }}>最多添加200个商品</p>
                            }
                            required
                            label="适用商品"
                        >
                            {getFieldDecorator('goods_type', {
                                initialValue: null,
                                rules: [
                                    {
                                        validator: this.handleGoodsListVal.bind(this)
                                    },
                                ],
                            })(
                                <Radio.Group disabled={this.state.coupon_id ? true : false}>
                                    <Radio value="all_goods">全部商品</Radio>
                                    <Radio value="general">指定商品</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        {getFieldValue('goods_type') === 'general' &&
                            <Row>
                                <Col offset={2} span={2} style={{ marginRight: '20px' }}>
                                    <div className="addProduct" onClick={this.changeVisible.bind(this, 'goods_select_visible')}>
                                        +添加商品
                                    </div>
                                </Col>
                                <Col span={18}>
                                    <GoodsDragSort
                                        list={this.state.goods_list.filter(item => item.status !== 0)}
                                        handlePreview={() => { }}
                                        editable={true}
                                        delete={this.delGoods}
                                        changeList={list => {
                                            this.setState({
                                                goods_list: list
                                            });
                                        }}
                                    />
                                </Col>
                            </Row>
                        }
                    </Form>

                    <FormTitle title="商详页配置" />
                    <Divider />

                    <Form {...formItemLayout}>
                        <Form.Item label={
                            <PopOverTips styleType="inner" content={imgContent} inners="快速领券" />
                        }>
                            {getFieldDecorator('choose', {
                                initialValue: 1,
                            })(
                                <Radio.Group>
                                    <Radio value={1}>开启</Radio>
                                    <Radio value={0}>关闭</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Form>

                    <FormTitle title="分享设置" />
                    <Divider />
                    <Form {...formItemLayout}>
                        <Form.Item wrapperCol={{ span: 8 }} label="分享文案">
                            {getFieldDecorator('shareText', {
                                rules: [
                                    {
                                        max: 25,
                                        message: '最多25个字哦～',
                                    },
                                ],
                            })(
                                <Input placeholder="送你一张优惠券，快来看看吧～"></Input>
                            )}
                        </Form.Item>
                        <Form.Item label="分享图片">
                            {getFieldDecorator('shareImg')(
                                <Fragment>
                                    <UploadComponent
                                        showWH={true}
                                        fileList={this.state.cardFileList}
                                        onPreview={this.cardHandlePreview.bind(this)}
                                        onChange={this.cardHandleChange.bind(this)}
                                        btn_text="上传图片"
                                    />
                                    <p className="text-note">建议尺寸500*400,仅支持jpeg,png 2种格式,单张图片大小不超过2M。</p>
                                </Fragment>
                            )}
                        </Form.Item>
                    </Form>
                    <div className="submitBtnWrap">
                        <Button shape="round" type="primary" onClick={this.handleSubmit.bind(this)}>保存</Button>
                        <Button shape="round" style={{ marginLeft: '12px' }} onClick={this.cancle.bind(this)}>取消</Button>
                    </div>
                </div>
                <GoodsChoose
                    visible={this.state.goods_select_visible}
                    handleCancel={this.changeVisible.bind(this, "goods_select_visible")}
                    handleOk={this.onGoodsOk.bind(this)}
                    activityType={"coupon"}
                />
                <Modal
                    className="black-modal"
                    visible={this.state.cardPreviewVisible}
                    footer={null}
                    onCancel={() => this.handleCancelCard()}
                    okButtonProps={{ shape: 'round' }}
                    cancelButtonProps={{ shape: 'round' }}
                >
                    <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={this.state.cardPreviewImage}
                    />
                </Modal>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode
    }
}
const FissionCouponSettingForm = Form.create({ name: 'fission_coupon_setting' })(FissionCouponSetting);
export default connect(mapStateToProps)(FissionCouponSettingForm)