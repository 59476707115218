import React, { Fragment } from 'react'
import { Modal, Form, Table, Radio, Input } from 'antd'
import '@css/selfShipmentModal.scss'
class SelfShipmentModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {

                    title: '商品名称',
                    dataIndex: 'goods_name',
                    render: (text, record) => (
                        <p>
                            {record.goods_res.goods_name}
                        </p>
                    )


                }, {

                    title: '规格',
                    dataIndex: 'sku',
                    render: (text, record) => (
                        <div>
                            {
                                record.sku_res.attribute_value_res_list.map(item =>
                                    item.attribute_value
                                )
                            }

                        </div>
                    )
                }, {
                    title: '单价|数量',
                    dataIndex: 'num',
                    render: (text, record) => (
                        <div>
                            <p>¥{record.goods_res.current_price / 100}</p>
                            <p>{record.quantity ? record.quantity : 1}件</p>
                        </div>
                    )
                }
            ],
            order_shipment_info: {
                type: null,
                logistics: null,
                tracking_number: null
            }
        }
    }
    changeLogistics = (e) => {
        e.persist()
        this.setState(prevState => ({
            order_shipment_info: {
                ...this.state.list,
                type: prevState.order_shipment_info.type,
                logistics: e.target.value,
                tracking_number: prevState.order_shipment_info.tracking_number,
            }
        }))

    }
    changeTrackingNumber = (e) => {
        e.persist()
        this.setState(prevState => ({
            order_shipment_info: {
                ...this.state.list,
                type: prevState.order_shipment_info.type,
                logistics: prevState.order_shipment_info.logistics,
                tracking_number: e.target.value
            }
        }))
    }
    onChange = (e) => {
        this.setState(prevState => ({
            order_shipment_info: {
                ...this.state.list,
                type: e.target.value,
                logistics: prevState.order_shipment_info.logistics,
                tracking_number: prevState.order_shipment_info.tracking_number,
            }
        }))

    }
    render() {
        return (
            <Modal
                className="self-shipment-modal"
                title="邮寄订单发货 "
                visible={this.props.visible}
                onOk={this.props.handleSelfOk.bind(this, this.state.order_shipment_info)}
                onCancel={this.props.handleSelfCancel}
                forceRender={true}
                okButtonProps={{
                    shape: 'round',
                    disabled: !this.props.canClick,
                    loading: !this.props.canClick,
                }}
                cancelButtonProps={{ shape: 'round' }}
            >
                <div className="choose-goods">
                    <div className="goods-title">
                        <i className="title-sign"></i>
                        选择商品
                    </div>
                    <Table rowKey={(record, index) => index} columns={this.state.columns}
                        dataSource={this.props.order.order_goods_list} pagination={false} bordered={true} />
                </div>
                <div className="shipment-info">
                    <div className="goods-title">
                        <i className="title-sign"></i>
                        配送信息
                    </div>
                    <span className="address">配送方式：快递邮寄</span>
                    {this.props.order.address_res ? (
                        <Fragment>
                            <span className="address">收货人：{this.props.order.address_res.receiver_name}{this.props.order.address_res.tel_number}</span>
                            <span className="address">收货地址：{`${this.props.order.address_res.city_name}${this.props.order.address_res.county_name}${this.props.order.address_res.detail_info}`}</span>
                        </Fragment>
                    ) : null}
                </div>
                <div className="shipment-info">
                    <div className="goods-title">
                        <i className="title-sign"></i>
                        发货方式
                    </div>
                    <Radio.Group onChange={this.onChange} value={this.state.order_shipment_info.type}>
                        <Radio value={1}>已自行联系快递，填写单号</Radio>
                        <Radio value={2}>无需物流</Radio>
                    </Radio.Group>
                    <div className="shipment-info" style={{ "display": this.state.order_shipment_info.type === 1 ? true : 'none' }}>
                        <p className="text-danger">请仔细填写物流公司及快递单号，发货后24小时内仅支持做一次更正，预逾期不可更改</p>
                        <Form layout="inline">
                            <Form.Item label="选择物流公司">
                                <Input value={this.state.order_shipment_info.logistics} onChange={this.changeLogistics} />
                            </Form.Item>
                            <Form.Item label="填写单号">
                                <Input value={this.state.order_shipment_info.tracking_number} onChange={this.changeTrackingNumber} />
                            </Form.Item>
                        </Form>
                    </div>

                </div>
            </Modal>
        )
    }

}
const SelfShipmentModalForm = Form.create({ name: 'self_shipment_modal_form' })(SelfShipmentModal);

export default SelfShipmentModalForm;
