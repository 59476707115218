import React from 'react'
import {connect} from "react-redux";
import { Tabs } from "antd";
import GoodsDetailSetting from "../shopManage/children/GoodsDetailSetting";
import NavBar from "@/view/component/common/NavBar.js";
import '@css/pageManage.scss'
class PageManage extends React.Component{
    render(){
        let { menu_code } = this.props;
        return(
            <div className="page-manage">
                <NavBar
                    icon="icon-guanliyuanshezhi"
                    child={menu_code.page_manage}
                />
                <div className="content">
                    <Tabs
                        defaultActiveKey="1"
                        tabBarStyle={{ backgroundColor: "#ffffff", borderRadius: "4px" }}
                    >
                        <Tabs.TabPane tab="商品详情页设置" key="1">
                            <GoodsDetailSetting  is_admin={"1"}/>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        menu_code: state.menuCode
    };
};
export default connect(mapStateToProps)(PageManage);
