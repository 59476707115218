import React from 'react';
import '@css/groupManage.scss'
import NavBar from '@/view/component/common/NavBar.js'
import {Form, Input, Button, DatePicker, Select, message, Table, Pagination,Modal,Radio,Checkbox} from 'antd'
import {queryGroupOpenRecordList,batchDeleteGroup,queryCommunityUserList,updateGroupOpenRecordGoods} from '@service/getData'
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import cookie from "react-cookies";

class GroupManage extends React.Component {
    constructor(props) {
        super()
        GroupManage.contextTypes = {
            router: PropTypes.object.isRequired
        }
        this.state = {
            groupList: [],
            page_no: 1,
            page_size: 10,
            total: 0,
            publish_start_date: null,
            publish_end_date: null,
            selectedRowKeys: [],
            selectedRows: [],
            columns: [
                {
                    title: '开团商品',
                    dataIndex: 'goods_name',
                    width: "27%",
                    render: (text, record) => (
                        <div className="goods-info">
                            <div className="goods-info-img">
                                <img alt="" width="60" height="60" src={record.thumbnail_url}/>
                            </div>
                            <div className="goods-info-detail">
                                <div className="goods-info-name" title={record.goods_name}>
                                    {record.goods_name}
                                </div>
                                <div>
                                    ￥{record.current_price / 100}
                                </div>
                            </div>
                        </div>
                    ),
                },
                {
                    title: '商品ID',
                    dataIndex: 'goods_id',
                    width: "13.2%",

                }, {
                    title: '团类型',
                    dataIndex: 'onsale',
                    width: "11.6%",
                    render: (text, record) => (
                        <div>
                            <span
                                style={{"display": record.can_group_shipment === 1 && record.can_self_shipment === 0 ? true : 'none'}}>仅自提</span>
                            <span
                                style={{"display": record.can_group_shipment === 0 && record.can_self_shipment === 1 ? true : 'none'}}>仅邮寄</span>
                            <span
                                style={{"display": record.can_group_shipment === 1 && record.can_self_shipment === 1 ? true : 'none'}}>自提+邮寄</span>
                        </div>
                    )

                }, {
                    title: '团状态',
                    dataIndex: 'is_effect',
                    width:"6.2%",
                    render: (text) => {
                        return +text===0?"失效":"有效"
                    }

                }, {
                    title: '开团时间',
                    dataIndex: 'created_at',
                    width:"18.2%",
                },
                {
                    title: '操作',
                    dataIndex: 'group_open_record_goods_id',
                    width:"12.2%",
                    render: (text, record) => (
                        <div>
                            <span style={{"marginRight": "5px"}} className="text-note"
                                  onClick={this.gotoGroupDetail.bind(this, text)}>查看详情</span>
                            <br/>
                            <span style={{"marginRight": "5px"}} className="text-note"
                                  onClick={this.showModal.bind(this,text)}>更新到新团长</span>
                        </div>
                    )
                }
            ],
            chooseLeaderColumns: [
                {
                    title: '社群名称',
                    dataIndex: 'community_name'
                },
                {
                    title: '团长姓名',
                    dataIndex: 'nick_name'
                },
                {
                    title: '电话',
                    dataIndex: 'mobile'
                }
            ],
            leaderColumns: [
                {
                    title: '社群名称',
                    dataIndex: 'community_name'
                },
                {
                    title: '团长姓名',
                    dataIndex: 'nick_name'
                },
                {
                    title: '电话',
                    dataIndex: 'mobile'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    render: (text, record, index) => (
                        <div>
                            <span  onClick={this.handelLeaderDel.bind(this,index)} style={{"marginRight": "5px"}} className="text-note">删除</span>
                        </div>
                    )
                }
            ],
            visible:false,
            leaderVisble:false,
            mobile:null,
            chooseLeaderData:[],
            selectedLeaderRowKeys:[],
            selectedLeaderRows:[],
            group_open_record_goods_id:null,
            leaderData:[]

        }
    }

    onShowTimeChange = (date, dateStr) => {
        this.setState({
            publish_start_date: dateStr[0],
            publish_end_date: dateStr[1],
        })
    }
    batchDeleteGroup= async ()=>  {
        let data={
            shop_id: cookie.load("shop_id"),
            group_open_record_goods_id_list:this.state.selectedRowKeys

        }
        let res=await batchDeleteGroup(data)
        if(res.success){
            message.success("失效团成功")
            this.setState({
                selectedRowKeys:[]
            },()=>{this.filterData();})

        }
    }
    filterData = () => {
        if(!cookie.load("shop_id")&&+cookie.load("is_admin")===1){
            return
        }
        let form = this.props.form.getFieldsValue();
        let data = {
            page_no: this.state.page_no,
            page_size: this.state.page_size,
            shop_id: cookie.load("shop_id"),
        }
        if(this.state.publish_start_date){
            data.start_date=this.state.publish_start_date
        }
        if(this.state.publish_end_date){
            data.end_date=this.state.publish_end_date
        }
        if (form.goods_name) {
            data.goods_name = form.goods_name
        }
        if (form.goods_id) {
            data.goods_id = form.goods_id
        }
        if (form.is_all_leader) {
            data.is_all_leader = form.is_all_leader
        }
        if (form.group_type) {
            data.group_type = +form.group_type===4?null:form.group_type
        }
        data.is_effect=+form.is_effect===2?null:form.is_effect

        this.queryGroupOpenRecordList(data)

    }

    clearFilter = () => {
        this.props.form.resetFields();
    }

    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        })
        this.filterData()
    }
    onShowPageChange = (page) => {
        this.setState({
            page_no: page
        },()=>{this.filterData()})

    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys,
            selectedRows
        });
    }
    onSelectLeaderChange = (selectedLeaderRowKeys, selectedLeaderRows) => {
        this.setState({
            selectedLeaderRowKeys,
            selectedLeaderRows
        });
    }

    async queryGroupOpenRecordList(data) {
        let res = await queryGroupOpenRecordList(data);
        if (res.success) {
            this.setState({
                groupList: res.result.list,
                total: res.result.total,
            })
            window.scrollTo(0,0);
        }
    }

    async queryCommunityUserList() {
        let data = {
            shop_id: cookie.load("shop_id")
        }
        if (this.state.mobile) {
            data.mobile = this.state.mobile
        }
        let res = await queryCommunityUserList(data)
        if (res.success) {
            this.setState({
                chooseLeaderData: res.result.list
            })
        }
    }
    async updateGroupOpenRecordGoods(data){
        let res=await updateGroupOpenRecordGoods(data)
        if(res.success){
            message.success("更新到新团长成功")
            this.setState({
                visible:false,
                leaderData: [],
            })
            this.filterData()
        }else{
            message.error(res.msg)
        }
    }

    handelLeaderDel = (index) => {
        let leaderData = this.state.leaderData;
        let selectedLeaderRowKeys=this.state.selectedLeaderRowKeys;
        leaderData.splice(index, 1)
        selectedLeaderRowKeys.splice(index,1)
        this.setState({
            leaderData: leaderData,
            selectedLeaderRowKeys:selectedLeaderRowKeys

        })
    }

    goGroupAdd = () => {
        this.context.router.history.push("/groupAdd")
    }

    gotoGroupDetail = (data) => {
        this.context.router.history.push("/groupDetail?group_open_record_goods_id=" + data)
    }
    showModal = (data) => {
        this.setState(prevState => ({
            visible: true,
            group_open_record_goods_id:data
        }))
    }

    changeMobile = (e) => {
        this.setState({
            mobile: e.target.value
        }, () => {
            this.queryCommunityUserList()
        })
    }

    handleLeaderOk = (e) => {
        let arr=this.state.leaderData.concat(this.state.selectedLeaderRows);
        let data=Array.from(new Set(arr));
        this.setState(prevState => ({
            leaderVisble: false,
            leaderData:data
        }))
    }

    saveLeader=()=>{
        let form = this.props.form.getFieldsValue();
        if (!form.choose_leader) {
            return message.info("请选择团长")
        }
        let data={
            shop_id:cookie.load('shop_id'),
            group_open_record_goods_id:this.state.group_open_record_goods_id,
            is_all_leader:form.choose_leader
        }
        if (+form.choose_leader === 2) {
            if (!this.state.leaderData.length) {
               return message.info("请选择团长")
            }
            data.community_ids = this.state.leaderData.map(item => item.community_id)
        }
        this.updateGroupOpenRecordGoods(data)
    }

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    }
    handleLeaderCancel = (e) => {
        this.setState({
            leaderVisble: false,
        });
    }

    handleLeader = (e) => {
        this.setState({
            leaderVisble: true,
        });
    }

    componentDidMount() {
        this.filterData()
        this.queryCommunityUserList()
    }

    render() {
        let {menu_code} = this.props
        const {getFieldDecorator} = this.props.form;
        const {selectedRowKeys,  selectedLeaderRowKeys,} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true,
            onSelection: this.onSelection,
        };
        const rowLeaderSelection = {
            selectedLeaderRowKeys,
            onChange: this.onSelectLeaderChange,
            hideDefaultSelections: true,
            onSelection: this.onSelection,
        };
        return (
            <div className="group-manage">
                <div className="content">
                    <NavBar icon="icon-duoren" child={menu_code.group_manage}/>
                    <div className="group-manage-content">
                        <div className="action">
                            <Button shape="round" type="primary" onClick={this.goGroupAdd}>开团</Button>
                        </div>
                        <div className="filter-content">
                            <Form className="filter-form" layout="inline">
                                <Form.Item label="团类型：">
                                    {getFieldDecorator('group_type', {
                                        initialValue: "4",
                                    })(
                                        <Select className="form-input">
                                            <Select.Option value="4">全部</Select.Option>
                                            <Select.Option value="1">仅自提</Select.Option>
                                            <Select.Option value="2">仅邮寄</Select.Option>
                                            <Select.Option value="3">自提+邮寄</Select.Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="推送团长类型：">
                                    {getFieldDecorator('is_all_leader')(
                                        <Select className="form-input">
                                            <Select.Option value="1">所有团长</Select.Option>
                                            <Select.Option value="2">部分团长</Select.Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="商品Id：">
                                    {getFieldDecorator('goods_id')(
                                        <Input className="form-input" placeholder="输入商品名称"/>
                                    )}
                                </Form.Item>
                                <Form.Item label="开团时间：">
                                    {getFieldDecorator('date')(
                                        <DatePicker.RangePicker className="form-input-long"
                                                                onChange={this.onShowTimeChange} showTime/>
                                    )}
                                </Form.Item>
                                <Form.Item label="商品名称：">
                                    {getFieldDecorator('goods_name')(
                                        <Input className="form-input-long" placeholder="输入商品名称"/>
                                    )}
                                </Form.Item>
                                <Form.Item label="团状态：">
                                    {getFieldDecorator('is_effect', {
                                        initialValue: "1"
                                    })(
                                        <Select className="form-input">
                                            <Select.Option value="2">全部</Select.Option>
                                            <Select.Option value="0">失效</Select.Option>
                                            <Select.Option value="1">有效</Select.Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Form>
                            <div className="filter-action">
                                <Button shape="round" type="primary" onClick={this.onShowPageChange.bind(this,1)}>筛选</Button>
                                <span className="text-note" onClick={this.clearFilter}>清空筛选条件</span>
                            </div>
                        </div>
                        <div className="action">
                            <Button disabled={this.state.selectedRowKeys.length ? false : true} shape="round"
                                    type="primary" onClick={this.batchDeleteGroup}>失效已选团</Button>
                            <span className="choose-num">当前已选择{this.state.selectedRows.length}项</span>
                        </div>
                        <Table rowKey={record => record.group_open_record_goods_id}
                               rowSelection={rowSelection}
                               columns={this.state.columns}
                               dataSource={this.state.groupList}
                               pagination={false}
                               bordered={true}
                        />
                        <Pagination
                            className="pagenation"
                            onChange={this.onShowPageChange}
                            onShowSizeChange={this.onShowSizeChange}
                            defaultCurrent={this.state.page_no}
                            total={this.state.total}
                            showTotal={(total) => `共 ${total} 条`}
                            pageSizeOptions={["10", "30", "50", "100"]}
                            showSizeChanger
                        />
                    </div>
                </div>
                <Modal
                    title="更新团"
                    visible={this.state.visible}
                    onOk={this.saveLeader}
                    onCancel={this.handleCancel}
                    width={736}
                    className="leader-modal"
                    destroyOnClose={true}
                    okButtonProps={{shape:'round'}}
                    cancelButtonProps={{shape:'round'}}
                >
                    <div>
                        <Form>
                            <Form.Item label="推送至团长">
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('shipment_type')(
                                    <Checkbox.Group style={{width: '100%'}} onChange={this.onChangeShipmentType}>
                                        <Checkbox value="2">
                                            邮寄
                                        </Checkbox>
                                        <Checkbox value="1">
                                            自提
                                        </Checkbox>
                                    </Checkbox.Group>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('shipment_pay')(
                                    <div>
                                        邮寄运费
                                        <Input onChange={this.changeSelfShipmentFee}
                                               placeholder="输入邮寄运费"
                                               value={this.state.self_shipment_fee}
                                               disabled={this.state.can_self_shipment ? false : true}
                                               style={{marginRight:50,width:200}}/>
                                    </div>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('choose_leader')(
                                    <Radio.Group>
                                        <Radio value={1}>全部团长</Radio>
                                        <Radio value={2}>部分团长</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            <Form.Item label="" style={{display:+(this.props.form.getFieldsValue().choose_leader)===2?true:"none"}}>
                                <span onClick={this.handleLeader} className="text-note">选择团长</span>
                            </Form.Item>
                            <Form.Item label="开团时间">
                                {getFieldDecorator('date')(
                                    <DatePicker.RangePicker className="form-input-long"
                                                            onChange={this.onShowTimeChange} showTime/>
                                )}
                            </Form.Item>
                        </Form>
                        <Table rowKey={(record, index) => index} columns={this.state.leaderColumns}
                               style={{display:+(this.props.form.getFieldsValue().choose_leader)===2?true:"none"}}
                               dataSource={this.state.leaderData} pagination={false}/>
                    </div>
                </Modal>
                <Modal
                    title="选择团长"
                    visible={this.state.leaderVisble}
                    onOk={this.handleLeaderOk}
                    onCancel={this.handleLeaderCancel}
                    width={736}
                    className="leader-modal"
                    destroyOnClose={true}
                    okButtonProps={{shape:'round'}}
                    cancelButtonProps={{shape:'round'}}
                >
                    <div>
                        <div className="modal-note">备注：若该团长已有该商品团，创建新团将导致之前创建的团失效</div>
                        <Form layout="inline" className="modal-form">
                            <Form.Item label="搜索团长">
                                <Input placeholder="请输入手机号" value={this.state.mobile} onChange={this.changeMobile}/>
                            </Form.Item>
                        </Form>
                        <Table rowKey={(record, index) => record.community_id}
                               rowSelection={rowLeaderSelection}
                               columns={this.state.chooseLeaderColumns}
                               dataSource={this.state.chooseLeaderData}
                               pagination={false}
                               scroll={{y: 240}}/>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode

    }
}

const GroupManageForm = Form.create({name: 'group_manage_form'})(GroupManage);

export default connect(mapStateToProps)(GroupManageForm);
