import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Input, Button, Table, Pagination, Spin } from "antd";
import cookie from "react-cookies";
import { getUserGiftCardList } from "@service/getData";
class CardRefundRecord extends React.Component {
  constructor() {
    super();
    CardRefundRecord.contextTypes = {
      router: PropTypes.object.isRequired
    };
    this.state = {
      loading: false,
      user_gift_card_list: [],
      page: 1,
      page_size: 10,
      total: 0,
      columns: [
        {
          title: "退卡时间",
          dataIndex: "refund_time"
        },
        {
          title: "权益卡名称",
          dataIndex: "gift_card_name"
        },
        {
          title: "客户手机号",
          dataIndex: "mobile",
          render: (text, record) => (
            <div>
              <span>{text}</span>
              <div className="text-note" onClick={this.goToClient.bind(this, record)}>
                {record.user_name || "未授权"}
              </div>
            </div>
          )
        },
        {
          title: "卡号",
          dataIndex: "gift_card_number"
        },
        {
          title: "操作人",
          dataIndex: "updated_user"
        }
      ]
    };
  }
  componentDidMount() {
    this.getUserGiftCardList();
  }
  goToClient = client => {
    this.context.router.history.push("/client", client.user_id);
  };
  onShowPageChange = page => {
    this.setState(
      {
        page: page
      },
      () => {
        this.getUserGiftCardList();
      }
    );
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        page_size: pageSize
      },
      () => {
        this.getUserGiftCardList();
      }
    );
  };
  clearFilter = () => {
    this.props.form.resetFields();
  };
  getUserGiftCardList = async () => {
    let form = this.props.form.getFieldsValue();
    let data = {
      mini_app_id: cookie.load("mini_app_id"),
      page_no: this.state.page,
      page_size: this.state.page_size,
      gift_card_name: form.gift_card_name,
      gift_card_number: form.gift_card_number,
      mobile: form.mobile,
      status: 5
    };
    this.setState({
      loading: true
    });
    let res = await getUserGiftCardList(data);
    let list = res.result.user_gift_card_list;
    this.setState({
      user_gift_card_list: list,
      total: res.result.total,
      loading: false
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Spin spinning={this.state.loading}>
        <div className="card-content">
          <div className="filter-content">
            <Form className="ant-vertical flex" layout="inline">
              <Form.Item label="权益卡名称">
                {getFieldDecorator("gift_card_name")(<Input className="card-name" placeholder="输入权益卡名称" />)}
              </Form.Item>
              <Form.Item label="权益卡卡号">
                {getFieldDecorator("gift_card_number")(<Input className="card-number" placeholder="输入权益卡卡号" />)}
              </Form.Item>
              <Form.Item label="客户手机号">
                {getFieldDecorator("mobile")(<Input className="card-user" placeholder="输入手机号" />)}
              </Form.Item>
            </Form>
            <div className="action">
              <Button shape="round" type="primary" onClick={this.getUserGiftCardList}>
                筛选
              </Button>
              <span className="text-note" onClick={this.clearFilter}>
                清空筛选条件
              </span>
            </div>
          </div>
          <Table
            rowKey={(record, index) => index}
            columns={this.state.columns}
            dataSource={this.state.user_gift_card_list}
            pagination={false}
            bordered={true}
          />
          <Pagination
            className="pagenation"
            onChange={this.onShowPageChange}
            onShowSizeChange={this.onShowSizeChange}
            defaultCurrent={this.state.page}
            total={this.state.total}
            showTotal={total => `共 ${total} 条`}
            pageSizeOptions={["10", "30", "50", "100"]}
            showSizeChanger
          />
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};
const CardRefundRecordForm = Form.create({ name: "card_refund_record" })(CardRefundRecord);
export default connect(mapStateToProps)(CardRefundRecordForm);
