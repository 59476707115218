import React, { Fragment } from 'react'
import { connect } from "react-redux";
import { Form, Input, Button, Table, Modal, message, Upload, Card, DatePicker, Radio, Icon } from "antd/lib/index";
import '@css/activity.scss'
import UploadButton from "@/view/component/common/UploadButton";
import TargetTypeModal from '@/view/component/Campaign/TargetTypeModal'
import CouponSelectModal from '@/view/component/Campaign/CouponSelectModal'

import cookie from 'react-cookies'
import {
    createOrUpdateActivity,
    exchangeActivityGoodsSortNum,
    queryActivityDetail,
    queryGoodsList,
    topActivityGoods,
    queryCampaignActivitySubtype,
    queryCampaignList,
    queryMiniAppDetail,
    topActivityCoupon,
    exchangeActivityCouponSortNum,
    queryCouponList
} from '@service/getData'
import config from "@config/config";

class Activity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type: null,
            loading2: false,
            requestUrl: config.baseUrl + "/ossFile/upload",
            campaign_activity_subtype_res_list: [],
            visible: false,
            target_type_visible: false,
            search_value: null,
            goods_columns: [
                {
                    title: "商品id",
                    dataIndex: "goods_id"
                },
                {
                    title: "商品图片",
                    dataIndex: "thumbnail_url",
                    render: (text, record) => (
                        <div>
                            <img alt=""
                                className="object-cover"
                                src={JSON.parse(record.gallery_urls)[0]}
                                width="100"
                                height="100"
                            />
                        </div>
                    )
                },
                {
                    title: "商品名称",
                    dataIndex: "goods_name"
                },
                {
                    title: "价格",
                    dataIndex: "current_price",
                    render: (text) => <div>￥{text / 100}</div>
                }
            ],
            goods_res_list: [],
            selected_row_keys: [],
            select_goods_res: [],
            current_select_goods_res: [],
            start_time: null,
            end_time: null,
            activity_id: null,
            activity_res: {},
            exchange_disabled: false,
            preview_image: '',
            preview_gallery_image: '',
            review_gallery_image2: '',
            file_list: [],
            file_list2: [],
            gallery_list: [],
            gallery_list2: [],
            preview_gallery_visible: false,
            preview_gallery_visible2: false,
            show_another: [
                "advertisement",
                "topScroll",
                "endScroll"
            ],
            loading: false,
            campaign_id: null,
            campaign_list: [],
            image_show: {
                buyEveryDay: "http://udh.oss-cn-hangzhou.aliyuncs.com/60d8a31a-90b2-4456-be1e-a21b34d9c9b9",
                timeLimitBuy: "http://udh.oss-cn-hangzhou.aliyuncs.com/15f906f1-ff7c-4ecd-86f0-f5ad87968845",
                memberDay: "http://udh.oss-cn-hangzhou.aliyuncs.com/34240318-3eb2-45f1-b982-3b1f5345faeb",
                topScroll: "http://udh.oss-cn-hangzhou.aliyuncs.com/a102873d-5086-4496-bb92-9e466f20543a",
                endScroll: "http://udh.oss-cn-hangzhou.aliyuncs.com/fd906080-685f-4c50-82ec-c17004740924",
                goodsListSlide: "http://udh.oss-cn-hangzhou.aliyuncs.com/b8e61c67-3239-4c33-8b7b-917db32bbcf7",
                advertisement: "http://udh.oss-cn-hangzhou.aliyuncs.com/cba46fd5-ea51-4c9b-a39f-d0a430cf1d30"
            },
            activity_desc_list: {
                title: {
                    buyEveryDay: "",
                    timeLimitBuy: "",
                    memberDay: "",
                    topScroll: "上传banner图",
                    goodsListSlide: "上传背景图",
                    advertisement: "上传图片广告"
                },
                no_target_type: [
                    'buyEveryDay',
                    'timeLimitBuy',
                    'memberDay',
                    'goodsListSlide'
                ],
                mini_app_name: null,
                coupon_visible: false,
            },
            coupon_list: [],
            is_auto: null,
            handle_coupon_list: [],
            target_type_desc: {
                lucky: "抽奖活动",
                checkin: "签到",
                pointsStore: "积分商城",
                free_order: "抢免单",
                rank_lucky: '线上拼团',
            },
            color: 'red',
            system_pages: {
                homePages: '店铺首页',
                categoryPages: '分类页面',
                shopcarPages: '购物车',
                myPages: '个人中心',
                vip_center: '会员中心',
            },
            data: {}

        }
    }

    createOrUpdateActivity = async () => {
        let form = this.props.form.getFieldsValue();
        let left_time = (new Date(this.state.end_time) - new Date(this.state.start_time)) / 1000
        let total = 99 * 3600 + 99 * 60 + 99;
        if (this.props.my_activity_subtype === "timeLimitBuy" && left_time > total && left_time > 0) {
            return message.error("限时秒杀时间范围在99:99:99")
        }
        let gallery_url = ''
        this.state.gallery_list.forEach(item => {
            if (item.response && item.response.success) {
                gallery_url = item.response.result.oss_file_url
            } else if (item.url) {
                gallery_url = item.url
            }
        })
        if (this.state.show_another.find(item => item === this.props.my_activity_subtype) && !this.state.gallery_list.length) {
            return message.error("请选择背景图")
        }
        if (this.props.my_activity_subtype !== "coupon" && this.props.my_activity_subtype !== "advertisement" && !this.state.select_goods_res.filter(item => item.status > 0).length) {
            return message.error("请选择商品")
        }
        if (this.props.my_activity_subtype === "coupon" && this.state.coupon_list.filter(item => item.status === 1).length === 0) {
            return message.error("请添加优惠券")
        }
        let image_url = null;
        if (this.props.my_activity_subtype === "coupon") {
            let length = this.state.coupon_list.filter(item => item.status === 1).length
            if (this.state.color === 'red') {
                image_url = length > 1 ? "http://udh.oss-cn-hangzhou.aliyuncs.com/f5554772-c6e5-466a-be34-131c3303b9e9" : "http://udh.oss-cn-hangzhou.aliyuncs.com/4ac14e7d-eb7b-4016-99ad-8e031b4f6026"
            } else {
                image_url = length > 1 ? "http://udh.oss-cn-hangzhou.aliyuncs.com/1e84df00-8c61-4713-8d4e-d71c64db448b" : "http://udh.oss-cn-hangzhou.aliyuncs.com/3a3ed8f7-2371-4052-9f03-36f1fd2923cd"
            }

        }
        let activity_subtype = this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === this.props.my_activity_subtype)
        let data = {
            activity_req: {
                image_url,
                dest_campaign_id: this.state.activity_res.dest_campaign_id,
                campaign_id: this.props.campaign_id,
                mini_app_id: cookie.load("mini_app_id"),
                activity_name: activity_subtype.activity_subtype_name,
                activity_id: this.props.activity_id,
                activity_type: "campaign",
                activity_subtype: this.props.my_activity_subtype,
                ...this.state.data,
                target_type: this.props.my_activity_subtype === "advertisement" ? this.state.activity_res.target_type : "goodsList",
                start_time: this.state.start_time,
                end_time: this.state.end_time,
                tag_id: this.state.activity_res.tag_id,
                title: form.title,
                goods_id: this.state.activity_res.goods_id,
                transfer_title: form.transfer_title,
                activity_goods_list: this.state.select_goods_res,
                status: 1,
                thumbnail_url: gallery_url,
                is_auto: this.state.is_auto,
                activity_coupon_req_list: this.state.coupon_list
            }
        }
        this.setState({
            loading: true,
            exchange_disabled: false
        })
        let res = await createOrUpdateActivity(data)

        if (res.success) {
            this.props.form.resetFields();
            if (this.state.type === "edit") {
                message.success('组件更新成功');
            } else {
                message.success('成功添加组件');
            }
            this.props.onOK()
        } else {
            message.error(res.msg);
        }
    }

    async queryMiniAppDetail() {
        let mini_app_id = cookie.load("mini_app_id");
        let res = await queryMiniAppDetail({
            mini_app_id
        });
        this.setState({
            mini_app_name: res.result.mini_app.mini_app_name,

        });
    }

    queryActivityDetail = async (activity_id) => {
        let data = {
            activity_id
        }
        let res = await queryActivityDetail(data);
        if (res.success) {
            let select_goods_res = res.result.activity_res.activity_goods_res_list.map((item, index) => {
                return {
                    goods_id: item.goods_res.goods_id,
                    goods_name: item.goods_res.goods_name,
                    current_price: item.goods_res.current_price,
                    start_time: item.start_time,
                    end_time: item.end_time,
                    activity_id: item.activity_id,
                    activity_goods_id: item.activity_goods_id,
                    status: 1,
                    gallery_urls: item.goods_res.gallery_urls,
                    loading: false,
                    index,
                }
            }
            )
            let gallery_list = []
            if (res.result.activity_res.thumbnail_url) {
                gallery_list = [
                    {
                        uid: '-1',
                        name: 'xxx.png',
                        status: 'done',
                        url: res.result.activity_res.thumbnail_url
                    },
                ];
            }
            let gallery_list2 = []
            if (res.result.activity_res.image_url) {
                gallery_list2 = [
                    {
                        uid: '-1',
                        name: 'xxx.png',
                        status: 'done',
                        url: res.result.activity_res.image_url
                    },
                ];
            }
            switch (res.result.activity_res.target_type) {
                case 'campaign':
                    res.result.activity_res.desc = `店铺页面:${res.result.activity_res.dest_campaign_name}`
                    break;
                case 'goods':
                    res.result.activity_res.desc = `商品:${res.result.activity_res.goods_res.goods_name}`
                    break;
                case 'tag':
                    res.result.activity_res.desc = `商品列表页:${res.result.activity_res.tag_name}`
                    break;
                case 'coupon':
                    res.result.activity_res.desc = `优惠券:${res.result.activity_res.dest_coupon_res.coupon_name}`
                    break;
                case 'activity_coupon':
                    res.result.activity_res.desc = `满减/满赠:${res.result.activity_res.dest_coupon_res.coupon_name}`
                    break;
                case 'activity_goods':
                    res.result.activity_res.desc = `活动商品:${res.result.activity_res.dest_activity_goods_res.goods_res.goods_name}`
                    break;
                case 'roomInfo':
                    res.result.activity_res.desc = `直播间:${res.result.activity_res.dest_room_info_res.name}`
                    break;
                default:
                    if (this.state.target_type_desc[res.result.activity_res.target_type]) {
                        res.result.activity_res.desc = `营销活动:${this.state.target_type_desc[res.result.activity_res.target_type]}`
                    } else {
                        res.result.activity_res.desc = `系统页面:${this.state.system_pages[res.result.activity_res.target_type]}`
                    }
                    break;
            }
            let coupon_list = res.result.activity_res.activity_coupon_res_list.map((item, index) => {
                return {
                    activity_coupon_id: item.activity_coupon_id,
                    coupon_id: item.coupon_res.coupon_id,
                    coupon_name: item.coupon_res.coupon_name,
                    status: 1,
                    index: index,
                }
            })
            let { image_url } = res.result.activity_res, color = 'red';
            if (!image_url || image_url === "http://udh.oss-cn-hangzhou.aliyuncs.com/f5554772-c6e5-466a-be34-131c3303b9e9" || image_url === "http://udh.oss-cn-hangzhou.aliyuncs.com/4ac14e7d-eb7b-4016-99ad-8e031b4f6026") {
                color = 'red'
            } else {
                color = 'yellow'
            }

            this.setState({
                select_goods_res: select_goods_res,
                activity_res: res.result.activity_res,
                gallery_list,
                gallery_list2,
                loading: false,
                type: "edit",
                end_time: res.result.activity_res.end_time,
                start_time: res.result.activity_res.start_time,
                coupon_list,
                is_auto: res.result.activity_res.is_auto,
                exchange_disabled: false,
                color

            })
        }
    }
    queryGoodsList = async () => {
        this.setState({
            loading2: true
        });
        let data = {
            shop_id: cookie.load("shop_id"),
            onsale: 1
        },
            value = this.state.search_value;
        data.goods_name_and_id = value;
        let res = await queryGoodsList(data);
        if (res.success) {
            this.setState({
                loading2: false,
                goods_res_list: res.result.goods_res_list
            });
        }
    };
    exchangeActivityGoodsSortNum = async (index, isUp) => {
        let list = [...this.state.select_goods_res],
            from_activity_goods_id = list[index].activity_goods_id,
            to__activity_goods_id;

        if (isUp < 0) {
            to__activity_goods_id = list[index - 1].activity_goods_id;
        } else {
            to__activity_goods_id = list[index + 1].activity_goods_id;
        }
        let res = await exchangeActivityGoodsSortNum({
            from_activity_goods_id,
            to__activity_goods_id
        });
        if (res.success) {
            message.success("操作成功");
        }
    }
    topActivityGoods = async (activity_goods_id) => {
        let res = await topActivityGoods({ activity_goods_id })
        if (res.success) {
            message.success("置顶成功");
            this.props.onOK()
        }

    }
    queryCouponList = async () => {
        let data = {
            mini_app_id: cookie.load("mini_app_id"),
            // 5: 进行中 和 未开始
            // 1: 进行中
            status: 5,
            page_no: 1,
            page_size: 10
        }

        let res = await queryCouponList(data)
        if (res.success) {

            let coupon_list = this.state.coupon_list.filter(item => item.activity_coupon_id != null);
            coupon_list.forEach(item => {
                item.status = 0;
            })
            coupon_list = coupon_list.concat(res.result.coupon_res_list)
            this.setState({
                coupon_list: coupon_list,
                is_auto: 1
            })
        }
    }
    handleCancel = () => this.setState({ visible: false });
    handleOk = () => {
        let select_goods_res = [...this.state.select_goods_res],
            current_select_goods_res = [...this.state.current_select_goods_res];
        select_goods_res.forEach(item => {
            let index = current_select_goods_res.findIndex(row => row.goods_id === item.goods_id);
            if (index > -1) {
                current_select_goods_res.splice(index, 1)
            }
        })
        select_goods_res = select_goods_res.concat(current_select_goods_res)
        select_goods_res.forEach((item, index) => {
            item.index = index
        })
        this.setState({
            select_goods_res: select_goods_res,
            visible: false,
            exchange_disabled: true,
            selected_row_keys: [],
            current_select_goods_res: []
        })
    };
    searchHandle = e => {
        this.setState({
            search_value: e.target.value
        });
    };
    showModal = () => {
        this.queryGoodsList()
        this.setState({
            visible: true
        });
    };
    onSelectedRowKeysChange = (selected_row_keys, record) => {
        this.setState({
            selected_row_keys: selected_row_keys,
            current_select_goods_res: record,
        }, () => {
            if (record.length === 1 && this.props.form.getFieldValue("target_type") === "goods") {
                this.handleOk()
            }
        })
    };
    changeGoodsDate = (index, date, dateStr) => {
        if (index > -1) {
            let list = [...this.state.select_goods_res];

            list[index].start_time = dateStr[0]
            list[index].end_time = dateStr[1]
            this.setState({
                select_goods_res: list
            })
        } else {
            this.setState({
                start_time: dateStr[0],
                end_time: dateStr[1]
            })
        }
    }
    exchangeGoodsPosition = async (index, num) => {
        let list = [...this.state.select_goods_res];
        let goods = list[index]
        if (this.state.type === "edit" && num === 0) {
            this.topActivityGoods(goods.activity_goods_id);
        } else if (this.state.type === "edit") {
            this.exchangeActivityGoodsSortNum(index, num)
        }

        if (num === -1) {
            if (index === 0) {
                return
            }
            list.splice(index - 1, 1, ...list.splice(index, 1, list[index - 1]))
        } else if (num === 1) {
            if (index === list.length - 1) {
                return
            }
            list.splice(index, 1, ...list.splice(index + 1, 1, list[index]))
        } else {
            if (index === 0) {
                return
            }
            list.splice(0, 0, ...list.splice(index, 1))
        }
        this.setState({
            select_goods_res: list
        }, () => {
            this.props.onOK()
        })
    };
    deleteGoods = (index) => {
        let select_goods_res = [...this.state.select_goods_res]
        let goods = select_goods_res[index]
        if (goods.activity_goods_id) {
            select_goods_res[index].status = 0;
        } else {
            select_goods_res.splice(index, 1)
        }
        select_goods_res.forEach((item, index) => {
            item.index = index
        })
        this.setState({
            select_goods_res,
            exchange_disabled: true
        })
    }
    range = (start, end) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }
    queryCampaignList = async () => {
        let data = {
            mini_app_id: cookie.load("mini_app_id")
        }
        let res = await queryCampaignList(data)
        if (res.success) {
            let campaign_list = res.result.campaign_res_list;
            campaign_list = campaign_list.filter(item => item.campaign_id !== Number(this.state.campaign_id))
            this.setState({
                campaign_list
            })
        }

    }
    handleGalleryCancel = () => {
        this.setState({
            preview_gallery_visible: false,
            preview_gallery_visible2: false
        })
    }
    handleGalleryChange = ({ fileList }) => {
        this.setState({ gallery_list: fileList })
    }
    handleGalleryPreview = (file) => {
        this.setState({
            preview_gallery_image: file.url || file.thumbUrl,
            preview_gallery_visible: true,
        });
    }
    handleGalleryChange2 = ({ fileList }) => {
        this.setState({ gallery_list2: fileList })
    }
    handleGalleryPreview2 = (file) => {
        this.setState({
            preview_gallery_image2: file.url || file.thumbUrl,
            preview_gallery_visible2: true,
        });
    }
    confirmOk = () => {
        this.props.onOk();
        this.props.form.resetFields();
    }
    queryCampaignActivitySubtype = async () => {
        let data = {}
        let res = await queryCampaignActivitySubtype(data);
        let list = res.result.campaign_activity_subtype_res_list
        list.push({
            "activity_subtype": "coupon",
            "activity_subtype_desc": "优惠券。",
            "activity_subtype_name": "优惠券",
            "app_id": "",
            "auto_create": 0,
            "base_version": 0,
            "campaign_activity_subtype_id": 8,
            "flagship_version": 1,
            "mini_app_id": null,
            "special_version": 0,
            "status": 1
        })
        if (res.success) {
            this.setState({
                campaign_activity_subtype_res_list: res.result.campaign_activity_subtype_res_list,
                loading: this.state.type === "edit" ? true : false
            })

        }
    }
    onTargetOk = (data) => {
        let activity_res = this.state.activity_res
        activity_res.target_type = data.target_type;
        switch (data.target_type) {
            case 'campaign':
                activity_res.dest_campaign_id = data.dest_campaign_id;
                activity_res.desc = data.desc
                break;
            case 'goods':
                activity_res.goods_id = data.goods_id
                activity_res.desc = data.desc
                break;
            case 'tag':
                activity_res.tag_id = data.tag_id
                activity_res.desc = data.desc
                break
            case 'roomInfo':
                activity_res.room_info_id = data.room_info_id
                activity_res.desc = data.desc
                break;
            case 'activity_goods':
                activity_res.activity_goods_id = data.activity_goods_id
                activity_res.desc = data.desc
                break;
            case 'coupon':
                activity_res.coupon_id = data.coupon_id
                activity_res.dest_coupon_id = data.coupon_id
                activity_res.desc = data.desc
                break
            case 'activity_coupon':
                activity_res.coupon_id = data.coupon_id
                activity_res.dest_coupon_id = data.coupon_id
                activity_res.desc = data.desc
                break
            default:
                activity_res.desc = data.desc
                break
        }
        this.setState({
            target_type_visible: false,
            activity_res,
            data

        })
    }
    deleteTarget = () => {
        let activity_res = JSON.parse(JSON.stringify(this.state.activity_res))
        activity_res.target_type = "";
        this.setState({
            activity_res
        })

    }
    onTargetCancel = () => {
        this.setState({
            target_type_visible: false
        })
    }
    showTargetModal = () => {
        this.setState({
            target_type_visible: true
        })
    }
    showCard = () => {
        return this.props.my_activity_subtype === "goodsListSlide" || this.props.my_activity_subtype === "advertisement" ? true : "none"
    }
    onCouponCancel = () => {
        this.setState({
            coupon_visible: false
        })
    }
    onCouponOK = (coupon_list) => {
        let current_coupon_list = this.state.coupon_list
        current_coupon_list.forEach(item => {
            let index = coupon_list.findIndex(row => row.coupon_id === item.coupon_id);
            if (index > -1) {
                coupon_list.splice(index, 1)
                item.status = 1
            }
        })
        coupon_list = current_coupon_list.concat(coupon_list)
        if (coupon_list.filter(item => item.status === 1).length > 10) {
            return message.error("最多添加10张优惠券")
        }

        coupon_list.forEach((item, index) => {
            item.index = index
        }
        )
        this.setState({
            coupon_visible: false,
            coupon_list,
            exchange_disabled: true
        })
    }
    showCouponModal = () => {
        this.setState({
            coupon_visible: true
        })
    }
    exchangeCouponPosition = async (index, num) => {
        let list = [...this.state.coupon_list];
        let coupon = list[index]
        if (coupon.activity_coupon_id && num === 0) {
            this.topActivityCoupon(coupon.activity_coupon_id);
        } else if (coupon.activity_coupon_id) {
            this.exchangeActivityCouponSortNum(index, num)
        }
        if (num === -1) {
            if (index === 0) {
                return
            }
            list.splice(index - 1, 1, ...list.splice(index, 1, list[index - 1]))
        } else if (num === 1) {
            if (index === list.length - 1) {
                return
            }
            list.splice(index, 1, ...list.splice(index + 1, 1, list[index]))
        } else {
            if (index === 0) {
                return
            }
            list.splice(0, 0, ...list.splice(index, 1))
        }
        this.setState({
            coupon_list: list
        })
    };
    deleteCoupon = (index) => {

        let coupon_list = [...this.state.coupon_list]
        let coupon = coupon_list[index]
        if (coupon.activity_coupon_id) {
            coupon_list[index].status = 0;
        } else {
            coupon_list.splice(index, 1)
        }
        coupon_list.forEach((item, index) => {
            item.index = index
        })
        this.setState({
            coupon_list,
            exchange_disabled: true
        })
    }
    exchangeActivityCouponSortNum = async (index, isUp) => {
        let list = [...this.state.coupon_list],
            from_activity_coupon_id = list[index].activity_coupon_id,
            to_activity_coupon_id;

        if (isUp < 0) {
            to_activity_coupon_id = list[index - 1].activity_coupon_id;
        } else {
            to_activity_coupon_id = list[index + 1].activity_coupon_id;
        }
        let res = await exchangeActivityCouponSortNum({
            from_activity_coupon_id,
            to_activity_coupon_id
        });
        if (res.success) {
            message.success("操作成功");
        }
    }
    topActivityCoupon = async (activity_coupon_id) => {
        let res = await topActivityCoupon({ activity_coupon_id })
        if (res.success) {
            message.success("置顶成功");
            this.props.onOK()
        }

    }
    onChangeIsAuto = (e) => {
        let coupon_list = this.state.coupon_list.filter(item => item.activity_coupon_id != null)
        coupon_list.forEach(item => {
            item.status = 0;
        })
        if (e.target.value === 1) {
            this.queryCouponList()
        } else {
            this.setState({
                coupon_list,
                is_auto: 0
            })
        }
    }
    changeColor = (e) => {
        this.setState({
            color: e.target.value
        })
    }
    componentDidMount() {
        this.queryCampaignActivitySubtype();
        this.queryCampaignList()
        this.queryMiniAppDetail();
        this.props.onRef('activity', this)
    }

    render() {
        const { selected_row_keys } = this.state;
        const rowSelection = {
            selectedRowKeys: selected_row_keys,
            onChange: this.onSelectedRowKeysChange,
            hideDefaultSelections: true,
            columnTitle: "选择"
        };
        return (
            <div className="activity-add">
                <Card
                    style={{ display: this.showCard() }}
                    className="card-info"
                    title={this.props.my_activity_subtype === "goodsListSlide" ? "商品背景图片" : "图片广告"}
                >
                    <Form>
                        <Form.Item label={this.state.activity_desc_list.title[this.props.my_activity_subtype]}>
                            <div className="image-upload">
                                <Upload
                                    accept="image/jpeg,image/jpg,image/png"
                                    withCredentials={true}
                                    action={this.state.requestUrl}
                                    beforeUpload={this.beforeUpdate}
                                    listType="picture-card"
                                    fileList={this.state.gallery_list}
                                    onPreview={this.handleGalleryPreview}
                                    onChange={this.handleGalleryChange}
                                >
                                    {this.state.gallery_list.length >= 1 ? null : <UploadButton />}
                                </Upload>
                                <div className="text-remind">
                                    建议宽度375，高度不限，仅支持jpg,png 2种格式,单张图片大小不超过3.0MB
                                </div>
                                <Modal visible={this.state.preview_gallery_visible} footer={null}
                                    onCancel={this.handleGalleryCancel}>
                                    <img alt="example" style={{ width: '100%' }}
                                        src={this.state.preview_gallery_image} />
                                </Modal>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="跳转路径"
                            style={{ display: this.props.my_activity_subtype === "goodsListSlide" ? "none" : true }}
                        > {
                                this.state.activity_res.target_type === "" || this.state.activity_res.target_type === null || this.state.activity_res.target_type === "goodsList" ? (

                                    <Button
                                        className="btn-choose-targettype"
                                        onClick={this.showTargetModal}
                                    >选择跳转路径</Button>
                                )
                                    : (<span>
                                        <span className="target-type-show">
                                            <span className="target-type-show-title"> {this.state.activity_res.desc}</span>
                                            <Icon type="close-circle" theme="filled" onClick={this.deleteTarget} />
                                        </span>
                                        <span
                                            className="text-note"
                                            onClick={this.showTargetModal}
                                        >修改</span>
                                    </span>)
                            }
                        </Form.Item>
                    </Form>
                </Card>
                {
                    this.props.my_activity_subtype === "timeLimitBuy" ? (
                        <div className="date-time-limit-buy">
                            <Form>
                                <Form.Item label="秒杀时间：">
                                    <DatePicker.RangePicker
                                        onChange={this.changeGoodsDate.bind(this, -1)}
                                        className="date-time-limit-buy-item"
                                        format="YYYY-MM-DD HH:mm:ss"
                                        showTime
                                    />
                                </Form.Item>
                            </Form>
                        </div>
                    ) : null
                }
                {
                    this.props.my_activity_subtype === "coupon" ? (
                        <Fragment>
                            <div className="date-time-limit-buy">
                                <Form>
                                    <Form.Item label="添加方式">
                                        <Radio.Group value={this.state.is_auto} onChange={this.onChangeIsAuto}>
                                            <Radio value={1}>自动添加</Radio>
                                            <Radio value={0}>手动添加</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Form>
                            </div>
                            <div className="date-time-limit-buy color-set">
                                <Form>
                                    <Form.Item label="配色方案">
                                        <Radio.Group size="large" value={this.state.color}>
                                            <Radio.Button onClick={this.changeColor} style={{ "background": "#FF4F4F" }} value="red"></Radio.Button>
                                            <Radio.Button onClick={this.changeColor} style={{ "background": "#EABA7A" }} value="yellow"></Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Fragment>
                    ) : null
                }
                {
                    this.props.my_activity_subtype !== "advertisement" ? this.state.select_goods_res.filter(item => item.status === 1).map((item, index) => (
                        <Card
                            key={index}
                            className="card-info card-goods"
                            title={<span>{`商品${index + 1}`}<span onClick={this.deleteGoods.bind(this, item.index)} className="text-note">删除</span></span>}
                            extra={<span>
                                {index !== 0 ? (
                                    !this.state.exchange_disabled && this.props.activity_id ? (
                                        <i
                                            className="iconfont icon-shang-copy-copy"
                                            onClick={this.exchangeGoodsPosition.bind(this, index, -1)}
                                        />) : (<i
                                            className="iconfont icon-shang-copy-copy icon-disabed"
                                        />)) : null}
                                {index !== this.state.select_goods_res.length - 1 ? (
                                    !this.state.exchange_disabled && this.props.activity_id ? (
                                        <i
                                            className="iconfont icon-xia"
                                            onClick={this.exchangeGoodsPosition.bind(this, index, 1)}
                                        />) : (<i
                                            className="iconfont icon-xia icon-disabed" />
                                        )) : null}
                                {index !== 0 ? (
                                    !this.state.exchange_disabled && this.props.activity_id ? (
                                        <i
                                            onClick={this.exchangeGoodsPosition.bind(this, index, 0)}
                                            className="iconfont icon-zhiding1"
                                        />) : (<i
                                            className="iconfont icon-zhiding1 icon-disabed"
                                        />)) : null}

                            </span>}
                        >
                            商品:{item.goods_name}
                        </Card>
                    )) : null
                }
                {
                    this.state.coupon_list.filter(item => item.status === 1).map((item, index) => (
                        <Card
                            key={index}
                            className="card-info card-goods"
                            title={<span>{`优惠券${index + 1}`}
                                {
                                    this.state.is_auto ? null : (
                                        <span onClick={this.deleteCoupon.bind(this, item.index)} className="text-note">删除</span>
                                    )
                                }
                            </span>}
                            extra={<span>
                                {index !== 0 ? (
                                    !this.state.exchange_disabled && this.state.is_auto !== 1 ? (
                                        <i
                                            className="iconfont icon-shang-copy-copy"
                                            onClick={this.exchangeCouponPosition.bind(this, index, -1)}
                                        />) : (<i
                                            className="iconfont icon-shang-copy-copy icon-disabed"
                                        />)) : null}
                                {index !== this.state.coupon_list.length - 1 ? (
                                    !this.state.exchange_disabled && this.state.is_auto !== 1 ? (
                                        <i
                                            className="iconfont icon-xia"
                                            onClick={this.exchangeCouponPosition.bind(this, index, 1)}
                                        />) : (<i
                                            className="iconfont icon-xia icon-disabed" />
                                        )) : null}
                                {index !== 0 ? (
                                    !this.state.exchange_disabled && this.state.is_auto !== 1 ? (
                                        <i
                                            onClick={this.exchangeCouponPosition.bind(this, index, 0)}
                                            className="iconfont icon-zhiding1"
                                        />) : (<i
                                            className="iconfont icon-zhiding1 icon-disabed"
                                        />)) : null}

                            </span>}
                        >
                            优惠券:{item.coupon_name}
                        </Card>
                    ))
                }
                {
                    this.props.my_activity_subtype !== "advertisement" && this.props.my_activity_subtype !== "coupon" ? (<Button type="dashed"
                        className="button-activity-goods"
                        onClick={this.showModal}>+添加商品
                    </Button>) : null
                }
                {
                    this.state.is_auto !== 1 && this.props.my_activity_subtype === "coupon" ?
                        (<Button type="dashed"
                            className="button-activity-goods"
                            onClick={this.showCouponModal}>+添加优惠券
                        </Button>) : null
                }
                <div className="action-save">
                    <Button
                        type="primary"
                        className="btn-save"
                        onClick={this.createOrUpdateActivity}
                    >提交</Button>
                </div>
                <Modal
                    width="1000px"
                    visible={this.state.visible}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    destroyOnClose={true}
                    okButtonProps={{ shape: 'round' }}
                    cancelButtonProps={{ shape: 'round' }}
                >
                    <div className="table-content">
                        <div className="table-header">
                            <Form
                                labelCol={{
                                    span: 12
                                }}
                                wrapperCol={{
                                    span: 12
                                }}
                                layout="inline"
                            >
                                <Form.Item label="商品名称/商品id">
                                    <Input
                                        value={this.state.search_value}
                                        onChange={this.searchHandle}
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button shape="round" onClick={this.queryGoodsList.bind(this)}>
                                        搜索
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <Table
                            loading={this.state.loading2}
                            className="select-goods-modal"
                            rowKey={record => record.goods_id}
                            bordered={true}
                            rowSelection={rowSelection}
                            columns={this.state.goods_columns}
                            dataSource={this.state.goods_res_list}
                        />
                    </div>
                </Modal>
                <TargetTypeModal
                    visible={this.state.target_type_visible}
                    onOK={this.onTargetOk}
                    onCancel={this.onTargetCancel}
                />
                <CouponSelectModal
                    visible={this.state.coupon_visible}
                    onOk={this.onCouponOK}
                    onCancel={this.onCouponCancel}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
        activity_subtype: state.activitySubtype

    }
}
const activityForm = Form.create({ name: 'activity' })(Activity);

export default connect(mapStateToProps)(activityForm);
