import { Card, Form, Upload, Modal, Button, message } from 'antd';
import React, { Component } from 'react';
import './cartStyle.scss';
import UploadButton from "@/view/component/common/UploadButton";
import config from '@config/config';
import TargetTypeModal from "@/view/component/Campaign/TargetTypeModal";
import { getData } from '@service/getData';
import cookie from 'react-cookies';

const target_type_desc = {
    lucky: "抽奖活动",
    checkin: "签到",
    pointsStore: "积分商城",
    free_order: "抢免单",
    rank_lucky: '线上拼团',
};
const system_pages = {
    homePages: "店铺首页",
    categoryPages: "分类页面",
    shopcarPages: "购物车",
    myPages: "个人中心",
    vip_center: '会员中心',
};
const requestUrl = config.baseUrl + "/ossFile/upload";

class CartStyle extends Component {
    constructor(props) {
        super();
        this.state = {
            cartAdverFileList: [],
            previewImage: '',
            previewVisible: false,
            target_type_visible: false,
            thumbnail_url: 'http://udh.oss-cn-hangzhou.aliyuncs.com/0317a7aa-d409-47b0-9c22-79b3d8bcffd8',
            targetData: {},
            activity_id: null,
            targetDesc: '选择跳转路径',
        }
    }

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl || file,
            previewVisible: true,
        });
    }

    handleCancel = () => {
        this.setState({
            previewVisible: false
        })
    }

    handleShareChange = ({ fileList }) => {
        let thumbnail_url;
        // 删除图片的时候，fileList为空数组。做兼容。
        if (fileList.length) {
            let last = fileList[fileList.length - 1]
            last.url = last.response && last.response.result && last.response.result.oss_file_url;
            thumbnail_url = last.url;
        } else {
            thumbnail_url = '';
        }
        this.setState((pre) => (
            {
                cartAdverFileList: [...fileList],
                thumbnail_url,
                targetData: { ...pre.targetData, thumbnail_url: thumbnail_url }
            }
        ));
    }

    onOk(data) {
        const targetData = {};
        targetData.target_type = data.target_type;
        switch (data.target_type) {
            case "campaign":
                targetData.dest_campaign_id = data.dest_campaign_id;
                targetData.desc = data.desc;
                break;
            case "goods":
                targetData.goods_id = data.goods_id;
                targetData.desc = data.desc;
                break;
            case "tag":
                targetData.tag_id = data.tag_id;
                targetData.desc = data.desc;
                break;
            case "roomInfo":
                targetData.room_info_id = data.room_info_id;
                targetData.desc = data.desc;
                break;
            case "activity_goods":
                targetData.activity_goods_id = data.activity_goods_id;
                targetData.desc = data.desc;
                break;
            case "coupon":
            case "activity_coupon":
                targetData.coupon_id = data.coupon_id;
                targetData.dest_coupon_id = data.coupon_id;
                targetData.desc = data.desc;
                break;
            case "customPages":
                targetData.path_url = data.path_url;
                targetData.desc = data.desc;
                break;
            default:
                targetData.desc = data.desc;
                break;
        }
        this.setState((pre) => ({
            target_type_visible: false,
            targetData: { ...pre.targetData, ...targetData },
            targetDesc: data.desc,
        }));
    }

    onTargetCancel() {
        this.setState({
            target_type_visible: false,
        });
    }

    onShowTarget() {
        this.setState({
            target_type_visible: true,
        });
    }

    async getData() {
        const data = {
            mini_app_id: cookie.load('mini_app_id'),
            target_type: 'shopcarPages',
        };
        const url = '/activity/querySystemPage';
        const res = await getData(url, data);
        if (res.success && res.result.activity_res) {
            const { activity_id, thumbnail_url, target_type } = res.result.activity_res;
            const cartAdverFileList = [
                {
                    uid: 0,
                    name: thumbnail_url,
                    status: 'done',
                    url: thumbnail_url,
                }
            ];
            let targetDesc = '';
            const activity_res = res.result.activity_res;
            switch (target_type) {
                case "campaign":
                    targetDesc = `店铺页面:${activity_res.campaign_res.campaign_name}`;
                    break;
                case "goods":
                    targetDesc = `商品:${activity_res.goods_res.goods_name}`;
                    break;
                case "tag":
                    targetDesc = `商品列表页:${activity_res.tag_name}`;
                    break;
                case "coupon":
                    targetDesc = `优惠券:${activity_res.dest_coupon_res.coupon_name}`;
                    break;
                case "activity_coupon":
                    targetDesc = `满减/满赠:${activity_res.dest_coupon_res.coupon_name}`;
                    break;
                case "activity_goods":
                    targetDesc = `活动商品:${activity_res.dest_activity_goods_res.goods_res.goods_name}`;
                    break;
                case "roomInfo":
                    targetDesc = `直播间:${activity_res.dest_room_info_res.name}`;
                    break;
                default:
                    if (target_type_desc[target_type]) {
                        targetDesc = `营销活动:${target_type_desc[target_type]}`;
                    } else {
                        targetDesc = `系统页面:${system_pages[target_type]}`;
                    }
                    break;
            }
            this.setState({
                activity_id,
                thumbnail_url,
                cartAdverFileList,
                targetDesc,
                targetData: activity_res,
            });
        }
    }

    async onSubmit() {
        const data = {
            mini_app_id: cookie.load('mini_app_id'),
            target_type: 'shopcarPages',
            activity_req: this.state.targetData,
        };

        const url = '/activity/modifySystemPage';

        const res = await getData(url, data);
        if (res.success) {
            message.success('保存成功', () => {
                this.getData();
            });
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <div className="cartStyle">
                <div className="left">
                    <img width="375" src="http://udh.oss-cn-hangzhou.aliyuncs.com/86a64545-4000-4808-92e9-34f217d14a9c" alt="" />
                    <div className="leftMid">
                        <img width="375" src={this.state.thumbnail_url} alt="" />
                    </div>
                    <img width="375" src="http://udh.oss-cn-hangzhou.aliyuncs.com/57acd7b7-7179-4a67-bf03-cf4d11799fa2" alt="" />
                </div>
                <div className="right">
                    <Card
                        title="图片广告"
                    >
                        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} labelAlign="left">
                            <Form.Item
                                label="图片广告"
                                extra="建议宽度375，高度140，仅支持jpg,png2种格式，且只能上传一张，小于2M"
                            >
                                <Upload
                                    accept="image/jpeg,image/jpg,image/png"
                                    withCredentials={true}
                                    action={requestUrl}
                                    listType="picture-card"
                                    fileList={this.state.cartAdverFileList}
                                    onPreview={this.handlePreview}
                                    onChange={this.handleShareChange}
                                >
                                    {this.state.cartAdverFileList.length > 0 ? null : <UploadButton />}
                                </Upload>
                            </Form.Item>
                            <Form.Item
                                label="跳转路径"
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <div className="jumpTextArea" onClick={this.onShowTarget.bind(this)}>
                                    {this.state.targetDesc}
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                    <Button onClick={this.onSubmit.bind(this)} className="submit" type="primary">提交</Button>
                </div>
                <TargetTypeModal visible={this.state.target_type_visible} onOK={this.onOk.bind(this)} onCancel={this.onTargetCancel.bind(this)} />
                <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel.bind(this)}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </div>
        );
    }
}

export default CartStyle;