import React, { Component } from 'react';
import { formatDate } from '@common/common'


class CouponPoster extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div id="post">
                <div className="post-content">
                    <div className="avator">
                        <img alt="" id="avator-img" src={this.props.logo_url} />
                    </div>
                    <div className="shop-name">{this.props.mini_app.mini_app_name}</div>
                    <div className="coupon-name">{this.props.coupon.coupon_name}</div>
                    <div className="coupon-date">
                        有效期：{this.props.coupon.effect_day ? `按领取之日起${this.props.coupon.effect_day}天内可用` :
                            (formatDate(new Date(this.props.coupon.start_time).getTime(), 'Y.M.D') + '-' + formatDate(new Date(this.props.coupon.end_time).getTime(), 'Y.M.D'))
                        }
                    </div>
                    <div className="coupon-content">
                        <div className="discount">¥{this.props.coupon.discount_amount / 100}</div>
                        <div className="coupon-user-min-amount">
                            {this.props.coupon.use_min_amount === 0 ? '无门槛' : `满${this.props.coupon.use_min_amount / 100}元可用`}
                        </div>
                        <div
                            className="coupon-use-goods">{`适用${this.props.coupon.coupon_category === "goods_coupon" ? '指定' : '全部'}商品`}</div>
                    </div>
                    <img alt=""
                        width="120"
                        src={this.props.url} />
                    <div>扫码领取</div>
                </div>
            </div>
        );
    }
}

export default CouponPoster;