import React from 'react'
import '@css/leaderContent.scss'
import {connect} from "react-redux";
import {Form, Input, DatePicker, Select, Button, Table, message, Pagination} from 'antd'
import {queryApplyGroupRoleList, approveGroupRoleList} from '@service/getData'
import cookie from 'react-cookies'
import moment from 'moment';
import PropTypes from 'prop-types'

class LeaderContent extends React.Component {
    constructor(props) {
        LeaderContent.contextTypes = {
            router: PropTypes.object.isRequired
        }
        super(props)
        this.state = {
            finish_columns: [{

                title: '申请人姓名',
                dataIndex: 'real_name',

            }, {

                title: '电话',
                dataIndex: 'tel_number',
            }, {

                title: '省市区',
                dataIndex: 'receive_time',
                render(text, record) {
                    return record.province_name + record.city_name + record.county_name
                }
            }, {

                title: '社区名称',
                dataIndex: 'community_name'
            },{

                title: '审核状态',
                dataIndex: 'apply_status',
                render: (text, record) => (
                    text===0?"已删除":text===1?"申请待审核":text===2?"成功":"审核失败"
                )
            }, {

                title: '操作',
                dataIndex: 'action',
                render: (text, record) =>
                    (
                        <div>
                            {record.apply_status===2||record.apply_status===0?( <p className="text-note" onClick={this.gotoLeaderBonus.bind(this,record.role_apply_id)}>佣金详情</p>):null}
                            {record.apply_status===1?( <p>
                                <span className="text-note" onClick={this.handleGroupRole.bind(this,[record.role_apply_id],1)}>审核通过</span>
                                <span className="text-note" onClick={this.handleGroupRole.bind(this,[record.role_apply_id],0)}>审核拒绝</span>
                            </p>):null}
                        </div>
                    )

            }
            ],

            order_list: null,
            publish_start_date: null,
            publish_end_date: null,
            give_start_date: null,
            give_end_date: null,
            selectedRowKeys: [],
            selectedRows: [],
            page: 1,
            page_size: 10,
            total: 0,
            status_list: [
                "待结算待商家审核",
                "待商家审核",
                "审核成功",
                "审核失败",
                "已取消"
            ],
            balance: null
        }

    }


    async queryApplyGroupRoleList(data) {
        let res = await queryApplyGroupRoleList(data)
        if (res.success) {
            this.setState({
                order_list: res.result.role_apply_res_list,
                total: res.result.total
            })
        }
    }

    async approveGroupRoleList(data) {
        let res = await approveGroupRoleList(data)
        if (res.success) {
            message.success("操作成功")
            this.setState({
                selectedRowKeys: []
            }, () => {
                this.filterData()
            })
        }

    }

    handleGroupRole = (arr,status) => {
        let row=[]
        if(status===1||status===0){
            row=this.state.selectedRows.filter(item=>item.apply_status===1)
        }else{
            row=this.state.selectedRows.filter(item=>item.apply_status!==1)
        }
        let row_keys=row.map(item=>{return item.role_apply_id} )

        let data = {
            shop_id: cookie.load("shop_id"),
            role_apply_id_list: arr===null?row_keys:arr,
            status: status
        }
        this.approveGroupRoleList(data)

    }
    onShowTimeChange = (date, dateStr) => {
        this.setState({
            publish_start_date: dateStr[0],
            publish_end_date: dateStr[1],
        })
    }
    onShowGiveTimeChange = (date, dateStr) => {
        this.setState({
            give_start_date: dateStr[0],
            give_end_date: dateStr[1],
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys,
            selectedRows
        });
    }
    onShowPageChange = (page) => {
        this.setState({
            page: page
        }, () => {
            this.filterData()
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.filterData()
        })
    }
    filterData = () => {

        if(!cookie.load("shop_id")&&+cookie.load("is_admin")===1){
            return
        }
        let form = this.props.form.getFieldsValue();
        let data = {
            page_no: this.state.page,
            page_size: this.state.page_size,
            shop_id: cookie.load("shop_id"),
        }
        if (form.real_name) {
            data.real_name = form.real_name
        }
        if (form.tel_number) {
            data.tel_number = form.tel_number
        }
        if (this.state.publish_start_date) {
            data.start_apply_time = this.state.publish_start_date
        }
        if (this.state.publish_end_date) {
            data.end_apply_time = this.state.publish_end_date
        }
        if (form.province_name) {
            data.province_name = form.province_name
        }
        if (form.city_nmae) {
            data.city_name = form.city_name
        }
        if (form.county_name) {
            data.county_name = form.county_name
        }
        if (form.approve_result) {
            data.approve_result = form.approve_result
        }
        this.queryApplyGroupRoleList(data);
        window.scrollTo(0,0);
    }
    clearFilter = () => {
        this.props.form.resetFields();
    }

    componentDidMount() {
        this.filterData();
    }
    gotoLeaderBonus=(role_apply_id)=>{
        this.context.router.history.push("/leaderbonus?role_apply_id="+role_apply_id);
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        const {selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true,
            onSelection: this.onSelection,
        };
        return (
            <div className="bonus-settle-content">
                <div className="fitlter-content">
                    <Form  className="ant-vertical"layout="inline">
                        <Form.Item label="申请时间">
                            {getFieldDecorator('date')(
                                <DatePicker.RangePicker
                                    className="form-input-long"
                                    onChange={this.onShowTimeChange}
                                    ranges={{
                                        "今日": [moment(), moment()],
                                        '这周': [moment().startOf('week'), moment().endOf('week')]
                                    }}
                                    showTime
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="申请人姓名">
                            {getFieldDecorator('real_name')(
                                <Input className="form-input" placeholder="输入申请人姓名"/>
                            )}
                        </Form.Item>
                        <Form.Item label="申请人手机号">
                            {getFieldDecorator('tel_number')(
                                <Input className="form-input" placeholder="输入手机号"/>
                            )}
                        </Form.Item>
                        <Form.Item label="审核状态：">
                            {getFieldDecorator('approve_result', {
                                initialValue: "3"
                            })(
                                <Select className="goods-id">
                                    <Select.Option value="0">审核未通过</Select.Option>
                                    <Select.Option value="1">审核通过</Select.Option>
                                    <Select.Option value="2">已删除</Select.Option>
                                    <Select.Option value="4">待审核</Select.Option>
                                    <Select.Option value="3">全部</Select.Option>
                                </Select>
                            )}
                        </Form.Item>

                        <Form.Item label="省">
                            {getFieldDecorator('province_name', {
                                initialValue: null
                            })(
                                <Input className="form-input" placeholder="输入省"/>
                            )}
                        </Form.Item>
                        <Form.Item label="市">
                            {getFieldDecorator('city_name', {
                                initialValue: null
                            })(
                                <Input className="form-input" placeholder="输入市"/>
                            )}
                        </Form.Item>
                        <Form.Item label="区">
                            {getFieldDecorator('county_name', {
                                initialValue: null
                            })(
                                <Input className="form-input" placeholder="输入区"/>
                            )}
                        </Form.Item>
                        <Form.Item label="区">
                            {getFieldDecorator('county_name', {
                                initialValue: null
                            })(
                                <Input className="form-input" placeholder="输入区"/>
                            )}
                        </Form.Item>
                    </Form>
                    <div className="action">
                        <Button shape="round" type="primary" onClick={this.filterData}>查询</Button>
                        <span className="text-note" onClick={this.clearFilter}>清空筛选条件</span>
                    </div>
                </div>
                <div className="action" style={{display: this.props.approve_status ? "none" : true}}>
                    <Button shape="round"
                            onClick={this.handleGroupRole.bind(this,null, 1)}
                            disabled={this.state.selectedRows.length ? false : true}
                    >审核通过</Button>
                    <Button shape="round"
                            onClick={this.handleGroupRole.bind(this,null, 0)}
                            disabled={this.state.selectedRows.length ? false : true}
                    >审核拒绝</Button>
                    <Button shape="round"
                            onClick={this.handleGroupRole.bind(this,null, 2)}
                            disabled={this.state.selectedRows.length ? false : true}
                    >删除已选团长</Button>
                    <span>当前已选择{this.state.selectedRows.length}项</span>
                </div>
                <Table
                    className="table"
                    rowKey={(record, index) => record.role_apply_id}
                    columns={this.state.finish_columns}
                    dataSource={this.state.order_list}
                    pagination={false}
                    bordered={true}
                    rowSelection={rowSelection}
                />
                <Pagination
                    className="pagenation"
                    onChange={this.onShowPageChange}
                    onShowSizeChange={this.onShowSizeChange}
                    defaultCurrent={this.state.page}
                    total={this.state.total}
                    showTotal={(total) => `共 ${total} 条`}
                    pageSizeOptions={["10", "30", "50", "100"]}
                    showSizeChanger
                />
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
        order_status_list: state.orderStatusList
    }
}

const LeaderContentForm = Form.create({name: 'leader_content_form'})(LeaderContent);

export default connect(mapStateToProps)(LeaderContentForm);
