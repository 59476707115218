import React from "react";
import { Upload, Modal } from "antd";
import config from "@config/config";
import UploadButton from "./UploadButton";
class UploadComponent extends React.Component {
  constructor(props) {
    super();
    this.state = {
      width: "",
      height: "",
      style: {
        display: "flex",
        flexDirection: "column"
      },
      noteStyle: {
        fontSize: "12px",
        marginLeft: "8px",
        lineHeight: "20px",
        color: "#909399"
      }
    };
  }
  handleBeforeUpload = file => {
    //限制图片 格式、size、分辨率
    const isJPG = file.type === "image/jpeg";
    const isJPEG = file.type === "image/jpeg";
    const isGIF = file.type === "image/gif";
    const isPNG = file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      Modal.error({
        title: "只能上传JPG 、JPEG 、GIF、 PNG格式的图片~"
      });
    } else if (!isLt2M) {
      Modal.error({
        title: "超过2M限制，不允许上传~"
      });
    }
    return new Promise((resolve, reject) => {
      if (!(isJPG || isJPEG || isGIF || isPNG) || !isLt2M) {
        reject(file);
      } else {
        resolve(file && this.checkImageWH(file));
      }
    });
  };
  checkImageWH(file) {
    let self = this;
    return new Promise(function(resolve, reject) {
      let filereader = new FileReader();
      filereader.onload = e => {
        let src = e.target.result;
        const image = new Image();
        image.onload = function() {
          // 获取图片的宽高，并存放到file对象中
          console.log("file width :" + this.width);
          console.log("file height :" + this.height);
          file.width = this.width;
          file.height = this.height;
          self.setState({
            width: this.width,
            height: this.height
          });
          resolve();
        };
        image.onerror = reject;
        image.src = src;
      };
      filereader.readAsDataURL(file);
    });
  }
  render() {
    const uploadProps = {
      action: config.baseUrl + "/ossFile/upload",
      listType: "picture-card",
      withCredentials: true
    };
    return (
      <div className="update" style={this.state.style}>
        <Upload
          {...uploadProps}
          fileList={this.props.fileList}
          onPreview={this.props.onPreview}
          onChange={this.props.onChange}
          beforeUpload={this.handleBeforeUpload}
        >
          {this.props.fileList.length >= 1 ? null : (
            <UploadButton text={this.props.btn_text} />
          )}
        </Upload>
        {this.props.showWH && this.state.width ? (
          <div style={this.state.noteStyle}>
            <span>宽: {this.state.width}</span>
            <span>高: {this.state.height}</span>
          </div>
        ) : null}
      </div>
    );
  }
}

export default UploadComponent;
