import React from "react";
import { Card } from "antd";
import { connect } from "react-redux";
import NavBar from "@/view/component/common/NavBar.js";
import {
  Form,
  Input,
  Button,
  Table,
  DatePicker,
  Modal,
  Icon,
  message,
  Upload,
  Spin,
  Tooltip,
  Select,
  Divider,
  Pagination,
  Tabs
} from "antd";
import "@css/activity.scss";
import UploadButton from "@/view/component/common/UploadButton";
import { formatInt, formatNumber, getQueryString } from "@common/common";
import GoodsChoose from "@/view/component/Goods/GoodsChoose";
import CouponSelectModal from "@/view/component/Campaign/CouponSelectModal";
import cookie from "react-cookies";
import {
  createOrUpdateActivity,
  exchangeActivityGoodsSortNum,
  topCoupon,
  exchangeCouponSortNum,
  queryActivityDetail,
  queryGoodsList,
  topActivityGoods,
  queryCouponList,
  createOrUpdateCoupon
} from "@service/getData";
import moment from "moment";
import config from "@config/config";
import PropTypes from "prop-types";

const REPLY_TEXT = "您好！长按识别下方二维码添加社群，更多精彩福利等着你噢～";

class Market extends React.Component {
  constructor() {
    Market.contextTypes = {
      router: PropTypes.object.isRequired
    };
    super();
    this.state = {
      loading2: false,
      requestUrl: config.baseUrl + "/ossFile/upload",
      integral_columns: [
        {
          title: "商品ID",
          dataIndex: "goods_id"
        },
        {
          title: "商品图片",
          dataIndex: "thumbnail_url",
          render: (text, record) => (
            <div className="relative wd100">
              <img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="100" height="100" />
              {record.onsale !== 1 ? <div className="unsale">已下架</div> : null}
            </div>
          )
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          render: text => <div>￥{text}</div>
        },
        {
          title: "价格",
          dataIndex: "current_price",
          render: text => <div className="input-info">￥{text / 100}</div>
        },
        {
          title: "积分",
          dataIndex: "points_amount",
          render: (text, record, index) => (
            <Input value={text} className="input-info" onChange={this.changeMoney.bind(this, record, "points_amount", index)} />
          )
        },
        {
          title: "抢购价",
          dataIndex: "partake_price",
          render: (text, record, index) => (
            <Input value={text} className="input-info" onChange={this.changeMoney.bind(this, record, "partake_price", index)} />
          )
        },
        {
          title: "积分商城限购",
          dataIndex: "buy_limit",
          render: (text, record, index) => (
            <Input value={text} className="input-info" onChange={this.changeMoney.bind(this, record, "buy_limit", index)} />
          )
        },
        {
          title: "时间",
          dataIndex: "activity_time",
          render: (text, record, index) => (
            <DatePicker.RangePicker
              onChange={this.changeGoodsDate.bind(this, index)}
              className="form-input-date"
              format="YYYY-MM-DD HH:mm:ss"
              showTime
              value={
                record.start_time
                  ? [moment(record.start_time, "YYYY-MM-DD HH:mm:ss"), moment(record.end_time, "YYYY-MM-DD HH:mm:ss")]
                  : null
              }
            />
          )
        },
        {
          title: "操作",
          dataIndex: "action",
          render: (text, record, index) => (
            <div className="input-info">
              {index !== 0 ? (
                this.state.exchange_disabled ? (
                  <Icon className="text-remind mr15" type="arrow-up" />
                ) : (
                  <Icon className="text-note mr15" onClick={this.exchangeGoodsPosition.bind(this, index, -1)} type="arrow-up" />
                )
              ) : null}
              {index !== this.state.select_goods_res.length - 1 ? (
                this.state.exchange_disabled ? (
                  <Icon className="text-remind" type="arrow-down" />
                ) : (
                  <Icon onClick={this.exchangeGoodsPosition.bind(this, index, 1)} className="text-note" type="arrow-down" />
                )
              ) : null}
              {index !== 0 ? (
                this.state.exchange_disabled ? (
                  <p className="text-remind" type="arrow-up">
                    置顶
                  </p>
                ) : (
                  <p className="text-note" onClick={this.exchangeGoodsPosition.bind(this, index, 0)} type="arrow-up">
                    置顶
                  </p>
                )
              ) : null}
              <p onClick={this.deleteGoods.bind(this, record, index)} className="text-note">
                删除
              </p>
            </div>
          )
        }
      ],
      coupon_columns: [
        {
          title: "优惠券ID",
          dataIndex: "coupon_id"
        },
        {
          title: "优惠券类型",
          dataIndex: "coupon_category",
          render: text => {
            return text === "shop_coupon" ? "全店通用券" : "商品优惠券";
          }
        },
        {
          title: "优惠券名称",
          dataIndex: "coupon_name"
        },
        {
          title: "优惠内容",
          dataIndex: "use_min_amount",
          render: (text, record) => {
            if (text === 0) {
              return `无门槛减${record.discount_amount / 100}`;
            }
            return `满${text / 100}减${record.discount_amount / 100}`;
          }
        },
        {
          title: "已领取/剩余",
          dataIndex: "receive_info"
        },
        {
          title: "积分",
          dataIndex: "coupon_reward_point",
          render: (text, record, index) => (
            <Input value={text} className="input-info" onChange={this.changeCoupon.bind(this, record, "coupon_reward_point", index)} />
          )
        },
        {
          title: "积分商城限购",
          dataIndex: "exchange_limit_num",
          render: (text, record, index) => (
            <Input value={text} className="input-info" onChange={this.changeCoupon.bind(this, record, "exchange_limit_num", index)} />
          )
        },
        {
          title: "时间",
          dataIndex: "activity_time",
          render: (text, record, index) => (
            <DatePicker.RangePicker
              onChange={this.changeCouponDate.bind(this, index)}
              className="form-input-date"
              format="YYYY-MM-DD HH:mm:ss"
              showTime
              value={
                record.start_time
                  ? [moment(record.start_time, "YYYY-MM-DD HH:mm:ss"), moment(record.end_time, "YYYY-MM-DD HH:mm:ss")]
                  : null
              }
            />
          )
        },
        {
          title: "操作",
          dataIndex: "action",
          render: (text, record, index) => (
            <div className="input-info">
              {index !== 0 ? (
                this.state.exchange_disabled ? (
                  <Icon className="text-remind mr15" type="arrow-up" />
                ) : (
                  <Icon className="text-note mr15" onClick={this.exchangeCouponPosition.bind(this, index, -1)} type="arrow-up" />
                )
              ) : null}
              {index !== this.state.activity_point_coupon_list.length - 1 ? (
                this.state.exchange_disabled ? (
                  <Icon className="text-remind" type="arrow-down" />
                ) : (
                  <Icon onClick={this.exchangeCouponPosition.bind(this, index, 1)} className="text-note" type="arrow-down" />
                )
              ) : null}
              {index !== 0 ? (
                this.state.exchange_disabled ? (
                  <p className="text-remind" type="arrow-up">
                    置顶
                  </p>
                ) : (
                  <p className="text-note" onClick={this.exchangeCouponPosition.bind(this, index, 0)} type="arrow-up">
                    置顶
                  </p>
                )
              ) : null}
              <p onClick={this.deleteCoupon.bind(this, record, index)} className="text-note">
                删除
              </p>
            </div>
          )
        }
      ],
      activity_columns: [
        {
          title: "活动名称",
          dataIndex: "coupon_name"
        },
        {
          title: "活动类型",
          dataIndex: "activity_type_str"
        },
        {
          title: "活动详情",
          dataIndex: "activity_detail_str",
          width: "200px",
          render: text => {
            const detailArr = text.split(";");
            return (
              <>
                {detailArr.map((item, iindex) => (
                  <p key={iindex}>{item};</p>
                ))}
              </>
            );
          }
        },
        {
          title: "状态",
          dataIndex: "admin_coupon_status",
          render: text => {
            switch (text) {
              case 1:
                return <span className="text-green">进行中</span>;
              case 2:
                return <span className="text-danger">已结束</span>;
              case 3:
                return <span className="text-remind">已失效</span>;
              case 0:
                return <span className="text-remind">未生效</span>;
              default:
                return null;
            }
          }
        },
        {
          title: "订单笔数",
          dataIndex: "order_num"
        },
        {
          title: "下单客户数",
          dataIndex: "pay_user_num"
        },
        {
          title: "实付金额（元）",
          dataIndex: "pay_amount",
          render: text => {
            return text / 100;
          }
        },
        {
          title: "操作 ",
          dataIndex: "action",
          render: (text, record) =>
            record.admin_coupon_status > 1 ? (
              <div>
                <span className="text-note" onClick={this.goto.bind(this, record.coupon_id)}>
                  查看
                </span>
              </div>
            ) : (
              <div>
                <span className="text-note" onClick={this.goto.bind(this, record.coupon_id)}>
                  编辑
                </span>
                <Divider type="vertical" />
                <span className="text-note" onClick={this.createOrUpdateCoupon.bind(this, record.coupon_id)}>
                  失效
                </span>
              </div>
            )
        }
      ],
      campaign_activity_subtype_res_list: [
        {
          activity_subtype: "pointsStore",
          activity_subtype_desc: "规则：签到领取积分，连续签到第三天与第七天积分翻倍，邀请好友助力或签到积分翻倍。",
          activity_subtype_name: "积分商城"
        },
        {
          icon: "iconfont icon-liwu",
          class: "activity-enterGroup icon-content",
          activity_subtype: "enterGroup",
          activity_subtype_desc: "规则：买家通过设置的群二维码进群。开启后首页、商品详情页、订单详情页、客服消息四处均会露出加群提示。",
          activity_subtype_name: "进群有礼"
        },
        {
          icon: "iconfont icon-lianhe4",
          class: "activity-coupon",
          activity_subtype: "full_reduce",
          activity_subtype_desc: "规则：多购买将享受优惠，优惠的方式包括：满N元减/赠、或件数立减金额或送赠品等。",
          activity_subtype_name: "满减/满赠/满折"
        }
      ],
      visible: false,
      search_value: null,
      goods_columns: [
        {
          title: "商品id",
          dataIndex: "goods_id"
        },
        {
          title: "商品图片",
          dataIndex: "thumbnail_url",
          render: (text, record) => (
            <div className="relative wd100">
              <img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="100" height="100" />
              {record.onsale !== 1 ? <div className="unsale">已下架</div> : null}
            </div>
          )
        },
        {
          title: "商品名称",
          dataIndex: "goods_name"
        },
        {
          title: "价格",
          dataIndex: "current_price",
          render: text => <div>￥{text / 100}</div>
        }
      ],
      goods_res_list: [],
      selected_row_keys: [],
      select_goods_res: [],
      current_select_goods_res: [],
      start_time: null,
      end_time: null,
      activity_id: null,
      activity_res: {},
      exchange_disabled: false,
      preview_image: "",
      preview_gallery_image: "",
      preview_gallery_image2: "",
      file_list: [],
      file_list2: [],
      gallery_list: [],
      gallery_list2: [],
      preview_gallery_visible: false,
      preview_gallery_visible2: false,
      show_another: ["advertisement", "topScroll", "endScroll"],
      loading: false,
      campaign_id: null,
      campaign_list: [],
      coupon_res_list: [],
      page: 1,
      page_size: 10,
      total: 0,
      custom_kefu_message: {
        answer_message: REPLY_TEXT,
        answer_type: "text",
        description: "",
        image_url: "",
        message_id: null,
        mini_app_id: cookie.load("mini_app_id"),
        show: 1,
        status: 1,
        title: "",
        user_message: "进群",
        user_message_type: "text"
      },
      tab_active_key: "goods",
      coupon_visible: false,
      activity_point_coupon_list: []
    };
  }

  createOrUpdateActivity = async data => {
    this.setState({
      loading: true
    });
    let res = await createOrUpdateActivity(data);

    if (res.success) {
      this.props.form.resetFields();
      message.success(
        `${
          this.state.activity_res.activity_subtype
            ? this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === this.state.activity_res.activity_subtype)
                .activity_subtype_name
            : null
        }更新成功`
      );
      this.setState(
        {
          visible: false,
          search_value: null,
          goods_res_list: [],
          selected_row_keys: [],
          select_goods_res: [],
          activity_point_coupon_list: [],
          current_select_goods_res: [],
          start_time: null,
          end_time: null,
          activity_res: {},
          exchange_disabled: false,
          gallery_list: [],
          gallery_list2: [],
          loading: false
        },
        () => {
          this.queryActivityDetail();
        }
      );
    } else {
      message.error(res.msg);
    }
  };

  queryActivityDetail = async () => {
    let data = {
      activity_id: this.state.activity_id
    };
    let res = await queryActivityDetail(data);
    if (res.success) {
      let activity_point_coupon_list = res.result.activity_res.activity_point_coupon_list || [];
      let select_goods_res = res.result.activity_res.activity_goods_res_list.map(item => {
        return {
          goods_id: item.goods_res.goods_id,
          goods_name: item.goods_res.goods_name,
          current_price: item.goods_res.current_price,
          start_time: item.start_time,
          end_time: item.end_time,
          draw_time: item.draw_time,
          activity_id: item.activity_id,
          activity_goods_id: item.activity_goods_id,
          status: 1,
          onsale: item.goods_res.onsale,
          gallery_urls: item.goods_res.gallery_urls,
          loading: false,
          partake_price: item.partake_price / 100,
          group_member_num: item.group_member_num,
          buy_limit: item.buy_limit,
          gift_num: item.gift_num,
          points_amount: item.points_amount
        };
      });
      if (res.result.activity_res.target_type === "goods") {
        select_goods_res.push(res.result.activity_res.goods_res);
      }
      let gallery_list = [];
      if (res.result.activity_res.thumbnail_url && res.result.activity_res.activity_subtype !== "enterGroup") {
        gallery_list = [
          {
            uid: "-1",
            name: "xxx.png",
            status: "done",
            url: res.result.activity_res.thumbnail_url
          }
        ];
      }
      let gallery_list2 = [];
      if (res.result.activity_res.transfer_image_url) {
        gallery_list2 = [
          {
            uid: "-1",
            name: "xxx.png",
            status: "done",
            url: res.result.activity_res.transfer_image_url
          }
        ];
      }
      if (res.result.activity_res.activity_subtype === "enterGroup" && res.result.activity_res.message_res_list.length) {
        gallery_list = [
          {
            uid: 0,
            name: "poster",
            status: "done",
            url: res.result.activity_res.message_res_list[0].image_url,
            message_id: res.result.activity_res.message_res_list[0].message_id
          }
        ];
      }
      let custom_kefu_message;
      if (res.result.activity_res.custom_kefu_message) {
        custom_kefu_message = {
          ...this.state.custom_kefu_message,
          ...res.result.activity_res.custom_kefu_message,
          answer_message: REPLY_TEXT
        };
      }
      this.setState({
        select_goods_res: select_goods_res,
        activity_res: res.result.activity_res,
        activity_point_coupon_list,
        gallery_list,
        gallery_list2,
        loading: false,
        custom_kefu_message
      });
    }
  };
  queryGoodsList = async () => {
    this.setState({
      loading2: true
    });
    let data = {
        shop_id: cookie.load("shop_id"),
        onsale: 1
      },
      value = this.state.search_value;
    if (isNaN(+value)) {
      data.goods_name = value;
    } else {
      data.goods_id = value;
    }
    let res = await queryGoodsList(data);
    if (res.success) {
      this.setState({
        loading2: false,
        goods_res_list: res.result.goods_res_list
      });
    }
  };
  exchangeActivityGoodsSortNum = async (index, isUp) => {
    let list = [...this.state.select_goods_res],
      from_activity_goods_id = list[index].activity_goods_id,
      to__activity_goods_id;

    if (isUp < 0) {
      to__activity_goods_id = list[index - 1].activity_goods_id;
    } else {
      to__activity_goods_id = list[index + 1].activity_goods_id;
    }
    let res = await exchangeActivityGoodsSortNum({
      from_activity_goods_id,
      to__activity_goods_id
    });
    if (res.success) {
      message.success("操作成功");
      this.queryActivityDetail();
    }
  };
  topActivityGoods = async activity_goods_id => {
    let res = await topActivityGoods({ activity_goods_id });
    if (res.success) {
      message.success("置顶成功");
      this.queryActivityDetail();
    }
  };
  exchangeCouponSortNum = async (index, isUp) => {
    let list = [...this.state.activity_point_coupon_list],
      from_coupon_id = list[index].coupon_id,
      to_coupon_id;
    if (isUp < 0) {
      to_coupon_id = list[index - 1].coupon_id;
    } else {
      to_coupon_id = list[index + 1].coupon_id;
    }
    let res = await exchangeCouponSortNum({
      from_coupon_id,
      to_coupon_id
    });
    if (res.success) {
      message.success("操作成功");
      this.queryActivityDetail();
    }
  };
  topCoupon = async coupon_id => {
    let res = await topCoupon({ coupon_id });
    if (res.success) {
      message.success("置顶成功");
      this.queryActivityDetail();
    }
  };
  handleCancel = () =>
    this.setState({
      visible: false,
      preview_gallery_visible: false
    });
  handleOk = current_select_goods_res => {
    let select_goods_res = [...this.state.select_goods_res];
    select_goods_res.forEach(item => {
      let index = current_select_goods_res.findIndex(row => row.goods_id === item.goods_id);
      if (index > -1) {
        current_select_goods_res.splice(index, 1);
      }
    });
    if (!this.state.activity_res || !this.state.activity_res.activity_subtype === "pointsStore") {
      current_select_goods_res.forEach(item => {
        item.partake_price = 0.01;
        item.group_member_num = 3;
        item.gift_num = 3;
      });
    }
    select_goods_res = select_goods_res.concat(current_select_goods_res);
    this.setState({
      select_goods_res: select_goods_res,
      visible: false,
      exchange_disabled: true
    });
  };
  searchHandle = e => {
    this.setState({
      search_value: e.target.value
    });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  showCouponModal = () => {
    this.setState({
      coupon_visible: true
    });
  };
  changeGoodsDate = (index, date, dateStr) => {
    if (index > -1) {
      let list = [...this.state.select_goods_res];
      list[index].start_time = dateStr[0];
      list[index].end_time = dateStr[1];
      list[index].draw_time = dateStr[1];

      this.setState({
        select_goods_res: list
      });
    } else {
      this.setState({
        start_time: dateStr[0],
        end_time: dateStr[1]
      });
    }
  };
  changeCouponDate = (index, date, dateStr) => {
    if (index > -1) {
      let list = [...this.state.activity_point_coupon_list];
      list[index].start_time = dateStr[0];
      list[index].end_time = dateStr[1];
      this.setState({
        activity_point_coupon_list: list
      });
    } else {
      this.setState({
        start_time: dateStr[0],
        end_time: dateStr[1]
      });
    }
  };
  exchangeGoodsPosition = async (index, num) => {
    let list = [...this.state.select_goods_res];
    let goods = list[index];
    if (+num === 0) {
      this.topActivityGoods(goods.activity_goods_id);
    } else {
      this.exchangeActivityGoodsSortNum(index, num);
    }
    this.sortHandle(list, num, index);
    this.setState({
      select_goods_res: list
    });
  };
  exchangeCouponPosition = async (index, num) => {
    let list = [...this.state.activity_point_coupon_list];
    let coupon = list[index];
    if (+num === 0) {
      this.topCoupon(coupon.coupon_id);
    } else {
      this.exchangeCouponSortNum(index, num);
    }
    this.sortHandle(list, num, index);
    this.setState({
      activity_point_coupon_list: list
    });
  };
  sortHandle(list, num, index) {
    if (num === -1) {
      if (index === 0) {
        return;
      }
      list.splice(index - 1, 1, ...list.splice(index, 1, list[index - 1]));
    } else if (num === 1) {
      if (index === list.length - 1) {
        return;
      }
      list.splice(index, 1, ...list.splice(index + 1, 1, list[index]));
    } else {
      if (index === 0) {
        return;
      }
      list.splice(0, 0, ...list.splice(index, 1));
    }
  }
  deleteGoods = goods => {
    let select_goods_res = [...this.state.select_goods_res];
    let index = select_goods_res.findIndex(item => item.goods_id === goods.goods_id);
    if (goods.activity_goods_id) {
      select_goods_res[index].status = 0;
    } else {
      select_goods_res.splice(index, 1);
    }
    this.setState({
      select_goods_res,
      exchange_disabled: true
    });
  };
  deleteCoupon = coupon => {
    let data = {
      activity_req: {
        mini_app_id: cookie.load("mini_app_id"),
        activity_id: this.state.activity_res.activity_id,
        target_type: "cancelPointCoupon",
        cancel_point_coupon_id: coupon.coupon_id
      }
    };
    this.createOrUpdateActivity(data);
  };
  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  updateActivity = status => {
    if (!status) {
      status = this.props.form.getFieldValue("status") ? 1 : 0;
    }
    if (this.state.activity_res && this.state.activity_res.activity_subtype === "pointsStore") {
      return this.updateIntegral();
    }
    let form = this.props.form.getFieldsValue();
    let flag = false;
    this.props.form.validateFields(err => {
      if (err) {
        flag = true;
      }
    });
    if (flag) return;
    if (this.state.activity_res.activity_subtype === "enterGroup" && !this.state.gallery_list.length) {
      message.error("请上传社群海报");
      return;
    }
    let gallery_url = "";
    this.state.gallery_list.forEach(item => {
      if (item.response && item.response.success) {
        gallery_url = item.response.result.oss_file_url;
      } else if (item.url) {
        gallery_url = item.url;
      }
    });
    if (this.state.activity_res.activity_subtype === "lucky" && !this.state.select_goods_res.filter(item => item.status > 0).length) {
      return message.error("请选择商品");
    }
    this.state.select_goods_res.forEach(item => {
      if (
        !item.partake_price ||
        Number(item.partake_price) === 0 ||
        !item.group_member_num ||
        !item.gift_num ||
        !item.start_time ||
        !item.end_time
      ) {
        flag = true;
      }
    });
    if (flag) return message.error("商品信息不完整");
    let goods_list = [];
    this.state.select_goods_res.forEach(item => {
      goods_list.push({
        goods_id: item.goods_id,
        start_time: item.start_time,
        end_time: item.end_time,
        draw_time: item.draw_time,
        activity_id: item.activity_id,
        activity_goods_id: item.activity_goods_id,
        partake_price: Math.round(item.partake_price * 100),
        buy_limit: item.buy_limit,
        points_amount: item.points_amount,
        group_member_num: item.group_member_num,
        gift_num: item.gift_num,
        status: item.status
      });
    });
    let gallery_url2 = "";
    this.state.gallery_list2.forEach(item => {
      if (item.response && item.response.success) {
        gallery_url2 = item.response.result.oss_file_url;
      } else if (item.url) {
        gallery_url2 = item.url;
      }
    });
    let message_req_list = [],
      message_list = [];
    if (this.state.activity_res.activity_subtype === "enterGroup") {
      message_req_list = this.state.gallery_list.map(item => {
        let _data = {
          message_id: item.message_id,
          miniAppId: cookie.load("mini_app_id"),
          userMessageType: "miniprogrampage",
          answerType: "image",
          status: 1
        };
        if (item.response && item.response.success) {
          _data.image_url = item.response.result.oss_file_url;
        } else if (item.url) {
          _data.image_url = item.url;
        }
        return _data;
      });
      message_list = JSON.parse(JSON.stringify(message_req_list));
      this.state.activity_res.message_res_list.forEach(item => {
        let _message = message_req_list.find(my => my.message_id === item.message_id);
        if (!_message) {
          message_list.push({
            message_id: item.message_id,
            miniAppId: cookie.load("mini_app_id"),
            userMessageType: "miniprogrampage",
            answerType: "image",
            status: 0,
            image_url: item.image_url
          });
        }
      });
    }
    //积分兑换优惠券
    let coupon_point_list = this.state.activity_point_coupon_list.map(item => ({
      coupon_id: item.coupon_id,
      coupon_reward_point: item.coupon_reward_point,
      exchange_limit_num: item.exchange_limit_num,
      start_time: item.start_time,
      end_time: item.end_time
    }));
    let data = {
      activity_req: {
        mini_app_id: cookie.load("mini_app_id"),
        target_type: this.state.tab_active_key === "goods" ? "goodsList" : "pointCouponList",
        activity_id: this.state.activity_res.activity_id,
        title: form.title ? form.title : null,
        transfer_title: form.transfer_title ? form.transfer_title : null,
        activity_goods_list: goods_list,
        coupon_point_list,
        thumbnail_url: this.state.activity_res.activity_subtype === "enterGroup" ? null : gallery_url,
        transfer_image_url: gallery_url2,
        message_req_list: this.state.activity_res.activity_subtype === "enterGroup" ? message_list : null,
        custom_kefu_message: this.state.custom_kefu_message
      }
    };
    this.createOrUpdateActivity(data);
  };
  updateIntegral = () => {
    let flag = false;
    if (this.state.activity_res.activity_subtype === "pointsStore" && !this.state.select_goods_res.filter(item => item.status > 0).length) {
      return message.error("请选择商品");
    }
    this.state.select_goods_res.forEach(item => {
      if (!item.buy_limit || !item.points_amount || !item.start_time || !item.end_time) {
        flag = true;
      }
    });
    if (flag) return message.error("商品信息不完整");
    let goods_list = [];
    this.state.select_goods_res.forEach(item => {
      goods_list.push({
        goods_id: item.goods_id,
        start_time: item.start_time,
        end_time: item.end_time,
        draw_time: item.draw_time,
        activity_id: item.activity_id,
        activity_goods_id: item.activity_goods_id,
        status: item.status,
        partake_price: Math.round(item.partake_price * 100),
        buy_limit: item.buy_limit,
        points_amount: item.points_amount
      });
    });
    //积分兑换优惠券
    let coupon_point_list = this.state.activity_point_coupon_list.map(item => ({
      coupon_id: item.coupon_id,
      coupon_reward_point: item.coupon_reward_point,
      exchange_limit_num: item.exchange_limit_num,
      start_time: item.start_time,
      end_time: item.end_time
    }));
    let data = {
      activity_req: {
        mini_app_id: cookie.load("mini_app_id"),
        activity_id: this.state.activity_res.activity_id,
        status: 1
      }
    };
    if (this.state.tab_active_key === "goods") {
      data.activity_req.activity_goods_list = goods_list;
      data.activity_req.target_type = "goodsList";
    } else {
      data.activity_req.coupon_point_list = coupon_point_list;
      data.activity_req.target_type = "pointCouponList";
    }
    this.createOrUpdateActivity(data);
  };
  handleGalleryChange = ({ fileList }) => {
    this.setState({ gallery_list: fileList });
  };
  handleGalleryPreview = file => {
    this.setState({
      preview_image: file.url || file.image_url || file,
      preview_gallery_visible: true
    });
  };
  handleGalleryChange2 = ({ fileList }) => {
    this.setState({ gallery_list2: fileList });
  };
  handleGalleryPreview2 = file => {
    this.setState({
      preview_gallery_image2: file.url || file.thumbUrl,
      preview_gallery_visible2: true
    });
  };
  changeActivitySubtype = () => {
    this.props.form.resetFields(["target_type"]);
  };
  changeMoney = (data, attr, index, e) => {
    e.persist();
    let value = e.target.value;
    if (attr === "partake_price") {
      value = formatNumber(value);
    } else {
      value = formatInt(value);
    }
    let list = this.state.select_goods_res.filter(item => item.status === 1);
    list[index][attr] = value;

    this.setState({
      select_goods_res: list
    });
  };
  changeCoupon = (data, attr, index, e) => {
    e.persist();
    let value = e.target.value;
    if (attr === "coupon_reward_point") {
      value = formatNumber(value);
    } else {
      value = formatInt(value);
    }
    let list = this.state.activity_point_coupon_list;
    list[index][attr] = value;

    this.setState({
      activity_point_coupon_list: list
    });
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(file.name + "图片大小超出限制，请修改后重新上传", 0.8);
    }
    return isLt2M;
  };
  componentDidMount() {
    let activity_id = getQueryString("activity_id");
    let campaign_id = getQueryString("campaign_id");
    let activity_res = {
      activity_subtype: "full_reduce",
      activity_goods_res_list: []
    };
    this.setState(
      {
        activity_id,
        campaign_id,
        loading: false,
        activity_res: activity_id ? {} : activity_res
      },
      () => {
        if (activity_id) {
          this.queryActivityDetail();
        } else {
          this.queryCouponList();
        }
      }
    );
  }
  handleGalleryCancel = () => {
    this.setState({
      preview_gallery_visible: false,
      preview_gallery_visible2: false
    });
  };
  queryCouponList = async () => {
    let form = this.props.form.getFieldsValue();

    let data = {
      mini_app_id: cookie.load("mini_app_id"),
      like_condition: form.like_condition,
      status: form.status,
      page_no: this.state.page,
      page_size: this.state.page_size,
      coupon_category: "activity_coupon"
    };
    let res = await queryCouponList(data);
    if (res.success) {
      let { coupon_res_list, total } = res.result;
      this.setState({
        coupon_res_list,
        total
      });
    }
  };
  onShowPageChange = page => {
    this.setState(
      {
        page: page
      },
      () => {
        this.queryCouponList();
      }
    );
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        page_size: pageSize
      },
      () => {
        this.queryCouponList();
      }
    );
  };
  clear = () => {
    this.props.form.resetFields();
  };
  goto = coupon_id => {
    if (coupon_id) {
      return window.open(`${window.location.origin}/fullReduction?coupon_id=${coupon_id}`, "_blank");
    }
    this.context.router.history.push({ pathname: "fullReduction" });
  };
  createOrUpdateCoupon = async coupon_id => {
    let data = {
      coupon_req: {
        coupon_id,
        status: 3,
        coupon_category: "activity_coupon"
      }
    };
    let res = await createOrUpdateCoupon(data);
    if (res.success) {
      message.success("成功失效该活动");
      this.queryCouponList();
    } else {
      message.error(res.msg);
    }
  };
  changeTab = key => {
    this.setState({
      tab_active_key: key
    });
  };
  onCouponCancel = () => {
    this.setState({
      coupon_visible: false
    });
  };
  onCouponOK = coupon_list => {
    console.log(coupon_list);
    let current_coupon_list = this.state.activity_point_coupon_list;
    current_coupon_list.forEach(item => {
      let index = coupon_list.findIndex(row => row.coupon_id === item.coupon_id);
      if (index > -1) {
        coupon_list.splice(index, 1);
        item.status = 1;
      }
    });
    coupon_list = current_coupon_list.concat(coupon_list);
    if (coupon_list.filter(item => item.status === 1).length > 10) {
      return message.error("最多添加10张优惠券");
    }
    coupon_list.forEach((item, index) => {
      item.index = index;
      item.receive_info = item.receive_info || `${item.total_num - item.remain_num}/${item.remain_num}`;
    });
    this.setState({
      coupon_visible: false,
      activity_point_coupon_list: coupon_list,
      exchange_disabled: true
    });
  };
  render() {
    let { menu_code } = this.props;
    const { getFieldDecorator } = this.props.form;
    const theItem =
      this.state.activity_res.activity_subtype &&
      this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === this.state.activity_res.activity_subtype);
    const { activity_subtype } = this.state.activity_res;
    return (
      <div className="market">
        <Spin spinning={this.state.loading}>
          <NavBar
            icon="icon-yingxiao"
            father={menu_code.market_setting}
            router="marketSetting"
            child={
              activity_subtype
                ? this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === activity_subtype)
                    .activity_subtype_name
                : null
            }
          />
          <div className="content">
            <Card style={{ display: this.state.activity_res && activity_subtype !== "pointsStore" ? true : "none" }}>
              <div className="filter-content">
                <div
                  className={
                    activity_subtype
                      ? this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === activity_subtype).class
                      : null
                  }>
                  <i
                    className={
                      activity_subtype
                        ? this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === activity_subtype).icon
                        : null
                    }
                  />
                </div>
                <div className="text-content">
                  <div className="title">
                    {activity_subtype
                      ? this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === activity_subtype)
                          .activity_subtype_name
                      : null}
                    {theItem && theItem.url ? (
                      <Tooltip
                        overlayClassName="market-tooltip"
                        placement="bottom"
                        title={
                          <img
                            alt=""
                            width="256"
                            src={
                              activity_subtype
                                ? this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === activity_subtype).url
                                : null
                            }
                          />
                        }>
                        <span className="text-note pic-show">示例图片</span>
                      </Tooltip>
                    ) : null}
                  </div>
                  <div>
                    {activity_subtype
                      ? this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === activity_subtype)
                          .activity_subtype_desc
                      : null}
                  </div>
                </div>
              </div>
              {activity_subtype === "enterGroup" ? (
                <div className="enterGroupWrap">
                  <img
                    className="enterGroupImg"
                    src="http://udh.oss-cn-hangzhou.aliyuncs.com/5ae26b05-7784-4835-89f1-92ec248810d91603690631512.png"
                    alt="示例图片"
                  />
                  <Card title="客服消息" style={{ width: "356px" }}>
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                      <Form.Item
                        label="社群海报"
                        extra={<div className="form-upload-remind">仅支持jpeg、png 2种格式，图片大小不超过2.0MB</div>}>
                        <Upload
                          accept="image/jpeg,image/jpg,image/png"
                          withCredentials={true}
                          action={this.state.requestUrl}
                          beforeUpload={this.beforeUpload}
                          listType="picture-card"
                          fileList={this.state.gallery_list}
                          onPreview={this.handleGalleryPreview}
                          onChange={this.handleGalleryChange}>
                          {this.state.gallery_list.length > 0 ? null : <UploadButton />}
                        </Upload>
                      </Form.Item>
                      <Form.Item label="回复文案">
                        <div className="replyText">{REPLY_TEXT}</div>
                      </Form.Item>
                    </Form>
                    <Button className="enterGroupSaveBtn" onClick={this.updateActivity.bind(this, null)}>
                      保存
                    </Button>
                  </Card>
                </div>
              ) : null}

              <div className="onsale" style={{ display: activity_subtype === "full_reduce" ? true : "none" }}>
                <Button type="primary" shape="round" onClick={this.goto.bind(this, null)}>
                  新建活动
                </Button>
                <div className="filter-content">
                  <Form>
                    <Form.Item label="活动名称">
                      {getFieldDecorator("like_condition", {
                        initialValue: null
                      })(<Input className="input-short" />)}
                    </Form.Item>
                    <Form.Item label="状态">
                      {getFieldDecorator("status", {
                        initialValue: null
                      })(
                        <Select className="input-short">
                          <Select.Option value="4">未生效</Select.Option>
                          <Select.Option value="1">进行中</Select.Option>
                          <Select.Option value="2">已结束</Select.Option>
                          <Select.Option value="3">已失效</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  </Form>
                  <Button type="primary" shape="round" onClick={this.queryCouponList}>
                    筛选
                  </Button>
                  <span className="text-note" onClick={this.clear}>
                    清空筛选条件
                  </span>
                </div>
                <Table
                  columns={this.state.activity_columns}
                  pagination={false}
                  dataSource={this.state.coupon_res_list}
                  rowKey={record => record.coupon_id}
                  bordered={true}
                />
                <Pagination
                  className="pagenation"
                  onChange={this.onShowPageChange}
                  onShowSizeChange={this.onShowSizeChange}
                  defaultCurrent={this.state.page_no}
                  total={this.state.total}
                  showTotal={total => `共 ${total} 条`}
                  pageSizeOptions={["10", "30", "50", "100"]}
                  showSizeChanger
                />
              </div>
            </Card>
            <Card style={{ display: this.state.activity_res && activity_subtype === "pointsStore" ? true : "none" }}>
              {/* <div className="shopDataTitle">
                <i className="titleSign" />
                积分兑换商城-积分兑换商品
              </div> */}
              <div className="filter-content">
                <div className="icon-content activity-pointsStore">
                  <i className="iconfont icon-jifenduihuan" />
                </div>
                <div className="text-content">
                  <div className="title">积分商城</div>
                  <div>积分兑换商品：用户可以支付一定的积分，获得购买抢购价商品的权益</div>
                </div>
              </div>
            </Card>
            <Card style={{ display: this.state.activity_res && activity_subtype === "pointsStore" ? true : "none" }}>
              <Tabs
                activeKey={this.state.tab_active_key}
                tabBarStyle={{ backgroundColor: "#ffffff", borderRadius: "4px" }}
                onChange={this.changeTab}>
                <Tabs.TabPane tab="商品兑换" key="goods">
                  <div className="action">
                    <Button
                      type="primary"
                      shape="round"
                      disabled={this.props.form.getFieldValue("activity_subtype") === "checkin"}
                      onClick={this.showModal}>
                      选择商品
                    </Button>
                  </div>
                  <Table
                    columns={this.state.integral_columns}
                    rowKey={record => record.goods_id}
                    dataSource={this.state.select_goods_res.filter(item => item.status > 0)}
                    pagination={false}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="优惠券兑换" key="coupon">
                  <div className="action">
                    <Button
                      type="primary"
                      shape="round"
                      disabled={this.props.form.getFieldValue("activity_subtype") === "checkin"}
                      onClick={this.showCouponModal}>
                      选择优惠券
                    </Button>
                  </div>
                  <Table
                    columns={this.state.coupon_columns}
                    rowKey={record => record.coupon_id}
                    dataSource={this.state.activity_point_coupon_list}
                    pagination={false}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Card>
            {activity_subtype === "enterGroup" ? null : (
              <div className="activity-footer" style={{ display: this.state.activity_id ? "block" : "none" }}>
                <Button type="primary" shape="round" onClick={this.updateActivity.bind(this, null)}>
                  保存
                </Button>
                {cookie.load("mini_app_type") === "flagship_version" && activity_subtype !== "enterGroup" ? null : this.state.activity_res
                    .status ? null : (
                  <Button shape="round" onClick={this.updateActivity.bind(this, 1)}>
                    保存并立即启用
                  </Button>
                )}
                <Button shape="round" onClick={this.queryActivityDetail}>
                  取消
                </Button>
              </div>
            )}
          </div>
          <GoodsChoose visible={this.state.visible} handleCancel={this.handleCancel} handleOk={this.handleOk} />
          <CouponSelectModal visible={this.state.coupon_visible} onOk={this.onCouponOK} onCancel={this.onCouponCancel} />
          <Modal visible={this.state.preview_gallery_visible} footer={null} onCancel={this.handleCancel}>
            <img alt="example" style={{ width: "100%" }} src={this.state.preview_image} />
          </Modal>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu_code: state.menuCode,
    activity_subtype: state.activitySubtype
  };
};
const marketForm = Form.create({ name: "market-from" })(Market);

export default connect(mapStateToProps)(marketForm);
