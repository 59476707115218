import React from 'react'
import {Tabs} from 'antd'
import IntegralManage from './IntegralManage'
import IntegralSetting from './children/IntegralSetting'
import NavBar from '@/view/component/common/NavBar.js'
import '@css/integralTab.scss'
 function IntegralTab() {
     return (
         <div className="integral-tab">
             <NavBar icon="icon-zichan" child="现金管理"/>
             <div className="content">
                 <Tabs
                     defaultActiveKey="1"
                     tabBarStyle={{backgroundColor: "#ffffff", borderRadius: "4px"}}
                 >
                     <Tabs.TabPane tab="现金管理" key="1">
                         <IntegralManage className="cashManage"/>
                     </Tabs.TabPane>
                     <Tabs.TabPane tab="提现设置" key="2">
                         <IntegralSetting/>

                     </Tabs.TabPane>
                 </Tabs>
             </div>

         </div>
     )


}

export  default  IntegralTab
