import React from "react";
import { connect } from "react-redux";
import config from "@config/config.js";
import NavBar from "@/view/component/common/NavBar.js";
import UploadComponent from "@/view/component/common/Upload";
import "@css/merchantAdd.scss";
import { getQueryString } from "@common/common";
import {
	Form,
	Collapse,
	Input,
	Button,
	message,
	Modal,
	Select,
	DatePicker
} from "antd";
import PropTypes from "prop-types";
import {
	queryMchApplyDetail,
	createOrUpdateMchApply
} from "@service/getData";
import moment from 'moment';
import cookie from 'react-cookies';

const reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

class MerchantManage extends React.Component {
	constructor(props) {
		super();
		MerchantManage.contextTypes = {
			router: PropTypes.object.isRequired
		};
		this.state = {
			disabled: null,
			apply_status: {
				1: "未提交",
				2: "已提交",
				3: "审核失败",
				4: "已入驻"
			},
			apply_status_tips: {
				1: "待DTC Mall向微信提交申请。",
				2: "资料审核中，微信审核需要1~5天。",
				3: "微信审核失败，请修改资料重新提交。",
				4: "微信审核成功，微信商户已成功入驻。"
			},
			mch_apply: {
				mch_apply_id: "",
				name: "",
				contact_name: "",
				mobile: "",
				email: "",
				contact_id_number: '',
				mch_short_name: "",
				legal_person: '',
				kefu_mobile: "",
				license: "",
				license_number: '',
				subject_type: '',
				operator_type: 1,
				id_card_front: "",
				id_card_behind: "",
				id_card_no: "",
				id_card_name: "",
				id_card_begin: null,
				id_card_end: null,
				bank_address_code: '',
				bank_name: "",
				branch_bank_name: "",
				bank_account: ""
			},
			license_list: [],
			qualifications_list: [],
			id_card_front_list: [],
			id_card_behind_list: [],
			previewImage: "",
			previewVisible: false,
			notVisible: false,
			qr_code: '',
		};
	}
	componentDidMount() {
		let mch_apply_id = getQueryString("mch_apply_id"),
			disabled = getQueryString("disabled");
		this.setState({
			disabled
		});
		if (mch_apply_id) {
			this.queryMchApplyDetail(mch_apply_id);
		}
	}
	queryMchApplyDetail = async mch_apply_id => {
		let res = await queryMchApplyDetail({
			mch_apply_id
		});
		let mch_apply = res.result.mch_apply,
			license_list = [
				{
					uid: "-1",
					name: "xxx",
					status: "done",
					url: mch_apply.license
				}
			],
			id_card_front_list = [
				{
					uid: "-2",
					name: "xxx",
					status: "done",
					url: mch_apply.id_card_front
				}
			],
			id_card_behind_list = [
				{
					uid: "-3",
					name: "xxx",
					status: "done",
					url: mch_apply.id_card_behind
				}
			],
			qualifications_list = [
				{
					uid: '-4',
					name: 'xxx',
					status: 'done',
					url: mch_apply.qualifications
				}
			];
		this.setState({
			mch_apply,
			license_list,
			id_card_front_list,
			id_card_behind_list,
			qualifications_list,
		});
	};
	handleSubmit = () => {
		const { getFieldValue } = this.props.form;
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (err) {
				return;
			}
			if (this.state.license_list.length === 0) {
				message.warning("请上传营业执照照片");
				return;
			}
			if (this.state.id_card_front_list.length === 0) {
				message.warning("请上传身份证正面照片");
				return;
			}
			if (this.state.id_card_behind_list.length === 0) {
				message.warning("请上传身份证反面照片");
				return;
			}
			if (getFieldValue('qualification_type') === '食品生鲜' && this.state.qualifications_list.length === 0) {
				message.warning('请上传食品经营许可证');
				return;
			}
			this.createOrUpdateMchApply(values);
		});
	};
	createOrUpdateMchApply = async data => {
		let {
			name,
			contact_name,
			mobile,
			email,
			contact_id_number,
			mch_short_name,
			legal_person,
			kefu_mobile,
			operator_type,
			id_card_no,
			license_number,
			subject_type,
			id_card_name,
			bank_address_code,
			bank_name,
			branch_bank_name,
			bank_account,
			qualification_type,
		} = data;
		let mch_apply = {
			name,
			contact_name,
			mobile,
			email,
			contact_id_number,
			mch_short_name,
			legal_person,
			kefu_mobile,
			operator_type,
			id_card_no,
			license_number,
			subject_type,
			id_card_name,
			bank_address_code,
			bank_name,
			branch_bank_name,
			bank_account,
			id_card_begin: this.state.mch_apply.id_card_begin,
			id_card_end: this.state.mch_apply.id_card_end,
			license: this.state.license_list[0].url,
			qualifications: this.state.qualifications_list.length ? this.state.qualifications_list[0].url : null,
			id_card_front: this.state.id_card_front_list[0].url,
			id_card_behind: this.state.id_card_behind_list[0].url,
			shop_id: cookie.load('shop_id'),
			qualification_type,
		};
		if (this.state.mch_apply.mch_apply_id) {
			mch_apply.mch_apply_id = this.state.mch_apply.mch_apply_id;
			mch_apply.business_code = this.state.mch_apply.business_code;
		}
		let res = await createOrUpdateMchApply({ mch_apply });
		if (res.success && res.result.qr_code) {
			this.clearFormData();
			this.setState({
				notVisible: true,
				qr_code: res.result.qr_code,
			});
		}
		else if (res.success && !res.result.qr_code) {
			message.error(res.result.err_msg, 3, () => {
				this.context.router.history.push(`/merchantAdd?mch_apply_id=${res.result.mch_apply_id}`);
				this.queryMchApplyDetail(res.result.mch_apply_id);
			});
		}
		else {
			message.error(res.msg || '服务器出错了哦,请联系技术小哥');
		}
	};
	clearFormData() {
		const form = this.props.form;
		form.resetFields();
		this.setState({
			mch_apply: {
				mch_apply_id: "",
				name: "",
				contact_name: "",
				mobile: "",
				email: "",
				contact_id_number: '',
				mch_short_name: "",
				legal_person: '',
				kefu_mobile: "",
				license: "",
				license_number: '',
				subject_type: '',
				operator_type: 1,
				id_card_front: "",
				id_card_behind: "",
				id_card_no: "",
				id_card_name: "",
				id_card_begin: null,
				id_card_end: null,
				bank_address_code: '',
				bank_name: "",
				branch_bank_name: "",
				bank_account: ""
			},
			license_list: [],
			qualifications_list: [],
			id_card_front_list: [],
			id_card_behind_list: [],
			previewImage: "",
			previewVisible: false,
		});
	}
	handlePreview = file => {
		this.setState({
			previewImage: file.url || file.thumbUrl,
			previewVisible: true
		});
	};
	handleChange = (name, info) => {
		let fileList = info.fileList;
		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.result.oss_file_url;
			}
			return file;
		});
		let obj = {};
		obj[name] = fileList;
		this.setState(obj);
	};
	handleCancel = () => this.setState({ previewVisible: false });
	// 有效时间
	onShowTimeChange = (date, dateStr) => {
		this.setState((preState) => ({
			mch_apply: { ...preState.mch_apply, id_card_begin: dateStr[0], id_card_end: dateStr[1] },
		}));
	};
	finished() {
		this.setState({
			notVisible: false,
		}, () => {
			this.context.router.history.push('/merchantManage');
		});
	}
	render() {
		let { menu_code } = this.props;
		const Panel = Collapse.Panel;
		const customPanelStyle = {
			borderRadius: 4,
			marginBottom: 24,
			border: 0,
			overflow: "hidden"
		};
		const formLayout = {
			labelCol: { span: 4 },
			wrapperCol: { span: 13 }
		};
		let mch_apply = this.state.mch_apply;
		const { getFieldDecorator, getFieldValue } = this.props.form;
		let license_list = this.state.license_list;
		let qualifications_list = this.state.qualifications_list;
		let id_card_front_list = this.state.id_card_front_list;
		let id_card_behind_list = this.state.id_card_behind_list;
		const { TextArea } = Input;
		const { Option } = Select;
		const disabled = this.state.disabled;
		return (
			<div className="merchant-manage">
				<div className="content">
					<NavBar
						icon="icon-shezhi"
						father={menu_code.manage_manage}
						child={menu_code.manage_add}
						router="merchantManage"
					/>
					<div className="merchant-manage-content">
						<div className="filter-content">
							<div className="text-content">
								<div className="title">
									申请微信商户后，店铺所产生的资金流水将直接进入您的微信商户中，T+1结算。
                				</div>
								<div>
									用户所填信息全部应用于微信商户号申请，绝不向第三方透露或做其他用途
                  					<a
										className="ml10"
										target="_blank"
										href="https://pay.weixin.qq.com/"
										rel="noopener noreferrer"
									>
										查看说明文档{">"}
									</a>
								</div>
							</div>
						</div>
						{this.state.mch_apply.mch_apply_id &&
							<p className="errMessage">{this.state.mch_apply.err_msg}</p>
						}
						<div className="form-content">
							<Form {...formLayout}>
								<Collapse
									bordered={false}
									defaultActiveKey={["1", "2", "3", "4"]}
									showArrow={false}
								>
									<Panel
										header="基本信息填写（必填）"
										key="1"
										style={customPanelStyle}
									>
										<div className="panel-item">
											<Form.Item
												label="联系人姓名"
												extra="请填写商户微信支付业务联系人的真实姓名，我们会在微信签约时进行实名认证"
											>
												{getFieldDecorator("contact_name", {
													rules: [
														{
															required: true,
															message: "请输入联系人姓名！"
														}
													],
													initialValue: mch_apply.contact_name
												})(<Input readOnly={disabled ? true : false} />)}
											</Form.Item>

											<Form.Item
												label="手机号"
												extra="用于接收微信支付的重要管理信息及日常操作验证码，我们会在微信签约时进行短信验证"
											>
												{getFieldDecorator("mobile", {
													rules: [
														{
															required: true,
															message: "请输入手机号！"
														}
													],
													initialValue: mch_apply.mobile
												})(
													<Input
														readOnly={disabled ? true : false}
														maxLength={11}
													/>
												)}
											</Form.Item>
											<Form.Item
												label="邮箱"
												extra="非常重要！用于接收微信支付的账号密码"
											>
												{getFieldDecorator("email", {
													rules: [
														{
															required: true,
															message: "请输入邮箱！"
														}
													],
													initialValue: mch_apply.email
												})(<Input readOnly={disabled ? true : false} />)}
											</Form.Item>
											<Form.Item
												label="身份证号码"
												extra="非常重要！我们会在微信签约时进行实名认证"
											>
												{getFieldDecorator('contact_id_number', {
													rules: [
														{
															required: true,
															message: '请填写身份证号码！',
														},
														{
															pattern: reg,
															message: '身份证号码无效',
														},
													],
													initialValue: mch_apply.contact_id_number,
												})(<Input readOnly={disabled ? true : false} />)}
											</Form.Item>
											<Form.Item
												label="客服电话"
												extra="微信可能将审核该电话号码，座机或手机号均可"
											>
												{getFieldDecorator("kefu_mobile", {
													rules: [
														{
															required: true,
															message: "请输入客服电话！"
														}
													],
													initialValue: mch_apply.kefu_mobile
												})(<Input readOnly={disabled ? true : false} />)}
											</Form.Item>
										</div>
									</Panel>
									<Panel
										header="商户信息填写（必填）"
										key="2"
										style={customPanelStyle}
									>
										<div className="panel-item">
											<Form.Item label="营业执照照片">
												{getFieldDecorator("license", {
													rules: [
														{
															required: true,
															message: "请上传营业执照照片！"
														}
													],
													initialValue: mch_apply.license
												})(
													<div className="flex">
														<UploadComponent
															fileList={license_list}
															onPreview={this.handlePreview}
															onChange={this.handleChange.bind(
																this,
																"license_list"
															)}
															btn_text="上传照片"
														/>
														<div className="text-note">
															<span>
																需年检章齐全(当年成立公司除外),
																照片需为彩色图片并小于2M、文件格式为bmp、png、jpeg、jpg或gif
                              								</span>
															{license_list.length ? (
																<a
																	href={
																		config.baseUrl +
																		"/file/download?url=" +
																		license_list[0].url
																	}
																>
																	下载
																</a>
															) : null}
														</div>
													</div>
												)}
											</Form.Item>
											<Form.Item label="企业名称" extra="与营业执照上的一致">
												{getFieldDecorator("name", {
													rules: [
														{
															required: true,
															message: "请输入企业名称！"
														}
													],
													initialValue: mch_apply.name
												})(<Input readOnly={disabled ? true : false} />)}
											</Form.Item>
											<Form.Item label="企业信用代码" extra="营业执照上18位信用代码">
												{getFieldDecorator('license_number', {
													rules: [
														{
															required: true,
															message: '请填写企业信用代码',
														},
													],
													initialValue: mch_apply.license_number,
												})(
													<Input />
												)}
											</Form.Item>
											<Form.Item label="主体类型">
												{getFieldDecorator('subject_type', {
													rules: [
														{
															required: true,
															message: '请选择主体类型',
														},
													],
													initialValue: mch_apply.subject_type,
												})(
													<Select>
														<Option value={1}>企业</Option>
														<Option value={0}>个体户</Option>
													</Select>
												)}
											</Form.Item>
											<Form.Item
												label="商户简称"
												extra="该简称将在支付完成页向买家展示，需与微信经营类目相关"
											>
												{getFieldDecorator("mch_short_name", {
													rules: [
														{
															required: true,
															message: "请输入商户简称！"
														}
													],
													initialValue: mch_apply.mch_short_name
												})(<Input readOnly={disabled ? true : false} />)}
											</Form.Item>
											<Form.Item
												label="个体户经营者/法人姓名"
												extra="请填写营业执照的经营者/法定代表人姓名"
											>
												{getFieldDecorator("legal_person", {
													rules: [
														{
															required: true,
															message: "请填写法人姓名"
														}
													],
													initialValue: mch_apply.legal_person
												})(<Input readOnly={disabled ? true : false} />)}
											</Form.Item>
											<Form.Item label="经营行业">
												{getFieldDecorator('qualification_type', {
													rules: [
														{
															required: true,
															message: '请选择经营行业',
														},
													],
													initialValue: mch_apply.qualification_type,
												})(
													<Select>
														<Option value={'食品生鲜'}>食品生鲜</Option>
														<Option value={'零售批发/生活娱乐/网上商城/其它'}>零售批发/生活娱乐/网上商城/其它</Option>
														<Option value={'门户论坛/网络广告及推广/软件开发/其他'}>门户论坛/网络广告及推广/软件开发/其他</Option>
													</Select>
												)}
											</Form.Item>
											{getFieldValue('qualification_type') === '食品生鲜' ? (
												<Form.Item label="《食品经营许可证》">
													{getFieldDecorator('qualifications', {
														rules: [
															{
																required: true,
																message: '请上传食品经营许可证',
															},
														],
														initialValue: mch_apply.qualifications
													})(
														<div className="flex">
															<UploadComponent
																fileList={qualifications_list}
																onPreview={this.handlePreview}
																onChange={this.handleChange.bind(
																	this,
																	"qualifications_list"
																)}
																btn_text="上传照片"
															/>
															<div className="text-note">
																<span>
																	上传原件或复印件，复印件务必加盖公司公章<br />
																	文件格式为jpg、jpeg、bmp、gif、或png,文件大小不超过5M
																</span>
																{qualifications_list.length ? (
																	<a
																		href={
																			config.baseUrl +
																			"/file/download?url=" +
																			qualifications_list[0].url
																		}
																	>
																		下载
																	</a>
																) : null}
															</div>
														</div>
													)}
												</Form.Item>
											) : null}
										</div>
									</Panel>
									<Panel
										header="企业法人/经办人（必填）"
										key="3"
										style={customPanelStyle}
									>
										<div className="panel-item">
											<Form.Item label="联系人类型">
												{getFieldDecorator("operator_type", {
													rules: [
														{
															required: true,
															message: "请选择联系人类型！"
														}
													],
													initialValue: mch_apply.operator_type
												})(
													<Select className="mb15">
														<Option value={1}>企业法人</Option>
														<Option value={2}>经办人</Option>
													</Select>
												)}
											</Form.Item>
											<Form.Item label="身份证正反照片">
												{getFieldDecorator("id_card_front")(
													<div className="flex">
														<div className="flex-col">
															<UploadComponent
																fileList={id_card_front_list}
																onPreview={this.handlePreview}
																onChange={this.handleChange.bind(
																	this,
																	"id_card_front_list"
																)}
																btn_text="上传正面照片"
															/>
															{id_card_front_list.length ? (
																<a
																	className="text-info"
																	href={
																		config.baseUrl +
																		"/file/download?url=" +
																		id_card_front_list[0].url
																	}
																>
																	下载
																</a>
															) : null}
														</div>
														<div className="flex-col">
															<UploadComponent
																fileList={id_card_behind_list}
																onPreview={this.handlePreview}
																onChange={this.handleChange.bind(
																	this,
																	"id_card_behind_list"
																)}
																btn_text="上传反面照片"
															/>
															{id_card_behind_list.length ? (
																<a
																	className="text-info"
																	href={
																		config.baseUrl +
																		"/file/download?url=" +
																		id_card_behind_list[0].url
																	}
																>
																	下载
																</a>
															) : null}
														</div>
														<div className="text-note">
															照片需为彩色图片并小于2M，文件格式为bmp、png、jpeg、jpg或gif
                           								</div>
													</div>
												)}
											</Form.Item>
											<Form.Item label="身份证号码">
												{getFieldDecorator("id_card_no", {
													rules: [
														{
															required: true,
															message: "请输入身份证号码！"
														}
													],
													initialValue: mch_apply.id_card_no
												})(
													<Input
														readOnly={disabled ? true : false}
														className="mb15"
													/>
												)}
											</Form.Item>
											<Form.Item label="身份证姓名">
												{getFieldDecorator("id_card_name", {
													rules: [
														{
															required: true,
															message: "请输入身份证姓名！"
														}
													],
													initialValue: mch_apply.id_card_name
												})(
													<Input
														readOnly={disabled ? true : false}
														className="mb15"
													/>
												)}
											</Form.Item>
											<Form.Item required label="身份证有效期">
												<DatePicker.RangePicker
													className="specialInputWrap"
													value={
														this.state.mch_apply.id_card_begin
															? [moment(this.state.mch_apply.id_card_begin, "YYYY-MM-DD"), moment(this.state.mch_apply.id_card_end, "YYYY-MM-DD")]
															: null
													}
													format="YYYY-MM-DD"
													onChange={this.onShowTimeChange.bind(this)}
												/>
											</Form.Item>
										</div>
									</Panel>
									<Panel
										header="结算账户（必填）"
										key="4"
										style={customPanelStyle}
									>
										<div className="panel-item">
											<Form.Item
												label="开户银行省市编码"
												extra={
													<a
														download
														href="http://udh.oss-cn-hangzhou.aliyuncs.com/b02b9930-e0f1-4555-a433-fac183824a55省市区编号对照表.xlsx"
														style={{ fontSize: '14px', color: '#1890ff', cursor: 'point' }}
													>
														省市区编号对照表
													</a>
												}
											>
												{getFieldDecorator("bank_address_code", {
													rules: [
														{
															required: true,
															message: "请输入开户银行省市编码！"
														}
													],
													initialValue: mch_apply.bank_address_code
												})(
													<Input
														readOnly={disabled ? true : false}
														className="mb15"
													/>
												)}
											</Form.Item>
											<Form.Item label="基本户开户行">
												{getFieldDecorator("bank_name", {
													rules: [
														{
															required: true,
															message: "请输入基本户开户行！"
														}
													],
													initialValue: mch_apply.bank_name
												})(
													<Input
														readOnly={disabled ? true : false}
														className="mb15"
													/>
												)}
											</Form.Item>
											<Form.Item
												label="基本户开户支行"
												extra="例：xxx省xxx市xxx支行"
											>
												{getFieldDecorator("branch_bank_name", {
													rules: [
														{
															required: true,
															message: "请输入基本户开户支行！"
														}
													],
													initialValue: mch_apply.branch_bank_name
												})(<Input readOnly={disabled ? true : false} />)}
											</Form.Item>
											<Form.Item label="基本户银行账号">
												{getFieldDecorator("bank_account", {
													rules: [
														{
															required: true,
															message: "请输入基本户银行账号！"
														}
													],
													initialValue: mch_apply.bank_account
												})(
													<Input
														readOnly={disabled ? true : false}
														className="mb15"
													/>
												)}
											</Form.Item>
										</div>
									</Panel>
								</Collapse>
							</Form>
						</div>
						{!this.state.disabled ? (
							<div className="btn-content">
								<Button
									className="submit-btn"
									type="primary"
									onClick={this.handleSubmit}
								>
									提交信息
                				</Button>
							</div>
						) : null}
					</div>
				</div>
				{/* 证件预览弹窗 */}
				<Modal
					visible={this.state.previewVisible}
					footer={null}
					onCancel={this.handleCancel}
					okButtonProps={{ shape: 'round' }}
					cancelButtonProps={{ shape: 'round' }}
				>
					<img
						alt="example"
						style={{ width: "100%" }}
						src={this.state.previewImage}
					/>
				</Modal>
				<Modal
					visible={this.state.notVisible}
					onOk={this.finished.bind(this)}
					onCancel={this.finished.bind(this)}
					cancelText="返回"
					okText="我知道了"
					title="申请单创建成功"
				>
					<p style={{ textAlign: 'center' }}>请微信扫码核对申请单信息</p>
					<img style={{ display: 'block', margin: '8px auto', width: '160px', height: '160px' }} src={this.state.qr_code} alt="image" />
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		menu_code: state.menuCode
	};
};

const MerchantManageForm = Form.create({ name: "merchant_manage_form" })(
	MerchantManage
);

export default connect(mapStateToProps)(MerchantManageForm);
