import React from 'react'
import {connect} from "react-redux";
import '@css/bonusSettle.scss'
import {Tabs} from 'antd'
import NavBar from '@/view/component/common/NavBar.js'
import LeaderContent from '@/view/component/Setting/LeaderBonusContent'

class LeaderManage extends React.Component {
    constructor(props) {
        super()

    }

    render() {
        let {menu_code} = this.props
        return (
            <div className="bonus-settle">
                <NavBar  icon="icon-yingxiao"
                         father="社区团购"child={menu_code.leader_bonus}
                         router="leaderManage"
                />
                <div className="content">
                    <div className="bonus-tab">
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="待审核" key="1">
                                <LeaderContent status={0}/>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="已审核" key="2">
                                <LeaderContent status={1}/>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="已提现" key="3">
                                <LeaderContent status={2}/>
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode

    }
}
export default connect(mapStateToProps)(LeaderManage);
