import React from 'react'
import '@css/leaderContent.scss'
import {connect} from "react-redux";
import {Table, Pagination} from 'antd'
import {queryGroupPromotionList} from '@service/getData'
import PropTypes from 'prop-types'
import {getQueryString} from '@common/common'

class LeaderBonusContent extends React.Component {
    constructor(props) {
        super(props)
        LeaderBonusContent.contextTypes = {
            router: PropTypes.object.isRequired
        }
        this.state = {
            total_amount:0,
            columns: [{

                title: '订单号',
                dataIndex: 'order_sn',

            }, {

                title: '金额',
                dataIndex: 'amount',
                render:(text)=>{
                    return text/100
                }
            }, {

                title: '交易时间',
                dataIndex: 'operate_time'
            }
            ],
            finish_columns: [{

                title: '流水',
                dataIndex: 'payment_no',

            }, {

                title: '金额',
                dataIndex: 'amount',
                render:(text)=>{
                    return text/100
                }
            }, {

                title: '交易时间',
                dataIndex: 'operate_time'
            }
            ],

            order_list: null,
            role_apply_id:null
        }

    }


    async queryGroupPromotionList(data) {

        let res = await queryGroupPromotionList(data)
        if (res.success) {

            this.setState({
                order_list: res.result.group_promotion_res_list,
                total:res.result.total?res.result.total:0,
                total_amount:res.result.total_amount
            })
        }

    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys,
            selectedRows
        });
    }
    onShowPageChange = (page) => {
        this.setState({
            page: page
        }, () => {
            this.filterData()
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.filterData()
        })
    }
    filterData = () => {
        if(this.state.role_apply_id){
            let data={
                role_apply_id:this.state.role_apply_id,
                status:this.props.status
            }
            this.queryGroupPromotionList(data);
        }
        window.scrollTo(0,0);
    }

    componentDidMount() {
        let  role_apply_id=getQueryString("role_apply_id")

        this.setState({
            role_apply_id:role_apply_id
        },()=>{this.filterData()})

    }


    render() {

        return (
            <div className="bonus-settle-content">
                <p>总额：{this.state.total_amount/100}元</p>
                <Table
                    className="table"
                    rowKey={(record, index) => index}
                    columns={this.props.status===2?this.state.finish_columns:this.state.columns}
                    dataSource={this.state.order_list}
                    pagination={false}
                    bordered={true}
                />
                <Pagination
                    className="pagenation"
                    onChange={this.onShowPageChange}
                    onShowSizeChange={this.onShowSizeChange}
                    defaultCurrent={this.state.page}
                    total={this.state.total}
                    showTotal={(total) => `共 ${total} 条`}
                    pageSizeOptions={["10", "30", "50", "100"]}
                    showSizeChanger
                />
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
        order_status_list: state.orderStatusList
    }
}


export default connect(mapStateToProps)(LeaderBonusContent);
