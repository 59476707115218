import React from 'react'
import {Modal, Carousel, Collapse, Divider,Icon,Radio} from 'antd'
import '@css/goodsShow.scss'

class GoodsShow extends React.Component {


    render() {
        return (
            <Modal
                className="goods-show"
                visible={this.props.visible}
                title="商品页面预览"
                onOk={this.props.onOK}
                onCancel={this.props.onOK}
                okButtonProps={{shape:'round'}}
                cancelButtonProps={{shape:'round'}}

            >
                <div className="iphone-content">
                    <div className="content">
                        <Carousel>
                            {
                                this.props.goods.gallery_urls? (JSON.parse(this.props.goods.gallery_urls).map((item,index )=> (
                                    <img key={index} alt="图片" src={item}/>
                                ))):null
                            }
                        </Carousel>
                        <div className="goods-info">
                            <div className="goods-name">
                                {this.props.goods.goods_name}
                            </div>
                            <Divider type="vertical"/>
                            <div className="goods-share">
                                <i className="iconfont icon-fen_xiang"></i>
                                <div className="goods-share-desc">分享</div>
                            </div>
                        </div>
                        <div className="price">
                            <span className="current-price">¥{this.props.goods.current_price/100}</span>
                            <span className="original-price">¥{this.props.goods.original_price/100}</span>
                        </div>
                        <div className="goods-rank">
                            <Collapse defaultActiveKey={['1']}>
                                <Collapse.Panel header="推荐理由" key="1">
                                    <p>{this.props.goods.goods_desc}</p>
                                </Collapse.Panel>
                            </Collapse>

                        </div>
                        <div className="goods-rank">
                            <Collapse defaultActiveKey={['1']}>
                                <Collapse.Panel header="他们也抢了这个商品(234)" key="1">
                                    <p></p>
                                </Collapse.Panel>
                            </Collapse>

                        </div>
                        <div className="goods-rank">
                            <Collapse defaultActiveKey={['1']}>
                                <Collapse.Panel header="商品评价(234)" key="1">
                                    <p></p>
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                        <div className="goods-show-detail">
                            <div className='goods-show-detail-title'>
                                <Divider>
                                商品详情
                                </Divider>
                            </div>
                            {
                                this.props.goods.image_url? (JSON.parse(this.props.goods.gallery_urls).map((item,index )=> (
                                    <img key={index} width="280px"alt="图片" src={item}/>
                                ))):null
                            }

                        </div>
                    </div>
                    <footer className="goods-detail-footer">
                        <Icon type="home"
                              style={{fontSize:18}}
                        />
                        <Divider type="vertical"/>
                        <Icon type="shopping-cart"
                              style={{fontSize:18,
                              }}
                        />
                        <Radio.Group
                            defaultValue="a" buttonStyle="solid">
                            <Radio.Button value="a">加入购物车</Radio.Button>
                            <Radio.Button value="b">直接购买</Radio.Button>
                        </Radio.Group>
                    </footer>
                </div>

            </Modal>
        )
    }
}

export default GoodsShow
