import axios from "axios";
import config from "@config/config.js";
import cookie from "react-cookies";
import {message} from 'antd'
axios.defaults.withCredentials = true;

axios.interceptors.response.use(data=> {

    if (data.status && data.status === 200 && data.data.status === 'error') {
        message.error({message: data.data.msg});
        return;
    }
    return data;
}, err=> {
    if (err.response.status === 504||err.response.status === 404) {
        message.error('服务器被吃了⊙﹏⊙∥');
    } else if (err.response.status === 403) {
        message.error('权限不足,请联系管理员');
    } else if (err.response.status === 500) {
        message.error('服务器内部错误');
    }else {
        message.error(err.response.data.msg?err.response.data.msg:"服务器内部错误");
    }
    return Promise.reject(err);
})

export function get(url, params) {
  url = config.baseUrl + url;
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: params, responseType: "arraybuffer" })
      .then(res => {
        return (
          "data:image/png;base64," +
          btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          )
        );
      })
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function post(url, data) {
  url = config.baseUrl + url;
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, {
        credentials: "include",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        cache: "force-cache"
      })
      .then(res => {
        if (res.data.code&&res.data.code === 7000000) {
          let cookies = cookie.loadAll();
          Object.keys(cookies).forEach(item => {
            cookie.remove(item);
          });
          window.location.href = window.location.origin + "/login";
          return;
        }
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
