import React from 'react';
import {Button, Form, Input, Icon, Tooltip} from 'antd'

class CouponButton extends React.Component {
    constructor(props) {
        super(props);
    }
    downLoadPost() {
        this.props.downLoadPost();
    }
    copy() {
        this.props.copy();
    }
    render() {
        return (
            <div className="wrap">
                <div className="coupon-post-form">
                    <Form layout="vertical">
                        <Form.Item label={<div>推广小程序路径
                            <Tooltip
                                overlayClassName="post-desc"
                                style={{width:'400px'}} title={<div>
                                通过填写小程序页面路径，直接使内容链接到指定的小程序页面。可用于以下场景：
                                <br/>
                                1.关联公众号：在公众号文章中添加小程序卡片
                                <br/>
                                2. 直播发券：直接复制路径到mp后台商品库中，引导用户领券
                            </div>}>
                                <Icon type="question-circle" theme="twoTone" />
                            </Tooltip>
                            ：
                        </div>}>
                            <Input.Search
                                style={{width: 200}}
                                readOnly
                                value={`pages/couponDetail/index?coupon_id=${this.props.coupon.coupon_id}&receive=1`}
                                size="small"
                                placeholder="input search text"
                                enterButton={
                                    <Button
                                        type="primary"
                                        shape="round">复制</Button>
                                }
                                onSearch={this.copy.bind(this)}
                            />
                        </Form.Item>
                    </Form>
                    <footer>
                        <Button shape="round" type="primary" onClick={this.downLoadPost.bind(this)}>电脑下载</Button>
                        <Tooltip
                            overlayClassName="photo-show"
                            autoAdjustOverflow={false}
                            placement="top" title={
                            <img alt=""
                                width={128}
                                src={this.props.qr_url}/>}>
                            <Button shape="round" type="primary">手机扫描下载</Button>
                        </Tooltip>
                        <Button shape="round" id="my-tooltip">
                            <a download="qrcode.png" href={this.props.url}>小程序码下载</a>
                        </Button>
                    </footer>
                </div>
            </div>
        )
    }
}

export default CouponButton;