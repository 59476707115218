import React from 'react'
import {Modal} from 'antd'
import { SketchPicker} from 'react-color';
class  ColorPicker extends React.Component{
    constructor(){
        super()
        this.state = {
            background: '#fff',
        };
    }
    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };

    render(){
        return(
            <Modal
                visible={this.props.visible}
                onOk={this.props.onOk.bind(this,this.state.background)}
                onCancel={this.props.onCancel}
                okButtonProps={{shape:'round'}}
                cancelButtonProps={{shape:'round'}}
            >
                <SketchPicker
                    color={ this.state.background }
                    onChangeComplete={ this.handleChangeComplete }
                />
            </Modal>

        )
    }
}

export  default  ColorPicker
