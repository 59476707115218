import React from 'react'
import '@css/groupDetail.scss'
import PropTypes from 'prop-types'
import {Form,Card,Table} from 'antd'
import {queryGroupOpenRecordDetail} from '@service/getData'
import {getQueryString} from "@common/common";
import {connect} from "react-redux";
class GroupDetail  extends  React.Component{
    constructor(){
        super()

        this.state={
            goodsColumns:[
                {
                    title: '已选开团商品',
                    dataIndex: 'goods_name',
                    render: (text, record) => (
                        <div className="goods-info">
                            <div className="goods-info-img">
                                <img alt="" width="60" height="60" src={record.thumbnail_url}/>
                            </div>
                            <div className="goods-info-detail">
                                <div className="goods-info-name" title={record.goods_name}>
                                    {record.goods_name}
                                </div>
                                <div>
                                    ￥{record.current_price/100}
                                </div>
                            </div>
                        </div>
                    ),
                },
                {
                    title: '商品ID',
                    dataIndex: 'goods_id',

                },
                {
                    title: '库存',
                    dataIndex: 'balance',

                }
            ],
            leaderColumns:[
                {
                    title: '社群名称',
                    dataIndex: 'community_name'
                },
                {
                    title: '团长姓名',
                    dataIndex: 'nick_name'
                },
                {
                    title: '电话',
                    dataIndex: 'mobile'
                }
            ],
            leaderData:[],
            goodsData:[],
            can_group_shipment:false,
            can_self_shipment:false,
            group_shipment_fee:0,
            self_shipment_fee:0,
            goods_page_size:10,
            goods_page_no:1,
            goods_total:0,
            headStyle:{
                backgroundColor:"#f5f7fa"
            }

    }
        GroupDetail.contextTypes = {
            router: PropTypes.object.isRequired
        }
    }
    async queryGroupOpenRecordDetail(data){
        let res=await queryGroupOpenRecordDetail(data);
        let goodsData=[
            {
                goods_id:res.result.group_open_record.goods_id,
                goods_name:res.result.group_open_record.goods_name,
                thumbnail_url:res.result.group_open_record.thumbnail_url,
                current_price:res.result.group_open_record.current_price,
                balance:res.result.group_open_record.balance,
            }
        ]
        this.setState({
            leaderData:res.result.group_open_record.community_user_list,
            goodsData:goodsData,
            can_group_shipment:res.result.group_open_record.can_group_shipment,
            can_self_shipment:res.result.group_open_record.can_self_shipment,
            group_shipment_fee:res.result.group_open_record.group_shipment_fee,
            self_shipment_fee:res.result.group_open_record.self_shipment_fee
        })

    }

    componentDidMount(){
        let group_open_record_goods_id=getQueryString("group_open_record_goods_id");

        if(group_open_record_goods_id){
            let data={
                group_open_record_goods_id:group_open_record_goods_id
            }
            this.queryGroupOpenRecordDetail(data);

        }
    }
    render(){
        return(
            <div className="group-detail">
                <div className="content">
                    <div className="group-content">
                    <Card>
                        <Form>
                            <Form.Item label="已选商品">
                                <Table rowKey={(record,index) => index} columns={this.state.goodsColumns}
                                       dataSource={this.state.goodsData} pagination={false}/>
                            </Form.Item>
                        </Form>
                    </Card>
                    <Card
                        headStyle={this.state.headStyle}
                        title={
                            <div className="panel-title">
                                团类型/运费设置
                            </div>
                        }>
                        <div>
                            <div>
                                <span>团类型：</span>
                                <span style={{"display":+this.state.can_self_shipment===1?true:'none'}} >邮寄</span>
                                <span style={{"display":+this.state.can_group_shipment===1&&+this.state.can_self_shipment===1?true:'none'}}>，</span>
                                <span style={{"display":+this.state.can_group_shipment===1?true:'none'}}>自提</span>
                            </div>
                            <div>
                                <span>运费信息：</span>
                                <span style={{"display":+this.state.can_self_shipment===1?true:'none'}} >邮寄运费：{this.state.self_shipment_fee/100}</span>
                            </div>
                        </div>
                    </Card>
                    <Card
                        title={
                            <div className="panel-title">
                                推送至团长
                            </div>
                        }>
                        <div>
                            <Table
                                rowKey={(record,index) => index}
                                columns={this.state.leaderColumns}
                                dataSource={this.state.leaderData}
                                pagination={false}
                                scroll={{  y: 240 }}/>
                        </div>
                    </Card>
                </div>
                </div>

            </div>
        )

    }
}
const mapStateToProps = (state) => {
    return {
        menu_code:state.menuCode

    }
}
const GroupDetailForm = Form.create({ name: 'group_detail_form' })(GroupDetail);

export default connect(mapStateToProps) (GroupDetailForm);
