import React from "react";
import "@css/message.scss";
import config from "@config/config";
import { Avatar, Badge, Input, Form, Icon, Modal, Popover } from "antd";
import { formatDate } from "@common/common";
import { sendCM } from "@service/getData";
let lockReconnect = false; //避免ws重复连接
let ws = null; // 判断当前浏览器是否支持WebSocket
let needReconnect = true;
let wsUrl = config.wsUrl;
const { TextArea } = Input;
let onlineX = 0;
let lastScrollHeight = 0,
	modal,
	first_get_top = true;
class Message extends React.Component {
	constructor(props) {
		super();
		this.state = {
			websocket_io: false, //判断是否连接
			show_list: false, //是否显示客服消息
			showMessageTip: false,
			user_cm_list: [], //用户列表
			create_time_for_list: "", //列表传递时间
			create_time_for_item: "", //个人传递时间
			current_item: {}, //当前选择用户
			current_cm_list: [], //当前聊天内容
			request_io: true,
			select_io: true,
			icon_tip: false,
			visible: false,
			pageX: 0,
			pageY: 0,
			poleft: "0px",
			pobottom: "0px",
			moving: false,
			is_drag: false,
			previewVisible: false,
			previewImage: "",
			message_box_style: {},
			onlineMessageBoxStyle: {},
			onlineServiceTipShow: false,
			closeIconShow: false,
		};
	}
	UNSAFE_componentWillReceiveProps(props) {
		// 表面移动的是消息列表，没有移动悬浮
		if (props.onlineX === onlineX) {
			this.setState({
				message_box_style: {
					left: `${props.move_to_x}px`,
					top: `${props.move_to_y}px`,
					right: "unset",
					bottom: "unset"
				},
			});
		} else {
			// 移动的是悬浮，没有移动消息列表
			this.setState({
				onlineMessageBoxStyle: {
					left: `${props.onlineX}px`,
					top: `${props.onlineY}px`,
					right: 'unset',
					bottom: 'unset',
				}
			});
			onlineX = props.onlineX;
		}
	}
	componentDidMount() {
		this.createWebSocket(wsUrl); //连接ws
		modal = document.getElementById("modal-btn");
		let messageBox = document.getElementById("message-box");
		messageBox.addEventListener("dragstart", this.dragstart_handler);
	}
	componentWillUnmount() {
		needReconnect = false;
		ws.close();
	}
	dragstart_handler = ev => {
		ev.dataTransfer.dropEffect = "move";
		ev.dataTransfer.setData("start_x", ev.pageX);
		ev.dataTransfer.setData("start_y", ev.pageY);
		let left = modal.offsetLeft,
			top = modal.offsetTop + (first_get_top ? 60 : 0);
		first_get_top = false;
		ev.dataTransfer.setData("left", left);
		ev.dataTransfer.setData("top", top);
		ev.dataTransfer.setData('dragEle', ev.target.className);
	};
	showMessage = () => {
		// if (!this.state.websocket_io) {
		//   this.createWebSocket(wsUrl); //点开按钮连接ws
		//   this.setState({
		//     websocket_io: true
		//   });
		// }
		if (this.state.is_drag) return;
		this.setState(
			{
				show_list: true,
				showMessageTip: false,
			},
			() => {
				this.resetTop(1);
				document.getElementById("user-list").addEventListener("scroll", this.scrollHandle);
				document.getElementById("cm-list").addEventListener("scroll", this.cmScrollHandle);
				let user_cm_list = [...this.state.user_cm_list];
				let showDot = user_cm_list.some((item) => item.read === 0);
				this.setState({
					icon_tip: showDot,
				});
			}
		);
	};
	closeMessage = () => {
		this.setState({
			show_list: false
		});
	};
	createWebSocket(url) {
		try {
			if ("WebSocket" in window) {
				ws = new WebSocket(url);
			} else {
				alert(
					"您的浏览器不支持websocket协议,建议使用新版谷歌、火狐等浏览器，请勿使用IE10以下浏览器，360浏览器请使用极速模式，不要使用兼容模式！"
				);
			}
			this.initEventHandle();
		} catch (e) {
			this.reconnect(url);
		}
	}
	initEventHandle() {
		ws.onclose = () => {
			this.reconnect(wsUrl);
			console.log("llws连接关闭!" + new Date().toUTCString());
		};
		ws.onerror = () => {
			this.reconnect(wsUrl);
			console.log("llws连接错误!");
		};
		ws.onopen = () => {
			heartCheck.reset().start(); //心跳检测重置
			console.log("llws连接成功!" + new Date().toUTCString());
		};
		ws.onmessage = event => {
			//如果获取到消息，心跳检测重置
			heartCheck.reset().start(); //拿到任何消息都说明当前连接是正常的
			let data = event.data;
			try {
				data = JSON.parse(data);
			} catch (e) { }
			//   console.log("llws收到消息啦:", data);
			if (data === "ping") {
				return;
			}
			if (data.type === "user_list") {
				//获取用户列表
				this.setState({
					create_time_for_list: data.result.create_time || null,
					request_io: true
				});
				let list = [...data.result.list];
				if (list.length) {
					let date = new Date();
					list.forEach(item => {
						let distinctTime = date.getTime() - item.create_time * 1000;
						let _time = new Date(parseInt(item.create_time) * 1000);
						if (distinctTime <= 24 * 3600 * 1000 && _time.getDate() === date.getDate()) {
							item.time = formatDate(item.create_time, "H:I");
						} else {
							item.time = formatDate(item.create_time, "Y/M/D", false, true);
						}
					});
					list = [...this.state.user_cm_list, ...list];
					this.setState({
						user_cm_list: list
					});
				}
			} else if (data.type === "new_cm") {
				//获取新发的消息
				if (!this.state.show_list) {
					this.setState({
						icon_tip: true,
						showMessageTip: true,
					});
				}
				let new_cm = data.result,
					current_item = this.state.current_item,
					current_cm_list = [...this.state.current_cm_list];
				if (current_item.open_id === new_cm.open_id) {
					new_cm.read = 1;
					current_cm_list.push(new_cm);
					this.setState(
						{
							current_cm_list
						},
						() => {
							this.resetTop(1);
						}
					);
					let data = {
						customer_message_id: new_cm.customer_message_id,
						type: "cm_read"
					};
					ws.send(JSON.stringify(data));
				} else {
					new_cm.read = 0;
				}
				new_cm.time = formatDate(new_cm.create_time, "H:I");
				this.freshLIst(new_cm);
			} else if (data.type === "cm_list") {
				//获取个人聊天记录
				let list = [...data.result.list];
				list.forEach((item, index) => {
					item.content = item.content.replace(/(\r\n|\n|\r)/gm, "<br />");
					let time_str = this.compareTime(item, list[index + 1]);
					item.time_str = time_str;
				});
				list.reverse();
				if (list.length) {
					list = [...list, ...this.state.current_cm_list];
					this.setState(
						{
							current_cm_list: list
						},
						() => {
							this.resetTop();
						}
					);
				}
				this.setState({
					create_time_for_item: data.result.create_time || null,
					request_io: true,
					select_io: true
				});
			}
		};
	}
	compareTime(item1, item2) {
		if (!item2) return "";
		let time_str = "";
		let distinctTime = (item1.create_time - item2.create_time) * 1000;
		let _time1 = new Date(parseInt(item1.create_time) * 1000);
		if (distinctTime >= 10 * 60 * 1000 && _time1.getDate() === new Date().getDate()) {
			time_str = formatDate(item1.create_time, "H:I");
		} else if (distinctTime >= 10 * 60 * 1000 && _time1.getDate() !== new Date().getDate()) {
			time_str = formatDate(item1.create_time, "Y/M/D H:I", false);
		}
		return time_str;
	}
	resetTop = (neednot = false) => {
		let message_form_text = document.getElementById("message_form_text");
		if (!message_form_text) return;
		message_form_text.focus();
		let $cm = document.getElementById("cm-list"),
			scrollHeight = $cm.scrollHeight,
			scrollTop;
		if (lastScrollHeight && !neednot) {
			scrollTop = scrollHeight - lastScrollHeight;
		} else {
			scrollTop = scrollHeight;
		}
		$cm.scrollTop = scrollTop;
	};
	freshLIst = new_cm => {
		let list = [...this.state.user_cm_list];
		let index = list.findIndex(item => {
			return item.open_id === new_cm.open_id;
		});
		if (~index) {
			new_cm.avatar = list[index].avatar;
			new_cm.nick_name = list[index].nick_name;
			list.splice(index, 1);
		}
		list.unshift(new_cm);
		this.setState({
			user_cm_list: list
		});
	};
	reconnect(url) {
		let that = this;
		if (!needReconnect) return;
		if (lockReconnect) return;
		lockReconnect = true;
		that.setState({
			user_cm_list: [],
			create_time_for_list: "",
			create_time_for_item: "",
			current_item: {},
			current_cm_list: []
		});
		setTimeout(function () {
			//没连接上会一直重连，设置延迟避免请求过多
			that.createWebSocket(url);
			lockReconnect = false;
		}, 2000);
	}
	scrollHandle = debounce(event => {
		if (!this.state.request_io) {
			event.preventDefault();
			return;
		}
		let clientHeight = event.target.clientHeight,
			scrollTop = event.target.scrollTop,
			scrollHeight = event.target.scrollHeight;
		if (clientHeight + scrollTop >= scrollHeight - 150 && this.state.create_time_for_list !== null) {
			let data = {
				type: "user_list",
				last_create_time: this.state.create_time_for_list
			};
			this.setState({
				request_io: false
			});
			ws.send(JSON.stringify(data));
		}
	});
	selectItem = index => {
		if (!this.state.select_io) {
			return;
		}
		let user_cm_list = [...this.state.user_cm_list];
		if (this.state.current_item && this.state.current_item.open_id === user_cm_list[index].open_id) {
			return;
		}
		lastScrollHeight = 0;
		let last_index = user_cm_list.indexOf(this.state.current_item),
			current_item = user_cm_list[index],
			text = this.props.form.getFieldsValue().text;
		current_item.read = 1;
		this.props.form.resetFields();
		if (~last_index) {
			user_cm_list[last_index].text = text;
		}
		if (current_item.text) {
			this.props.form.setFieldsValue({
				text: current_item.text
			});
			current_item.text = "";
		}
		this.setState({
			current_item,
			user_cm_list,
			current_cm_list: [],
			select_io: false,
			create_time_for_item: ""
		});
		let showDot = user_cm_list.some((item) => item.read === 0);
		this.setState({
			icon_tip: showDot,
		});
		let data = {
			type: "cm_list",
			open_id: current_item.open_id
		};
		ws.send(JSON.stringify(data));
	};
	handleSubmit = async event => {
		event.persist();
		if (event.shiftKey) {
			return;
		}
		event.preventDefault();
		let text = this.props.form.getFieldsValue().text;
		if (!text) return;
		let data = {
			cm: {
				message: text,
				open_id: this.state.current_item.open_id
			}
		};
		let res = await sendCM(data);
		if (res.result.type === "send_cm_success") {
			let current_cm_list = [...this.state.current_cm_list];
			let message = res.result.cm;
			message.message_from = "merchant";
			message.read = 1;
			message.content = message.message.replace(/(\r\n|\n|\r)/gm, "<br />");
			message.time = formatDate(message.create_time, "H:I");
			current_cm_list.push(message);
			this.freshLIst(message);
			this.setState(
				{
					current_cm_list
				},
				() => {
					this.resetTop(1);
				}
			);
			this.props.form.resetFields();
		} else {
			Modal.error({
				title: "温馨提示",
				content: res.result.cm.errmsg
			});
		}
	};
	cmScrollHandle = debounce(event => {
		if (!this.state.select_io || !this.state.request_io) {
			event.preventDefault();
			return;
		}
		lastScrollHeight = event.target.scrollHeight;
		if (event.target.scrollTop <= 100 && this.state.create_time_for_item !== null && this.state.request_io) {
			let data = {
				type: "cm_list",
				open_id: this.state.current_item.open_id,
				last_create_time: this.state.create_time_for_item
			};
			this.setState({
				request_io: false
			});
			ws.send(JSON.stringify(data));
		}
	});
	getPosition = e => {
		// 标题DOM元素titleDom
		const titleDom = e.target.className === "message-icon" ? e.target : e.target.parentElement;
		// titleDom的坐标
		const X = titleDom.getBoundingClientRect().left;
		const Y = titleDom.getBoundingClientRect().top;
		// 鼠标点击的坐标
		let mouseX = 0,
			mouseY = 0;
		if (e.pageX || e.pageY) {
			//ff,chrome等浏览器
			mouseX = e.pageX;
			mouseY = e.pageY;
		} else {
			mouseX = e.clientX + document.body.scrollLeft - document.body.clientLeft;
			mouseY = e.clientY + document.body.scrollTop - document.body.clientTop;
		}
		// 鼠标点击位置与modal的位移
		const diffX = mouseX - X;
		const diffY = mouseY - Y;
		return { X, Y, mouseX, mouseY, diffX, diffY };
	};
	onMouseDown = e => {
		const position = this.getPosition(e);
		window.onmousemove = this.onMouseMove;
		this.setState({
			moving: true,
			diffX: position.diffX,
			diffY: position.diffY,
			startX: position.mouseX,
			startY: position.mouseY
		});
	};
	// 松开鼠标，设置modal状态为不可移动,
	onMouseUp = e => {
		this.setState({ moving: false });
		setTimeout(() => {
			this.setState({
				is_drag: false
			});
		});
	};
	// 鼠标移动重新设置modal的位置
	onMouseMove = e => {
		let { moving, diffX, diffY, startX, startY, is_drag, poleft, pobottom, show_list } = this.state;
		if (moving && !show_list) {
			// 获取鼠标位置数据
			const position = this.getPosition(e);
			// 计算modal应该随鼠标移动到的坐标
			const x = position.mouseX - diffX;
			const y = position.mouseY - diffY;
			if (!is_drag && (Math.abs(position.mouseX - startX) > 10 || Math.abs(position.mouseY - startY) > 10)) {
				is_drag = true;
				poleft = "unset";
				pobottom = "unset";
			}
			// 窗口大小
			const { clientWidth, clientHeight } = document.documentElement;
			const modal = document.getElementById("modal-btn");
			if (modal) {
				// 计算modal坐标的最大值
				const maxHeight = clientHeight - modal.offsetHeight;
				const maxWidth = clientWidth - modal.offsetWidth;
				// 判断得出modal的最终位置，不得超出浏览器可见窗口
				const left = x > 0 ? (x < maxWidth ? x : maxWidth) : 0;
				const top = y > 0 ? (y < maxHeight ? y : maxHeight) : 0;
				this.setState({
					pageX: left,
					pageY: top - 60,
					is_drag,
					poleft,
					pobottom
				});
			}
		}
	};
	handlePreviewImage = img_url => {
		this.setState({
			previewImage: img_url,
			previewVisible: true
		});
	};
	handleCancel = () => this.setState({ previewVisible: false });
	showOlineServiceTop() {
		this.setState({
			onlineServiceTipShow: true,
		});
	}
	hideOlineServiceTip() {
		this.setState({
			onlineServiceTipShow: false,
		});
	}
	tipClose(e) {
		e.stopPropagation();
		this.setState({
			showMessageTip: false
		});
	}
	showCloseIcon() {
		this.setState({
			closeIconShow: true,
		});
	}
	hideCloseIcon() {
		this.setState({
			closeIconShow: false,
		});
	}
	onlineDragStart(e) {
		e.target = e.target.className === 'olineServiceWrap' ? e.target : e.target.parentElement;
		e.dataTransfer.effectAllowed = 'move'

		// e.pageX 为鼠标点击的时候，指针距离整个文档左边缘的距离。（不是窗口左边缘，而是整个文档左边缘。如果有横向的滚动条，滚动距离也要计算）
		e.dataTransfer.setData('olineStartX', e.pageX)

		// e.pageY 为鼠标点击的时候，指针距离整个文档顶部的距离。也要计算滚动高度。
		e.dataTransfer.setData('olineStartY', e.pageY)

		// e.offsetLeft 相对与其offsetParent元素的内边距离.
		/* offsetParent: 最近的包涵该子元素的定位元素或者table、td、th、body元素。如果子元素为position:fixed，那么该属性返回null.
			在谷歌内核浏览器中，如果包涵该子元素的父元素display为none,那么该属性也返回null.
			虽然返回null，但是不影响获取offsetLeft等值
		*/
		e.dataTransfer.setData('olineLeft', e.target.offsetLeft)
		e.dataTransfer.setData('olineTop', e.target.offsetTop)
		e.dataTransfer.setData('dragEle', e.target.className);
	}
	onlineDragEnd(e) {
		e.preventDefault();
		e.dataTransfer.clearData();
	}
	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<div>
				<div
					className={this.state.show_list ? "message center" : "message"}
					id="modal-btn"
					onMouseDown={this.onMouseDown}
					onMouseUp={this.onMouseUp}
					style={this.state.message_box_style}
				>
					<div className={"main-content" + (this.state.show_list ? "" : " hide")} id="message-box" draggable={true}>
						<div className="close-content" onClick={this.closeMessage}>
							<Icon type="close" />
						</div>
						<div className="left-content">
							<h4 className="title">消息列表</h4>
							<div className="user-list" id="user-list" onScroll={this.scrollHandle}>
								{this.state.user_cm_list.length ? (
									<div>
										{this.state.user_cm_list.map((item, index) => {
											return (
												<div
													className={item.open_id === this.state.current_item.open_id ? "user-item active" : "user-item"}
													key={item.customer_message_id}
													onClick={this.selectItem.bind(this, index)}>
													{item.read ? (
														<Badge>
															<Avatar size={50} src={item.avatar} />
														</Badge>
													) : (
															<Badge dot>
																<Avatar size={50} src={item.avatar} />
															</Badge>
														)}
													<div className="text-content">
														<div className="line1">
															<span className="user-name">{item.nick_name}</span>
															<span className="time">{item.time}</span>
														</div>
														<div className="line2">
															{item.text ? (
																<p className="no-margin h20">
																	<span className="red">[草稿]</span>
																	<span className="cg-text">{item.text}</span>
																</p>
															) : (
																	<span className="content">{item.content}</span>
																)}
														</div>
													</div>
												</div>
											);
										})}
										{this.state.create_time === null ? <p className="no-more">没有更多</p> : null}
									</div>
								) : (
										<div className="no-user">暂无用户</div>
									)}
							</div>
						</div>
						<div className="right-content">
							<div className={this.state.current_item.open_id ? "item-info" : "item-info hide"}>
								<div className="right-top">
									<div className="title">{this.state.current_item.nick_name}</div>
									<div
										className="cm-list"
										id="cm-list"
									// onScroll={this.cmScrollHandle}
									>
										{this.state.current_cm_list.map((item, index) => {
											return (
												<div className="cm-item" key={item.customer_message_id}>
													<div className="time-title">{item.time_str}</div>
													<div className={item.message_from === "merchant" ? "main-message merchant" : "main-message"}>
														{item.message_from === "merchant" ? (
															<Avatar className="cm-item-avatar" icon="user" style={{ backgroundColor: "#87d068" }} />
														) : (
																<Avatar className="cm-item-avatar" src={item.avatar} />
															)}
														<div className="message-content">
															{item.msg_type === "image" ? (
																<img
																	onClick={this.handlePreviewImage.bind(this, item.pic_url)}
																	width="100px"
																	src={item.pic_url}
																	alt="图片"
																	style={{ cursor: "pointer" }}></img>
															) : (
																	<div
																		className="content-info"
																		dangerouslySetInnerHTML={{
																			__html: item.content
																		}}
																	/>
																)}
															{item.send_fail ? <span className="send-fail">发送失败</span> : null}
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</div>
								<div className="input-content">
									<Form>
										<Form.Item>{getFieldDecorator("text")(<TextArea onPressEnter={this.handleSubmit} />)}</Form.Item>
									</Form>
									<div className="send-btn" onClick={this.handleSubmit}>
										发送消息
                  					</div>
								</div>
							</div>
						</div>
					</div>
					<Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel} style={{ top: 20 }}>
						<img alt="example" style={{ width: "100%" }} src={this.state.previewImage} />
					</Modal>
				</div>
				<div className="messageBtnWrap"
					onClick={this.showMessage}
					onMouseOver={this.showCloseIcon.bind(this)}
					onMouseOut={this.hideCloseIcon.bind(this)}
				>
					<Badge dot={this.state.icon_tip} offset={[-50, 20]}>
						<div className="message-icon">
							<i className="iconfont icon-xiaoxi" />
							<p>客服消息</p>
						</div>
					</Badge>
					{this.state.icon_tip && !this.state.show_list && this.state.showMessageTip &&
						<div className="newMessageTip">
							<div className="sanjiao"></div>
							<span className="messageTip">店铺内有客户咨询</span>
							<span className="seeNow">立即查看</span>
							<i style={{ visibility: this.state.closeIconShow ? 'visible' : 'hidden' }} className="iconfont icon-guanbianniu" onClick={this.tipClose.bind(this)}></i>
						</div>
					}
				</div>
				{/* <a target="_blank"
					rel="noopener noreferrer"
					href="http://wpa.qq.com/msgrd?v=3&amp;uin=765526150&amp;site=qq&amp;menu=yes">
					<div className="olineServiceWrap"
						onMouseOver={this.showOlineServiceTop.bind(this)}
						onMouseLeave={this.hideOlineServiceTip.bind(this)}
						draggable={true}
						onDragStart={this.onlineDragStart.bind(this)}
						onDragEnd={this.onlineDragEnd.bind(this)}
						style={this.state.onlineMessageBoxStyle}
					>
						<img className="robotImg" src="//udh.oss-cn-hangzhou.aliyuncs.com/eb919b48-3f05-45c0-b855-13baa637aa19" alt="客服机器人" />
						<div className="olineServiceContent">
							<i className="iconfont icon-kefu1"></i>
							<span className="text">在线客服</span>
							{this.state.onlineServiceTipShow &&
								<div className="popTip">
									帮您提供一整套小程序店铺运营 方案，解决开店所有难题！
								<div className="sanjiao"></div>
								</div>
							}
						</div>
					</div>
				</a> */}
			</div>
		);
	}
}
const MessageForm = Form.create({ name: "message_form" })(Message);
export default MessageForm;

// 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
window.onbeforeunload = function () {
	ws.close();
};

//心跳检测
var heartCheck = {
	timeout: 30000, //30s发一次心跳
	timeoutObj: null,
	serverTimeoutObj: null,
	reset: function () {
		clearTimeout(this.timeoutObj);
		clearTimeout(this.serverTimeoutObj);
		return this;
	},
	start: function () {
		var self = this;
		this.timeoutObj = setTimeout(function () {
			//这里发送一个心跳，后端收到后，返回一个心跳消息，
			//onmessage拿到返回的心跳就说明连接正常
			ws.send("ping");
			self.serverTimeoutObj = setTimeout(function () {
				//如果超过一定时间还没重置，说明后端主动断开了
				ws.close(); //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
			}, self.timeout);
		}, this.timeout);
	}
};

function debounce(fn, context, delay = 500, watiTime = 200) {
	let timer = null,
		startTime;
	return function loop() {
		let args = [...arguments],
			curTime = +new Date();
		if (!startTime) {
			startTime = curTime;
		}
		timer && clearTimeout(timer);
		if (curTime - startTime > watiTime) {
			fn.apply(context, args);
			startTime = curTime;
		} else {
			timer = setTimeout(() => {
				loop.apply(context, args);
			}, delay);
		}
	};
}
