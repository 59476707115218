import React from 'react'
import { Modal, Table } from 'antd'
import { queryVehicleList } from '@service/getData'
import { formatDate } from '@common/common'
import cookie from 'react-cookies'
import '@css/printModal.scss'
class PrintModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: '昵称',
                    dataIndex: 'nick_name',

                }, {
                    title: '手机号',
                    dataIndex: 'tel_number',

                },
                {
                    title: '商品',
                    dataIndex: 'goods_name'
                },
                {
                    title: '规格',
                    dataIndex: 'sku',
                    width: "80px",
                }, {
                    title: '单价|数量',
                    dataIndex: 'num',
                    width: "86px",
                    render: (text, record) => (
                        <div>
                            <p>¥{record.current_price / 100}</p>
                            <p>{record.quantity}件</p>
                        </div>

                    )
                }, {
                    title: '订单号',
                    dataIndex: 'order_sn',
                    width: "114px",

                },
            ],
            order_shipment_info: {
                shipment_type: null,
                driver: null,
                tracking_number: null,
                logistics: null


            },
            vehicle_list: [

            ],
            date: null

        }
    }
    changeDriver = (e) => {
        this.setState(prevState => ({
            order_shipment_info: {
                ...this.state.order_shipment_info,
                shipment_type: prevState.order_shipment_info.shipment_type,
                driver: e,
                logistics: prevState.order_shipment_info.logistics,
                tracking_number: prevState.order_shipment_info.tracking_number,
            }
        }))

    }
    changeLogistics = (e) => {
        e.persist()
        this.setState(prevState => ({
            order_shipment_info: {
                ...this.state.order_shipment_info,
                shipment_type: prevState.order_shipment_info.shipment_type,
                driver: prevState.order_shipment_info.driver,
                logistics: e.target.value,
                tracking_number: prevState.order_shipment_info.tracking_number,
            }
        }))

    }

    changeTrackingNumber = (e) => {
        e.persist()
        this.setState(prevState => ({
            order_shipment_info: {
                ...this.state.order_shipment_info,
                shipment_type: prevState.order_shipment_info.shipment_type,
                driver: prevState.order_shipment_info.driver,
                logistics: prevState.order_shipment_info.logistics,
                tracking_number: e.target.value
            }
        }))
    }
    onChange = (e) => {
        this.setState(prevState => ({
            order_shipment_info: {
                ...this.state.order_shipment_info,
                shipment_type: e.target.value,
                driver: prevState.order_shipment_info.driver,
                logistics: prevState.order_shipment_info.logistics,
                tracking_number: prevState.order_shipment_info.tracking_number,
            }
        }))

    }
    async queryVehicleList(data) {
        let res = await queryVehicleList(data)
        if (res.success) {
            this.setState({
                vehicle_list: res.result.vehicle_res_list
            })
        }
    }
    print = () => {
        const el = document.getElementById("print-content");
        const iframe = document.createElement('IFRAME');
        let doc = null;
        iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;

        doc.write('<LINK rel="stylesheet" type="text/css" href="../../../index.css">');
        doc.write(el.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
        }
        this.props.handleSelfOk();

    }
    UNSAFE_componentWillReceiveProps() {
        let date = formatDate((new Date()).getTime())
        this.setState({
            date: date
        })
    }
    componentDidMount() {
        if (!cookie.load("shop_id") && +cookie.load("is_admin") === 1) {
            return
        }
        let data = {
            shop_id: cookie.load("shop_id")
        }
        this.queryVehicleList(data)
    }

    render() {
        return (
            <Modal
                centered={true}
                className="print-modal"
                title="配送单"
                visible={this.props.visible}
                onOk={this.print}
                onCancel={this.props.handleSelfCancel}
                okButtonProps={{ shape: 'round' }}
                cancelButtonProps={{ shape: 'round' }}
            >
                <div id="print-content">
                    <div className="address-info">
                        <p>
                            <span>配送单号:</span> {this.props.order.shipment_sn}
                        </p>
                        <p>
                            <span>打印时间:</span> {this.state.date}
                        </p>
                        <p>
                            <span>社群:</span> {this.props.order.community_name}
                        </p>
                        <p>
                            <span>团长信息:</span> {this.props.order.receiver_name}{this.props.order.mobile}{this.props.order.address}
                        </p>
                    </div>
                    <Table
                        className="table"
                        border="1"
                        rowKey={(record, index) => index}
                        columns={this.state.columns}
                        dataSource={this.props.order ? this.props.order.order_goods_list : []}
                        pagination={false}
                        bordered={true} />
                </div>
            </Modal>
        )
    }

}


export default PrintModal;
