import React from 'react'
import {Upload} from 'antd'
import config from "@config/config";
class UploadText extends React.Component{
    constructor(){
        super();
        this.state={
            file_list:[],
            request_url: config.baseUrl + "/ossFile/upload",
        }
    }
    handlePicChange = ({fileList}) => {
        let image;
        this.setState({file_list:[...fileList]})
        fileList.forEach(item=>{
            if(item.response&&item.response.success){
                image=item.response.result.oss_file_url
            }else{
                image=item.url
            }
        })
        if(image){
            this.props.createOrUpdateCat(this.props.catId,1,image,null)
        }
    }
    render(){
        return (
            <Upload
                accept="image/jpeg,image/jpg,image/png"
                withCredentials={true}
                action={this.state.request_url}
                fileList={this.state.file_list}
                onChange={this.handlePicChange}
            >
                <span className="text-note pic-choose ">选择图片</span>
            </Upload>
        )
    }

}
export default UploadText;