import React from 'react'
import NavBar from '@/view/component/common/NavBar.js'
import '@css/orderShipmentHistory.scss'
import {Tabs} from 'antd'
import {connect} from "react-redux";
import GroupOrderShipment from '@/view/component/Order/GroupOrderShipment'
import RepeatPrintShipment from '@/view/component/Order/RepeatPrintShipment'
class OrderShipmentHistory extends  React.Component {
    constructor(props){
        super(props)
        this.state={

        }
    }
    render(){
        let { menu_code } = this.props
        return(
            <div className="order-shipment-history">
                <NavBar icon="icon-fahuo" child={menu_code.group_shipment_history}/>
                <div className="content">
                    <Tabs
                        defaultActiveKey="1"
                        tabBarStyle={{backgroundColor:"#ffffff",borderRadius:"4px"}}
                    >
                        <Tabs.TabPane tab="待生成配送单" key="1">
                            <GroupOrderShipment status="-1"/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="待打印配送单" key="2">
                            <GroupOrderShipment status="0"/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="待发货配送单" key="3">
                            <GroupOrderShipment status="1"/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="补打印配送单" key="4">
                            <RepeatPrintShipment status="1"/>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        menu_code:state.menuCode
    }
}

export default connect(mapStateToProps) (OrderShipmentHistory);