import React from 'react'
import { Form, Input, DatePicker, Table, Button, Tooltip, Pagination, message, Popconfirm } from "antd";
import NavBar from '@/view/component/common/NavBar.js'
import { queryLinkList, createOrUpdateLink, getLinkCode } from '@service/getData'
import { connect } from "react-redux";
import '@css/promoteManage.scss'
import copy from 'copy-to-clipboard';
import PropTypes from "prop-types";
import cookie from 'react-cookies'
import html2canvas from 'html2canvas';
class PromoteManage extends React.Component {
    constructor() {
        super()
        PromoteManage.contextTypes = {
            router: PropTypes.object.isRequired
        };
        this.state = {
            order_by_condition: "createdAtDesc",
            columns: [
                {
                    title: '推广监控名称',
                    width: "9.7%",
                    dataIndex: 'link_name',
                    render: (text, record) =>
                        <div>
                            {text}
                            <Popconfirm
                                title={
                                    <div className="goods-price-change">
                                        <Input
                                            defaultValue={text}
                                            style={{ "width": "400px", "borderRadius": "100px" }}
                                            onChange={this.changeLinkName} />
                                    </div>
                                }
                                onConfirm={this.createOrUpdateLink.bind(this, record.link_id, 1)}
                                onCancel={this.cancelChange}
                                okText="确认"
                                cancelText="取消"
                                placement="bottom"
                                icon={null}>
                                <i className="iconfont icon-bianji text-note" ></i>
                            </Popconfirm>
                        </div>
                }, {
                    title: '推广渠道',
                    width: "9.7%",
                    dataIndex: 'channel_name',
                }, {
                    title: '推广页面',
                    width: "9.7%",
                    dataIndex: 'desc'
                }, {
                    title: '创建时间',
                    width: "9.7%",
                    dataIndex: 'created_at',
                    sorter: true
                }, {
                    title: '操作',
                    width: "9.7%",
                    dataIndex: 'link_id',
                    render: (link_id) => {
                        return (
                            <div className="action">
                                <Tooltip
                                    overlayClassName="tooltip-link-qrcode"
                                    arrowPointAtCenter
                                    placement="left"
                                    autoAdjustOverflow={false}
                                    onVisibleChange={(visible) => {
                                        if (visible) {
                                            this.getLinkCode(link_id)
                                        }
                                    }}
                                    title={
                                        <Form>
                                            <Form.Item label="推广小程序路径：">
                                                <Input.Search
                                                    readOnly
                                                    value={`pages/link/index?referer_id=10000&link_id=${link_id}`}
                                                    size="small"
                                                    placeholder="input search text"
                                                    enterButton={
                                                        <Button
                                                            type="primary"
                                                            shape="round">复制</Button>
                                                    }
                                                    onSearch={this.copy}
                                                />
                                            </Form.Item>
                                            <Form.Item label="">
                                                <div className="tooltip-qrcode">
                                                    <img alt=""
                                                        onError={(target) => {
                                                            this.setState({ url: "http://udh.oss-cn-hangzhou.aliyuncs.com/144c1feb-c3ed-46f7-9dac-f0b2527b4dea" })
                                                            target.onerrοr = null;
                                                        }
                                                        }
                                                        width="120"
                                                        src={this.state.url}
                                                    />
                                                    <Button
                                                        className="btn-qrcode"
                                                        type="primary"
                                                        shape="round"
                                                        onClick={this.downLoadPost}
                                                    > <a download="qrcode.png" href={this.state.url}>下载小程序码</a></Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    }
                                >
                                    <span className="text-note">推广</span>
                                </Tooltip>
                                <span className="text-note" onClick={this.gotoDataStatistics.bind(this, link_id)}>数据统计</span>
                                <span className="text-note" onClick={this.gotoPromoteMonitor.bind(this, link_id)}>编辑</span>
                                <Popconfirm
                                    placement="topRight"
                                    title="确定删除该推广监控?"
                                    onConfirm={this.createOrUpdateLink.bind(this, link_id, 0)}
                                    okText="确定"
                                    cancelText="取消"
                                >
                                    <span className="text-note">删除</span>
                                </Popconfirm>
                            </div>
                        )
                    }

                },
            ],
            page: 1,
            page_size: 10,
            total: 0,
            data: [],
            target_type_desc: {
                lucky: "抽奖活动",
                checkin: "签到",
                pointsStore: "积分商城",
                free_order: "抢免单",
                rank_lucky: '线上拼团',
            },
            system_pages: {
                homePages: "店铺首页",
                categoryPages: "分类页面",
                shopcarPages: "购物车",
                myPages: "个人中心",
                vip_center: '会员中心',
            },
            link_name: null,
            url: null

        }
    }
    gotoPromoteMonitor = (link_id) => {
        if (link_id) {
            this.context.router.history.push('/promoteMonitor', link_id)
        } else {
            this.context.router.history.push('/promoteMonitor')
        }

    }
    gotoDataStatistics = (link_id) => {
        this.context.router.history.push('/dataStatistics', link_id)

    }
    queryLinkList = async () => {
        let form = this.props.form.getFieldsValue();
        let _data = {
            order_by_condition: this.state.order_by_condition,
            mini_app_id: cookie.load("mini_app_id"),
            page_no: this.state.page,
            page_size: this.state.page_size,
            link_name: form.link_name,
            channel_name: form.channel_name,
            create_at_big: form.date ? form.date[1].format('YYYY-MM-DD HH:mm:ss') : null,
            create_at_small: form.date ? form.date[0].format('YYYY-MM-DD HH:mm:ss') : null
        }
        let res = await queryLinkList(_data)
        if (res.success) {
            let data = res.result.link_res_list;
            data.forEach(item => {
                switch (item.target_type) {
                    case 'campaign':
                        item.desc = `店铺页面:${item.campaign_res.campaign_name}`
                        break;
                    case 'goods':
                        item.desc = `商品:${item.goods_res.goods_name}`
                        break;
                    case 'tag':
                        item.desc = `商品列表页:${item.tag_res.tag_name}`
                        break;
                    case 'coupon':
                        item.desc = `优惠券:${item.coupon_res.coupon_name}`
                        break;
                    case 'activity_goods':
                        item.desc = `活动商品:${item.activity_goods_res.goods_res.goods_name}`
                        break;
                    case 'customPages':
                        item.desc = '自定义页面';
                        break;
                    default:
                        if (this.state.target_type_desc[item.target_type]) {
                            item.desc = `营销活动:${this.state.target_type_desc[item.target_type]}`;
                        } else {
                            item.desc = `系统页面:${this.state.system_pages[item.target_type]}`;
                        }
                        break;
                }

            })

            this.setState({
                data,
                total: res.result.total
            })
        }
    }
    clearFilter = () => {
        this.props.form.resetFields();
    }
    createOrUpdateLink = async (link_id, status) => {
        let data = {
            link_req: {
                mini_app_id: cookie.load("mini_app_id"),
                link_id,
                status,
                link_name: this.state.link_name
            }
        }
        let res = await createOrUpdateLink(data)
        if (res.success) {
            message.success(status ? '编辑成功' : "删除成功")
            this.queryLinkList()
        } else {
            message.error(res.msg)
        }
    }
    getLinkCode = async (link_id) => {
        let res = await getLinkCode(cookie.load("shop_id"), link_id);
        this.setState({
            url: res,
        })

    }
    changeLinkName = (e) => {
        this.setState({
            link_name: e.target.value
        })
    }
    cancelChange = () => {
        this.setState({
            link_name: null
        })
    }
    onShowPageChange = (page) => {
        this.setState({
            page: page
        }, () => {
            this.queryLinkList()
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.queryLinkList()
        })
    }
    downLoadPost = () => {
        window.pageYOffset = 0;
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        html2canvas(document.getElementById('post'), {
            useCORS: true, // 【重要】开启跨域配置
            taintTest: false,//是否在渲染前测试图片,
            dpi: 192,
            scale: 8,
        }).then(function (canvas) {
            var context = canvas.getContext('2d');
            context.mozImageSmoothingEnabled = false;
            context.webkitImageSmoothingEnabled = false;
            context.msImageSmoothingEnabled = false;
            context.imageSmoothingEnabled = false;
            var imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // 获取生成的图片的url
            var a = document.createElement("a");
            a.href = imgUri;
            a.download = 'qrcode.png';
            a.click();
        })

    }
    copy = (value) => {
        copy(value)
        message.success('复制成功')
    }
    handleTableChange = (pagination, filters, sorter) => {
        let order_by_condition = null
        if (sorter.field === 'created_at') {
            if (sorter.order === "ascend") {
                order_by_condition = "createdAtAsc"
            } else {
                order_by_condition = "createdAtDesc"
            }
        }
        this.setState({
            order_by_condition
        }, () => {
            this.queryLinkList()
        })
    }
    componentDidMount() {
        this.queryLinkList()
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        let { menu_code } = this.props
        return (
            <div className="promote-manage">
                <NavBar
                    icon="icon-tuiguang"
                    child={menu_code.promote_manage}
                />
                <div className="content">
                    <Button type="primary" shape="round" onClick={this.gotoPromoteMonitor.bind(this, null)}>新建推广监控</Button>
                    <div className="filter-content">
                        <Form className="ant-vertical" layout="inline">
                            <Form.Item label="推广监控名称">
                                {getFieldDecorator('link_name')(
                                    <Input className="goods-id" placeholder="输入推广监控名称" />
                                )}
                            </Form.Item>
                            <Form.Item label="推广渠道">
                                {getFieldDecorator('channel_name')(
                                    <Input className="goods-id" placeholder="输入推广渠道" />
                                )}
                            </Form.Item>
                            <Form.Item label="创建时间">
                                {getFieldDecorator('date')(
                                    <DatePicker.RangePicker
                                        showTime
                                    />
                                )}
                            </Form.Item>
                        </Form>
                        <div className="filter-action">
                            <Button shape="round" type="primary" onClick={this.queryLinkList}>筛选</Button>
                            <span className="text-note" onClick={this.clearFilter}>清空筛选条件</span>
                        </div>
                    </div>
                    <Table columns={this.state.columns}
                        dataSource={this.state.data}
                        pagination={false}
                        rowKey={(record) => record.link_id}
                        onChange={this.handleTableChange}
                    />
                    <Pagination
                        className="pagenation"
                        onChange={this.onShowPageChange}
                        onShowSizeChange={this.onShowSizeChange}
                        defaultCurrent={this.state.page}
                        total={this.state.total}
                        showTotal={(total) => `共 ${total} 条`}
                        pageSizeOptions={["10", "30", "50", "100"]}
                        showSizeChanger
                    />
                </div>

            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        menu_code: state.menuCode
    };
};
const PromoteForm = Form.create({ name: 'promote_form' })(PromoteManage);
export default connect(mapStateToProps)(PromoteForm)
