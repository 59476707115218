import React from "react";
import "@css/official.scss";
import PropTypes from "prop-types";
class Official extends React.Component {
  constructor(props) {
    super();
    Official.contextTypes = {
      router: PropTypes.object.isRequired
    };
  }
  goToLogin = () => {
    this.context.router.history.push("/login");
  };
  render() {
    return (
      <div className="official">
        <div className="header block1">
          <div className="block-content">
            <div className="img-content">
              <img
                src="http://udh.oss-cn-hangzhou.aliyuncs.com/d7c4479d-e44a-4955-9525-dcab41e90186"
                alt="logo"
              />
            </div>
            <div className="contact">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://wpa.qq.com/msgrd?v=3&amp;uin=765526150&amp;site=qq&amp;menu=yes"
              >
                <img alt="" border="0" src="http://wpa.qq.com/pa?p=2:765526150:51" />
              </a>
              <div className="btn" onClick={this.goToLogin}>
                立即使用
              </div>
            </div>
          </div>
        </div>
        <div className="block2 pointer" onClick={this.goToLogin}>
          <img 
            className="full-width"
            src="http://udh.oss-cn-hangzhou.aliyuncs.com/964bd3d5-dbd9-4706-98fb-5a1925302371"
            alt=""
          />
        </div>
        <div className="block3 pointer" onClick={this.goToLogin}>
          <img
            className="full-width"
            src="http://udh.oss-cn-hangzhou.aliyuncs.com/d58a757f-01b5-43c6-a486-906ab67c12f6"
            alt=""
          />
          <div className="block-content" />
        </div>
        <div className="block4">
          <img
            className="full-width"
            src="http://udh.oss-cn-hangzhou.aliyuncs.com/4c260a15-22d5-45e2-823e-864f9602ad77"
            alt=""
          />
        </div>
        <div className="block5">
          <img
            className="full-width"
            src="http://udh.oss-cn-hangzhou.aliyuncs.com/2315ee70-5b56-4539-ba0d-9e1a78efdab7"
            alt=""
          />
          <div className="btn" onClick={this.goToLogin}>
            立即咨询
          </div>
        </div>
        <div className="block6">
          <img
            className="full-width"
            src="http://udh.oss-cn-hangzhou.aliyuncs.com/f05ca35e-2e9f-4480-96dd-61b765326d69"
            alt=""
          />
        </div>
        <div className="block7 footer">
          <div className="block-content">
            <div className="left">
              <div className="p1">联系我们</div>
              <div className="p2">客服全程指导开店</div>
            </div>
            <div className="center">
              <div>在线时间：工作日9:00 ~ 18:00</div>
              <div>来电咨询：18658132127</div>
              <div>客服微信：duoxiangzhuan123</div>
            </div>
            <div className="right">
              <div className="img-item">
                <img
                  src="http://udh.oss-cn-hangzhou.aliyuncs.com/395e4cc3-2c10-409a-86d4-b18daaf193fb"
                  alt=""
                />
                <div>微信客服</div>
              </div>
              <div className="img-item">
                <img
                  src="http://udh.oss-cn-hangzhou.aliyuncs.com/a2267ef6-88f0-4782-972c-1a824d27ed94"
                  alt=""
                />
                <div>QQ客服</div>
              </div>
              <div className="img-item">
                <img
                  src="http://udh.oss-cn-hangzhou.aliyuncs.com/78ce1ad4-2b3f-42d3-a8a6-9e7dd25d4af2"
                  alt=""
                />
                <div>商家交流群</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

(function(doc, win) {
  let docEl = doc.documentElement,
    resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
    recalc = function() {
      let clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      docEl.style.fontSize = 16 * (clientWidth / 1440) + "px";
    };
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener("DOMContentLoaded", recalc, false);
})(document, window);

export default Official;
