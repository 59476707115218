import { get, post } from "../common/axios";

export function getData(url, data) {
  return post(url, data);
}

export function login(user_name, password) {
  let data = {
    user_name: user_name,
    password: password
  };
  return post("/login", data);
}

export function getPreAuthUrl() {
  let data = {};
  return post("/wxOpen/oauth/authorize", data);
}

export function getTemplate() {
  let data = {};
  return post("/wxOpen/template/detail", data);
}

export function getMiniAppList() {
  let data = {};
  return post("/miniApp/list", data);
}

export function bindMiniApp(app_id, admin_user_id, auth_code) {
  let data = {
    app_id: app_id,
    admin_user_id: admin_user_id,
    auth_code: auth_code
  };
  return post("/wxOpen/oauth/access_token", data);
}

export function getAppAuditList(data) {
  return post("/miniApp/audit", data);
}

export function switchApp(data) {
  return post("/miniApp/switch", data);
}

export function getMiniAppAuditList(data) {
  return post("/miniApp/audit/list", data);
}

export function createOrUpdateMiniAppAudit(item) {
  let data = {
    mini_app_audit_list: item
  };
  return post("/miniApp/audit/commit", data);
}

export function submitMiniAppAudit(data) {
  return post("/miniApp/audit/submit", data);
}

export function cancelSubmitMiniAppAudit(data) {
  return post("/miniApp/audit/cancel", data);
}

export function releaseMiniAppAudit(data) {
  return post("/miniApp/audit/release", data);
}

export function revertReleaseMiniAppAudit(data) {
  return post("/miniApp/audit/revert", data);
}

export function getExQrCode(data) {
  return get("/wxOpen/qrCode?mini_app_id=" + data);
}

export function getQrCodeGroup(data) {
  return get("/wxOpen/qrCode/group?mini_app_id=" + data);
}

export function getCategory(data) {
  return post("/wxOpen/category/detail", data);
}

export function getMiniAppQrCode(data) {
  return post("/wxOpen/miniApp/qrCode", data);
}

export function modifyDomain(data) {
  return post("wxOpen/domain/update", data);
}

export function updatePic(data) {
  return post("/ossFile/upload", data);
}

export function queryAttributeList(data) {
  return post("/attribute/list", data);
}

export function queryAttributeValueList(data) {
  return post("/attribute/value/list", data);
}

export function getCatList(data) {
  return post("/cat/list", data);
}

export function createOrUpdateGoodsAndSku(data) {
  return post("/goods/save", data);
}

export function queryGoodsList(data) {
  return post("/goods/list", data);
}

export function updateGoodsAndSku(data) {
  return post("/goods/update", data);
}

export function queryGoodsDetail(data) {
  return post("/goods/detail", data);
}

export function onsaleGoodsList(data) {
  return post("/goods/onsale/list", data);
}
export function queryGroupOpenRecordList(data) {
  return post("/groupOpenRecord/list", data);
}
export function queryUnopenedGoodsList(data) {
  return post("/groupOpenRecord/unOpenedGoodsList", data);
}

export function queryCommunityUserList(data) {
  return post("/community/user/list", data);
}

export function queryGroupOpenRecordDetail(data) {
  return post("/groupOpenRecord/detail", data);
}

export function sendCM(data) {
  return post("/cm/send", data);
}

export function createGroupOpenRecord(data) {
  return post("/groupOpenRecord/create", data);
}
export function queryAdminOrderList(data) {
  return post("/order/list", data);
}

export function queryOrderDetail(data) {
  return post("/order/detail", data);
}

export function cancelOrder(data) {
  return post("/order/cancel", data);
}

export function updateOrder(data) {
  return post("/order/update", data);
}

export function queryQohList(data) {
  return post("/inventory/qoh/list", data);
}

export function modifyQoh(data) {
  return post("//inventory/qoh/update", data);
}
export function queryGroupShipmentOrderList(data) {
  return post("/shipment/group/list", data);
}
export function queryGroupShipmentOrderDueList(data) {
  return post("/shipment/group/unshippedList", data);
}
export function createShipment(data) {
  return post("/shipment/create", data);
}
export function queryShipmentBatchList(data) {
  return post("/shipment/batch/list", data);
}
export function querySelfShipmentOrderDueList(data) {
  return post("/shipment/unshippedList", data);
}
export function queryTemplateList(data) {
  return post("/template/list", data);
}
export function switchTemplate(data) {
  return post("/template/switch", data);
}
export function queryTemplateInitList(data) {
  return post("/templateInit/list", data);
}
export function pushTemplateInit(data) {
  return post("/templateInit/push", data);
}
export function shippingOutSelfOrder(data) {
  return post("/logistics/send", data);
}
export function queryAdminRefundOrderList(data) {
  return post("/refundOrder/list", data);
}
export function queryRefundOrderDetail(data) {
  return post("/refundOrder/detail", data);
}
export function createOrUpdateRefundRecord(data) {
  return post("/refundOrder/save", data);
}
export function shopApprovalRefundOrder(data) {
  return post("/refundOrder/approval", data);
}
export function queryRefundRecordList(data) {
  return post("/refundOrder/records", data);
}
export function shipmentBatchXlsTask(data) {
  return post("/shipment/batchXlsTask", data);
}
export function printShipment(data) {
  return post("/shipment/print", data);
}
export function queryVehicleList(data) {
  return post("/vehicle/list", data);
}
export function shippingOutGroupOrder(data) {
  return post("/logistics/group/send", data);
}
export function queryUserList(data) {
  return post("/adminUser/list", data);
}
export function createOrUpdateAdminUser(data) {
  return post("/adminUser/save", data);
}
export function freezeAdminUser(data) {
  return post("/adminUser/freeze", data);
}
export function changeUserTrial(data) {
  return post("/adminUser/trial", data);
}
export function queryAppApplyList(data) {
  return post("/appApply/list", data);
}
export function deleteAppApply(data) {
  return post("/appApply/delete", data);
}
export function createOrUpdateAppApply(data) {
  return post("/appApply/save", data);
}
export function queryAppApplyDetail(data) {
  return post("/appApply/detail", data);
}
export function queryAdminAppApplyList(data) {
  return post("/appApply/listForAdmin", data);
}
export function mchRelate(data) {
  return post("/appApply/bindMchId", data);
}
export function xmdMchRelate(data) {
  return post("/appApply/bindXmdMchId", data);
}
export function executeInit(data) {
  return post("/appApply/init", data);
}
export function createAppCreateOrder(data) {
  return post("/adminOrder/save", data);
}
export function queryMchApplyList(data) {
  return post("/mchApply/list", data);
}
export function queryMchApplyDetail(data) {
  return post("/mchApply/detail", data);
}
export function createOrUpdateMchApply(data) {
  return post("/mchApply/save", data);
}
export function modifyRefundAuth(data) {
  return post("/mchApply/updateRefundAuth", data);
}
export function createOrUpdateAttributeValue(data) {
  return post("/attribute/value/save", data);
}
export function submitAuditResult(data) {
  return post("/mchApply/submitAuditResult", data);
}
export function modifyMchId(data) {
  return post("/mchApply/updateMchId", data);
}
export function modifyWxApplyNo(data) {
  return post("/mchApply/updateWxApplyNo", data);
}
export function queryAdminbonusItemList(data) {
  return post("/finance/admin/bonusItem/list", data);
}
export function approveBillList(data) {
  return post("/finance/billList/approve", data);
}
export function createShopBonusOrder(data) {
  return post("/adminOrder/createShopBonus", data);
}
export function queryAdminOrderDetail(data) {
  return post("/adminOrder/detail", data);
}
export function queryShopBonusSummaryDetail(data) {
  return post("/shopBonusSummary/detail", data);
}
export function createOrUpdateVehicle(data) {
  return post("/vehicle/save", data);
}
export function queryApplyGroupRoleList(data) {
  return post("/role/applyGroupRole/list", data);
}
export function queryShopBonusTransactionList(data) {
  return post("/shopBonusSummary/transactions", data);
}
export function approveGroupRoleList(data) {
  return post("/role/groupRoleList/approve", data);
}
export function queryGroupPromotionList(data) {
  return post("/role/groupPromotion/list", data);
}
export function queryShopDashBoard(data) {
  return post("/dashBoard/detail", data);
}
export function updatePassword(data) {
  return post("/adminUser/updatePassword", data);
}
export function batchDeleteGroup(data) {
  return post("/group/batchDelete", data);
}
export function setDoMain(data) {
  return post("/miniApp/setDomain", data);
}
export function modifySignatrue(data) {
  return post("/miniApp/updateSignatrue", data);
}
export function bindTester(data) {
  return post("/miniApp/bindTester", data);
}
export function queryAllCategories(data) {
  return post("/miniApp/allCategories", data);
}
export function queryMiniAppDetail(data) {
  return post("/miniApp/detail", data);
}
export function createOrUpdateMiniApp(data) {
  return post("/miniApp/save", data);
}
export function queryBannerList(data) {
  return post("/banner/list", data);
}
export function deleteBanner(data) {
  return post("/banner/delete", data);
}
export function createOrUpdateBanner(data) {
  return post("/banner/save", data);
}
export function exchangeBannerPosition(data) {
  return post("/banner/sort", data);
}
export function topBanner(data) {
  return post("/banner/top", data);
}
export function updateGroupOpenRecordGoods(data) {
  return post("/groupOpenRecord/updateGoods", data);
}
export function queryRegionDetail(data) {
  return post("/region/detail", data);
}
export function queryShippingTemplateList(data) {
  return post("/shippingTemplate/list", data);
}
export function createOrUpdateShippingTemplateAndDetail(data) {
  return post("/shippingTemplate/save", data);
}
export function createOrUpdateActivity(data) {
  return post("/activity/save", data);
}
export function queryActivityList(data) {
  return post("/activity/list", data);
}
export function queryCampaignActivitySubtype(data) {
  return post("/activity/subtype", data);
}
export function queryActivityDetail(data) {
  return post("/activity/detail", data);
}
export function createOrUpdateActivityGoods(data) {
  return post("/activityGoods/save", data);
}
export function exchangeActivityGoodsSortNum(data) {
  return post("/activityGoods/sort", data);
}
export function topActivityGoods(data) {
  return post("/activityGoods/top", data);
}
export function exchangeCouponSortNum(data) {
  return post("/coupon/sort", data);
}
export function topCoupon(data) {
  return post("/coupon/top", data);
}
export function deleteActivityGoods(data) {
  return post("/activityGoods/delete", data);
}
export function dragActivityGoods(data) {
  return post("/activityGoods/drag", data);
}
export function createOrUpdateCampaignActivitySubtype(data) {
  return post("/CampaignActivitySubtype/save", data);
}
export function exchangeActivitySortNum(data) {
  return post("/activity/sort", data);
}
export function topActivity(data) {
  return post("/activity/top", data);
}
export function dragActivity(data) {
  return post("/activity/drag", data);
}
export function queryMiniAppPageConfigList(data) {
  return post("/pageConfig/list", data);
}
export function queryPageConfigList(data) {
  return post("/pageConfig/list2", data);
}
export function createOrUpdatePageConfig(data) {
  return post("/pageConfig/save", data);
}
export function createOrUpdateMiniAppPageConfig(data) {
  return post("/pageConfig/save2", data);
}
export function createOrUpdateCat(data) {
  return post("/cat/save", data);
}
export function exchangeCatSortNum(data) {
  return post("/cat/sort", data);
}
export function topCat(data) {
  return post("/cat/top", data);
}
export function queryCampaignList(data) {
  return post("/campaign/list", data);
}
export function setFirstPageCampaign(data) {
  return post("/campaign/setFirstPage", data);
}
export function createOrUpdateCampaign(data) {
  return post("/campaign/save", data);
}
export function createOrUpdateMiniAppPageConfigList(data) {
  return post("/pageConfig/list/save", data);
}
export function queryCjAdminOrderList(data) {
  return post("/cjGroup/order/list", data);
}
export function querySelfShipmentCjOrderList(data) {
  return post("/cjGroup/self/order/list", data);
}
export function queryCjOrderDetail(data) {
  return post("/cjGroup/order/detail", data);
}
export function createOrUpdateBannerActivity(data) {
  return post("/activity/banner/save", data);
}
export function createOrUpdateImageAdActivity(data) {
  return post("/activity/imageAd/save", data);
}
export function createOrUpdateTag(data) {
  return post("/tag/save", data);
}
export function queryTagList(data) {
  return post("/tag/list", data);
}
export function queryTagDetail(data) {
  return post("/tag/detail", data);
}
export function exchangeTagGoodsSortNum(data) {
  return post("/tag/goods/sort", data);
}
export function deleteTagGoods(data) {
  return post("/tag/delete", data);
}
export function createOrUpdateTagGoods(data) {
  return post("/tag/goods/save", data);
}
export function tagGoodsBatchXlsTask(data) {
  return post("/tag/goods/batchXlsTask", data);
}
export function createOrUpdateCoupon(data) {
  return post("/coupon/save", data);
}
export function queryCouponList(data) {
  return post("/coupon/list", data);
}
export function queryCouponDetail(data) {
  return post("/coupon/detail", data);
}
export function exchangeActivityCouponSortNum(data) {
  return post("/activityCoupon/sort", data);
}
export function topActivityCoupon(data) {
  return post("/activityCoupon/top", data);
}
export function queryActivityDashBoard(data) {
  return post("/dashBoard/activity", data);
}
export function getPostQrCode(shop_id, coupon_id, receive) {
  if (receive) {
    return get(`/qrCode/detail?shop_id=${shop_id}&receive=1&coupon_id=${coupon_id}&page=pages/couponDetail/index`);
  } else {
    return get(`/qrCode/detail?shop_id=${shop_id}&coupon_id=${coupon_id}&page=pages/couponDetail/index`);
  }
}
// 获取base64编码的图片链接
export function getBase64ImageUrl(url) {
  return get(`/file/download?url=${url}`);
}
export function queryLinkList(data) {
  return post("/link/list", data);
}
export function createOrUpdateLink(data) {
  return post("/link/save", data);
}
export function queryLinkDetail(data) {
  return post("/link/detail", data);
}
export function queryLinkDailyStatsList(data) {
  return post("/link/dailyStats", data);
}
export function queryChannelList(data) {
  return post("/channel/list", data);
}
export function createOrUpdateChannel(data) {
  return post("/channel/save", data);
}
export function queryRoomInfoList(data) {
  return post("/room/list", data);
}
export function synWechatRoomInfo(data) {
  return post("/room/wechat/sync", data);
}
export function queryUserUserList(data) {
  return post("/user/list", data);
}
export function queryUserLabelList(data) {
  return post("/user/labels", data);
}
export function queryUserDashBoard(data) {
  return post("/user/detail", data);
}
export function queryPointsRuleList(data) {
  return post("/pointsRule/list", data);
}
export function createOrUpdatePointsRule(data) {
  return post("/pointsRule/save", data);
}
export function queryPointsRuleDetail(data) {
  return post("/pointsRule/detail", data);
}
export function getLinkCode(shop_id, link_id) {
  return get(`/qrCode/detail?shop_id=${shop_id}&link_id=${link_id}&jump=1&page=pages/link/index`);
}
//权益卡
export function getGiftCardList(data) {
  return post(`/giftCard/list`, data);
}
export function queryGiftCardDetail(data) {
  return post(`/giftCard/detail`, data);
}
export function stopGiftCard(data) {
  return post(`/giftCard/stop`, data);
}
export function deleteGiftCard(data) {
  return post(`/giftCard/delete`, data);
}
export function createOrUpdateGiftCard(data) {
  return post(`/giftCard/save`, data);
}
export function getUserGiftCardList(data) {
  return post(`/userGiftCard/list`, data);
}
export function cancelUserGiftCard(data) {
  return post(`/userGiftCard/cancel`, data);
}
export function getGiftCardCode(shop_id, gift_card_id) {
  return get(`/qrCode/detail?shop_id=${shop_id}&coupon_id=${gift_card_id}&page=pages/cardDetail/index`);
}
// 生成二维码
export function getCode(data) {
  let url = "/qrCode/detail";
  for (let k in data) {
    if (url.indexOf("?") === -1) {
      url = `${url}?${k}=${data[k]}`;
    } else {
      url = `${url}&${k}=${data[k]}`;
    }
  }
  return get(url);
}

//场景营销
export function querySceneActivityList(data) {
  return post(`/ump/sceneActivity/list`, data); //场景营销列表
}
export function querySceneActivityDetail(data) {
  return post(`/ump/sceneActivity/detail`, data); //场景营销详情
}
export function changeSceneActivityStatus(data) {
  return post(`/ump/sceneActivity/update`, data); //修改场景营销的状态
}
export function createOrUpdateSceneActivity(data) {
  return post(`/ump/scene/save`, data); //创建修改场景营销
}
export function queryVipGradeList(data) {
  return post(`/ump/vipGrade/list`, data); //获取会员等级列表
}
