import React from 'react'
import {Form,Input,DatePicker,Table,Button,Pagination,message,Popconfirm} from "antd";
import NavBar from '@/view/component/common/NavBar.js'
import {connect} from "react-redux";
import '@css/promoteChanel.scss'
import PropTypes from "prop-types";
import ChanelModal from '@/view/component/common/ChannelModal'
import {queryChannelList,createOrUpdateChannel}from '@service/getData'
import cookie from 'react-cookies'
class PromoteChannel extends React.Component{
    constructor(){
        super()
        PromoteChannel.contextTypes = {
            router: PropTypes.object.isRequired
        };
        this.state={
            order_by_condition:null,
            columns:[
                {
                    title: '推广渠道名称',
                    width: "9.7%",
                    dataIndex: 'channel_name',
                    align:'center',
                    render:(text,record)=>
                        <div>
                            {text}
                        </div>
                },{
                    title: '创建时间',
                    dataIndex: 'created_at',
                    width:'30%',
                    align:'center',
                    sorter:true
                },{
                    title: '到达访问量',
                    width: "9.7%",
                    dataIndex: 'page_view_count',
                    align:'center'
                },{
                    title: '到达访客数',
                    width: "9.7%",
                    dataIndex: 'unique_visitor_count',
                    align:'center'
                },{
                    title: '新访客数',
                    width: "9.7%",
                    dataIndex: 'new_visitor_count',
                    align:'center'
                },{
                    title: '平均跳出率',
                    width: "9.7%",
                    dataIndex: 'bounce_rate',
                    align:'center'
                },{
                    title: '操作',
                    width: "9.7%",
                    align:'center',
                    dataIndex: 'channel_id',
                    render:(text)=>
                        <Popconfirm
                            title="确定删除该渠道?"
                            onConfirm={this.createOrUpdateChannel.bind(this,text,0)}
                            okText="确定"
                            cancelText="取消"
                            placement="topRight"
                        >
                            <span className="text-note">删除</span>
                        </Popconfirm>
                },
            ],
            visible:false,
            page:1,
            page_size:10,
            total:0,
            data:[],
            channel_name:null
        }
    }
    onOk=()=>{
        this.setState({
            visible:false
        },()=>{
            this.queryChannelList()
        })
    }
    onCanCel=()=>{
        this.setState({
            visible:false
        })
    }
    showModal=()=>{
        this.setState({
            visible:true
        })
    }
    onShowPageChange = (page) => {
        this.setState({
            page: page
        }, () => {
            this.queryChannelList()
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.queryChannelList()
        })
    }
    queryChannelList=async ()=>{
        let form = this.props.form.getFieldsValue();
        let data={
            order_by_condition:this.state.order_by_condition,
            mini_app_id: cookie.load("mini_app_id"),
            page: this.state.page,
            page_size: this.state.page_size,
            link_name: form.link_name,
            channel_name: form.channel_name,
            stats_date_big: form.date ? form.date[1].format('YYYY-MM-DD HH:mm:ss') : null,
            stats_date_small: form.date ? form.date[0].format('YYYY-MM-DD HH:mm:ss') : null
        }
        let res=await queryChannelList(data)
        if(res.success){
            let data=res.result.channel_res_list;
            this.setState({
                data,
                total:res.result.total
            })
        }
    }
    createOrUpdateChannel=async(channel_id,status)=>{
        let data={
            channel_req:{
                channel_id,
                status,
                channel_name:this.state.channel_name
            }
        }
        let res=await createOrUpdateChannel(data)
        if(res.success){
            message.success(status===1?'成功编辑该渠道':"成功删除该渠道")
            this.queryChannelList()
        }else{
            message.error(res.msg)
        }
    }
    changeChannelName = (e) => {
        this.setState({
            channel_name:e.target.value
        })
    }
    cancelChange=()=>{
        this.setState({
            channel_name:null
        })
    }
    clearFilter = () => {
        this.props.form.resetFields();
    }
    handleTableChange=(pagination, filters, sorter)=>{
        let order_by_condition=null
        switch(sorter.field){
            case 'created_at':
                if(sorter.order==="ascend"){
                    order_by_condition="createdAtAsc"
                }else{
                    order_by_condition="createdAtDesc"
                }
                break;
            default:
                order_by_condition=null
        }

        this.setState({
            order_by_condition
        },()=>{
            this.queryChannelList()
        })
    }
    componentDidMount(){
        this.queryChannelList()
    }
    render(){
        const {getFieldDecorator} = this.props.form;
        let {menu_code} = this.props
        return (
            <div className="promote-chanel">
                <NavBar
                    icon="icon-tuiguang"
                    child={menu_code.promote_chanel}
                />
                <div className="content">
                    <Button type="primary" shape="round" onClick={this.showModal}>新建渠道</Button>
                    <div className="filter-content">
                        <Form className="ant-vertical"layout="inline">
                            <Form.Item label="推广渠道">
                                {getFieldDecorator('channel_name')(
                                    <Input className="goods-id" placeholder="输入推广渠道"/>
                                )}
                            </Form.Item>
                            <Form.Item label="推广时间段">
                                {getFieldDecorator('date')(
                                    <DatePicker.RangePicker
                                        showTime
                                    />
                                )}
                            </Form.Item>
                        </Form>
                        <div className="filter-action">
                            <Button shape="round" type="primary"onClick={this.queryChannelList}>筛选</Button>
                            <span className="text-note" onClick={this.clearFilter}>清空筛选条件</span>
                        </div>
                    </div>
                    <Table columns={this.state.columns}
                           dataSource={this.state.data}
                           pagination={false}
                           rowKey={(record)=>record.channel_id}
                           onChange={this.handleTableChange}
                    />
                    <Pagination
                        className="pagenation"
                        onChange={this.onShowPageChange}
                        onShowSizeChange={this.onShowSizeChange}
                        defaultCurrent={this.state.page}
                        total={this.state.total}
                        showTotal={(total) =>  `共 ${total} 条`}
                        pageSizeOptions={["10","30","50","100"]}
                        showSizeChanger
                    />
                </div>
                <ChanelModal
                    onOk={this.onOk}
                    onCancel={this.onCanCel}
                    visible={this.state.visible}
                />

            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        menu_code: state.menuCode
    };
};
const PromoteChanelForm = Form.create({name: 'promote_chanel_form'})(PromoteChannel);
export  default connect(mapStateToProps) (PromoteChanelForm)
