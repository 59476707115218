export default {
  menu_code: {
    home: "首页",
    user_dashboard: "概况",
    shipping_template: "运费模版",
    order_manage: "订单管理",
    goods_manage: "商品管理",
    balance_manage: "进销存管理",
    after_sale_service: "售后服务",
    code_manage: "授权设置",
    code_history: "查看记录",
    goods_publish: "商品管理",
    goods_publish_new: "发布新商品",
    goods_detail: "商品详情",
    group_manage: "开团管理",
    group_goods_manage: "分类页面",
    group_add: "创建新商品团",
    group_detail: "商品团详情",
    order_detail: "订单详情",
    order_shipment_history: "发货记录",
    self_shipment_history: "邮寄订单发货",
    group_shipment_history: "自提订单发货",
    batch_shipment: "批量发货",
    order_shipment: "订单发货",
    template_message: "订阅消息",
    user_message: "账户管理",
    setting: "设置",
    applet_manage: "小程序管理",
    applet_apply_manage: "小程序申请管理",
    manage_manage: "支付设置",
    manage_add: "创建商户号",
    bonus_settle: "佣金审核",
    bonus_stream: "佣金流水",
    bonus_recharge: "充值",
    money_manage: "财务管理",
    driver_manage: "司机管理",
    leader_manage: "团长管理",
    leader_bonus: "团长佣金",
    leader_interview: "团长审核",
    modify_password: "修改密码",
    shop_setting: "在线店铺",
    activity_setting: "店铺页面",
    market_setting: "营销活动",
    scene_marketing: "场景营销",
    integral_exchange: "编辑积分兑换商城",
    market_add: "创建营销",
    campaign_detail: "店铺页面详情",
    activity_type_setting: "活动类型管理",
    activity_add: "创建活动",
    activity_edit: "编辑活动",
    page_manage: "商品详情页",
    verson_manage: "版本订购",
    merchant_manage: "商户号管理",
    integral_manage: "积分管理",
    goods_list_page: "商品列表页",
    coupon_manage: "优惠券",
    assets_manage: "店铺资金",
    pop_up_promotion: "弹窗推广",
    promote_manage: "推广管理",
    promote_chanel: "渠道管理",
    promote_monitor: "推广监控",
    promote_data_statistics: "数据统计详情",
    live_list: "直播",
    client_manage: "客户管理",
    client: "客户详情",
    full_reduction: "满减/满赠",
    card_manage: "权益卡管理",
    fission_coupon: "分享优惠券",
    basic_info: "基本信息",
  },
  admin_user_id: null,
  side_bar: [
    {
      name: "首页",
      icon: "icon-shouye",
      selectIcon: "icon-shouye",
      router: "/home",
      children: null,
      value: "home"
    },
    {
      name: "订单",
      icon: "icon-dingdanguanli-xianxing",
      selectIcon: "icon-dingdanguanli-xianxing",
      router: "/order",
      value: "order",
      children: [
        {
          name: "订单查询",
          icon: "icon-dingdan",
          router: "/orderManage",
          value: "orderManage"
        },
        {
          name: "售后服务",
          icon: "icon-shouhou",
          router: "/afterSaleManage",
          children: null,
          value: "afterSaleManage"
        },
        {
          name: "发货记录",
          router: "/orderShipmentHistory",
          value: "orderShipmentHistory"
        },
        {
          name: "快递模版",
          router: "/shippingManage",
          value: "shippingManage"
        },
        {
          name: '发票管理',
          router: '/invoiceManage',
          value: 'invoiceManage',
        },
      ]
    },
    {
      name: "商品",
      icon: "icon-shangpin-xianxing",
      selectIcon: "icon-shangpin-xianxing",
      router: "/goods",
      value: "goods",
      children: [
        {
          name: "商品列表",
          router: "/goodsManage",
          value: "goodsManage"
        },
        {
          name: "商品分组",
          router: "/goodsListPage",
          value: "goodsListPage"
        },
      ]
    },
    {
      name: "客户",
      icon: "icon-kehu-xianxing",
      selectIcon: "icon-kehu-xianxing",
      router: "/client",
      value: "client",
      children: [
        {
          name: "客户管理",
          router: "/clientManage",
          value: "clientManage"
        },
        // TODO 待删除
        {
          name: "等级管理",
          router: "/gradeManage",
          value: "gradeManage"
        }
      ]
    },
    {
      name: "数据",
      icon: "icon-gaikuang-xianxing",
      selectIcon: "icon-gaikuang-xianxing",
      router: "/dashboard",
      children: null,
      value: "dashboard"
    },
    {
      name: "营销",
      router: "/marketSetting",
      value: "marketSetting",
      icon: "icon-yingxiao-xianxing",
      selectIcon: "icon-yingxiao-xianxing",
    },
    {
      name: "在线店铺",
      icon: "icon-dianpuzhuangxiu-xianxing",
      selectIcon: "icon-dianpuzhuangxiu-xianxing",
      router: "/shopPainting",
      value: "shopPainting",
    },
    {
      name: "管理员设置",
      icon: "icon-guanliyuanshezhi-xianxing",
      selectIcon: "icon-guanliyuanshezhi-xianxing",
      router: "/guanliyuan",
      value: "guanliyuan",
      is_admin: 1,
      children: [
        {
          name: "授权设置",
          icon: "icon-daima",
          router: "/codeManage",
          children: null,
          value: "codeManage"
        },
        {
          name: "小程序申请管理",
          icon: "icon-daima",
          router: "/appletApplyManage",
          children: null,
          value: "appletApplyManage"
        },
        {
          name: "商户号管理",
          icon: "icon-ren",
          router: "/adminMerchantManage",
          children: null,
          value: "adminMerchantManage"
        },
        {
          name: "账户管理",
          icon: "icon-ren",
          router: "/userManage",
          children: null,
          value: "userManage"
        },
        {
          name: "订阅消息",
          icon: "icon-shezhi",
          router: "/template?is_admin=1",
          children: null,
          value: "template?is_admin=1"
        },
        {
          name: "活动类型管理",
          router: "/activityTypeSetting",
          value: "activityTypeSetting"
        },
        {
          name: "页面管理",
          router: "/pageManage",
          value: "pageManage"
        }
      ]
    }
    // {
    //   name: "概况",
    //   icon: "icon-gaikuangxianxing",
    //   selectIcon: "icon-gaikuangxianxing",
    //   router: "/dashboard",
    //   children: null,
    //   value: "dashboard"
    // },
    // {
    //   name: "店铺装修",
    //   icon: "icon-dianpuzhuangxiuxianxing",
    //   selectIcon: "icon-dianpuzhuangxiuxianxing",
    //   router: "/shopPainting",
    //   value: "shopPainting",
    // },
    // {
    //   name: "营销",
    //   router: "/marketSetting",
    //   value: "marketSetting",
    //   icon: "icon-yingxiaoxianxing",
    //   selectIcon: "icon-yingxiaoxianxing",
    // },
    // {
    //   name: "商品",
    //   icon: "icon-shangpinxianxing",
    //   selectIcon: "icon-shangpinxianxing",
    //   router: "/goods",
    //   value: "goods",
    //   children: [
    //     {
    //       name: "商品管理",
    //       router: "/goodsManage",
    //       value: "goodsManage"
    //     },
    //     {
    //       name: "商品详情页",
    //       router: "/userPageManage",
    //       value: "userPageManage"
    //     },
    //     {
    //       name: "商品列表页",
    //       router: "/goodsListPage",
    //       value: "goodsListPage"
    //     },
    //     {
    //       name: "进销存管理",
    //       icon: "icon-kucun",
    //       router: "/balanceManage",
    //       children: null,
    //       value: "balanceManage"
    //     }
    //   ]
    // },
    // {
    //   name: "订单",
    //   icon: "icon-dingdanguanlixianxing",
    //   selectIcon: "icon-dingdanguanlixianxing",
    //   router: "/order",
    //   value: "order",
    //   children: [
    //     {
    //       name: "订单查询",
    //       icon: "icon-dingdan",
    //       router: "/orderManage",
    //       value: "orderManage"
    //     },
    //     {
    //       name: "售后服务",
    //       icon: "icon-shouhou",
    //       router: "/afterSaleManage",
    //       children: null,
    //       value: "afterSaleManage"
    //     },
    //     {
    //       name: "发货记录",
    //       router: "/orderShipmentHistory",
    //       value: "orderShipmentHistory"
    //     },
    //     {
    //       name: "快递模版",
    //       router: "/shippingManage",
    //       value: "shippingManage"
    //     },
    //     {
    //       name: '发票管理',
    //       router: '/invoiceManage',
    //       value: 'invoiceManage',
    //     },
    //   ]
    // },
    // {
    //   name: "客户",
    //   icon: "icon-kehuxianxing",
    //   selectIcon: "icon-kehuxianxing",
    //   router: "/client",
    //   value: "client",
    //   children: [
    //     {
    //       name: "客户管理",
    //       router: "/clientManage",
    //       value: "clientManage"
    //     },
    //     {
    //       name: "权益卡管理",
    //       router: "/cardManage",
    //       value: "cardManage"
    //     },
    //     {
    //       name: "等级管理",
    //       router: "/gradeManage",
    //       value: "gradeManage"
    //     }
    //   ]
    // },
    // {
    //   name: "资产",
    //   icon: "icon-zichanxianxing",
    //   selectIcon: "icon-zichanxianxing",
    //   router: "/leader",
    //   value: "leader",
    //   children: [
    //     {
    //       name: "店铺资金",
    //       router: "/assetsManage",
    //       value: "assetsManage"
    //     },
    //     {
    //       name: "现金管理",
    //       router: "/cashManage",
    //       value: "cashManage"
    //     },
    //     {
    //       name: "红包管理",
    //       router: "/redBagManage",
    //       value: "redBagManage"
    //     },
    //     {
    //       name: "积分管理",
    //       router: "/integralManage",
    //       value: "integralManage"
    //     }
    //   ]
    // },
    // {
    //   name: "推广",
    //   icon: "icon-tuiguangxianxing",
    //   selectIcon: "icon-tuiguangxianxing",
    //   router: "/promote",
    //   value: "promote",
    //   children: [
    //     {
    //       name: "推广管理",
    //       router: "/promoteManage",
    //       value: "promoteManage"
    //     },
    //     {
    //       name: "渠道管理",
    //       router: "/promoteChanel",
    //       value: "promoteChanel"
    //     }
    //   ]
    // },
    // {
    //   name: "小程序设置",
    //   icon: "icon-xiaochengxuguanlixianxing",
    //   selectIcon: "icon-xiaochengxuguanlixianxing",
    //   router: "/shezhi",
    //   value: "shezhi",
    //   children: [
    //     {
    //       name: "小程序管理",
    //       router: "/appletManage",
    //       value: "appletManage"
    //     },
    //     {
    //       name: "支付设置",
    //       router: "/merchantManage",
    //       value: "merchantManage"
    //     },
    //     {
    //       name: "版本订购",
    //       router: "/appOrder",
    //       value: "appOrder"
    //     },
    //     {
    //       name: "订阅消息",
    //       router: "/template?is_admin=0",
    //       value: "template?is_admin=0"
    //     }
    //   ]
    // },
    // {
    //   name: "管理员设置",
    //   icon: "icon-guanliyuanshezhixianxing",
    //   selectIcon: "icon-guanliyuanshezhixianxing",
    //   router: "/guanliyuan",
    //   value: "guanliyuan",
    //   is_admin: 1,
    //   children: [
    //     {
    //       name: "授权设置",
    //       icon: "icon-daima",
    //       router: "/codeManage",
    //       children: null,
    //       value: "codeManage"
    //     },
    //     {
    //       name: "小程序申请管理",
    //       icon: "icon-daima",
    //       router: "/appletApplyManage",
    //       children: null,
    //       value: "appletApplyManage"
    //     },
    //     {
    //       name: "商户号管理",
    //       icon: "icon-ren",
    //       router: "/adminMerchantManage",
    //       children: null,
    //       value: "adminMerchantManage"
    //     },
    //     {
    //       name: "账户管理",
    //       icon: "icon-ren",
    //       router: "/userManage",
    //       children: null,
    //       value: "userManage"
    //     },
    //     {
    //       name: "订阅消息",
    //       icon: "icon-shezhi",
    //       router: "/template?is_admin=1",
    //       children: null,
    //       value: "template?is_admin=1"
    //     },
    //     {
    //       name: "活动类型管理",
    //       router: "/activityTypeSetting",
    //       value: "activityTypeSetting"
    //     },
    //     {
    //       name: "页面管理",
    //       router: "/pageManage",
    //       value: "pageManage"
    //     }
    //   ]
    // }
  ],
  order_status_list: [
    {
      order_status: 0,
      order_status_short_desc: "待付款",
      order_status_desc: "商品已拍下等待买家付款",
      reason: null
    },
    {
      order_status: 1,
      order_status_short_desc: "待配送",
      order_status_desc: "买家已付款，请尽快",
      reason: null
    },
    {
      order_status: 2,
      order_status_short_desc: "已发货",
      order_status_desc: "商家已发货，等待交易成功",
      reason: null
    },
    {
      order_status: 3,
      order_status_short_desc: "待自提",
      order_status_desc: "商家已发货，等待买家自提",
      reason: null
    },
    {
      order_status: 4,
      order_status_short_desc: "已完成",
      order_status_desc: "交易完成",
      reason: null
    },
    {
      order_status: 5,
      order_status_short_desc: "已取消",
      order_status_desc: "订单已关闭",
      reason: "买家超时未付款/卖家取消订单"
    }
  ],
  cj_order_status_list: [
    {
      order_status: -1,
      order_status_short_desc: "已失效",
      order_status_desc: "买家拼团未成功，订单失效",
      reason: null
    },
    {
      order_status: 0,
      order_status_short_desc: "下单",
      order_status_desc: "下单",
      reason: null
    },
    {
      order_status: 1,
      order_status_short_desc: "已支付",
      order_status_desc: "买家已支付订单，等待拼团",
      reason: null
    },
    {
      order_status: 2,
      order_status_short_desc: "已成团",
      order_status_desc: "买家已拼团成功",
      reason: null
    },
    {
      order_status: 3,
      order_status_short_desc: "已发货",
      order_status_desc: "买家已中奖，订单已发货，请买家尽快确认收货",
      reason: null
    },
    {
      order_status: 4,
      order_status_short_desc: "已完成",
      order_status_desc: "买家已确认收货，交易已完成"
    }
  ],
  refund_status_list: [
    {
      value: "团长待审核",
      status: 0
    },
    {
      value: "团长审核失败",
      status: 1
    },
    {
      value: "商家待审核",
      status: 0
    },
    {
      value: "商家审核通过待用户提交物流信息",
      status: 0
    },
    {
      value: "商家审核失败",
      status: 1
    },
    {
      value: "商家待处理",
      status: 1
    },
    {
      value: "商家处理失败",
      status: 1
    },
    {
      value: "待提交微信退款",
      status: 0
    },
    {
      value: "提交微信退款成功",
      status: 0
    },
    {
      value: "提交微信退款失败",
      status: 1
    },
    {
      value: "微信退款成功",
      status: 0
    },
    {
      value: "微信退款失败",
      status: 1
    },
    {
      value: "已取消",
      status: 1
    }
  ],
  app_version: [
    {
      name: "个体工商户版",
      version: "base_version",
      icon: "icon-wo",
      desc: "我是个体工商户，已有线下销售路径，想要增加线上销售渠道，积累线上客源。",
      admin: 1,
      shop: 1,
      fee: '999元/年'
    },
    {
      name: "商家版",
      version: "special_version",
      icon: "icon-dianpu",
      desc: "我是商家，我有优质货源，想要开辟新的流量池，增加销售渠道，卖更多好货。",
      admin: 5,
      shop: 3,
      fee: '1999元/年'
    },
    {
      name: "品牌版",
      version: "flagship_version",
      icon: "icon-viphuiyuanhuangguan",
      desc: "我是品牌方，已有其他平台销售渠道和资源，想增加微信小程序销售渠道。",
      admin: 30,
      shop: 5,
      fee: '面议',
    }
  ],
  app_function: [
    {
      name: "小程序创建",
      no_surport: null
    },
    {
      name: "微信商户号创建",
      no_surport: null
    },
    {
      name: "一键生成小程序商城",
      no_surport: null
    },
    {
      name: "部分店铺装修设置",
      no_show: ["品牌版", "商家版"],
      no_surport: null
    },
    {
      name: "店铺装修设置",
      no_show: ["个体工商户版"],
      no_surport: null
    },
    {
      name: "快速上商品开店",
      no_surport: null
    },
    {
      name: "订单发货售后处理",
      no_surport: null
    },
    {
      name: "财务管理",
      no_surport: null
    },
    {
      name: "数据管理",
      no_surport: null
    },
    {
      name: "客服交流工具",
      no_surport: null
    },
    {
      name: "分销、直销选择配置",
      no_show: ["个体工商户版"],
      no_surport: null
    },
    {
      name: "分销裂变营销工具",
      no_show: ["品牌版", "商家版"],
      no_surport: ['个体工商户版']
    },
    {
      name: "活动营销玩法",
      no_surport: ["个体工商户版"]
    },
    {
      name: "特殊需求定制开发",
      no_surport: ["个体工商户版", "商家版"]
    },
    {
      name: "特色店铺模板",
      no_surport: ["个体工商户版", "商家版"]
    },
    {
      name: "最新营销工具与功能",
      no_surport: ["个体工商户版", "商家版"]
    }
  ],
  activity_sub_type: [
    { activity_name: "每日必买", activity_subtype: "buyEveryDay", desc: "固定展示活动列表，可添加日常促销或处理商品" },
    { activity_name: "会员日", activity_subtype: "memberDay", desc: "会员活动列表，可在会员日设置，添加会员专享商品" },
    { activity_name: "限时秒杀", activity_subtype: "timeLimitBuy", desc: "秒杀活动列表，可添加秒杀商品" }
  ]
};
