import React from 'react'
import config from "@config/config";
import {Modal,Radio,Form,Input,Select,Upload,message} from 'antd'
import {createOrUpdateCat, getCatList} from "@service/getData";
import UploadButton from "../common/UploadButton";
import cookie from "react-cookies";
class GroupGoodAdd extends React.Component{
    constructor(){
        super()
        this.state={
            cat_list:[],
            file_list: [],
            request_url: config.baseUrl + "/ossFile/upload",
            preview_gallery_image:null,
            preview_gallery_visible: false,
            cat:null,
            second_cat_list:[]
        }
    }
    getCatList =async ()=>{
        let data={
            mini_app_id:cookie.load("mini_app_id")
        }
        let res=await getCatList(data);
        if(res.success){
            this.setState({
                cat_list:res.result.cat_list,
                cat:null,
                file_list:[]
            })
        }
    }
    createOrUpdateCat=async (data,status)=>{
        if(status===0){
            data={
                cat_id:data,
                status:status
            }
        }
        let res=await createOrUpdateCat(data);
        if(res.success){
            this.props.form.resetFields();
            message.success("操作成功")
            this.setState({
                file_list:[]

            })
            this.props.goodsOk();
        }
    }
    handleGalleryChange = ({fileList}) => {
        this.setState({file_list: fileList})
    }
    handleGalleryPreview = (file) => {
        this.setState({
            preview_gallery_image: file.url || file.thumbUrl,
            preview_gallery_visible: true,
        });
    }
    showModal=()=>{
        this.setState({
            visible:true
        })
    }
    handleOK=()=>{
        let form = this.props.form.getFieldsValue();
        let flag = false
        this.props.form.validateFields(err => {
            if (err) {
                flag = true
            }
        });
        if (flag) return
        let image=null
        this.state.file_list.forEach(item=>{
            if(item.response&&item.response.success){
                image=item.response.result.oss_file_url
            }else{
                image=item.url
            }
        })
        let data={
            mini_app_id:cookie.load("mini_app_id"),
            cat_name:form.cat_name||form.second_cat_name,
            cat_image:image,
            parent_id:form.parent_id?form.parent_id:0,
            level:form.level,
            status:1
        }
        this.createOrUpdateCat(data)
    }
    handleCancel=()=>{
        this.setState({
            visible:false,
            cat:null
        })
    }
    componentDidMount(){
        this.getCatList()
    }
    render(){
        const {getFieldDecorator} = this.props.form;
        return(
            <Modal
                className="cat-add-modal"
                visible={this.props.visible}
                title="新建分组"
                onOk={this.handleOK}
                onCancel={this.props.onCancel}
                okButtonProps={{shape:'round'}}
                cancelButtonProps={{shape:'round'}}
            >
                <Form>
                    <Form.Item label="级别">
                        {getFieldDecorator('level',{
                            initialValue:this.state.cat?this.state.cat.level:"1",
                            rules: [{
                                required:true,
                                message: "请选择级别!"
                            }]
                        })(
                            <Radio.Group >
                                <Radio value="1">一级</Radio>
                                <Radio value="2">二级</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item label="一级分组名称" style={{display:this.props.form.getFieldValue("level") ==="1"?true:"none"}}>
                        {getFieldDecorator('cat_name',{
                            initialValue:this.state.cat&&this.state.cat.level==="1"?this.state.cat.cat_name:null,
                            rules: [{
                                required:this.props.form.getFieldValue("level") ==="1",
                                message: "请填写一级分组名称!"
                            }]

                        })(
                            <Input className="cat-input"/>

                        )}
                    </Form.Item>
                    <Form.Item label="一级分组名称" style={{display:this.props.form.getFieldValue("level") ==="2"?true:"none"}}>
                        {getFieldDecorator('parent_id',{
                            initialValue:this.state.cat&&this.state.cat.level==="2"?this.state.cat.cat_id:null,
                            rules: [{
                                required:this.props.form.getFieldValue("level") ==="2",
                                message: "请选择级别!"
                            }]

                        })(
                            <Select  className="cat-input">
                                {
                                    this.state.cat_list.map((item,index)=>(
                                        <Select.Option
                                            value={item.cat_id}
                                            key={index}>
                                            {item.cat_name}
                                        </Select.Option>
                                    ))
                                }
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="二级分组名称" style={{display:this.props.form.getFieldValue("level") ==="2"?true:"none"}}>
                        {getFieldDecorator('second_cat_name',{
                            initialValue:this.state.cat&&this.state.cat.level==="2"?this.state.cat.cat_name:null,
                            rules: [{
                                required:this.props.form.getFieldValue("level") ==="2",
                                message: "请填写二级分组名称!"
                            }]

                        })(
                            <Input className="cat-input"/>
                        )}

                    </Form.Item>
                    <Form.Item label={(this.props.form.getFieldValue("level") ==="2"?"二级":"一级")+"分组图片"}
                               extra={
                                   <div className="text-remind">
                                       建议尺寸
                                       {
                                           this.props.form.getFieldValue("level") ==="2"?"1:1":"258px*91px"
                                       }
                                       ，仅支持jpg与png两种格式，图片大小不超过3.0MB
                                   </div>}>
                        {getFieldDecorator('pic',{
                            initialValue:this.state.cat?this.state.cat.cat_image:null,
                            rules: [{
                                required:this.props.form.getFieldValue("level") ==="2"?true:false,
                                message: "请选择图片!"
                            }]

                        })(
                            <Upload
                                accept="image/jpeg,image/jpg,image/png"
                                withCredentials={true}
                                action={this.state.request_url}
                                beforeUpload={this.beforeUpdate}
                                listType="picture-card"
                                fileList={this.state.file_list}
                                onPreview={this.handleGalleryPreview}
                                onChange={this.handleGalleryChange}
                            >

                                {this.state.file_list.length >= 1 ? null : <UploadButton/>}
                            </Upload>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}
const groupGoodsManageAddForm = Form.create({name: 'group_goods_add_form'})(GroupGoodAdd);

export  default  groupGoodsManageAddForm
