import React from 'react'
import { Steps, Table, Popconfirm, Input, Button, message, Spin, Modal, Form, Collapse } from 'antd'
import '@css/orderDetail.scss'
import { queryOrderDetail, updateOrder, cancelOrder, queryRefundOrderDetail, createOrUpdateRefundRecord, shopApprovalRefundOrder, queryRefundRecordList, queryCjOrderDetail, getData } from '@service/getData'
import { getQueryString, formatNumber } from "@common/common";
import NavBar from '@/view/component/common/NavBar.js'
import RefundOrderMoneyModal from '@/view/component/common/RefundOrderMoneyModal'
import cookie from 'react-cookies'
import { connect } from "react-redux";
import PropTypes from "prop-types";
class OrderDetail extends React.Component {
    constructor() {
        super();
        OrderDetail.contextTypes = {
            router: PropTypes.object.isRequired
        };
        this.state = {
            order_id: null,
            columns: [{
                title: '商品',
                dataIndex: 'goods_name'
            }, {
                title: '规格/价格',
                dataIndex: 'sku',
                render: (text, record) => (
                    <div>
                        <p>{text || '暂无规格'}</p>
                        <p>{record.current_price}</p>
                    </div>
                )
            }, {
                title: '商品编码',
                dataIndex: 'goods_code'
            }, {
                title: '数量',
                dataIndex: 'quantity'
            }, {
                title: '价格',
                dataIndex: 'current_price'
            }, {
                title: '积分兑换',
                dataIndex: 'points_amount'
            }, {
                title: '商品优惠',
                dataIndex: 'goods_cut'
            }, {
                title: '小计',
                dataIndex: 'total'
            }, {
                title: '售后状态',
                dataIndex: 'refund_status',
                render: (text, row) => (
                    <div>
                        <p>{text}</p>
                        {row.order_goods_refund_status > 6 && row.order_goods_refund_status < 12 ? (
                            <p className="text-note" onClick={this.goOrderDetail.bind(this, row.order_id)}>退款详情</p>
                        ) : null}
                    </div>
                )
            }, {
                title: '订单状态',
                dataIndex: 'status',
            }, {
                title: '操作',
                dataIndex: 'order_id',
                render: (text) => (
                    <span onClick={this.showPeriodPurchaseRecord.bind(this, text)} className="text-note">发货记录</span>
                )
            }
            ],
            normal_columns: [{
                title: '商品',
                dataIndex: 'goods_name',
                render: (text, record) => (
                    <div className="goodsInfoWrap">
                        <img className="goodsImg" src={record.thumbnail_url} alt="" />
                        <div className="nameAndId">
                            <p className="name">{text}</p>
                            <p className="goodsId">商品id: {record.goods_id}</p>
                        </div>
                    </div>
                )
            }, {
                title: '规格/价格',
                dataIndex: 'sku',
                render: (text, record) => (
                    <div>
                        <p>{text || '暂无规格'}</p>
                        <p>{record.current_price}</p>
                    </div>
                )
            }, {
                title: '数量',
                dataIndex: 'quantity'
            }, {
                title: '小计',
                dataIndex: 'total'
            }, {
                title: '商品优惠',
                dataIndex: 'goods_discount_amount',
                render: (value, row) => {
                    let obj = {
                        children: (
                            <div>
                                <div>满减:{this.state.activity_coupon_amount / 100}</div>
                                <div>优惠券:{this.state.goods_discount_amount / 100}</div>
                                <div>满折:{this.state.discount_by_rate_amount / 100}</div>
                            </div>
                        ),
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    } else { obj.props.rowSpan = 0 }

                    return obj;
                },
            }, {
                title: '优惠信息',
                dataIndex: 'discount_info',
                render: (value, row, index) => {
                    let obj = {
                        children:
                            <>
                                {index === 0 ? (
                                    <pre>{this.state.discount_info_str}</pre>
                                ) : null}
                            </>,
                        props: {},
                    };
                    if (row.rowSpan != null) {
                        obj.props.rowSpan = row.rowSpan
                    }
                    else {
                        obj.props.rowSpan = 0
                    }
                    return obj;
                },

            }, {
                title: '售后状态',
                dataIndex: 'refund_status',
                render: (text, row) => (
                    <div>
                        <p>{text}</p>
                        {row.order_goods_refund_status > 6 && row.order_goods_refund_status < 12 ? (
                            <p className="text-note" onClick={this.goOrderDetail.bind(this, row.order_id)}>退款详情</p>
                        ) : null}
                    </div>
                )
            }, {
                title: '订单状态',
                dataIndex: 'status',
            }, {
                title: '操作',
                dataIndex: 'order_id',
                render: (text) => (
                    <span onClick={this.showPeriodPurchaseRecord.bind(this, text)} className="text-note">发货记录</span>
                )
            }
            ],
            refund_status: [
                "团长待审核",
                "团长审核失败",
                "商家待审核",
                "商家审核通过待用户提交物流信息",
                "商家审核失败",
                "商家待处理",
                "商家处理失败",
                "待提交微信退款",
                "提交微信退款成功",
                "提交微信退款失败",
                "微信退款成功",
                "微信退款失败",
                "已取消"
            ],
            content_type_list: [
                {
                    name: "user_refund_request",
                    value: "买家诉求"
                },
                {
                    name: "user_refund_progress",
                    value: "协商进度"
                },
                {
                    name: "user_refund_result",
                    value: "售后结果"
                }

            ],
            cj_refund_status: [
                "正常",
                "申请退款",
                "退款中",
                "退款成功",
                "退款失败"
            ],
            content_type: {
                name: null,
                value: null
            },
            order: {
                order_sn: null,
                order_type: null,
                nick_name: null,
                shipment_type: null,
                receiver_name: null,
                mobile: null,
                address: null,
                order_status: null,
                order_status_desc: null
            },
            goodsList: [],
            shipment_fee: null,
            pay_amount: null,
            discount_amount: null,
            original_price: null,
            order_time: null,
            pay_time: null,
            shipping_time: null,
            change_shipment_fee: null,
            change_discount_amount: null,
            logistics: null,
            shipment_sn: null,
            loading: false,
            visible: false,
            content: null,
            refund_order_id: null,
            refund_record_list: [],
            refund_visible: false,
            cj_order_id: null,
            goods_discount_amount: 0,
            manual_discount_amount: 0,
            discount_by_rate_amount: 0,
            discount_info_str: '',
            type: 'orderManage',
            activity_id: null,
            activity_coupon_amount: null,
            order_type: {
                points: "积分兑换订单",
                general: "普通订单",
                free_order: '抢免单订单',
                lucky: "抽奖订单",
                period_purchase: '周期购',
                rank_lucky: '线上拼团',
            },
            invoice_info: null,
            period_purchase_res: {},
            periodVisible: false,
            periodColumns: [
                {
                    title: '期数',
                    dataIndex: 'period_order',
                },
                {
                    title: '物流信息',
                    dataIndex: 'logistics',
                },
                {
                    title: '物流单号',
                    dataIndex: 'tracking_number',
                },
                {
                    title: '发货时间',
                    dataIndex: 'ship_time',
                },
            ],
            releaseRecords: [],
        }
    }
    queryCjOrderDetail = async (cj_order_id) => {
        let data = {
            cj_order_id
        }
        let res = await queryCjOrderDetail(data)
        if (res.success) {
            let cj_order_res = res.result.cj_order_res
            let order = {
                order_sn: cj_order_res.cj_order_sn,
                channel_name: cj_order_res.channel_name || '',
                shipment_type: "快递",
                order_type: this.state.order_type[cj_order_res.cj_order_type],
                nick_name: cj_order_res.user_res.nick_name,
                receiver_name: cj_order_res.address_res.receiver_name,
                mobile: cj_order_res.address_res.tel_number,
                address: cj_order_res.address_res.province_name + cj_order_res.address_res.city_name + cj_order_res.address_res.county_name + cj_order_res.address_res.detail_info,
                order_status_desc: this.props.cj_order_status_list[cj_order_res.order_status + 1].order_status_desc,
                order_status: cj_order_res.order_status,
                reason: this.props.cj_order_status_list[cj_order_res.order_status + 1].reason,
                refund_status_desc: this.state.cj_refund_status[cj_order_res.refund_status],
                is_lucky_desc: cj_order_res.is_lucky ? "中奖" : "未中奖",
                is_luck: cj_order_res.is_lucky,
                remark: cj_order_res.remark,
                refund_status: cj_order_res.refund_status,
                customer_num: cj_order_res.customer_num,
                group_member_num: cj_order_res.group_member_num,
                cj_order_type: cj_order_res.cj_order_type,
                free_order_back_cash_amount: cj_order_res.free_order_back_cash_amount,
                free_order_back_redpack_amount: cj_order_res.free_order_back_redpack_amount
            }
            let goodsList = []
            let goods = {
                goods_name: cj_order_res.goods_res.goods_name,
                goods_code: cj_order_res.goods_res.goods_code,
                goods_cut: null,
                current_price: cj_order_res.sku_res.current_price / 100,
                total: cj_order_res.sku_res.current_price / 100,
                quantity: 1,
                sku_code: cj_order_res.sku_code,
                rowSpan: 1
            }
            if (cj_order_res.order_goods_refund_status == null) {
                goods.status = this.props.cj_order_status_list[cj_order_res.order_status + 1].order_status_short_desc
            } else {
                goods.status = this.state.refund_status[cj_order_res.order_goods_refund_status]
            }
            goodsList.push(goods)
            this.setState({
                order: order,
                goodsList: goodsList,
                shipment_fee: cj_order_res.shipment_fee / 100,
                pay_amount: cj_order_res.pay_amount ? cj_order_res.pay_amount / 100 : 0,
                discount_amount: cj_order_res.discount_amount ? cj_order_res.discount_amount / 100 : 0,
                original_price: cj_order_res.pay_amount / 100,
                order_time: cj_order_res.order_time,
                pay_time: cj_order_res.pay_time,
                shipping_time: cj_order_res.shipping_time,
                shipment_sn: cj_order_res.shipment_res ? cj_order_res.shipment_res.tracking_number : null,
                logistics: cj_order_res.shipment_res ? cj_order_res.shipment_res.logistics : null,
                shipment_type: cj_order_res.shipment_res ? cj_order_res.shipment_res.shipment_type : null,
                driver_name: cj_order_res.shipment_res && cj_order_res.shipment_res.vehicle_res ? cj_order_res.shipment_res.vehicle_res.driver_name : null
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }
    }

    async queryOrderDetail(order_id) {
        let data = {
            order_id: order_id
        }
        this.setState({
            order_id: order_id
        })
        let res = await queryOrderDetail(data)
        if (res.success) {
            let order = {
                order_sn: res.result.order_res.order_sn,
                channel_name: res.result.order_res.channel_name,
                shipment_type: res.result.order_res.shipment_type === "group_shipment" ? "自提" : "快递",
                nick_name: res.result.order_res.user_res.nick_name,
                receiver_name: res.result.order_res.address_res.receiver_name,
                mobile: res.result.order_res.address_res.tel_number,
                address: res.result.order_res.address_res.province_name + res.result.order_res.address_res.city_name + res.result.order_res.address_res.county_name + res.result.order_res.address_res.detail_info,
                order_status_desc: this.props.order_status_list[res.result.order_res.order_status].order_status_desc,
                order_status: res.result.order_res.order_status,
                remark: res.result.order_res.remark,
                reason: this.props.order_status_list[res.result.order_res.order_status].reason,
                refund_status: res.result.order_res.refund_status,
                order_type: this.state.order_type[res.result.order_res.order_type]
            }
            let goodsList = []
            res.result.order_res.order_goods_res_list.forEach((item, index) => {
                let goods = {
                    goods_name: item.goods_res.goods_name,
                    goods_code: item.goods_res.goods_code,
                    goods_cut: null,
                    current_price: item.sku_res.current_price / 100,
                    total: item.sku_res.current_price * item.quantity / 100,
                    quantity: item.quantity,
                    sku_code: item.sku_code,
                    points_amount: item.points_amount,
                    goods_discount_amount: res.result.order_res.goods_discount_amount / 100,
                    order_id: item.order_id,
                    thumbnail_url: item.goods_res.thumbnail_url,
                    goods_id: item.goods_res.goods_id,
                    status: this.props.order_status_list[res.result.order_res.order_status].order_status_short_desc,
                    refund_status: item.order_goods_refund_status ? this.state.refund_status[item.order_goods_refund_status] : null,
                    order_goods_refund_status: item.order_goods_refund_status,
                }
                if (index === 0) {
                    goods.rowSpan = res.result.order_res.order_goods_res_list.length
                    if (res.result.order_res.order_promotion_res_list) {
                        goods.order_promotion_res_list = res.result.order_res.order_promotion_res_list
                    }

                } else {
                    goods.order_promotion_res_list = []
                }
                goodsList.push(goods)
            })

            this.setState({
                order: order,
                goodsList: goodsList,
                shipment_fee: res.result.order_res.shipment_fee / 100,
                pay_amount: res.result.order_res.pay_amount ? res.result.order_res.pay_amount / 100 : 0,
                discount_amount: res.result.order_res.discount_amount ? res.result.order_res.discount_amount / 100 : 0,
                original_price: (res.result.order_res.pay_amount + res.result.order_res.discount_amount - res.result.order_res.shipment_fee) / 100,
                order_time: res.result.order_res.order_time,
                pay_time: res.result.order_res.pay_time,
                shipping_time: res.result.order_res.shipping_time,
                shipment_sn: res.result.shipment_res ? res.result.shipment_res.tracking_number : null,
                logistics: res.result.shipment_res ? res.result.shipment_res.logistics : null,
                shipment_type: res.result.shipment_res ? res.result.shipment_res.shipment_type : null,
                driver_name: res.result.shipment_res && res.result.shipment_res.vehicle_res ? res.result.shipment_res.vehicle_res.driver_name : null,
                goods_discount_amount: res.result.order_res.goods_discount_amount,
                discount_info_str: res.result.order_res.discount_info_str,
                discount_by_rate_amount: res.result.order_res.discount_by_rate_amount,
                activity_coupon_amount: res.result.order_res.activity_coupon_amount,
                manual_discount_amount: res.result.order_res.manual_discount_amount / 100,
                invoice_info: res.result.order_res.invoice_info || null,
                period_purchase_res: res.result.order_res.period_purchase_res || {},
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }
    }
    async queryRefundOrderDetail(refund_order_id) {
        let data = {
            refund_order_id: refund_order_id
        }
        let res = await queryRefundOrderDetail(data)
        if (res.success) {

            let order = {
                order_sn: res.result.refund_order_res.order_res.order_sn,
                channel_name: res.result.refund_order_res.order_res.channel_name,
                shipment_type: res.result.refund_order_res.order_res.shipment_type === "group_shipment" ? "自提" : "快递",
                order_type: this.state.order_type[res.result.refund_order_res.order_res.order_type],
                nick_name: res.result.refund_order_res.order_res.user_res.nick_name,
                receiver_name: res.result.refund_order_res.order_res.address_res.receiver_name,
                mobile: res.result.refund_order_res.order_res.address_res.tel_number,
                address: res.result.refund_order_res.order_res.address_res.province_name + res.result.refund_order_res.order_res.address_res.city_name + res.result.refund_order_res.order_res.address_res.county_name + res.result.refund_order_res.order_res.address_res.detail_info,
                order_status_desc: this.props.order_status_list[res.result.refund_order_res.order_res.order_status].order_status_desc,
                order_status: res.result.refund_order_res.order_res.order_status,
                refund_status: res.result.refund_order_res.refund_status,
                remark: res.result.refund_order_res.remark,
                refund_status_desc: this.props.refund_status_list[res.result.refund_order_res.refund_status].value,
                finish_refund_status: this.props.refund_status_list[res.result.refund_order_res.refund_status].status,
            }
            let goodsList = []
            res.result.refund_order_res.refund_goods_res_list.forEach((item, index) => {
                let sku = ""
                item.sku_res.attribute_value_res_list.forEach(qitem => {
                    sku += qitem.attribute_value
                })
                let goods = {
                    goods_name: item.goods_res.goods_name,
                    sku: sku,
                    goods_cut: null,
                    current_price: item.sku_res.current_price / 100,
                    total: item.sku_res.current_price * item.quantity / 100,
                    quantity: item.quantity,
                    status: this.props.refund_status_list[res.result.refund_order_res.refund_status].value,
                    rowSpan: 1
                }
                if (index === 0) {
                    if (res.result.refund_order_res.order_res.order_promotion_res_list) {
                        goods.order_promotion_res_list = res.result.refund_order_res.order_res.order_promotion_res_list
                    }

                } else {
                    goods.order_promotion_res_list = []
                }


                goodsList.push(goods)
            })
            let refund_record_list = [];
            res.result.refund_order_res.refund_record_res_list.forEach(item => {
                let record = {
                    content: item.content
                }
                switch (item.content_type) {
                    case "user_refund_start": record.title = item.operate_time + " 买家对商品" + item.content + "发起了售后";
                        record.content = item.operate_time + " 买家对商品" + item.content + "发起了售后";
                        break;
                    case "user_refund_request": record.title = item.operate_time + " 卖家填写了买家诉求"
                        break;
                    case "user_refund_progress": record.title = item.operate_time + " 卖家添加了协商进度"
                        break;
                    case "user_refund_result": record.title = item.operate_time + " 卖家填写了售后结果"
                        break;
                    case "user_refund_cash": record.title = item.operate_time + " 商家向买家退款" + item.content / 100;
                        record.content = item.operate_time + " 商家向买家退款" + item.content / 100
                        break;
                    default: break;
                }
                refund_record_list.push(record)
            })
            this.setState({
                order: order,
                goodsList: goodsList,
                shipment_fee: res.result.refund_order_res.order_res.shipment_fee / 100,
                pay_amount: res.result.refund_order_res.order_res.pay_amount / 100,
                discount_amount: res.result.refund_order_res.order_res.discount_amount / 100,
                original_price: (res.result.refund_order_res.order_res.pay_amount + res.result.refund_order_res.order_res.discount_amount - res.result.refund_order_res.order_res.shipment_fee) / 100,
                order_time: res.result.refund_order_res.order_res.order_time,
                pay_time: res.result.refund_order_res.order_res.pay_time,
                shipping_time: res.result.refund_order_res.order_res.shipping_time,
                shipment_sn: res.result.refund_order_res.shipment_res ? res.result.shipment_res.tracking_number : null,
                logistics: res.result.refund_order_res.shipment_res ? res.result.refund_order_res.shipment_res.logistics : null,
                refund_record_list: refund_record_list,
                goods_discount_amount: res.result.refund_order_res.order_res.goods_discount_amount,
                discount_info_str: res.result.refund_order_res.order_res.discount_info_str,
                discount_by_rate_amount: res.result.refund_order_res.order_res.discount_by_rate_amount,
                activity_coupon_amount: res.result.refund_order_res.order_res.activity_coupon_amount
            }, () => {
                this.setState({
                    loading: false
                })
            })
        }
    }
    async updateOrder(data) {
        let res = await updateOrder(data);
        if (res.success) {
            message.success("修改订单成功")
            this.setState({
                change_shipment_fee: null,
                shipment_fee_visible: false,
                change_discount_amount: null,
                discount_amount_visible: false,
            })
            this.queryOrderDetail(this.state.order_id)
        }
    }
    async cancelOrder() {
        let data = {
            order_id: this.state.order_id
        }

        let res = await cancelOrder(data)
        if (res.success) {
            message.success("订单关闭成功")
            this.queryOrderDetail(this.state.order_id)


        }
    }
    async createOrUpdateRefundRecord(data) {
        let res = await createOrUpdateRefundRecord(data)
        if (res.success) {
            message.success("添加成功")
            let query_data = {
                refund_order_id: this.state.refund_order_id
            }
            this.setState({
                content: null
            })
            this.queryRefundRecordList(query_data)

        }
    }
    async queryRefundRecordList(data) {
        let res = await queryRefundRecordList(data)
        let refund_record_list = [];
        if (res.success) {
            res.result.refund_record_res_list.forEach(item => {
                let record = {
                    content: item.content
                }
                switch (item.content_type) {
                    case "user_refund_start": record.title = item.operate_time + " 买家对商品" + item.content + "发起了售后";
                        record.content = item.operate_time + " 买家对商品" + item.content + "发起了售后";

                        break;
                    case "user_refund_request": record.title = item.operate_time + " 卖家填写了买家诉求"
                        break;
                    case "user_refund_progress": record.title = item.operate_time + " 卖家添加了协商进度"
                        break;
                    case "user_refund_result": record.title = item.operate_time + " 卖家填写了售后结果"
                        break;
                    case "user_refund_cash": record.title = item.operate_time + " 商家向买家退款" + item.content / 100;
                        record.content = item.operate_time + " 商家向买家退款" + item.content / 100
                        break;
                    default: break;
                }
                refund_record_list.push(record)
            })
            this.setState({
                refund_record_list: refund_record_list,
                visible: false

            })

        }
    }
    goOrderDetail(order_id) {
        this.context.router.history.push(`/refundDetail?order_id=${order_id}`);
    }
    showPeriodPurchaseRecord(order_id) {
        const url = '/period_purchase/shipList';
        const data = {
            order_id,
        };
        getData(url, data).then((res) => {
            if (res.success) {
                this.setState({
                    releaseRecords: res.result,
                }, () => {
                    this.setState({
                        periodVisible: true,
                    });
                });
            }
        })
    }
    cancelOnPaidOrder = () => {
        this.cancelOrder()
    }

    changeShippingFee = (e) => {
        this.setState({
            change_shipment_fee: formatNumber(e.target.value)
        })
    }
    changeDiscountAmount = (e) => {
        this.setState({
            change_discount_amount: formatNumber(e.target.value)
        })
    }
    confirmChange = () => {
        let data = {
            order_id: this.state.order_id
        };

        if (this.state.change_shipment_fee) {
            let number = Number(this.state.change_shipment_fee) * 100 + Number(this.state.original_price) * 100 - Number(this.state.discount_amount) * 100;
            if (number <= 0) {
                this.setState({
                    change_shipment_fee: null
                })
                return message.error("订单总金额为负数")

            }
            data.shipment_fee = Math.round(this.state.change_shipment_fee * 100)
        }
        if (this.state.change_discount_amount) {
            if (this.state.change_discount_amount > this.state.pay_amount) {
                this.setState({
                    change_discount_amount: null
                })
                return message.error("优惠金额不能大于商品总价与邮费之和")

            }
            data.discount_amount = Math.round(this.state.change_discount_amount * 100)
        }
        this.updateOrder(data)
    }
    cancelChange = (e) => {
        this.setState({
            change_shipment_fee: null,
            change_discount_amount: null,
        });

    }
    async shopApprovalRefundOrder(data) {
        let res = await shopApprovalRefundOrder(data)
        if (res.success) {
            if (res.success) {
                if (res.result.wx_err_code === "FAIL") {
                    Modal.info({
                        title: '温馨提示',
                        okText: "我知道了",
                        content: (
                            <div>
                                <p>您还未授权退款功能，请登录微信支付商户平台：https://pay.weixin.qq.com，将退款功能授权给DTC Mall后再次进行退款操作。</p>
                                <p> 授权路径：产品中心→我授权的产品→将“服务商API退款”功能授权给服务商</p>
                            </div>
                        ),
                        onOk() {
                        },
                    });
                } else if (res.result.wx_err_code === "NOTENOUGH") {
                    Modal.info({
                        title: '温馨提示',
                        okText: "我知道了",
                        content: (
                            <div>
                                <p>您的账户余额不足，请确认微信支付账户金额大于退款金额后再次进行退款操作。微信支付商户平台：https://pay.weixin.qq.com。
                                </p>
                                <p>为确保正常退款，建议在微信支付账户保留部分金额以供退款。</p>
                            </div>
                        ),
                        onOk() {
                        },
                    });
                } else if (res.result.wx_err_code === "ERROR") {
                    Modal.info({
                        title: '温馨提示',
                        okText: "我知道了",
                        content: (
                            <div>
                                {res.result.wx_err_code_des}
                            </div>
                        ),
                        onOk() {
                        },
                    });
                } else {
                    message.success(data.status ? "退款成功" : "拒绝售后成功");
                }
                this.setState({
                    visible: false
                })
            } else {
                message.error(res.msg)
            }
            this.queryRefundOrderDetail(this.state.refund_order_id)
            this.setState({
                refund_visible: false
            })
        } else {
            message.error(res.msg)
        }
    }
    showModal = (status) => {
        this.setState(prevState => ({
            content_type: prevState.content_type_list[status]
        }), () => {
            this.setState({
                content: null,
                visible: true,
            });
        })
    }

    handleOk = () => {
        let data = {
            refund_record_req: {
                shop_id: cookie.load("shop_id"),
                content_type: this.state.content_type.name,
                content: this.state.content,
                status: 1,
                refund_order_id: this.state.refund_order_id
            }

        }
        this.createOrUpdateRefundRecord(data)


    }

    handleCancel = (e) => {
        this.setState({
            visible: false,
            content: null
        });
    }

    handleRefundOk = (refund_money) => {
        if (!refund_money) {
            return message.error("请输入金额")
        }
        if (refund_money > this.state.pay_amount) {
            return message.error("退款金额不得大于订单金额")
        }
        let data = {
            shop_id: cookie.load("shop_id"),
            refund_order_id: this.state.refund_order_id,
            refund_amount: Math.round(refund_money * 100),
            status: 1

        }
        this.shopApprovalRefundOrder(data)
    }
    handleRefundFalse = (refund_money) => {
        let data = {
            shop_id: cookie.load("shop_id"),
            refund_order_id: this.state.refund_order_id,
            status: 0

        }
        this.shopApprovalRefundOrder(data)
    }

    handleRefundCancel = () => {
        this.setState({
            refund_visible: false
        })
    }
    showRefundModal = (data) => {
        this.setState({
            refund_visible: true,
        })
    }
    changeContent = (e) => {
        this.setState({
            content: e.target.value,
        });
    }

    componentDidMount() {
        let order_id = getQueryString("order_id");
        let refund_order_id = getQueryString("refund_order_id");
        let cj_order_id = getQueryString("cj_order_id");
        let type = getQueryString("type")
        let activity_id = getQueryString("activity_id")
        if (order_id) {
            this.setState({
                loading: true,
                type,
                order_id,
            }, () => { this.queryOrderDetail(order_id) })

        }
        if (refund_order_id) {
            this.setState({
                loading: true,
                refund_order_id: refund_order_id,
                type
            }, () => { this.queryRefundOrderDetail(refund_order_id) })
        }
        if (cj_order_id) {
            this.setState({
                loading: true,
                cj_order_id,
                activity_id,
                type
            }, () => { this.queryCjOrderDetail(cj_order_id) })

        }

    }
    render() {
        const type = this.state.order.cj_order_type;
        return (
            <div className="order-detail">
                <Spin spinning={this.state.loading}>
                    <div className="content">
                        <NavBar
                            icon="icon-dingdan"
                            father={this.state.type === 'afterSaleManage' ? '售后服务' : '订单管理'}
                            child={this.state.type === 'afterSaleManage' ? '售后详情' : '订单详情'}
                            router={this.state.type === "marketTab" ? `marketTab?activity_id=${this.props.location.state}` : this.state.type}
                            state={this.state.type === "marketTab" ? "2" : null}
                        />
                        <div className="order-step" style={{ "display": this.state.cj_order_id || this.state.order.order_status >= 5 ? "none" : true }}>
                            <div className="goods-title">
                                <i className="title-sign"></i>
                             订单进程
                         </div>
                            <Steps className="order-steps" current={+this.state.order.order_status === 3 ? 3 : this.state.order.order_status + 1}>
                                <Steps.Step title="买家下单" description={this.state.order_time}></Steps.Step>
                                <Steps.Step title="买家付款" description={this.state.pay_time}></Steps.Step>
                                <Steps.Step title="卖家发货" description={this.state.shipping_time}></Steps.Step>
                                <Steps.Step title="交易完成"></Steps.Step>
                            </Steps>
                        </div>
                        <div className="order-detail-info">
                            <div className="order-info">
                                <div className="goods-title">
                                    <i className="title-sign"></i>
                                    订单信息
                                </div>
                                <p className="user-info"><span>订单编号：{this.state.order.order_sn}</span> <span>订单类型：{this.state.order.order_type}</span></p>
                                <p className="user-info"><span>买家微信名：{this.state.order.nick_name}</span><span>配送方式：{this.state.order.shipment_type}</span></p>
                                <p className="user-info">订单渠道：{this.state.order.channel_name}</p>
                                <div className="goods-title">
                                    <i className="title-sign"></i>
                                    {this.state.order.order_type === "自提" ? "团长信息" : "收货信息"}
                                </div>
                                <p>收货人：{this.state.order.receiver_name}</p>
                                <p>联系电话：{this.state.order.mobile}</p>
                                <p>收货地址：{this.state.order.address}</p>
                            </div>
                            <div className="order-info">
                                <div className="goods-title">
                                    <i className="title-sign"></i>
                                    订单状态
                                </div>
                                <div style={{ "display": this.state.order_id && this.state.order.order_status === 0 ? true : 'none' }} className="order-remind" >
                                    如买家在规定时间内未付款，订单将自动关闭
                                </div>
                                <p>{this.state.order.order_status_desc}{this.state.order.order_status === 1 ? this.state.order.order_type === "自提" ? "配送" : "发货" : null}</p>
                                {this.state.order.order_type === '周期购' ? (
                                    <p>{this.state.period_purchase_res.shipping_info}</p>
                                ) : null}
                                <p>{this.state.order.reason}</p>
                                <Button style={{ "display": this.state.order_id && this.state.order.order_status === 0 ? true : 'none' }} type="primary" shape="round" onClick={this.cancelOnPaidOrder}>关闭订单</Button>
                                <div style={{ "display": this.state.order.remark ? true : 'none' }} >
                                    <div className="goods-title">
                                        <i className="title-sign"></i>
                                     买家留言
                                 </div>
                                    <p>{this.state.order.remark}</p>
                                </div>
                                <div style={{ "display": this.state.order.order_status === 2 ? true : 'none' }}>
                                    <div className="goods-title" style={{ "display": this.state.order.order_status === 3 ? true : 'none' }}>
                                        <i className="title-sign"></i>
                                     物流信息
                                 </div>
                                    <p style={{ "display": this.state.logistics ? true : 'none' }}  >快递公司：{this.state.logistics}</p>
                                    <p style={{ "display": this.state.logistics ? true : 'none' }} >快递单号：{this.state.shipment_sn}</p>
                                </div>
                                <div style={{ "display": this.state.cj_order_id && (type === "lucky" || type === 'rank_lucky') ? true : 'none' }}>
                                    <div className="goods-title">
                                        <i className="title-sign"></i>
                                     订单描述
                                 </div>
                                    {
                                        this.state.order.refund_status === 0 ? (
                                            this.state.order.is_lucky ? "中奖" : "未开奖"
                                        ) : (
                                                <div>
                                                    <p>退款：{this.state.order.refund_status_desc}</p>
                                                    <p >中奖：{this.state.order.is_lucky_desc}</p>
                                                </div>

                                            )
                                    }
                                </div>
                                {type === 'rank_lucky' && this.state.order.rank ? (
                                    <div>
                                        <div className="goods-title">
                                            <i className="title-sign"></i>
                                        拼团排名
                                    </div>
                                        <p>第{this.state.order.rank}名</p>
                                    </div>
                                ) : null}
                                <div style={{ "display": this.state.order_id && this.state.order.order_type === "lucky" ? true : 'none' }}>
                                    <div className="goods-title">
                                        <i className="title-sign"></i>
                                     订单描述
                                 </div>
                                    <p>中奖</p>
                                </div>
                                <div style={{ "display": this.state.cj_order_id && this.state.order.cj_order_type === "free_order" ? true : 'none' }}>
                                    <div className="goods-title">
                                        <i className="title-sign"></i>
                                     现金/红包
                                 </div>
                                    <div>返现金{this.state.order.free_order_back_cash_amount / 100}元</div>
                                    <div>发红包{this.state.order.free_order_back_redpack_amount / 100}元</div>
                                </div>
                                <div style={{ "display": this.state.refund_order_id ? true : 'none' }}>
                                    <div className="goods-title">
                                        <i className="title-sign"></i>
                                     订单描述
                                 </div>
                                    <p style={{ "display": this.state.order.refund_status != null && this.state.cj_order_id === null ? true : "none" }}>售后状态：{this.state.order.refund_status_desc}</p>
                                </div>
                                <div style={{ "display": this.state.order.refund_status != null && +this.state.order.finish_refund_status === 1 ? true : 'none' }}>
                                    <p>买家已经对商品申请售后，请填写进度</p>
                                    <p className="record-action">
                                        <Button shape="round" onClick={this.showModal.bind(this, 0)}>填写买家诉求</Button>
                                        <i className="iconfont icon-xiangyoushuangjiantou"></i>
                                        <Button shape="round" onClick={this.showModal.bind(this, 1)}>填写协商进度</Button>
                                        <i className="iconfont icon-xiangyoushuangjiantou"></i>
                                        <Button shape="round" onClick={this.showModal.bind(this, 2)}>填写售后结果</Button>
                                    </p>
                                    <Button shape="round" type="primary" onClick={this.showRefundModal}>退款</Button>
                                    <Button className="btn-refuse" shape="round" type="primary" onClick={this.handleRefundFalse}>拒绝售后</Button>
                                </div>
                            </div>
                        </div>
                        <div className="refund_order_list" style={{ display: this.state.refund_order_id ? true : "none" }}>
                            <div className="goods-title">
                                <i className="title-sign"></i>
                             售后记录
                         </div>
                            <Collapse>
                                {
                                    this.state.refund_record_list.map((item, index) =>
                                        (
                                            <Collapse.Panel key={index} header={item.title}>
                                                {item.content}
                                            </Collapse.Panel>
                                        )
                                    )
                                }
                            </Collapse>
                        </div>
                        <div className="order-goods">
                            <div className="goods-title">
                                <i className="title-sign"></i>
                             商品信息
                            </div>
                            <Table
                                rowKey={(record, index) => index}
                                columns={this.state.goodsList.length > 0 && Number(this.state.goodsList[0].points_amount) > 0 ? this.state.columns : this.state.normal_columns}
                                dataSource={this.state.goodsList}
                                pagination={false}
                                bordered={true}
                            />
                            <footer>
                                <span>商品总价：￥{this.state.original_price}</span>
                                <span>运费：￥{this.state.shipment_fee ? this.state.shipment_fee : 0}</span>
                                <span>商品优惠：￥{(this.state.goods_discount_amount + this.state.activity_coupon_amount) / 100}</span>
                                <span>手动优惠：￥{this.state.manual_discount_amount ? this.state.discount_amount : 0}
                                    {
                                        +this.state.order.order_status === 0 ? (
                                            <Popconfirm
                                                title={
                                                    <div className="goods-price-change">
                                                        <Input
                                                            value={this.state.change_discount_amount}
                                                            style={{ "width": "84px", "borderRadius": "100px" }}
                                                            onChange={this.changeDiscountAmount} />
                                                    </div>
                                                }
                                                onConfirm={this.confirmChange}
                                                onCancel={this.cancelChange}
                                                okText="确认"
                                                cancelText="取消"
                                                placement="bottom"
                                                icon={null}>
                                                <i className="iconfont icon-bianji" ></i>

                                            </Popconfirm>
                                        ) : null
                                    }
                                </span>
                                <span>订单金额：￥{this.state.pay_amount}{this.state.order.refund_status === 0 ? null : '（备注：有退款）'}</span>
                            </footer>
                            {this.state.invoice_info ? (
                                <p style={{ marginTop: '12px' }}>备注：有申请开发票记录</p>
                            ) : null}
                        </div>
                    </div>
                </Spin>
                <Modal
                    className="add-record-modal"
                    title={"填写" + this.state.content_type.value}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    destroyOnClose={true}
                    onCancel={this.handleCancel}
                    okButtonProps={{ shape: 'round' }}
                    cancelButtonProps={{ shape: 'round' }}
                >
                    <Form layout="inline">
                        <Form.Item label={this.state.content_type.value}>
                            <Input.TextArea
                                placeholder="请输入"
                                rows={4}
                                vlaue={this.state.content}
                                onChange={this.changeContent
                                }
                            />
                        </Form.Item>
                    </Form>
                </Modal>
                <RefundOrderMoneyModal
                    visible={this.state.refund_visible}
                    handleOk={this.handleRefundOk}
                    handleCancel={this.handleRefundCancel}
                    order_goods_list={this.state.goodsList}
                    pay_amount={this.state.pay_amount}
                    discount_amount={this.state.discount_amount}
                    shipment_fee={this.state.shipment_fee}
                    original_price={this.state.original_price}
                />
                <Modal
                    visible={this.state.periodVisible}
                    title="发货记录"
                    footer={null}
                    onCancel={() => this.setState({ periodVisible: false })}
                >
                    <Table
                        rowKey={(record, index) => index}
                        columns={this.state.periodColumns}
                        dataSource={this.state.releaseRecords}
                    />
                </Modal>

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
        order_status_list: state.orderStatusList,
        refund_status_list: state.refundStatusList,
        cj_order_status_list: state.cjOrderStatusList
    }
}
export default connect(mapStateToProps)(OrderDetail);
