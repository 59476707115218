import React from "react";
import "@css/codeManage.scss";
import {Table, Pagination} from "antd";
import {getMiniAppAuditList} from "@service/getData";
import {getQueryString} from "@common/common";
import {connect} from "react-redux";
import NavBar from '@/view/component/common/NavBar.js'

const columns = [
    {
        title: "提交审核时间",
        dataIndex: "submit_at",
        width: 282
    },
    {
        title: "当前模版Id",
        dataIndex: "template_id",
        width: 341
    },
    {
        title: "最后发布时间",
        dataIndex: "updated_at",
        width: 265
    },
    {
        title: "状态",
        dataIndex: "status",
        width: 248,
        render: (text, record) => (
            <div>
        <span style={{display: record.status === 0 ? true : "none"}}>
          上传代码成功
        </span>
                <span style={{display: record.status === 1 ? true : "none"}}>
          提交审核中
        </span>
                <span style={{display: record.status === 2 ? true : "none"}}>
          取消审核
        </span>
                <span style={{display: record.status === 3 ? true : "none"}}>
          审核失败
        </span>
                <span style={{display: record.status === 4 ? true : "none"}}>
          审核成功
        </span>
                <span style={{display: record.status === 5 ? true : "none"}}>
          发布失败
        </span>
                <span style={{display: record.status === 6 ? true : "none"}}>
          发布成功
        </span>
                <span style={{display: record.status === 7 ? true : "none"}}>
          发布退回
        </span>
            </div>
        )
    }
];

class CodeDetail extends React.Component {
    constructor(props) {
        super();
        this.state = {
            selectedRowKeys: [],
            data: [],
            template_list: [],
            publish_start_date: null,
            publish_end_date: null,
            page: 1,
            page_size: 10,
            total: 0,
            selectedRows: [],
            visible: false,
            mini_app_id: null
        };
    }

    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        },()=>{
            this.getMiniAppAuditList();
        });
    };
    onShowPageChange = page => {
        this.setState({
            page: page
        },()=>{
            this.getMiniAppAuditList();
        });

    };

    async getMiniAppAuditList(mini_app_id) {
        let data = {
            mini_app_id: this.state.mini_app_id,
            page_no: this.state.page,
            page_size: this.state.page_size
        };
        let res = await getMiniAppAuditList(data);
        if (res.success) {
            this.setState({
                data: res.result.mini_app_audit_list,
                total: res.result.total
            });
        }
    }

    componentDidMount() {
        let mini_app_id = getQueryString("mini_app_id");
        this.setState({
            mini_app_id: mini_app_id
        }, () => {
            if (mini_app_id) {
                this.getMiniAppAuditList(mini_app_id);
            }
        })

    }

    render() {
        let {menu_code} = this.props
        return (
            <div className="codeManage">
                <NavBar icon="icon-daima"  father={menu_code.code_manage}child={menu_code.code_history}/>
                <div className="dataContent">
                    <Table
                        rowKey={record => record.mini_app_audit_id}
                        columns={columns}
                        dataSource={this.state.data}
                        pagination={false}
                    />
                    <Pagination
                        className="pagenation"
                        onChange={this.onShowPageChange}
                        onShowSizeChange={this.onShowSizeChange}
                        defaultCurrent={this.state.page}
                        total={this.state.total}
                        showTotal={total => `共 ${total} 条`}
                        pageSizeOptions={["10", "30", "50", "100"]}
                        showSizeChanger
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode
    }
}
export default connect(mapStateToProps)(CodeDetail);
