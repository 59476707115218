import React from "react";
import { connect } from "react-redux";
import "@css/afterSaleManage.scss";
import NavBar from "@/view/component/common/NavBar.js";
import AfterSaleHandle from "@/view/component/afterSale/AfterSaleHandle";

class AfterSaleManage extends React.Component {
  render() {
    let { menu_code } = this.props;
    return (
      <div className="after-sale-manage">
        <NavBar icon="icon-fahuo" child={menu_code.after_sale_service} />
        <div className="content">
          <AfterSaleHandle></AfterSaleHandle>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};

export default connect(mapStateToProps)(AfterSaleManage);
