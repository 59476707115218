import React from "react";
import NavBar from "@/view/component/common/NavBar.js";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { Form, Table, Tabs, Button, message, Modal } from "antd";
import { querySceneActivityList, changeSceneActivityStatus } from "@service/getData";
import "@css/activity.scss";
import "../groupActivitys/groupActivitys.scss";
import "@css/sceneMarketing.scss";
const { TabPane } = Tabs;
class SceneMarketing extends React.Component {
  constructor() {
    SceneMarketing.contextTypes = {
      router: PropTypes.object.isRequired
    };
    super();
    this.state = {
      colums: [
        {
          title: "场景类型",
          dataIndex: "title"
        },
        {
          title: "权益内容",
          dataIndex: "right"
        },
        {
          title: "状态",
          dataIndex: "status",
          render: text => {
            return text === 1 ? <span className="success">启用</span> : <span className="red">停用</span>;
          }
        },
        {
          title: "操作",
          dataIndex: "action",
          render: (text, record, index) => (
            <>
              <Button type="link" onClick={this.groupClick.bind(this, record)}>
                查看
              </Button>
              <Button type="link" onClick={this.changeStatus.bind(this, record)}>
                {record.status === 1 ? "停止" : "启用"}
              </Button>
            </>
          )
        }
      ],
      groups: [
        {
          title: "生日营销",
          desc: "会员在生日当月享受特别优惠",
          type: "birth",
          backgroundImage: "http://udh.oss-cn-hangzhou.aliyuncs.com/459bab2f-40a1-4ed2-b0aa-3a3cf624de2616073271933412x.png"
        },
        {
          title: "会员日营销",
          desc: "会员固定在每月几号享受优惠",
          type: "vip_day",
          backgroundImage: "http://udh.oss-cn-hangzhou.aliyuncs.com/ae15c55c-fbef-4673-88d0-bbd6d75c217f16073271972892x.png"
        }
      ]
    };
  }
  componentDidMount() {
    this.querySceneActivityList();
  }
  async querySceneActivityList() {
    let res = await querySceneActivityList({
      mini_app_id: cookie.load("mini_app_id")
    });
    let groups = this.state.groups,
      birth = res.result.birth,
      vip_day = res.result.vip_day;
    groups.forEach(item => {
      if (birth && item.type === "birth") {
        item.activity_id = birth.activity_id;
        item.right = birth.right;
        item.status = birth.status;
      } else if (vip_day && item.type === "vip_day") {
        item.activity_id = vip_day.activity_id;
        item.right = vip_day.right;
        item.status = vip_day.status;
      }
    });
    this.setState({
      groups
    });
  }
  groupClick(item) {
    const open = this.context.router.history.push;
    let url = "/sceneMarketingDetail?activity_type=" + item.type;
    if (item.activity_id) {
      url += `&activity_id=${item.activity_id}`;
    }
    open(url);
  }
  async changeStatus(item) {
    Modal.confirm({
      title: item.status === 1 ? "确定停止?" : "确定开启?",
      onOk: async () => {
        let data = {
          mini_app_id: cookie.load("mini_app_id"),
          activity_id: item.activity_id,
          status: item.status === 1 ? 2 : 1
        };
        let res = await changeSceneActivityStatus(data);
        if (res.success) {
          message.success("操作成功");
          this.querySceneActivityList();
        }
      }
    });
  }
  render() {
    let { menu_code } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="page scene-marketing groupActivitys">
        <NavBar icon="icon-yingxiao" father={menu_code.market_setting} child={menu_code.scene_marketing} router="marketSetting" />
        <div className="content market">
          <div className="filter-content">
            <div className="icon-content activity-lucky">
              <i className="iconfont icon-changjingyingxiao" />
            </div>
            <div className="text-content">
              <div className="title">场景营销</div>
              <div>针对不同等级会员，在会员日、生日、节日时发放福利，提升客户忠诚度</div>
            </div>
          </div>
          <div className="groups">
            {this.state.groups.map((item, index) => (
              <div
                className="groupItem"
                key={index}
                style={{
                  backgroundImage: `url(${item.backgroundImage})`
                }}
                onClick={this.groupClick.bind(this, item)}>
                <p className="title">{item.title}</p>
                <p className="desc">{item.desc}</p>
                <div className="btn">{item.activity_id ? "编辑活动" : "新建活动"}</div>
              </div>
            ))}
          </div>
          <div className="tabsWrap">
            <Tabs defaultActiveKey="0">
              <TabPane tab="全部商品" key="0" />
            </Tabs>
          </div>
          <div className="tableWrap">
            <Table
              rowKey="type"
              columns={this.state.colums}
              dataSource={this.state.groups.filter(item => item.activity_id)}
              bordered
              pagination={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};
const SceneMarketingForm = Form.create({ name: "coupon_form" })(SceneMarketing);
export default connect(mapStateToProps)(SceneMarketingForm);
