import React from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import "@css/leaderInterview.scss";
class LeaderInterview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shop_id: cookie.load("shop_id")
    };
  }
  render() {
    return (
      <div className="leader-interview">
        <div className="content">
          <div className="leader-title">
            <p className="title">团长招募海报</p>
            <p className="sub-title">
                快将团长招募海报发布给你的粉丝，马上成为团长帮你分销赚钱吧！可发布在公众号、粉丝群、其他店铺页面等。
                <br/>
                海报分享：微信扫描海报二维码→保存海报图片→转发海报页面分享到群。
            </p>
          </div>
          <div className="post-content">
            <div className="post-item">
              <img 
                src={
                  "https://testdxzapi.lepeen.com/xiaodian_admin/qr_code/queryQrCode?shop_id=" +
                  this.state.shop_id +
                  "&page=pages/post/index"
                }
                alt="二维码"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};
export default connect(mapStateToProps)(LeaderInterview);
