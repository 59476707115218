import React from 'react'
import NavBar from '@/view/component/common/NavBar.js'
import '@css/orderShipmentHistory.scss'
import {Tabs} from 'antd'
import {connect} from "react-redux";
import OrderHistory from '@/view/component/Order/OrderHistory'
import BatchShipment from './BatchShipment'
import cookie from "react-cookies";
class OrderShipmentHistory extends  React.Component {
    constructor(props){
        super(props)
        this.state={

        }
    }
    render(){
        let { menu_code } = this.props
        return(
            <div className="order-shipment-history">
                <NavBar icon="icon-fahuo" child={menu_code.order_shipment_history}/>
                <div className="content">
                    {cookie.load("mini_app_type")==="flagship_version"?(
                        <Tabs
                            defaultActiveKey="1"
                            tabBarStyle={{backgroundColor:"#ffffff",borderRadius:"4px"}}
                        >
                            <Tabs.TabPane tab="邮寄订单发货记录" key="1">
                                <OrderHistory order_shipment_type="self_shipment"/>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="邮寄订单发货批次记录" key="3">
                                <BatchShipment />
                            </Tabs.TabPane>
                        </Tabs>
                    ):(
                        <Tabs
                            defaultActiveKey="1"
                            tabBarStyle={{backgroundColor:"#ffffff",borderRadius:"4px"}}
                        >
                            <Tabs.TabPane tab="邮寄订单发货记录" key="1">
                                <OrderHistory order_shipment_type="self_shipment"/>
                            </Tabs.TabPane>
                            <Tabs.TabPane  style={{display:cookie.load("mini_app_type")==="flagship_version"?"none":true}}  tab="自提订单发货记录" key="2">
                                <OrderHistory order_shipment_type="group_shipment"/>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="邮寄订单发货批次记录" key="3">
                                <BatchShipment />
                            </Tabs.TabPane>
                        </Tabs>
                    )}
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        menu_code:state.menuCode
    }
}

export default connect(mapStateToProps) (OrderShipmentHistory);