import React from 'react'
import { Form, DatePicker, Input, Select, Button, Table, Pagination, Tag, Avatar, message, Spin } from 'antd'
import { connect } from "react-redux";
import NavBar from '@/view/component/common/NavBar.js'
import '@css/clientManage.scss'
import cookie from 'react-cookies'
import { formatNumber, formatInt } from '@common/common'
import { queryUserUserList, queryUserLabelList, getData } from '@service/getData'
import PropTypes from 'prop-types'
class ClientManage extends React.Component {
    constructor() {
        super()
        ClientManage.contextTypes = {
            router: PropTypes.object.isRequired
        }
        this.state = {
            columns: [
                {
                    title: '客户信息',
                    dataIndex: 'goods_name',
                    render: (text, record) => (
                        <div className='user-info'>
                            <div><Avatar shape="square" src={record.avatar} /></div>
                            <div className="user-info-detail">
                                <div className="text-note" onClick={this.goToClient.bind(this, record.user_id)}>{record.nick_name}</div>
                                <div>{record.mobile}</div>
                            </div>

                        </div>
                    )
                },
                {
                    title: '渠道来源',
                    dataIndex: 'channel_name',
                }, {
                    title: '标签',
                    dataIndex: 'user_label_res_list',
                    render: (text) => (
                        <div>
                            {
                                text.map(item => item.user_label_name === '购买客户' ?
                                    (<div key={item.user_label_id} className="tag-info"><Tag color="green">购买客户</Tag></div>) : item.user_label_name === '直播客户' ? <div className="tag-info" key={item.user_label_id}><Tag color="red">直播客户</Tag></div> : null)
                            }
                        </div>
                    )
                }
                , {
                    title: '积分',
                    dataIndex: 'total_points',
                    sorter: true,
                }, {
                    title: '笔数',
                    dataIndex: 'total_order_num',
                    sorter: true,
                }, {
                    title: '金额',
                    dataIndex: 'total_order_amount',
                    sorter: true,
                    render: (text) => {
                        return text / 100
                    }
                }, {
                    title: '上次消费时间',
                    dataIndex: 'lasted_pay_time',
                }
            ],
            page_no: 1,
            page_size: 10,
            total: 0,
            user_res_list: [],
            points_amount_big: null,
            points_amount_small: null,
            pay_num_big: null,
            pay_num_small: null,
            pay_cash_big: null,
            pay_cash_small: null,
            user_label_res_list: [],
            channel_res_list: [],
            order_by_param: null,
            loading: false
        }
    }
    queryUserUserList = async () => {
        this.setState({
            loading: true
        })
        let form = this.props.form.getFieldsValue();
        let pay_cash_big = this.state.pay_cash_big,
            pay_cash_small = this.state.pay_cash_small,
            pay_num_small = this.state.pay_num_small,
            pay_num_big = this.state.pay_num_big,
            points_amount_big = this.state.points_amount_big,
            points_amount_small = this.state.points_amount_small;
        if (!!this.state.pay_cash_small && !!this.state.pay_cash_big && Number(pay_cash_big) < Number(pay_cash_small)) {
            pay_cash_big = this.state.pay_cash_small;
            pay_cash_small = this.state.pay_cash_big
        }
        if (!!this.state.pay_num_big && !!this.state.pay_num_small && Number(pay_num_big) < Number(pay_num_small)) {
            pay_num_big = this.state.pay_num_small;
            pay_num_small = this.state.pay_num_big
        }
        if (!!this.state.points_amount_big && !!this.state.points_amount_small && Number(points_amount_big) < Number(points_amount_small)) {
            points_amount_big = this.state.points_amount_small;
            points_amount_small = this.state.points_amount_big
        }

        let data = {
            mini_app_id: cookie.load('mini_app_id'),
            page_no: this.state.page_no,
            page_size: this.state.page_size,
            points_amount_big,
            points_amount_small,
            pay_num_big,
            pay_num_small,
            pay_cash_big: !!pay_cash_big ? Math.round(pay_cash_big * 100) : null,
            pay_cash_small: !!pay_cash_small ? Math.round(pay_cash_small * 100) : null,
            user_label_id: form.user_label_id,
            order_by_param: this.state.order_by_param,
            channel_id: form.channel_id,
            like_name_and_mobile: form.like_name_and_mobile,
            pay_time_small: Array.isArray(form.date) && form.date[0] ? form.date[0].format('YYYY-MM-DD HH:mm:ss') : null,
            pay_time_big: Array.isArray(form.date) && form.date[1] ? form.date[1].format('YYYY-MM-DD HH:mm:ss') : null,


        }
        let res = await queryUserUserList(data);
        if (res.success) {
            let { total, user_res_list } = res.result;
            this.setState({
                total,
                user_res_list,
                points_amount_big,
                points_amount_small,
                pay_num_big,
                pay_num_small,
                pay_cash_big,
                pay_cash_small,
                loading: false
            })
        }
    }
    queryChannelList = async () => {
        const url = '/channel/queryChannelSelection';
        let data = {
            mini_app_id: cookie.load("mini_app_id")
        }
        let res = await getData(url, data);
        if (res.success) {
            let channel_res_list = res.result.channel_res_list;
            this.setState({
                channel_res_list
            })
        }
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.queryUserUserList()
        })
    }
    onShowPageChange = (page) => {
        this.setState({
            page_no: page
        }, () => {
            this.queryUserUserList()
        })

    }
    onChangeValue = (type, e) => {
        let value = e.target.value;
        if (type === 'pay_cash_small' || type === 'pay_cash_big') {
            value = formatNumber(value)
        } else {
            value = formatInt(value)
        }
        this.setState({
            [type]: value
        })
    }
    queryUserLabelList = async () => {
        let data = {
            mini_app_id: cookie.load('mini_app_id')
        }
        let res = await queryUserLabelList(data);
        if (res.success) {
            let { user_label_res_list } = res.result;
            this.setState({
                user_label_res_list
            })
        } else {
            message.error(res.msg)
        }
    }
    clearFilter = () => {
        this.props.form.resetFields();
        this.setState({
            points_amount_big: null,
            points_amount_small: null,
            pay_num_big: null,
            pay_num_small: null,
            pay_cash_big: null,
            pay_cash_small: null
        })

    };
    goToClient = (user_id) => {
        this.context.router.history.push("/client", user_id)
    }
    handleTableChange = (pagination, filters, sorter) => {
        let order_by_param = null
        switch (sorter.field) {
            case 'total_points':
                if (sorter.order === "ascend") {
                    order_by_param = "points_asc"
                } else {
                    order_by_param = "points_desc"
                }
                break;
            case 'total_order_num':
                if (sorter.order === "ascend") {
                    order_by_param = "order_num_asc"
                } else {
                    order_by_param = "order_num_desc"
                }
                break;
            case 'total_order_amount':
                if (sorter.order === "ascend") {
                    order_by_param = "order_cash_asc"
                } else {
                    order_by_param = "order_cash_desc"
                }
                break;
            default:
                order_by_param = null
        }

        this.setState({
            order_by_param
        }, () => {
            this.queryUserUserList()
        })
    }
    componentDidMount() {
        this.queryUserUserList()
        this.queryUserLabelList()
        this.queryChannelList()
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        let { menu_code } = this.props
        return (
            <div className="client-manage">
                <Spin spinning={this.state.loading}>
                    <NavBar icon="icon-kehu1" child={menu_code.client_manage} />
                    <div className="content">
                        <div className='filter-content'>
                            <Form className="ant-vertical" layout="inline">
                                <Form.Item label="上次消费时间">
                                    {getFieldDecorator('date')(
                                        <DatePicker.RangePicker className="form-input-long" showTime />
                                    )}
                                </Form.Item>
                                <Form.Item label="关键词">
                                    {getFieldDecorator('like_name_and_mobile')(
                                        <Input className="form-input-long" placeholder="手机号/微信昵称/unionId" />
                                    )}
                                </Form.Item>
                                <Form.Item label="积分">
                                    {getFieldDecorator('app_id')(
                                        <div>
                                            <Input className="form-input" value={this.state.points_amount_small} onChange={this.onChangeValue.bind(this, 'points_amount_small')} suffix="分" />
                                            <span className="form-divider">至</span>
                                            <Input className="form-input" value={this.state.points_amount_big} onChange={this.onChangeValue.bind(this, 'points_amount_big')} suffix="分" />
                                        </div>
                                    )}
                                </Form.Item>
                                <Form.Item label="标签">
                                    {getFieldDecorator('user_label_id')(
                                        <Select className="form-input">
                                            {
                                                this.state.user_label_res_list.map(item => (
                                                    <Select.Option key={item.user_label_id} value={item.user_label_id}>{item.user_label_name}</Select.Option>
                                                ))

                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="来源渠道">
                                    {getFieldDecorator('channel_id')(
                                        <Select className="form-input">
                                            {
                                                this.state.channel_res_list.map(item => (
                                                    <Select.Option key={item.channel_id} value={item.channel_id}>{item.channel_name}</Select.Option>
                                                ))

                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item label="购买次数">
                                    {getFieldDecorator('app_id')(
                                        <div>
                                            <Input className="form-input"

                                                value={this.state.pay_num_small} onChange={this.onChangeValue.bind(this, 'pay_num_small')}
                                                suffix="次" />
                                            <span className="form-divider">至</span>
                                            <Input className="form-input"
                                                value={this.state.pay_num_big} onChange={this.onChangeValue.bind(this, 'pay_num_big')}
                                                suffix="次" />
                                        </div>
                                    )}
                                </Form.Item>
                                <Form.Item label="累计消费金额">
                                    {getFieldDecorator('app_id')(
                                        <div>
                                            <Input className="form-input"
                                                value={this.state.pay_cash_small} onChange={this.onChangeValue.bind(this, 'pay_cash_small')}
                                                suffix="元" />
                                            <span className="form-divider">至</span>
                                            <Input className="form-input"
                                                value={this.state.pay_cash_big} onChange={this.onChangeValue.bind(this, 'pay_cash_big')}
                                                suffix="元" />
                                        </div>
                                    )}
                                </Form.Item>
                            </Form>
                            <div className="action">
                                <Button type="primary" shape="round" onClick={this.queryUserUserList}>筛选</Button>
                                <span className="text-note" onClick={this.clearFilter}>清空筛选条件</span>
                            </div>
                        </div>
                        <Table
                            rowKey={(record, index) => record.user_id}
                            columns={this.state.columns}
                            pagination={false}
                            dataSource={this.state.user_res_list}
                            bordered={true}
                            onChange={this.handleTableChange}

                        />
                        <Pagination
                            className="pagenation"
                            onChange={this.onShowPageChange}
                            onShowSizeChange={this.onShowSizeChange}
                            defaultCurrent={this.state.page_no}
                            total={this.state.total}
                            showTotal={total => `共 ${total} 条`}
                            pageSizeOptions={["10", "30", "50", "100"]}
                            showSizeChanger
                        />
                    </div>
                </Spin>
            </div>
        )
    }
}

const ClientManageForm = Form.create({ name: 'client_manage_form' })(ClientManage);

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode
    }
}
export default connect(mapStateToProps)(ClientManageForm);
