import React from "react";
import { Menu, Modal } from "antd";
import "@css/sideBar.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cookie from "react-cookies";

class SideBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			// theme: "dark",
			// current: "1",
			// rootSubmenuKeys: ["dashboard", "shop", "market", "goods", "order", "fahuo", "client", "leader", "shezhi", "guanliyuan", "promote"],
			// openKeys: ["0"],
			// selectedKeys: []
			key: 'dashboard',
		};
		SideBar.contextTypes = {
			router: PropTypes.object.isRequired
		};
	}
	selected = item => {
		cookie.load("key", item.key);
		let location = this.context.router.route.location.pathname;
		if (cookie.load("defaultSelectedKeys") !== "0" && item.item.props.router !== '/dashboard') {
			return;
		}
		if (+cookie.load("status") === 1 && location === "/shopManage") {
			return Modal.confirm({
				title: "",
				content: "确定要离开页面么，系统不会自动保存您设置的颜色信息",
				onOk: () => {
					this.context.router.history.push(item.item.props.router);
					// this.setState({
					// 	children: item.item.props.child ? item.item.props.child : null
					// });
					cookie.save("status", 0);
				},
				onCancel: () => {
					return;
				}
			});
		}
		this.context.router.history.push(item.item.props.router);
		this.setState({
			key: item.key,
		});
		// this.setState({
		// 	children: item.item.props.child ? item.item.props.child : null
		// });
		// 体验优化，每次选择菜单，滚动到顶部，防止内容被遮挡
		window.scrollTo(0, 0);
	};
	// componentDidMount() {
	// 	this.changeMenuSelect();
	// 	this.context.router.history.listen(route => {
	// 		this.changeMenuSelect();
	// 	});
	// }
	// changeMenuSelect = () => {
	// 	let location,
	// 		side_bar = this.props.side_bar,
	// 		open_index;
	// 	for (let i = 0; i < side_bar.length; i++) {
	// 		let item = side_bar[i];
	// 		if (window.location.pathname === item.router) {
	// 			open_index = item.value;
	// 			location = item.value;
	// 			break;
	// 		} else if (item.children) {
	// 			for (let j = 0; j < item.children.length; j++) {
	// 				let elem = item.children[j];
	// 				if (window.location.pathname === elem.router) {
	// 					open_index = item.value;
	// 					location = elem.value;
	// 					break;
	// 				}
	// 			}
	// 		}
	// 	}
	// 	if (this.context.router.history.length === 1 && location === "goodsGroupDetail") {
	// 		location = "goodsManage";
	// 		open_index = "goods";
	// 	}
	// 	this.setState({
	// 		openKeys: [open_index],
	// 		selectedKeys: [location]
	// 	});
	// };
	// onOpenChange = openKeys => {
	// 	if (cookie.load("defaultSelectedKeys") !== "0") {
	// 		return;
	// 	}
	// 	const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
	// 	if (this.state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
	// 		this.setState({ openKeys });
	// 	} else {
	// 		this.setState({
	// 			openKeys: latestOpenKey ? [latestOpenKey] : []
	// 		});
	// 	}
	// };
	// onSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
	// 	if (cookie.load("defaultSelectedKeys") !== "0") {
	// 		return;
	// 	}
	// 	this.setState({
	// 		selectedKeys
	// 	});
	// };

	subClassName(item) {
		if (item.children.find((zitem) => zitem.value === this.state.key)) {
			return item.selectIcon;
		}
		return item.icon;
	}
	itemClassName(item) {
		if (item.value === this.state.key) {
			return item.selectIcon;
		}
		return item.icon;
	}

	render() {
		return (
			<div className="sideBar">
				<Menu
					theme="dark"
					style={{ width: 200 }}
					mode="inline"
					// onOpenChange={this.onOpenChange}
					// onSelect={this.onSelect}
					onClick={this.selected}
					// selectedKeys={this.state.selectedKeys}
					// openKeys={this.state.openKeys}
					defaultOpenKeys={['goods', 'order']}
					defaultSelectedKeys={['home']}
				>
					{this.props.side_bar.map(item =>
						Number(cookie.load("is_admin")) === 0 && Number(item.is_admin) === 1 ? null : item.children ? (
							<Menu.SubMenu
								key={item.value}
								title={
									<div>
										<i className={"iconfont " + this.subClassName.bind(this, item)()} />
										{item.name}
									</div>
								}>
								{item.children.map(qitem => (
									<Menu.Item
										style={{
											display: qitem.mini_app_type && cookie.load("mini_app_type") !== qitem.mini_app_type ? "none" : true
										}}
										key={qitem.value}
										router={qitem.router}
										value={qitem.name}>
										{qitem.name}
									</Menu.Item>
								))}
							</Menu.SubMenu>
						) : (
							<Menu.Item className="AMenu" target='yiji' key={item.value} router={item.router} value={item.name}>
								<i className={"iconfont " + this.itemClassName.bind(this, item)()} />
								{item.name}
							</Menu.Item>
						)
					)}
				</Menu>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		side_bar: state.sideBar
	};
};

export default connect(mapStateToProps)(SideBar);
