import React from "react";
import { Icon } from "antd";
import "@css/uploadButton.scss";
class UploadButton extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div className="update-button">
        <Icon type="plus" />
        <div className="ant-upload-text">{this.props.text || "添加图片"}</div>
      </div>
    );
  }
}

export default UploadButton;
