import React from 'react'
import '@css/groupAdd.scss'
import PropTypes from 'prop-types'
import NavBar from '@/view/component/common/NavBar.js'
import {connect} from "react-redux";
import { Form, Collapse, Icon, Checkbox, Input, Button, Table, Radio, Modal, message,DatePicker} from "antd/lib/index";
import cookie from "react-cookies";
import {queryUnopenedGoodsList, queryCommunityUserList, createGroupOpenRecord} from '@service/getData'
import {formatNumber} from '@common/common'
import GoodsRemind from '@/view/component/common/GoodsRemind'

class GroupAdd extends React.Component {
    constructor() {
        super()
        this.state = {
            selectedLeaderRowKeys: [],
            selectedLeaderRows: [],
            selectedGoodsRowKeys: [],
            selectedGoodsRows: [],
            can_group_shipment: false,
            group_shipment_fee: null,
            can_self_shipment: false,
            self_shipment_fee: null,
            chooseLeaderColumns: [
                {
                    title: '社群名称',
                    dataIndex: 'community_name'
                },
                {
                    title: '团长姓名',
                    dataIndex: 'nick_name'
                },
                {
                    title: '电话',
                    dataIndex: 'mobile'
                }
            ],
            chooseLeaderData: [],
            leaderVisble: false,
            goodsVisble: false,
            leaderColumns: [
                {
                    title: '社群名称',
                    dataIndex: 'community_name'
                },
                {
                    title: '团长姓名',
                    dataIndex: 'nick_name'
                },
                {
                    title: '电话',
                    dataIndex: 'mobile'
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    render: (text, record, index) => (
                        <div>
                            <span  onClick={this.handelLeaderDel.bind(this,index)} style={{"marginRight": "5px"}} className="text-note">删除</span>
                        </div>
                    )
                }
            ],
            leaderData: [],
            chooseGoodsColumns: [
                {
                    title: '开团商品',
                    width: 300,
                    dataIndex: 'goods_name',
                    render: (text, record) => (
                        <div className="goods-info">
                            <div className="goods-info-img">
                                <img width="60" alt="商品图片" height="60" src={record.thumbnail_url}/>
                            </div>
                            <div className="goods-info-detail">
                                <div className="goods-info-name" title={record.goods_name}>
                                    {record.goods_name}
                                </div>
                                <div>
                                    ￥{record.current_price / 100}
                                </div>
                            </div>
                        </div>
                    ),
                },
                {
                    title: '商品ID',
                    dataIndex: 'goods_id',

                },
                {
                    title: '库存',
                    dataIndex: 'balance',

                }
            ],
            chooseGoodsData: [],
            goodsColumns: [
                {
                    title: '开团商品',
                    width: 500,
                    dataIndex: 'goods_name',
                    render: (text, record) => (
                        <div className="goods-info">
                            <div className="goods-info-img">
                                <img width="60"alt="商品图片" height="60" src={record.thumbnail_url}/>
                            </div>
                            <div className="goods-info-detail">
                                <div className="goods-info-name" title={record.goods_name}>
                                    {record.goods_name}
                                </div>
                                <div>
                                    ￥{record.current_price / 100}
                                </div>
                            </div>
                        </div>
                    ),
                },
                {
                    title: '商品ID',
                    width: 200,
                    dataIndex: 'goods_id',

                },
                {
                    title: '库存',
                    dataIndex: 'balance',
                    width: 180,

                }, {
                    title: '操作',
                    dataIndex: 'action',
                    width: 280,
                    render: (text, record, index) => (
                        <div>
                            <span style={{"marginRight": "5px"}} className="text-note"
                                  onClick={this.handelGoodsDel.bind(this, index)}>删除</span>
                        </div>
                    )
                },


            ],
            goodsData: [],
            goods_id: null,
            mobile: null,
            not_group_goods: 0,
            publish_start_date:null,
            publish_end_date:null,
            goodsRemindVisible:false,
            goodsRemindList:[]

        }

        GroupAdd.contextTypes = {
            router: PropTypes.object.isRequired
        }
    }
    onShowTimeChange = (date, dateStr) => {
        this.setState({
            publish_start_date: dateStr[0],
            publish_end_date: dateStr[1],
        })
    }

    onChangeShipmentType = (checkedList) => {
        let can_group_shipment = false, can_self_shipment = false;

        checkedList.forEach(item => {
            if (Number(item) ===2) {
                can_self_shipment = true
            }
            else if (Number(item)=== 1) {
                can_group_shipment = true
            }
        })
        this.setState({
            can_group_shipment: can_group_shipment,
            can_self_shipment: can_self_shipment,
        })

    }
    handleLeader = (e) => {
        this.setState({
            leaderVisble: true,
        });
    }

    handleLeaderOk = (e) => {
        let arr=this.state.leaderData.concat(this.state.selectedLeaderRows);
        let data=Array.from(new Set(arr));
        this.setState(prevState => ({
            leaderVisble: false,
            leaderData:data
        }))
    }

    handleLeaderCancel = (e) => {
        this.setState({
            leaderVisble: false,
        });
    }

    handleGoods = (e) => {
        this.setState({
            goodsVisble: true,
        });
    }

    handleGoodsOk = (e) => {
        let arr=this.state.goodsData.concat(this.state.selectedGoodsRows);
        let data=Array.from(new Set(arr));
        this.setState(prevState => ({
            goodsVisble: false,
            goodsData:data
        }))

    }

    handleGoodsCancel = (e) => {
        this.setState({
            goodsVisble: false,
        });
    }

    async queryUnopenedGoodsList() {
        let data = {
            shop_id: cookie.load("shop_id"),
        }
        if (this.state.goods_id) {
            data.goods_id = this.state.goods_id
        }
        if (this.state.not_group_goods) {
            data.not_group_goods = this.state.not_group_goods
        }

        let res = await queryUnopenedGoodsList(data)
        if (res.success) {
            this.setState({
                chooseGoodsData: res.result.list
            })
        }

    }

    async queryCommunityUserList() {
        let data = {
            shop_id: cookie.load("shop_id")
        }
        if (this.state.mobile) {
            data.mobile = this.state.mobile
        }
        let res = await queryCommunityUserList(data)
        if (res.success) {
            this.setState({
                chooseLeaderData: res.result.list
            })
        }
    }

    async createGroupOpenRecord(data) {

        let res = await createGroupOpenRecord(data);
        if (res.success) {
            this.setState({
                can_group_shipment: false,
                group_shipment_fee: null,
                can_self_shipment: false,
                self_shipment_fee: null,
                leaderData: [],
                goodsData: [],
                goodsRemindVisible:res.result.goods_invalid_notice_list.length?true:false,
                goodsRemindList:res.result.goods_invalid_notice_list.length?res.result.goods_invalid_notice_list:[]
            })
            this.props.form.resetFields();
            if(!res.result.goods_invalid_notice_list.length){
                message.success("开团成功")
            }
        }
    }

    onSelectLeaderChange = (selectedLeaderRowKeys, selectedLeaderRows) => {
        this.setState({
            selectedLeaderRowKeys,
            selectedLeaderRows
        });
    }

    onSelectGoodsChange = (selectedGoodsRowKeys, selectedGoodsRows) => {
        this.setState({
            selectedGoodsRowKeys,
            selectedGoodsRows
        });
    }
    handelGoodsDel = (index) => {
        let goodsData = this.state.goodsData;
        let selectedGoodsRowKeys=this.state.selectedGoodsRowKeys;

        goodsData.splice(index, 1)
        selectedGoodsRowKeys.splice(index, 1)
        this.setState({
            goodsData: goodsData,
            selectedGoodsRowKeys:selectedGoodsRowKeys

        })
    }
    handelLeaderDel = (index) => {
        let leaderData = this.state.leaderData;
        let selectedLeaderRowKeys=this.state.selectedLeaderRowKeys;
        leaderData.splice(index, 1)
        selectedLeaderRowKeys.splice(index,1)
        this.setState({
            leaderData: leaderData,
            selectedLeaderRowKeys:selectedLeaderRowKeys

        })
    }

    componentDidMount() {
        this.queryUnopenedGoodsList()
        this.queryCommunityUserList()
    }

    changeGoodsId = (e) => {
        this.setState({
            goods_id: e.target.value
        }, () => {
            this.queryUnopenedGoodsList()
        })


    }

    changeNotGroupGoods = (e) => {
        this.setState(prevState => ({
            not_group_goods: prevState.not_group_goods === 1 ? 0 : 1
        }), () => {
            this.queryUnopenedGoodsList()
        })

    }
    changeMobile = (e) => {
        this.setState({
            mobile: e.target.value
        }, () => {
            this.queryCommunityUserList()
        })
    }
    clearGoods = () => {
        this.setState({
            goodsData: [],
            selectedGoodsRowKeys:[],
            selectedGoodsRows:[]
        })

    }
    createGroup = () => {
        let form = this.props.form.getFieldsValue();
        let data = {
            shop_id: cookie.load("shop_id")
        }
        if (!this.state.goodsData.length) {
            return message.info("请选择商品")
        }
        data.goods_ids = this.state.goodsData.map(item => item.goods_id);
        if (!form.choose_leader) {
            return message.info("请选择团长")
        }

        data.is_all_leader = form.choose_leader
        if (form.choose_leader === 2) {
            if (!this.state.leaderData.length) {
                return  message.info("请选择团长")
            }
            data.community_ids = this.state.leaderData.map(item => item.community_id)
        }
        if (this.state.can_group_shipment && this.state.can_self_shipment) {
            data.group_type = 3
            data.group_shipment_fee = 0;
            data.self_shipment_fee = Math.round(this.state.self_shipment_fee * 100);
        } else if (this.state.can_group_shipment === 1) {
            data.group_type = 1
            data.group_shipment_fee = 0;
        } else if (this.state.can_self_shipment === 1) {
            data.group_type = 2
            data.self_shipment_fee = Math.round(this.state.self_shipment_fee * 100);
        }

        if(this.state.publish_start_date){
            data.start_time=this.state.publish_start_date;
        }
        if(this.state.publish_end_date){
            data.expire_time=this.state.publish_end_date;
        }
        this.createGroupOpenRecord({group_open_record: data});

    }

    changeGroupShipmentFee = (e) => {
        this.setState({
            group_shipment_fee: e.target.value
        })
    }
    changeSelfShipmentFee = (e) => {
        this.setState({
            self_shipment_fee: formatNumber(e.target.value)
        })
    }

    handleGoodsRemindOk = (e) => {
       this.setState({
           goodsRemindVisible:false

       })

    }
    render() {
        let {menu_code} = this.props;
        const {getFieldDecorator} = this.props.form;
        const {selectedLeaderRowKeys, selectedGoodsRowKeys} = this.state;
        const rowSelection = {
            selectedLeaderRowKeys,
            onChange: this.onSelectLeaderChange,
            hideDefaultSelections: true,
        };

        const goodsRowSelection = {
            selectedGoodsRowKeys,
            onChange: this.onSelectGoodsChange,
            hideDefaultSelections: true,
        };
        return (
            <div className="group-add">
                <NavBar icon="icon-duoren" father={menu_code.group_manage} child={menu_code.group_add}/>
                <div className="content">
                    <div className="goods-title">
                        <i className="title-sign"></i>
                        添加商品团基本信息
                    </div>
                    <Collapse defaultActiveKey={['1', '2', '3','4']}
                              expandIcon={({isActive}) => <Icon className="caret" type="caret-right"
                                                                rotate={isActive ? 90 : 0}/>}>
                        <Collapse.Panel header={
                            <div className="panel-title">
                                选择商品(必填)
                            </div>
                        } key="1">
                            <div className="action">
                                <Button shape="round" type="primary" onClick={this.handleGoods}>添加商品</Button>
                                <span  style={{display:this.state.goodsData.length?true:"none"}}className="text-note" onClick={this.clearGoods}>清空开团商品</span>
                            </div>

                                <Table rowKey={(record, index) => index}
                                       columns={this.state.goodsColumns}
                                       dataSource={this.state.goodsData}
                                       pagination={false}
                                       scroll={{y: 240}}/>
                        </Collapse.Panel>
                        <Collapse.Panel header={
                            <div className="panel-title">
                                团类型/运费设置(必填)
                            </div>
                        } key="2">
                            <Form>
                                <Form.Item>
                                    {getFieldDecorator('shipment_type')(
                                        <Checkbox.Group style={{width: '100%'}} onChange={this.onChangeShipmentType}>
                                            <Checkbox value="2">
                                                邮寄
                                            </Checkbox>
                                            <Checkbox value="1">
                                                自提
                                            </Checkbox>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('shipment_pay')(
                                        <div>
                                            邮寄运费
                                            <Input onChange={this.changeSelfShipmentFee}
                                                   placeholder="输入邮寄运费"
                                                   value={this.state.self_shipment_fee}
                                                   disabled={this.state.can_self_shipment ? false : true}
                                                   style={{marginRight:50}}/>
                                        </div>
                                    )}
                                </Form.Item>
                            </Form>
                        </Collapse.Panel>
                        <Collapse.Panel header={
                            <div className="panel-title">
                                推送至团长(必填)
                            </div>
                        } key="3">
                            <Form layout="inline">
                                <Form.Item label="推送至团长">
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('choose_leader')(
                                        <Radio.Group>
                                            <Radio value={1}>全部团长</Radio>
                                            <Radio value={2}>部分团长</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <Form.Item label="" style={{display:this.props.form.getFieldsValue().choose_leader===2?true:"none"}}>
                                    <span onClick={this.handleLeader} className="text-note">选择团长</span>
                                </Form.Item>
                            </Form>
                            <Table rowKey={(record, index) => index} columns={this.state.leaderColumns}
                                   style={{display:this.props.form.getFieldsValue().choose_leader===2?true:"none"}}
                                   dataSource={this.state.leaderData} pagination={false}/>
                        </Collapse.Panel>
                        <Collapse.Panel header={
                            <div className="panel-title">
                                开团时间(非必填)
                            </div>
                        } key="4">
                            <Form layout="inline">
                                <Form.Item label="开团时间">
                                    {getFieldDecorator('date')(
                                        <DatePicker.RangePicker className="form-input-long"
                                                                onChange={this.onShowTimeChange} showTime/>
                                    )}
                                </Form.Item>
                            </Form>
                        </Collapse.Panel>
                    </Collapse>
                    <div className="group-add-button-box">
                        <Button shape="round" type="primary" onClick={this.createGroup}>
                            开团
                        </Button>
                    </div>
                </div>
                <Modal
                    title="选择团长"
                    visible={this.state.leaderVisble}
                    onOk={this.handleLeaderOk}
                    onCancel={this.handleLeaderCancel}
                    width={736}
                    className="leader-modal"
                    destroyOnClose={true}
                    okButtonProps={{shape:'round'}}
                    cancelButtonProps={{shape:'round'}}
                >
                    <div>
                        <div className="modal-note">备注：若该团长已有该商品团，创建新团将导致之前创建的团失效</div>
                        <Form layout="inline" className="modal-form">
                            <Form.Item label="搜索团长">
                                <Input placeholder="请输入手机号" value={this.state.mobile} onChange={this.changeMobile}/>
                            </Form.Item>
                        </Form>
                        <Table rowKey={(record, index) => record.community_id}
                               rowSelection={rowSelection}
                               columns={this.state.chooseLeaderColumns}
                               dataSource={this.state.chooseLeaderData}
                               pagination={false}
                               scroll={{y: 240}}/>
                    </div>
                </Modal>
                <Modal
                    title="选择商品开团"
                    visible={this.state.goodsVisble}
                    onOk={this.handleGoodsOk}
                    onCancel={this.handleGoodsCancel}
                    className="goods-modal"
                    destroyOnClose={true}
                    okButtonProps={{shape:'round'}}
                    cancelButtonProps={{shape:'round'}}
                >
                    <div>
                        <Form layout="inline" className="modal-form">
                            <Form.Item label="搜索商品">
                            </Form.Item>
                            <Form.Item>
                                <Input placeholder="输入商品ID" value={this.state.goods_id} onChange={this.changeGoodsId}/>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox onChange={this.changeNotGroupGoods}>选择未开团的商品</Checkbox>
                            </Form.Item>
                        </Form>
                        <Table rowKey={(record, index) => record.goods_id}
                               rowSelection={goodsRowSelection}
                               columns={this.state.chooseGoodsColumns}
                               dataSource={this.state.chooseGoodsData}
                               pagination={false}
                               scroll={{y: 240}}/>
                    </div>
                </Modal>
                <GoodsRemind
                    visible={this.state.goodsRemindVisible}
                    data={this.state.goodsRemindList}
                    title={"以下商品已在团，无法重复创建。如需更新请失效团后再次操作"}
                    handleOk={this.handleGoodsRemindOk}
                    handleCancel={this.handleGoodsRemindOk}
                >
                </GoodsRemind>
            </div>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode

    }
}
const GroupAddForm = Form.create({name: 'group_add_form'})(GroupAdd);

export default connect(mapStateToProps)(GroupAddForm);
