import React from 'react'
import {connect} from "react-redux";
import NavBar from '@/view/component/common/NavBar.js'
import '@css/driverManage.scss'
import {Button,Input,Table,Popconfirm,message,Modal,Form} from 'antd'
import {queryVehicleList,createOrUpdateVehicle} from '@service/getData'
import cookie from 'react-cookies'
class  DriverManage extends React.Component{
    constructor(props){
        super(props)
        this.state={
            columns:[
                {
                    title: '司机编码',
                    dataIndex: 'vehicle_id',
                },{
                    title: '司机名称',
                    dataIndex: 'driver_name',
                    render:(text,record)=>(
                        <div>
                            {text}
                            <Popconfirm
                                title={
                                    <Input
                                        style={{"width":"84px","borderRadius":"100px"}}
                                        value={this.state.driver_name}
                                        onChange={this.changeDriverName}
                                    />
                                }
                                onConfirm={this.confirm.bind(this,record.vehicle_id)}
                                onCancel={this.cancel}
                                okText="确认"
                                cancelText="取消"
                                icon={null}>
                                <i className="iconfont icon-bianji" ></i>
                            </Popconfirm>
                        </div>
                    )
                },{
                    title: '司机电话',
                    dataIndex: 'driver_mobile',
                    render:(text,record)=>(
                        <div>
                            {text}
                            <Popconfirm
                                title={
                                    <Input
                                        style={{"width":"200px","borderRadius":"100px"}}
                                        value={this.state.driver_mobile}
                                        onChange={this.changeDriverMobile}
                                    />
                                }
                                onConfirm={this.confirm.bind(this,record.vehicle_id)}
                                onCancel={this.cancel}
                                okText="确认"
                                cancelText="取消"
                                icon={null}>
                                <i className="iconfont icon-bianji" ></i>
                            </Popconfirm>
                        </div>
                    )
                }, {
                    title: '操作',
                    dataIndex: 'action',
                    render:(text,record)=>(
                        <p className="text-note"  onClick={this.detele.bind(this,record)}>删除</p>
                    )
                }
            ],
            vehicle_res_list:[],
            driver_mobile:null,
            driver_name:null,
            visible: false

        }
    }
    async queryVehicleList(){
        if(!cookie.load("shop_id")&&Number(cookie.load("is_admin"))===1){
            return
        }
        let data={
            shop_id:cookie.load("shop_id")
        }
        let res =await queryVehicleList(data)
        if(res.success){
            this.setState({
                vehicle_res_list:res.result.vehicle_res_list
            })

        }

    }
    async createOrUpdateVehicle(data,status){
        let res=await createOrUpdateVehicle(data);
        if(res.success){
            message.success(Number(status)===0?"已成功添加司机":Number(status)===1?"已成功更新司机信息":"已成功删除此司机")
            this.queryVehicleList();
            this.setState({
                visible: false,
            },()=> this.props.form.resetFields());
        }
    }
    confirm=(vehicle_id,) =>{
        let data={
            vehicle_req:{
                vehicle_id:vehicle_id,
                driver_name:this.state.driver_name,
                driver_mobile:this.state.driver_mobile,
                shop_id:cookie.load("shop_id"),
                status:1
            }
        }
        this.createOrUpdateVehicle(data,1)
        this.setState({
            driver_name:null,
            driver_mobile:null
        })
    }
    changeDriverName=(e)=>{
        this.setState({
            driver_name:e.target.value
        })
    }
    changeDriverMobile=(e)=>{
        this.setState({
            driver_mobile:e.target.value
        })
    }
    detele=(record)=>{
        let data={
            vehicle_req:{
                vehicle_id:record.vehicle_id,
                status:0,
                shop_id:cookie.load("shop_id")
            }
        }
        this.createOrUpdateVehicle(data,2)
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleOk = (e) => {
        let form=this.props.form.getFieldsValue();
        let data={
            vehicle_req:{
                driver_name:form.driver_name,
                driver_mobile:form.driver_mobile,
                status:1,
                shop_id:cookie.load("shop_id")
            }
        }
        this.createOrUpdateVehicle(data,0)
    }

    handleCancel = (e) => {
        this.setState({
            visible: false,
        });
    }
    cancel=(e)=> {
        this.setState({
            driver_mobile:null
        })
    }
    componentDidMount(){
        this.queryVehicleList();
    }
    render(){
        let {menu_code} = this.props
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="driver-manage">
                <NavBar icon="icon-yingyee"  father={menu_code.order_manage}child={menu_code.driver_manage}/>
                <div className="content">
                    <div className="bonus-recharge-title">
                        <i className="icon-waimai1 iconfont"></i>
                        <span className="bonus-recharge-title-sign">自提订单可选择司机送货至团长。</span>
                        <Button type="primary" shape="round" onClick={this.showModal}>添加送货司机</Button>
                    </div>
                    <Table rowKey={(record, index) => index}

                           columns={this.state.columns}
                           dataSource={this.state.vehicle_res_list}
                           pagination={false} bordered={true}
                    />
                </div>
                <Modal
                    title="添加送货司机"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    className="driver-modal"
                    okButtonProps={{shape:'round'}}
                    cancelButtonProps={{shape:'round'}}
                >
                   <Form>
                       <Form.Item label="司机姓名">
                           {getFieldDecorator('driver_name')(
                               <Input className="driver-info" />
                           )}
                       </Form.Item>
                       <Form.Item label="司机电话">
                           {getFieldDecorator('driver_mobile')(
                               <Input className="driver-info" />
                           )}
                       </Form.Item>
                   </Form>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
    }
}
const DriverManageForm = Form.create({ name: 'driver_manage_form' })(DriverManage);
export default connect(mapStateToProps)(DriverManageForm);
