/*
 * @Author: zhouliang
 * @Date: 2020-12-01 10:30:26
 * @Last Modified by: Zela.zhou
 * @Last Modified time: 2020-12-03 16:57:04
 * @Desc: 售后订单详情（原orderDetail里状态区分展示）
*/

import React, { Component } from 'react';
import { Spin, Button, Divider, Modal, Input, message } from 'antd';
import NavBar from "@/view/component/common/NavBar.js";
import { getData } from '@service/getData';
import { getQueryString } from '@common/common';
import '../scss/refundOrderDetail.scss';
import FormTitle from '@/view/component/common/FormTitle/FormTitle.js';
import RefundOrderMoneyModal from '@/view/component/common/RefundOrderMoneyModal';
import cookie from 'react-cookies';
import PropTypes from "prop-types";

class RefundOrderDetail extends Component {
    btnProps = {
        shape: 'round'
    };
    refund_order_id = getQueryString('refund_order_id');
    refundType = {
        refund_cash_only: '仅退款',
        refund_cash_goods: '退货退款',
    };
    iconList = [
        {}, {}, {}, {}, {},
        {
            icon: 'icon-daichuli',
            text: '商家待处理',
            tip: '买家已经对商品申请售后，请尽快处理；还可以点击',
            refunded: false,
        },
        {
            icon: 'icon-jujueshouhou',
            text: '拒绝售后',
            tip: '你已拒绝本次退款申请；还可以点击',
            refunded: true,
        },
        {
            icon: 'icon-tongyi',
            text: '同意售后，待提交微信退款',
            tip: '你已同意本次退款申请；还可以点击',
            canGoDetail: true,
            refunded: true,
        },
        {
            icon: 'icon-tongyi',
            text: '同意售后，提交微信退款成功',
            tip: '你已同意本次退款申请；还可以点击',
            canGoDetail: true,
            refunded: true,
        },
        {
            icon: 'icon-shibai',
            text: '同意售后，提交微信退款失败',
            tip: '你已同意本次退款申请；还可以点击',
            canGoDetail: true,
            color: '#D64442',
            refunded: false,
        },
        {
            icon: 'icon-tongyi',
            text: '退款成功',
            tip: '你已同意本次退款申请；还可以点击',
            canGoDetail: true,
            refunded: true,
        },
        {
            icon: 'icon-shibai',
            text: '退款失败',
            tip: '你已同意本次退款申请；还可以点击',
            canGoDetail: true,
            color: '#D64442',
            refunded: false,
        },
        {
            icon: 'icon-shibai',
            text: '已取消',
            tip: '你还可以点击',
            canGoDetail: true,
            refunded: false,
        },
    ];
    state = {
        loading: true,
        refund_order_res: {},
        showMessageModal: false,
        refund_visible: false,
        goodsList: [],
        refund_record_list: [],
        message: null,
        hideBtn: false,
    };
    constructor() {
        super();
        RefundOrderDetail.contextTypes = {
            router: PropTypes.object.isRequired
        };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        const url = '/refundOrder/detail';
        const data = {
            refund_order_id: +this.refund_order_id,
        };
        getData(url, data).then((res) => {
            if (res.success) {
                const refund_record_list = res.result.refund_order_res.refund_record_res_list.map(item => {
                    const record = {};
                    record.content = item.content;
                    switch (item.content_type) {
                        case "user_refund_start":
                            record.title = item.operate_time + " 买家对商品" + item.content + "发起了售后";
                            record.type = 'buyer';
                            break;
                        case "user_refund_request":
                            record.title = item.operate_time + " 卖家填写了买家诉求"
                            break;
                        case "user_refund_progress":
                            record.title = item.operate_time + " 卖家添加了协商进度"
                            break;
                        case "user_refund_result":
                            record.title = item.operate_time + " 卖家填写了售后结果"
                            break;
                        case "user_refund_cash":
                            record.title = item.operate_time + " 商家向买家退款" + item.content / 100;
                            break;
                        default:
                            record.title = item.operate_time + " 商家添加了留言"
                            break;
                    }
                    record.list = [record.title, record.content];
                    return record;
                })
                this.setState({
                    refund_order_res: res.result.refund_order_res,
                    refund_record_list,
                    loading: false,
                }, () => {
                    this.getGoodsLIst();
                });
            }
        });
    }
    getGoodsLIst() {
        let goodsList = []
        const refund_order_res = this.state.refund_order_res;
        refund_order_res.refund_goods_res_list.forEach((item, index) => {
            let sku = ""
            item.sku_res.attribute_value_res_list.forEach(qitem => {
                sku += qitem.attribute_value
            })
            let goods = {
                goods_id: item.goods_id,
                goods_name: item.goods_res.goods_name,
                sku: sku,
                goods_cut: null,
                current_price: item.sku_res.current_price / 100,
                total: item.sku_res.current_price * item.quantity / 100,
                quantity: item.quantity,
                status: this.iconList[refund_order_res.refund_status].refunded ? 0 : 1,
                rowSpan: 1,
                thumbnail_url: item.goods_res.thumbnail_url,
                in_cur_refund_order: item.in_cur_refund_order,
            }
            if (index === 0) {
                if (refund_order_res.order_res.order_promotion_res_list) {
                    goods.order_promotion_res_list = refund_order_res.order_res.order_promotion_res_list
                }

            } else {
                goods.order_promotion_res_list = []
            }
            goodsList.push(goods)
        })
        this.setState({
            goodsList,
            pay_amount: refund_order_res.order_res.pay_amount / 100,
            discount_amount: refund_order_res.order_res.discount_amount / 100,
            shipment_fee: refund_order_res.order_res.shipment_fee / 100,
            original_price: (refund_order_res.order_res.pay_amount + refund_order_res.order_res.discount_amount - refund_order_res.order_res.shipment_fee) / 100,
            refund_amount: refund_order_res.refund_amount / 100,
            already_refund_amount: refund_order_res.already_refund_amount / 100,
            refund_ship_fee: refund_order_res.refund_ship_fee / 100,

        });
    }
    toggleMessageModal = () => {
        this.setState(preState => ({
            showMessageModal: !preState.showMessageModal,
        }));
    }
    addMessage = () => {
        const url = '/refundOrder/createOrUpdateRefundRecord';
        const data = {
            refund_record_req: {
                shop_id: cookie.load("shop_id"),
                content: this.state.message,
                status: 1,
                refund_order_id: +this.refund_order_id
            }
        };
        getData(url, data).then((res) => {
            if (res.success) {
                message.success('添加留言成功', 1, () => {
                    this.toggleMessageModal();
                    this.init();
                });
            }
        });
    }

    toggleRefundModal(hide) {
        this.setState(preState => ({
            refund_visible: !preState.refund_visible,
            hideBtn: hide ? true : false,
        }), () => {
            console.log(this.state.hideBtn);
        });
    }

    handleRefundOk = () => {
        let data = {
            shop_id: cookie.load("shop_id"),
            refund_order_id: +this.refund_order_id,
            status: 1
        }
        this.shopApprovalRefundOrder(data, 'refund');
    }

    async shopApprovalRefundOrder(data, type) {
        const url = '/refundOrder/shopApprovalRefundOrder';
        let res = await getData(url, data);
        if (res.success) {
            if (res.result.wx_err_code === "FAIL") {
                Modal.info({
                    title: '温馨提示',
                    okText: "我知道了",
                    content: (
                        <div>
                            <p>您还未授权退款功能，请登录微信支付商户平台：https://pay.weixin.qq.com，将退款功能授权给DTC Mall后再次进行退款操作。</p>
                            <p> 授权路径：产品中心→我授权的产品→将“服务商API退款”功能授权给服务商</p>
                        </div>
                    ),
                    onOk() {
                    },
                });
            } else if (res.result.wx_err_code === "NOTENOUGH") {
                Modal.info({
                    title: '温馨提示',
                    okText: "我知道了",
                    content: (
                        <div>
                            <p>您的账户余额不足，请确认微信支付账户金额大于退款金额后再次进行退款操作。微信支付商户平台：https://pay.weixin.qq.com。
                            </p>
                            <p>为确保正常退款，建议在微信支付账户保留部分金额以供退款。</p>
                        </div>
                    ),
                    onOk() {
                    },
                });
            } else if (res.result.wx_err_code === "ERROR") {
                Modal.info({
                    title: '温馨提示',
                    okText: "我知道了",
                    content: (
                        <div>
                            {res.result.wx_err_code_des}
                        </div>
                    ),
                    onOk() {
                    },
                });
            } else {
                message.success(data.status ? "退款成功" : "拒绝售后成功");
            }
            type === 'refund' && this.toggleRefundModal(false);
            this.init();
        } else {
            message.error(res.msg)
        }
    }
    goUserDetail(user_id) {
        this.context.router.history.push(`/client?user_id=${user_id}`);
    }

    goOrderDetail(order_id) {
        this.context.router.history.push(`/orderDetail?order_id=${order_id}&type=orderManage`);
    }

    handleRefundFalse = () => {
        let data = {
            shop_id: cookie.load("shop_id"),
            refund_order_id: +this.refund_order_id,
            status: 0
        }
        this.shopApprovalRefundOrder(data, 'refused')
    }
    onMessageChange = (e) => {
        this.setState({
            message: e.target.value,
        });
    }
    render() {
        const StatusInfo = Object.keys(this.state.refund_order_res).length ? this.iconList[this.state.refund_order_res.refund_status] : {};
        const orderRes = Object.keys(this.state.refund_order_res).length && Object.keys(this.state.refund_order_res.order_res).length ? this.state.refund_order_res.order_res : {};
        return (
            <div className="RefundOrderDetail">
                <Spin spinning={this.state.loading}>
                    <NavBar
                        icon="icon-dingdan"
                        father='售后服务'
                        router='afterSaleManage'
                        child='售后详情'
                    />
                    <div className="content top">
                        <p className="refundSn">售后单号：{this.state.refund_order_res.refund_order_sn}</p>
                        <p className="status">
                            <i style={{ color: StatusInfo.color }} className={`iconfont ${StatusInfo.icon}`}></i>
                            <span style={{ color: StatusInfo.color }}>{StatusInfo.text}</span>
                            {StatusInfo.canGoDetail ? (
                                <span className="goDetail" onClick={this.toggleRefundModal.bind(this, true)}>查看退款详情</span>
                            ) : null}
                            <span></span>
                        </p>
                        <p className="statusDesc">
                            <span>{StatusInfo.tip}</span>
                            <span className="text-note" onClick={this.toggleMessageModal}>添加留言</span>
                        </p>
                        {StatusInfo.refunded ? null : (
                            <p className="btnWrap">
                                <Button shape="round" type="primary" onClick={this.toggleRefundModal.bind(this, false)}>退款</Button>
                                <Button shape="round" style={{ marginLeft: '26px' }} onClick={this.handleRefundFalse}>拒绝售后</Button>
                            </p>
                        )}
                        <p className="personAndTime">申请人：<span className="text-note" onClick={this.goUserDetail.bind(this, this.state.refund_order_res.user_id)}>{this.state.refund_order_res.create_user}</span> | 申请时间：{this.state.refund_order_res.create_at}</p>
                    </div>
                    <div className="content mid">
                        <FormTitle title="售后详情"></FormTitle>
                        <div className="midWrap">
                            <div className="refundGoods">
                                <p className="midTitle">售后商品</p>
                                {this.state.goodsList.filter((goods) => goods.in_cur_refund_order).map((goods) => (
                                    <div key={goods.goods_id} className="goodsWrap">
                                        <img className="goodsImg" src={goods.thumbnail_url} alt="" />
                                        <div>
                                            <p className="noMTop goodsName">{goods.goods_name}</p>
                                            <p className="gray">¥{goods.current_price}{goods.sku ? `/${goods.sku}` : ''}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="refundInfos">
                                <p className="midTitle">售后信息</p>
                                <p className="noMTop">
                                    <span>售后方式：</span>
                                    <span className="juice">{this.refundType[this.state.refund_order_res.refund_type]}</span>
                                </p>
                                <p>
                                    <span>退款金额：</span>
                                    <span className="juice">¥{this.state.refund_order_res.refund_amount / 100}</span>
                                </p>
                                <p className="refundReason">退款原因：{this.state.refund_order_res.refund_reason_desc || '未知'}</p>
                            </div>
                            <div className="buyInfos">
                                <p className="midTitle">购买信息</p>
                                <p className="noMTop">运费：{this.state.shipment_fee}</p>
                                <p>发货状态：{this.state.refund_order_res.refund_shipment_id ? '已发货' : '未发货'}</p>

                                <p>合计优惠：{(orderRes.goods_discount_amount + orderRes.activity_coupon_amount) / 100}</p>
                                <p>实付金额：{this.state.pay_amount}</p>
                                <p>
                                    <span>订单编号：</span>
                                    <span className="text-note" onClick={this.goOrderDetail.bind(this, this.state.refund_order_res.order_id)}>{this.state.refund_order_res.order_sn}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="content bot">
                        <FormTitle title="售后记录"></FormTitle>
                        {this.state.refund_record_list.map((item, index) => (
                            <div key={index} className="record">
                                <div className="recordLeft">
                                    <div className="role" style={{ backgroundColor: item.type === 'buyer' ? '#C2C2C2' : '#1790FF' }}>{item.type === 'buyer' ? '买' : '商'}</div>
                                    {index === this.state.refund_record_list.length - 1 ? null : (
                                        <div className="line"></div>
                                    )}
                                </div>
                                <div className="recordRight">
                                    {item.list.map((ccitem, ccindex) => (
                                        <div key={ccindex}>
                                            <span>
                                                {ccitem}
                                            </span>
                                            {ccindex === item.list.length - 1 ? null : (
                                                <Divider />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </Spin>
                <Modal
                    visible={this.state.showMessageModal}
                    title="添加留言"
                    cancelText="取消"
                    okText="确定"
                    okButtonProps={this.btnProps}
                    cancelButtonProps={this.btnProps}
                    onCancel={this.toggleMessageModal}
                    onOk={this.addMessage}
                >
                    <p>协商进度：</p>
                    <Input.TextArea
                        style={{ borderRadius: '12px', marginTop: '12px' }}
                        rows={4}
                        placeholder="请输入"
                        value={this.state.message}
                        onChange={this.onMessageChange}
                    />
                </Modal>
                <RefundOrderMoneyModal
                    visible={this.state.refund_visible}
                    handleOk={this.handleRefundOk}
                    handleCancel={this.toggleRefundModal.bind(this, false)}
                    refund_amount={this.state.refund_amount}
                    already_refund_amount={this.state.already_refund_amount}
                    refund_ship_fee={this.state.refund_ship_fee}
                    order_goods_list={this.state.goodsList}
                    pay_amount={this.state.pay_amount}
                    discount_amount={this.state.discount_amount}
                    shipment_fee={this.state.shipment_fee}
                    original_price={this.state.original_price}
                    hideBtn={this.state.hideBtn}
                />
            </div>
        );
    }
}

export default RefundOrderDetail;
