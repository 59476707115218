import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import NavBar from "@/view/component/common/NavBar.js";
import UploadComponent from "@/view/component/common/Upload.js";
import GoodsChoose from "@/view/component/Goods/GoodsChoose";
import "@css/createOrUpdateCard.scss";
import cookie from "react-cookies";
import { createOrUpdateGiftCard, queryGiftCardDetail } from "@service/getData";
import { getQueryString } from "@common/common";
import { Form, Input, DatePicker, Divider, Radio, Tooltip, Button, Checkbox, message } from "antd";

const { TextArea } = Input;
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};
class CreateOrUpdateCard extends React.Component {
  constructor() {
    super();
    CreateOrUpdateCard.contextTypes = {
      router: PropTypes.object.isRequired
    };
    this.state = {
      loading: false,
      card_res: {},
      gift_card_id: "",
      bg_list: [
        { color_name: "pink", color_value: "linear-gradient(360deg, rgba(253, 234, 239, 1) 0%, rgba(249, 161, 186, 1) 100%)" },
        { color_name: "blue", color_value: "linear-gradient(360deg, rgba(253, 234, 239, 1) 0%, rgba(131, 204, 238, 1) 100%)" },
        { color_name: "green", color_value: "linear-gradient(360deg, rgba(175, 246, 234, 1) 0%, rgba(253, 234, 239, 1) 100%)" },
        { color_name: "gray", color_value: "linear-gradient(360deg, rgba(194, 194, 194, 1) 0%, rgba(253, 234, 239, 1) 100%)" }
      ],
      bg_name: "",
      card_bg_type: "gift_card_color",
      bg_style: {},
      fileList: [],
      gift_card_note: "", //使用须知
      gift_card_price: "", //价格
      gift_card_count: "", //库存
      gift_card_code: "", //商品编码
      redeem_time_type: "", //兑换有效期类型
      redeem_start_time: "", //开始时间
      redeem_end_time: "", //结束时间
      redeem_days: "", //有效期几天
      redeemable_sku_count: "", //兑换次数
      redeemable_sku_id: "", //兑换商品sku_id
      visible: false,
      coupon_goods_req_list: [],
      activationValue: null,
      file: null,
    };
  }
  componentDidMount() {
    let gift_card_id = getQueryString("gift_card_id");
    if (gift_card_id) {
      this.setState(
        {
          gift_card_id
        },
        () => {
          this.queryGiftCardDetail();
        }
      );
    } else {
      this.changeBg(this.state.bg_list[0]);
    }
  }
  queryGiftCardDetail = async () => {
    let res = await queryGiftCardDetail({
      gift_card_id: this.state.gift_card_id,
      mini_app_id: cookie.load("mini_app_id"),
      goods_detail_ext: true
    });
    let stateValue = {
      redeem_start_time: "",
      redeem_end_time: "",
      gift_card_price: "",
      gift_card_count: "",
      gift_card_code: "",
      redeem_days: "",
      redeemable_sku_count: "",
      redeemable_sku_id: "",
      card_bg_type: ""
    },
      formValue = {
        gift_card_name: "",
        gift_card_note: "",
        redeem_time_type: "",
        gift_card_channel: "",
        card_bg_type: ""
      },
      gift_card = res.result.gift_card;
    let { gift_card_color, gift_card_image } = gift_card;
    if (gift_card_image) {
      stateValue.card_bg_type = "gift_card_image";
      formValue.card_bg_type = "gift_card_image";
      //添加图片
      let list = [
        {
          uid: "1",
          name: "image",
          status: "done",
          url: gift_card_image.substring(gift_card_image.indexOf("(") + 1, gift_card_image.indexOf(")"))
        }
      ];
      let bg_style = {
        background: gift_card_image
      };
      this.setState({
        fileList: list,
        bg_style
      });
    } else {
      stateValue.card_bg_type = "gift_card_color";
      formValue.card_bg_type = "gift_card_color";
      let index = this.state.bg_list.findIndex(item => {
        return item.color_value === gift_card_color;
      });
      this.changeBg(this.state.bg_list[index]);
    }
    for (let item in stateValue) {
      stateValue[item] = gift_card[item];
    }
    for (let item in formValue) {
      let obj = {};
      if (item === "gift_card_channel") {
        obj[item] = gift_card[item].split(",");
      } else {
        obj[item] = formValue[item] || gift_card[item];
      }
      this.props.form.setFieldsValue(obj);
    }
    stateValue.coupon_goods_req_list = [gift_card.goods_detail];
    this.setState(stateValue);
    this.setState({
      activationValue: gift_card.gift_card_activation_type
    });
  };
  changeBg = item => {
    let bg_style = {
      background: item.color_value
    };
    this.setState({
      bg_name: item.color_name,
      bg_style
    });
  };
  handleChange = info => {
    let fileList = info.fileList,
      upload_img;
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = file.response.result.oss_file_url;
        upload_img = file.url;
      }
      return file;
    });
    let bg_style = {
      background: `url(${upload_img}) 0 0 / 100% 100%`
    };
    this.setState({ fileList, bg_style });
  };
  changeRedeemTimeType = e => {
    this.setState({
      redeem_time_type: +e.target.value
    });
  };
  onBgChange = e => {
    this.setState({
      card_bg_type: e.target.value
    });
  };
  changeInput = (key, event) => {
    event.persist();
    let obj = {};
    obj[key] = event.target.value;
    this.setState(obj);
  };
  onShowTimeChange = (date, dateStr) => {
    this.setState({
      redeem_start_time: dateStr[0],
      redeem_end_time: dateStr[1]
    });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = list => {
    this.setState({
      coupon_goods_req_list: [...list],
      redeemable_sku_id: list[0].sku_res_list[0].sku_id,
      visible: false
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false
    });
  };
  createCard = async () => {
    let form = this.props.form.getFieldsValue();
    let flag = false;
    this.props.form.validateFields((err, values) => {
      if (err) {
        flag = true;
      }
    });
    if (flag) return;
    if (!this.state.gift_card_code) {
      message.warning("请填写商品编码");
      return;
    }
    if (!this.state.redeemable_sku_id) {
      message.warning("请选择商品");
      return;
    }
    if (this.state.activationValue === null) {
      message.warning('请选择激活方式');
      return;
    }
    let redeem_time_type = form.redeem_time_type;
    let data = {
      gift_card_id: this.state.gift_card_id,
      mini_app_id: cookie.load("mini_app_id"),
      gift_card_name: form.gift_card_name,
      gift_card_note: form.gift_card_note,
      redeem_time_type,
      gift_card_channel: form.gift_card_channel.join(","),
      gift_card_price: this.state.gift_card_price,
      redeemable_sku_count: this.state.redeemable_sku_count,
      gift_card_count: this.state.gift_card_count,
      gift_card_code: this.state.gift_card_code,
      redeemable_sku_id: this.state.redeemable_sku_id,
      gift_card_type: "gift_card",
      gift_card_activation_type: this.state.activationValue,
    };
    data[form.card_bg_type] = this.state.bg_style.background;
    if (redeem_time_type === 2) {
      data.redeem_start_time = this.state.redeem_start_time + " 00:00:01";
      data.redeem_end_time = this.state.redeem_end_time + " 23:59:59";
    } else if (redeem_time_type === 3) {
      data.redeem_days = this.state.redeem_days;
    }
    let res = await createOrUpdateGiftCard({ giftCard: data });
    if (res.success) {
      if (this.state.gift_card_id) {
        message.success("成功编辑权益卡");
      } else {
        message.success("成功创建权益卡");
      }
      this.clear();
    } else {
      message.error(res.msg);
    }
  };
  clear = () => {
    this.props.form.resetFields();
    this.context.router.history.push({ pathname: "/cardManage" });
  };
  onActivationValueChange(e) {
    this.setState({
      activationValue: e.target.value,
    });
  }
  render() {
    let { menu_code } = this.props;
    const state = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="page card-content create-update-card">
        <NavBar
          icon="icon-kehu1"
          child={state.gift_card_id ? "编辑权益卡" : "新建权益卡"}
          father={menu_code.card_manage}
          router="cardManage"
        />
        <div className="content">
          <div className="shopDataTitle">
            <i className="titleSign" />
            权益卡信息
          </div>
          <Divider />
          <div className="show-content">
            <div className="show-title">卡片示例</div>
            <div className={"card-info " + (state.card_bg_type === "gift_card_image" ? "white " : "")} style={state.bg_style}>
              {state.card_bg_type === "gift_card_image" && state.fileList.length ? (
                <div className="bg-image black" />
              ) : (
                  <img alt=""
                    className="bg-image"
                    lazy-load="true"
                    src="http://udh.oss-cn-hangzhou.aliyuncs.com/a0da0d84-30d7-4235-888a-dc028b0acb97"
                    mode="widthFix"
                  />
                )}
              <div className="rules">
                <span>使用须知</span>
              </div>
              <div className="flex-col">
                <img alt=""
                  src="http://udh.oss-cn-hangzhou.aliyuncs.com/6138bf79-432e-4b83-a917-bc9dce30c458"
                  mode="widthFix"
                  lazy-load="true"
                  className="logo"
                />
                <span className="card-name">依云12箱水兑换卡</span>
                <span className="label">剩余数量</span>
                <span className="less-num">11</span>
                <span className="card-equity">权益：兑换商品，总数量：11</span>
                <div className="flex">
                  <span className="card-no">卡号：1234567890</span>
                  <span className="card-status">使用中</span>
                </div>
                <span className="order-no">天猫订单号：XXXXXXXXXX</span>
                <span className="card-date">有效期：2020.04.10 - 2020.04.21</span>
              </div>
            </div>
          </div>
          <Form className="card-form">
            <Form.Item label="权益卡名称">
              {getFieldDecorator("gift_card_name", {
                initialValue: null,
                rules: [
                  {
                    required: true,
                    message: "不可为空"
                  }
                ]
              })(<Input disabled={state.gift_card_id ? true : false} maxLength={15} className="input-short" placeholder="15字以内" />)}
            </Form.Item>
            <Form.Item label="卡片背景">
              {getFieldDecorator("card_bg_type", {
                initialValue: "gift_card_color",
                rules: [
                  {
                    required: true,
                    message: "请选择背景色"
                  }
                ]
              })(
                <Radio.Group onChange={this.onBgChange}>
                  <Radio value="gift_card_color" className="block">
                    <span>背景色</span>
                    <div className="bg-list">
                      {state.bg_list.map(item => (
                        <div
                          onClick={this.changeBg.bind(this, item)}
                          key={item.color_name}
                          className={"bg-item " + (state.bg_name === item.color_name ? "active " : " ")}
                          style={{
                            background: item.color_value
                          }}
                        />
                      ))}
                    </div>
                  </Radio>
                  <Radio value="gift_card_image" className="block">
                    <span>背景图</span>
                    <div className="upload">
                      <UploadComponent fileList={state.fileList} onChange={this.handleChange} btn_text="添加图片" />
                      <div className="upload-tips">建议宽度375，高度不限，仅支持jpg、png2种格式，单张图片大小不超过3.0MB</div>
                    </div>
                  </Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item label="有效期">
              {getFieldDecorator("redeem_time_type", {
                initialValue: 1,
                rules: [
                  {
                    required: true,
                    message: <span className="text-danger">请输入使用时间</span>
                  }
                ]
              })(
                <Radio.Group disabled={state.gift_card_id ? true : false} onChange={this.changeRedeemTimeType}>
                  <Radio className="block mb10" value={1}>
                    永久有效
                  </Radio>
                  <Radio className="block mb10" value={2}>
                    <DatePicker.RangePicker
                      value={
                        state.redeem_start_time
                          ? [moment(state.redeem_start_time, "YYYY-MM-DD"), moment(state.redeem_end_time, "YYYY-MM-DD")]
                          : null
                      }
                      disabled={state.gift_card_id ? true : false}
                      className="form-long-content"
                      format="YYYY-MM-DD"
                      onChange={this.onShowTimeChange}
                    />
                  </Radio>
                  <Radio className="block mb10" value={3}>
                    按领取之日起
                    <Input
                      disabled={state.gift_card_id ? true : false || state.redeem_time_type !== 3}
                      className="input-short"
                      suffix="天"
                      value={state.redeem_days}
                      onChange={this.changeInput.bind(this, "redeem_days")}
                    />
                    内可用
                    <Tooltip
                      placement="bottom"
                      title="有效期按自然天计算。如设置按领取之日起3天内可用，用户在11月11日11:11:11时领取权益卡，则该权益卡的可用时间为11月11日的11:11:11至11月13日的23:59:59。">
                      <i className="iconfont icon-bangzhu" />
                    </Tooltip>
                  </Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item label="使用须知">
              {getFieldDecorator("gift_card_note", {
                initialValue: null,
                rules: [
                  {
                    required: true,
                    message: "请填写使用须知"
                  }
                ]
              })(
                <TextArea
                  disabled={state.gift_card_id ? true : false}
                  className="card-textarea"
                  rows={4}
                  onChange={this.changeInput.bind(this, "gift_card_note")}
                />
              )}
            </Form.Item>
          </Form>
          <div className="shopDataTitle">
            <i className="titleSign" />
            领取和使用规则
          </div>
          <Divider />
          <Form>
            <Form.Item label="领取方式">
              {getFieldDecorator("receive_type", {
                rules: [
                  {
                    required: true,
                    message: <span className="text-danger">请填写商品编码</span>
                  }
                ],
                initialValue: state.gift_card_code
              })(
                <div>
                  <span>付费购买</span>
                  <div className="pay-table">
                    <div className="table-col">
                      <div className="col-head">
                        <span>价格</span>
                      </div>
                      <div className="col-body">
                        <Input
                          disabled={state.gift_card_id ? true : false}
                          className="input-short"
                          value={state.gift_card_price}
                          onChange={this.changeInput.bind(this, "gift_card_price")}
                        />
                      </div>
                    </div>
                    <div className="table-col">
                      <div className="col-head">
                        <span>库存</span>
                      </div>
                      <div className="col-body">
                        <Input
                          disabled={state.gift_card_id ? true : false}
                          className="input-short"
                          value={state.gift_card_count}
                          onChange={this.changeInput.bind(this, "gift_card_count")}
                        />
                      </div>
                    </div>
                    <div className="table-col">
                      <div className="col-head">
                        <span className="text-danger">*</span>
                        <span>商品编码</span>
                        <span className="iconfont icon-tishi" />
                      </div>
                      <div className="col-body">
                        <Input
                          disabled={state.gift_card_id ? true : false}
                          className="input-short"
                          value={state.gift_card_code}
                          onChange={this.changeInput.bind(this, "gift_card_code")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Form.Item>
            <Form.Item label="付费渠道">
              {getFieldDecorator("gift_card_channel", {
                initialValue: ["oms"],
                rules: [
                  {
                    required: true,
                    message: <span className="text-danger">请选择付费渠道</span>
                  }
                ]
              })(
                <Checkbox.Group style={{ width: "100%" }} disabled={state.gift_card_id ? true : false}>
                  <Checkbox value="applet">小程序</Checkbox>
                  <Checkbox value="oms">OMS</Checkbox>
                </Checkbox.Group>
              )}
            </Form.Item>
            <Form.Item label="激活设置" required>
              <Radio.Group onChange={this.onActivationValueChange.bind(this)} value={this.state.activationValue}>
                <Radio style={radioStyle} value={0}>
                  验证手机号：买家领取卡片需填写收件人手机号
                </Radio>
                <Radio disabled style={radioStyle} value={2}>
                  验证卡号和密码（自动生成）：买家领取卡片需输入卡号和密码
                </Radio>
                <Radio style={radioStyle} value={1}>
                  验证卡号和密码（手动导入）：买家领取卡片需输入卡号和密码
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
          <div className="shopDataTitle">
            <i className="titleSign" />
            权益内容
          </div>
          <Divider />
          <Form>
            <Form.Item label="权益">
              {getFieldDecorator("redeemable_sku_count", {
                rules: [
                  {
                    required: true,
                    message: <span className="text-danger">请填写兑换次数</span>
                  }
                ],
                initialValue: state.redeemable_sku_count,
              })(
                <div className="select-goods">
                  <span>兑换商品</span>
                  <Input
                    disabled={state.gift_card_id ? true : false}
                    className="input-short"
                    suffix="次"
                    value={state.redeemable_sku_count}
                    onChange={this.changeInput.bind(this, "redeemable_sku_count")}
                  />
                  <div className="integral-goods-add" onClick={this.showModal}>
                    {state.coupon_goods_req_list.length ? (
                      <Fragment>
                        <img alt="" className="goods-image" src={state.coupon_goods_req_list[0].thumbnail_url} />
                        <span className="mask-text">修改商品</span>
                      </Fragment>
                    ) : (
                        <Fragment>
                          <span className="plus">+</span>
                          <span>选择商品</span>
                        </Fragment>
                      )}
                  </div>
                  <div className="tips">最多添加一个商品</div>
                </div>
              )}
            </Form.Item>
          </Form>
        </div>
        <footer className="fixed-bottom">
          <Button type="primary" shape="round" onClick={this.createCard}>
            保存
          </Button>
          <Button shape="round" onClick={this.clear.bind(this, null)}>
            取消
          </Button>
        </footer>
        <GoodsChoose visible={state.visible} handleOk={this.handleOk} handleCancel={this.handleCancel} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};
const CreateOrUpdateCardForm = Form.create({ name: "create_or_update_card" })(CreateOrUpdateCard);
export default connect(mapStateToProps)(CreateOrUpdateCardForm);
