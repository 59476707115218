import React from 'react'
import '@css/campaignShow.scss'
import { Modal } from 'antd'
import cookie from 'react-cookies'
import { queryActivityList } from '@service/getData'
import CampaignBanner from './CampaignBanner'
import CampaignFixed from "./CampaignFixed";
import CampaignGoodsSlider from './CampaignGoodsSlider'
import { compare } from '@common/common'
import CampaignCoupon from './CampaignCoupon'
import CampaignIconLinkList from "./CampaignIconLinkList";
import CampaignLive from "./CampaignLive";
import CampaignVideo from "./CampaignVideo";
import CampaignAdvertisement from "./CampaignAdvertisement";

class CampaignShow extends React.Component {
    constructor() {
        super()
        this.state = {
            activity_list: []
        }
    }

    queryActivityList = async (campaign_id) => {
        let data = {
            mini_app_id: cookie.load("mini_app_id"),
            activity_type: "campaign",
            campaign_id: campaign_id
        }
        let res = await queryActivityList(data)
        let activity_list = res.result.activity_res_list;
        activity_list = activity_list.filter(item => item.activity_subtype !== "topScroll");
        activity_list = activity_list.filter(item => item.activity_subtype !== "endScroll");
        activity_list.forEach(item => {
            if (item.activity_subtype === "scroll" || item.activity_subtype === "advertisement") {
                item.url_list = item.activity_res_list.map(qitem => {
                    return qitem.thumbnail_url || '';
                });
                item.default_url = ["http://udh.oss-cn-hangzhou.aliyuncs.com/ba1f25aa-21bf-4628-94e1-8cc5f7d72970"];
            }
            if (item.activity_subtype === "iconLinkList") {
                item.thumbnail_url = "http://udh.oss-cn-hangzhou.aliyuncs.com/74359521-5e6b-404e-8e7e-33ae7351a782";
            }
            if (item.activity_subtype === "roomInfoList") {
                item.thumbnail_url = "http://udh.oss-cn-hangzhou.aliyuncs.com/f9d1c529-a89e-4c0f-8860-164ff0813e36";
            }
            if (item.activity_subtype === "roomInfoList") {
                let activity_room_info_res_list = item.activity_room_info_res_list.map(qitem => {
                    return {
                        ...qitem,
                        ...qitem.room_info_res
                    };
                });
                item.activity_room_info_res_list = activity_room_info_res_list;
            }
        });
        activity_list.sort(compare('sort_num'));
        if (res.success) {
            this.setState({
                activity_list: activity_list
            })
        }

    }
    cancel = () => {
        this.setState({
            activity_list: []
        })
        this.props.onOk();
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.visible) {
            if (props.campaign && props.campaign.campaign_id) {
                this.queryActivityList(props.campaign.campaign_id)
            }
        }
    }

    render() {
        return (
            <Modal
                className="campaign-show"
                visible={this.props.visible}
                onOk={this.cancel}
                onCancel={this.cancel}
                destroyOnClose={true}
                okButtonProps={{ shape: 'round' }}
                cancelButtonProps={{ shape: 'round' }}
            >
                <div className="iphone-content">
                    <div className="iphone-title">
                        <div className="iphone-title-content"></div>
                    </div>
                    <div className="phone-content">
                        {this.state.activity_list.map((item, index) => (
                            <div
                                className="activity"
                                key={item.activity_id}
                                data-id={item.activity_id}>
                                {item.activity_subtype === "scroll" ? (
                                    <CampaignBanner pattern_type={item.pattern_type} default_url={item.default_url} url_list={item.url_list ? item.url_list : []} />
                                ) : item.activity_subtype === "buyEveryDay" ||
                                    item.activity_subtype === "memberDay" ||
                                    item.activity_subtype === "timeLimitBuy" ? (
                                            <CampaignFixed campaign={item} show_type={this.props.show_type} />
                                        ) : item.activity_subtype === "goodsListSlide" ? (
                                            <CampaignGoodsSlider goods={item} />
                                        ) : item.activity_subtype === "coupon" && item.activity_coupon_res_list.length !== 0 ? (
                                            <CampaignCoupon coupon_list={item.activity_coupon_res_list} url={item.image_url} />
                                        ) : item.activity_subtype === "iconLinkList" && item.activity_res_list.length ? (
                                            <CampaignIconLinkList activity_list={item.activity_res_list} />
                                        ) : item.activity_subtype === "roomInfoList" && item.activity_room_info_res_list.length ? (
                                            <CampaignLive
                                                button_back_value={item.button_back_value}
                                                activity_room_info_res_list={item.activity_room_info_res_list}
                                            />
                                        ) : item.activity_subtype === "advertisement" ? (
                                            <CampaignAdvertisement template_type={item.template_type} url_list={item.url_list ? item.url_list : []} />
                                        ) : item.activity_subtype === 'video' ? (
                                            <CampaignVideo
                                                poster={item.video_pic}
                                                url={item.video_url}
                                            ></CampaignVideo>
                                        ) : (
                                                                    <div>
                                                                        <img alt="" width="270px" src={item.thumbnail_url} />
                                                                    </div>
                                                                )
                                }
                            </div>
                        ))}
                    </div>
                    <div className="iphone-nav"></div>
                </div>
            </Modal>
        )
    }
}

export default CampaignShow;
