import { Divider, Spin, Form, Icon, Input, Button, message, Radio, Select, Table } from 'antd';
import React, { Component } from 'react';
import FormTitle from '@/view/component/common/FormTitle/FormTitle';
import NavBar from "@/view/component/common/NavBar.js";
import './cycleSetting.scss';
import GoodsChoose from "@/view/component/Goods/GoodsChoose";
import PopOverTips from '@/view/component/common/PopOverTips/PopOverTips';
import { getData } from '@service/getData';
import cookie from 'react-cookies';
import { getQueryString } from "@common/common";
import PropTypes from "prop-types";

class CycleSetting extends Component {
    dayOption = [];
    timeOption = [];
    period_purchase_id = +getQueryString('period_purchase_id');
    mini_app_id = cookie.load('mini_app_id');
    // +null => 0
    // Boolean(0) => false
    disabled = Boolean(this.period_purchase_id);
    constructor() {
        super();
        CycleSetting.contextTypes = {
            router: PropTypes.object.isRequired,
        };
        this.state = {
            loading: true,
            visible: false,
            seletedGoods: null,
            name: null,
            start: null,
            fulfill_time_day: null,
            fulfill_time_clock: null,
            columns: [
                {
                    title: '活动规格',
                    dataIndex: 'period_num',
                    render: (text, row, index) => (
                        <div>
                            <Input className="phase" value={text} placeholder="填写数字" onChange={this.onSettingChange.bind(this, index, 'period_num')} />
                            期
                        </div>
                    ),
                },
                {
                    title: '展示给买家的描述',
                    dataIndex: 'option_name',
                    render: (text, row, index) => (
                        <Input value={text} placeholder="比如：1月四束鲜花" onChange={this.onSettingChange.bind(this, index, 'option_name')} />
                    ),
                },
                {
                    title: <PopOverTips inners='价格' content='配送数量为1件时该规格的售价' />,
                    dataIndex: 'price',
                    render: (text, row, index) => (
                        <Input style={{ width: '106px' }} prefix="¥" value={text} onChange={this.onSettingChange.bind(this, index, 'price')} />
                    ),
                },
                {
                    title: '库存',
                    dataIndex: 'stock',
                    render: (text, row, index) => (
                        <Input className="sku" value={text} onChange={this.onSettingChange.bind(this, index, 'stock')} />
                    ),
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    render: (text, row, index) => {
                        return index > 0 ? (
                            <span onClick={this.delete.bind(this, index)} className="text-note">删除</span>
                        ) : null
                    },
                }
            ],
            optionData: [
                {
                    period_num: null,
                    option_name: null,
                    price: null,
                    stock: null,
                },
            ],
        };
        for (let i = 1; i < 8; i++) {
            this.dayOption.push(i);
        }
        for (let i = 1; i < 25; i++) {
            this.timeOption.push(i);
        }
    }

    componentDidMount() {
        if (this.period_purchase_id) {
            this.getData();
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    async getData() {
        const url = '/period_purchase/info';
        const data = {
            mini_app_id: this.mini_app_id,
            period_purchase_id: this.period_purchase_id,
        };
        let res = await getData(url, data);
        if (res.success && res.result) {
            let {
                goods_res: seletedGoods,
                group_id,
                sku_id,
                name,
                min_quantity_per_order: start,
                fulfill_time_day,
                fulfill_time_clock,
                period_purchase_option_res_vos: optionData,
            } = res.result.period_purchase_res;
            seletedGoods.group_id = group_id;
            seletedGoods.sku_id = sku_id;
            optionData = optionData.map((item) => {
                let _item = { ...item };
                for (let k in _item) {
                    if (k === 'price') {
                        _item[k] = _item[k] / 100;
                    }
                }
                return _item;
            });
            this.setState({
                seletedGoods,
                name,
                start,
                fulfill_time_day,
                fulfill_time_clock,
                optionData,
                loading: false,
            });
        }
    }

    onSettingChange(index, attr, e) {
        let value = e.target.value;
        const optionData = [...this.state.optionData].map((item, zindex) => {
            if (index === zindex) {
                item = { ...this.state.optionData[index], [attr]: value };
            }
            return item;
        });
        this.setState({
            optionData,
        });
    }

    handleOk = (list) => {
        let seletedGoods = { ...list[0] };
        seletedGoods.sku_id = list[0].sku_res_list[0].sku_id;
        seletedGoods.goods_id = list[0].goods_id;
        seletedGoods.group_id = list[0].default_group_id;
        this.setState({
            seletedGoods,
            visible: false,
        });
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }

    openModal = () => {
        if (this.disabled) {
            return;
        }
        this.setState({
            visible: true,
        });
    }
    onNameChange = (e) => {
        this.setState({
            name: e.target.value,
        });
    }
    onStartChange = (e) => {
        this.setState({
            start: +e.target.value,
        });
    }

    onSave = async () => {
        if (!this.validate()) {
            return;
        }
        let optionData = [...this.state.optionData];
        optionData = optionData.map((item) => {
            let _item = { ...item };
            for (let k in _item) {
                if (k !== 'option_name') {
                    _item[k] = +_item[k];
                    if (k === 'price') {
                        _item[k] = _item[k] * 100;
                    }
                }
            }
            return _item;
        });
        const url = '/period_purchase/createOrUpdatePeriodPurchase';
        const data = {
            mini_app_id: this.mini_app_id,
            period_purchase_req: {
                period_purchase_id: this.period_purchase_id,
                name: this.state.name,
                group_id: this.state.seletedGoods.group_id,
                goods_id: this.state.seletedGoods.goods_id,
                sku_id: this.state.seletedGoods.sku_id,
                period_type: 1,
                min_quantity_per_order: this.state.start,
                fulfill_time_day: this.state.fulfill_time_day,
                fulfill_time_clock: this.state.fulfill_time_clock,
                status: 0,
            },
            period_purchase_options: optionData,
        };
        let res = await getData(url, data);
        if (res.success) {
            message.success('保存成功', 1, () => {
                this.context.router.history.push('/cyclePurchase');
            });
        }
    }
    validate() {
        if (!this.state.seletedGoods) {
            message.error('请选择商品');
            return false;
        }
        if (this.state.name.length > 30) {
            message.error('活动名称不能超过30个字');
            return false;
        }
        if (!Number.isInteger(this.state.start) || this.state.start < 1) {
            message.error('起售件数请输入大于等于1的整数');
            return false;
        }
        if (!this.state.fulfill_time_day || !this.state.fulfill_time_clock) {
            message.error('请选择配送提前期');
            return false;
        }
        const period_num_arr = [];
        const option_name_arr = [];
        const price_arr = [];
        const stock_arr = [];
        this.state.optionData.forEach((item) => {
            for (let k in item) {
                if (k === 'period_num') {
                    period_num_arr.push(item[k]);
                }
                else if (k === 'option_name') {
                    option_name_arr.push(item[k]);
                }
                else if (k === 'price') {
                    price_arr.push(item[k]);
                }
                else if (k === 'stock') {
                    stock_arr.push(item[k]);
                }
            }
        });
        const isRightPeriodNum = period_num_arr.every((item) => /^[0-9]+$/.test(item) && +item > 0);
        const isRightOptionName = option_name_arr.every((item) => item);
        const isRightPrice = price_arr.every((item) => {
            const reg = /^[0-9]+(\.\d+)?$/;
            return reg.test(item);
        });
        const isRightStock = stock_arr.every((item) => /^[0-9]+$/.test(item) && +item > 0);
        if (!isRightPeriodNum || !isRightStock) {
            message.error('活动规格和库存需要为正整数');
            return false;
        }
        if (!isRightOptionName) {
            message.error('请填写展示给买家的描述');
            return false;
        }
        if (!isRightPrice) {
            message.error('单件价格需为数字且大于等于0');
            return false;
        }
        return true;
    }
    addSize = () => {
        const obj = {
            period_num: null,
            option_name: null,
            price: null,
            stock: null,
        };
        this.setState((preState) => ({
            optionData: [...preState.optionData, obj]
        }));
    }

    delete(index) {
        const optionData = [...this.state.optionData];
        optionData.splice(index, 1);
        this.setState({
            optionData,
        });
    }

    onSelectChange(attr, value) {
        this.setState({
            [attr]: +value,
        });
    }
    back = () => {
        this.context.router.history.push('/cyclePurchase');
    }

    render() {
        const formItemLayout = {
            labelCol: { span: 3 },
            wrapperCol: { span: 20 },
        };
        return (
            <div className="cycleSetting">
                <Spin spinning={this.state.loading}>
                    <NavBar
                        icon="icon-zhouqigou"
                        father='周期购'
                        router='cyclePurchase'
                        child='设置周期购'
                    />
                    <div className="content">
                        <FormTitle title="设置周期购活动" />
                        <Divider />
                        <Form {...formItemLayout}>
                            <Form.Item
                                label="选择商品"
                                required
                                extra={this.disabled ? '禁止更换已有活动商品' : '最多选择一个商品，且仅支持单规格商品'}
                            >
                                <div className="selectArea" onClick={this.openModal}>
                                    {this.state.seletedGoods ? (
                                        <img className="goods" src={this.state.seletedGoods.thumbnail_url} alt="" />
                                    ) : (
                                            <>
                                                <Icon type="plus" />
                                                <p className="text-note">选择商品</p>
                                            </>
                                        )}
                                </div>
                            </Form.Item>
                            <Form.Item label="活动名称" required wrapperCol={{ span: 8 }}>
                                <Input placeholder="30个字以内" value={this.state.name} onChange={this.onNameChange} />
                            </Form.Item>
                            <Form.Item label="起售件数" required wrapperCol={{ span: 8 }}>
                                <Input style={{ width: '180px', marginRight: '8px' }} placeholder="输入的数字需大于等于1" value={this.state.start} onChange={this.onStartChange} />
                                件起售
                            </Form.Item>
                            <Form.Item label="配送周期" required>
                                <Radio checked={true}>每周一期</Radio>
                            </Form.Item>
                            <Form.Item label="配送提前期" required>
                                买家需提前
                                <Select placeholder="天" value={this.state.fulfill_time_day} onChange={this.onSelectChange.bind(this, 'fulfill_time_day')}>
                                    {this.dayOption.map((day) => (
                                        <Select.Option key={day} value={day}>{day}天</Select.Option>
                                    ))}
                                </Select>
                                并在
                                <Select placeholder="时" value={this.state.fulfill_time_clock} onChange={this.onSelectChange.bind(this, 'fulfill_time_clock')}>
                                    {this.timeOption.map((time) => (
                                        <Select.Option key={time} value={time}>{time < 10 ? `0${time}` : time}时</Select.Option>
                                    ))}
                                </Select>
                                前下单支付，才能在最近的配送周期时间送达
                            </Form.Item>
                            <Form.Item label="周期购运费" required>
                                包邮
                            </Form.Item>
                            <Form.Item label="设置配送次数规格" required>
                                <Table
                                    rowKey={(record, index) => index}
                                    columns={this.state.columns}
                                    dataSource={this.state.optionData}
                                    bordered
                                    pagination={false}
                                    footer={() => (
                                        <span onClick={this.addSize}>
                                            <Icon type="plus" />
                                            新增规格
                                        </span>
                                    )}
                                />
                            </Form.Item>
                        </Form>
                        <div className="btnWrap">
                            <Button className="save" shape="round" type="primary" onClick={this.onSave}>保存</Button>
                            <Button className="back" shape="round" onClick={this.back}>取消</Button>
                        </div>
                    </div>
                </Spin>
                <GoodsChoose single={true} visible={this.state.visible} handleOk={this.handleOk} handleCancel={this.handleCancel} />
            </div>
        );
    }
}

export default CycleSetting;