import React from "react";
import "@css/campaignShow.scss";
class CampaignAdvertisement extends React.Component {
  render() {
    return (
      <div className={"campaign-advertisement type" + this.props.template_type}>
        {this.props.url_list.map(item => (
          <div className="campaign-advertisement-box" key={item}>
            <img className="ad-image" alt="" src={item} />
          </div>
        ))}
      </div>
    );
  }
}
export default CampaignAdvertisement;
