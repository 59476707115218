import React from 'react'
import { Button, Table, Pagination, Divider, message, Modal, Tooltip, Form, Input, Select, Icon } from 'antd'
import NavBar from '@/view/component/common/NavBar.js'
import { connect } from "react-redux";
import {
    queryCouponList,
    createOrUpdateCoupon,
    queryMiniAppDetail,
    getPostQrCode,
    getBase64ImageUrl
} from '@service/getData'
import cookie from 'react-cookies'
import PropTypes from 'prop-types'
import { formatDate } from '@common/common'
import '@css/couponManage.scss'
import html2canvas from 'html2canvas';
import copy from 'copy-to-clipboard';
import Poster from '@/view/component/Poster/Poster';
import CouponPoster from './children/CouponPoster';
import CouponButton from './children/CouponButton';


class CouponManage extends React.Component {
    constructor() {
        super()
        CouponManage.contextTypes = {
            router: PropTypes.object.isRequired
        }
        this.state = {
            columns: [{
                title: 'id',
                dataIndex: 'coupon_id'
            }, {
                title: '优惠券类型',
                dataIndex: 'coupon_category',
                render: (text) => {
                    return text === "shop_coupon" ? "全店通用券" : "商品优惠券"
                }
            }, {
                title: '优惠券名称',
                dataIndex: 'coupon_name'
            }, {
                title: '优惠内容',
                dataIndex: 'use_min_amount',
                render: (text, record) => {
                    if (text === 0) {
                        return `无门槛减${record.discount_amount / 100}`
                    }
                    return `满${text / 100}减${record.discount_amount / 100}`
                }
            }, {
                title: '使用时间',
                dataIndex: 'start_time',
                render: (text, record) => {
                    return record.effect_day ? `按领取之日起${record.effect_day}天内可用` : `${formatDate(new Date(text).getTime(), 'Y-M-D')} 至 ${formatDate(new Date(record.end_time).getTime(), 'Y-M-D')}`
                }
            }, {
                title: '状态',
                dataIndex: 'admin_coupon_status',
                render: (text) => {
                    switch (text) {
                        case 0:
                            return "未开始"
                        case 1:
                            return (<span className="text-green">进行中</span>)
                        case 2:
                            return (<span className="text-remind">已过期</span>)
                        case 3:
                            return (<span className="text-danger">已停止</span>)
                        default:
                            break;
                    }

                }
            }, {
                title: '已领取/剩余',
                dataIndex: 'remain_num',
                render: (text, record) => {
                    return `${record.total_num - text}/${text}`
                }
            }, {
                title: '已使用',
                dataIndex: 'use_num'
            }, {
                title: '操作',
                dataIndex: 'action',
                render: (text, record) => {
                    switch (record.admin_coupon_status) {
                        case 0:
                        case 1:
                            return (
                                <div>
                                    <span className="text-note"
                                        onClick={this.goToCoupon.bind(this, record.coupon_id)}>编辑</span>
                                    <Divider type="vertical" />
                                    <span className="text-note"
                                        onClick={this.showConfirm.bind(this, record.coupon_id)}>停止发放</span>
                                    <Divider type="vertical" />
                                    <span className="text-note" onClick={this.showPost.bind(this, record)}>投放</span>
                                </div>)
                        case 2:
                        case 3:
                            return (
                                <span onClick={this.showDeleteConfirm.bind(this, record.coupon_id)}
                                    className="text-note">删除</span>
                            )
                        default:
                            break;
                    }
                }
            }],
            delete_columns: [{
                title: 'id',
                dataIndex: 'coupon_id'
            }, {
                title: '优惠券类型',
                dataIndex: 'coupon_type',
                render: () => {
                    return "全店通用券"
                }
            }, {
                title: '优惠券名称',
                dataIndex: 'coupon_name'
            }, {
                title: '优惠内容',
                dataIndex: 'use_min_amount',
                render: (text, record) => {
                    return `满${text / 100}减${record.discount_amount / 100}`
                }
            }, {
                title: '使用时间',
                dataIndex: 'start_time',
                render: (text, record) => {
                    return `${formatDate(new Date(text).getTime(), 'Y-M-D')} 至 ${formatDate(new Date(record.end_time).getTime(), 'Y-M-D')}`
                }
            }, {
                title: '状态',
                dataIndex: 'admin_coupon_status',
                render: () => {
                    return '已删除'

                }
            }, {
                title: '已领取/剩余',
                dataIndex: 'remain_num',
                render: (text, record) => {
                    return `${record.total_num - text}/${text}`
                }
            }, {
                title: '已使用',
                dataIndex: 'use_num'
            }],
            page_size: 10,
            page: 1,
            total: 0,
            coupon_list: [],
            coupon: {},
            mini_app: {},
            url: "",
            logo_url: '',
            delete: false,
            qr_url: ''
        }

    }

    showConfirm = (coupon_id) => {
        Modal.confirm({
            title: '停止后，无法继续领券，已领取的券在可用时间内可继续使用，不可编辑券内容，确定停止发券?',
            onOk: () => {
                this.createOrUpdateCoupon(coupon_id, 3)
            },
        });
    }
    showDeleteConfirm = (coupon_id) => {
        Modal.confirm({
            title: '删除后，买家已领取、使用的券信息及创建记录均会删除，不可继续使用，确定删除此券?',
            onOk: () => {
                this.createOrUpdateCoupon(coupon_id, 0)
            },
        });
    }
    queryCouponList = async () => {
        let form = this.props.form.getFieldsValue()
        let data = {
            mini_app_id: cookie.load("mini_app_id"),
            page_no: this.state.page,
            page_size: this.state.page_size,
            like_condition: form.like_condition,
            coupon_category: form.coupon_category,
            status: this.state.delete ? 0 : form.status
        }
        let res = await queryCouponList(data)
        let coupon = {}
        if (res.success) {
            if (this.props.location.coupon_id) {
                coupon = res.result.coupon_res_list.find(item => item.coupon_id === this.props.location.coupon_id)
                this.getPostQrCode(this.props.location.coupon_id, 1)
                this.getPostQrCode(coupon.coupon_id, null)
                delete this.props.location.coupon_id
            }
            this.setState({
                coupon_list: res.result.coupon_res_list,
                total: res.result.total,
                coupon
            })

        }
    }
    createOrUpdateCoupon = async (coupon_id, status) => {
        let data = {
            coupon_req: {
                coupon_id,
                status
            }
        }

        let res = await createOrUpdateCoupon(data)
        if (res.success) {
            if (status === 3) {
                message.success("已停止该优惠券")
            } else {
                message.success("已删除该优惠券")
            }
            this.queryCouponList(null)
        }

    }
    queryMiniAppDetail = async () => {
        let mini_app_id = cookie.load("mini_app_id");

        let res = await queryMiniAppDetail({ mini_app_id });
        if (res.success) {
            this.setState({
                mini_app: res.result.mini_app
            }, () => {
                this.getBase64ImageUrl()
            })
        }
    }
    onShowPageChange = (page) => {
        this.setState({
            page: page
        }, () => {
            this.queryCouponList(null)
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.queryCouponList(null)
        })
    }
    goToCoupon = (coupon_id) => {
        if (coupon_id) {
            this.context.router.history.push(`/coupon?coupon_id=${coupon_id}`)
        } else {
            this.context.router.history.push("/coupon")
        }
    }

    downLoadPost = () => {
        window.pageYOffset = 0;
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        html2canvas(document.getElementById('post'), {
            useCORS: true, // 【重要】开启跨域配置
            taintTest: false,//是否在渲染前测试图片,
            dpi: 192,
            scale: 8,
        }).then(function (canvas) {
            var context = canvas.getContext('2d');
            context.mozImageSmoothingEnabled = false;
            context.webkitImageSmoothingEnabled = false;
            context.msImageSmoothingEnabled = false;
            context.imageSmoothingEnabled = false;
            var imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // 获取生成的图片的url
            var a = document.createElement("a");
            a.href = imgUri;
            a.download = 'qrcode.png';
            a.click();
        })

    }
    showPost = (coupon) => {
        this.getPostQrCode(coupon.coupon_id, 1)
        this.getPostQrCode(coupon.coupon_id, null)
        this.setState({
            coupon,
        })
    }
    postCancel = () => {
        this.setState({
            visible: false
        })
    }

    async getPostQrCode(coupon_id, receive) {
        let res = await getPostQrCode(cookie.load("shop_id"), coupon_id, receive);
        if (receive) {
            this.setState({
                url: res,
                visible: true
            })
        } else {
            this.setState({
                qr_url: res,
                visible: true
            })
        }

    }

    async getBase64ImageUrl() {
        let res = await getBase64ImageUrl(this.state.mini_app.logo);
        this.setState({
            logo_url: res
        })
    }

    deleteCouponList = () => {
        this.setState(preState => ({
            page: 1,
            page_size: 10,
            delete: !preState.delete

        }), () => {
            this.queryCouponList()
        })

    }
    copy = (value) => {
        copy(value)
        message.success('复制成功')
    }

    componentDidMount() {
        this.queryCouponList(null)
        this.queryMiniAppDetail()
    }
    clear = () => {
        this.props.form.resetFields();
    }

    render() {
        let { menu_code } = this.props
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="page coupon-manage">
                <NavBar icon="icon-youhuiquan"
                    child={menu_code.coupon_manage}
                />
                <div className="content">
                    <div className="filter-content">
                        <Form>
                            <Form.Item label="优惠券名称/id">
                                {getFieldDecorator('like_condition')(
                                    <Input className="form-input" placeholder="输入优惠券名称/id" />
                                )}
                            </Form.Item>
                            <Form.Item label="优惠券类型">
                                {getFieldDecorator('coupon_category')(
                                    <Select className="form-input">
                                        <Select.Option value="shop_coupon">全店通用券</Select.Option>
                                        <Select.Option value="goods_coupon">商品优惠券</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                            {
                                this.state.delete ? null : (
                                    <Form.Item label="状态" style={{ display: this.state.delete ? "none" : false }}>
                                        {getFieldDecorator('status')(
                                            <Select className="form-input">
                                                <Select.Option value="4">未开始</Select.Option>
                                                <Select.Option value="1">进行中</Select.Option>
                                                <Select.Option value="2">已过期</Select.Option>
                                                <Select.Option value="3">已停止</Select.Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                )
                            }
                        </Form>
                        <div className="action">
                            <Button type="primary" shape="round" onClick={this.queryCouponList}>筛选</Button>
                            <span className="text-note" onClick={this.clear}>清空筛选条件</span>
                        </div>
                    </div>
                    <div className="action">
                        {
                            this.state.delete ? null : (
                                <Button type="primary" shape="round"
                                    onClick={this.goToCoupon.bind(this, null)}>新建优惠券</Button>
                            )
                        }
                        <span className="text-note text-note-right" onClick={this.deleteCouponList}>
                            {
                                this.state.delete ? '返回优惠券' : '已删除优惠券记录'
                            }
                        </span>
                    </div>
                    <Table rowKey={(record, index) => index}
                        columns={this.state.delete ? this.state.delete_columns : this.state.columns}
                        dataSource={this.state.coupon_list}
                        pagination={false}
                        bordered={true}
                    />
                    <Pagination
                        className="pagenation"
                        onChange={this.onShowPageChange}
                        onShowSizeChange={this.onShowSizeChange}
                        defaultCurrent={this.state.page}
                        total={this.state.total}
                        showTotal={(total) => `共 ${total} 条`}
                        pageSizeOptions={["10", "30", "50", "100"]}
                        showSizeChanger
                    />
                </div>
                <Modal
                    className="coupon-post"
                    visible={this.state.visible}
                    footer={null}
                    onCancel={this.postCancel}
                    destroyOnClose={true}
                    okButtonProps={{ shape: 'round' }}
                    cancelButtonProps={{ shape: 'round' }}
                >
                    <Poster
                        left={
                            <CouponPoster
                                logo_url={this.state.logo_url}
                                mini_app={this.state.mini_app}
                                coupon={this.state.coupon}
                                url={this.state.url}
                            />
                        }
                        right={
                            <CouponButton
                                coupon={this.state.coupon}
                                qr_url={this.state.qr_url}
                                url={this.state.url}
                                downLoadPost={this.downLoadPost.bind(this)}
                                copy={this.copy.bind(this)}
                            />
                        }
                    >
                        {this.props.location.coupon_id ? (<i className="iconfont icon-xuanzhong1"></i>) : null}
                        <div id="title" className="coupon-post-title">
                            {this.props.location.coupon_id ? (<div>新建成功!</div>) : null}
                            快去把优惠券发放给粉丝吧！
                        </div>
                    </Poster>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode
    }
}
const CouponManageForm = Form.create({ name: 'coupon_manage_form' })(CouponManage);
export default connect(mapStateToProps)(CouponManageForm)
