import React from "react";
import "@css/topBar.scss";
import {
    getMiniAppList,
    bindMiniApp,
    switchApp,
    queryMchApplyList,
    queryAppApplyList,
    setDoMain,
    modifySignatrue
} from "@service/getData";
import { Dropdown, Menu, message, Form } from "antd";
import PropTypes from "prop-types";
import { getQueryString } from "@common/common";
import cookie from "react-cookies";
import config from '@config/config'

class TopBar extends React.Component {
    constructor() {
        super();
        this.state = {
            now_mini_app: { mini_app_name: "" },
            mini_app_list: [],
            visible: false,
            auth_type: 1,
            my_qrcode: config.baseUrl + "/qrCode/detail?shop_id=" + cookie.load("shop_id") + "&page=pages/home/index",

            modal_ok_text: "立即授权",
            modal_cancel_text: "微信官网注册",
            qrcode_url:
                ""
        };
        TopBar.contextTypes = {
            router: PropTypes.object.isRequired
        };
    }

    queryMchApplyList = async () => {
        this.setState({
            loading: true
        });
        let res = await queryMchApplyList({
            page_no: this.state.page_no,
            page_size: this.state.page_size
        });
        cookie.save("mch_apply_num", res.result.total)
        if (!res.result.total) {
            cookie.save("defaultSelectedKeys", "merchantManage")
            this.context.router.history.push("/merchantManage");
        } else {
            cookie.save("defaultSelectedKeys", "0")
        }
    }

    async getMiniAppList() {
        let res = await getMiniAppList();
        if (res.success) {
            let current_app_id = +cookie.load("mini_app_id"),
                mini_app_list = [...res.result.mini_app_list],
                now_mini_app = {
                    mini_app_name: "无店铺"
                };
            if (mini_app_list.length) {
                let arr = mini_app_list.filter(
                    item => current_app_id === item.mini_app_id
                );
                now_mini_app = arr.length ? arr[0] : mini_app_list[0];
                cookie.save("shop_id", now_mini_app.shop_id)
                cookie.save("mini_app_type", now_mini_app.mini_app_type)
                cookie.save("app_apply_id", now_mini_app.app_apply_id);
                cookie.save("default_community_id", now_mini_app.default_community_id);
                cookie.save("mini_app_length", mini_app_list.length);
            }
            this.setState({
                mini_app_list,
                now_mini_app
            });
        }
        cookie.save(
            "mini_app_id",
            (this.state.now_mini_app && this.state.now_mini_app.mini_app_id) || ""
        );
    }
    queryAppApplyList = async () => {
        this.setState({
            loading: true
        });
        let res = await queryAppApplyList({
            page_no: this.state.page_no,
            page_size: this.state.page_size
        });
        if (res.result.total) {
            cookie.save("mini_app_length", res.result.total);
        }
        this.queryMchApplyList()
    };
    modifySignatrue = async (mini_app_id) => {
        let data = {
            mini_app_id: mini_app_id
        }
        await modifySignatrue(data)
    }
    async bindMiniApp(auth_code) {
        let res = await bindMiniApp(
            config.component_appid,
            cookie.load("admin_user_id"),
            auth_code
        );

        if (res.success) {
            message.success("成功绑定新店铺");
            this.setDoMain(res.result.mini_app_id);
            this.modifySignatrue(res.result.mini_app_id);
            this.getMiniAppList();
            this.queryAppApplyList()

        } else {
            message.error("绑定店铺失败");
        }
    }

    async setDoMain(mini_app_id) {
        let data = {
            mini_app_id: mini_app_id,
        };
        let res = await setDoMain(data);
        if (!res.success) {
            message.error("配置域名失败");
        }
    }

    handleModifyPassword = e => {
        this.context.router.history.push("/modifyPassword");
    };
    handleLoginOut = e => {
        cookie.remove();
        this.context.router.history.push("/login");
    };

    componentDidMount() {
        this.getMiniAppList();
        let auth_code = getQueryString("auth_code");
        if (auth_code) {
            this.bindMiniApp(auth_code);
        }
    }

    async changeShop(item) {
        if (this.state.now_mini_app.mini_app_id === item.mini_app_id) {
            return;
        }
        let res = await switchApp({
            admin_user_id: cookie.load("admin_user_id"),
            mini_app_id: item.mini_app_id
        });
        if (res.success) {
            cookie.save("mini_app_id", item.mini_app_id);
            cookie.save("shop_id", item.shop_id);
            cookie.save("mini_app_type", item.mini_app_type);
            cookie.save("app_apply_id", item.app_apply_id);
            cookie.save("default_community_id", item.default_community_id);
            this.setState({
                now_mini_app: item
            })
        }
        if (this.context.router.route.location.pathname === '/dashboard') {
            window.history.go(0);
        } else {
            this.context.router.history.push("/dashboard");
        }
    }

    imgError = () => {
        message.error("未发布正式小程序，无法获取")
    }
    render() {
        return (
            <div className="topBar">
                <div className="topbar-item topbar-login-item">
                    {/* <img alt=""
                        className="dashboard-logo"
                        src={require("../../../images/bg_logo.png")}
                        height="30"
                    /> */}
                    <div className="topbar-logo">PLEGO</div>
                    <div className="topbar-name">官方商城管理后台</div>
                </div>
                {/* <Dropdown
                    overlay={
                        <Menu>
                            {this.state.mini_app_list.map((item, index) => (
                                <Menu.Item
                                    onClick={this.changeShop.bind(this, item)}
                                    className={
                                        this.state.now_mini_app &&
                                            item.mini_app_name === this.state.now_mini_app.mini_app_name
                                            ? "nowShop"
                                            : null
                                    }
                                    key={index}
                                >
                                    {item.mini_app_name}
                                </Menu.Item>
                            ))}
                            {this.state.mini_app_list.length === 0 ? (<Menu.Item>暂无店铺</Menu.Item>) : null
                            }
                        </Menu>
                    }
                    placement="bottomLeft"
                >
                    <div className="topbar-item topbar-dianpu-item">
                        <label className="label-icon-dianpu">
                            {
                                cookie.load("mini_app_type") === "flagship_version" ? (<i className="iconfont icon-viphuiyuanhuangguan"></i>) : null
                            }
                            {
                                cookie.load("mini_app_type") === "special_version" ? (<i className="iconfont icon-dianpu"></i>) : null
                            }
                            {
                                cookie.load("mini_app_type") === "base_version" ? (<i className="iconfont icon-wo"></i>) : null
                            }
                            {
                                cookie.load("mini_app_type") ? null : (<i className="iconfont icon-dianpu1"></i>)
                            }

                        </label>
                        当前店铺：
                        {this.state.now_mini_app && this.state.now_mini_app.mini_app_name}
                    </div>
                </Dropdown> */}
                <Dropdown
                    overlay={<img alt="" src={this.state.my_qrcode} onError={this.imgError} width="124" height="129" />}
                    placement="bottomLeft"
                >
                    <div className="topbar-item topbar-qrcode-item">
                        <i className="iconfont icon-xiaochengxu" />
                        <span>查看当前店铺二维码</span>
                    </div>
                </Dropdown>
                <div>
                    <Dropdown
                        overlay={
                            <Menu>
                                {this.state.mini_app_list.map((item, index) => (
                                    <Menu.Item
                                        onClick={this.changeShop.bind(this, item)}
                                        className={
                                            this.state.now_mini_app &&
                                                item.mini_app_name === this.state.now_mini_app.mini_app_name
                                                ? "nowShop"
                                                : null
                                        }
                                        key={index}
                                    >
                                        {item.mini_app_name}
                                    </Menu.Item>
                                ))}
                                {this.state.mini_app_list.length === 0 ? (<Menu.Item>暂无店铺</Menu.Item>) : null
                                }
                            </Menu>
                        }
                        placement="bottomLeft"
                    >
                        <div className="topbar-item topbar-dianpu-item">
                            当前店铺：
                            {this.state.now_mini_app && this.state.now_mini_app.mini_app_name}
                        </div>
                    </Dropdown>
                    <Dropdown
                        overlay={
                            <Menu>
                                <Menu.Item onClick={this.handleModifyPassword}>修改密码</Menu.Item>
                                <Menu.Item onClick={this.handleLoginOut}>退出登录</Menu.Item>
                            </Menu>
                        }
                        placement="bottomLeft"
                    >
                        <div className="topbar-item topbar-account-item">
                            <i className="iconfont icon-ren" />
                            <span>管理员{cookie.load("user_name")}</span>
                        </div>
                    </Dropdown>
                </div>
            </div>
        );
    }
}

const TopFormBar = Form.create({ name: "bind_mini_app_form" })(TopBar);
export default TopFormBar;
