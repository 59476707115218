import React from "react";
import { Form, Input, Select, DatePicker, Button, Table, Pagination, message, Icon, Modal, Tabs, Spin } from "antd";
import { queryAdminRefundOrderList, shopApprovalRefundOrder } from "@service/getData";
import cookie from "react-cookies";
import "@css/afterSaleHadle.scss";
import PropTypes from "prop-types";
import config from "@config/config";
import RefundOrderMoneyModal from "../common/RefundOrderMoneyModal";
import { connect } from "react-redux";
class AfterSaleHandle extends React.Component {
	constructor(props) {
		super(props);
		AfterSaleHandle.contextTypes = {
			router: PropTypes.object.isRequired
		};
		this.state = {
			request_url: config.baseUrl + "/refundOrder/exportAdminRefundOrderList",
			page_size: 10,
			page: 1,
			total: 0,
			status: 0,
			columns: [
				{
					title: "商品",
					dataIndex: "goods_name",
					width: "30%",
					render: (value, row, index) => {
						let obj = {
							children: value,
							props: {}
						};
						if (row.children) {
							obj.children = (
								<div className="table-order-info">
									<span>售后订单号：{row.refund_order_sn}</span>
									<span>订单号：{row.order_sn}</span>
									<span>微信商户订单号：{row.order_res.transaction_id}</span>
								</div>
							);
							obj.props.colSpan = 7;
							obj.props.className = "row-header";
						} else {
							obj.children = (
								<div className="order-goods-info">
									<img alt="" width="60" height="60" src={row.thumbnail_url} className="goods-image"></img>
									<div className="goods-name-sku">
										<div className="order-goods-name">{row.goods_name}</div>
										<div className="sku">{row.sku}</div>
									</div>
								</div>
							);
						}
						return obj;
					}
				},
				{
					title: "发货状态",
					dataIndex: "refund_shipment_id",
					width: "7.47%",
					render: (value, row) => {
						let obj = {
							children: (
								<div>
									<p>{value ? '已发货' : '未发货'}</p>
								</div>
							),
							props: {}
						};
						if (row.children) {
							obj.props.colSpan = 0;
						}
						return obj;
					}
				},
				{
					title: "订单金额",
					dataIndex: "pay_amount",
					width: "7.56%",
					render: (value, row) => {
						let obj = {
							children: value,
							props: {}
						};
						if (row.children) {
							obj.props.colSpan = 0;
						} else if (row.rowSpan != null) {
							obj.props.rowSpan = row.rowSpan;
						}
						return obj;
					}
				},
				{
					title: "退款金额",
					dataIndex: "refund_amount",
					width: "7.56%",
					render: (value, row) => {
						let obj = {
							children: value,
							props: {}
						};
						if (row.children) {
							obj.props.colSpan = 0;
						} else if (row.rowSpan != null) {
							obj.props.rowSpan = row.rowSpan;
						}
						return obj;
					}
				},
				{
					title: "下单时间",
					dataIndex: "create_at",
					width: "7.56%",
					render: (value, row) => {
						let obj = {
							children: value,
							props: {}
						};
						if (row.children) {
							obj.props.colSpan = 0;
						} else if (row.rowSpan != null) {
							obj.props.rowSpan = row.rowSpan;
						}
						return obj;
					}
				},
				{
					title: "售后状态",
					dataIndex: "order_status_desc",
					width: "7.56%",
					render: (value, row) => {
						let obj = {
							children: value,
							props: {}
						};
						if (row.children) {
							obj.props.colSpan = 0;
						} else if (row.rowSpan != null) {
							obj.props.rowSpan = row.rowSpan;
						}
						return obj;
					}
				},
				{
					title: "操作",
					width: "12.6%",
					dataIndex: "action",
					render: (value, row) => {
						let obj = {
							children:
								Number(this.state.status) === 0 ? (
									<div className="table-action">
										<p className="text-note" onClick={this.gotoOrderDetail.bind(this, row.refund_order_id)}>
											查看详情
                    					</p>
										<p className="text-note" onClick={this.showModal.bind(this, row)}>
											退款
                    					</p>
									</div>
								) : (
										<div className="table-action">
											<p className="text-note" onClick={this.gotoOrderDetail.bind(this, row.refund_order_id)}>
												查看结果
                    						</p>
										</div>
									),
							props: {}
						};
						if (row.children) {
							obj.props.colSpan = 0;
						} else if (row.rowSpan != null) {
							obj.props.rowSpan = row.rowSpan;
						}
						return obj;
					}
				}
			],
			refund_order_res_list: [],
			refund_list: [],
			visible: false,
			refund_order: {
				refund_order_goods_list: []
			},
			loading: false,
			order_by_condition: 'time_sort_asc',
		};
	}

	onShowTimeChange = (date, dateStr) => {
		this.setState({
			start_order_time: dateStr[0],
			end_order_time: dateStr[1]
		});
	};
	onShowSizeChange = (current, pageSize) => {
		this.setState({
			page_size: pageSize
		});
		this.filterOrder();
	};
	onShowPageChange = page => {
		this.setState(
			{
				page: page
			},
			() => {
				this.filterOrder();
			}
		);
	};
	filterOrder = () => {
		if (!cookie.load("shop_id") && Number(cookie.load("is_admin")) === 1) {
			return;
		}
		let form = this.props.form.getFieldsValue();
		let data = {
			shop_id: cookie.load("shop_id"),
			status: this.state.status,
			page_no: this.state.page,
			page_size: this.state.page_size,
			// order_by_condition: this.state.order_by_condition,
		};
		if (form.order_sn) {
			data.order_sn = form.order_sn;
		}
		if (form.refund_order_sn) {
			data.refund_order_sn = form.refund_order_sn;
		}
		if (form.transaction_id) {
			data.transaction_id = form.transaction_id;
		}
		if (form.goods_name) {
			data.goods_name = form.goods_name;
		}
		if (form.order_shipment_type) {
			data.order_shipment_type = form.order_shipment_type;
		}
		if (this.state.start_order_time) {
			data.start_order_time = this.state.start_order_time;
		}
		if (this.state.end_order_time) {
			data.end_order_time = this.state.end_order_time;
		}
		this.queryAdminRefundOrderList(data);
	};
	clearFilter = () => {
		this.props.form.resetFields();
	};

	async queryAdminRefundOrderList(data) {
		let res = await queryAdminRefundOrderList(data);
		if (res.success) {
			res.result.refund_order_res_list.forEach(item => {
				let refund_list = [];
				item.refund_order_sn_key = item.refund_order_sn;  // 唯一的key，不做UI展示
				item.refund_goods_res_list.forEach((goods, index) => {
					let order = {
						thumbnail_url: goods.goods_res.thumbnail_url,
						order_id: item.order_res.order_id,
						order_sn: item.order_res.order_sn,
						sku: "",
						transaction_id: item.order_res.transaction_id,
						pay_time: item.order_res.pay_time,
						order_status: item.order_res.address_res.order_status,
						receiver_name: item.order_res.address_res.receiver_name,
						tel_number: item.order_res.tel_number,
						order_status_desc: this.props.refund_status_list[item.refund_status].value,
						pay_amount: item.order_res.pay_amount / 100,
						nick_name: item.order_res.user_res.nick_name,
						mobile: item.order_res.user_res.mobile,
						shipment_type: item.order_res.shipment_type,
						quantity: goods.quantity,
						refund_order_sn: item.refund_order_sn,
						refund_order_sn_key: item.refund_order_sn + '' + index, // 唯一的key，不做UI展示
						refund_order_id: item.refund_order_id,
						shipment_fee: item.order_res.shipment_fee / 100,
						discount_amount: item.order_res.discount_amount / 100,
						original_price: (item.order_res.pay_amount + item.order_res.discount_amount - item.order_res.shipment_fee) / 100,
						refund_amount: item.refund_amount / 100,
						already_refund_amount: item.already_refund_amount / 100,
						refund_ship_fee: item.refund_ship_fee / 100,
						refund_shipment_id: item.refund_shipment_id,
						create_at: item.create_at,
					};
					order.goods_name = goods.goods_res.goods_name;
					order.current_price = goods.goods_res.current_price / 100;
					goods.sku_res.attribute_value_res_list.forEach(qitem => {
						order.sku += qitem.attribute_value;
					});
					if (index === 0) {
						order.rowSpan = item.refund_goods_res_list.length;
						order.refund_order_goods_list = item.refund_goods_res_list.map((qgoods, qindex) => {
							let _order = {
								order_id: item.order_res.order_id,
								order_sn: item.order_res.order_sn,
								sku: "",
								pay_time: item.order_res.pay_time,
								order_status: item.order_res.order_status,
								order_status_desc: this.props.refund_status_list[item.order_res.order_status],
								pay_amount: item.order_res.pay_amount / 100,
								nick_name: item.order_res.user_res.nick_name,
								mobile: item.order_res.user_res.mobile,
								shipment_type: item.order_res.shipment_type,
								quantity: qgoods.quantity,
								refund_order_sn: item.refund_order_sn,
								refund_order_sn_key: item.refund_order_sn + '' + qindex, // 唯一的key，不做UI展示
								refund_order_id: item.refund_order_id,
								in_cur_refund_order: qgoods.in_cur_refund_order,
							};
							_order.goods_name = qgoods.goods_res.goods_name;
							_order.current_price = qgoods.sku_res.current_price / 100;
							qgoods.sku_res.attribute_value_res_list.forEach(qitem => {
								_order.sku += qitem.attribute_value;
							});
							return _order;
						});
					} else {
						order.rowSpan = 0;
					}
					refund_list.push(order);
				});
				item.children = refund_list;
			});
			this.setState({
				refund_order_res_list: [],
			}, () => {
				this.setState({
					refund_order_res_list: res.result.refund_order_res_list,
					total: res.result.total,
					page: res.result.page_no,
					page_size: res.result.page_size,
					loading: false
				});
			});
			window.scrollTo(0, 0);
		}
	}
	async shopApprovalRefundOrder(data) {
		let res = await shopApprovalRefundOrder(data);
		if (res.success) {
			if (res.result.wx_err_code === "FAIL") {
				Modal.info({
					title: "温馨提示",
					okText: "我知道了",
					content: (
						<div>
							<p>您还未授权退款功能，请登录微信支付商户平台：https://pay.weixin.qq.com，将退款功能授权给DTC Mall后再次进行退款操作。</p>
							<p> 授权路径：产品中心→我授权的产品→将“服务商API退款”功能授权给服务商</p>
						</div>
					),
					onOk() { }
				});
			} else if (res.result.wx_err_code === "NOTENOUGH") {
				Modal.info({
					title: "温馨提示",
					okText: "我知道了",
					content: (
						<div>
							<p>您的账户余额不足，请确认微信支付账户金额大于退款金额后再次进行退款操作。微信支付商户平台：https://pay.weixin.qq.com。</p>
							<p>为确保正常退款，建议在微信支付账户保留部分金额以供退款。</p>
						</div>
					),
					onOk() { }
				});
			} else if (res.result.wx_err_code === "ERROR") {
				Modal.info({
					title: "温馨提示",
					okText: "我知道了",
					content: <div>{res.result.wx_err_code_des}</div>,
					onOk() { }
				});
			} else {
				message.success("退款成功");
			}
			this.filterOrder();
			this.setState({
				visible: false
			});
		} else {
			message.error(res.msg);
		}
	}
	gotoOrderDetail = refund_order_id => {
		this.context.router.history.push(`/refundOrderDetail?refund_order_id=${refund_order_id}`);
	};
	gotoCustomOrderDetail = order_id => {
		window.open(`${window.location.origin}/orderDetail?order_id=${order_id}&type=orderManage`, "_blank");
	};
	handleOk = () => {
		let data = {
			refund_order_id: this.state.refund_order.refund_order_id,
			shop_id: cookie.load("shop_id")
		};
		this.shopApprovalRefundOrder(data);
	};
	handleCancel = () => {
		this.setState({
			visible: false
		});
	};
	showModal = data => {
		this.setState({
			visible: true,
			refund_order: data
		});
	};
	onExport = () => {
		let form = this.props.form.getFieldsValue();
		let url = this.state.request_url + "?shop_id=" + cookie.load("shop_id");
		if (form.order_shipment_type && form.order_shipment_type !== "all") {
			url += "&order_shipment_type=" + form.order_shipment_type;
		}
		url += "&status=" + this.state.status;
		if (form.order_sn) {
			url += "&order_sn=" + form.order_sn;
		}
		if (form.transaction_id) {
			url += "&transaction_id=" + form.transaction_id;
		}
		if (form.refund_order_sn) {
			url += "&refund_order_sn=" + form.refund_order_sn;
		}
		if (this.state.start_order_time) {
			url += "&start_order_time=" + this.state.start_order_time;
		}
		if (this.state.end_order_time) {
			url += "&end_order_time=" + this.state.end_order_time;
		}
		window.open(url);
	};
	componentDidMount() {
		this.filterOrder();
	}
	changeTabs = status => {
		this.setState(
			{
				loading: true,
				status: status === 'null' ? null : +status,
				page: 1,
				page_size: 10,
			},
			() => {
				this.filterOrder();
			}
		);
	};
	// onTableChange = (pages, filters, sort) => {
	// 	this.setState({
	// 		order_by_condition: sort.order === 'descend' ? 'time_sort_desc' : 'time_sort_asc',
	// 	}, () => {
	// 		this.filterOrder();
	// 	});
	// }
	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<div className="after-sale-content">
				<Spin tip="加载中..." spinning={this.state.loading}>
					<div className="filter-content">
						<Form className="ant-vertical" layout="inline">
							<Form.Item label="订单号">
								{getFieldDecorator("order_sn")(<Input className="form-input" placeholder="输入订单号" />)}
							</Form.Item>
							<Form.Item label="售后订单号">
								{getFieldDecorator("refund_order_sn")(<Input className="form-input" placeholder="输入订单号" />)}
							</Form.Item>
							<Form.Item label="下单时间">
								{getFieldDecorator("date")(
									<DatePicker.RangePicker
										className="form-input-long"
										onChange={this.onShowTimeChange}
										showTime
										prefix={<Icon type="contacts" style={{ color: "rgba(0,0,0,.25)" }} />}
									/>
								)}
							</Form.Item>
							<Form.Item label="配送类型：">
								{getFieldDecorator("order_shipment_type")(
									<Select className="form-input">
										<Select.Option value="all">全部</Select.Option>
										<Select.Option value="group_shipment">自提订单</Select.Option>
										<Select.Option value="self_shipment">邮寄订单</Select.Option>
									</Select>
								)}
							</Form.Item>
							<Form.Item label="商品名称">
								{getFieldDecorator("goods_name")(<Input className="form-input-long" placeholder="输入商品名称" />)}
							</Form.Item>
							<Form.Item label="微信商户号订单">
								{getFieldDecorator("transaction_id")(<Input className="form-input" placeholder="微信商户号订单" />)}
							</Form.Item>
						</Form>
						<div className="action">
							<Button type="primary" shape="round" onClick={this.onShowPageChange.bind(this, 1)}>
								筛选
              </Button>
							<Button shape="round" onClick={this.onExport}>
								导出
              </Button>
							<span className="text-note" onClick={this.clearFilter}>
								清空筛选条件
              </span>
						</div>
					</div>
					<div className="action">
						<Tabs defaultActiveKey="0" onChange={this.changeTabs} tabBarStyle={{ backgroundColor: "#ffffff", borderRadius: "4px" }}>
							<Tabs.TabPane tab="全部" key="null"></Tabs.TabPane>
							<Tabs.TabPane tab="待商家处理" key="0"></Tabs.TabPane>
							<Tabs.TabPane tab="已完成" key="1"></Tabs.TabPane>
						</Tabs>
					</div>
					{this.state.refund_order_res_list && this.state.refund_order_res_list.length ? (
						<Table
							rowKey={(row, index) => row.refund_order_sn_key}
							columns={this.state.columns}
							dataSource={this.state.refund_order_res_list}
							pagination={false}
							defaultExpandAllRows={true}
							expandIconAsCell={false}
							expandIconColumnIndex={-1}
						// onChange={this.onTableChange}
						/>
					) : null}
					<Pagination
						className="pagenation"
						current={this.state.page}
						defaultCurrent={1}
						pageSize={this.state.page_size}
						onChange={this.onShowPageChange}
						onShowSizeChange={this.onShowSizeChange}
						total={this.state.total}
						showTotal={total => `共 ${total} 条`}
						pageSizeOptions={["10", "30", "50", "100"]}
						showSizeChanger
					/>
					<RefundOrderMoneyModal
						visible={this.state.visible}
						handleOk={this.handleOk}
						handleCancel={this.handleCancel}
						refund_amount={this.state.refund_order.refund_amount}
						already_refund_amount={this.state.refund_order.already_refund_amount}
						refund_ship_fee={this.state.refund_order.refund_ship_fee}
						order_goods_list={this.state.refund_order.refund_order_goods_list}
						pay_amount={this.state.refund_order.pay_amount}
						discount_amount={this.state.refund_order.discount_amount}
						shipment_fee={this.state.refund_order.shipment_fee}
						original_price={this.state.refund_order.original_price}
					/>
				</Spin>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		menu_code: state.menuCode,
		refund_status_list: state.refundStatusList
	};
};

const afterSaleHandleForm = Form.create({ name: "after_sale_handle_form" })(AfterSaleHandle);

export default connect(mapStateToProps)(afterSaleHandleForm);
