import React from 'react'
import {Form,Input,Button,message} from 'antd'
import '@css/modifypassword.scss'
import NavBar from "@/view/component/common/NavBar.js";
import { connect } from "react-redux";
import {updatePassword} from '@service/getData'
import cookie from 'react-cookies'
import PropTypes from "prop-types";
class  ModifyPassword extends React.Component{
    constructor(props) {
        super();
        ModifyPassword.contextTypes = {
            router: PropTypes.object.isRequired
        };
    }

    async updatePassword(data){
        let res =await updatePassword(data);
        if(res.success){
           message.success("密码修改成功")
            this.props.form.resetFields();
            this.context.router.history.push("/login");
        }else{
            message.error(res.msg);
        }

    }
    submit=()=>{
        this.props.form.validateFields(
            (err) => {
                if (!err) {
                    let form=this.props.form.getFieldsValue();
                    if(form.new_password!==form.again_new_password){
                       return  message.error("两次密码输入不同")
                    }
                    let data={
                        admin_user_id:cookie.load("admin_user_id"),
                        old_password:form.old_password,
                        new_password:form.new_password
                    }
                    this.updatePassword(data)
                }
            },
        );

    }

    render(){
        const {getFieldDecorator} = this.props.form;
        let { menu_code } = this.props;
        return (
           <div className="modify_password">
             <NavBar icon="icon-shezhi" father={menu_code.setting} child={menu_code.modify_password}/>
               <div className="content">
                   <Form>
                       <Form.Item label="旧密码">
                           {getFieldDecorator("old_password", {
                               rules: [
                                   {
                                       required: true,
                                       message: "请输入旧密码！"
                                   }
                               ],
                           })(<Input type="password"placeholder="请输入旧密码" />)}

                       </Form.Item>
                       <Form.Item label="新密码">
                           {getFieldDecorator("new_password", {
                               rules: [
                                   {
                                       required: true,
                                       message: "请输入新密码！"
                                   }
                               ],
                           })(<Input type="password" placeholder="请输入新密码" />)}

                       </Form.Item>
                       <Form.Item label="确认密码">
                           {getFieldDecorator("again_new_password", {
                               rules: [
                                   {
                                       required: true,
                                       message: "请再次输入新密码！"
                                   }
                               ],
                           })(<Input type="password" placeholder="请再次输入新密码" />)}
                       </Form.Item>
                       <Form.Item>
                           <Button type="primary" shape="round" onClick={this.submit}>提交</Button>
                       </Form.Item>
                   </Form>
               </div>
           </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        menu_code: state.menuCode
    };
};

const ModifyPasswordForm = Form.create({name: "modify_password_form"})(ModifyPassword);
export default  connect(mapStateToProps) (ModifyPasswordForm)