import { Button, Checkbox, Icon, Input, message, Tooltip, Tag } from 'antd';
import React, { Component } from 'react';
import '../scss/gradeManage.scss';
import { getData } from '@service/getData';
import cookie from 'react-cookies';
import CouponSelectModal from "@/view/component/Campaign/CouponSelectModal";

class Task extends Component {
    constructor() {
        super();
        this.state = {
            infoChecked: false,
            infoGrowth: null,
            infoId: null,
            commodityChecked: false,
            commodityThreshold: null,
            commodityGrowth: null,
            commodityId: null,
            orderChecked: false,
            orderThreshold: null,
            orderGrowth: null,
            orderId: null,
            coupon_select_visible: false,
            coupon: {},
        };
    }

    componentDidMount() {
        this.init();
    }

    async init() {
        const url = '/vip/task/list';
        const data = {
            mini_app_id: cookie.load('mini_app_id'),
        };
        let res = await getData(url, data);
        if (res.success && res.result && res.result.length) {
            const { result } = res;
            this.setState({
                infoChecked: result[0].status === 1,
                infoGrowth: result[0].reward_value,
                infoId: result[0].task_id,
                coupon: result[0].reward_coupon || {},
                commodityChecked: result[1].status === 1,
                commodityThreshold: result[1].condition_amount / 100,
                commodityGrowth: result[1].reward_value,
                commodityId: result[1].task_id,
                orderChecked: result[2].status === 1,
                orderThreshold: result[2].condition_amount,
                orderGrowth: result[2].reward_value,
                orderId: result[2].task_id,
            });
        }
    }
    onInputChange(attr, e) {
        this.setState({
            [attr]: +e.target.value,
        });
    }
    onCheckChange(attr, e) {
        this.setState({
            [attr]: e.target.checked,
        });
    }
    async onSave() {
        const url = '/vip/task/updateList';
        const data = {
            mini_app_id: cookie.load('mini_app_id'),
            taskList: [
                {
                    task_id: this.state.infoId,
                    reward_value: this.state.infoGrowth,
                    condition_amount: null,
                    reward_id: this.state.coupon.coupon_id,
                    status: this.state.infoChecked ? 1 : 2,
                },
                {
                    task_id: this.state.commodityId,
                    reward_value: this.state.commodityGrowth,
                    condition_amount: this.state.commodityThreshold * 100,
                    status: this.state.commodityChecked ? 1 : 2,
                },
                {
                    task_id: this.state.orderId,
                    reward_value: this.state.orderGrowth,
                    condition_amount: this.state.orderThreshold,
                    status: this.state.orderChecked ? 1 : 2,
                },
            ],
        };
        let res = await getData(url, data);
        if (res.success) {
            message.success('保存成功');
        }
    }
    reset() {
        this.init();
        message.warn('已重置设置');
    }

    onCouponOk = (list) => {
        this.setState({
            coupon: list[0],
        });
        this.changeCouponModal();
    }

    changeCouponModal = () => {
        this.setState((preState) => ({
            coupon_select_visible: !preState.coupon_select_visible,
        }));
    }

    deleteCoupon = () => {
        this.setState({
            coupon: {},
        });
    }

    render() {
        return (
            <div className="task">
                <div className="title">
                    <div className="bar"></div>
                    <span className="text">成长值任务</span>
                    <Tooltip title="完成以下任务可以获得成长值" placement="right">
                        <Icon style={{ fontSize: '12px', color: '#0D87EB' }} type="question-circle" theme="filled" />
                    </Tooltip>
                </div>
                <div className="taskWrap">
                    <div className="taskItem">
                        <Checkbox style={{ fontWeight: 'bolder' }} checked={this.state.infoChecked} onChange={this.onCheckChange.bind(this, 'infoChecked')}>
                            完善信息
                        </Checkbox>
                        <div className="detail">
                            <span>完成后赠送：</span>
                            <Input className="detailInput" value={this.state.infoGrowth} onChange={this.onInputChange.bind(this, 'infoGrowth')} />
                            <span>成长值,</span>
                            <span className="text-note" onClick={this.changeCouponModal}>添加优惠券</span>
                            {this.state.coupon.coupon_name ? (
                                <div className="couponTagWrap">
                                    <Tag
                                        closable={true}
                                        onClose={this.deleteCoupon}
                                    >
                                        满{this.state.coupon.use_min_amount / 100}减{this.state.coupon.discount_amount / 100}&#160;&#160;{this.state.coupon.coupon_name}
                                    </Tag>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="taskItem">
                        <Checkbox style={{ fontWeight: 'bolder' }} checked={this.state.commodityChecked} onChange={this.onCheckChange.bind(this, 'commodityChecked')}>
                            购买商品
                            <Tooltip title="确认收货后发放成长值" placement="right">
                                <Icon style={{ fontSize: '12px', color: '#0D87EB', verticalAlign: 'revert' }} type="question-circle" theme="filled" />
                            </Tooltip>
                        </Checkbox>
                        <div className="detail">
                            <span>每消费</span>
                            <Input className="detailInput" value={this.state.commodityThreshold} onChange={this.onInputChange.bind(this, 'commodityThreshold')} />
                            <span>元，获得</span>
                            <Input className="detailInput" value={this.state.commodityGrowth} onChange={this.onInputChange.bind(this, 'commodityGrowth')} />
                            <span>成长值</span>
                        </div>
                    </div>
                    <div className="taskItem">
                        <Checkbox style={{ fontWeight: 'bolder' }} checked={this.state.orderChecked} onChange={this.onCheckChange.bind(this, 'orderChecked')}>
                            完成下单
                            <Tooltip title="确认收货后发放成长值" placement="right">
                                <Icon style={{ fontSize: '12px', color: '#0D87EB', verticalAlign: 'revert' }} type="question-circle" theme="filled" />
                            </Tooltip>
                        </Checkbox>
                        <div className="detail">
                            <span>每完成</span>
                            <Input className="detailInput" value={this.state.orderThreshold} onChange={this.onInputChange.bind(this, 'orderThreshold')} />
                            <span>笔订单，获得</span>
                            <Input className="detailInput" value={this.state.orderGrowth} onChange={this.onInputChange.bind(this, 'orderGrowth')} />
                            <span>成长值</span>
                        </div>
                    </div>
                </div>
                <div className="btnWrap">
                    <Button shape="round" type="primary" onClick={this.onSave.bind(this)}>保存</Button>
                    <Button style={{ marginLeft: '12px' }} shape="round" onClick={this.reset.bind(this)}>取消</Button>
                </div>
                <CouponSelectModal
                    visible={this.state.coupon_select_visible}
                    onCancel={this.changeCouponModal}
                    onOk={this.onCouponOk}
                    single={true}
                />
            </div>
        );
    }
}

export default Task;