import React from 'react'
import '@css/popUppromotion.scss'

class PopUpCoupon extends React.Component {

    render() {
        return this.props.style===0?(
            <div
                className={`pop-up-coupon ${this.props.coupon_list.length < 2 ? "pop-up-coupon-less" : "pop-up-coupon-more"}`}>
                {
                    this.props.coupon_list.length<2?(
                        this.props.coupon_list.map(item=>(
                                <div className="coupon" key={item.coupon_id}>
                                    <div className="coupon-content">
                                        <div className='discount_amount' title={item.discount_amount/100}>{item.discount_amount/100}</div>
                                        <div className="coupon-type-money">
                                            <div className="coupon-type">满减券</div>
                                            <div className="coupon-money" title={`满${item.use_min_amount/100}元可用`}>满{item.use_min_amount/100}元可用</div>
                                        </div>
                                    </div>
                                    <div className="coupon-btn">立即使用</div>
                                </div>
                            )
                        )
                    ):(
                        <div className={`coupon-list ${this.props.coupon_list.length===2?"coupon-list-tow":null}`}>
                            {
                                this.props.coupon_list.map(item=>(
                                        <div className="coupon" key={item.coupon_id}>
                                            <div className="coupon-content">
                                                <div className='discount_amount' title={item.discount_amount/100}>{item.discount_amount/100}</div>
                                                <div className="coupon-type-money">
                                                    <div className="coupon-type">满减券</div>
                                                    <div className="coupon-money" title={`满${item.use_min_amount/100}元可用`}>满{item.use_min_amount/100}元可用</div>
                                                </div>
                                            </div>
                                            <div className="coupon-btn">立即使用</div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                    )
                }
            </div>
        ):(
            <img alt="" className="coupon-ad"src={this.props.image_url}/>
        )
    }
}

export default PopUpCoupon
