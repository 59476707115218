import React from 'react'
import {Form,Button,Input,DatePicker,Table,Pagination,message} from 'antd'
import {queryGroupShipmentOrderList} from '@service/getData'
import cookie from 'react-cookies'
import {connect} from "react-redux";
class  OrderHistory extends  React.Component{
    constructor(){
        super();
        this.state={
            order_history_list:[],
            columns:[{

                    title: '订单号',
                    dataIndex: 'order_sn',
                },{

                    title: '买家微信名',
                    dataIndex: 'nick_name'
            }

                ,{

                    title: '收件人',
                    dataIndex: 'receiver_name',
                },{

                    title: '电话',
                    dataIndex: 'mobile',
                },{

                    title: '类型',
                    dataIndex: 'shipment_type',
                    render:(text)=>(
                        <p>{text==="group_shipment"?"自提":"邮寄"}</p>
                    )
                },{

                    title: '发货时间',
                    dataIndex: 'shipping_time',
                },{

                    title: '订单状态',
                    dataIndex: 'order_status',
                    render:(text)=>(
                        <p>{this.props.order_status_list[text].order_status_short_desc}</p>
                    )
                },{

                    title: '快递',
                    dataIndex: 'logistics',
                },{

                    title: '快递单号',
                    dataIndex: 'tracking_number',
                }

            ],
            group_columns:[{
                title: '配送单号',
                dataIndex: 'shipment_sn',
                render: (value, row)  => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.row_span!=null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },

            }, {

                title: '商品名称',
                dataIndex: 'goods_name',
            }, {

                title: '规格',
                dataIndex: 'sku',
            }, {
                title: '单价|数量',
                dataIndex: 'num',
                render: (text, record) => (
                    <div>
                        <p>¥{record.current_price / 100}</p>
                        <p>{record.quantity}件</p>
                    </div>
                )
            }, {

                title: '订单编号',
                dataIndex: 'order_sn',
                render: (value, row)  => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.order_goods_rowspan!=null) {
                        obj.props.rowSpan = row.order_goods_rowspan
                    }
                    return obj;
                },

            }, {

                title: '付款时间',
                dataIndex: 'pay_time',
                render: (value, row)  => {
                    let obj = {
                        children: value,
                        props: {},
                    };
                    if (row.order_goods_rowspan!=null) {
                        obj.props.rowSpan = row.order_goods_rowspan
                    }
                    return obj;
                },
            }, {

                title: '社区信息',
                dataIndex: 'comunity',
                render: (value, row)  => {
                    let obj = {
                        children: (
                            <div>
                                <p>{row.community_name}</p>
                                <p>{row.receiver_name}{row.mobile}</p>
                                <p>{row.address}</p>
                            </div>
                        ),
                        props: {},
                    };
                    if (row.row_span!=null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },
            }, {
                title: '类型',
                dataIndex: 'shipment_type',
                render: (value, row)  => {
                    let obj = {
                        children: (<p>{value === "group_shipment" ? "自提" : "邮寄"}</p>),
                        props: {},
                    };
                    if (row.row_span!=null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },
            } ,
                {
                title: '配送',
                dataIndex: 'shipment',
                render: (value, row)  => {
                    let obj = {
                        children: (
                            <div>
                                {row.logistics?(<p>{row.logistics}</p>):null}
                                {row.tracking_number?(<p>{row.tracking_number}</p>):null}
                                {row.vehicle_res?(<p>司机：{row.vehicle_res.driver_name}</p>):null}
                                {row.vehicle_res?(<p>{row.vehicle_res.driver_mobile}</p>):null}
                            </div>
                        ),
                        props: {},
                    };
                    if (row.row_span!=null) {
                        obj.props.rowSpan = row.row_span
                    }
                    return obj;
                },
            }
            ],
            order_shipment_type:"self_shipment",
            start_order_time:null,
            end_order_time:null,
            page_size:10,
            page:1,
            total:0,
        }
    }
    async queryGroupShipmentOrderList(data){
        let order_history_list=[]
        let res= await queryGroupShipmentOrderList(data);
        if(res.success){
            if(this.props.order_shipment_type==="self_shipment"){
                res.result.shipment_res_list.forEach(item=>{
                    let position=order_history_list.length;
                    item.order_res_list.forEach((order,index)=>{
                        let data={
                            logistics:item.logistics,
                            order_sn:order.order_sn,
                            receiver_name:item.receiver_name,
                            nick_name:order.user_res.nick_name,
                            mobile:item.mobile,
                            shipping_time:item.shipping_time,
                            order_status:order.order_status,
                            shipment_type:order.shipment_type,
                            shipment_sn:item.shipment_sn,
                            tracking_number:item.tracking_number
                        }

                        order_history_list.push(data)
                    })
                    order_history_list[position].row_span=item.order_res_list.length


                })
            }else{
                res.result.shipment_res_list.forEach(item=>{
                    let position=order_history_list.length;
                    let row_span=0;
                    item.order_res_list.forEach(order=>{
                        row_span+=order.order_goods_res_list.length
                        order.order_goods_res_list.forEach((goods,index)=>{
                            let data = {
                                logistics: item.logistics,
                                order_sn:order.order_sn,
                                pay_time:order.pay_time,
                                receiver_name: item.receiver_name,
                                mobile: item.mobile,
                                shipping_time: item.shipping_time,
                                order_status: order.order_status,
                                shipment_type: order.shipment_type,
                                shipment_sn: item.shipment_sn,
                                goods_name:goods.goods_res.goods_name,
                                quantity:goods.quantity,
                                current_price:goods.sku_res.current_price,
                                community_name:item.community_res.community_name,
                                address:item.province+item.district+item.address,
                                row_span:0,
                                goods_shipment_type:item.shipment_type,
                                tracking_number:item.tracking_number,
                                vehicle_res:item.vehicle_res

                            }
                            if(index===0){
                                data.order_goods_rowspan=order.order_goods_res_list.length
                            }else{
                                data.order_goods_rowspan=0
                            }
                            let sku=""
                            goods.sku_res.attribute_value_res_list.forEach(item=>{
                                sku+=item.attribute_value
                            })
                            data.sku=sku
                            order_history_list.push(data)
                        })
                    })
                    order_history_list[position].row_span=row_span?row_span:1
                })
            }


            this.setState({
                order_history_list:order_history_list,
                total:res.result.total
            })
        }
    }

    filterOrder=()=>{
        if(!cookie.load("shop_id")&&+cookie.load("is_admin")===1){
            return
        }
        let  form=this.props.form.getFieldsValue();
        let data={
            shop_id:cookie.load("shop_id"),
            page_size:this.state.page_size,
            page_no:this.state.page,
            status:2
        }
        if(form.order_sn){
            data.order_sn=form.order_sn
        }
        if(form.received_name){
            data.received_name=form.received_name
        }
        if(form.mobile){
            var myreg=/^[1][3,4,5,7,8][0-9]{9}$/;
            if (!myreg.test(form.mobile)) {
                return message.error("手机号码格式错误")
            } else {
                data.mobile=form.mobile
            }
        }
        if(form.goods_name){
            data.goods_name=form.goods_name
        }
        if(this.props.order_shipment_type){
            data.shipment_type=this.props.order_shipment_type
        }
        if(this.state.start_order_time){
            data.start_shipping_time=this.state.start_order_time
        }
        if(this.state.end_order_time){
            data.end_shipping_time=this.state.end_order_time
        }
        if(form.community_name){
            data.community_name=form.community_name
        }
        if(form.leader_name){
            data.leader_name=form.leader_name
        }
        if(form.shipment_sn){
            data.shipment_sn=form.shipment_sn
        }

        this.queryGroupShipmentOrderList(data)
        window.scrollTo(0,0);
    }
    onShowTimeChange = (date, dateStr) => {
        this.setState({
            start_order_time: dateStr[0],
            end_order_time: dateStr[1],
        })
    }
    clearFilter = () => {
        this.props.form.resetFields();
        this.setState({
            start_order_time: null,
            end_order_time: null,
        })
    };
    onShowPageChange = (page) => {
        this.setState({
            page:page
        },()=>{this.filterOrder()})
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size:pageSize
        },()=>{this.filterOrder()})
    }
    componentDidMount(){
        this.filterOrder()
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="order-history">
                <div className="filter-content">
                    <Form className="ant-vertical" layout="inline">
                        <Form.Item label="订单号" style={{ display: this.props.order_shipment_type=== 'self_shipment' ? true : "none" }}>
                            {getFieldDecorator('order_sn')(
                                <Input className="form-input" placeholder="输入订单号" />
                            )}
                        </Form.Item>
                        <Form.Item label="收货人" style={{ display: this.props.order_shipment_type=== 'self_shipment' ? true : "none" }}>
                            {getFieldDecorator('received_name')(
                                <Input className="form-input" placeholder="输入收货人姓名" />
                            )}
                        </Form.Item>
                        <Form.Item label="手机号 " style={{ display: this.props.order_shipment_type=== 'self_shipment' ? true : "none" }}>
                            {getFieldDecorator('mobile')(
                                <Input className="form-input" placeholder="输入收货手机号码" />
                            )}
                        </Form.Item>
                        <Form.Item label="发货时间">
                            {getFieldDecorator('date')(
                                <DatePicker.RangePicker  className="form-input-long" onChange={this.onShowTimeChange} showTime />
                            )}
                        </Form.Item>
                        <Form.Item label="商品名称" style={{ display: this.props.order_shipment_type=== 'self_shipment' ? true : "none" }}>
                            {getFieldDecorator('goods_name')(
                                <Input className="form-input-long"placeholder="输入商品名称" />
                            )}
                        </Form.Item>
                        <Form.Item label="社群名称" style={{ display: this.props.order_shipment_type=== 'self_shipment' ? "none" : true }}>
                            {getFieldDecorator('community_name')(
                                <Input className="form-input" placeholder="输入社群名称" />
                            )}
                        </Form.Item>
                        <Form.Item label="团长姓名" style={{ display: this.props.order_shipment_type=== 'self_shipment' ? "none" : true }}>
                            {getFieldDecorator('leader_name')(
                                <Input className="form-input" placeholder="输入团长姓名" />
                            )}
                        </Form.Item>
                        <Form.Item label="手机号 " style={{ display: this.props.order_shipment_type=== 'self_shipment' ? "none" : true}}>
                            {getFieldDecorator('mobile')(
                                <Input className="form-input" placeholder="输入收货手机号码" />
                            )}
                        </Form.Item>
                        <Form.Item label="配送单号" style={{ display: this.props.order_shipment_type=== 'self_shipment' ? "none" : true }}>
                            {getFieldDecorator('shipment_sn')(
                                <Input className="form-input" placeholder="输入配送单号" />
                            )}
                        </Form.Item>
                    </Form>
                    <div className="action">
                        <Button shape="round" type="primary" onClick={this.onShowPageChange.bind(this,1)}>筛选</Button>
                        <span className="text-note" onClick={this.clearFilter}>清空筛选条件</span>
                    </div>
                </div>
                <Table rowKey={(record,index) => index}
                       columns={
                           this.props.order_shipment_type=== 'self_shipment'?this.state.columns:this.state.group_columns
                       }

                       dataSource={this.state.order_history_list} pagination={false} bordered={true}/>
                <Pagination
                    className="pagenation"
                    onChange={this.onShowPageChange}
                    onShowSizeChange={this.onShowSizeChange}
                    defaultCurrent={this.state.page}
                    total={this.state.total}
                    showTotal={(total) =>  `共 ${total} 条`}
                    pageSizeOptions={["10","30","50","100"]}
                    showSizeChanger
                />
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        menu_code:state.menuCode,
        order_status_list:state.orderStatusList
    }
}

const OrderHistoryForm = Form.create({ name: 'order_history_form' })(OrderHistory);

export default connect(mapStateToProps) (OrderHistoryForm);
