import React from "react";
import { Card, Checkbox, Tag } from "antd";
import {
	Form,
	Input,
	Button,
	Table,
	DatePicker,
	Modal,
	Icon,
	message,
	Upload,
	Spin,
	Tooltip,
	Radio
} from "antd/lib/index";
import "@css/activity.scss";
import UploadButton from "@/view/component/common/UploadButton";
import CouponSelectModal from "@/view/component/Campaign/CouponSelectModal";
import { formatInt, formatNumber, getQueryString, mergeList } from "@common/common";
import cookie from "react-cookies";
import {
	createOrUpdateActivity,
	exchangeActivityGoodsSortNum,
	queryActivityDetail,
	queryGoodsList,
	topActivityGoods,
} from "@service/getData";
import moment from "moment";
import config from "@config/config";
import PropTypes from "prop-types";
class MarketTabpane extends React.Component {
	constructor() {
		MarketTabpane.contextTypes = {
			router: PropTypes.object.isRequired
		};
		super();
		this.state = {
			type: null,
			loading2: false,
			requestUrl: config.baseUrl + "/ossFile/upload",
			columns: [
				{
					title: "商品",
					dataIndex: "goods_id",
					render: (text, record) => (
						<div className="table-goods-info">
							<div className="relative">
								<img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="48" height="48" />
								{record.onsale !== 1 ? <div className="unsale">已下架</div> : null}
							</div>
							<div className="flex-col">
								<span>id:{text}</span>
								<span className="input-info">价格:￥{record.current_price / 100}</span>
							</div>
						</div>
					)
				},
				{
					title: "营销价格",
					dataIndex: "partake_price",
					render: (text, record, index) => (
						<Input value={text} className="input-info" onChange={this.changeMoney.bind(this, record, "partake_price", index)} />
					)
				},
				{
					title: "参与人数",
					dataIndex: "group_member_num",
					render: (text, record, index) => (
						<Input
							disabled={!record.activity_goods_id ? false : true}
							value={text}
							className="input-info"
							onChange={this.changeMoney.bind(this, record, "group_member_num", index)}
						/>
					)
				},
				{
					title: "中奖团数",
					dataIndex: "max_num",
					render: (text, record, index) => (
						<Input value={text} className="input-info" onChange={this.changeMoney.bind(this, record, "max_num", index)} />
					)
				},
				{
					title: "时间",
					dataIndex: "activity_time",
					render: (text, record, index) => (
						<DatePicker.RangePicker
							onChange={this.changeGoodsDate.bind(this, record.activity_goods_id)}
							className="form-input-date"
							format="YYYY-MM-DD HH:mm:ss"
							showTime
							value={
								record.start_time
									? [moment(record.start_time, "YYYY-MM-DD HH:mm:ss"), moment(record.end_time, "YYYY-MM-DD HH:mm:ss")]
									: null
							}
						/>
					)
				},
				{
					title: "操作",
					dataIndex: "action",
					render: (text, record, index) => (
						<div className="input-info">
							{index !== 0 ? (
								this.state.exchange_disabled && this.state.type === "edit" ? (
									<Icon className="text-remind mr15" type="arrow-up" />
								) : (
										<Icon className="text-note mr15" onClick={this.exchangeGoodsPosition.bind(this, index, -1)} type="arrow-up" />
									)
							) : null}
							{index !== this.state.select_goods_res.length - 1 ? (
								this.state.exchange_disabled && this.state.type === "edit" ? (
									<Icon className="text-remind" type="arrow-down" />
								) : (
										<Icon onClick={this.exchangeGoodsPosition.bind(this, index, 1)} className="text-note" type="arrow-down" />
									)
							) : null}
							{index !== 0 ? (
								this.state.exchange_disabled && this.state.type === "edit" ? (
									<p className="text-remind" type="arrow-up">
										置顶
									</p>
								) : (
										<p className="text-note" onClick={this.exchangeGoodsPosition.bind(this, index, 0)} type="arrow-up">
											置顶
										</p>
									)
							) : null}
							<p onClick={this.deleteGoods.bind(this, record, index)} className="text-note">
								删除
              				</p>
						</div>
					)
				}
			],
			free_order_columns: [
				{
					title: "商品",
					dataIndex: "goods_id",
					render: (text, record) => (
						<div className="table-goods-info">
							<div className="relative">
								<img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="48" height="48" />
								{record.onsale !== 1 ? <div className="unsale">已下架</div> : null}
							</div>
							<div className="flex-col">
								<span>id:{text}</span>
								<span className="input-info">价格:￥{record.current_price / 100}</span>
							</div>
						</div>
					)
				},
				{
					title: "助力人数",
					dataIndex: "group_member_num",
					render: (text, record, index) => (
						<Input
							disabled={!record.activity_goods_id ? false : true}
							value={text}
							className="input-info"
							onChange={this.changeMoney.bind(this, record, "group_member_num", index)}
						/>
					)
				},
				{
					title: "奖品数量",
					dataIndex: "gift_num",
					render: (text, record, index) => (
						<Input value={text} className="input-info" onChange={this.changeMoney.bind(this, record, "gift_num", index)} />
					)
				},
				{
					title: "时间",
					dataIndex: "activity_time",
					render: (text, record, index) => (
						<DatePicker.RangePicker
							onChange={this.changeGoodsDate.bind(this, record.activity_goods_id)}
							className="form-input-date"
							format="YYYY-MM-DD HH:mm:ss"
							showTime
							value={
								record.start_time
									? [moment(record.start_time, "YYYY-MM-DD HH:mm:ss"), moment(record.end_time, "YYYY-MM-DD HH:mm:ss")]
									: null
							}
						/>
					)
				},
				{
					title: "操作",
					dataIndex: "action",
					render: (text, record, index) => (
						<div className="input-info">
							{index !== 0 ? (
								this.state.exchange_disabled && this.state.type === "edit" ? (
									<Icon className="text-remind mr15" type="arrow-up" />
								) : (
										<Icon className="text-note mr15" onClick={this.exchangeGoodsPosition.bind(this, index, -1)} type="arrow-up" />
									)
							) : null}
							{index !== this.state.select_goods_res.length - 1 ? (
								this.state.exchange_disabled && this.state.type === "edit" ? (
									<Icon className="text-remind" type="arrow-down" />
								) : (
										<Icon onClick={this.exchangeGoodsPosition.bind(this, index, 1)} className="text-note" type="arrow-down" />
									)
							) : null}
							{index !== 0 ? (
								this.state.exchange_disabled && this.state.type === "edit" ? (
									<p className="text-remind" type="arrow-up">
										置顶
									</p>
								) : (
										<p className="text-note" onClick={this.exchangeGoodsPosition.bind(this, index, 0)} type="arrow-up">
											置顶
										</p>
									)
							) : null}
							<p onClick={this.deleteGoods.bind(this, record, index)} className="text-note">
								删除
              				</p>
						</div>
					)
				}
			],
			integral_columns: [
				{
					title: "商品Id",
					dataIndex: "goods_id"
				},
				{
					title: "商品图片",
					dataIndex: "thumbnail_url",
					render: (text, record) => (
						<div>
							<img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="100" height="100" />
						</div>
					)
				},
				{
					title: "商品名称",
					dataIndex: "goods_name",
					render: text => <div>￥{text}</div>
				},
				{
					title: "价格",
					dataIndex: "current_price",
					render: text => <div className="input-info">￥{text / 100}</div>
				},
				{
					title: "积分",
					dataIndex: "points_amount",
					render: (text, record, index) => (
						<Input value={text} className="input-info" onChange={this.changeMoney.bind(this, record, "points_amount", index)} />
					)
				},
				{
					title: "抢购价",
					dataIndex: "partake_price",
					render: (text, record, index) => (
						<Input value={text} className="input-info" onChange={this.changeMoney.bind(this, record, "partake_price", index)} />
					)
				},
				{
					title: "积分商城限购",
					dataIndex: "buy_limit",
					render: (text, record, index) => (
						<Input value={text} className="input-info" onChange={this.changeMoney.bind(this, record, "buy_limit", index)} />
					)
				},
				{
					title: "时间",
					dataIndex: "activity_time",
					render: (text, record, index) => (
						<DatePicker.RangePicker
							onChange={this.changeGoodsDate.bind(this, record.activity_goods_id)}
							className="form-input-date"
							format="YYYY-MM-DD HH:mm:ss"
							showTime
							value={
								record.start_time
									? [moment(record.start_time, "YYYY-MM-DD HH:mm:ss"), moment(record.end_time, "YYYY-MM-DD HH:mm:ss")]
									: null
							}
						/>
					)
				},
				{
					title: "操作",
					dataIndex: "action",
					render: (text, record, index) => (
						<div className="input-info">
							{index !== 0 ? (
								this.state.exchange_disabled && this.state.type === "edit" ? (
									<Icon className="text-remind mr15" type="arrow-up" />
								) : (
										<Icon className="text-note mr15" onClick={this.exchangeGoodsPosition.bind(this, index, -1)} type="arrow-up" />
									)
							) : null}
							{index !== this.state.select_goods_res.length - 1 ? (
								this.state.exchange_disabled && this.state.type === "edit" ? (
									<Icon className="text-remind" type="arrow-down" />
								) : (
										<Icon onClick={this.exchangeGoodsPosition.bind(this, index, 1)} className="text-note" type="arrow-down" />
									)
							) : null}
							{index !== 0 ? (
								this.state.exchange_disabled && this.state.type === "edit" ? (
									<p className="text-remind" type="arrow-up">
										置顶
									</p>
								) : (
										<p className="text-note" onClick={this.exchangeGoodsPosition.bind(this, index, 0)} type="arrow-up">
											置顶
										</p>
									)
							) : null}
							<p onClick={this.deleteGoods.bind(this, record, index)} className="text-note">
								删除
              				</p>
						</div>
					)
				}
			],
			visible: false,
			search_value: null,
			goods_columns: [
				{
					title: "商品id",
					dataIndex: "goods_id"
				},
				{
					title: "商品图片",
					dataIndex: "thumbnail_url",
					render: (text, record) => (
						<div>
							<img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="100" height="100" />
						</div>
					)
				},
				{
					title: "商品名称",
					dataIndex: "goods_name"
				},
				{
					title: "价格",
					dataIndex: "current_price",
					render: text => <div>￥{text / 100}</div>
				}
			],
			goods_res_list: [],
			selected_row_keys: [],
			select_goods_res: [],
			current_select_goods_res: [],
			start_time: null,
			end_time: null,
			activity_id: null,
			activity_res: {},
			exchange_disabled: false,
			preview_image: "",
			preview_gallery_image: "",
			preview_gallery_image2: "",
			file_list: [],
			file_list2: [],
			gallery_list: [],
			gallery_list2: [],
			preview_gallery_visible: false,
			preview_gallery_visible2: false,
			show_another: ["advertisement", "topScroll", "endScroll"],
			loading: false,
			campaign_id: null,
			campaign_list: [],
			rule_list: [
				"按商品价格的 100% 返现，买家抢到概率为10%",
				"按商品价格的 80%   返现，买家抢到概率为20%",
				"按商品价格的 60%   返现，买家抢到概率为40%",
				"按商品价格的 40%   返现，买家抢到概率为20%",
				"按商品价格的 20%   返现，买家抢到概率为10%"
			],
			help_cash_amount: null,
			// 拼团奖励设置列表
			ladderRewardData: [
				{
					start: null,
					end: null,
					ship: 0,
					refund: 0,
					integral: 0,
					choosed: [],
					coupon_id_list: [],
					couponList: [],
					group_status: 0,
					activity_id: getQueryString("activity_id"),
					// 后端根据status的值判断是否删除
					status: 1,
				}
			],
			// 优惠券选择弹窗
			coupon_select_visible: false,
			// 当前正在操作的拼团阶梯index
			currentIndex: 0,
		};
	}

	componentDidMount() {
		let type = getQueryString("type");
		let activity_id = getQueryString("activity_id");
		let campaign_id = getQueryString("campaign_id");
		this.setState(
			{
				type,
				activity_id,
				campaign_id,
				loading: false
			},
			() => {
				if (activity_id && activity_id !== 'undefined') {
					this.queryActivityDetail();
				} else {
					// ？这TM是什么逻辑？找不到activity_id就默认展示拼团抽奖的页面？？？
					// 先注释掉，有问题再说吧...
					// this.queryActivityList();
				}
			}
		);
	}
	createOrUpdateActivity = async data => {
		this.setState({
			loading: true
		});
		let res = await createOrUpdateActivity(data);

		if (res.success) {
			this.props.form.resetFields();
			message.success(
				this.state.activity_res.activity_subtype === "free_order"
					? "抢免单更新成功"
					: this.state.activity_res.activity_subtype === "checkin"
						? "签到更新成功"
						: this.state.activity_res.activity_subtype === "rank_lucky"
							? '线上拼团更新成功'
							: "拼团抽奖更新成功"
			);
			this.setState(
				{
					visible: false,
					search_value: null,
					goods_res_list: [],
					selected_row_keys: [],
					select_goods_res: [],
					current_select_goods_res: [],
					start_time: null,
					end_time: null,
					activity_res: {},
					exchange_disabled: false,
					gallery_list: [],
					gallery_list2: [],
					loading: false
				},
				() => {
					if (this.state.type === "edit") {
						this.queryActivityDetail();
					}
				}
			);
		} else {
			message.error(res.msg);
		}
	};

	queryActivityDetail = async () => {
		let data = {
			activity_id: this.state.activity_id
		};
		let res = await queryActivityDetail(data);
		if (res.success) {
			let select_goods_res = res.result.activity_res.activity_goods_res_list.map(item => {
				return {
					goods_id: item.goods_res.goods_id,
					goods_name: item.goods_res.goods_name,
					current_price: item.goods_res.current_price,
					start_time: item.start_time,
					end_time: item.end_time,
					draw_time: item.draw_time,
					activity_id: item.activity_id,
					activity_goods_id: item.activity_goods_id,
					status: 1,
					onsale: item.goods_res.onsale,
					gallery_urls: item.goods_res.gallery_urls,
					loading: false,
					partake_price: item.partake_price / 100,
					group_member_num: item.group_member_num,
					buy_limit: item.buy_limit,
					gift_num: item.gift_num,
					points_amount: item.points_amount,
					max_num: Math.ceil(item.gift_num / item.group_member_num),
					expires_in: item.expires_in,
				};
			});
			if (res.result.activity_res.target_type === "goods") {
				select_goods_res.push(res.result.activity_res.goods_res);
			}
			let gallery_list = [];
			if (res.result.activity_res.thumbnail_url) {
				gallery_list = [
					{
						uid: "-1",
						name: "xxx.png",
						status: "done",
						url: res.result.activity_res.thumbnail_url
					}
				];
			}
			let gallery_list2 = [];
			if (res.result.activity_res.transfer_image_url) {
				gallery_list2 = [
					{
						uid: "-1",
						name: "xxx.png",
						status: "done",
						url: res.result.activity_res.transfer_image_url
					}
				];
			}
			const activityType = res.result.activity_res.activity_subtype;
			if (activityType === "enterGroup") {
				gallery_list = res.result.activity_res.message_res_list.map((item, index) => {
					return {
						uid: index,
						name: `${index}照片`,
						status: "done",
						url: item.image_url,
						message_id: item.message_id
					};
				});
			}
			// 线上拼团，数据处理
			if (activityType === 'rank_lucky') {
				const columns = [...this.state.columns].map((item) => {
					if (item.dataIndex === 'max_num') {
						item.title = '最大开团数';
					}
					return item;
				});
				this.setState({
					columns,
				});
				const ladderRewardData = res.result.activity_res.activity_draw_rule_res_list;
				ladderRewardData.length > 0 && this.dealLadderData(ladderRewardData);
			}
			this.setState({
				select_goods_res: select_goods_res,
				activity_res: res.result.activity_res,
				gallery_list,
				gallery_list2,
				loading: false,
				help_cash_amount: res.result.activity_res.help_cash_amount / 100,
				exchange_disabled: false,
			});
		}
	};
	dealLadderData(list) {
		const ladderRewardData = list.map((ladder) => {
			const {
				start_rank: start,
				end_rank: end,
				is_lucky: ship,
				need_refund: refund,
				points: integral,
				coupon_id_list,
				group_status,
				coupon_res_list: couponList,
				rule_id,
				activity_id,
				status,
			} = ladder
			let choosed = [];
			if (ship) {
				choosed.push('ship');
			}
			if (refund) {
				choosed.push('refund');
			}
			if (ladder.need_points) {
				choosed.push('integral');
			}
			if (ladder.need_coupon) {
				choosed.push('coupon');
			}
			return {
				start,
				end,
				ship,
				refund,
				integral,
				coupon_id_list,
				group_status,
				choosed,
				couponList,
				rule_id,
				activity_id,
				status,
			}
		});
		this.setState({
			ladderRewardData,
		});
	}
	queryGoodsList = async () => {
		this.setState({
			loading2: true
		});
		let data = {
			shop_id: cookie.load("shop_id"),
			onsale: 1
		},
			value = this.state.search_value;
		if (isNaN(+value)) {
			data.goods_name = value;
		} else {
			data.goods_id = value;
		}
		let res = await queryGoodsList(data);
		if (res.success) {
			this.setState({
				loading2: false,
				goods_res_list: res.result.goods_res_list
			});
		}
	};
	exchangeActivityGoodsSortNum = async (index, isUp) => {
		let list = [...this.state.select_goods_res],
			from_activity_goods_id = list[index].activity_goods_id,
			to__activity_goods_id;

		if (isUp < 0) {
			to__activity_goods_id = list[index - 1].activity_goods_id;
		} else {
			to__activity_goods_id = list[index + 1].activity_goods_id;
		}
		let res = await exchangeActivityGoodsSortNum({
			from_activity_goods_id,
			to__activity_goods_id
		});
		if (res.success) {
			message.success("操作成功");
			this.queryActivityDetail();
		}
	};
	topActivityGoods = async activity_goods_id => {
		let res = await topActivityGoods({ activity_goods_id });
		if (res.success) {
			message.success("置顶成功");
			this.queryActivityDetail();
		}
	};
	handleCancel = () =>
		this.setState({
			visible: false,
			preview_gallery_visible: false
		});
	handleOk = () => {
		const type = this.state.activity_res.activity_subtype;
		let select_goods_res = [...this.state.select_goods_res],
			current_select_goods_res = [...this.state.current_select_goods_res];
		// 线上拼团只能选取一个商品
		if (type === 'rank_lucky') {
			if (current_select_goods_res.length > 1) {
				message.error('只能选取一个商品参加线上拼团活动哦');
			} else {
				this.setState({
					select_goods_res: [...select_goods_res, ...current_select_goods_res],
					visible: false,
					// 只有一个商品
					exchange_disabled: false,
				});
			}
			return;
		}
		select_goods_res.forEach(item => {
			let index = current_select_goods_res.findIndex(row => row.goods_id === item.goods_id);
			if (index > -1) {
				current_select_goods_res.splice(index, 1);
			}
		});
		if (!this.state.activity_res || (this.state.activity_res.activity_subtype !== "pointsStore")) {
			current_select_goods_res.forEach(item => {
				item.partake_price = 0.01;
				item.group_member_num = 3;
				item.gift_num = 3;
			});
		}
		select_goods_res = select_goods_res.concat(current_select_goods_res);
		this.setState({
			select_goods_res: select_goods_res,
			visible: false,
			exchange_disabled: true
		});
	};
	searchHandle = e => {
		this.setState({
			search_value: e.target.value
		});
	};
	showModal = () => {
		if (this.state.activity_res.activity_subtype === 'rank_lucky' && this.state.select_goods_res.filter(item => item.status === 1).length >= 1) {
			message.error('只能选取一个商品参加线上拼团活动哦');
			return
		}
		this.setState(
			{
				visible: true
			},
			() => {
				this.queryGoodsList();
			}
		);
	};
	onSelectedRowKeysChange = (selected_row_keys, record) => {
		this.setState({
			selected_row_keys: selected_row_keys,
			current_select_goods_res: record
		});
	};
	changeGoodsDate = (activity_goods_id, date, dateStr) => {
		let index = this.state.select_goods_res.findIndex(item => item.activity_goods_id === activity_goods_id)
		if (index > -1) {
			let list = [...this.state.select_goods_res];

			list[index].start_time = dateStr[0];
			list[index].end_time = dateStr[1];
			list[index].draw_time = dateStr[1];

			this.setState({
				select_goods_res: list
			});
		} else {
			this.setState({
				start_time: dateStr[0],
				end_time: dateStr[1]
			});
		}
	};
	exchangeGoodsPosition = async (index, num) => {
		let list = [...this.state.select_goods_res];
		let goods = list[index];
		if (this.state.type === "edit" && +num === 0) {
			this.topActivityGoods(goods.activity_goods_id);
		} else if (this.state.type === "edit") {
			this.exchangeActivityGoodsSortNum(index, num);
		}

		if (num === -1) {
			if (index === 0) {
				return;
			}
			list.splice(index - 1, 1, ...list.splice(index, 1, list[index - 1]));
		} else if (num === 1) {
			if (index === list.length - 1) {
				return;
			}
			list.splice(index, 1, ...list.splice(index + 1, 1, list[index]));
		} else {
			if (index === 0) {
				return;
			}
			list.splice(0, 0, ...list.splice(index, 1));
		}
		this.setState({
			select_goods_res: list
		});
	};
	deleteGoods = (goods, index) => {
		const arr = this.state.select_goods_res.map((good, gindex) => {
			if (gindex === index) {
				good.status = 0;
			}
			return good;
		});
		this.setState({
			select_goods_res: arr,
			exchange_disabled: true,
		});
	};
	range = (start, end) => {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	};

	updateActivity = upstatus => {
		if (!this.state.activity_res.reserve_balance && this.state.activity_res.activity_subtype === "free_order") {
			return message.error("店铺余额不足");
		}

		if (this.state.activity_res.activity_subtype === 'rank_lucky') {
			const notChhoseCoupon = this.state.ladderRewardData.every((ladder) => {
				if (ladder.choosed.indexOf('coupon') > -1 && !ladder.coupon_id_list.length) {
					return false;
				}
				return true;
			});
			const isLadderNumRight = this.state.ladderRewardData.every((ladder) => {
				const reg = new RegExp(/^[1-9]\d*$/);
				if (
					(reg.test(ladder.start) &&
						reg.test(ladder.end) &&
						(ladder.end > ladder.start)) ||
					ladder.group_status === 0
				) {
					return true;
				}
				return false;
			})
			if (!notChhoseCoupon) {
				message.error('勾选赠券后，请添加优惠券');
				return;
			}
			if (!isLadderNumRight) {
				message.error('请填写有效的成团名次（大于0的正整数,且末尾名次大于开始名次）');
				return;
			}
		}

		let form = this.props.form.getFieldsValue();
		let flag = false;
		if (!this.state.gallery_list.length) {
			this.props.form.setFieldsValue({ thumbnail_url: null });
		} else {
			this.props.form.setFieldsValue({ thumbnail_url: true });
		}
		this.props.form.validateFields(err => {
			if (err) {
				flag = true;
			}
		});
		if (flag) return;
		let gallery_url = "";
		this.state.gallery_list.forEach(item => {
			if (item.response && item.response.success) {
				gallery_url = item.response.result.oss_file_url;
			} else if (item.url) {
				gallery_url = item.url;
			}
		});
		if (
			this.state.activity_res.activity_subtype !== "checkin" &&
			this.state.activity_res.activity_subtype !== "rank_lucky" &&
			this.state.select_goods_res.filter(item => item.status > 0).length === 0
		) {
			return message.error("请选择商品");
		}
		let desc = "";
		if (this.state.activity_res.activity_subtype === "checkin" || this.state.activity_res.activity_subtype === "free_order") {
			for (let i = 0; i < this.state.select_goods_res.length; i++) {
				let item = this.state.select_goods_res[i];
				if (!item.group_member_num) {
					flag = true;
					desc = `助力人数不能为空：商品id${item.goods_id}`;
					break;
				}
				if (!item.gift_num) {
					flag = true;
					desc = `奖品数量不能为空：商品id${item.goods_id}`;
					break;
				}
				if (!item.start_time || !item.end_time) {
					flag = true;
					desc = `活动时间不能为空：商品id${item.goods_id}`;
					break;
				}
			}
		} else {
			for (let i = 0; i < this.state.select_goods_res.length; i++) {
				let item = this.state.select_goods_res[i];
				if (!item.partake_price && +item.partake_price !== 0) {
					flag = true;
					desc = `价格不能为空：商品id${item.goods_id}`;
					break;
				}
				if (!item.group_member_num) {
					flag = true;
					desc = `参与人数不能为为空：商品id${item.goods_id}`;
					break;
				}
				if (!item.gift_num) {
					flag = true;
					desc = `奖品数量不能为空：商品id${item.goods_id}`;
					break;
				}
				if (!item.start_time || !item.end_time) {
					flag = true;
					desc = `活动时间不能为空：商品id${item.goods_id}`;
					break;
				}
			}
		}
		if (flag) return message.error(desc);
		let goods_list = [];
		this.state.select_goods_res.forEach(item => {
			goods_list.push({
				goods_id: item.goods_id,
				start_time: item.start_time,
				end_time: item.end_time,
				draw_time: item.draw_time,
				activity_id: item.activity_id,
				activity_goods_id: item.activity_goods_id,
				partake_price:
					this.state.activity_res.activity_subtype === "free_order" ? item.current_price : Math.round(item.partake_price * 100),
				buy_limit: +form.buy_limit,
				expires_in: (+form.expires_in) * 3600,
				points_amount: item.points_amount,
				group_member_num: item.group_member_num,
				gift_num: item.gift_num,
				status: item.status
			});
		});
		let gallery_url2 = "";
		this.state.gallery_list2.forEach(item => {
			if (item.response && item.response.success) {
				gallery_url2 = item.response.result.oss_file_url;
			} else if (item.url) {
				gallery_url2 = item.url;
			}
		});
		let message_req_list = [],
			message_list = [];
		if (this.state.activity_res.activity_subtype === "enterGroup") {
			message_req_list = this.state.gallery_list.map(item => {
				let zdata = {
					message_id: item.message_id,
					miniAppId: cookie.load("mini_app_id"),
					userMessageType: "miniprogrampage",
					answerType: "image",
					status: 1
				};
				if (item.response && item.response.success) {
					zdata.image_url = item.response.result.oss_file_url;
				} else if (item.url) {
					zdata.image_url = item.url;
				}
				return zdata;
			});
			message_list = JSON.parse(JSON.stringify(message_req_list));
			this.state.activity_res.message_res_list.forEach(item => {
				let zmessage = message_req_list.find(my => my.message_id === item.message_id);
				if (!zmessage) {
					message_list.push({
						message_id: item.message_id,
						miniAppId: cookie.load("mini_app_id"),
						userMessageType: "miniprogrampage",
						answerType: "image",
						status: 0,
						image_url: item.image_url
					});
				}
			});
		}
		if (this.state.activity_res.activity_subtype === "free_order" && Number(this.state.help_cash_amount) === 0) {
			return message.error("返红包金额大于0");
		}
		let activity_draw_rule_list;
		if (this.state.activity_res.activity_subtype === 'rank_lucky') {
			activity_draw_rule_list = this.state.ladderRewardData.map((ladder) => {
				const {
					rule_id,
					activity_id,
					group_status,
					start: start_rank,
					end: end_rank,
					ship: is_lucky,
					refund: need_refund,
					integral: points,
					coupon_id_list,
					status,
				} = ladder;
				return {
					rule_id,
					activity_id,
					group_status,
					start_rank,
					end_rank,
					is_lucky,
					need_refund,
					points,
					coupon_id_list,
					status,
				}
			});
		}
		let data = {
			activity_req: {
				activity_sub_type: this.state.activity_res.activity_subtype,
				activity_draw_rule_list,
				mini_app_id: cookie.load("mini_app_id"),
				target_type: "goodsList",
				activity_id: this.state.activity_res.activity_id,
				title: form.title || null,
				transfer_title: form.transfer_title || null,
				description: form.description,
				activity_goods_list: goods_list,
				thumbnail_url: gallery_url,
				transfer_image_url: gallery_url2,
				user_free_num: form.user_free_num,
				user_help_num: form.user_help_num,
				help_user_cash: form.help_user_cash,
				cash_back_type: form.cash_back_type,
				help_cash_amount: this.state.activity_res.activity_subtype === "free_order" ? this.state.help_cash_amount * 100 : null
			}
		};
		this.createOrUpdateActivity(data);
	};
	updateIntegral = () => {
		let flag = false;
		if (
			this.state.activity_res.activity_subtype !== "checkin" &&
			this.state.select_goods_res.filter(item => item.status > 0).length === 0
		) {
			return message.error("请选择商品");
		}
		let desc = "";
		if (this.state.activity_res.activity_subtype === "free_order") {
			for (let i = 0; i < this.state.select_goods_res.length; i++) {
				let item = this.state.select_goods_res[i];
				if (!item.group_member_num) {
					flag = true;
					desc = `助力人数不能为空：商品id${item.goods_id}`;
					break;
				}
				if (!item.gift_num) {
					flag = true;
					desc = `奖品数量不能为空：商品id${item.goods_id}`;
					break;
				}
				if (!item.start_time || !item.end_time) {
					flag = true;
					desc = `活动时间不能为空：商品id${item.goods_id}`;
					break;
				}
			}
		} else {
			for (let i = 0; i < this.state.select_goods_res.length; i++) {
				let item = this.state.select_goods_res[i];
				if (!item.partake_price && +item.partake_price !== 0) {
					flag = true;
					desc = `价格不能为空：商品id${item.goods_id}`;
					break;
				}
				if (!item.group_member_num) {
					flag = true;
					desc = `参与人数不能为为空：商品id${item.goods_id}`;
					break;
				}
				if (!item.gift_num) {
					flag = true;
					desc = `奖品数量不能为空：商品id${item.goods_id}`;
					break;
				}
				if (!item.start_time || !item.end_time) {
					flag = true;
					desc = `活动时间不能为空：商品id${item.goods_id}`;
					break;
				}
			}
		}
		if (flag) return message.error(desc);
		let goods_list = [];
		this.state.select_goods_res.forEach(item => {
			goods_list.push({
				goods_id: item.goods_id,
				start_time: item.start_time,
				end_time: item.end_time,
				draw_time: item.draw_time,
				activity_id: item.activity_id,
				activity_goods_id: item.activity_goods_id,
				status: item.status,
				partake_price: Math.round(item.partake_price * 100),
				buy_limit: item.buy_limit,
				points_amount: item.points_amount
			});
		});
		let data = {
			activity_req: {
				mini_app_id: cookie.load("mini_app_id"),
				target_type: "goodsList",
				activity_id: this.state.activity_res.activity_id,
				activity_goods_list: goods_list,
				status: 1
			}
		};
		this.createOrUpdateActivity(data);
	};
	// queryActivityList = async () => {
	// 	let data = {
	// 		mini_app_id: cookie.load("mini_app_id"),
	// 		activity_type_list: ["booth"]
	// 	};
	// 	let res = await queryActivityList(data);
	// 	if (res.success) {
	// 		let activity = res.result.activity_res_list.find(item => item.activity_subtype === "lucky");
	// 		if (activity) {
	// 			message.warn('未找到activity_id，即将为您转换为拼团抽奖设置', 2, () => {
	// 				this.setState(
	// 					{
	// 						activity_id: activity.activity_id
	// 					},
	// 					() => {
	// 						this.queryActivityDetail(activity.activity_id);
	// 					}
	// 				);
	// 			});
	// 		}
	// 	}
	// };
	handleGalleryChange = ({ fileList }) => {
		this.setState({ gallery_list: fileList });
	};
	handleGalleryPreview = file => {
		this.setState({
			preview_image: file.url || file.image_url || file,
			preview_gallery_visible: true
		});
	};
	handleGalleryChange2 = ({ fileList }) => {
		this.setState({ gallery_list2: fileList });
	};
	handleGalleryPreview2 = file => {
		this.setState({
			preview_gallery_image2: file.url || file.thumbUrl,
			preview_gallery_visible2: true
		});
	};
	changeActivitySubtype = () => {
		this.props.form.resetFields(["target_type"]);
	};
	changeMoney = (data, attr, index, e) => {
		e.persist();
		let list = this.state.select_goods_res.concat([]);
		let true_index = list.findIndex(item => item.activity_goods_id === data.activity_goods_id)
		let value = e.target.value;
		if (attr === "partake_price") {
			value = formatNumber(value);
		} else if (attr === "gift_num") {
			value = formatInt(value);
		} else if (attr === "max_num") {
			value = formatInt(value);
			list[true_index]["gift_num"] = formatInt(value) * data.group_member_num;
		} else {
			value = formatInt(value);
		}
		if (attr === "group_member_num") {
			if (this.state.activity_res.activity_subtype !== "free_order") {
				list[true_index]["gift_num"] = list[true_index]["max_num"] * value;
			}
		}
		list[true_index][attr] = value;

		this.setState({
			select_goods_res: list
		});
	};
	beforeUpload = file => {
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error(file.name + "图片大小超出限制，请修改后重新上传", 0.8);
		}
		return isLt2M;
	};
	handleGalleryCancel = () => {
		this.setState({
			preview_gallery_visible: false,
			preview_gallery_visible2: false
		});
	};
	changeFormValue = e => {
		let value = e.target.value;
		if (value === "all_cash" || value === "part_cash") {
			this.props.form.setFieldsValue({ cash_back_type: value });
		} else {
			this.props.form.setFieldsValue({ help_user_cash: value });
		}
	};
	changeHelpCashAmount = e => {
		e.stopPropagation();
		let value = formatNumber(e.target.value);
		this.setState({
			help_cash_amount: value
		});
	};
	goToActivity = () => {
		this.context.router.history.push("/bonusRecharge");
	};
	addLadder() {
		this.setState((preState) => ({
			ladderRewardData: [...preState.ladderRewardData, {
				start: null,
				end: null,
				ship: 0,
				refund: 0,
				integral: 0,
				choosed: [],
				coupon_id_list: [],
				couponList: [],
				group_status: 1,
				activity_id: getQueryString("activity_id"),
				status: 1,
			}],
		}));
	}

	// 成团名次开始
	startChange(index, e) {
		const val = e.target.value;
		const obj = { ...this.state.ladderRewardData[index], start: +val };
		const arr = this.state.ladderRewardData.map((item, zindex) => {
			if (index === zindex) {
				item = obj;
			}
			return item;
		})
		this.setState({
			ladderRewardData: arr,
		});
	}
	// 成团名次结束
	endChange(index, e) {
		const val = e.target.value;
		const obj = { ...this.state.ladderRewardData[index], end: +val };
		const arr = this.state.ladderRewardData.map((item, zindex) => {
			if (index === zindex) {
				item = obj;
			}
			return item;
		})
		this.setState({
			ladderRewardData: arr,
		});
	}
	// 奖励变化
	// 发货 0:未勾选 1:勾选
	// 退款 0:未勾选 1:勾选
	rewardChange(index, val) {
		// 是否勾选发货
		const ship = val.indexOf('ship') === -1 ? 0 : 1;
		// 是否勾选退款
		const refund = val.indexOf('refund') === -1 ? 0 : 1;
		// 是否勾选赠积分
		const integral = val.indexOf('integral') === -1 ? 0 : this.state.ladderRewardData[index].integral;
		// 是否勾选赠优惠券
		const coupon_id_list = val.indexOf('coupon') === -1 ? [] : this.state.ladderRewardData[index].coupon_id_list;
		const couponList = val.indexOf('coupon') === -1 ? [] : this.state.ladderRewardData[index].couponList;
		const obj = { ...this.state.ladderRewardData[index], ship, refund, integral, coupon_id_list, couponList, choosed: val };
		const arr = this.state.ladderRewardData.map((item, zindex) => {
			if (index === zindex) {
				item = obj;
			}
			return item;
		})
		this.setState({
			ladderRewardData: arr,
		});
	}
	// 积分输入
	integralChange(index, e) {
		const val = e.target.value;
		const obj = { ...this.state.ladderRewardData[index], integral: +val };
		const arr = this.state.ladderRewardData.map((item, zindex) => {
			if (index === zindex) {
				item = obj;
			}
			return item;
		})
		this.setState({
			ladderRewardData: arr,
		});
	}
	// 添加优惠券
	addCoupon(index) {
		this.setState({
			coupon_select_visible: true,
			currentIndex: index,
		});
	}
	cancleCouponModal() {
		this.setState({
			coupon_select_visible: false,
		});
	}
	onCouponOk(list) {
		const index = this.state.currentIndex;
		const couponIdList = list.map(item => item.coupon_id);
		const couponList = list.map((item) => {
			const { coupon_id, discount_amount, use_min_amount, coupon_name } = item;
			return {
				coupon_id,
				discount_amount,
				use_min_amount,
				coupon_name
			}
		});
		const obj = { ...this.state.ladderRewardData[index], coupon_id_list: [...new Set([...this.state.ladderRewardData[index].coupon_id_list, ...couponIdList])], couponList: mergeList(couponList, this.state.ladderRewardData[index].couponList, 'coupon_id') };
		const arr = this.state.ladderRewardData.map((item, zindex) => {
			if (index === zindex) {
				item = obj;
			}
			return item;
		})
		this.setState({
			ladderRewardData: arr,
			coupon_select_visible: false,
		});
	}
	deleteCoupon(coupon_id, index) {
		const coupon_id_list = this.state.ladderRewardData[index].coupon_id_list.filter((id) => id !== coupon_id);
		const couponList = this.state.ladderRewardData[index].couponList.filter((coupon) => coupon.coupon_id !== coupon_id)
		const obj = { ...this.state.ladderRewardData[index], coupon_id_list, couponList };
		const arr = this.state.ladderRewardData.map((item, zindex) => {
			if (index === zindex) {
				item = obj;
			}
			return item;
		})
		this.setState({
			ladderRewardData: arr,
			coupon_select_visible: false,
		});
	}
	deleteLadder(index) {
		const arr = this.state.ladderRewardData.map((ladder, lindex) => {
			if (index === lindex) {
				ladder.status = 0;
			}
			return ladder;
		});
		this.setState({
			ladderRewardData: arr,
		});
	}
	goBack() {
		this.context.router.history.push('/groupActivitys');
	}
	render() {
		const { getFieldDecorator } = this.props.form;
		const { selected_row_keys } = this.state;
		const rowSelection = {
			selectedRowKeys: selected_row_keys,
			onChange: this.onSelectedRowKeysChange,
			hideDefaultSelections: true,
			columnTitle: "选择"
		};
		let goods = this.state.select_goods_res.length > 0 &&
			this.state.select_goods_res[0];
		return (
			<div className="market-tabpane">
				<Spin spinning={this.state.loading}>
					<div className="content">
						<Card>
							{this.state.activity_res.activity_subtype === 'rank_lucky' ? (
								<Form className="form">
									<Form.Item label="拼团用户奖励" wrapperCol={{ span: 20 }}>
										<Button shape='round' type="primary" onClick={this.addLadder.bind(this)}>添加成团名次</Button>
									</Form.Item>
									<Form.Item wrapperCol={{ span: 24 }}>
										{this.state.ladderRewardData.map((ladder, index) => ladder.status === 1 ? (
											<div className="ladderItem" key={index}>
												{ladder.group_status === 0 ? (
													// 未成团奖励
													<div className="ladder">
														未成团：
													</div>
												) : (
														// 成团奖励
														<div className="ladder">
															成团名次：
															<Input allowClear={true} suffix="名" value={ladder.start} onChange={this.startChange.bind(this, index)} />
															<span style={{ margin: '0 12px' }}>至</span>
															<Input allowClear={true} suffix="名" value={ladder.end} onChange={this.endChange.bind(this, index)} />
														</div>
													)}
												<div className="reward">
													<span className="groupRewardText">{ladder.group_status === 0 ? '奖励：' : '成团奖励：'}</span>
													<Checkbox.Group value={ladder.choosed} onChange={this.rewardChange.bind(this, index)}>
														<Checkbox value='ship' disabled={ladder.group_status === 0}>发货</Checkbox>
														<Checkbox value='refund'>退款</Checkbox>
														<Checkbox value='integral'>
															<span className="text">赠积分</span>
															{ladder.choosed.indexOf('integral') > -1 ? (
																<Input suffix="个" value={ladder.integral || null} onChange={this.integralChange.bind(this, index)} />
															) : null}
														</Checkbox>
														<Checkbox className="coupnCheckbox" value='coupon'>
															<span className="text">赠券</span>
														</Checkbox>
														{ladder.choosed.indexOf('coupon') > -1 ? (
															<span className="addCoupon" onClick={this.addCoupon.bind(this, index)}>添加优惠券</span>
														) : null}
														<div className="couponTagWrap">
															{ladder.couponList.map((coupon) => (
																<Tag
																	key={coupon.coupon_id}
																	closable={true}
																	onClose={this.deleteCoupon.bind(this, coupon.coupon_id, index)}
																>
																	满{coupon.use_min_amount / 100}减{coupon.discount_amount / 100}&#160;&#160;{coupon.coupon_name}
																</Tag>
															))}
														</div>
													</Checkbox.Group>
												</div>
												{ladder.group_status === 0 ? null : (
													<i className="iconfont icon-guanbianniu" onClick={this.deleteLadder.bind(this, index)}></i>
												)}
											</div>
										) : null)}
									</Form.Item>
									<Form.Item label="单个用户最多参与">
										{getFieldDecorator('buy_limit', {
											rules: [
												{
													required: true,
													message: '请填写单个用户最多参与次数'
												},
												{
													pattern: new RegExp(/[1-9]+/),
													message: '请填写有效数字',
												}
											],
											initialValue: goods.buy_limit || null
										})(<Input suffix="次" />)}
									</Form.Item>
									<Form.Item label="成团有效期">
										{getFieldDecorator('expires_in', {
											rules: [
												{
													required: true,
													message: '请填写成团有效期'
												},
												{
													pattern: new RegExp(/[1-9]+/),
													message: '请填写有效数字',
												}
											],
											initialValue: goods.expires_in / 3600 || null
										})(<Input suffix="h" />)}
									</Form.Item>
								</Form>
							) : null}
							<Form className="form">
								<Form.Item
									label="入口图标"
									style={{ display: cookie.load("mini_app_type") === "flagship_version" ? "none" : true }}
									extra={<div className="form-upload-remind"> 建议宽度375，高度不限，仅支持jpg,png 2种格式,单张图片大小不超过3.0MB</div>}>
									{getFieldDecorator("thumbnail_url", {
										initialValue: this.state.activity_res.thumbnail_url,
										rules: [
											{
												required: true,
												message: "请上传图标"
											}
										]
									})(
										<div className="iamge-upload">
											<Upload
												accept="image/jpeg,image/jpg,image/png"
												withCredentials={true}
												action={this.state.requestUrl}
												listType="picture-card"
												fileList={this.state.gallery_list}
												onPreview={this.handleGalleryPreview}
												onChange={this.handleGalleryChange}>
												{this.state.gallery_list.length >= 1 ? null : <UploadButton />}
											</Upload>
											<Modal visible={this.state.preview_gallery_visible} footer={null} onCancel={this.handleGalleryCancel}>
												<img alt="example" style={{ width: "100%" }} src={this.state.preview_gallery_image} />
											</Modal>
										</div>
									)}
								</Form.Item>
							</Form>
							{this.state.activity_res.activity_subtype === "free_order" ? (
								<Form className="form">
									<Form.Item label="返现方式">
										{getFieldDecorator("cash_back_type", {
											initialValue: this.state.activity_res.cash_back_type,
											rules: [
												{
													required: true
												}
											]
										})(
											<div>
												<Radio.Group value={this.props.form.getFieldValue("cash_back_type")} onChange={this.changeFormValue}>
													<Radio value="all_cash">全额返现</Radio>
													<Radio value="part_cash">
														部分返现
                            							<Tooltip title="现金可提现，可设置提现门槛">
															<Icon type="question-circle" theme="twoTone" />
														</Tooltip>
													</Radio>
												</Radio.Group>
												{this.props.form.getFieldValue("cash_back_type") === "all_cash" ? (
													<div>全额返现规则：所有买家均按商品价格全额返现金</div>
												) : (
														<div className="free-order-rule">
															<div className="rule-item">部分返现规则：</div>
															<div className="rule-item">
																{this.state.rule_list.map((item, index) => (
																	<div key={index}>{item}</div>
																))}
															</div>
														</div>
													)}
											</div>
										)}
									</Form.Item>
									<Form.Item label="店铺资产">
										{getFieldDecorator("reserve_balance", {
											initialValue: this.state.activity_res.reserve_balance / 100,
											rules: [
												{
													required: true
												}
											]
										})(
											<div>
												{this.state.activity_res.reserve_balance / 100}元
												<span className="money-recharge" onClick={this.goToActivity}>
													金额充值
												</span>
											</div>
										)}
									</Form.Item>
									<Form.Item label="助力用户奖励">
										{getFieldDecorator("help_user_cash", {
											initialValue: this.state.activity_res.help_user_cash.toString(),
											rules: [
												{
													required: true
												}
											]
										})(
											<div>
												<Radio.Group value={this.props.form.getFieldValue("help_user_cash")} onChange={this.changeFormValue}>
													<Radio value="0">无</Radio>
													<Radio value="1">
														返红包
                            							<Tooltip title="红包不能提现，购物时可抵扣，建议返不超过0.5元">
															<Icon type="question-circle" theme="twoTone" />
														</Tooltip>
													</Radio>
												</Radio.Group>
												{this.props.form.getFieldValue("help_user_cash") === "1" ? (
													<div className="help_user_cash_content">
														<Input
															className="form-short-item"
															prefix="返"
															suffix="元"
															value={this.state.help_cash_amount}
															onChange={this.changeHelpCashAmount}
														/>
													</div>
												) : null}
											</div>
										)}
									</Form.Item>
									<Form.Item label="单个用户最多免单次数">
										{getFieldDecorator("user_free_num", {
											initialValue: this.state.activity_res.user_free_num,
											rules: [
												{
													required: true,
													message: "请输入企业名称"
												}
											]
										})(<Input className="form-short-item" suffix="次" />)}
									</Form.Item>
									<Form.Item label="单个用户最多助力次数">
										{getFieldDecorator("user_help_num", {
											initialValue: this.state.activity_res.user_help_num,
											rules: [
												{
													required: true,
													message: "请输入企业名称"
												}
											]
										})(<Input className="form-short-item" suffix="次/天" />)}
									</Form.Item>
								</Form>
							) : null}
							<Form className="form">
								<Form.Item label="页面标题">
									{getFieldDecorator("title", {
										initialValue: this.state.activity_res.title
									})(<Input className="form-item" />)}
								</Form.Item>
								<Form.Item label="分享文案">
									{getFieldDecorator("transfer_title", {
										initialValue: this.state.activity_res.transfer_title
									})(<Input className="form-item" />)}
								</Form.Item>
								<Form.Item
									label="分享图片"
									extra={<div className="form-upload-remind">建议尺寸500*400，仅支持jpg,png 2种格式,单张图片大小不超过3.0MB</div>}>
									{getFieldDecorator("image_url", {
										initialValue: this.state.activity_res.transfer_image_url
									})(
										<div className="iamge-upload">
											<Upload
												accept="image/jpeg,image/jpg,image/png"
												withCredentials={true}
												action={this.state.requestUrl}
												listType="picture-card"
												fileList={this.state.gallery_list2}
												onPreview={this.handleGalleryPreview2}
												onChange={this.handleGalleryChange2}>
												{this.state.gallery_list2.length >= 1 ? null : <UploadButton />}
											</Upload>
											<Modal visible={this.state.preview_gallery_visible2} footer={null} onCancel={this.handleGalleryCancel}>
												<img alt="example" style={{ width: "100%" }} src={this.state.preview_gallery_image2} />
											</Modal>
										</div>
									)}
								</Form.Item>
								{this.state.activity_res.activity_subtype === 'rank_lucky' ? (
									<Form.Item label="补充说明" wrapperCol={{ span: 12 }}>
										{getFieldDecorator('description', {
											initialValue: this.state.activity_res.description
										})(<Input.TextArea autoSize placeholder="拼团抽奖赢大礼" />)}
									</Form.Item>
								) : null}
							</Form>
						</Card>
						<Card
							style={{
								display:
									this.state.activity_res &&
										this.state.activity_res.activity_subtype !== "checkin" &&
										this.state.activity_res.activity_subtype !== "enterGroup"
										? true
										: "none"
							}}
							title={<div className="panel-title">商品信息{this.state.activity_res.activity_subtype === 'rank_lucky' ? '(仅支持选择一个商品)' : null}</div>}>
							<div className="action">
								<Button type="primary" shape="round" onClick={this.showModal}>
									选择商品
                				</Button>
							</div>
							<Table
								columns={this.state.activity_res.activity_subtype === "free_order" ? this.state.free_order_columns : this.state.columns}
								rowKey={record => record.goods_id}
								dataSource={this.state.select_goods_res.filter(item => item.status > 0)}
								pagination={false}
							/>
						</Card>
						<div className="activity-footer">
							<Button type="primary" shape="round" onClick={this.updateActivity.bind(this, null)}>
								保存
              				</Button>
							{cookie.load("mini_app_type") === "flagship_version" &&
								this.state.activity_res.activity_subtype !== "enterGroup" ? null : this.state.activity_res.status ? null : (
									<Button shape="round" onClick={this.updateActivity.bind(this, 1)}>
										保存并立即启用
									</Button>
								)}
							<Button shape="round" onClick={this.goBack.bind(this)}>
								取消
              				</Button>
						</div>
					</div>
					<Modal
						width="1000px"
						visible={this.state.visible}
						onCancel={this.handleCancel}
						onOk={this.handleOk}
						okButtonProps={{ shape: "round" }}
						cancelButtonProps={{ shape: "round" }}>
						<div className="table-content">
							<div className="table-header">
								<Form
									labelCol={{
										span: 12
									}}
									wrapperCol={{
										span: 12
									}}
									layout="inline">
									<Form.Item label="商品名称/商品id">
										<Input value={this.state.search_value} onChange={this.searchHandle} />
									</Form.Item>
									<Form.Item>
										<Button shape="round" onClick={this.queryGoodsList.bind(this)}>
											搜索
                    					</Button>
									</Form.Item>
								</Form>
							</div>
							<Table
								loading={this.state.loading2}
								className="select-goods-modal"
								rowKey={record => record.goods_id}
								bordered={true}
								rowSelection={rowSelection}
								columns={this.state.goods_columns}
								dataSource={this.state.goods_res_list}
							/>
						</div>
					</Modal>
					<Modal visible={this.state.preview_gallery_visible} footer={null} onCancel={this.handleCancel}>
						<img alt="example" style={{ width: "100%" }} src={this.state.preview_image} />
					</Modal>
					<CouponSelectModal
						visible={this.state.coupon_select_visible}
						onCancel={this.cancleCouponModal.bind(this)}
						onOk={this.onCouponOk.bind(this)}
					/>
				</Spin>
			</div>
		);
	}
}
const marketTabpaneForm = Form.create({ name: "market-from" })(MarketTabpane);

export default marketTabpaneForm;
