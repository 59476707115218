import React from 'react'
import '@css/bonusSettleContent.scss'
import {connect} from "react-redux";
import {Form, Input, DatePicker, Select, Button, Table, message, Pagination,Tabs} from 'antd'
import {queryAdminbonusItemList, approveBillList} from '@service/getData'
import cookie from 'react-cookies'
import moment from 'moment';

class BonusSettleContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [{

                title: '订单号',
                width: "16.8%",
                dataIndex: 'order_sn',
                render(text, record) {
                    return record.order_res.order_sn
                }
            }, {

                title: '团长/团员',
                width: "15.04%",
                dataIndex: 'nick_name',
                render(text, record) {
                    return record.leader_res.nick_name
                }
            }, {

                title: '收货时间',
                width: "23.6%",
                dataIndex: 'receive_time',
                render(text, record) {
                    return record.order_res.receive_time
                }
            }, {

                title: '金额',
                width: "12.8%",
                dataIndex: 'amount',
                render(text) {
                    return text / 100
                }
            }, {

                title: '订单类型',
                width: "10%",
                dataIndex: 'bonus_type',
                render(text) {
                    return text ==="promotion_cash"?"佣金":"现金"
                }
            }, {

                title: '订单状态',
                width: "10%",
                dataIndex: 'status',
                render(text) {
                    switch (text) {
                        case 0: return '待结算';
                        case 1: return '待审核';
                        case 2:return '已结算';
                        case 3 :return '审核失败';
                        case 4:return '已失效'
                        default:break;
                    }

                }
            }, {

                title: '操作',
                width: "28.3%",
                dataIndex: 'action',
                render:(text, record) =>(
                        <div>
                            {
                                record.status===1?(
                                    <div>
                                        <p className="text-note" onClick={this.approveBill.bind(this, record, 1)}>结算</p>
                                        <p className="text-note"  onClick={this.approveBill.bind(this, record, 0)}>结算未通过</p>
                                    </div>
                                ):null
                            }
                        </div>

                    )
                }
            ],
            finish_columns: [{

                title: '订单号',
                width: "16.8%",
                dataIndex: 'order_sn',
                render(text, record) {
                    return record.order_res.order_sn
                }
            }, {

                title: '团长',
                width: "15.04%",
                dataIndex: 'nick_name',
                render(text, record) {
                    return record.leader_res.nick_name
                }
            }, {

                title: '收货时间',
                width: "23.6%",
                dataIndex: 'receive_time',
                render(text, record) {
                    return record.order_res.receive_time
                }
            }, {

                title: '金额',
                width: "12.8%",
                dataIndex: 'amount',
                render(text) {
                    return text / 100
                }
            }, {

                title: '状态',
                width: "28.3%",
                dataIndex: 'status_desc'
            }
            ],
            order_list: null,
            publish_start_date: null,
            publish_end_date: null,
            give_start_date: null,
            give_end_date: null,
            selectedRowKeys: [],
            selectedRows: [],
            page: 1,
            page_size: 10,
            total: 0,
            status_list:[
                "待结算待商家审核",
                "待商家审核",
                "审核成功",
                "审核失败",
                "已取消"
            ],
            balance:null,
            status:null
        }

    }

    gotoOrderDetail = (order_id) => {
        this.context.router.history.push("/orderDetail?order_id=" + order_id)
    }
    approveBill = (arr, status) => {
        let bonus_item_id_list = null
        if (Array.isArray(arr)) {
            bonus_item_id_list = arr.map(item =>
                item.bonus_item_id
            )

        } else {
            bonus_item_id_list = [arr.bonus_item_id]
        }
        let data = {
            shop_id: cookie.load("shop_id"),
            approve_status: status,
            bonus_item_id_list: bonus_item_id_list
        }
        this.setState({
            selectedRowKeys: [],
            selectedRows: []
        });
        this.approveBillList(data)

    }

    async queryAdminbonusItemList(data) {
        let res = await queryAdminbonusItemList(data);
        let list=res.result.bonus_item_res_list;
        list.forEach(item=>{
            item.status_desc=this.state.status_list[item.status]
        })
        if (res.success) {
            this.setState({
                order_list: list,
                total: res.result.total,
                selectedRowKeys: [],
                selectedRows: []
            })
        }
    }

    async approveBillList(data) {
        let res = await approveBillList(data)
        if (res.success) {
            message.success(data.approve_status ? "结算成功" : "结算未通过成功")
            this.props.refreshBalance();
            this.filterData()
        }
    }
    onShowTimeChange = (date, dateStr) => {
        this.setState({
            publish_start_date: dateStr[0],
            publish_end_date: dateStr[1],
        })
    }
    onShowGiveTimeChange = (date, dateStr) => {
        this.setState({
            give_start_date: dateStr[0],
            give_end_date: dateStr[1],
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        this.setState({
            selectedRowKeys,
            selectedRows
        });
    }
    onShowPageChange = (page) => {
        this.setState({
            page: page
        }, () => {
            this.filterData()
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.filterData()
        })
    }
    clearFilter = () => {
        this.props.form.resetFields();

    }
    filterData = () => {
        if(!cookie.load("shop_id")&&+cookie.load("is_admin")===1){
            return
        }
        let form = this.props.form.getFieldsValue();
        let data = {
            page_no: this.state.page,
            page_size: this.state.page_size,
            shop_id: cookie.load("shop_id"),
            status: this.state.status,
            bonus_type:form.bonus_type

        }
        if (form.order_sn) {
            data.order_sn = form.order_sn
        }
        if (form.leader_name) {
            data.leader_name = form.leader_name
        }
        if (this.state.publish_start_date) {
            data.start_receive_time = this.state.publish_start_date
        }
        if (this.state.publish_end_date) {
            data.end_receive_time = this.state.publish_end_date
        }
        if (this.state.give_start_date) {
            data.start_verify_time = this.state.give_start_date
        }
        if (this.state.give_end_date) {
            data.end_verify_time = this.state.give_end_date
        }
        if (form.shipment_type) {
            data.shipment_type = form.shipment_type
        }
        if (form.is_refund) {
            data.is_refund = form.is_refund
        }
        if (form.approve_result) {
            data.approve_result = form.approve_result
        }
        this.queryAdminbonusItemList(data)
        window.scrollTo(0,0);
    }
    handleChangeStatus = (e) => {
        this.setState({
            status: e===-1?null:e,
        }, () => {
            this.filterData()
        })
    }

    componentDidMount() {
        this.filterData()
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        const {selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            hideDefaultSelections: true,
            onSelection: this.onSelection,
            selections:{ text:"hah"}
        };
        return (
            <div className="bonus-settle-content">
                <div className="fitlter-content">
                    <Form className="ant-vertical"layout="inline">
                        <Form.Item label="订单号">
                            {getFieldDecorator('order_sn')(
                                <Input className="form-input" placeholder="输入订单号"/>
                            )}
                        </Form.Item>
                        <Form.Item label="团长/团员姓名">
                            {getFieldDecorator('leader_name')(
                                <Input className="form-input" placeholder="输入团长姓名"/>
                            )}
                        </Form.Item>
                        <Form.Item label="收货时间">
                            {getFieldDecorator('date')(
                                <DatePicker.RangePicker
                                    className="form-input-long"
                                    onChange={this.onShowTimeChange}
                                    ranges={{ "今日": [moment(), moment()], '这周': [moment().startOf('week'), moment().endOf('week')] }}
                                    showTime
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="订单类型：">
                            {getFieldDecorator('shipment_type', {
                                initialValue: "all_shipment"
                            })(
                                <Select className="goods-id">
                                    <Select.Option value="all_shipment">全部</Select.Option>
                                    <Select.Option value="group_shipment">自提订单</Select.Option>
                                    <Select.Option value="self_shipment">邮寄订单</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="资产类型">
                            {getFieldDecorator('bonus_type', {
                                initialValue: "promotion_cash"
                            })(
                                <Select className="goods-id">
                                    <Select.Option value="promotion_cash">佣金</Select.Option>
                                    <Select.Option value="free_order_reward_cash">现金</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Form>
                    <div className="action">
                        <Button shape="round" type="primary" onClick={this.onShowPageChange.bind(this,1)}>查询</Button>
                        <span className="text-note" onClick={this.clearFilter}>清空筛选条件</span>
                    </div>
                </div>
                <Tabs defaultActiveKey={this.state.status} onChange={this.handleChangeStatus}
                >
                    <Tabs.TabPane tab="全部" key="-1"></Tabs.TabPane>
                    <Tabs.TabPane tab="待结算" key="0"></Tabs.TabPane>
                    <Tabs.TabPane tab="待审核" key="1"></Tabs.TabPane>
                    <Tabs.TabPane tab="审核不通过" key="3"></Tabs.TabPane>
                    <Tabs.TabPane tab="已结算 " key="2"></Tabs.TabPane>
                    <Tabs.TabPane tab="已失效" key="4"></Tabs.TabPane>
                </Tabs>
                <div className="action" style={{display:+this.props.approve_status===0?true:"none"}}>
                    <Button shape="round"
                            disabled={this.state.selectedRows.length ? false : true}
                            onClick={this.approveBill.bind(this, this.state.selectedRows, 1)}>批量结算</Button>
                    <span>当前已选择{this.state.selectedRows.length}项</span>
                </div>
                <Table
                    className="table"
                    rowKey={(record, index) => index}
                    columns={this.props.approve_status?this.state.finish_columns:this.state.columns}
                    dataSource={this.state.order_list}
                    pagination={false}
                    bordered={true}
                    rowSelection={+this.props.approve_status===0?rowSelection:null}
                />
                <Pagination
                    className="pagenation"
                    onChange={this.onShowPageChange}
                    onShowSizeChange={this.onShowSizeChange}
                    defaultCurrent={this.state.page}
                    total={this.state.total}
                    showTotal={(total) => `共 ${total} 条`}
                    pageSizeOptions={["10", "30", "50", "100"]}
                    showSizeChanger
                />
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
        order_status_list: state.orderStatusList
    }
}

const BonusSettleForm = Form.create({name: 'bonus_settle_form'})(BonusSettleContent);

export default connect(mapStateToProps)(BonusSettleForm);
