import React from 'react'
import ShopManage from "../shopManage/ShopManage";
import NavBar from "@/view/component/common/NavBar.js";
import { connect } from "react-redux";
import { Tabs } from "antd";
import CampainSetting from '../activitySetting/CampainSetting'
import GroupGoodsManage from '../groupGoodsManage/GroupGoodsManage'
import ShopNav from './children/ShopNav'
import '@css/shopPainting.scss'
import CartStyle from './children/CartStyle';
import CenterStyle from './children/CenterStyle';
import UserPageManage from "@/view/container/userPageManage/UserPageManage";
class ShopPaintingV2 extends React.Component {
    constructor() {
        super();
        this.state = {
            active_key: '1',
            subActiveKey: '1',

        }
    }
    onChange = (active_key) => {
        this.setState({
            active_key
        })
    }
    onSubChange(subActiveKey) {
        this.setState({
            subActiveKey,
        });
    }
    render() {
        let { menu_code } = this.props;
        return (
            <div className="shop-painting">
                <NavBar
                    icon={"icon-dianpu2"}
                    child={menu_code.shop_setting}
                />
                <div className="content">
                    <Tabs
                        activeKey={this.state.active_key}
                        onChange={this.onChange}
                        tabBarStyle={{ backgroundColor: "#ffffff", borderRadius: "4px" }}
                        size={"large"}
                    >
                        <Tabs.TabPane tab="主题" key="1">
                            <ShopManage />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="菜单导航" key="2">
                            <ShopNav />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="自定义页面" key="3">
                            <CampainSetting />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="系统页面" key="4">
                            <Tabs
                                activeKey={this.state.subActiveKey}
                                onChange={this.onSubChange.bind(this)}
                                tabBarStyle={{ backgroundColor: "#F8F8F8", borderRadius: "4px" }}
                                size="small"
                            >
                                <Tabs.TabPane tab="购物车" key='1'>
                                    <CartStyle></CartStyle>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="个人中心" key='2'>
                                    <CenterStyle></CenterStyle>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="分类页面" key='3'>
                                    <GroupGoodsManage />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="商品详情页" key='4'>
                                    <UserPageManage />
                                </Tabs.TabPane>
                            </Tabs>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {
        menu_code: state.menuCode
    };
};
export default connect(mapStateToProps)(ShopPaintingV2);
