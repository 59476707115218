import React, { Fragment } from 'react'
import { connect } from "react-redux";
import { Form, Button, message, Radio, Icon } from "antd/lib/index";
import '@css/activity.scss'
import BannerContent from './BannerContent'
import cookie from 'react-cookies'
import {
    createOrUpdateActivity,
    queryActivityDetail,
    queryCampaignActivitySubtype,
    queryCampaignList,
    queryMiniAppDetail,

} from '@service/getData'
import ColorPicker from '@/view/component/common/ColorPicker'
import TargetTypeModal from '@/view/component/Campaign/TargetTypeModal'

class LiveActivity extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type: null,
            loading2: false,
            button_back_value: '#0D87EB',
            button_back_style: 0,
            visible: false,
            activity_list: [],
            current_index: null,
            target_type_visible: false


        }
    }

    createOrUpdateActivity = async () => {
        let flag = false
        let activity_list = this.state.activity_list.map((item, index) => {
            if (!item.image_url || !item.room_info_id) {
                flag = true;
            }
            return {
                activity_room_info_id: item.activity_room_info_id,
                room_info_id: item.room_info_id,
                status: item.status,
                sort_num: index,
                image_url: item.image_url
            }
        })
        if (flag) {
            return message.error("信息填写不全")

        }
        let data = {
            activity_req: {
                campaign_id: this.props.campaign_id,
                mini_app_id: cookie.load("mini_app_id"),
                activity_name: "直播",
                activity_id: this.props.activity_id,
                activity_type: "campaign",
                activity_subtype: "roomInfoList",
                status: 1,
                button_back_style: this.state.button_back_style,
                button_back_value: this.state.button_back_style === 1 ? this.state.button_back_value : "#0D87EB",
                activity_room_info_req_list: activity_list
            }
        }
        this.setState({
            loading: true,
            exchange_disabled: false
        })
        let res = await createOrUpdateActivity(data)
        if (res.success) {
            this.props.form.resetFields();
            if (this.state.type === "edit") {
                message.success('组件更新成功');
            } else {
                message.success('成功添加组件');
            }
            this.props.onOK()
        } else {
            message.error(res.msg);
        }
    }

    async queryMiniAppDetail() {
        let mini_app_id = cookie.load("mini_app_id");
        let res = await queryMiniAppDetail({
            mini_app_id
        });
        this.setState({
            mini_app_name: res.result.mini_app.mini_app_name,

        });
    }

    queryActivityDetail = async (activity_id) => {
        let data = {
            activity_id
        }
        let res = await queryActivityDetail(data);
        if (res.success) {
            let { activity_room_info_res_list } = res.result.activity_res;
            let activity_list = activity_room_info_res_list.map((item, index) => {
                let gallery_list;
                if (item.image_url) {
                    gallery_list = [
                        {
                            uid: '-1',
                            name: 'xxx.png',
                            status: 'done',
                            url: item.image_url
                        },
                    ];
                }
                item.gallery_list = gallery_list;
                return {
                    gallery_list,
                    activity_room_info_id: item.activity_room_info_id,
                    room_info_id: item.room_info_id,
                    ...item.room_info_res,
                    target_type: "roomInfoList",
                    desc: `直播:${item.room_info_res.name}`,
                    status: 1,
                    image_url: item.image_url,
                    index
                }
            })
            let { button_back_style, button_back_value } = res.result.activity_res
            this.setState({
                activity_res: res.result.activity_res,
                loading: false,
                type: "edit",
                activity_list,
                button_back_value,
                button_back_style,

            })
        }
    }
    queryCampaignList = async () => {
        let data = {
            mini_app_id: cookie.load("mini_app_id")
        }
        let res = await queryCampaignList(data)
        if (res.success) {
            let campaign_list = res.result.campaign_res_list;
            campaign_list = campaign_list.filter(item => item.campaign_id !== Number(this.state.campaign_id))
            this.setState({
                campaign_list
            })
        }

    }
    queryCampaignActivitySubtype = async () => {
        let data = {}
        let res = await queryCampaignActivitySubtype(data);
        let list = res.result.campaign_activity_subtype_res_list
        list.push({
            "activity_subtype": "coupon",
            "activity_subtype_desc": "优惠券。",
            "activity_subtype_name": "优惠券",
            "app_id": "",
            "auto_create": 0,
            "base_version": 0,
            "campaign_activity_subtype_id": 8,
            "flagship_version": 1,
            "mini_app_id": null,
            "special_version": 0,
            "status": 1
        })
        if (res.success) {
            this.setState({
                campaign_activity_subtype_res_list: res.result.campaign_activity_subtype_res_list,
                loading: this.state.type === "edit" ? true : false
            })

        }
    }
    onChangeButtonBackStyle = (e) => {
        this.setState({
            button_back_style: e.target.value
        })
    }
    handleOK = (color) => {
        if (this.state.property === "core_color" && (color.toLowerCase() === "#ffffff" || color.toLowerCase() === "#fff")) {
            return message.error("不能选择白色")
        }
        this.setState({
            visible: false,
            button_back_value: color

        })
    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    showModal = (property) => {
        this.setState({
            visible: true,
            property
        })
    }
    eidtActivityList = (index, status) => {
        let activity_list = this.state.activity_list.concat()
        let activity = activity_list[index]
        if (status === 0) {
            if (activity.activity_room_info_id) {
                activity_list[index].status = 0
            } else {
                activity_list.splice(index, 1)
            }
        } else if (status === 1) {
            activity_list.push({
                mini_app_id: cookie.load("mini_app_id"),
                activity_name: "直播",
                activity_type: "campaign",
                activity_subtype: "roomInfoList",
                target_type: null,
                status: 1,
                image_url: null,
                exchange_disabled: false,
                desc: null,
            })
        }
        activity_list.forEach((item, index) => {
            item.index = index;
        })
        this.setState({
            activity_list
        })
    }
    deleteTargetType = (index) => {
        let activity_list = this.state.activity_list.concat()
        activity_list[index].room_info_id = null
        activity_list[index].target_type = null
        activity_list[index].desc = null
        this.setState({
            activity_list
        })
    }
    sortActivityList = (index, position) => {
        let activity_list = this.state.activity_list.concat()
        if (position === -1) {
            activity_list.splice(index - 1, 1, ...activity_list.splice(index, 1, activity_list[index - 1]))
        } else if (position === 1) {
            activity_list.splice(index, 1, ...activity_list.splice(index + 1, 1, activity_list[index]))
        } else {
            activity_list.splice(0, 0, ...activity_list.splice(index, 1))
        }
        activity_list.forEach((item, index) => {
            item.index = index;
        })
        this.setState({
            activity_list
        })

    }
    showTargetTypeModal = (current_index) => {
        this.setState({
            target_type_visible: true,
            current_index
        })
    }
    onOk = (data) => {
        let activity_list = this.state.activity_list.concat()
        activity_list[this.state.current_index].target_type = data.target_type;
        switch (data.target_type) {
            case 'campaign':
                activity_list[this.state.current_index].dest_campaign_id = data.dest_campaign_id;
                activity_list[this.state.current_index].desc = data.desc
                break;
            case 'goods':
                activity_list[this.state.current_index].goods_id = data.goods_id
                activity_list[this.state.current_index].desc = data.desc
                break;
            case 'tag':
                activity_list[this.state.current_index].tag_id = data.tag_id
                activity_list[this.state.current_index].desc = data.desc
                break;
            case 'roomInfoList':
                activity_list[this.state.current_index].room_info_id = data.room_info_id
                activity_list[this.state.current_index].desc = data.desc
                break
            default:
                activity_list[this.state.current_index].desc = data.desc
                break
        }
        this.setState({
            target_type_visible: false,
            activity_list
        })
    }
    onCancel = () => {
        this.setState({
            target_type_visible: false
        })
    }
    submitForm = (index, key, value) => {
        let activity_list = this.state.activity_list.concat()
        activity_list[index][key] = value
        if (key === "gallery_list") {
            let gallery_url = null
            value.forEach(item => {
                if (item.response && item.response.success) {
                    gallery_url = item.response.result.oss_file_url
                } else if (item.url) {
                    gallery_url = item.url
                }
            })
            activity_list[index].image_url = gallery_url;

        }
        activity_list.forEach((item, index) => {
            item.index = index;
        })
        this.setState({
            activity_list
        })
    }
    componentDidMount() {
        this.queryCampaignActivitySubtype();
        this.queryCampaignList()
        this.queryMiniAppDetail();
        this.props.onRef('liveActivity', this)
    }

    render() {
        return (
            <div className="activity-add">
                <div className="date-time-limit-buy">
                    <Form>
                        <Form.Item label="按钮背景色"
                            extra={
                                <div style={{ display: this.state.button_back_style === 1 ? true : 'none' }} className="text-danger">
                                    注：不能选白色
                                       </div>}>
                            <Fragment>
                                <Radio.Group value={this.state.button_back_style} onChange={this.onChangeButtonBackStyle}>
                                    <Radio value={0}>系统配色</Radio>
                                    <Radio value={1}>自定义</Radio>
                                </Radio.Group>
                                <div style={{ display: this.state.button_back_style === 1 ? true : 'none' }} >
                                    自定义：
                                    <div className='color-choose' onClick={this.showModal.bind(this, "core_color")}>
                                        <span className="color"
                                            style={{ "background": this.state.button_back_value }}
                                        >
                                        </span>
                                        {this.state.button_back_value}
                                        <Icon className="color-picker-icon" type="caret-down" />
                                    </div>

                                </div>
                            </Fragment>

                        </Form.Item>
                    </Form>
                </div>
                {
                    this.state.activity_list.filter(item => item.status === 1).map((item, index) =>
                        <BannerContent
                            deleteTargetType={this.deleteTargetType}
                            key={index}
                            list_key={index}
                            title={`直播`}
                            uploadText='直播'
                            submitForm={this.submitForm}
                            gallery_list={item.gallery_list}
                            gallery_list2={item.gallery_list2}
                            target_type={item.target_type}
                            eidtActivityList={this.eidtActivityList}
                            activity_id={item.activity_id}
                            exchange_disabled={item.exchange_disabled}
                            index={item.index}
                            showTargetTypeModal={this.showTargetTypeModal}
                            length={this.state.activity_list.filter(item => item.status === 1).length}
                            sortActivityList={this.sortActivityList}
                            desc={item.desc}
                            activity_subtype="roomInfoList"
                            activity_name={item.activity_name}
                        />
                    )
                }
                <Button type="dashed"
                    disabled={this.state.activity_list.filter(item => item.status === 1).length === 10}
                    className="button-activity-goods"
                    onClick={this.eidtActivityList.bind(this, 0, 1)}
                >+添加直播
                </Button>

                <div className="action-save">
                    <Button
                        type="primary"
                        className="btn-save"
                        onClick={this.createOrUpdateActivity}
                    >提交</Button>
                </div>
                <ColorPicker
                    visible={this.state.visible}
                    onOk={this.handleOK}
                    onCancel={this.handleCancel}
                />
                <TargetTypeModal
                    title="选择直播"
                    activity_subtype="roomInfoList"
                    visible={this.state.target_type_visible}
                    onOK={this.onOk}
                    onCancel={this.onCancel}
                />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
        activity_subtype: state.activitySubtype

    }
}
const liveActivityForm = Form.create({ name: 'live_activity' })(LiveActivity);

export default connect(mapStateToProps)(liveActivityForm);
