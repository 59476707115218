import React from "react";
import "@css/campaignShow.scss";
import { Tag, Popconfirm } from "antd";
import Sortable from "react-sortablejs";
import CampaignBanner from "./CampaignBanner";
import CampaignFixed from "./CampaignFixed";
import CampaignGoodsSlider from "./CampaignGoodsSlider";
import CampaignCoupon from "./CampaignCoupon";
import CampaignVideo from "./CampaignVideo";
import CampaignIconLinkList from "./CampaignIconLinkList";
import CampaignLive from "./CampaignLive";
import CampaignAdvertisement from "./CampaignAdvertisement";

// 注意：更改此页面的逻辑，如果影响到UI展示的更改，记得同步更改预览组件（CampaignShow）

class CampaignShowPhone extends React.Component {
	constructor() {
		super();
		this.state = {
			activity_list: []
		};
	}
	queryActivityList = activity_list => {
		activity_list = activity_list.filter(item => item.activity_subtype !== "topScroll");
		activity_list = activity_list.filter(item => item.activity_subtype !== "endScroll");
		activity_list.forEach(item => {
			if (item.activity_subtype === "scroll" || item.activity_subtype === "advertisement") {
				item.url_list = item.activity_res_list.map(qitem => {
					return qitem.thumbnail_url || '';
				});
				item.default_url = ["http://udh.oss-cn-hangzhou.aliyuncs.com/ba1f25aa-21bf-4628-94e1-8cc5f7d72970"];
			}
			if (item.activity_subtype === "iconLinkList") {
				item.thumbnail_url = "http://udh.oss-cn-hangzhou.aliyuncs.com/74359521-5e6b-404e-8e7e-33ae7351a782";
			}
			if (item.activity_subtype === "roomInfoList") {
				item.thumbnail_url = "http://udh.oss-cn-hangzhou.aliyuncs.com/f9d1c529-a89e-4c0f-8860-164ff0813e36";
			}
			if (item.activity_subtype === "roomInfoList") {
				let activity_room_info_res_list = item.activity_room_info_res_list.map(qitem => {
					return {
						...qitem,
						...qitem.room_info_res
					};
				});
				item.activity_room_info_res_list = activity_room_info_res_list;
			}
		});
		this.setState({
			activity_list
		});
	};
	UNSAFE_componentWillReceiveProps(props) {
		if (props.campaign && props.campaign.campaign_id && props.activity_list.length) {
			this.queryActivityList(props.activity_list);
		}
	}
	swapArray(arr, newIndex, oldIndex) {
		arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
		return arr;
	}
	changeSort(order, sortable, evt) {
		let list = this.swapArray([...this.state.activity_list], evt.newIndex, evt.oldIndex);
		this.setState({
			activity_list: list
		});
		this.props.dragActivity && this.props.dragActivity(evt.newIndex, evt.oldIndex, list);
	}
	dragover_handler = ev => {
		ev.persist();
		ev.preventDefault();
		let className = ev.target.parentNode.className;
		if (className.indexOf("activity") !== -1) {
			if (className.indexOf("hover") !== -1) return;
			ev.target.parentNode.className = className + " hover";
		}
	};
	drop_handler = ev => {
		ev.persist();
		ev.preventDefault();
		let type = ev.dataTransfer.getData("activity_type"),
			index = +ev.target.dataset.index;
		let className = ev.target.parentNode.className;
		if (className.indexOf("activity") !== -1) {
			ev.target.parentNode.className = "activity";
			if (!type) return;
			this.props.createActivity &&
				this.props.createActivity(type, () => {
					let newIndex = index + 1,
						oldIndex = this.state.activity_list.length - 1;
					let activity_list = this.swapArray([...this.state.activity_list], newIndex, oldIndex);
					this.setState({
						activity_list
					});
					this.props.dragActivity && this.props.dragActivity(newIndex, oldIndex, activity_list, 1);
				});
		} else if (className === "iphone-content") {
			this.props.createActivity && this.props.createActivity(type);
		}
	};
	dragleave_handler = ev => {
		ev.persist();
		ev.preventDefault();
		let className = ev.target.parentNode.className;
		if (className.indexOf("activity") !== -1) {
			ev.target.parentNode.className = "activity";
		}
	};
	render() {
		return (
			<div className="campaign-show-phone" style={{ height: this.props.window_height }}>
				<div className="iphone-content">
					<div className="iphone-title">
						<div className="iphone-title-content" />
					</div>
					<div
						className="phone-content"
						id="dragTarget"
						onDrop={this.drop_handler}
						onDragOver={this.dragover_handler}
						onDragLeave={this.dragleave_handler}>
						<Sortable
							options={{
								animation: 150,
								scroll: true,
								scrollSensitivity: 50,
								handle: ".drag-btn"
							}}
							className="drag-sort"
							onChange={this.changeSort.bind(this)}>
							{this.state.activity_list.map((item, index) => (
								<div
									className={"activity" + (item.activity_id === this.props.activity_id ? " current-activity" : "")}
									key={item.activity_id}
									data-id={item.activity_id}
									onClick={this.props.editComponentShow.bind(this, item.activity_subtype, item.activity_id)}>
									<div className="drag-btn" data-index={index} />
									{item.activity_subtype === "scroll" ? (
										<CampaignBanner pattern_type={item.pattern_type} default_url={item.default_url} url_list={item.url_list || []} />
									) : item.activity_subtype === "buyEveryDay" ||
										item.activity_subtype === "memberDay" ||
										item.activity_subtype === "timeLimitBuy" ? (
												<CampaignFixed campaign={item} show_type={this.props.show_type} />
											) : item.activity_subtype === "goodsListSlide" ? (
												<CampaignGoodsSlider goods={item} />
											) : item.activity_subtype === "coupon" && item.activity_coupon_res_list.length !== 0 ? (
												<CampaignCoupon coupon_list={item.activity_coupon_res_list} url={item.image_url} />
											) : item.activity_subtype === "iconLinkList" && item.activity_res_list.length ? (
												<CampaignIconLinkList activity_list={item.activity_res_list} />
											) : item.activity_subtype === "roomInfoList" && item.activity_room_info_res_list.length ? (
												<CampaignLive
													button_back_value={item.button_back_value}
													activity_room_info_res_list={item.activity_room_info_res_list}
												/>
											) : item.activity_subtype === "advertisement" ? (
												<CampaignAdvertisement template_type={item.template_type} url_list={item.url_list ? item.url_list : []} />
											) : item.activity_subtype === 'video' ? (
												<CampaignVideo
													poster={item.video_pic}
													url={item.video_url}
												></CampaignVideo>
											) : (
																		<div>
																			<img alt="" width="270px" src={item.thumbnail_url} />
																		</div>
																	)
									}
									<div className="activity-action">
										<Popconfirm
											title="确定要删除此组件吗?"
											onConfirm={this.props.deleteActivity.bind(this, item.activity_id, index)}
											onCancel={this.props.cancel}
											okText="确定"
											cancelText="取消">
											<Tag className="btn-component">
												<i className="iconfont icon-cha" />
											</Tag>
										</Popconfirm>
									</div>
								</div>
							))}
						</Sortable>
					</div>
					<div className="iphone-nav" />
				</div>
			</div>
		);
	}
}

export default CampaignShowPhone;
