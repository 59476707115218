import React from 'react'
import {connect} from "react-redux";
import NavBar from '@/view/component/common/NavBar.js'
import '@css/bonusRecharge.scss'
import {Button,Input,message,Modal} from 'antd'
import * as QrCode from 'qrcode.react'
import {createShopBonusOrder,queryAdminOrderDetail} from '@service/getData'
import cookie from 'react-cookies'
import {formatNumber} from '@common/common'
class  BonusRecharge extends React.Component{
    constructor(props){
        super(props)
        this.state={
            money:null,
            visible: false,
            lessTime: '30:00',
            qr_code:null,
            admin_order_id:null,
            input_money:null,
            modal_visible:false
        }
    }
    async createShopBonusOrder(data){
        let res=await createShopBonusOrder(data)
        if(res.result){
            let code_url=JSON.parse(res.result.pay_form).code_url
            this.setState({
                qr_code:code_url,
                admin_order_id:res.result.admin_order_id
            },()=>this.showModal())
        }

    }
    async queryAdminOrderDetail(data){
        let  res=await queryAdminOrderDetail(data)
        if(res.success){
            if(res.result.admin_order.order_status===2|| res.result.admin_order.order_status===3){
                message.success("充值成功")
                this.handleCancel();
            }
        }
    }
    recharge=()=>{
        let data={
            admin_Order:{
                mini_app_id:cookie.load("mini_app_id"),
                quantity:Math.round(this.state.money*100)
            },
            return_url: window.location.href
        }
      this.createShopBonusOrder(data)
    }
    changeMoney=(data)=>{
        this.setState({
            money:data,
            input_money:null
        })
    }
    change=(e)=>{
        let value=formatNumber(e.target.value)
        this.setState({
            money:value,
            input_money:value
        })

    }

    showModal = () => {
        this.setState({
            visible: true,
        });
        let allTime = 60*30
        this.timer = setInterval(()=>{
            --allTime
            this.setState({
                lessTime: this.returnNum(Math.floor(allTime/60)) + ":" + this.returnNum(allTime%60),
            });
            let data={
                admin_order_id:this.state.admin_order_id
            }
            this.queryAdminOrderDetail(data);
            if (allTime === 0) {
                clearInterval(this.timer)
            }
        }, 1000)
    }
    returnNum=(num)=>{
        return num<10?('0'+num):num
    }

    handleOk = (e) => {
        this.setState({
            visible: false,
            money:null,
            input_money:null
        });
        clearInterval(this.timer)
    }

    handleCancel = (e) => {
        this.setState({
            visible: false,
            money:null,
            input_money:null
        });
        clearInterval(this.timer)
    }
    showExampleModal=()=>{
        this.setState({
            modal_visible: true
        });
    }
    handleModalOk = (e) => {
        this.setState({
            modal_visible: false
        });
    }

    handleModalCancel = (e) => {
        this.setState({
            modal_visible: false

        });
    }


    render(){
        let {menu_code} = this.props
        return (
            <div className="bonus-recharge">
                <NavBar  icon="icon-zichan"
                         father="店铺资金"
                         router="assetsManage"
                         child={menu_code.bonus_recharge}/>
                <div className="content">
                    <div className="bonus-recharge-title">
                        <i className="icon-tubiao11 iconfont"></i>
                        <div className="text-content">
                            <div
                                className="title">
                                充值
                            </div>
                            <div>充值金额将作为店铺资金，用于营销活动费用发放给用户，请预先充值，无手续费。</div>
                        </div>
                    </div>
                    <div className="recharge-content">
                      <div className="recharge-kind">
                          <span className="kind-title">选择充值金额：</span>
                          <Button shape="round" onClick={this.changeMoney.bind(this,500)}>500</Button>
                          <Button shape="round" onClick={this.changeMoney.bind(this,1000)}>1000</Button>
                          <Button shape="round" onClick={this.changeMoney.bind(this,2000)}>2000</Button>
                      </div>
                        <div className="recharge-kind">
                            <span className="kind-title">其他金额：</span>
                            <Input  value={this.state.input_money}onChange={this.change}/>
                        </div>
                    </div>
                    <div className="action">
                        <Button shape="round" disabled={this.state.money?false:true} type="primary" onClick={this.recharge}>
                            充 值
                        </Button>
                    </div>
                </div>
                <Modal
                    className="recharge-modal"
                    title="微信支付"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okButtonProps={{shape:'round'}}
                    cancelButtonProps={{shape:'round'}}
                >
                    <div className="wxscan">
                        <div className="scan">
                            <div className="tip"></div>
                            <div className="codeimg">
                                <QrCode value={this.state.qr_code} size={220} />
                            </div>

                        <p className="tip2">为避免系统自动取消订单，请在 <i id="order-countdown" className="s-fc6">{this.state.lessTime}</i> 内进行付款</p>
                    </div>
                    <div className="rphone"></div>
            </div>
                </Modal>
                <Modal
                    className="example-modal"
                    title="团长佣金提现示例"
                    visible={this.state.modal_visible}
                    onOk={this.handleModalOk}
                    onCancel={this.handleModalCancel}
                    okButtonProps={{shape:'round'}}
                    cancelButtonProps={{shape:'round'}}
                >
                    <ul>
                        <li>
                            <p>查看余额</p>
                            <div>
                                <img alt="" src="http://udh.oss-cn-hangzhou.aliyuncs.com/1772e9d3-efb9-4e2f-b5e1-ec024ca40753"/>
                            </div>

                        </li>
                        <li>
                            <p>查看可提现余额</p>
                            <div>
                                <img alt="" src="http://udh.oss-cn-hangzhou.aliyuncs.com/cc776b37-747a-437b-bbd0-ed1794258d1b"/>
                            </div>

                        </li>
                        <li>
                            <p>提现</p>
                            <div>
                                <img alt="" src="http://udh.oss-cn-hangzhou.aliyuncs.com/02b6fa8a-456d-49fb-805e-e0af409a5ff4"/>
                            </div>

                        </li>
                        <li>
                            <p>提现成功到账</p>
                            <div>
                                <img alt="" src="http://udh.oss-cn-hangzhou.aliyuncs.com/9d1836ef-d3ee-476b-8af2-415d199649a8"/>
                            </div>


                        </li>
                    </ul>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
    }
}
export default connect(mapStateToProps)(BonusRecharge);
