import  React from 'react'
import {Modal} from 'antd'
import '@css/rechargeModal.scss'
import * as QrCode from 'qrcode.react'
class RechargeModal extends  React.Component{
    constructor(props){
        super();
        this.state={
            less_time:null
        }
    }
    render(){
        return(
            <Modal
                className="recharge-modal"
                title="微信支付"
                visible={this.props.visible}
                onOk={this.props.handleOk}
                onCancel={this.props.handleCancel}
                okButtonProps={{shape:'round'}}
                cancelButtonProps={{shape:'round'}}
            >
                <div className="wxscan">
                    <div className="scan">
                        <div className="tip"></div>
                        <div className="codeimg">
                            <QrCode value={this.props.qr_code} size={220} />
                        </div>

                        <p className="tip2">为避免系统自动取消订单，请在 <i id="order-countdown" className="s-fc6">{this.props.less_time}</i> 内进行付款</p>
                    </div>
                    <div className="rphone"></div>
                </div>
            </Modal>
        )
    }
}
export default  RechargeModal
