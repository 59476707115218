import React from 'react'
import NavBar from '@/view/component/common/NavBar.js'
import {Form, Table, Pagination, Button,Modal,Input,message,Popconfirm,Icon,Tooltip} from 'antd'
import {connect} from "react-redux";
import {createOrUpdateTag,queryTagList} from '@service/getData'
import cookie from 'react-cookies'
import '@css/goodsListPageDetail.scss'
import PropTypes from 'prop-types'
class GoodsListPage extends React.Component {
    constructor() {
        super()
        GoodsListPage.contextTypes = {
            router: PropTypes.object.isRequired
        }
        this.state = {
            columns: [{
                title: '商品列表页名称',
                dataIndex: 'tag_name',
                key: 'tag_name',
                render:(text,record)=>(
                    <div className="tag-name">
                        {text}
                        {
                            text!=="全部商品"&&text!=="隐藏商品"?(
                                <Popconfirm
                                    title={
                                        <Input
                                            style={{"width":"84px","borderRadius":"100px"}}
                                            value={this.state.tag_name}
                                            onChange={this.changeTagName}
                                        />
                                    }
                                    okText="确认"
                                    cancelText="取消"
                                    onConfirm={this.createOrUpdateTag.bind(this,record.tag_id,1,this.state.tag_name)}
                                    onCancel={this.cancel}
                                    icon={null}>
                                    <i className="iconfont icon-bianji" ></i>
                                </Popconfirm>
                            ):(
                                <Tooltip
                                    title={text==="全部商品"?"店内所有商品":"此类商品将在商品列表中隐藏"}
                                >
                                    <Icon type="question-circle" theme="twoTone" />
                                </Tooltip>
                            )
                        }
                    </div>
                )
            },{
                title: '商品数',
                dataIndex: 'goods_num',
                key: 'goods_num',
            },{
                title: '管理商品',
                dataIndex: 'tag_id',
                key: 'tag_id',
                render:(value,row)=> {
                  return  row.tag_name!=="全部商品"?(
                        <div>
                            <span className="text-note" onClick={this.goGoodsDetail.bind(this,value,row.tag_name,0)}>查看</span>
                            <span className="text-note" onClick={this.goGoodsDetail.bind(this,value,row.tag_name,1)}>添加</span>
                        </div>
                    ):null
                }
            },{
                title: '创建时间',
                dataIndex: 'created_at'
            }
             ,{
                title: '操作',
                dataIndex: 'delete',
                key: 'delete',
                render:(value,row)=>{
                    return (
                        <div>
                            {
                                row.tag_name!=="全部商品"&&row.tag_name!=="隐藏商品"?(
                                    <span className="text-note" onClick={this.createOrUpdateTag.bind(this,row.tag_id,0,null)}>删除</span>
                                ):null
                            }
                        </div>
                        )
                    }
            }],
            page: 1,
            page_size: 10,
            total: 0,
            visible:false,
            tag_list:[],
            tag_name:null,
        }

    }
    createOrUpdateTag= async (tag_id,status,tag_name)=>{
        let data={}
        if(tag_id){
            data={
                tag_req:{
                    mini_app_id:cookie.load("mini_app_id"),
                    tag_id,
                    tag_name,
                    status:status
                }
            }
        }else{
            let form=this.props.form.getFieldsValue();
            let flag = false
            this.props.form.validateFields(err => {
                if (err) {
                    flag = true
                }
            });
            if (flag) return
            data={
                tag_req:{
                    mini_app_id:cookie.load("mini_app_id"),
                    tag_name:form.tag_name,
                }
            }
        }
        let res =await createOrUpdateTag(data);
        if(res.success){
            if(!tag_id){
                message.success("成功添加商品列表页")
            }else if(status===0){
                message.success("成功删除商品列表页")
            } else{
                message.success("成功修改商品列表页")
            }
            this.setState({
                visible:false,
                tag_name:null
            })
            this.queryTagList()
        }
    }
    queryTagList=async ()=>{
       let data={
           mini_app_id:cookie.load("mini_app_id"),
           page_no:this.state.page,
           page_size:this.state.page_size
       }
       let res=await queryTagList(data)
        if(res.success){
           this.setState({
                tag_list:res.result.tag_res_list,
                total:res.result.total
           })
        }
    }

    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.queryTagList()
        })

    }
    onShowPageChange = (page) => {
        this.setState({
            page: page
        }, () => {
            this.queryTagList()
        })

    }
    cancel=()=>{
        this.setState({
            visible:false,
            tag_name:null
        })
    }
    showModal=()=>{
        this.setState({
            visible:true
        })
    }
    goGoodsDetail=(tag_id,tag_name,status)=>{
        let url=`/goodsListPageDetail?tag_id=${tag_id}&tag_name=${tag_name}&status=${status}`
        this.context.router.history.push(url)
    }
    changeTagName=(e)=>{

        this.setState({
            tag_name:e.target.value
        })
    }
    componentDidMount(){
        this.queryTagList()
    }
    render() {
        let {menu_code} = this.props
        const {getFieldDecorator} = this.props.form;
        return (
            <div className="page goods-list-page">
                <NavBar icon="icon-shangpinguanli" child={menu_code.goods_list_page}/>
                <div className="content">
                    <div className="action">
                        <Button
                            shape="round"
                            type="primary"
                            onClick={this.showModal}
                        >新建商品列表页</Button>
                    </div>
                    <Table
                        rowKey={(record, index) => index}
                        dataSource={this.state.tag_list}
                        columns={this.state.columns}
                        pagination={false}
                    />
                    <Pagination
                        className="pagenation"
                        onChange={this.onShowPageChange}
                        onShowSizeChange={this.onShowSizeChange}
                        defaultCurrent={this.state.page_no}
                        total={this.state.total}
                        showTotal={(total) => `共 ${total} 条`}
                        pageSizeOptions={["10", "30", "50", "100"]}
                        showSizeChanger
                    />

                </div>
                <Modal
                    visible={this.state.visible}
                    title="新建商品列表页"
                    className="add-goods-list-page"
                    destroyOnClose={true}
                    onCancel={this.cancel}
                    onOk={this.createOrUpdateTag.bind(this,null,null,null)}
                    okButtonProps={{shape:'round'}}
                    cancelButtonProps={{shape:'round'}}
                >
                    <Form>
                        <Form.Item label="商品列表页名称">
                            {getFieldDecorator('tag_name', {
                                initialValue:null,
                                rules: [{
                                    required: true,
                                    message: "请填写列表页名称!"
                                }]
                            })(
                                <Input className="input-name"/>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>

        )
    }
}

const GoodsListPageForm = Form.create({name: 'goods_list__page_form'})(GoodsListPage);
const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode

    }
}

export default connect(mapStateToProps)(GoodsListPageForm);
