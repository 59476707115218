import React from 'react'
import { Spin, Tabs } from 'antd';
import NavBar from "@/view/component/common/NavBar";
import Grade from '@/view/container/client/children/Grade';
import Task from '@/view/container/client/children/Task';
import IntegralManageV2 from "@/view/container/integralManage/IntegralManageV2";
import SceneMarketingDetailV2 from '@/view/container/sceneMarketing/SceneMarketingDetailV2'

const { TabPane } = Tabs;
export default class VipMarketing extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  render () {
    return <div className="gradeManage">
      <Spin spinning={this.state.loading}>
        <NavBar icon="icon-kehu" child="会员营销" />
        <div className="content">
          <Tabs defaultActiveKey="1" size={"large"} tabBarStyle={{ backgroundColor: "#ffffff", borderRadius: "4px", marginBottom: 0 }}>
            <TabPane tab="等级管理" key="1">
              <Tabs defaultActiveKey="11" size="small" tabBarStyle={{ backgroundColor: "#F8F8F8", borderRadius: "4px" }}>
                <Tabs.TabPane tab="等级设置" key="11">
                  <Grade></Grade>
                </Tabs.TabPane>
                <Tabs.TabPane tab="任务设置" key="12">
                  <Task></Task>
                </Tabs.TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="权益管理" key="2">
              <Tabs defaultActiveKey="21" size="small" tabBarStyle={{ backgroundColor: "#F8F8F8", borderRadius: "4px" }}>
                <Tabs.TabPane tab="积分设置" key="21">
                  <IntegralManageV2 />
                </Tabs.TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="会员营销" key="3">
              <Tabs defaultActiveKey="31" size="small" tabBarStyle={{ backgroundColor: "#F8F8F8", borderRadius: "4px" }}>
                <Tabs.TabPane tab="会员日营销" key="31">
                  <SceneMarketingDetailV2 activity_type="vip_day" />
                </Tabs.TabPane>
                <Tabs.TabPane tab="生日营销" key="32">
                  <SceneMarketingDetailV2 activity_type="birth" />
                </Tabs.TabPane>
              </Tabs>
            </TabPane>
          </Tabs>
        </div>
      </Spin>
    </div>
  }
}