import React from 'react'
import  '@css/navbar.scss'
import PropTypes from "prop-types";
class NavBar extends  React.Component{
    constructor(){
        super();
        NavBar.contextTypes = {
            router: PropTypes.object.isRequired
        };

    }
    go=()=>{
        if(!this.props.father) return

        this.context.router.history.push(`/${this.props.router}`,this.props.state);
    }
    componentDidMount () {
    }
    render(){
         return (
             <div className="navbar">
                 <i className= {["iconfont", this.props.icon,this.props.father?"nav-father":"nav-no-father"].join(' ')}></i>
                 <span  onClick={this.go} style={{"display":this.props.father?true:'none'}}  className="nav-father">{this.props.father}></span>
                 <span className="nav-child">{this.props.child}</span>
             </div>
         )
    }
}


export default NavBar;
