import React from 'react'
import { Upload } from 'antd'
import config from "@config/config";
import UploadButton from "./UploadButton";
import '@css/skuPhoto.scss'

class SkuPhoto extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            requestUrl: config.baseUrl + "/ossFile/upload",
            fileList: [],
            previewImage: null,
            previewVisible: null
        }
    }

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        })
    }
    handleChange = ({ fileList }) => {
        this.setState({ fileList }, () => {
            this.handelOk()
        })
    }
    handelOk = () => {
        let url = ''
        this.state.fileList.forEach(item => {
            if (item.response && item.response.success) {
                url = item.response.result.oss_file_url
            } else {
                url = item.url
            }
        })
        if (url) {
            this.props.handelOK(url, this.props.index)
        }
    }
    onRemove = () => {
        this.props.handelOK("", this.props.index)
    }

    UNSAFE_componentWillReceiveProps(e) {
        this.setState({
            fileList: e.fileList
        })
    }
    componentDidMount(e) {
        this.setState({
            fileList: this.props.fileList
        })
    }

    render() {
        return (

            <Upload
                className="sku-photo"
                accept="image/jpeg,image/jpg,image/png"
                withCredentials={true}
                action={this.state.requestUrl}
                beforeUpload={this.beforeUpdate}
                listType="picture-card"
                fileList={this.state.fileList}
                onPreview={this.handlePreview}
                onChange={this.handleChange}
                onRemove={this.onRemove}
            >
                {this.state.fileList.length >= 1 ? null : <UploadButton />}
            </Upload>

        )
    }

}

export default SkuPhoto
