import React from 'react'
import { Tabs, Tooltip } from "antd";
import NavBar from '@/view/component/common/NavBar.js'
import { connect } from "react-redux";
import '@css/campainSetting.scss'
import '@css/activity.scss'
import Order from '../order/children/Order'
import MarketTabpane from './children/MarketTabpane'
import MarketData from './children/MarketData'
import { queryActivityDetail } from '@service/getData'
import { getQueryString } from "@common/common";

class MarketTab extends React.Component {
    constructor() {
        super()
        this.state = {
            campaign_activity_subtype_res_list: [
                {
                    icon: 'iconfont icon-choujiang',
                    class: "activity-lucky icon-content",
                    activity_subtype:
                        "lucky",
                    activity_subtype_desc:
                        "规则：买家支付金额并与好友拼团成功参与抽奖，商品下架时自动开奖，每个商品只能开一个有效团且仅抽一个团中奖，未成团或未中奖待开奖时自动退款。",
                    activity_subtype_name:
                        "拼团抽奖",
                    url: 'http://udh.oss-cn-hangzhou.aliyuncs.com/c2e56e30-baa7-43e4-bd46-83bc0e41df2f'
                },
                {
                    icon: 'iconfont icon-pintuan',
                    class: "activity-checkin icon-content",
                    activity_subtype:
                        "rank_lucky",
                    activity_subtype_desc:
                        "规则：拼团成功后可根据成团名次发放奖励",
                    activity_subtype_name:
                        "线上拼团",
                    url: "http://udh.oss-cn-hangzhou.aliyuncs.com/f4e6592d-9418-4564-b36c-894210e1b673"
                },
                {
                    icon: 'iconfont icon-lianhe1',
                    class: "activity-freeOrder icon-content",
                    activity_subtype:
                        "free_order",
                    activity_subtype_desc:
                        "抢免单规则：买家付款后开团邀请新用户助力，拼团成功并确认收货后返现金到账户，拼团失败不返现。开团后助力时间为12小时。注：新用户定义为未支付过订单的用户",
                    activity_subtype_name:
                        "抢免单",
                    url: "http://udh.oss-cn-hangzhou.aliyuncs.com/683e1c33-98f0-4515-af1f-7133ebea19ab"
                },
                {
                    icon: 'iconfont icon-qiandao',
                    class: "activity-checkin icon-content",
                    activity_subtype:
                        "checkin",
                    activity_subtype_desc:
                        "规则：1、每日签到，奖励1积分； 2、连续第三天和第七天签到，奖励2.5积分；3、邀请好友签到，额外奖励2-10积分；4、每日积分上限为100分",
                    activity_subtype_name:
                        "签到",
                    url: "http://udh.oss-cn-hangzhou.aliyuncs.com/35bdca8e-186b-42ed-9eea-d7e11f2e9aeb"
                },
            ],
            activity_res: {},
            activity_id: null,
            type: "",
            defaultActiveKey: "1"
        }
    }
    queryActivityDetail = async () => {
        let data = {
            activity_id: this.state.activity_id
        }
        let res = await queryActivityDetail(data);
        if (res.success) {
            this.setState({
                activity_res: res.result.activity_res
            })
        }
    }
    callback = (key) => {
        this.setState({
            defaultActiveKey: key
        })
    }
    UNSAFE_componentWillMount() {
        let activity_id = getQueryString("activity_id")
        this.setState({
            activity_id,
            defaultActiveKey: this.props.location.state === "2" ? "2" : "1"
        }, () => {
            if (activity_id && activity_id !== 'undefined') {
                this.queryActivityDetail();
            }
        })
    }

    render() {
        let { menu_code } = this.props
        return (
            <div className="market-tab">
                <NavBar icon="icon-yingxiao" father={menu_code.market_setting} router="marketSetting" child={this.state.activity_res.activity_subtype ? this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === this.state.activity_res.activity_subtype).activity_subtype_name : null} />
                <div className="order-content">
                    {this.state.activity_res.activity_subtype ? (
                        <div className="icon-filter-content">
                            <div
                                className={this.state.activity_res.activity_subtype ? this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === this.state.activity_res.activity_subtype).class : null}>
                                <i className={this.state.activity_res.activity_subtype ? this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === this.state.activity_res.activity_subtype).icon : null} />
                            </div>
                            <div className="text-content">
                                <div
                                    className="title">{this.state.activity_res.activity_subtype ? this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === this.state.activity_res.activity_subtype).activity_subtype_name : null}
                                    <Tooltip overlayClassName="market-tooltip" placement="bottom" title={
                                        <img
                                            alt="示例图片"
                                            width="256"
                                            src={this.state.activity_res.activity_subtype ? this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === this.state.activity_res.activity_subtype).url : null}
                                        />}>
                                        <span className="text-note pic-show">
                                            示例图片
                                        </span>
                                    </Tooltip>
                                </div>
                                <div> {this.state.activity_res.activity_subtype ? this.state.campaign_activity_subtype_res_list.find(item => item.activity_subtype === this.state.activity_res.activity_subtype).activity_subtype_desc : null}</div>
                            </div>
                        </div>
                    ) : null}
                    <Tabs
                        activeKey={this.state.defaultActiveKey}
                        tabBarStyle={{ backgroundColor: "#ffffff", borderRadius: "4px" }}
                        onChange={this.callback}
                    >
                        <Tabs.TabPane tab="基本信息" key="1">
                            <MarketTabpane />
                        </Tabs.TabPane>
                        {
                            this.state.activity_res.activity_subtype === "checkin" ? null : (
                                <Tabs.TabPane tab={this.state.activity_res.activity_subtype === "free_order" ? "免单记录" : this.state.activity_res.activity_subtype === "rank_lucky" ? '拼团记录' : "抽奖记录"} key="2">
                                    <Order
                                        index="2"
                                        activity_subtype={this.state.activity_res.activity_subtype}
                                    />
                                </Tabs.TabPane>
                            )
                        }
                        {
                            this.state.activity_res.activity_subtype !== "free_order" ? (
                                <Tabs.TabPane tab="活动统计" key="3">
                                    <MarketData
                                        activity_subtype={this.state.activity_res.activity_subtype}
                                    />
                                </Tabs.TabPane>
                            ) : null
                        }
                    </Tabs>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode
    }
}
export default connect(mapStateToProps)(MarketTab);
