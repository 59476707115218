import React from 'react'
import '@css/campaignShow.scss'
import {formatDate} from '@common/common'
import {Button} from 'antd'
class CampaignLive extends React.Component{


    render(){
        return (
            <div className="campaign-live">
                {
                    this.props.activity_room_info_res_list.map(item=>
                        <div className="campaign-live-box" key={item.activity_room_info_id}>
                            <img alt="" src={item.image_url}/>
                            <div className="live-desc">
                                <div>
                                    <div className="live-name">{item.name}</div>
                                    <div className="live-time">{formatDate(item.start_time*1000)}</div>

                                </div>
                                <div>
                                    {
                                        item.live_status===101|| item.live_status===102?(
                                            <Button  style={{backgroundColor:`${this.props.button_back_value}`,borderColor:`${this.props.button_back_value}`}}size='small'>{item.live_status===102?"开播提醒":"直播中…"}</Button>
                                        ):null
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }

            </div>
        )
    }
}
export  default CampaignLive;
