import React from "react";
import NavBar from "@/view/component/common/NavBar.js";
import { connect } from "react-redux";
import "@css/appletApplyManage.scss";
import {
  Form,
  Table,
  Input,
  Button,
  message,
  Pagination,
  DatePicker,
  Modal,
  Select,
  Popconfirm
} from "antd";
import moment from "moment";
import cookie from "react-cookies";
import PropTypes from "prop-types";
import {
  executeInit,
  queryAdminAppApplyList,
  deleteAppApply,
  mchRelate,
  xmdMchRelate
} from "@service/getData";
const confirm = Modal.confirm;
const RangePicker = DatePicker.RangePicker;
const { Option } = Select;

class AppletApplyManage extends React.Component {
  constructor(props) {
    super();
    AppletApplyManage.contextTypes = {
      router: PropTypes.object.isRequired
    };
    this.state = {
      total: 0,
      page_no: 1,
      page_size: 10,
      applet_list: [],
      loading: false,
      apply_status_mapping: {
        1: "未提交",
        2: "提交申请中",
        3: "创建/授权成功",
        4: "创建/授权失败",
        5: "初始化中",
        6: "初始化成功",
        7: "初始化失败",
        8: "发布成功",
        9: "发布失败"
      },
      xmd_mch_relateds_mapping: {
        1: "未关联",
        2: "审核中",
        3: "审核失败",
        4: "已关联"
      },
      columns: [
        {
          title: "账户名称",
          dataIndex: "name"
        },
        {
          title: "appId",
          dataIndex: "app_id"
        },
        {
          title: "小程序名称",
          dataIndex: "app_name",
          render: (text, record) => (
            <div>
              {text}
              {record.type === "auth" && record.apply_status >= 3
                ? "(授)"
                : null}
            </div>
          )
        },
        {
          title: "关联商户号",
          dataIndex: "mch_short_name"
        },
        {
          title: "商户号",
          dataIndex: "mch_id"
        },
        {
          title: "承诺时间",
          dataIndex: "promise_at"
        },
        {
          title: "代码发布状态",
          width: 100,
          dataIndex: "apply_status",
          render: (text, record) => (
            <div>
              {this.state.apply_status_mapping[text]}
              {text === 7 ? (
                <div
                  className="text-note"
                  onClick={this.executeInit.bind(this, record.app_apply_id)}
                >
                  初始化
                </div>
              ) : null}
            </div>
          )
        },
        {
          title: "审核失败原因",
          textWrap: 'ellipsis',
          width: 300,
          dataIndex: "err_msg"
        },
        {
          title: "关联商户状态",
          dataIndex: "mch_related",
          render: (text, record) => (
            <div>
              <div>{text === 0 ? "未关联" : "已关联"}</div>
              {record.mch_related === 0 ? (
                <span
                  className="text-note"
                  onClick={this.showConfirm.bind(
                    this,
                    record.app_apply_id,
                    record.app_id,
                    record.mch_id
                  )}
                >
                  关联
                </span>
              ) : null}
            </div>
          )
        },
        {
          title: "支付状态",
          dataIndex: "paid",
          render: (text, record) => (
            <div>{text === 0 ? "未支付" : "已支付"}</div>
          )
        },
        {
          title: "关联DTC Mall状态",
          render: (text, record) => (
            <div>
              {this.state.xmd_mch_relateds_mapping[record.xmd_mch_related]}
            </div>
          )
        },
        {
          title: "操作DTC Mall关联操作",
          dataIndex: "xmd_mch_related",
          render: (text, record) => (
            <div>
              <span
                className="text-note"
                style={{ display: text === 1 ? "inline-block" : "none" }}
                onClick={this.showBindConfirm.bind(
                  this,
                  record.app_apply_id,
                  2
                )}
              >
                关联
              </span>
              <span
                className="text-note mr15"
                style={{ display: text === 2 ? "inline-block" : "none" }}
                onClick={this.showBindConfirm.bind(
                  this,
                  record.app_apply_id,
                  4
                )}
              >
                审核成功
              </span>
              <Popconfirm
                style={{ display: text === 2 ? "inline-block" : "none" }}
                title={
                  <div className="goods-price-change">
                    <Input
                      value={this.state.err_msg}
                      style={{ width: "84px", borderRadius: "100px" }}
                      onChange={this.changeErrorMsg}
                    />
                  </div>
                }
                onConfirm={this.showBindConfirm.bind(
                  this,
                  record.app_apply_id,
                  3
                )}
                onCancel={this.cancelChange}
                okText="确认"
                cancelText="取消"
                placement="bottom"
                icon={null}
              >
                <span
                  className="text-note"
                  style={{ display: text === 2 ? "inline-block" : "none" }}
                >
                  审核失败
                </span>
              </Popconfirm>
              <span
                className="text-note"
                style={{ display: text === 3 ? "inline-block" : "none" }}
                onClick={this.showBindConfirm.bind(
                  this,
                  record.app_apply_id,
                  2
                )}
              >
                重新关联
              </span>
            </div>
          )
        }
      ]
    };
  }
  componentDidMount() {
    this.queryAdminAppApplyList();
  }
  queryAdminAppApplyList = async () => {
    this.setState({
      loading: true
    });
    let state = this.state;
    let res = await queryAdminAppApplyList({
      page_no: this.state.page_no,
      page_size: this.state.page_size,
      app_name: state.app_name,
      mch_id: state.mch_id,
      name: state.name,
      apply_status: state.apply_status,
      mch_related: state.mch_related,
      xmd_mch_relateds: state.xmd_mch_relateds,
      promise_at_begin: state.promise_at_begin,
      promise_at_end: state.promise_at_end
    });
    this.setState({
      applet_list: [...res.result.list],
      total: res.result.total,
      loading: false
    });
  };
  deleteAppApply = async app_apply_id => {
    let res = await deleteAppApply({
      app_apply_id
    });
    if (res.success) {
      message.success("删除成功");
      this.queryAdminAppApplyList();
    }
  };
  clearFormData = () => {
    const form = this.formRef.props.form;
    form.resetFields();
  };
  executeInit = async app_apply_id => {
    let res = await executeInit({
      app_apply_id
    });
    if (res.success) {
      message.success("重新开始初始化！");
      this.queryAdminAppApplyList();
    }
  };
  onShowSizeChange = (current, page_size) => {
    this.setState({
      page_size
    });
    setTimeout(() => {
      this.queryAdminAppApplyList();
    });
  };
  onShowPageChange = page_no => {
    this.setState({
      page_no
    });
    setTimeout(() => {
      this.queryAdminAppApplyList();
    });
  };
  filterData = () => {
    let form = this.props.form.getFieldsValue();
    let data = {
      app_name: form.app_name || null,
      mch_id: form.mch_id || null,
      name: form.name || null,
      apply_status: form.apply_status || null,
      mch_related: form.mch_related || null,
      xmd_mch_relateds: form.xmd_mch_relateds || [],
      promise_at_begin: form.promise_at_begin || null,
      promise_at_end: form.promise_at_en || null
    };
    this.setState(data);
    setTimeout(() => {
      this.queryAdminAppApplyList();
    });
  };
  clearFilter = () => {
    this.props.form.resetFields();
    let data = {
      app_name: null,
      mch_id: null,
      name: null,
      apply_status: null,
      mch_related: null,
      xmd_mch_relateds: [],
      promise_at_begin: null,
      promise_at_end: null,
      page_no: 1
    };
    this.setState(data);
    setTimeout(() => {
      this.queryAdminAppApplyList();
    });
  };
  handleChange = (dates, dateStrings) => {
    this.setState({
      promise_at_begin: dateStrings[0],
      promise_at_end: dateStrings[1]
    });
  };
  showConfirm = (app_apply_id, app_id, mch_id) => {
    confirm({
      title: "商户号是否关联成功？",
      onOk: () => {
        this.mchRelate(app_apply_id, app_id, mch_id);
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };
  mchRelate = async (app_apply_id, app_id, mch_id) => {
    let res = await mchRelate({
      app_apply_id,
      app_id,
      mch_id
    });
    if (res.success) {
      message.success("关联成功");
      this.queryAdminAppApplyList();
    } else {
      message.error(res.msg);
    }
  };
  showBindConfirm = (app_apply_id, xmd_mch_related) => {
    let text =
      xmd_mch_related === 2
        ? "关联操作"
        : xmd_mch_related === 4
          ? "审核成功操作"
          : "审核失败操作";
    confirm({
      title: `是否进行${text}?`,
      onOk: () => {
        this.xmdMchRelate(app_apply_id, xmd_mch_related, text);
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };
  xmdMchRelate = async (app_apply_id, xmd_mch_related, text) => {
    let res = await xmdMchRelate({
      app_apply_id,
      xmd_mch_related
    });
    if (res.success) {
      message.success(`${text}成功！`);
      this.queryAdminAppApplyList();
    } else {
      message.error(res.msg);
    }
  };
  render() {
    let { menu_code } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="applet-apply-manage">
        <div className="content">
          <NavBar
            icon={cookie.load("key") === "72" ? "icon-shezhi" : "icon-guanliyuanshezhi"}
            child={menu_code.applet_apply_manage}
          />
          <div className="applet-apply-manage-content">
            <div className="filter-content">
              <Form className="filter-form ant-vertical" layout="inline">
                <Form.Item label="小程序名称">
                  {getFieldDecorator("app_name")(<Input />)}
                </Form.Item>
                <Form.Item label="商户号">
                  {getFieldDecorator("mch_id")(<Input />)}
                </Form.Item>
                <Form.Item label="主体名称">
                  {getFieldDecorator("name")(<Input />)}
                </Form.Item>
                <Form.Item label="代码发布状态">
                  {getFieldDecorator("apply_status", {
                    initialValue: null
                  })(
                    <Select style={{ width: 170 }}>
                      <Option value={null}>全部</Option>
                      {Object.values(this.state.apply_status_mapping).map(
                        (item, index) => {
                          return (
                            <Option key={index} value={index + 1}>
                              {item}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="关联商户状态">
                  {getFieldDecorator("mch_related", {
                    initialValue: null
                  })(
                    <Select style={{ width: 170 }}>
                      <Option value={null}>全部</Option>
                      <Option value={0}>未关联</Option>
                      <Option value={1}>已关联</Option>
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="关联DTC Mall状态">
                  {getFieldDecorator("xmd_mch_relateds", {
                    initialValue: []
                  })(
                    <Select style={{ width: 420 }} mode="multiple">
                      {Object.values(this.state.xmd_mch_relateds_mapping).map(
                        (item, index) => {
                          return (
                            <Option key={index} value={index + 1}>
                              {item}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="承诺时间">
                  {getFieldDecorator("promise_at", {
                    initialValue: ""
                  })(
                    <RangePicker
                      ranges={{
                        一天: [moment(), moment().add("days", 1)],
                        三天: [moment(), moment().add("days", 3)],
                        七天: [moment(), moment().add("weeks", 1)],
                        一个月: [moment(), moment().add("month", 1)],
                        三个月: [moment(), moment().add("month", 3)],
                        六个月: [moment(), moment().add("month", 6)],
                        一年: [moment(), moment().add("year", 1)]
                      }}
                      showTime
                      format="YYYY/MM/DD HH:mm:ss"
                      onChange={this.handleChange}
                    />
                  )}
                </Form.Item>
              </Form>
              <div className="line">
                <Button shape="round" type="primary" onClick={this.filterData}>
                  查询
                </Button>
                <span className="text-note" onClick={this.clearFilter}>
                  清空筛选条件
                </span>
              </div>
            </div>
            <div className="table-content">
              <Table
                loading={this.state.loading}
                rowKey={(record, index) => index}
                columns={this.state.columns}
                dataSource={this.state.applet_list}
                pagination={false}
                bordered={true}
              />
              <Pagination
                className="pagenation"
                onChange={this.onShowPageChange}
                onShowSizeChange={this.onShowSizeChange}
                defaultCurrent={this.state.page_no}
                total={this.state.total}
                showTotal={total => `共 ${total} 条`}
                pageSizeOptions={["10", "30", "50", "100"]}
                showSizeChanger
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};

const AppletApplyManageForm = Form.create({ name: "applet_apply_manage_form" })(
  AppletApplyManage
);

export default connect(mapStateToProps)(AppletApplyManageForm);
