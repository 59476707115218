import React from 'react'
import '@css/batchShipment.scss'
import config from '@config/config'
import {Form, DatePicker, Button, Table, message, Pagination, Modal, Upload} from 'antd'
import {queryShipmentBatchList,shipmentBatchXlsTask} from '@service/getData'
import cookie from 'react-cookies'

class BatchShipment extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            start_operate_time: null,
            end_operate_time: null,
            columns: [{
                title: '批次号',
                dataIndex: 'shipment_batch_id'
            }, {
                title: '发货时间',
                dataIndex: 'operate_time'
            }, {
                title: '发货单数',
                dataIndex: 'shipment_amount'
            }, {
                title: '发货成功数',
                dataIndex: 'success_amount'
            }
            ],
            shipment_batch_list: [],
            page_size: 10,
            page: 1,
            total: 0,
            visible: false,
            fileList: [],
            action: {
                action: config.baseUrl + "/ossFile/upload",
                withCredentials: true
            }
        }
    }

    onShowTimeChange = (date, dateStr) => {
        this.setState({
            start_operate_time: dateStr[0],
            end_operate_time: dateStr[1],
        })
    }
    onShowPageChange = (page) => {
        this.setState({
            page: page
        }, () => {
            this.filterOrder()
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.filterOrder()
        })
    }
    showModal = () => {
        this.setState({
            visible: true
        });
    }

    handleOk = (e) => {
        if(this.state.fileList.length<1){
            return message.success("请上传文件之后再操作")
        }
        let data={
            shop_id:cookie.load("shop_id"),
            oss_file_url:this.state.fileList[0].response.result.oss_file_url
        }
       this.shipmentBatchXlsTask(data)
    }

    handleCancel = (e) => {
        this.setState({
            visible: false,
            fileList:[]
        });
    }

    filterOrder = () => {
        if(!cookie.load("shop_id")&&Number(cookie.load("is_admin"))===1){
            return
        }
        let data = {
            shop_id: cookie.load("shop_id"),
            page_no:this.state.page,
            page_size:this.state.page_size
        }
        if (this.state.start_operate_time) {
            data.start_operate_time = this.state.start_operate_time
        }
        if (this.state.end_operate_time) {
            data.end_operate_time = this.state.end_operate_time
        }
        this.queryShipmentBatchList(data)
    }
    async queryShipmentBatchList(data) {
        let res = await queryShipmentBatchList(data)
        if (res.success) {
            this.setState({
                shipment_batch_list: res.result.shipment_batch_res_list,
                total: res.result.total
            })
        }
    }
    async shipmentBatchXlsTask(data){
        let res=await shipmentBatchXlsTask(data);
        if(res.success){
            message.success("已成功发送"+res.result.shipment_success_num+"条记录")
            this.setState({
                visible: false,
                fileList:[]
            });

        }
    }
    componentDidMount() {
        this.filterOrder()
    }

    onChange = (info) => {
        this.setState({
            fileList:info.fileList
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div className="order-shipment" id="order-shipment">
                <div className="batch-shipment-history">
                    <div className="filter-content">
                        <Form.Item label="下单时间">
                            {getFieldDecorator('date')(
                                <DatePicker.RangePicker onChange={this.onShowTimeChange} showTime/>
                            )}
                        </Form.Item>
                        <div className="action">
                            <Button type="primary" shape="round" onClick={this.onShowPageChange.bind(this,1)}>筛选</Button>
                        </div>
                    </div>
                    <Table rowKey={(record, index) => index} columns={this.state.columns}
                           dataSource={this.state.shipment_batch_list} pagination={false} bordered={true}/>
                    <Pagination
                        className="pagenation"
                        onChange={this.onShowPageChange}
                        onShowSizeChange={this.onShowSizeChange}
                        defaultCurrent={this.state.page}
                        total={this.state.total}
                        showTotal={(total) => `共 ${total} 条`}
                        pageSizeOptions={["10", "30", "50", "100"]}
                        showSizeChanger
                    />
                </div>
                <Modal
                    title="批量发货"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText="批量导入"
                    okButtonProps={{shape:'round'}}
                    cancelButtonProps={{shape:'round'}}
                >
                    <div className="batch-modal-content">
                        <Upload
                            {...this.state.action}
                            onChange={this.onChange}
                            fileList={this.state.fileList}
                        >
                            <Button disabled={this.state.fileList.length>0?true:false} shape="round">上传</Button>
                        </Upload>
                        <div className="text-note">
                            <a href={config.baseUrl+"/file/shipmentBatchDownload"}>下载批量发货模版</a>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

}

const BatchShipmentForm = Form.create({name: 'batch_shipment_form'})(BatchShipment);

export default BatchShipmentForm;
