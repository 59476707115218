import React from "react";
import "@css/userDashboard.scss";
import ShopData from "./chidren/ShopData";
import OpeningGuide from './chidren/OpeningGuide';
import NavBar from '@/view/component/common/NavBar.js'
import { connect } from "react-redux";
import { Icon } from 'antd'
import { queryShopDashBoard } from '@service/getData'
import cookie from 'react-cookies'
import config from '@config/config'
import PropTypes from "prop-types";

class UserDashboard extends React.Component {
    constructor(props) {
        super(props);
        UserDashboard.contextTypes = {
            router: PropTypes.object.isRequired,
        };
        this.state = {
            requestUrl: config.baseUrl + "/file/download?url=",
            shop_data: [],
            order_data: [],
            my_qrcode: '',
            // 是否完成开店指引
            finished: 0,
            // 发布状态
            status: null,
            //更新时间
            gmt_modified: '',
        };
    }
    async queryShopDashBoard() {
        if (!cookie.load("shop_id") && cookie.load("is_admin") === "1") {
            return
        }
        let data = {
            shop_id: cookie.load("shop_id")
        }
        let res = await queryShopDashBoard(data);
        if (res.success) {
            let shop_data = [
                {
                    icon: "icon-yingyee1",
                    title: "今日实时营业额(元)",
                    total: res.result.today_sold_amount / 100,
                    color: "#F05B5B",
                    yesterday_total: res.result.yesterday_sold_amount / 100
                },
                {
                    icon: "icon-dingdanshu",
                    title: "今日实时订单数(单)",
                    total: res.result.today_order_num,
                    color: "#3399ED",
                    yesterday_total: res.result.yesterday_order_num,

                },
                {
                    icon: "icon-zhifurenshu",
                    title: "支付人数",
                    total: res.result.today_pay_user_num,
                    color: "#FB9652",
                    yesterday_total: res.result.yesterday_order_num
                },
                {
                    icon: "icon-fangkeshu",
                    title: "访客数",
                    total: res.result.today_login_app_user_num,
                    color: "#48CFB1",
                    yesterday_total: res.result.yesterday_login_app_user_num
                },
                {
                    icon: "icon-xinfangke",
                    title: "新客数",
                    total: res.result.today_app_new_user_num,
                    color: "#9065EB",
                    yesterday_total: res.result.yesterday_app_new_user_num
                },
                {
                    icon: "icon-jinrifafangyongjin",
                    title: "今日发放佣金",
                    total: res.result.send_promotion_amount / 100,
                    color: "#F5A623",
                    yesterday_total: res.result.yesterday_app_new_user_num
                }
            ]
            let order_data = [
                {
                    icon: "icon-daifahuodingdan",
                    title: "待发货订单(单)",
                    total: res.result.to_shipped_num,
                    color: "#48cfb1"
                },
                {
                    icon: "icon-daishouhoudingdan",
                    title: "待售后订单(单)",
                    total: res.result.refund_order_num,
                    color: "#fb9652"
                },
            ]
            this.setState({
                shop_data: shop_data,
                order_data: order_data,
                finished: res.result.finished,
                my_qrcode: `${config.baseUrl}/qrCode/detail?shop_id=${cookie.load("shop_id")}&page=pages/home/index`,
                status: res.result.status,
                gmt_modified: res.result.gmt_modified,
            }, () => {
                this.state.finished &&  cookie.save("defaultSelectedKeys", "0");
            })
        }

    }
    componentDidMount() {
        const shopId = cookie.load('shop_id');
        if (shopId !== 'null') {
            this.queryShopDashBoard();
        } else {
            this.setState({
                finished: 0,
            });
        }
    }
    goAppletManage() {
        this.context.router.history.push('/appletManage');
    }
    render() {
        let { menu_code } = this.props;
        const statusText = [
            '授权中',
            '授权失败',
            '发布中',
            '发布成功',
            '发布失败',
            '未授权'
        ];
        const statusColor = [
            '#FF6600',
            '#E42317',
            '##6699FF',
            '#66CC66',
            '#FF0000',
            '#333333'
        ];
        return (
            <div className="userDashboard">
                <NavBar icon="icon-yemian" child={menu_code.user_dashboard} />
                <div className="dataContent">
                    <div className="shop-info">
                        {this.state.finished
                            ?
                            <ShopData shopData={this.state.shop_data} orderData={this.state.order_data} />
                            :
                            <OpeningGuide></OpeningGuide>
                        }
                    </div>
                    <aside>
                        <div className="side-content">
                            <div className="shopDataTitle">
                                <i className="titleSign"></i>
                                查看店铺小程序
                            </div>
                            {this.state.finished
                                ?
                                <>
                                    <p className="tip">当前店铺已关联小程序，扫码查看</p>
                                    <img className="myQrcode" alt="店铺二维码" src={this.state.my_qrcode} width="121" height="128" />
                                    <p className="newTime">最新发布时间：{this.state.gmt_modified}</p>
                                    <p className="status">状态：<span className="statusText" style={{color: statusColor[this.state.status]}}>{statusText[this.state.status]}</span><span className="seeDetail" onClick={this.goAppletManage.bind(this)}>查看详情</span></p>
                                </>
                                :
                                <>
                                    <p className="noAppTip">完成开店指引后查看 </p>
                                </>
                            }

                        </div>
                        <div className="side-content">
                            <div className="shopDataTitle">
                                <i className="titleSign"></i>
                                帮助中心
                            </div>
                            <p><a target="_blank" rel="noopener noreferrer"
                                href={this.state.requestUrl + "http://udh.oss-cn-hangzhou.aliyuncs.com/2ddc12b3-637f-45ac-a6a8-8f15a111e0cd"}>DTC Mall开店指引
                                {">"}</a></p>
                            <p><a target="_blank" rel="noopener noreferrer"
                                href={this.state.requestUrl + "http://udh.oss-cn-hangzhou.aliyuncs.com/fed9c727-7992-4880-b210-ce4acfa9a132"}>DTC Mall功能介绍
                                {">"}</a></p>
                        </div>
                        {/* <div className="side-content">
                            <div className="shopDataTitle">
                                <i className="titleSign"></i>
                                交流答疑
                            </div>
                            <p><a target="_blank" rel="noopener noreferrer"
                                href="http://wpa.qq.com/msgrd?v=3&amp;uin=765526150&amp;site=qq&amp;menu=yes"> <i
                                    className="iconfont icon-kefu"></i>人工客服帮助</a></p>
                            <p><a target="_blank" rel="noopener noreferrer"
                                href="//shang.qq.com/wpa/qunwpa?idkey=bcf8a90ddab177124d13e0ecc0ca9144451eb2ae4ac7428ae822354fcb799791">
                                <Icon type="qq" />加入商家交流群</a></p>
                        </div> */}
                        <div className="side-content">
                            <div className="shopDataTitle">
                                <i className="titleSign"></i>
                                运营指南
                            </div>
                        </div>
                        <div className="side-content">
                            <div className="shopDataTitle">
                                <i className="titleSign"></i>
                                公告/产品动态
                            </div>
                        </div>
                    </aside>


                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode
    }
}
export default connect(mapStateToProps)(UserDashboard);
