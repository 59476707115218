import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import '@css/reset.scss'
import '@css/common.scss'
import * as serviceWorker from "./serviceWorker";
import Router from "./router/router";
import { Provider } from "react-redux";
import store from "./store/index";
import zhCN from "antd/lib/locale-provider/zh_CN";
import { ConfigProvider ,message} from "antd";
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

message.config({
    top: 100,
    duration: 3,
    maxCount: 3,
});

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN}>
      <Router />
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
