import React, { Component } from 'react';
import { Form, Icon, Radio, Upload, message, Button } from 'antd';
import './scss/videoActivity.scss';
import { queryActivityDetail, createOrUpdateActivity } from '@service/getData';
import config from "@config/config";
import cookie from "react-cookies";

class VideoActivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            video_url: '',
            fileList: [],
            is_auto: 1,
            video_pic_type: 0,
            video_pic: '',
        }
    }
    componentDidMount() {
        this.props.onRef('VideoActivity', this)
    }
    queryActivityDetail = async (activity_id) => {
        const data = {
            activity_id,
        };
        let res = await queryActivityDetail(data);
        if (res.success) {
            const {
                video_url,
                is_auto,
                video_pic,
                video_pic_type,
            } = res.result.activity_res;
            this.setState({
                video_url,
                video_pic,
                video_pic_type,
                is_auto,
            });
        } else {
            message.error('获取视频组件详情失败');
        }
    }
    beforeUpload(file) {
        // 小于100M
        const rightSize = file.size < (100 * 1024 * 1000);
        if (!rightSize) {
            message.error('视频大小不能超过100M哦～');
        }
        return rightSize;
    }
    onChange({ file, fileList }) {
        if (file.status === 'done' && file.response) {
            this.setState({
                video_url: file.response.result.oss_file_url,
            });
        }
        const list = fileList.filter((item, index) => index === fileList.length - 1);
        this.setState({
            fileList: [...list],
        });
    }
    changeIsAuto(e) {
        const is_auto = e.target.value;
        this.setState({
            is_auto,
        });
    }
    changePicType(e) {
        const video_pic_type = e.target.value;
        if (video_pic_type === 0) {
            this.setState({
                video_pic: '',
            });
        }
        this.setState({
            video_pic_type,
        });
    }
    picBeforeUpload(file) {
        // 小于3M
        const rightSize = file.size < (3 * 1024 * 1000);
        if (!rightSize) {
            message.error('图片大小不能超过3M哦～');
        }
        return rightSize;
    }
    picOnChange({ file }) {
        if (file.status === 'done' && file.response) {
            this.setState({
                video_pic: file.response.result.oss_file_url,
            });
        }
    }
    editVideo() {
        const isVerification = this.verification();
        if (!isVerification) {
            return;
        }
        const data = {
            activity_req: {
                activity_id: this.props.activity_id,
                activity_name: '视频组件',
                activity_sub_type: 'video',
                mini_app_id: cookie.load('mini_app_id'),
                campaign_id: this.props.campaign_id,
                activity_type: 'campaign',
                video_url: this.state.video_url,
                video_pic_type: this.state.video_pic_type,
                video_pic: this.state.video_pic,
                is_auto: this.state.is_auto,
            },
        }
        createOrUpdateActivity(data).then((res) => {
            if (res.success) {
                message.success('视频组件保存成功');
                this.props.onOK();
            }
        })
    }
    verification() {
        if (!this.state.video_url) {
            message.error('请先上传视频');
            return false;

        }
        if (+this.state.video_pic_type === 1 && !this.state.video_pic) {
            message.error('请上传自定义视频封面');
            return false
        }
        return true;
    }
    render() {
        // 接受所有视频格式
        const accept = 'video/*';
        // 上传地址
        const action = config.baseUrl + "/ossFile/upload";
        // 显示上传的图片
        const styleObj = {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${this.state.video_pic})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
        }
        const videoStyleObj = {
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${this.state.video_url}?x-oss-process=video/snapshot,t_100,f_jpg,m_fast)`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
        }
        const videoExtra = '视频大小不超过 100 MB，建议时长在 5 分钟以内,建议宽高比 16:9';
        const picExtra = '建议宽度 750,宽高比 16:9, 大小不超过3M';
        return (
            <div className="videoActivity">
                <p className="video-title">{this.props.title}</p>
                <div className="video-content">
                    <Form labelalign='left' labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                        <Form.Item labelalign='left' label="上传视频" required extra={videoExtra}>
                            <Upload
                                fileList={this.state.fileList}
                                showUploadList={
                                    {
                                        showRemoveIcon: false,
                                    }
                                }
                                accept={accept}
                                action={action}
                                beforeUpload={this.beforeUpload}
                                withCredentials={true}
                                onChange={this.onChange.bind(this)}
                            >
                                <div className="uploadBtn" style={this.state.video_url ? videoStyleObj : null}>
                                    {this.state.video_url ? (
                                        <Icon style={this.state.video_url ? { color: '#ffffff' } : null} className="iconEdit" type="edit" theme="filled" />
                                    ) : (
                                            <Icon style={this.state.video_url ? { color: '#ffffff' } : null} className="iconPlus" type="plus" />
                                        )}
                                    <span style={this.state.video_url ? { color: '#ffffff' } : null} className="addVideoText">{this.state.video_url ? '更换视频' : '添加视频'}</span>
                                </div>
                            </Upload>
                        </Form.Item>
                        <Form.Item label="是否自动播放" required>
                            <Radio.Group defaultValue={1} value={this.state.is_auto} onChange={this.changeIsAuto.bind(this)}>
                                <Radio value={1}>自动播放</Radio>
                                <Radio value={0}>手动播放</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="选择封面图" required>
                            <Radio.Group defaultValue={1} value={this.state.video_pic_type} onChange={this.changePicType.bind(this)}>
                                <Radio value={0}>原视频封面</Radio>
                                <Radio value={1}>自定义</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {this.state.video_pic_type === 1 ? (
                            <Form.Item label="上传封面图" required extra={picExtra}>
                                <Upload
                                    accept='image/*'
                                    action={action}
                                    showUploadList={
                                        {
                                            showRemoveIcon: false,
                                        }
                                    }
                                    beforeUpload={this.picBeforeUpload}
                                    withCredentials={true}
                                    onChange={this.picOnChange.bind(this)}
                                >
                                    <div className='uploadBtn' style={this.state.video_pic ? styleObj : null}>
                                        {this.state.video_pic ? (
                                            <Icon style={this.state.video_pic ? { color: '#ffffff' } : null} className="iconEdit" type="edit" theme="filled" />
                                        ) : (
                                                <Icon style={this.state.video_pic ? { color: '#ffffff' } : null} className="iconPlus" type="plus" />
                                            )}
                                        <span style={this.state.video_pic ? { color: '#ffffff' } : null} className="addVideoText">{this.state.video_pic ? '更换图片' : '添加图片'}</span>
                                    </div>
                                </Upload>
                            </Form.Item>
                        ) : null}
                    </Form>
                </div>
                <Button className="videoSubmit" type="primary" onClick={this.editVideo.bind(this)}>提交</Button>
            </div>
        );
    }
}

export default VideoActivity;