import { Divider, Form, Input, Radio, Spin, Icon, Upload, Modal, Checkbox, Button, message } from 'antd';
import React, { Component } from 'react';
import './scss/editLevel.scss';
import NavBar from "@/view/component/common/NavBar";
import ColorPicker from "@/view/component/common/ColorPicker";
import UploadButton from "@/view/component/common/UploadButton";
import config from '@config/config';
import PropTypes from "prop-types";
import { getData } from '@service/getData';
import { getQueryString } from "@common/common";
import CouponSelectModal from "@/view/component/Campaign/CouponSelectModal";

// react router 跳转问题。
// 问题描述：
// A页面为一个表格页。表格的每一行都有一个按钮，跳转到对应的编辑页面B。
// 点击A页面表格的第一行的按钮，跳转到该编辑页面，以下注释的代码不会执行。手动 cmd+R 刷新页面后，才会执行。这时候，页面保存了数据和状态。
// 路由跳转到A页面，点击表格第二行的按钮，跳转到该页面，页面显示的仍然是上一次的数据和状态（表格第一行对应的detail数据）。

// const requestUrl = config.baseUrl + "/oss_file/createOrUpdateOssFile";
// const VIP_GRADE_ID = getQueryString('vip_grade_id');
// const VIP_SYSTEM_ID = getQueryString('vip_system_id');
// const LEVEL = getQueryString('level');
// const EDIT_USER_INfO_LEVEL = getQueryString('editUserInfoLevel');
// const canEditUserInfo = (EDIT_USER_INfO_LEVEL === 'null') || (EDIT_USER_INfO_LEVEL === LEVEL);

// console.log(VIP_GRADE_ID);

class EditLevel extends Component {
    // 解决上面注释所说的问题
    requestUrl = config.baseUrl + "/ossFile/upload";

    LASTLEVELGROWTH = +getQueryString('lastLevelGrowth');
    VIP_GRADE_ID = getQueryString('vip_grade_id');
    VIP_SYSTEM_ID = getQueryString('vip_system_id');
    LEVEL = getQueryString('level');
    EDIT_USER_INfO_LEVEL = getQueryString('editUserInfoLevel');

    canEditUserInfo = (this.EDIT_USER_INfO_LEVEL === 'null') || (this.EDIT_USER_INfO_LEVEL >= this.LEVEL);

    constructor() {
        super();
        EditLevel.contextTypes = {
            router: PropTypes.object.isRequired,
        };
        this.state = {
            loading: false,
            levelName: this.LEVEL.toLocaleLowerCase(),
            visible: false,
            bacImgFileList: [],
            previewVisible: false,
            previewImage: '',
            bacType: 0,
            color: '#0D87EB',
            pointsFeedback: false,
            growthValue: null,
            needUserInfo: true,
            pointFeedbackRate: null,
			showError: false,
			couponList: [],
			couponVisible: false,
			pointsNumSelect: false,
			pointsNum: "",
			couponListSelect: false,
        };
    }

    componentDidMount() {
        const vip_grade_id = +this.VIP_GRADE_ID;
        Number.isInteger(vip_grade_id) && this.init(vip_grade_id);
    }

    async init(vip_grade_id) {
        const url = '/vip/level/detail';
        const data = {
            vip_grade_id,
        };
        let res = await getData(url, data);
        if (res.success && res.result) {
            const { result } = res;
            if (result.background_url) {
                const bacImgFileList = [{
                    uid: 0,
                    name: result.background_url,
                    status: 'done',
                    url: result.background_url,
                }];
                this.setState({
                    bacImgFileList,
                });
            }
            if (result.point_feedback_rate) {
                this.setState({
                    pointsFeedback: true,
                });
			}
			if (result.coupon_list.length) {
                this.setState({
                    couponListSelect: true,
                });
			}
			if (result.point_num) {
                this.setState({
                    pointsNumSelect: true,
                });
            }
            this.setState({
				levelName: result.name,
				pointsNum: result.point_num,
				couponList: result.coupon_list,
                color: result.background_color || '#0D87EB',
                previewImage: result.background_url,
                growthValue: result.growth_value,
                pointFeedbackRate: result.point_feedback_rate,
                needUserInfo: result.need_user_info,
                bacType: result.background_url ? 1 : 0,
            });
        }
    }

    onLevelNameChange(e) {
        this.setState({
            levelName: e.target.value,
        });
    }
    showModal() {

        this.setState({
            visible: true,
        });
    }

    handleOK(color) {
        this.setState({
            color,
        });
        this.handleCancel();
    }

    handleCancel() {
        this.setState({
            visible: false,
        });
    }
    handlePreview = (file) => {
        this.setState({
            previewVisible: true,
        });
    }

    handleBacChange = ({ fileList }) => {
        let previewImage;
        // 删除图片的时候，fileList为空数组。做兼容。
        if (fileList.length) {
            let last = fileList[fileList.length - 1]
            previewImage = last.response && last.response.result && last.response.result.oss_file_url;
        } else {
            previewImage = '';
        }
        this.setState({
            bacImgFileList: [...fileList],
            previewImage,
        });
    }

    handlePreviewCancel = () => {
        this.setState({
            previewVisible: false
        })
    }
    onBacTypeChange(e) {
        this.setState({
            bacType: e.target.value,
        });
    }
    checkPoint(name, e) {
		let obj = {};
		obj[name] = e.target.checked;
        this.setState(obj);
    }
    onCancle() {
        this.context.router.history.push('/gradeManage');
    }
    async onSave() {
        if (!this.validate()) {
            return;
        }
        const url = '/vip/level/createOrUpdate';
        const data = {
            vip_grade_id: this.VIP_GRADE_ID === 'null' ? null : this.VIP_GRADE_ID,
            vip_system_id: this.VIP_SYSTEM_ID,
            level: +this.LEVEL.slice(3),
            name: this.state.levelName,
            background_color: this.state.bacType === 0 ? this.state.color : null,
            background_url: this.state.bacType === 1 ? this.state.previewImage : null,
            growth_value: this.state.growthValue,
            need_user_info: this.state.needUserInfo,
			point_feedback_rate: this.state.pointFeedbackRate,
			point_num: this.state.pointsNum,
			coupon_list: this.state.couponList,
        };
        let res = await getData(url, data);
        if (res.success) {
            message.success('保存成功');
            this.onCancle();
        }
    }
    validate() {
        const { error } = message;
        if (!this.state.levelName) {
            error('请填写等级名称');
            return false;
        }
        if (this.state.bacType === 0 && !this.state.color) {
            error('请选择背景色');
            return false;
        }
        if (this.state.bacType === 1 && !this.state.previewImage) {
            error('请上传背景图');
            return false;
        }
        if (!this.state.growthValue) {
            error('请填写成长值');
            return false;
        }
        if (+this.state.growthValue <= this.LASTLEVELGROWTH) {
            this.setState({
                showError: true,
            });
            error('高等级成长值要大于低等级成长值');
            return false;
        }
        if (this.state.pointsFeedback && !this.state.pointFeedbackRate) {
            error('请填写积分回馈倍率');
            return false;
        }
        return true;
    }
    onGrowthValueChange(e) {
        if (+e.target.value > this.LASTLEVELGROWTH) {
            this.setState({
                showError: false,
            });
        }
        this.setState({
            growthValue: e.target.value,
        });
    }
    onNeedUserInfoChange(e) {
        this.setState({
            needUserInfo: e.target.checked,
        });
    }
    onRateChange(e) {
        this.setState({
            pointFeedbackRate: e.target.value,
        });
	}
    onPointsNumChange(e) {
        this.setState({
            pointsNum: e.target.value,
			pointsNumSelect: true
        });
	}
	showCouponModal = () => {
		this.setState({
			couponVisible: true
		});
	}
	onCouponCancel = () => {
		this.setState({
			couponVisible: false
		});
	};
	onCouponOK = couponList => {
		if (couponList.filter(item => item.status === 1).length > 10) {
			return message.error("最多添加10张优惠券");
		}
		this.setState({
			couponVisible: false,
			couponList
		});
	};
	deleteCoupon(index) {
		let couponList = this.state.couponList;
		couponList.splice(index, 1);
		this.setState({
			couponList
		});
	}
    render() {
        const cardBacObj = {
            backgroundImage: `url(${this.state.previewImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
        };
        return (
            <div className="editLevel">
                <Spin spinning={this.state.loading}>
                    <NavBar icon="icon-kehu" child="编辑等级" father="等级管理" router="gradeManage" />
                    <div className="content">
                        <p className="formTitle">基本信息</p>
                        <Divider />
                        <Form wrapperCol={{ span: 20 }} labelCol={{ span: 2 }}>
                            <Form.Item
                                label="等级名称"
                                required
                                wrapperCol={{ span: 4 }}
                            >
                                <Input value={this.state.levelName} onChange={this.onLevelNameChange.bind(this)} />
                            </Form.Item>
                            <Form.Item label="等级背景" required>
                                <Radio.Group value={this.state.bacType} onChange={this.onBacTypeChange.bind(this)}>
                                    <Radio value={0}>
                                        <div className="colorWrap">
                                            <span>背景色</span>
                                            <div className="color-choose" onClick={this.showModal.bind(this)}>
                                                <span className="color-desc">{this.state.color}</span>
                                                <span className="color" style={{ background: this.state.color }} />
                                                <Icon className="color-picker-icon" type="caret-down" />
                                            </div>
                                        </div>
                                    </Radio>
                                    <Radio value={1}>
                                        <div className="bacWrap">
                                            <span className="text">背景图</span>
                                            <Upload
                                                accept="image/jpeg,image/jpg,image/png"
                                                withCredentials={true}
                                                action={this.requestUrl}
                                                listType="picture-card"
                                                fileList={this.state.bacImgFileList}
                                                onPreview={this.handlePreview.bind(this)}
                                                onChange={this.handleBacChange.bind(this)}
                                            >
                                                {this.state.bacImgFileList.length > 0 ? null : <UploadButton />}
                                            </Upload>
                                            <div className="tip">
                                                尺寸：1000*600像素，小于1M，支持jpg,jpeg,png格式
                                            </div>
                                        </div>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="获得条件" required>
                                <div className="conditionWrap">
                                    <div className="growthWrap">
                                        <div className="grouth">
                                            <span className="text">成长值</span>
                                            <Input style={{ width: '240px' }} value={this.state.growthValue} onChange={this.onGrowthValueChange.bind(this)} />
                                        </div>
                                        <div className="tip">
                                            修改等级所需成长值后，部分客户会因为无法达到该成长值要求而发生会员等级的变化
                                            {this.state.showError ? (
                                                <div style={{ color: 'red' }}>成长值要大于{this.LASTLEVELGROWTH}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="registrationMessage">
                                        <div className="registration">
                                            <Checkbox disabled={!this.canEditUserInfo} checked={this.state.needUserInfo} onChange={this.onNeedUserInfoChange.bind(this)}>
                                                注册信息(必填项：姓名，手机号，生日)
                                            </Checkbox>
                                        </div>
                                        <div className="tip">
                                            {this.canEditUserInfo ?
                                                '勾选后，所有等级新会员都需填写，老会员仍正常降级，系统会在个人中心提示完善资料' :
                                                `如需编辑注册信息，请前往${this.EDIT_USER_INfO_LEVEL}编辑页进行编辑`}
                                        </div>
                                    </div>
                                </div>
                            </Form.Item>
                            <div className="cardWrap" style={(this.state.bacType === 0 || this.state.previewImage === '') ? { backgroundColor: this.state.color } : cardBacObj}>
                                <p className="cardTitle">注册会员</p>
                                <p className="cardGrowth">
                                    <span className="growthNum">7</span>
                                    <span className="growth">成长值</span>
                                </p>
                                <Divider style={{ height: '2px', backgroundColor: '#ffffff' }} />
                                <p className="nextLevel">再获得0成长值成为lv1</p>
                                <p className="tip">仅为预览，实际以用户查看时为准</p>
                            </div>
                        </Form>
                        <p className="formTitle">权益礼包</p>
                        <Divider />
                        <Form wrapperCol={{ span: 20 }} labelCol={{ span: 2 }}>
                            <Form.Item label="权益" required>
								<Checkbox checked={this.state.pointsFeedback} onChange={this.checkPoint.bind(this, "pointsFeedback")}>积分回馈率</Checkbox>
								{this.state.pointsFeedback ? (
									<Input suffix="倍" placeholder="请填写数字" value={this.state.pointFeedbackRate} onChange={this.onRateChange.bind(this)} />
								) : null}
                            </Form.Item>
							<Form.Item label="升级礼包" required>
								<div className="flex">
									<Checkbox checked={this.state.pointsNumSelect} onChange={this.checkPoint.bind(this, "pointsNumSelect")}></Checkbox>
									<div className="ml8">送<Input placeholder="请填写数字" value={this.state.pointsNum} onChange={this.onPointsNumChange.bind(this)} style={{ width: "100px", margin: "0 5px" }} />积分</div>
								</div>
								<div className="flex">
									<Checkbox checked={this.state.couponListSelect} onChange={this.checkPoint.bind(this, "couponListSelect")}>送优惠券</Checkbox>
									{this.state.couponListSelect ? (
										<>
										{this.state.couponList.map((item, index) => (
											<span className="selected-coupon" key={item.coupon_id}>
												{item.coupon_name}
												<Icon
												onClick={this.deleteCoupon.bind(this, index)}
												className="img-close-icon"
												type="close-circle"
												theme="filled"
												/>
											</span>
										))}
										<Button
											type="link"
											onClick={this.showCouponModal}>
											选择优惠券
										</Button>
										</>
									) : null}
								</div>
                            </Form.Item>
                        </Form>
                        <div className="btnWrap">
                            <Button className="cancel" shape="round" onClick={this.onCancle.bind(this)}>取消</Button>
                            <Button className="save" type="primary" shape="round" onClick={this.onSave.bind(this)}>保存</Button>
                        </div>
                    </div>
                </Spin>
                <ColorPicker visible={this.state.visible} onOk={this.handleOK.bind(this)} onCancel={this.handleCancel.bind(this)} />
                <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handlePreviewCancel.bind(this)}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
				<CouponSelectModal
					selected_coupon_list={this.state.couponList}
					visible={this.state.couponVisible}
					onOk={this.onCouponOK}
					onCancel={this.onCouponCancel}
				/>
            </div>
        );
    }
}

export default EditLevel;