import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getGiftCardList, stopGiftCard, deleteGiftCard, getGiftCardCode } from "@service/getData";
import cookie from "react-cookies";
import { Form, Input, Divider, Select, Button, Table, message, Pagination, Spin, Modal, Tooltip, Icon, Upload } from "antd";
import { formatDate } from "@common/common";
import html2canvas from "html2canvas";
import config from '@config/config';

const action = `${config.baseUrl}/giftCard//upload/cd_keys`;
const MUBANEXCEL = 'http://udh.oss-cn-hangzhou.aliyuncs.com/5c325b75-2c00-484e-90bb-8961a599a1d01603765242945.xlsx';
/**
 * 问题：
 * 1.权益卡类型有哪些
 * 2.
 */
class CardList extends React.Component {
	constructor() {
		super();
		CardList.contextTypes = {
			router: PropTypes.object.isRequired
		};
		this.state = {
			loading: false,
			gift_card_list: [],
			page: 1,
			page_size: 10,
			total: 0,
			status_mapping: {
				0: "未开始",
				1: "进行中",
				2: "已过期",
				3: "已停止",
				4: "已删除"
			},
			usage_status_mapping: {
				0: "待激活",
				1: "待使用",
				2: "使用中",
				3: "已使用",
				4: "已失效",
				5: "已退卡"
			},
			color_mapping: {
				1: "text-green",
				2: "text-remind",
				3: "text-danger"
			},
			code_url: "",
			columns: [
				{
					title: "id",
					dataIndex: "gift_card_id"
				},
				{
					title: "权益卡名称",
					dataIndex: "gift_card_name"
				},
				{
					title: "商品编码",
					dataIndex: "gift_card_code"
				},
				{
					title: "权益卡类型",
					dataIndex: "gift_card_type",
					render: text => "付费权益卡"
				},
				{
					title: "有效期",
					dataIndex: "redeem_start_time",
					render: (text, record) => {
						return record.redeem_time_type === 1
							? "永久有效"
							: record.redeem_time_type === 2
								? `${formatDate(new Date(text).getTime(), "Y-M-D")} 至 ${formatDate(new Date(record.redeem_end_time).getTime(), "Y-M-D")}`
								: `按领取之日起${record.redeem_days}天内可用`;
					}
				},
				{
					title: "状态",
					dataIndex: "status",
					render: text => <span className={this.state.color_mapping[Number(text)]}>{this.state.status_mapping[Number(text)]}</span>
				},
				{
					title: "未激活",
					dataIndex: "num1",
					render: (text, record) => (
						<div>
							{record.usage_stat ? (
								<span className="text-note" onClick={this.goToReceiveRecord.bind(this, record)}>
									{record.usage_stat[0] || 0}
								</span>
							) : (
									"-"
								)}
						</div>
					)
				},
				{
					title: "已领取/使用中",
					dataIndex: "num2",
					render: (text, record) => (
						<div>
							{record.usage_stat ? (
								<span>
									{record.usage_stat[1] || 0}/{record.usage_stat[2] || 0}
								</span>
							) : (
									"-"
								)}
						</div>
					)
				},
				{
					title: "已用完/已过期",
					dataIndex: "num4",
					render: (text, record) => (
						<div>
							{record.usage_stat ? (
								<span>
									{record.usage_stat[3] || 0}/{record.usage_stat[4] || 0}
								</span>
							) : (
									"-"
								)}
						</div>
					)
				},
				{
					title: (
						<div>
							操作
							<Tooltip
								overlayClassName="post-desc"
								style={{ width: "400px" }}
								title={
									<div>
										跨平台发卡
                    <br />
                    方式1：点击【发卡】，下载权益卡小程序码发送给客户
                    <br />
                    方式2：首页选择模板【绑定权益卡】，发送店铺小程序码给客户
                  </div>
								}>
								<Icon type="question-circle" theme="twoTone" />
							</Tooltip>
						</div>
					),
					dataIndex: "action",
					render: (text, record) => {
						switch (record.status) {
							case 0:
							case 1:
								return (
									<div>
										<span className="text-note" onClick={this.goToCreateCard.bind(this, record.gift_card_id)}>
											编辑
                    					</span>
										<Divider type="vertical" />
										<span className="text-note" onClick={this.showStopCard.bind(this, record.gift_card_id)}>
											停止发放
                    					</span>
										<Divider type="vertical" />
										<Tooltip
											overlayClassName="tooltip-link-qrcode"
											arrowPointAtCenter
											placement="left"
											autoAdjustOverflow={false}
											onVisibleChange={visible => {
												if (visible) {
													this.getGiftCardCode(record.gift_card_id);
												}
											}}
											title={
												<Form>
													<Form.Item label="推广小程序路径：">
														<Input.Search
															readOnly
															value={`pages/cardDetail/index?gift_card_id=${record.gift_card_id}`}
															size="small"
															placeholder="input search text"
															enterButton={
																<Button type="primary" shape="round">
																	复制
                                								</Button>
															}
															onSearch={this.copy}
														/>
													</Form.Item>
													<Form.Item label="">
														<div className="tooltip-qrcode">
															<img
																alt=""
																onError={target => {
																	this.setState({ url: "http://udh.oss-cn-hangzhou.aliyuncs.com/144c1feb-c3ed-46f7-9dac-f0b2527b4dea" });
																	target.onerrοr = null;
																}}
																width="120"
																src={this.state.code_url}
															/>
															<Button className="btn-qrcode" type="primary" shape="round" onClick={this.downLoadPost}>
																<a download="qrcode.png" href={this.state.code_url}>
																	下载小程序码
                                								</a>
															</Button>
														</div>
													</Form.Item>
												</Form>
											}>
											<span className="text-note">发卡</span>
										</Tooltip>
										{record.gift_card_activation_type === 1 ? (
											<div>
												<span className="text-note" onClick={this.onShowImportModal.bind(this, record.gift_card_id, record.uploaded_success_num)}>导入激活账号</span>
											</div>
										) : null}
									</div>
								);
							case 2:
							case 3:
								return (
									<span onClick={this.showDeleteConfirm.bind(this, record.gift_card_id)} className="text-note">
										删除
									</span>
								);
							default:
								break;
						}
					}
				}
			],
			importModal: false,
			gift_card_id: null,
			uploaded_success_num: 0,
		};
	}
	componentDidMount() {
		this.getCardList();
	}
	onShowImportModal(gift_card_id, uploaded_success_num) {
		this.setState({
			importModal: true,
			gift_card_id,
			uploaded_success_num,
		});
	}
	goToReceiveRecord = item => {
		this.props.changeTab && this.props.changeTab("2", { gift_card_name: item.gift_card_name, status: "0" });
	};
	showStopCard = gift_card_id => {
		Modal.confirm({
			title: "确定停止?",
			onOk: () => {
				this.stopGiftCard(gift_card_id);
			}
		});
	};
	stopGiftCard = async gift_card_id => {
		let res = await stopGiftCard({ gift_card_id });
		if (res.success) {
			message.success("已停用该卡");
			this.getCardList();
		}
	};
	showDeleteConfirm = gift_card_id => {
		Modal.confirm({
			title: "确定删除？",
			onOk: () => {
				this.deleteGiftCard(gift_card_id);
			}
		});
	};
	deleteGiftCard = async gift_card_id => {
		let res = await deleteGiftCard({ gift_card_id });
		if (res.success) {
			message.success("已删除该卡");
			this.getCardList();
		}
	};
	onShowPageChange = page => {
		this.setState(
			{
				page: page
			},
			() => {
				this.getCardList();
			}
		);
	};
	onShowSizeChange = (current, pageSize) => {
		this.setState(
			{
				page_size: pageSize
			},
			() => {
				this.getCardList();
			}
		);
	};
	clearFilter = () => {
		this.props.form.resetFields();
	};
	getCardList = async () => {
		let form = this.props.form.getFieldsValue();
		let data = {
			mini_app_id: cookie.load("mini_app_id"),
			page_no: this.state.page,
			page_size: this.state.page_size,
			gift_card_name: form.gift_card_name,
			gift_card_type: form.gift_card_type,
			status: form.status,
			usage_stat_ext: true
		};
		this.setState({
			loading: true
		});
		let res = await getGiftCardList(data);
		let list = res.result.gift_card_list;
		list.forEach(item => {
			if (item.usage_stat === "null") {
				item.usage_stat = "";
			} else {
				item.usage_stat = this.stringToObject(item.usage_stat);
			}
		});
		this.setState({
			gift_card_list: list,
			total: res.result.total,
			loading: false
		});
	};
	stringToObject(str) {
		let _str = str.substring(str.indexOf("{") + 1, str.indexOf("}"));
		let arr = _str.split(","),
			obj = {};
		arr.forEach(item => {
			let key_value = item.split(":");
			obj[key_value[0]] = key_value[1];
		});
		return obj;
	}
	goToCreateCard = gift_card_id => {
		if (gift_card_id) {
			this.context.router.history.push(`/createOrUpdateCard?gift_card_id=${gift_card_id}`);
		} else {
			this.context.router.history.push("/createOrUpdateCard");
		}
	};
	getGiftCardCode = async gift_card_id => {
		let res = await getGiftCardCode(cookie.load("shop_id"), gift_card_id);
		this.setState({
			code_url: res
		});
	};
	downLoadPost = () => {
		window.pageYOffset = 0;
		document.documentElement.scrollTop = 0;
		document.body.scrollTop = 0;
		html2canvas(document.getElementById("post"), {
			useCORS: true, // 【重要】开启跨域配置
			taintTest: false, //是否在渲染前测试图片,
			dpi: 192,
			scale: 8
		}).then(function (canvas) {
			var context = canvas.getContext("2d");
			context.mozImageSmoothingEnabled = false;
			context.webkitImageSmoothingEnabled = false;
			context.msImageSmoothingEnabled = false;
			context.imageSmoothingEnabled = false;
			var imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // 获取生成的图片的url
			var a = document.createElement("a");
			a.href = imgUri;
			a.download = "qrcode.png";
			a.click();
		});
	};
	onCloseImportModal() {
		this.setState({
			importModal: false,
		});
	}
	onUploadOver({ file }) {
		if (file.status === 'done' && file.xhr.status == '200' && file.xhr.readyState === 4) {
			const response = JSON.parse(file.xhr.response);
			if (response.success) {
				const uploaded_success_num = this.state.uploaded_success_num + response.result.upload_success_num;
				this.setState({
					uploaded_success_num,
				});
				message.success(`本次成功导入${response.result.upload_success_num}组`);
			} else {
				message.error(response.msg || '导入失败');
			}
		}
	}
	downloadExcel() {
		window.open(MUBANEXCEL);
	}
	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<Spin spinning={this.state.loading}>
				<div className="card-content">
					<div className="filter-content">
						<Form className="ant-vertical flex" layout="inline">
							<Form.Item label="权益卡名称">
								{getFieldDecorator("gift_card_name")(<Input className="card-name" placeholder="输入权益卡名称" />)}
							</Form.Item>
							<Form.Item label="权益卡类型">
								{getFieldDecorator("gift_card_type")(
									<Select className="card-type">
										<Select.Option value="0">付费权益卡</Select.Option>
										<Select.Option value="1">无门槛权益卡</Select.Option>
									</Select>
								)}
							</Form.Item>
							<Form.Item label="状态">
								{getFieldDecorator("status")(
									<Select className="card-status">
										{Object.keys(this.state.status_mapping).map(item => (
											<Select.Option value={item} key={item}>
												{this.state.status_mapping[item]}
											</Select.Option>
										))}
									</Select>
								)}
							</Form.Item>
						</Form>
						<div className="action">
							<Button shape="round" type="primary" onClick={this.onShowPageChange.bind(this, 1)}>
								筛选
              				</Button>
							<span className="text-note" onClick={this.clearFilter}>
								清空筛选条件
              				</span>
						</div>
					</div>
					<div className="action">
						<Button type="primary" shape="round" onClick={this.goToCreateCard.bind(this, null)}>
							新建权益卡
            			</Button>
					</div>
					<Table
						rowKey={(record, index) => index}
						columns={this.state.columns}
						dataSource={this.state.gift_card_list}
						pagination={false}
						bordered={true}
					/>
					<Pagination
						className="pagenation"
						onChange={this.onShowPageChange}
						onShowSizeChange={this.onShowSizeChange}
						defaultCurrent={this.state.page}
						total={this.state.total}
						showTotal={total => `共 ${total} 条`}
						pageSizeOptions={["10", "30", "50", "100"]}
						showSizeChanger
					/>
				</div>
				<Modal
					visible={this.state.importModal}
					title="导入激活账号"
					okButtonProps={{ shape: 'round' }}
					cancelButtonProps={{ shape: 'round', style: { display: 'none' } }}
					onOk={this.onCloseImportModal.bind(this)}
					onCancel={this.onCloseImportModal.bind(this)}
					okText="关闭"
				>
					<div>
						<div className="top" style={{
							display: 'flex',
							alignItems: 'center',
						}}>
							<Upload
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
								action={action}
								withCredentials={true}
								showUploadList={false}
								onChange={this.onUploadOver.bind(this)}
								data={{
									gift_card_id: this.state.gift_card_id,
									mini_app_id: cookie.load('mini_app_id')
								}}
							>
								<Button>导入账号和密码</Button>
							</Upload>
							<span style={{ marginLeft: '8px' }}>已导入{this.state.uploaded_success_num}组</span>
						</div>
						<div className="bot" style={{ marginTop: '12px', cursor: 'pointer' }}>
							<span onClick={this.downloadExcel.bind(this)} className="text-note" style={{ marginRight: '8px' }}>下载模板</span>
							{/* <span className="text-note">导入记录</span> */}
						</div>
					</div>
				</Modal>
			</Spin>
		);
	}
}

const mapStateToProps = state => {
	return {
		menu_code: state.menuCode
	};
};
const CardListForm = Form.create({ name: "card_list" })(CardList);
export default connect(mapStateToProps)(CardListForm);
