import React from "react";
import { Form, Button, Modal, message, Upload, Card, DatePicker, Radio, Popover } from "antd/lib/index";
import "@css/activity.scss";
import UploadButton from "@/view/component/common/UploadButton";
import TargetTypeModal from "@/view/component/Campaign/TargetTypeModal";
import GoodsDragSort from "@/view/component/GoodsDragSort/index";
import GoodsChoose from "@/view/component/Goods/GoodsChoose";
import CampaignFixed from "@/view/component/Campaign/CampaignFixed";
import cookie from "react-cookies";
import moment from "moment";
import {
  createOrUpdateActivity,
  dragActivityGoods,
  queryActivityDetail,
  queryCampaignActivitySubtype,
  queryMiniAppDetail
} from "@service/getData";
import config from "@config/config";
class GoodsActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      loading2: false,
      requestUrl: config.baseUrl + "/ossFile/upload",
      campaign_activity_subtype_res_list: [],
      visible: false,
      target_type_visible: false,
      search_value: null,
      goods_columns: [
        {
          title: "商品id",
          dataIndex: "goods_id"
        },
        {
          title: "商品图片",
          dataIndex: "thumbnail_url",
          render: (text, record) => (
            <div>
              <img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="100" height="100" />
            </div>
          )
        },
        {
          title: "商品名称",
          dataIndex: "goods_name"
        },
        {
          title: "价格",
          dataIndex: "current_price",
          render: text => <div>￥{text / 100}</div>
        }
      ],
      select_goods_res: [],
      current_select_goods_res: [],
      start_time: null,
      end_time: null,
      activity_id: null,
      activity_res: {
        activity_goods_res_list: []
      },
      exchange_disabled: false,
      preview_image: "",
      preview_gallery_image: "",
      review_gallery_image2: "",
      file_list: [],
      file_list2: [],
      gallery_list: [],
      gallery_list2: [],
      preview_gallery_visible: false,
      preview_gallery_visible2: false,
      show_another: ["advertisement", "topScroll", "endScroll"],
      loading: false,
      campaign_id: null,
      campaign_list: [],
      activity_desc_list: {
        title: {
          buyEveryDay: "",
          timeLimitBuy: "",
          memberDay: "",
          topScroll: "上传banner图",
          goodsListSlide: "上传背景图",
          advertisement: "上传图片广告"
        },
        no_target_type: ["buyEveryDay", "timeLimitBuy", "memberDay", "goodsListSlide"],
        mini_app_name: null,
        coupon_visible: false
      },
      coupon_list: [],
      is_auto: null,
      handle_coupon_list: [],
      target_type: null,
      pattern_type: 1,
      desc: null,
      tag_id: null,
    };
  }
  createOrUpdateActivity = async () => {
    let form = this.props.form.getFieldsValue();
    // let left_time = (new Date(this.state.end_time) - new Date(this.state.start_time)) / 1000;
    // let total = 99 * 3600 + 99 * 60 + 99;
    // if (this.props.my_activity_subtype === "timeLimitBuy" && left_time > total && left_time > 0) {
    //   return message.error("限时秒杀时间范围在99:99:99");
    // }
    let gallery_url = "";
    this.state.gallery_list.forEach(item => {
      if (item.response && item.response.success) {
        gallery_url = item.response.result.oss_file_url;
      } else if (item.url) {
        gallery_url = item.url;
      }
    });
    if (this.state.show_another.find(item => item === this.props.my_activity_subtype) && !this.state.gallery_list.length) {
      return message.error("请选择背景图");
    }
    if (this.state.target_type !== "tag" && !this.state.select_goods_res.filter(item => item.status > 0).length) {
      return message.error("请选择商品");
    }
    if (this.state.target_type === "tag" && this.state.tag_id === null) {
      return message.error("请选择商品列表");
    }
    let activity_subtype = this.state.campaign_activity_subtype_res_list.find(
      item => item.activity_subtype === this.props.my_activity_subtype
    );
    const type = this.props.my_activity_subtype;
    let data = {
      activity_req: {
        dest_campaign_id: this.state.activity_res.dest_campaign_id,
        campaign_id: this.props.campaign_id,
        mini_app_id: cookie.load("mini_app_id"),
        activity_name: activity_subtype.activity_subtype_name,
        activity_id: this.props.activity_id,
        activity_type: "campaign",
        activity_subtype: this.props.my_activity_subtype,
        target_type: this.state.target_type,
        start_time: this.state.start_time,
        end_time: this.state.end_time,
        title: form.title,
        goods_id: this.state.activity_res.goods_id,
        transfer_title: form.transfer_title,
        activity_goods_list: this.state.target_type === "goodsList" ? this.state.select_goods_res : null,
        status: 1,
        thumbnail_url: gallery_url,
        is_auto: this.state.is_auto,
        tag_id: this.state.target_type === "goodsList" ? null : this.state.tag_id,
        pattern_type: (type === "timeLimitBuy" || type === 'goodsListSlide') ? this.state.pattern_type : null
      }
    };
    this.setState({
      loading: true,
      exchange_disabled: false
    });
    let res = await createOrUpdateActivity(data);
    if (res.success) {
      this.props.form.resetFields();
      if (this.state.type === "edit") {
        message.success("组件更新成功");
      } else {
        message.success("成功添加组件");
      }
      this.props.onOK();
    } else {
      message.error(res.msg);
    }
  };
  async queryMiniAppDetail() {
    let mini_app_id = cookie.load("mini_app_id");
    let res = await queryMiniAppDetail({
      mini_app_id
    });
    this.setState({
      mini_app_name: res.result.mini_app.mini_app_name
    });
  }
  queryActivityDetail = async activity_id => {
    let data = {
      activity_id
    };
    let res = await queryActivityDetail(data);
    if (res.success) {
      let select_goods_res = res.result.activity_res.activity_goods_res_list.map((item, index) => {
        return {
          goods_id: item.goods_res.goods_id,
          goods_name: item.goods_res.goods_name,
          thumbnail_url: item.goods_res.thumbnail_url,
          current_price: item.goods_res.current_price,
          start_time: item.start_time,
          end_time: item.end_time,
          activity_id: item.activity_id,
          activity_goods_id: item.activity_goods_id,
          status: 1,
          onsale: item.goods_res.onsale,
          gallery_urls: item.goods_res.gallery_urls,
          loading: false,
          index
        };
      });
      let gallery_list = [];
      if (res.result.activity_res.thumbnail_url) {
        gallery_list = [
          {
            uid: "-1",
            name: "xxx.png",
            status: "done",
            url: res.result.activity_res.thumbnail_url
          }
        ];
      }
      let gallery_list2 = [];
      if (res.result.activity_res.image_url) {
        gallery_list2 = [
          {
            uid: "-1",
            name: "xxx.png",
            status: "done",
            url: res.result.activity_res.image_url
          }
        ];
      }
      switch (res.result.activity_res.target_type) {
        case "campaign":
          res.result.activity_res.desc = `店铺页面:${res.result.activity_res.dest_campaign_name}`;
          break;
        case "goods":
          res.result.activity_res.desc = `商品:${res.result.activity_res.goods_res.goods_name}`;
          break;
        default:
          break;
      }
      let coupon_list = res.result.activity_res.activity_coupon_res_list.map((item, index) => {
        return {
          activity_coupon_id: item.activity_coupon_id,
          coupon_id: item.coupon_res.coupon_id,
          coupon_name: item.coupon_res.coupon_name,
          status: 1,
          index: index
        };
      });
      let desc = null;
      if (res.result.activity_res.target_type === "tag") {
        desc = `商品列表页:${res.result.activity_res.tag_name}`;
      }
      this.setState({
        select_goods_res: res.result.activity_res.target_type !== "tag" ? select_goods_res : [],
        activity_res: res.result.activity_res,
        gallery_list,
        gallery_list2,
        loading: false,
        type: "edit",
        end_time: res.result.activity_res.end_time,
        start_time: res.result.activity_res.start_time,
        coupon_list,
        is_auto: res.result.activity_res.is_auto,
        exchange_disabled: false,
        target_type: res.result.activity_res.target_type,
        desc,
        tag_id: res.result.activity_res.tag_id,
        pattern_type: res.result.activity_res.pattern_type || 1
      });
    }
  };
  handleCancel = () => this.setState({ visible: false });
  handleOk = list => {
    let select_goods_res = [...this.state.select_goods_res],
      current_select_goods_res = [...list];
    select_goods_res.forEach(item => {
      let index = current_select_goods_res.findIndex(row => row.goods_id === item.goods_id);
      if (index > -1) {
        current_select_goods_res.splice(index, 1);
      }
    });
    select_goods_res = select_goods_res.concat(current_select_goods_res);
    select_goods_res.forEach((item, index) => {
      item.index = index;
    });
    this.setState({
      select_goods_res: select_goods_res,
      visible: false,
      exchange_disabled: true,
      current_select_goods_res: []
    });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  changeGoodsDate = (index, date, dateStr) => {
    if (index > -1) {
      let list = [...this.state.select_goods_res];
      list[index].start_time = dateStr[0];
      list[index].end_time = dateStr[1];
      this.setState({
        select_goods_res: list
      });
    } else {
      this.setState({
        start_time: dateStr[0],
        end_time: dateStr[1]
      });
    }
  };
  deleteGoods = index => {
    let select_goods_res = [...this.state.select_goods_res];
    let goods = select_goods_res[index];
    if (goods.activity_goods_id) {
      select_goods_res[index].status = 0;
    } else {
      select_goods_res.splice(index, 1);
    }
    select_goods_res.forEach((item, index) => {
      item.index = index;
    });
    this.setState({
      select_goods_res,
      exchange_disabled: true
    });
  };
  changeSortGoodsList = async (list, toIndex, fromIndex) => {
    let select_goods_res = [...this.state.select_goods_res];
    if (!select_goods_res[fromIndex].activity_goods_id) {
      this.setState({
        select_goods_res: list
      });
      return;
    }
    let from_activity_goods_id = select_goods_res[fromIndex].activity_goods_id,
      to__activity_goods_id =
        fromIndex > toIndex
          ? select_goods_res[toIndex].activity_goods_id
          : (select_goods_res[toIndex + 1] && select_goods_res[toIndex + 1].activity_goods_id) || null;
    let data = { from_activity_goods_id };
    if (to__activity_goods_id) {
      data.to__activity_goods_id = to__activity_goods_id;
    }
    let res = await dragActivityGoods(data);
    if (res.success) {
      this.setState(
        {
          select_goods_res: list
        },
        () => {
          this.props.onOK();
        }
      );
    }
  };
  handleGalleryCancel = () => {
    this.setState({
      preview_gallery_visible: false,
      preview_gallery_visible2: false
    });
  };
  handleGalleryChange = ({ fileList }) => {
    this.setState({ gallery_list: fileList });
  };
  handleGalleryPreview = file => {
    this.setState({
      preview_gallery_image: file.url || file.thumbUrl,
      preview_gallery_visible: true
    });
  };
  queryCampaignActivitySubtype = async () => {
    let data = {};
    let res = await queryCampaignActivitySubtype(data);
    let list = res.result.campaign_activity_subtype_res_list;
    list.push({
      activity_subtype: "coupon",
      activity_subtype_desc: "优惠券。",
      activity_subtype_name: "优惠券",
      app_id: "",
      auto_create: 0,
      base_version: 0,
      campaign_activity_subtype_id: 8,
      flagship_version: 1,
      mini_app_id: null,
      special_version: 0,
      status: 1
    });
    if (res.success) {
      this.setState({
        campaign_activity_subtype_res_list: res.result.campaign_activity_subtype_res_list,
        loading: this.state.type === "edit" ? true : false
      });
    }
  };
  onTargetOk = data => {
    this.setState({
      target_type_visible: false,
      desc: data.desc,
      tag_id: data.tag_id
    });
  };
  onTargetCancel = () => {
    this.setState({
      target_type_visible: false
    });
  };
  showTargetModal = () => {
    this.setState({
      target_type_visible: true
    });
  };
  showCard = () => {
    return this.props.my_activity_subtype === "goodsListSlide" ? true : "none";
  };
  onChangeTargetType = e => {
    this.setState({
      target_type: e.target.value
    });
  };
  onChangePatternType = e => {
    this.setState({
      pattern_type: e.target.value
    });
  };
  componentDidMount() {
    this.queryCampaignActivitySubtype();
    this.queryMiniAppDetail();
    this.props.onRef("goodsActivity", this);
  }
  render() {
    const imgurl = 'http://udh.oss-cn-hangzhou.aliyuncs.com/574f03ba-2466-43be-9c2c-6a9d55b8f775示例图.png';
    return (
      <div className="activity-add">
        {this.props.my_activity_subtype === 'goodsListSlide' ? (
          <Card className="card-info">
            <Form>
              <Form.Item label="选择样式" extra={
                <Popover content={
                  <img style={{ width: '240px', maxHeight: '360px' }} src={imgurl} />
                }>
                  <span style={{ color: '#1890ff' }}>悬浮样式示例图</span>
                </Popover>
              }>
                <Radio.Group value={this.state.pattern_type} onChange={this.onChangePatternType.bind(this)}>
                  <Radio value={1}>普通样式</Radio>
                  <Radio value={2}>悬浮样式</Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          </Card>
        ) : null}
        <Card style={{ display: this.showCard() }} className="card-info" title="商品背景图片">
          <Form>
            <Form.Item label="上传背景图">
              <div className="image-upload">
                <Upload
                  accept="image/jpeg,image/jpg,image/png"
                  withCredentials={true}
                  action={this.state.requestUrl}
                  beforeUpload={this.beforeUpdate}
                  listType="picture-card"
                  fileList={this.state.gallery_list}
                  onPreview={this.handleGalleryPreview}
                  onChange={this.handleGalleryChange}>
                  {this.state.gallery_list.length >= 1 ? null : <UploadButton />}
                </Upload>
                <div className="text-remind">建议宽度375，高度不限，仅支持jpg,png 2种格式,单张图片大小不超过3.0MB</div>
                <Modal visible={this.state.preview_gallery_visible} footer={null} onCancel={this.handleGalleryCancel}>
                  <img alt="example" style={{ width: "100%" }} src={this.state.preview_gallery_image} />
                </Modal>
              </div>
            </Form.Item>
            <Form.Item label="跳转路径" style={{ display: this.props.my_activity_subtype === "goodsListSlide" ? "none" : true }}>
              {this.state.activity_res.target_type === "" ||
                this.state.activity_res.target_type === null ||
                this.state.activity_res.target_type === "goodsList" ? (
                  <Button className="btn-choose-targettype" onClick={this.showTargetModal}>
                    选择跳转路径
                  </Button>
                ) : (
                  <span>
                    {this.state.activity_res.desc}
                    <span className="text-note" onClick={this.showTargetModal}>
                      修改
                  </span>
                  </span>
                )}
            </Form.Item>
          </Form>
        </Card>
        {this.props.my_activity_subtype === "timeLimitBuy" ? (
          <div className="date-time-limit-buy">
            <Form>
              <Form.Item label="秒杀时间：">
                <DatePicker.RangePicker
                  onChange={this.changeGoodsDate.bind(this, -1)}
                  className="date-time-limit-buy-item"
                  format="YYYY-MM-DD HH:mm:ss"
                  showTime
                  value={
                    this.state.start_time ? [moment(this.state.start_time, "YYYY-MM-DD"), moment(this.state.end_time, "YYYY-MM-DD")] : null
                  }
                />
              </Form.Item>
              <Form.Item label="选择样式：">
                <Radio.Group value={this.state.pattern_type} onChange={this.onChangePatternType}>
                  <Radio value={1}>样式一</Radio>
                  <Radio value={2}>样式二</Radio>
                </Radio.Group>
              </Form.Item>
              <CampaignFixed campaign={this.state.activity_res} show_type={this.state.pattern_type} />
            </Form>
          </div>
        ) : null}
        <div className="date-time-limit-buy">
          <Form>
            <Form.Item label="商品来源">
              <Radio.Group value={this.state.target_type} onChange={this.onChangeTargetType}>
                <Radio value="goodsList">商品</Radio>
                <Radio value="tag">商品列表</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </div>
        {this.state.target_type === "goodsList" ? (
          <div className="goods-image-list">
            <GoodsDragSort
              size="small"
              list={this.state.select_goods_res.filter(item => item.status !== 0)}
              handlePreview={() => { }}
              editable={true}
              delete={this.deleteGoods}
              changeList={this.changeSortGoodsList}
            />
          </div>
        ) : null}
        {this.state.target_type === "goodsList" ? (
          <Button type="dashed" className="button-activity-goods" onClick={this.showModal}>
            +添加商品
          </Button>
        ) : null}
        {this.state.target_type === "tag" ? (
          this.state.desc ? (
            <span className="activity-target-desc">
              {this.state.desc}
              <span className="text-note" onClick={this.showTargetModal}>
                修改
              </span>
            </span>
          ) : (
              <Button type="dashed" className="button-activity-goods" onClick={this.showTargetModal}>
                +添加商品列表
              </Button>
            )
        ) : null}
        <div className="action-save">
          <Button type="primary" className="btn-save" onClick={this.createOrUpdateActivity}>
            提交
          </Button>
        </div>
        <GoodsChoose visible={this.state.visible} handleOk={this.handleOk} handleCancel={this.handleCancel} />
        <TargetTypeModal visible={this.state.target_type_visible} onOK={this.onTargetOk} onCancel={this.onTargetCancel} showGoods={true} />
      </div>
    );
  }
}
const goodsActivityForm = Form.create({ name: "goods_activity" })(GoodsActivity);
export default goodsActivityForm;
