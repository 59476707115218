import React from 'react'
import NavBar from '@/view/component/common/NavBar.js'
import {connect} from "react-redux";
import {Tabs} from 'antd'
import SelfOrderShipment from '@/view/component/Order/SelfOrderShipment'
import '@css/selfOrderShipment.scss'
class  OrderShipment extends React.Component{
    render(){
        let { menu_code } = this.props
        return (
            <div className="order-shipment">
                <NavBar icon="icon-fahuo" child={menu_code.self_shipment_history}/>
                <Tabs
                    defaultActiveKey="1"
                    tabBarStyle={{ backgroundColor: "#ffffff", borderRadius: "4px" }}
                >
                    <Tabs.TabPane tab="订单" key="1">
                        <SelfOrderShipment  index="1"/>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        menu_code:state.menuCode,
        order_status_list:state.orderStatusList
    }
}


export default connect(mapStateToProps) (OrderShipment);
