import React from 'react'
import { connect } from "react-redux";
import '@css/appOrderItem.scss'
import { Button } from 'antd'
import RechargeModal from '@/view/component/common/RechargeModal'
import { createAppCreateOrder, queryAdminOrderDetail } from "@service/getData";
import { message } from "antd/lib/index";
import { getQueryString } from "@common/common";
import cookie from 'react-cookies'
class AppOrderItem extends React.Component {
    constructor() {
        super()
        this.state = {
            visible: false,
            qr_code: null,
            less_time: null,
            app_apply_id: null,
            admin_order_id: null
        }
    }
    handleOk = () => {
        clearInterval(this.timer);
        this.setState({
            visible: false
        })
    }
    handleCancel = () => {
        clearInterval(this.timer);
        this.setState({
            visible: false
        })

    }
    createAppCreateOrder = async () => {
        let app_apply_id = getQueryString("app_apply_id")
        if (!app_apply_id) {
            app_apply_id = cookie.load("app_apply_id")
        }
        let return_url = window.location.href;
        let res = await createAppCreateOrder({
            admin_Order: {
                app_apply_id
            },
            mini_app_type: this.props.app.version,
            return_url
        });
        if (res.result) {
            let code_url = JSON.parse(res.result.pay_form).code_url;
            this.setState(
                {
                    qr_code: code_url,
                    admin_order_id: res.result.admin_order_id
                },
                () => this.showModal()
            );
        }
    };
    async queryAdminOrderDetail(data) {
        let res = await queryAdminOrderDetail(data);
        if (res.success) {
            if (
                res.result.admin_order.order_status === 2 ||
                res.result.admin_order.order_status === 3
            ) {
                message.success("充值成功");
                window.history.go(0)
                this.handleCancel();
            }
        }
    }
    showModal = () => {
        this.setState({
            visible: true
        })
        let allTime = 60 * 30;
        this.timer = setInterval(() => {
            --allTime;
            this.setState({
                less_time:
                    this.returnNum(Math.floor(allTime / 60)) +
                    ":" +
                    this.returnNum(allTime % 60)
            });
            let data = {
                admin_order_id: this.state.admin_order_id
            };
            this.queryAdminOrderDetail(data);
            if (allTime === 0) {
                clearInterval(this.timer);
            }
        }, 1000);
    };
    returnNum = num => {
        return num < 10 ? "0" + num : num;
    };
    render() {
        return (
            <div className="app-order-item">
                <i className={["iconfont", this.props.app.icon].join(' ')}></i>
                <p className="app-name">{this.props.app.name}</p>
                <p className="app-desc">{this.props.app.desc}</p>
                <div className="app-function">
                    {
                        this.props.app_function.map((item, index) =>
                            <div key={index}>
                                {
                                    item.no_show && item.no_show.find(item => item === this.props.app.name) ? null : (<p>
                                        {
                                            item.no_surport && item.no_surport.find(item => item === this.props.app.name) ? (<i className="iconfont icon-cha"></i>) : (<i className="iconfont icon-duihao"></i>)
                                        }
                                        {item.name}{item.admin ? this.props.app.admin + "位" : null}{item.shop ? this.props.app.shop + "个" : null}
                                    </p>)
                                }
                            </div>)
                    }
                </div>
                <Button type="primary" onClick={this.createAppCreateOrder}>{this.props.app.fee}</Button>
                <RechargeModal visible={this.state.visible} fee={this.props.app.fee} handleOk={this.handleOk} handleCancel={this.handleCancel} qr_code={this.state.qr_code} less_time={this.state.less_time} />
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        app_function: state.appFunction
    };
};

export default connect(mapStateToProps)(AppOrderItem)