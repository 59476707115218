import React, { Component } from 'react';
import { Button, Form, Input, Icon, Tooltip, message } from 'antd'
import copy from "copy-to-clipboard/index";

class GoodsButton extends Component {
    constructor() {
        super();
    }

    downLoadPost() {
        this.props.downLoadPost();
    }
    copy(value) {
        copy(value)
        message.success('复制成功');
    }

    render() {
        return (
            <div className="buttonWrap">
                <div className="coupon-post-form">
                    <Form layout="vertical">
                        <Form.Item label={<div>推广商品路径
                            <Tooltip
                                overlayClassName="post-desc"
                                style={{ width: '400px' }} title={<div>
                                    通过填写商品页面路径，直接使内容链接到指定的小程序页面。可用于以下场景：
                                <br />
                                1.关联公众号：在公众号文章中添加小程序卡片
                                </div>}>
                                <Icon type="question-circle" theme="twoTone" />
                            </Tooltip>
                            ：
                        </div>}>
                            <Input.Search
                                style={{ width: 200 }}
                                readOnly
                                value={`pages/groupDetail/index?period_purchase_id=${this.props.period_purchase_id}`}
                                size="small"
                                placeholder="input search text"
                                enterButton={
                                    <Button
                                        type="primary"
                                        shape="round">复制</Button>
                                }
                                onSearch={this.copy}
                            />
                        </Form.Item>
                    </Form>
                    <footer style={{ textAlign: "left" }}>
                        <Button shape="round" type="primary" onClick={this.downLoadPost.bind(this)}>电脑下载</Button>
                        <Button shape="round" id="my-tooltip">
                            <a download="qrcode.png" href={this.props.url}>小程序码下载</a>
                        </Button>
                    </footer>
                </div>
            </div>
        );
    }
}

export default GoodsButton;