import React, { Fragment } from "react";
import "@css/goodsManage.scss";
import NavBar from "@/view/component/common/NavBar.js";
import {
	Button,
	Form,
	Input,
	Table,
	Pagination,
	Popconfirm,
	message,
	Modal,
	Checkbox,
	DatePicker,
	TreeSelect,
	Tabs,
	Spin,
	Divider,
	Icon
} from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import { formatIntAndZero, formatNumber } from "@common/common";
import GoodsShow from "@/view/component/Goods/GoodsShow";
import GoodsGroupAdd from "@/view/component/common/GoodsGroupAdd";
import Poster from '@/view/component/Poster/Poster';
import GoodsPoster from './children/GoodsPoster';
import GoodsButton from './children/GoodsButton';
import {
	queryGoodsList,
	updateGoodsAndSku,
	onsaleGoodsList,
	queryCommunityUserList,
	updateGroupOpenRecordGoods,
	getCatList,
	getCode,
	getBase64ImageUrl
} from "@service/getData";
import html2canvas from 'html2canvas';
import moment from 'moment';
let goodsGroupAddRef
class GoodsManage extends React.Component {
	constructor(props) {
		super();
		GoodsManage.contextTypes = {
			router: PropTypes.object.isRequired
		};
		this.state = {
			selectedRowKeys: [],
			selectedRows: [],
			page_no: 1,
			page_size: 10,
			total: 0,
			current_price: null,
			goods_name: null,
			balance: null,
			goodsList: [],
			smallPrice: null,
			bigPrice: null,
			changeNameVisible: null,
			goods_show_visible: false,
			onsaleStatus: 1,
			loading: true,
			sort: null,
			columns: [
				{
					title: "商品名称",
					dataIndex: "goods_name",
					render: (text, record) => (
						<div className="goods-info">
							<div className="goods-info-img">
								<img alt="" width="60" height="60" src={JSON.parse(record.gallery_urls)[0]} />
							</div>
							<div className="goods-info-detail">
								<div className="goods-info-name" title={record.goods_name}>
									{record.goods_name}
								</div>
								<div>id：{record.goods_id}</div>
							</div>
							<div className={"change-goods-name" + (record.showEdit ? "" : " hide")}>
								<Popconfirm
									title={
										<div className="goods-price-change">
											<Input defaultValue={text} style={{ width: "400px", borderRadius: "100px" }} onChange={this.changeGoodsName} />
										</div>
									}
									onConfirm={this.confirmChangeGoodsName.bind(this, record)}
									onCancel={this.cancelChange}
									okText="确认"
									cancelText="取消"
									placement="bottom"
									icon={null}>
									<Icon type="edit"></Icon>
								</Popconfirm>
							</div>
						</div>
					)
				},
				{
					title: "价格",
					dataIndex: "current_price",
					sorter: true,
					render: (text, record) => (
						<div className="flex">
							<span className="mr20 no-wrap">￥{text / 100}</span>
							<div className={record.showEdit ? "" : "hide"}>
								<Popconfirm
									overlayClassName={record.dataSource.length < 1 ? null : "balance-pop"}
									title={
										record.dataSource.length < 1 ? (
											<div className="goods-balance-change">
												<Input
													value={this.state.current_price}
													style={{ width: "84px", borderRadius: "100px" }}
													onChange={this.changeCurrentPrice}
												/>
											</div>
										) : (
												<div>
													<Table
														rowKey={(record, index) => index}
														dataSource={record.dataSource}
														columns={this.state.current_price_columns}
													/>
												</div>
											)
									}
									onConfirm={
										record.dataSource.length < 1
											? this.confirmChangeCurrentPrice.bind(this, record).bind(this, record)
											: this.confirmChangeSkuBalance.bind(this, record)
									}
									onCancel={this.cancelChange}
									okText="确认"
									cancelText="取消"
									placement="bottom"
									icon={null}>
									<Icon type="edit"></Icon>
								</Popconfirm>
							</div>
						</div>
					)
				},
				{
					title: "状态",
					dataIndex: "onsale",
					render: text => (
						<div>
							<span style={{ display: text === 1 ? true : "none" }}>上架</span>
							<span style={{ display: text === 2 ? true : "none" }}>未上架</span>
							<span style={{ display: text === 3 ? true : "none" }}>下架</span>
						</div>
					)
				},
				{
					title: "商品库存",
					dataIndex: "balance",
					sorter: true,
					render: (text, record) => (
						<div className="flex">
							<span className="mr20">{text}</span>
							<div className={record.showEdit ? "" : "hide"}>
								<Popconfirm
									overlayClassName={record.dataSource.length < 1 ? null : "balance-pop"}
									title={
										record.dataSource.length < 1 ? (
											<div className="goods-balance-change">
												<Input
													placeholder="请输入新的商品库存"
													value={this.state.balance}
													style={{ width: "400px", borderRadius: "100px" }}
													onChange={this.changeGoodsBalance}
												/>
											</div>
										) : (
												<div>
													<Table rowKey={(record, index) => index} dataSource={record.dataSource} columns={this.state.balance_columns} />
												</div>
											)
									}
									onConfirm={
										record.dataSource.length < 1
											? this.confirmChangeBalance.bind(this, record)
											: this.confirmChangeSkuBalance.bind(this, record, "balance")
									}
									onCancel={this.cancelChange}
									okText="确认"
									cancelText="取消"
									placement="bottom"
									icon={null}>
									<Icon type="edit"></Icon>
								</Popconfirm>
							</div>
						</div>
					)
				},
				{
					title: "销量",
					dataIndex: "sale_num",
					sorter: true
				},
				{
					title: '排序',
					dataIndex: 'sort',
					sorter: true,
					render: (text, row, index) => (
						<Fragment>
							<span className="mr20">{text || null}</span>
							<div className={row.showEdit ? "" : "hide"}>
								<Popconfirm
									overlayClassName='sortPop'
									placement="left"
									title={
										<div>
											<p className="title">修改排序</p>
											<Form wrapperCol={{ span: 20 }} labelCol={{ span: 4 }}>
												<Form.Item
													label="排序"
													required
													extra="数字越大，排序越靠前。数字重复，则最新上架的靠前"
												>
													<Input placeholder={`${text}`} value={this.state.sort} onChange={this.sortChange.bind(this)} />
												</Form.Item>
											</Form>
										</div>
									}
									icon={null}
									onConfirm={this.sortConfirm.bind(this, row)}
								>
									<Icon type="edit"></Icon>
								</Popconfirm>
							</div>
						</Fragment>
					)
				},
				{
					title: "创建时间",
					dataIndex: "created_at",
					sorter: true
				},
				{
					title: "操作",
					dataIndex: "action",
					width: "24%",
					render: (text, record) => (
						<div className="option-list">
							<span onClick={this.goGoodsDetail.bind(this, record)} style={{ marginRight: "0px" }} className="text-note">
								查看
              				</span>
							<Divider type="vertical" />
							<span onClick={this.goGoodsEdit.bind(this, record)} style={{ marginRight: "0px" }} className="text-note">
								编辑
              				</span>
							<Divider type="vertical" />
							{cookie.load("mini_app_type") === "flagship_version" || !record.group_open_record_goods_res ? null : (
								<>
									<span onClick={this.showModal.bind(this, record.group_open_record_goods_res)} className="text-note">
										更新团
              						</span>
									<Divider type="vertical" />
								</>
							)}
							<span className="text-note" onClick={this.showGoodsVisible.bind(this, record)}>
								预览
              				</span>
							{record.onsale === 1 ?
								<>
									<Divider type="vertical" />
									<span className="text-note" onClick={this.showPromoteModal.bind(this, record)}>推广</span>
								</>
								: null}
							{/* <Divider type="vertical" />
							<span className="text-note" onClick={this.onChangePriceInTimeClick.bind(this, record)}>
								定时改价
							</span> */}
						</div>
					)
				}
			],
			selectedLeaderRowKeys: [],
			can_group_shipment: 0,
			can_self_shipment: 0,
			chooseLeaderColumns: [
				{
					title: "社群名称",
					dataIndex: "community_name"
				},
				{
					title: "团长姓名",
					dataIndex: "nick_name"
				},
				{
					title: "电话",
					dataIndex: "mobile"
				}
			],
			leaderColumns: [
				{
					title: "社群名称",
					dataIndex: "community_name"
				},
				{
					title: "团长姓名",
					dataIndex: "nick_name"
				},
				{
					title: "电话",
					dataIndex: "mobile"
				},
				{
					title: "操作",
					dataIndex: "action",
					render: (text, record, index) => (
						<div>
							<span onClick={this.handelLeaderDel.bind(this, index)} style={{ marginRight: "5px" }} className="text-note">
								删除
              				</span>
						</div>
					)
				}
			],
			visible: false,
			leaderVisble: false,
			groupRecord: {},
			publish_start_date: null,
			publish_end_date: null,
			mobile: null,
			chooseLeaderData: [],
			leaderData: [],
			goods: {},
			cat_list: [],
			order_by_condition: null,
			current_price_columns: [
				{
					title: "规格",
					dataIndex: "sku"
				},
				{
					title: "价格",
					dataIndex: "current_price",
					render: (text, record) => (
						<div>
							<Input
								onChange={this.changeSkuBalance.bind(this, record, "current_price")}
								style={{ width: "80px", borderRadius: "100px" }}
								value={text}
							/>
						</div>
					)
				}
			],
			balance_columns: [
				{
					title: "规格",
					dataIndex: "sku"
				},
				{
					title: "库存",
					dataIndex: "balance",
					render: (text, record) => (
						<div>
							<Input
								onChange={this.changeSkuBalance.bind(this, record, "balance")}
								style={{ width: "80px", borderRadius: "100px" }}
								value={text}
							/>
						</div>
					)
				}
			],
			checkAll: false,
			indeterminate: false,
			openGroupVisible: false,
			promoteVisible: false,
			posterInfo: {},
			url: '',
			changePriceModal: false,
		};
	}
	onChangePriceInTimeClick(record) {
		this.setState({
			changePriceModal: true,
		});
	}
	onSelectChange = (selectedRowKeys, selectedRows) => {
		this.setState({
			selectedRowKeys,
			selectedRows,
			indeterminate: !!selectedRows.length && selectedRows.length < this.state.goodsList.length,
			checkAll: selectedRows.length === this.state.goodsList.length
		});
	};
	onCheckAllChange = e => {
		let selectedRows = e.target.checked ? this.state.goodsList : [];
		let selectedRowKeys = selectedRows.map(item => item.goods_id);
		this.setState({
			selectedRows,
			selectedRowKeys,
			indeterminate: false,
			checkAll: e.target.checked
		});
	};
	onShowSizeChange = (current, pageSize) => {
		this.setState(
			{
				page_size: pageSize
			},
			() => {
				this.filterData(1);
			}
		);
	};
	onShowPageChange = page => {
		this.setState(
			{
				page_no: page
			},
			() => {
				this.filterData(1);
			}
		);
	};
	onChangeSmallPrice = e => {
		let value = formatNumber(e.target.value);
		this.setState({
			smallPrice: value
		});
	};
	onChangeBigPrice = e => {
		let value = formatNumber(e.target.value);
		this.setState({
			bigPrice: value
		});
	};
	changeCurrentPrice = e => {
		let value = formatNumber(e.target.value);
		this.setState({
			current_price: value
		});
	};
	changeGoodsName = e => {
		this.setState({
			goods_name: e.target.value
		});
	};
	changeGoodsBalance = e => {
		let value = formatIntAndZero(e.target.value);
		this.setState({
			balance: value
		});
	};
	confirmChangeCurrentPrice = (record, e) => {
		let data = {
			goods_req: {
				goods_id: record.goods_id,
				current_price: Math.round(this.state.current_price * 100),
				original_price: record.original_price,
			}
		};
		this.updateGoodsAndSku(data);
	};
	confirmChangeGoodsName = (record, e) => {
		let data = {
			goods_req: {
				goods_id: record.goods_id,
				goods_name: this.state.goods_name,
				original_price: record.original_price,
			}
		};
		this.updateGoodsAndSku(data);
	};
	confirmChangeBalance = (record, e) => {
		let data = {
			goods_req: {
				goods_id: record.goods_id,
				sku_req_list: [
					{
						balance: this.state.balance,
						sku_id: record.sku_res_list[0].sku_id
					}
				],
				original_price: record.original_price,
			}
		};
		this.updateGoodsAndSku(data);
	};
	sortChange(e) {
		this.setState({
			sort: +e.target.value,
		});
	}
	sortConfirm(record) {
		if (!this.state.sort) {
			return;
		}
		const data = {
			goods_req: {
				shop_id: record.shop_id,
				goods_id: record.goods_id,
				sort: this.state.sort,
				original_price: record.original_price,
			}
		}
		this.updateGoodsAndSku(data);
	}
	confirmChangeSkuBalance = record => {
		let sku_req_list = [];
		record.dataSource.forEach(item => {
			let sku = {
				sku_id: item.sku_id,
				balance: item.balance,
				current_price: item.current_price * 100
			};
			sku_req_list.push(sku);
		});
		let data = {
			goods_req: {
				goods_id: record.goods_id,
				sku_req_list: sku_req_list,
				original_price: record.original_price,
			}
		};
		this.updateGoodsAndSku(data);
	};
	cancelChange = e => {
		this.setState({
			current_price: null,
			goods_name: null
		});
	};
	filterData = status => {
		let form = this.props.form.getFieldsValue();
		let data = {
			page_no: this.state.page_no,
			page_size: this.state.page_size,
			shop_id: cookie.load("shop_id"),
			onsale: this.state.onsaleStatus ? this.state.onsaleStatus : null,
			show_hide: 1
		};
		if (form.goods_name) {
			data.goods_name = form.goods_name;
		}
		if (form.goods_id) {
			data.goods_id = form.goods_id;
		}
		if (this.state.smallPrice) {
			data.small_price = Math.round(this.state.smallPrice * 100);
		}
		if (this.state.bigPrice) {
			data.big_price = Math.round(this.state.bigPrice * 100);
		}
		let bigPrice = this.state.bigPrice,
			smallPrice = this.state.smallPrice;
		if (!!this.state.smallPrice && !!this.state.bigPrice && Number(bigPrice) < Number(smallPrice)) {
			smallPrice = this.state.bigPrice;
			bigPrice = this.state.smallPrice;
			data.small_price = Math.round(smallPrice * 100);
			data.big_price = Math.round(bigPrice * 100);
		}
		if (form.cat_id) {
			data.cat_id = form.cat_id;
		}
		data.order_by_condition = this.state.order_by_condition;
		this.setState({
			smallPrice,
			bigPrice
		});
		this.queryGoodsList(data);
		if (status === 1) {
			window.scrollTo(0, 0);
		}
	};
	async queryGoodsList(data) {
		if (!cookie.load("shop_id") && cookie.load("is_admin") === 1) {
			return;
		}
		let res = await queryGoodsList(data);
		if (res.success) {
			let goodsList = [...res.result.goods_res_list];
			goodsList.forEach(item => {
				item.showEdit = false;
				let dataSource = [];
				if (item.attribute_res_list.length > 0) {
					item.sku_res_list.forEach(item => {
						let sku = "";
						item.attribute_value_res_list.forEach(item => {
							sku += item.attribute_value;
						});
						let data = {
							balance: item.balance,
							goods_id: item.goods_id,
							sku_id: item.sku_id,
							current_price: item.current_price / 100,
							sku: sku
						};
						item.attribute_value_res_list.forEach(attr => {
							data[attr.attribute_id] = attr.attribute_value;
						});
						if (item.attribute_value_res_list.length > 0) {
							dataSource.push(data);
						}
					});
				}
				item.dataSource = dataSource;
			});
			this.setState({
				loading: false,
				goodsList,
				total: res.result.total,
				selectedRowKeys: [],
				selectedLeaderRowKeys: []
			});
		}
	}
	async updateGoodsAndSku(data) {
		let res = await updateGoodsAndSku(data);
		if (res.success) {
			message.success("更新商品成功");
			this.filterData(1);
			this.setState({
				current_price: null,
				goods_name: null,
				balance: null,
				sort: null,
			});
		}
	}
	async onsaleGoodsList(data) {
		let res = await onsaleGoodsList(data);
		if (res.success) {
			message.success("商品下架成功");
			this.filterData(1);
		}
	}
	goGoodsDetail = data => {
		let url = "/goodsGroupDetail?goods_id=" + data.goods_id;
		if (data.group_open_record_goods_res) {
			url += "&group_open_record_goods_id=" + data.group_open_record_goods_res.group_open_record_goods_id;
		}
		window.open(`${window.location.origin}${url}`, "_blank");
	};
	goGoodsEdit = data => {
		let url = "/goodsGroupDetail?eidt=1&goods_id=" + data.goods_id;
		if (data.group_open_record_goods_res) {
			url += "&group_open_record_goods_id=" + data.group_open_record_goods_res.group_open_record_goods_id;
		}
		window.open(`${window.location.origin}${url}`, "_blank");
	};
	componentDidMount() {
		let data = {
			page_no: this.state.page_no,
			page_size: this.state.page_size,
			shop_id: cookie.load("shop_id"),
			onsale: this.state.onsaleStatus ? this.state.onsaleStatus : null,
			show_hide: 1,
			cat_id: this.props.state
		};
		this.queryGoodsList(data);
		this.getCatList();
	}
	async getCode(record) {
		const data = {
			shop_id: cookie.load('shop_id'),
			group_id: record.default_group_id,
			page: 'pages/shareGoods/index',
		}
		let res = await getCode(data);
		this.setState({
			url: res,
		});
	}
	changeOnsale = () => {
		let data = {
			goods_id_list: this.state.selectedRowKeys,
			onsale_status: 0
		};
		this.onsaleGoodsList(data);
	};
	changeSkuBalance = (data, attr, e) => {
		e.persist();
		let goodsList = this.state.goodsList.splice(0);
		goodsList.forEach(item => {
			if (+item.goods_id === +data.goods_id) {
				item.dataSource.forEach(sku => {
					if (sku.sku_id === data.sku_id) {
						if (attr === "balance") {
							sku[attr] = formatIntAndZero(e.target.value);
						} else {
							sku[attr] = formatNumber(e.target.value);
						}
					}
				});
			}
		});
		this.setState({
			goodsList: goodsList
		});
	};
	goToPublishNew = () => {
		this.context.router.history.push("/goodsAdd");
	};
	goGroupAdd = () => {
		this.context.router.history.push("/groupAdd");
	};
	clearFilter = () => {
		this.props.form.resetFields();
		this.setState({
			smallPrice: null,
			bigPrice: null
		});
	};
	onSelectLeaderChange = (selectedLeaderRowKeys, selectedLeaderRows) => {
		this.setState({
			selectedLeaderRowKeys,
			selectedLeaderRows
		});
	};
	onChangeShipmentType = checkedList => {
		let can_group_shipment = false,
			can_self_shipment = false;
		checkedList.forEach(item => {
			if (item === 2) {
				can_self_shipment = true;
			} else if (item === 1) {
				can_group_shipment = true;
			}
		});
		this.setState({
			can_group_shipment: can_group_shipment,
			can_self_shipment: can_self_shipment
		});
	};
	showModal = data => {
		let checkList = [];
		if (data.can_group_shipment) {
			checkList.push("1");
		}
		if (data.can_self_shipment) {
			checkList.push("2");
		}
		data.checkList = checkList;
		this.setState(
			prevState => ({
				visible: true,
				group_open_record_goods_id: data.group_open_record_goods_id,
				groupRecord: data,
				can_self_shipment: data.can_self_shipment,
				can_group_shipment: data.can_group_shipment
			}),
			() => {
				this.queryCommunityUserList();
			}
		);
	};
	handleLeaderOk = e => {
		let arr = [];
		if (this.state.selectedLeaderRows) {
			arr = this.state.leaderData.concat(this.state.selectedLeaderRows);
		}
		let data = Array.from(new Set(arr));
		this.setState(prevState => ({
			leaderVisble: false,
			leaderData: data
		}));
	};
	handleLeaderCancel = e => {
		this.setState({
			leaderVisble: false
		});
	};
	handleLeader = e => {
		this.setState({
			leaderVisble: true
		});
	};
	saveLeader = () => {
		let form = this.props.form.getFieldsValue();
		let data = {
			shop_id: cookie.load("shop_id"),
			group_open_record_goods_id: this.state.group_open_record_goods_id,
			is_all_leader: 2
		};
		if (this.state.can_group_shipment && this.state.can_self_shipment) {
			data.group_type = 3;
			data.group_shipment_fee = 0;
			data.self_shipment_fee = Math.round(this.state.self_shipment_fee * 100);
		} else if (this.state.can_group_shipment === 1) {
			data.group_type = 1;
			data.group_shipment_fee = 0;
		} else if (this.state.can_self_shipment === 1) {
			data.group_type = 2;
			data.self_shipment_fee = Math.round(this.state.self_shipment_fee * 100);
		} else {
			return message.error("请选择邮寄类型");
		}
		if (this.state.leaderData.length) {
			data.community_ids = this.state.leaderData.map(item => item.community_id);
		}
		if (form.self_shipment_fee) {
			data.self_shipment_fee = Math.round(this.state.self_shipment_fee * 100);
		}
		if (this.state.publish_start_date) {
			data.start_time = this.state.publish_start_date;
		}
		if (this.state.publish_end_date) {
			data.expire_time = this.state.publish_end_date;
		}
		this.updateGroupOpenRecordGoods(data);
	};
	handleCancel = e => {
		this.setState({
			visible: false,
			selectedLeaderRowKeys: []
		});
	};
	onShowTimeChange = (date, dateStr) => {
		this.setState({
			publish_start_date: dateStr[0],
			publish_end_date: dateStr[1]
		});
	};
	changeSelfShipmentFee = e => {
		this.setState({
			self_shipment_fee: formatNumber(e.target.value)
		});
	};
	changeMobile = e => {
		this.setState(
			{
				mobile: e.target.value
			},
			() => {
				this.queryCommunityUserList();
			}
		);
	};
	async queryCommunityUserList() {
		let data = {
			shop_id: cookie.load("shop_id"),
			goods_id: this.state.groupRecord.goods_id
		};
		if (this.state.mobile) {
			data.mobile = this.state.mobile;
		}
		let res = await queryCommunityUserList(data);
		if (res.success) {
			this.setState({
				chooseLeaderData: res.result.list
			});
		}
	}
	handelLeaderDel = index => {
		let leaderData = this.state.leaderData;
		let selectedLeaderRowKeys = this.state.selectedLeaderRowKeys;
		leaderData.splice(index, 1);
		selectedLeaderRowKeys.splice(index, 1);
		this.setState({
			leaderData: leaderData,
			selectedLeaderRowKeys: selectedLeaderRowKeys
		});
	};
	async updateGroupOpenRecordGoods(data) {
		let res = await updateGroupOpenRecordGoods(data);
		if (res.success) {
			message.success("更新到新团长成功");
			this.setState({
				visible: false,
				leaderData: [],
				selectedLeaderRowKeys: [],
				selectedLeaderRows: []
			});
			this.filterData(1);
		} else {
			message.error(res.msg);
		}
	}
	getCatList = async () => {
		let data = {
			mini_app_id: cookie.load("mini_app_id")
		};
		let res = await getCatList(data);
		if (res.success) {
			this.setState({
				cat_list: res.result.cat_list
			});
		}
	};
	showGoodsVisible = record => {
		this.setState({
			goods: record,
			goods_show_visible: true
		});
	};
	showGoodsVisibleCancel = () => {
		this.setState({
			goods_show_visible: false
		});
	};
	handleTableChange = (pagination, filters, sorter) => {
		let order_by_condition;
		switch (sorter.field) {
			case "balance":
				if (sorter.order === "ascend") {
					order_by_condition = "atpNumAsc";
				} else {
					order_by_condition = "atpNumDesc";
				}
				break;
			case "sale_num":
				if (sorter.order === "ascend") {
					order_by_condition = "saleNumAsc";
				} else {
					order_by_condition = "saleNumDesc";
				}
				break;
			case "created_at":
				if (sorter.order === "ascend") {
					order_by_condition = "createdAtAsc";
				} else {
					order_by_condition = "createdAtDesc";
				}
				break;
			case "current_price":
				if (sorter.order === "ascend") {
					order_by_condition = "goodsPriceAsc";
				} else {
					order_by_condition = "goodsPriceDesc";
				}
				break;
			case 'sort':
				if (sorter.order === 'ascend') {
					order_by_condition = 'sortAsc'
				} else {
					order_by_condition = 'sortDesc'
				}
				break;
			default:
				order_by_condition = null;
		}
		this.setState(
			{
				order_by_condition
			},
			() => {
				this.filterData(0);
			}
		);
	};
	changeTabs = status => {
		this.setState(
			{
				onsaleStatus: +status,
				loading: true
			},
			() => {
				this.filterData(0);
			}
		);
	};
	openGroup = (group) => {
		if (cookie.load("mini_app_type") === "flagship_version") {
			goodsGroupAddRef.createGroup(group)
		} else {
			this.setState({
				openGroupVisible: true
			});
		}
	};
	handleClose = () => {
		this.setState(
			{
				openGroupVisible: false,
				goods_id: null
			},
			() => {
				this.filterData(1);
			}
		);
	};
	rowHover = (index, showEdit) => {
		let list = [...this.state.goodsList];
		list[index].showEdit = showEdit;
		this.setState({
			goodsList: list
		});
	};
	showPromoteModal(record) {
		this.setState({
			promoteVisible: true,
			posterInfo: record,
		}, () => {
			this.getCode(record);
			this.getBase64ImageUrl(record);
		});
	}
	async getBase64ImageUrl(record) {
		let res = await getBase64ImageUrl(JSON.parse(record.gallery_urls)[0]);
		this.setState({
			posterImg: res,
		});
	}
	postCancel() {
		this.setState({
			promoteVisible: false,
		});
	}
	downLoadPost = () => {
		window.pageYOffset = 0;
		document.documentElement.scrollTop = 0
		document.body.scrollTop = 0
		html2canvas(document.querySelector('.goodsPosterWrap'), {
			useCORS: true, // 【重要】开启跨域配置
			taintTest: false,//是否在渲染前测试图片,
			dpi: 192,
			scale: 8,
		}).then(function (canvas) {
			var context = canvas.getContext('2d');
			context.mozImageSmoothingEnabled = false;
			context.webkitImageSmoothingEnabled = false;
			context.msImageSmoothingEnabled = false;
			context.imageSmoothingEnabled = false;
			var imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // 获取生成的图片的url
			var a = document.createElement("a");
			a.href = imgUri;
			a.download = 'qrcode.png';
			a.click();
		})
	}
	onRef = ref => {
		goodsGroupAddRef = ref
	}
	render() {
		let { menu_code } = this.props;
		const { getFieldDecorator } = this.props.form;
		const { selectedRowKeys } = this.state;
		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange,
			hideDefaultSelections: true,
			onSelection: this.onSelection
		};
		const rowLeaderSelection = {
			selectedRowKeys: this.state.selectedLeaderRowKeys,
			onChange: this.onSelectLeaderChange,
			hideDefaultSelections: true,
			onSelection: this.onSelection,
			getCheckboxProps: record => ({
				disabled: record.open_group === 1 ? true : false
			})
		};
		return (
			<div className="goods_manage">
				<Spin spinning={this.state.loading} tip="加载中...">
					<NavBar icon="icon-shangpinguanli" child={menu_code.goods_publish} />
					<div className="content">
						<div className="goods-publish">
							<div className="action">
								<Button shape="round" type="primary" onClick={this.goToPublishNew}>
									新增商品
                				</Button>
							</div>
							<div className="filter-content">
								<Form className="filter-form ant-vertical" layout="inline">
									<Form.Item label="商品ID：">
										{getFieldDecorator("goods_id")(<Input className="form-input" placeholder="输入商品ID" />)}
									</Form.Item>
									<Form.Item label="商品名称：">
										{getFieldDecorator("goods_name")(<Input className="form-input-long" placeholder="输入商品名称" />)}
									</Form.Item>
									<Form.Item label="商品价格：">
										{getFieldDecorator("goods_start_price")(
											<div>
												<Input className="form-input" value={this.state.smallPrice} suffix="元" onChange={this.onChangeSmallPrice} />
												<span className="goods-price-center">至</span>
												<Input className="form-input" suffix="元" value={this.state.bigPrice} onChange={this.onChangeBigPrice} />
											</div>
										)}
									</Form.Item>
									<Form.Item label="商品分类">
										{getFieldDecorator("cat_id", {
											initialValue: this.props.state ? this.props.state : null
										})(
											<TreeSelect
												className="form-input"
												showSearch
												dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
												allowClear
												treeDefaultExpandAll>
												{this.state.cat_list.map(item => (
													<TreeSelect.TreeNode
														disabled={item.cat_list.length > 0}
														value={item.cat_id}
														title={item.cat_name}
														key={item.cat_id}>
														{item.cat_list.map(cat => (
															<TreeSelect.TreeNode value={cat.cat_id} title={cat.cat_name} key={cat.cat_id} />
														))}
													</TreeSelect.TreeNode>
												))}
											</TreeSelect>
										)}
									</Form.Item>
								</Form>
								<div className="filter-action">
									<Button shape="round" type="primary" onClick={this.onShowPageChange.bind(this, 1)}>
										筛选
                  					</Button>
									<span className="text-note" onClick={this.clearFilter}>
										清空筛选条件
                  					</span>
								</div>
							</div>
							<div className="action">
								<Tabs defaultActiveKey="1" onChange={this.changeTabs}>
									<Tabs.TabPane tab="全部" key="0"></Tabs.TabPane>
									<Tabs.TabPane tab="已上架" key="1"></Tabs.TabPane>
									<Tabs.TabPane tab="未上架" key="2"></Tabs.TabPane>
								</Tabs>
							</div>
							<div className="action">
								<Checkbox indeterminate={this.state.indeterminate} onChange={this.onCheckAllChange} checked={this.state.checkAll}>
									全选
                				</Checkbox>
								{this.state.onsaleStatus === 1 ? (
									<Button onClick={this.changeOnsale} disabled={this.state.selectedRows.length ? false : true} shape="round" type="primary">
										下架已选商品
									</Button>
								) : this.state.onsaleStatus === 2 ? (
									<Button onClick={this.openGroup.bind(this, 'noGroup')} disabled={this.state.selectedRows.length ? false : true} shape="round" type="primary">
										上架已选商品
									</Button>
								) : null}
								{this.state.onsaleStatus ? <span className="choose-num">当前已选择{this.state.selectedRows.length}项</span> : null}
							</div>
							<Table
								rowKey={record => record.goods_id}
								rowSelection={rowSelection}
								columns={this.state.columns}
								dataSource={this.state.goodsList}
								pagination={false}
								bordered={true}
								onChange={this.handleTableChange}
								onRow={(record, index) => {
									return {
										onMouseEnter: this.rowHover.bind(this, index, true),
										onMouseLeave: this.rowHover.bind(this, index, false)
									};
								}}
							/>
							<Pagination
								className="pagenation"
								onChange={this.onShowPageChange}
								onShowSizeChange={this.onShowSizeChange}
								defaultCurrent={this.state.page_no}
								total={this.state.total}
								showTotal={total => `共 ${total} 条`}
								pageSizeOptions={["10", "30", "50", "100"]}
								showSizeChanger
							/>
							<Modal
								title="更新团"
								visible={this.state.visible}
								onOk={this.saveLeader}
								onCancel={this.handleCancel}
								width={736}
								className="leader-modal"
								destroyOnClose={true}
								okButtonProps={{ shape: "round" }}
								cancelButtonProps={{ shape: "round" }}>
								<div>
									<Form>
										<Form.Item label="团类型：">
											{getFieldDecorator("shipment_type", { initialValue: this.state.groupRecord.checkList })(
												<Checkbox.Group style={{ width: "100%" }} onChange={this.onChangeShipmentType}>
													<Checkbox value="2">邮寄</Checkbox>
													<Checkbox value="1">自提</Checkbox>
												</Checkbox.Group>
											)}
										</Form.Item>
										<Form.Item label="运费信息：">
											{getFieldDecorator("shipment_pay", {
												initialValue: this.state.groupRecord.self_shipment_fee / 100 ? this.state.groupRecord.self_shipment_fee / 100 : 0
											})(
												<div>
													邮寄运费
                          							<Input
														onChange={this.changeSelfShipmentFee}
														placeholder="输入邮寄运费"
														value={this.state.self_shipment_fee}
														disabled={this.state.can_self_shipment ? false : true}
														style={{ marginRight: 50, width: 200, marginLeft: 10 }}
													/>
												</div>
											)}
										</Form.Item>
										<Form.Item label="推送至团长">
											<span onClick={this.handleLeader} className="text-note">
												选择团长
                      						</span>
										</Form.Item>
										<Form.Item label="开团时间">
											{getFieldDecorator("date")(
												<DatePicker.RangePicker className="form-input-long" onChange={this.onShowTimeChange} showTime />
											)}
										</Form.Item>
									</Form>
								</div>
							</Modal>
							<Modal
								title="选择团长"
								visible={this.state.leaderVisble}
								onOk={this.handleLeaderOk}
								onCancel={this.handleLeaderCancel}
								width={736}
								className="leader-modal"
								okButtonProps={{ shape: "round" }}
								cancelButtonProps={{ shape: "round" }}>
								<div>
									<Form layout="inline" className="modal-form">
										<Form.Item label="搜索团长">
											<Input placeholder="请输入手机号" value={this.state.mobile} onChange={this.changeMobile} />
										</Form.Item>
									</Form>
									<Table
										rowKey={(record, index) => record.community_id}
										rowSelection={rowLeaderSelection}
										columns={this.state.chooseLeaderColumns}
										dataSource={this.state.chooseLeaderData}
										pagination={false}
										scroll={{ y: 240 }}
									/>
								</div>
							</Modal>
							<GoodsGroupAdd
								onRef={this.onRef}
								visible={this.state.openGroupVisible}
								goods_list={this.state.selectedRows}
								handleFalse={this.handleClose}></GoodsGroupAdd>
							<GoodsShow visible={this.state.goods_show_visible} goods={this.state.goods} onOK={this.showGoodsVisibleCancel} />
							<Modal
								className="coupon-post"
								visible={this.state.promoteVisible}
								footer={null}
								onCancel={this.postCancel.bind(this)}
								destroyOnClose={true}
								okButtonProps={{ shape: 'round' }}
								cancelButtonProps={{ shape: 'round' }}
							>
								<Poster
									goods_id={this.state.posterInfo.goods_id}
									left={
										<GoodsPoster
											posterImg={this.state.posterImg}
											title={this.state.posterInfo.goods_name}
											price={this.state.posterInfo.current_price}
											originalPrice={this.state.posterInfo.original_price}
											url={this.state.url}
										/>
									}
									right={
										<GoodsButton
											group_id={this.state.posterInfo.default_group_id}
											url={this.state.url}
											downLoadPost={this.downLoadPost.bind(this)}
										/>
									}
								/>
							</Modal>
							<Modal
								width={600}
								title="定时改价"
								okText="保存"
								cancelText="取消"
								visible={this.state.changePriceModal}
								onCancel={() => this.setState({ changePriceModal: false })}
							>
								<Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
									<Form.Item label="商品名称">
										<span>aaaaaaaa</span>
									</Form.Item>
									<Form.Item label="改价前" labelCol={{ span: 4 }} wrapperCol={{ span: 8 }}>
										<Input prefix="¥" value={'aaaaaa'} disabled />
									</Form.Item>
									<Form.Item label="改价后" labelCol={{ span: 4 }} wrapperCol={{ span: 8 }}>
										<Input prefix="¥" />
									</Form.Item>
									<Form.Item label="生效时间">
										<DatePicker.RangePicker
											showTime={{
												hideDisabledOptions: true,
												defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
											}}
											format="YYYY-MM-DD HH:mm:ss"
										></DatePicker.RangePicker>
									</Form.Item>
								</Form>
							</Modal>
						</div>
					</div>
				</Spin>
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		menu_code: state.menuCode
	};
};
const GoodsManageForm = Form.create({ name: "goods_manage_form" })(GoodsManage);
export default connect(mapStateToProps)(GoodsManageForm);
