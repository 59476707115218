import React from 'react'
import '@css/bonusSettleContent.scss'
import {connect} from "react-redux";
import {Table, Pagination,Form,DatePicker,Input,Icon,Button} from 'antd'
import {queryShopBonusTransactionList} from '@service/getData'
import cookie from 'react-cookies'
import PropTypes from "prop-types";
class BonusStreamontent extends React.Component {
    constructor(props) {
        BonusStreamontent.contextTypes = {
            router: PropTypes.object.isRequired
        };
        super(props)
        this.state = {
            columns: [{

                title: '交易金额',
                dataIndex: 'quantity',
                render:(text)=>{
                   return  text/100
                }
            }, {

                title: '账户余额',
                dataIndex: 'current_balance',
                render:(text)=>{
                    return  text/100
                }

            }, {

                title: '交易时间',
                dataIndex: 'created_at',
            }
            ],
            finish_columns: [{

                title: '交易金额',
                dataIndex: 'quantity',
                render:(text)=>{
                    return  text/100
                }
            }, {

                title: '团长/团员',
                dataIndex: 'nick_name'
            },{

                title: '交易时间',
                dataIndex: 'created_at',
            }
            ],
            order_list: null,
            publish_start_date: null,
            publish_end_date: null,
            give_start_date: null,
            give_end_date: null,
            selectedRowKeys: [],
            selectedRows: [],
            page: 1,
            page_size: 10,
            total: 0,
            status_list:[
                "待结算待商家审核",
                "待商家审核",
                "审核成功",
                "审核失败",
                "已取消"
            ],
            balance:null,
            start_order_time:null,
            end_order_time:null,
        }

    }
    onShowPageChange = (page) => {
        this.setState({
            page: page
        }, () => {
            this.queryShopBonusTransactionList()
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.queryShopBonusTransactionList()
        })
    }
     queryShopBonusTransactionList= async()=> {
        if(!cookie.load("shop_id")&&cookie.load("is_admin")===1){
            return
        }
        let form = this.props.form.getFieldsValue();
        let data={
            shop_id:cookie.load("shop_id"),
            status:this.state.location==="rechargeHistory"?1:2,
            page_no:this.state.page,
            page_size:this.state.page_size,
            nick_name:!form.nick_name?null:form.nick_name,
            start_time:this.state.start_order_time,
            end_time:this.state.end_order_time
        }
        let res=await queryShopBonusTransactionList(data);
        if(res.success){
            this.setState({
                order_list:res.result.shop_bonus_transaction_res_list,
                total:res.result.total
            })
        }
        window.scrollTo(0,0);
    }
    onShowTimeChange = (date, dateStr) => {
        this.setState({
            start_order_time: dateStr[0],
            end_order_time: dateStr[1],
        })
    }
    clearFilter = () => {
        this.props.form.resetFields();
    }
    componentDidMount() {
        let location = this.context.router.route.location.pathname.replace(/[/||\\]/g, '');
        this.props.form.resetFields();
        this.setState({
            location
        }, () => {
            this.queryShopBonusTransactionList()
        })
    }


    render() {
        const {getFieldDecorator} = this.props.form;
        return (
            <div className="bonus-settle-content">
                <Form layout="inline">
                    {
                        this.state.location!=="rechargeHistory"?(
                            <Form.Item label="团长/团员姓名" >
                                {getFieldDecorator('nick_name')(
                                    <Input className="goods-name" placeholder="输入团长/团员姓名"/>
                                )}
                            </Form.Item>
                        ):null
                    }
                    <Form.Item label="交易时间">
                        {getFieldDecorator('date')(
                            <DatePicker.RangePicker
                                onChange={this.onShowTimeChange}
                                showTime
                                prefix={<Icon type="contacts"  style={{ color: 'rgba(0,0,0,.25)' }} />}
                            />
                        )}
                    </Form.Item>

                </Form>
                <div className="action">
                    <div className="action">
                        <Button shape="round" type="primary" onClick={this.queryShopBonusTransactionList}>查询</Button>
                        <span className="text-note" onClick={this.clearFilter}>清空筛选条件</span>
                    </div>
                </div>
                <Table
                    className="table"
                    rowKey={(record, index) => index}
                    columns={this.state.location==="rechargeHistory"?this.state.columns:this.state.finish_columns}
                    dataSource={this.state.order_list}
                    pagination={false}
                    bordered={true}
                />
                <Pagination
                    className="pagenation"
                    onChange={this.onShowPageChange}
                    onShowSizeChange={this.onShowSizeChange}
                    defaultCurrent={this.state.page}
                    total={this.state.total}
                    showTotal={(total) => `共 ${total} 条`}
                    pageSizeOptions={["10", "30", "50", "100"]}
                    showSizeChanger
                />
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode,
        order_status_list: state.orderStatusList
    }
}


const BonusStreamContentForm = Form.create({name: 'bonus_stream_content_form'})(BonusStreamontent);
export default connect(mapStateToProps)(BonusStreamContentForm);
