import defaultState from './state.js'
import { combineReducers } from 'redux'

function menuCode (state = defaultState.menu_code, action) {
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'set_menu_code':
        default:
            return state
    }
}

function adminUserId (state = defaultState.admin_user_id, action) {
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'set_admin_user_id':
            return action.data

        default:
            return state
    }
}

function sideBar (state = defaultState.side_bar, action) {
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'set_side_bar':
            return action.data

        default:
            return state
    }
}

function orderStatusList (state = defaultState.order_status_list, action) {
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'set_order_status_list':
            return action.data

        default:
            return state
    }
}


function refundStatusList (state = defaultState.refund_status_list, action) {
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        case 'set_refund_status_list':
            return action.data

        default:
            return state
    }
}
function appVersion (state = defaultState.app_version, action) {
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        default:
            return state
    }
}
function appFunction (state = defaultState.app_function, action) {
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        default:
            return state
    }
}
function activitySubtype (state = defaultState.activity_sub_type, action) {
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        default:
            return state
    }
}
function cjOrderStatusList (state = defaultState.cj_order_status_list, action) {
    // 不同的action有不同的处理逻辑
    switch (action.type) {
        default:
            return state
    }
}


export default combineReducers({
    menuCode,
    adminUserId,
    sideBar,
    orderStatusList,
    refundStatusList,
    appVersion,
    appFunction,
    activitySubtype,
    cjOrderStatusList
})