import React, { Component, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import cookie from 'react-cookies'
import { getData, getPostQrCode, queryMiniAppDetail, getBase64ImageUrl } from '@service/getData';
import './scss/fissionCoupon.scss';
import NavBar from "@/view/component/common/NavBar.js";
import { formatDate } from '@common/common'
import { Popover, Button, Form, Input, Select, Table, Divider, Modal, message, Pagination } from 'antd';
import Poster from '@/view/component/Poster/Poster';
import html2canvas from 'html2canvas';
import CouponPoster from './children/CouponPoster';
import CouponButton from './children/CouponButton';
import copy from "copy-to-clipboard/index";

// 数组格式化，符合最初预设的dataSource格式
const formatList = (list) => {
    let tempList = JSON.parse(JSON.stringify(list));
    let count = 0;
    list.forEach((item, index) => {
        let { booster_coupon } = item;
        booster_coupon.share_coupon_id = item.coupon_id;
        tempList.splice(index + 1 + count, 0, booster_coupon);
        count++;
    });
    return tempList;
}

class FissionCoupon extends Component {
    constructor(props) {
        super(props);
        FissionCoupon.contextTypes = {
            router: PropTypes.object.isRequired,
        };
        this.state = {
            // 表格数据展示格式
            colums: [
                {
                    title: 'ID',
                    dataIndex: 'coupon_id',
                    render: (text, row, index) => {
                        let obj = {
                            children: text,
                            props: {},
                        }
                        if (index % 2 === 0) {
                            obj.props.rowSpan = 2;
                        } else {
                            obj.props.rowSpan = 0;
                        }
                        return obj;
                    }
                },
                {
                    title: '优惠券名称',
                    dataIndex: 'coupon_name',
                    render: (text, row, index) => {
                        let obj = {
                            children: text,
                            props: {},
                        }
                        if (index % 2 === 0) {
                            obj.props.rowSpan = 2;
                        } else {
                            obj.props.rowSpan = 0;
                        }
                        return obj;
                    }
                },
                {
                    title: '角色',
                    dataIndex: 'character',
                    render: (text, row, index) => {
                        return (
                            <span>{row.booster_coupon ? '分享者券' : '被分享者券'}</span>
                        )
                    }
                },
                {
                    title: '优惠内容',
                    dataIndex: 'discount_amount',
                    render: (text, row, index) => {
                        text = text * 1;
                        return (
                            <span>满{row.use_min_amount / 100}减{text / 100}</span>
                        )
                    }
                },
                {
                    title: '使用时间',
                    dataIndex: 'effect_day',
                    render: (text, row, index) => {
                        return (
                            <span>{formatDate(new Date(row.start_time).getTime(), 'Y-M-D')} ~ {formatDate(new Date(row.end_time).getTime(), 'Y-M-D')}</span>
                        )
                    }
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    render: (text, row, index) => {
                        text = text * 1;
                        if (text === 1) {
                            return (
                                <span style={{ color: 'green' }}>进行中</span>
                            )
                        }
                        if (text === 3) {
                            return (
                                <span style={{ color: 'red' }}>已停止</span>
                            )
                        }
                        return (
                            <span style={{ color: 'gray' }}>已结束</span>
                        )
                    }
                },
                {
                    title: '已领取/剩余',
                    dataIndex: 'remain_num',
                    render: (text, row, index) => {
                        return (
                            <span>{row.total_num - text} / {text}</span>
                        )
                    }
                },
                {
                    title: '已使用',
                    dataIndex: 'use_num',
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    render: (text, row, index) => {
                        let child = row.status === 1 ?
                            (
                                <Fragment>
                                    {row.booster_coupon
                                        ?
                                        <Fragment>
                                            <span className="text-note" onClick={this.goSetting.bind(this, row.coupon_id)}>编辑</span>
                                            <Divider type="vertical" />
                                            <span className="text-note" onClick={this.showConfirm.bind(this, row.coupon_id, row.booster_coupon_id)}>停止发放</span>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <span className="text-note" onClick={this.goSetting.bind(this, row.share_coupon_id)}>编辑</span>
                                            <Divider type="vertical" />
                                            <span className="text-note" onClick={this.showConfirm.bind(this, row.share_coupon_id, row.coupon_id)}>停止发放</span>
                                        </Fragment>
                                    }
                                    <Divider type="vertical" />
                                    <span className="text-note" onClick={this.showPost.bind(this, row)}>投放</span>
                                </Fragment>
                            )
                            :
                            (
                                <Fragment>
                                    {row.booster_coupon
                                        ?
                                        <span className="text-note" onClick={this.showDeleteConfirm.bind(this, row.coupon_id, row.booster_coupon_id)}>删除</span>
                                        :
                                        <span className="text-note" onClick={this.showDeleteConfirm.bind(this, row.share_coupon_id, row.coupon_id)}>删除</span>
                                    }
                                </Fragment>
                            )
                        let obj = {
                            children: child,
                            props: {},
                        }
                        if (index % 2 === 0) {
                            obj.props.rowSpan = 2;
                        } else {
                            obj.props.rowSpan = 0;
                        }
                        return obj;
                    }
                },
            ],
            // 表格数据源
            data: [],
            // 推广弹窗图片 
            url: '',
            qr_url: '',
            // 券信息
            coupon: {},
            // 弹窗控制
            visible: false,
            mini_app: {},
            // 分页
            page: 1,
            page_size: 10,
            total: 0,
        };
    }
    componentDidMount() {
        this.search();
        this.queryMiniAppDetail();
    }
    goSetting(couponId) {
        this.context.router.history.push(`/fissionCouponSetting${couponId ? `?coupon_id=${couponId}` : ''}`);
    }
    async search() {
        const form = this.props.form.getFieldsValue();
        let data = {
            mini_app_id: cookie.load('mini_app_id'),
            status: form.status,
            like_condition: form.like_condition,
            page_no: this.state.page,
            page_size: this.state.page_size,
        };
        let url = '/fission_coupon/queryFissionCouponList';
        let res = await getData(url, data);
        if (res.success && res.result) {
            let list = formatList(res.result.coupon_res_list);
            this.setState({
                data: list,
                total: res.result.total,
            });
        } else {
            this.setState({
                data: [],
                total: 0,
            });
        }
    }
    showConfirm = (share_coupon_id, booster_coupon_id) => {
        Modal.confirm({
            title: '停止后，无法继续领券，已领取的券在可用时间内可继续使用，不可编辑券内容，确定停止发券?',
            onOk: () => {
                this.createOrUpdateCoupon(share_coupon_id, booster_coupon_id, 3)
            },
        });
    }
    showDeleteConfirm = (share_coupon_id, booster_coupon_id) => {
        Modal.confirm({
            title: '删除后，买家已领取、使用的券信息及创建记录均会删除，不可继续使用，确定删除此券?',
            onOk: () => {
                this.createOrUpdateCoupon(share_coupon_id, booster_coupon_id, 0)
            },
        });
    }
    createOrUpdateCoupon = async (share_coupon_id, booster_coupon_id, status) => {
        let data = {
            sharerCoupon: {
                coupon_id: share_coupon_id,
                status
            },
            boosterCoupon: {
                coupon_id: booster_coupon_id,
                status,
            },
        }
        const url = '/fission_coupon/createOrUpdateFissionCoupon';
        let res = await getData(url, data);
        if (res.success) {
            if (status === 3) {
                message.success("已停止该优惠券")
            } else {
                message.success("已删除该优惠券")
            }
            this.search()
        }
    }
    showPost = (coupon) => {
        this.getPostQrCode(coupon.coupon_id, 1)
        this.getPostQrCode(coupon.coupon_id, null)
        this.setState({
            coupon,
        })
    }
    async getPostQrCode(coupon_id, receive) {
        let res = await getPostQrCode(cookie.load("shop_id"), coupon_id, receive);
        if (receive) {
            this.setState({
                url: res,
                visible: true
            })
        } else {
            this.setState({
                qr_url: res,
                visible: true
            })
        }

    }
    downLoadPost = () => {
        window.pageYOffset = 0;
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        html2canvas(document.getElementById('post'), {
            useCORS: true, // 【重要】开启跨域配置
            taintTest: false,//是否在渲染前测试图片,
            dpi: 192,
            scale: 8,
        }).then(function (canvas) {
            var context = canvas.getContext('2d');
            context.mozImageSmoothingEnabled = false;
            context.webkitImageSmoothingEnabled = false;
            context.msImageSmoothingEnabled = false;
            context.imageSmoothingEnabled = false;
            var imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // 获取生成的图片的url
            var a = document.createElement("a");
            a.href = imgUri;
            a.download = 'qrcode.png';
            a.click();
        })

    }
    copy = (value) => {
        copy(value)
        message.success('复制成功')
    }
    queryMiniAppDetail = async () => {
        let mini_app_id = cookie.load("mini_app_id");

        let res = await queryMiniAppDetail({ mini_app_id });
        if (res.success) {
            this.setState({
                mini_app: res.result.mini_app
            }, () => {
                this.getBase64ImageUrl()
            })
        }
    }
    async getBase64ImageUrl() {
        let res = await getBase64ImageUrl(this.state.mini_app.logo);
        this.setState({
            logo_url: res
        })
    }
    postCancel = () => {
        this.setState({
            visible: false
        })
    }
    onShowPageChange = (page) => {
        this.setState({
            page,
        }, () => {
            this.search();
        });
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.search();
        })
    }
    clear() {
        this.props.form.resetFields();
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        let { menu_code } = this.props;
        return (
            <div className="fissionCoupon">
                <NavBar
                    icon="icon-yingxiao"
                    father={menu_code.market_setting}
                    router='marketSetting'
                    child={menu_code.fission_coupon}
                />
                <div className="content">
                    <div className="introduction">
                        <div className="iconWrap">
                            <i className="iconfont icon-youhuijuan1"></i>
                        </div>
                        <div className="incRight">
                            <p className="rightTop">
                                <span className="shareCoupon">分享优惠券</span>
                                <Popover content={
                                    <img src="http://udh.oss-cn-hangzhou.aliyuncs.com/3c1719cb-a742-44a8-80b7-387cf958d437" width="200" height="356" />
                                }>
                                    <span className="shili">示例图片</span>
                                </Popover>
                            </p>
                            <p className="rightBot">规则：分享者完成分享任务获得优惠券奖励，被分享的好友也可以免费获得优惠券</p>
                        </div>
                    </div>
                    <Button className="newBtn" shape="round" onClick={this.goSetting.bind(this, null)} type="primary">新建优惠券</Button>
                    <div className="formWrap">
                        <Form layout="inline">
                            <Form.Item label="优惠券名称/ID">
                                {getFieldDecorator('like_condition')(
                                    <Input />
                                )}
                            </Form.Item>
                            <Form.Item label="状态">
                                {getFieldDecorator('status')(
                                    <Select className="selectStatus">
                                        <Select.Option value="1">进行中</Select.Option>
                                        <Select.Option value="3">已停止</Select.Option>
                                        <Select.Option value="2">已结束</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Form>
                        <div className="btnWrap">
                            <Button shape="round" type="primary" onClick={this.search.bind(this)}>筛选</Button>
                            <span className="clearSearch" onClick={this.clear.bind(this)}>清空筛选条件</span>
                        </div>
                    </div>
                    <Table
                        rowKey={(record, index) => index}
                        columns={this.state.colums}
                        dataSource={this.state.data}
                        bordered
                        pagination={false}
                    />
                    <Pagination
                        className="pagenation"
                        onChange={this.onShowPageChange}
                        onShowSizeChange={this.onShowSizeChange}
                        defaultCurrent={this.state.page}
                        total={this.state.total}
                        showTotal={(total) => `共 ${total} 条`}
                        pageSizeOptions={["10", "30", "50", "100"]}
                        showSizeChanger
                    />
                </div>
                <Modal
                    className="coupon-post"
                    visible={this.state.visible}
                    footer={null}
                    onCancel={this.postCancel}
                    destroyOnClose={true}
                    okButtonProps={{ shape: 'round' }}
                    cancelButtonProps={{ shape: 'round' }}
                >
                    <Poster
                        left={
                            <CouponPoster
                                logo_url={this.state.logo_url}
                                mini_app={this.state.mini_app}
                                coupon={this.state.coupon}
                                url={this.state.url}
                            />
                        }
                        right={
                            <CouponButton
                                coupon={this.state.coupon}
                                qr_url={this.state.qr_url}
                                url={this.state.url}
                                downLoadPost={this.downLoadPost.bind(this)}
                                copy={this.copy.bind(this)}
                            />
                        }
                    >
                        {this.props.location.coupon_id ? (<i className="iconfont icon-xuanzhong1"></i>) : null}
                        <div id="title" className="coupon-post-title">
                            {this.props.location.coupon_id ? (<div>新建成功!</div>) : null}
                            快去把优惠券发放给粉丝吧！
                        </div>
                    </Poster>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        menu_code: state.menuCode,
    };
};
const FissionCouponForm = Form.create({ name: "shop_manage_form" })(FissionCoupon);

export default connect(mapStateToProps)(FissionCouponForm);