import React from 'react'
import NavBar from '@/view/component/common/NavBar.js'
import { connect } from "react-redux";
import '@css/popUppromotion.scss'
import { Tooltip, Tabs, Table, Divider, message, Spin } from 'antd'
import PropTypes from 'prop-types'
import { queryActivityDetail, createOrUpdateActivity } from '@service/getData'
import { getQueryString, formatDate } from "@common/common";


class PopUpPromotion extends React.Component {
    constructor() {
        PopUpPromotion.contextTypes = {
            router: PropTypes.object.isRequired
        }
        super()
        this.state = {
            columns: [
                {
                    title: "弹窗名称",
                    dataIndex: "activity_name"
                }, {
                    title: "有效时间",
                    dataIndex: "date",
                    render: (text, record) => {
                        return `${formatDate(new Date(record.start_time).getTime(), "YYYY-MM-DD HH:mm:ss")} - ${formatDate(new Date(record.end_time).getTime(), "YYYY-MM-DD HH:mm:ss")}`
                    }
                }, {
                    title: "弹窗状态",
                    dataIndex: "popup_status",
                    render: (text) => {
                        switch (text) {
                            case 0:
                                return '已结束'
                            case 1:
                                return <span className="text-green">进行中</span>
                            case 2:
                                return '未生效'
                            case 3:
                                return <span className="text-danger">已失效</span>
                            default: break;
                        }
                    }
                }, {
                    title: "推广人群",
                    dataIndex: "user_type",
                    render: (text) => {
                        switch (text) {
                            case 'all_user':
                                return '全部人群'
                            case 'new_user':
                                return '新用户'
                            case 'old_user':
                                return '老用户'
                            default: break;
                        }
                    }
                }, {
                    title: "浏览量(弹出次数)",
                    dataIndex: "brower_num"
                }, {
                    title: "操作",
                    dataIndex: "activity_id",
                    render: (text, record) => {
                        return record.popup_status === 1 || record.popup_status === 2 ? (
                            (
                                <div>
                                    <span className="text-note" onClick={this.createOrUpdatePopUp.bind(this, text, record.target_type)}>编辑</span>
                                    <Divider type="vertical" />
                                    <span className="text-note" onClick={this.createOrUpdateActivity.bind(this, text)}>失效</span>
                                </div>
                            )
                        ) : (<span className="text-note" onClick={this.createOrUpdatePopUp.bind(this, text, record.target_type)}>查看</span>)
                    }
                },
            ],
            ad_columns: [
                {
                    title: "弹窗名称",
                    dataIndex: "activity_name"
                }, {
                    title: "有效时间",
                    dataIndex: "date",
                    render: (text, record) => {
                        return `${formatDate(new Date(record.start_time).getTime(), "YYYY-MM-DD HH:mm:ss")} - ${formatDate(new Date(record.end_time).getTime(), "YYYY-MM-DD HH:mm:ss")}`
                    }
                }, {
                    title: "弹窗状态",
                    dataIndex: "popup_status",
                    render: (text) => {
                        switch (text) {
                            case 0:
                                return '已结束'
                            case 1:
                                return <span className="text-green">进行中</span>
                            case 2:
                                return '未生效'
                            case 3:
                                return <span className="text-danger">已失效</span>
                            default: break;
                        }
                    }
                }, {
                    title: "推广人群",
                    dataIndex: "user_type",
                    render: (text) => {
                        switch (text) {
                            case 'all_user':
                                return '全部人群'
                            case 'new_user':
                                return '新用户'
                            case 'old_user':
                                return '老用户'
                            default: break;
                        }
                    }
                }, {
                    title: "浏览量(弹出次数)",
                    dataIndex: "brower_num"
                }, {
                    title: "操作",
                    dataIndex: "activity_id",
                    render: (text, record) => {
                        return record.popup_status === 1 || record.popup_status === 2 ? (
                            (
                                <div>
                                    <span className="text-note" onClick={this.createOrUpdatePopUp.bind(this, text, record.target_type)}>编辑</span>
                                    <Divider type="vertical" />
                                    <span className="text-note" onClick={this.createOrUpdateActivity.bind(this, text)}>失效</span>
                                </div>
                            )
                        ) : (<span className="text-note" onClick={this.createOrUpdatePopUp.bind(this, text, record.target_type)}>查看</span>)
                    }
                },
            ],

            activity_id: null,
            data: [],
            type: 'couponList',
            loading: false
        }
    }
    createOrUpdatePopUp = (activity_id, type) => {
        type = type !== 'couponList' ? "adPopup" : "couponList"
        let data = [activity_id, this.state.activity_id, type]
        this.context.router.history.push({ pathname: `createOrUpdatePopUp`, state: data.join(',') })
    }
    createOrUpdateActivity = async (activity_id) => {
        let data = {
            activity_req: {
                activity_id,
                status: 0
            }
        }
        let res = await createOrUpdateActivity(data)
        if (res.success) {
            message.success('成功失效弹窗')
            this.queryActivityDetail()
        } else {
            message.error(res.msg)
        }

    }
    queryActivityDetail = async () => {
        let data = {
            activity_id: this.state.activity_id,
            target_type: this.state.type
        }
        let res = await queryActivityDetail(data)
        if (res.success) {
            this.setState({
                data: res.result.activity_res.activity_res_list,
                loading: false
            })

        }
    }
    changeType = (type) => {
        this.setState({
            type: type,
            loading: true
        }, () => {
            this.queryActivityDetail()
        }
        )
    }
    componentDidMount() {
        let activity_id = getQueryString("activity_id")
        let type = this.props.location.state ? this.props.location.state : 'couponList'
        this.setState({
            activity_id,
            type,
            loading: true
        }, () => {
            if (activity_id) {
                this.queryActivityDetail();
            }
        })
    }
    render() {
        let { menu_code } = this.props
        return (
            <div className="pop-up-promotion">
                <NavBar icon="icon-yingxiao"
                    father={menu_code.market_setting}
                    child={menu_code.pop_up_promotion}
                    router="marketSetting"
                />
                <Spin spinning={this.state.loading}>
                    <div className="content">
                        <div className="filter-content">
                            <div className="activity-coupon">
                                <i className="iconfont icon-tuiguang" />
                            </div>
                            <div className="text-content">
                                <div className="title">弹窗推广
                                <Tooltip overlayClassName="market-tooltip" placement="bottom" title="">
                                        <span className="text-note pic-show">
                                            示例图片
                                        </span>
                                    </Tooltip>
                                </div>
                                <div>
                                    1、推送时机：仅当日首次进店进行弹窗推送
                                    2、推送数量：优先推送“进店发券弹窗”，每人每天只会收到一个弹窗
                            </div>
                            </div>
                        </div>
                        <div className="action">
                            <div onClick={this.createOrUpdatePopUp.bind(this, 0, 'couponList')} className="create-pop-up-promotion"></div>
                            <div onClick={this.createOrUpdatePopUp.bind(this, 0, 'adPopup')} className="create-pop-up-ad-promotion"></div>
                        </div>
                        <Tabs activeKey={this.state.type} onChange={this.changeType}>
                            <Tabs.TabPane tab="进店发券" key="couponList">
                                <Table
                                    columns={this.state.columns}
                                    rowKey={record => record.activity_id}
                                    dataSource={this.state.data}
                                    pagination={{
                                        showTotal: (total) => `共 ${total} 条`,
                                        pageSizeOptions: ["10", "30", "50", "100"],
                                        showSizeChanger: true
                                    }
                                    }

                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="图片广告" key="adPopup">
                                <Table
                                    columns={this.state.columns}
                                    rowKey={record => record.activity_id}
                                    dataSource={this.state.data}
                                    pagination={{
                                        showTotal: (total) => `共 ${total} 条`,
                                        pageSizeOptions: ["10", "30", "50", "100"],
                                        showSizeChanger: true
                                    }
                                    }

                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </Spin>
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode
    }
}
export default connect(mapStateToProps)(PopUpPromotion);
