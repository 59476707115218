import React from "react";
import { Form, Radio, Icon, Button, message, Tooltip } from "antd";
import "@css/styleSetting.scss";
import ColorPicker from "@/view/component/common/ColorPicker";
import { queryMiniAppPageConfigList, createOrUpdateMiniAppPageConfigList } from "@service/getData";
import cookie from "react-cookies";
class StyleSetting extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      property: null,
      mini_app_page_config_list: [],
      color_list: ["#313131", "#C43434", "#FF5000", "#FF9900"]
    };
  }
  queryMiniAppPageConfigList = async () => {
    let data = {
      mini_app_id: cookie.load("mini_app_id"),
      config_type: "style"
    };
    let res = await queryMiniAppPageConfigList(data);
    if (res.success) {
      const names = ['radio-group', 'core_color', 'head_font_color', 'head_back_color'];
      res.result.mini_app_page_config_res_list.forEach(item => {
        if (names.includes(item.config_name)) {
          this.props.form.setFieldsValue({ [item.config_name]: item.page_config_value });
        }
        if (item.config_name === "core-color") {
          if (this.state.color_list.indexOf(item.page_config_value) > -1) this.props.form.setFieldsValue({ "radio-group": "a" });
        } else {
          this.props.form.setFieldsValue({ "radio-group": "b" });
        }
      });
      this.setState({
        mini_app_page_config_list: res.result.mini_app_page_config_res_list
      });
    }
  };
  createOrUpdateMiniAppPageConfigList = async () => {
    let list = this.state.mini_app_page_config_list.concat();
    let form = this.props.form.getFieldsValue();
    list.forEach(item => {
      item.page_config_value = form[item.config_name];
    });
    let data = {
      mini_app_page_config_req_list: list
    };
    let res = await createOrUpdateMiniAppPageConfigList(data);
    if (res.success) {
      // message.success("颜色设置成功");
      this.queryMiniAppPageConfigList();
      cookie.save("status", 0);
    }
  };
  showModal = property => {
    this.setState({
      visible: true,
      property
    });
  };
  handleOK = color => {
    if (this.state.property === "core_color" && (color.toLowerCase() === "#ffffff" || color.toLowerCase() === "#fff")) {
      return message.error("不能选择白色");
    }
    this.props.form.setFieldsValue({ [this.state.property]: color });
    cookie.save("status", 1);
    this.setState({
      visible: false
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false
    });
  };
  changeStatus = () => {
    cookie.save("status", 1);
  };
  componentDidMount() {
    this.props.onRef(this);
    this.queryMiniAppPageConfigList();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="style-setting">
        <div className="style-content">
          <div className="shopDataTitle">
            <i className="titleSign" />
            选择主色调颜色
            <Tooltip
              overlayClassName="photo-show"
              placement="bottom"
              title={<img width={256} alt="示例图片" src="http://udh.oss-cn-hangzhou.aliyuncs.com/f988478c-7ab9-45e6-83d7-3b4de8b5598f" />}>
              <span className="text-note">查看示例</span>
            </Tooltip>
          </div>
          <div className="title-content">
            <span className="text-remind">包括：字体、按钮、图标、勾选等主风格颜色</span>
            <Form>
              <Form.Item>
                {getFieldDecorator("radio-group", {
                  initialValue: this.state.color_list.findIndex(item => item === this.props.form.getFieldValue("core_color")) ? "a" : "b"
                })(
                  <Radio.Group>
                    <Radio value="a">系统配色</Radio>
                    <Radio value="b">自定义</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item label="系统配色" style={{ display: this.props.form.getFieldValue("radio-group") === "a" ? true : "none" }}>
                {getFieldDecorator("core_color", {})(
                  <Radio.Group size="large">
                    <Radio.Button onClick={this.changeStatus} style={{ background: "#313131" }} value="#313131" />
                    <Radio.Button onClick={this.changeStatus} style={{ background: "#C43434" }} value="#C43434" />
                    <Radio.Button onClick={this.changeStatus} style={{ background: "#FF5000" }} value="#FF5000" />
                    <Radio.Button onClick={this.changeStatus} style={{ background: "#FF9900" }} value="#FF9900" />
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item
                label="自定义"
                style={{ display: this.props.form.getFieldValue("radio-group") === "b" ? true : "none" }}
                extra={<div className="text-danger">注：不能选白色</div>}>
                {getFieldDecorator("core_color")(
                  <div className="color-choose" onClick={this.showModal.bind(this, "core_color")}>
                    <span className="color-desc">{this.props.form.getFieldValue("core_color")}</span>
                    <span className="color" style={{ background: this.props.form.getFieldValue("core_color") }} />
                    <Icon className="color-picker-icon" type="caret-down" />
                  </div>
                )}
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="style-content">
          <div className="shopDataTitle">
            <i className="titleSign" />
            选择头部颜色
            <Tooltip
              overlayClassName="photo-show"
              placement="bottom"
              title={<img width={256} alt="示例图片" src="http://udh.oss-cn-hangzhou.aliyuncs.com/7e0c57d2-ec07-4564-a614-37e303145f4e" />}>
              <span className="text-note">查看示例</span>
            </Tooltip>
          </div>
          <div className="title-content">
            <Form layout="inline">
              <Form.Item label="头部字体颜色">
                {getFieldDecorator("head_font_color", {})(
                  <Radio.Group size="large" onChange={this.changeStatus}>
                    <Radio.Button style={{ background: "#ffffff" }} value="#ffffff" />
                    <Radio.Button style={{ background: "#000000" }} value="#000000" />
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item label="头部背景颜色">
                {getFieldDecorator("head_back_color")(
                  <div className="color-choose" onClick={this.showModal.bind(this, "head_back_color")}>
                    <span className="color-desc">{this.props.form.getFieldValue("head_back_color")}</span>
                    <span className="color" style={{ background: this.props.form.getFieldValue("head_back_color") }} />
                    <Icon className="color-picker-icon" type="caret-down" />
                  </div>
                )}
              </Form.Item>
            </Form>
          </div>
        </div>
        <ColorPicker visible={this.state.visible} onOk={this.handleOK} onCancel={this.handleCancel} />
      </div>
    );
  }
}
const StyleSettingForm = Form.create({ name: "setle-setting-form" })(StyleSetting);

export default StyleSettingForm;
