import React from 'react'
import { Tabs, Tooltip } from 'antd'
import NavBar from '@/view/component/common/NavBar.js'
import CardList from './children/CardList'
import CardReceiveRecord from './children/CardReceiveRecord'
import CardRefundRecord from './children/CardRefundRecord'
import { connect } from 'react-redux'
let receiveRef
class CardManage extends React.Component {
	constructor() {
		super()
		this.state = {
			card_activity_res: {
				icon: 'iconfont icon-huiyuan',
				class: 'activity-checkin icon-content',
				activity_subtype: 'free_order',
				activity_subtype_desc:
					'满足一定条件的客户，可以领取权益卡，享受指定权益。当前支持的权益有：兑换商品。（兑换商品：支持多次分批兑换商品）',
				activity_subtype_name: '权益卡',
				url:
					'http://udh.oss-cn-hangzhou.aliyuncs.com/683e1c33-98f0-4515-af1f-7133ebea19ab',
			},
			activity_res: {},
			activity_id: null,
			type: '',
			default_active_key: '1',
			params: {},
		}
	}
	callback = (key, params = null) => {
		this.setState(
			{
				default_active_key: key,
				params,
			},
			() => {
				if (params) {
					receiveRef.getUserCardList()
				}
			}
		)
	}
	onRef(ref) {
		receiveRef = ref
	}
	render() {
		let { menu_code } = this.props
		let card_activity_res = this.state.card_activity_res
		return (
			<div className="market-tab">
				<NavBar icon="icon-kehu1" child={menu_code.card_manage} />
				<div className="order-content">
					<div className="icon-filter-content">
						<div className={card_activity_res.class}>
							<i className={card_activity_res.icon} />
						</div>
						<div className="text-content">
							<div className="title">
								{card_activity_res.activity_subtype_name}
								<Tooltip
									overlayClassName="market-tooltip"
									placement="bottom"
									title={
										<img
											alt="示例图片"
											width="256"
											src={card_activity_res.url}
										/>
									}>
									<span className="text-note pic-show">示例图片</span>
								</Tooltip>
							</div>
							<div>{card_activity_res.activity_subtype_desc}</div>
						</div>
					</div>
					<Tabs
						activeKey={this.state.default_active_key}
						tabBarStyle={{ backgroundColor: '#ffffff', borderRadius: '4px' }}
						onChange={this.callback}>
						<Tabs.TabPane tab="权益卡" key="1">
							<CardList changeTab={this.callback} />
						</Tabs.TabPane>
						<Tabs.TabPane tab="领卡记录" key="2">
							<CardReceiveRecord
								params={this.state.params}
								onRef={this.onRef}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane tab="退卡记录" key="3">
							<CardRefundRecord />
						</Tabs.TabPane>
					</Tabs>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		menu_code: state.menuCode,
	}
}
export default connect(mapStateToProps)(CardManage)
