import React, { Fragment } from 'react'
import cookie from "react-cookies";
import { Form, Input, Radio, Button, Icon, message } from 'antd';
import NavBar from '@/view/component/common/NavBar.js'
import '@css/integralManage.scss'
import PropTypes from "prop-types";
import GoodsChoose from '@/view/component/Goods/GoodsChoose'
import { createOrUpdatePointsRule, queryPointsRuleDetail } from '@service/getData'
import { formatNumber, formatInt, getQueryString } from '@common/common'

class Integral extends React.Component {
    constructor() {
        Integral.contextTypes = {
            router: PropTypes.object.isRequired
        };
        super()
        this.state = {
            visible: false,
            goods_list: [],
            buy_amount: null,
            reward_amount: null,
            goods_type: 'all_goods',
            points_rule_id: null

        }
    }
    createOrUpdatePointsRule = async () => {
        if (!this.state.buy_amount || !this.state.reward_amount) {
            return
        }
        if (this.state.goods_list.filter(item => item.status === 1).length > 200) {
            return message.error("最多添加200个商品")
        }
        let data = {
            points_rule_req: {
                mini_app_id: cookie.load("mini_app_id"),
                buy_amount: this.state.buy_amount * 100,
                reward_amount: this.state.reward_amount,
                goods_type: this.state.goods_type,
                points_rule_goods_req_list: this.state.goods_list,
                points_rule_id: this.state.points_rule_id
            }
        }
        let res = await createOrUpdatePointsRule(data)
        if (res.success) {
            message.success(this.state.points_rule_id ? "成功更新积分规则" : "成功创建积分规则")
            this.context.router.history.push('integralManage');
        }
    }
    queryPointsRuleDetail = async () => {
        let data = {
            points_rule_id: this.state.points_rule_id
        }
        let res = await queryPointsRuleDetail(data)
        if (res.success) {
            let { goods_type, buy_amount, reward_amount, points_rule_goods_res_list } = res.result.points_rule_res;
            let goods_list = points_rule_goods_res_list.map(item => {
                return {
                    ...item.goods_res,
                    ...item
                }
            })
            this.setState({
                goods_type,
                buy_amount: buy_amount / 100,
                reward_amount,
                goods_list
            })
        }

    }
    onOk = (goods_list) => {
        let current_goods_list = this.state.goods_list.concat()
        let add_goods_list = [];
        goods_list.forEach(item => {
            let goods = current_goods_list.find(goods => goods.goods_id === item.goods_id)
            if (goods) {
                goods.status = 1;

            } else {
                add_goods_list.push(item)
            }
        })
        this.setState({
            visible: false,
            goods_list: current_goods_list.concat(add_goods_list)
        })
    }
    onCancel = () => {
        this.setState({
            visible: false
        })
    }
    showModal = () => {
        this.setState({
            visible: true
        })
    }
    changeMoney = (type, e) => {
        let value = e.target.value
        if (type === 'buy_amount') {
            value = formatNumber(value)
        } else if (type === 'reward_amount') {
            value = formatInt(value)
        }
        this.setState({
            [type]: value
        })
    }
    delGoods = (goods_id) => {
        let goods_list = this.state.goods_list.concat();
        let goods = goods_list.find(item => item.goods_id === goods_id);
        if (goods.points_rule_goods_id) {
            goods.status = 0;
        } else {
            goods_list = goods_list.filter(item => item.goods_id !== goods_id)
        }
        this.setState({
            goods_list
        })
    }
    changeGoodsType = (e) => {
        this.props.form.setFieldsValue({ "goods_type": e.target.value })
        this.setState({
            goods_type: e.target.value
        })
    }
    componentDidMount() {
        let points_rule_id = getQueryString("points_rule_id")
        if (points_rule_id) {
            this.setState({
                points_rule_id
            }, () => {
                this.queryPointsRuleDetail()
            })
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="integral">
                <NavBar icon="icon-zichan" father="积分管理" child={`${this.state.points_rule_id ? "编辑" : "新建"}积分规则`} router="integralManage" />
                <div className="content">
                    <Form>
                        <Form.Item label="奖励条件">
                            {getFieldDecorator('condition', {
                                initialValue: true,
                                rules: [{
                                    required: true,
                                }]
                            })(
                                <Fragment>
                                    <div>
                                        <span className="input-desc">每购买金额</span>
                                        <Input onChange={this.changeMoney.bind(this, 'buy_amount')} value={this.state.buy_amount} className="input-short" suffix="元" />
                                        {
                                            this.state.buy_amount !== null && !this.state.buy_amount ? (<div className='text-danger'>请填写金额</div>) : null
                                        }
                                    </div>
                                    <div>
                                        <span className="input-desc">获得</span>
                                        <Input onChange={this.changeMoney.bind(this, 'reward_amount')} value={this.state.reward_amount} className="input-short" suffix="分" />
                                        {
                                            this.state.reward_amount !== null && !this.state.reward_amount ? (<div className='text-danger'>请填写获得奖励积分</div>) : null
                                        }
                                    </div>
                                </Fragment>
                            )}
                        </Form.Item>
                        <Form.Item label="适用商品">
                            {getFieldDecorator('goods_type', {
                                initialValue: "",
                                rules: [{
                                    required: true,
                                    message: "请输入优惠券类型!"
                                }]
                            })(
                                <Fragment>
                                    <Radio.Group value={this.state.goods_type} onChange={this.changeGoodsType}>
                                        <Radio value="all_goods">全部商品</Radio>
                                        <Radio value="general">指定商品</Radio>
                                    </Radio.Group>
                                    <div className="text-remind">
                                        {
                                            this.state.goods_type === "general" ? '最多添加200个商品，' : null
                                        }
                                        如果商品参加多个积分规则，会重复送积分
                                    </div>
                                    <div className="integral-goods" style={{ display: this.state.goods_type === "general" ? 'flex' : 'none' }}>
                                        <div className="integral-goods-add" onClick={this.showModal}>
                                            +添加商品
                                        </div>
                                        {
                                            this.state.goods_list.filter(item => item.status === 1).map(item =>
                                                <div className="img-content" key={item.goods_id}>
                                                    <img alt=""
                                                        key={item.goods_id}
                                                        src={item.thumbnail_url}
                                                    />
                                                    <Icon onClick={this.delGoods.bind(this, item.goods_id)} type="close-circle" theme="filled" className='icon-del' />
                                                </div>
                                            )
                                        }
                                    </div>
                                </Fragment>
                            )}
                        </Form.Item>
                    </Form>
                    <footer>
                        <Button type="primary" shape="round" onClick={this.createOrUpdatePointsRule}>保存</Button>
                        <Button shape="round" onClick={() => { this.context.router.history.push('integralManage') }}>取消</Button>
                    </footer>
                </div>
                <GoodsChoose
                    visible={this.state.visible}
                    handleOk={this.onOk}
                    handleCancel={this.onCancel}
                />
            </div>
        )
    }
}
const IntegralForm = Form.create({ name: 'integral_form' })(Integral);


export default IntegralForm;

