import React, { Component } from 'react';
import config from '@config/config';
import './index.scss';
import { Upload, Icon, message, Button } from 'antd';
import copy from "copy-to-clipboard/index";
import cookie from 'react-cookies';
import PropTypes from "prop-types";

class imageUpload extends Component {
    constructor(props) {
        super(props);
        imageUpload.contextTypes = {
            router: PropTypes.object.isRequired
        };
        this.state = {
            fileList: [],
            status: 'upLoading',
            showModal: false,
            uid: '',
        };
        this.onChange = this.onChange.bind(this);
        this.showTheModal = this.showTheModal.bind(this);
    }
    componentDidMount() {
        if (!cookie.load('user_name')) {
            message.error('没有登陆DTC Mall哦，即将为您跳转', 2, () => {
                this.context.router.history.push("/login");
            });
            return;
        }
        const MORE_30_DAY = 30 * 24 * 60 * 60 * 1000;
        const list = JSON.parse(localStorage.getItem('fileList'));
        if (list && Array.isArray(list)) {
            const canUseList = list.filter((item) => {
                return (item.uploadTime + MORE_30_DAY) > Date.now();
            })
            this.setState({
                fileList: canUseList,
            }, () => {
                localStorage.setItem('fileList', JSON.stringify(this.state.fileList));
            });
        }
    }
    beforeUpload(file) {
        const reg = /^image\/.+$/;
        if (!reg.test(file.type)) {
            message.error('仅支持上传图片或者gif');
        }
        return reg.test(file.type);
    }
    onChange({ file }) {
        if (file.status === 'done') {
            if (!this.state.fileList.some((item) => item.uid === file.uid)) {
                file.uploadTime = Date.now();
                this.setState((preState) => ({
                    fileList: [file, ...preState.fileList],
                }), () => {
                    localStorage.setItem('fileList', JSON.stringify(this.state.fileList));
                });
            }
        }
    }
    showTheModal() {
        this.setState({
            showModal: true,
        });
    }
    showRightModal(uid) {
        this.setState({
            uid,
        });
    }
    copyUrl(url) {
        copy(url);
        message.success('复制成功');
    }
    render() {
        const beforeUpload = this.beforeUpload;
        const onChange = this.onChange;
        const uploadProps = {
            action: `${config.baseUrl}/ossFile/upload`,
            accept: 'image/*',
            beforeUpload,
            multiple: true,
            showUploadList: {
                showPreviewIcon: false,
                showRemoveIcon: false,
                showDownloadIcon: false,
            },
            onChange,
            withCredentials: true,
        };
        return (
            <div className="imageUpload">
                <span className="logo">ImageUpload</span>
                <span className="favorites">👆将我保存为书签，下次不会找不到啦😊</span>
                <div className="uploadContent">
                    <div className="btnWrap">
                        <Upload.Dragger
                            {...uploadProps}
                            className="uploadDraggerWrap"
                        >
                            <p className="ant-upload-drag-icon">
                                <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">点击或者拖拽文件上传</p>
                            <p className="ant-upload-hint">
                                支持单个或者批量上传。
                                <br />
                                仅支持上传图片或者gif
                                <br />
                                ⚠️：请不要上传公司机密并传播。否则后果自负。
                            </p>
                        </Upload.Dragger>
                    </div>
                </div>
                <div className="historyContent">
                    <p className="historyTitle">
                        上传历史<br />
                        <span className="tips">历史记录保存30天，期间请不要清除浏览器缓存，否则历史记录将失效</span>
                    </p>
                    <div className="content"
                        onMouseOver={this.showTheModal}
                        onMouseLeave={() => this.setState({ showModal: false })}
                    >
                        {this.state.fileList.map((file) => (
                            <div
                                className="item"
                                key={file.uid}
                                onMouseOver={this.showRightModal.bind(this, file.uid)}
                            >
                                <img className="itemImg" src={file.response.result.oss_file_url} alt="" />
                                {this.state.uid === file.uid && this.state.showModal
                                    ?
                                    <div className="modal">
                                        <Button onClick={this.copyUrl.bind(this, file.response.result.oss_file_url)}>复制链接</Button>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        ))}
                    </div>
                </div>
                <p className="connect">有任何问题，欢迎请联系产品开发部-UDH-周亮。<br />📱微信：zxlAlxz<br />📮邮箱：lzhou@leqee.com</p>
            </div>
        );
    }
}

export default imageUpload;