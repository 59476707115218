import React from 'react'
import {Modal,List} from 'antd'
import '@css/goodsRemind.scss'

class GoodsRemind extends React.Component{
    constructor(){
        super()
        this.state={

        }
    }
    render(){
        return (
            <Modal
                title="温馨提示"
                visible={this.props.visible}
                onOk={this.props.handleOk}
                onCancel={this.props.handleCancel}
                width={736}
                className="goods-remind"
                destroyOnClose={true}
                centered
                okButtonProps={{shape:'round'}}
                cancelButtonProps={{shape:'round'}}
            >
                <p className="title">{this.props.title}</p>
                <List
                     className="list"
                    itemLayout="horizontal"
                    dataSource={this.props.data}
                    renderItem={item => (
                        <List.Item>
                            {item}
                        </List.Item>
                    )}
                />
            </Modal>
        )
    }
}
export  default  GoodsRemind
