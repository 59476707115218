import React, { Fragment } from "react";
import { Icon } from "antd";
import "@css/campaignShow.scss";

class CampaignGoodsSlider extends React.Component {
	constructor() {
		super();
		this.state = {
			goods_list: [],
			template_list: [1, 2, 3]
		};
	}

	render() {
		return (
			<div className="campaign-goods-slider">
				{this.props.goods.pattern_type === 1 ? (
					<Fragment>
						<img alt="" src={this.props.goods.thumbnail_url} width="270px" />
						<div className="slide-content">
							{this.props.goods.activity_goods_res_list.length ? (
								this.props.goods.activity_goods_res_list.map((item, index) => (
									<div key={index} className="campaign-goods-slider-goods">
										<div className="goods-pic">
											<img alt="" src={JSON.parse(item.goods_res.gallery_urls)[0]} width="60px" height="60px" />
										</div>
										<div className="goods-name">{item.goods_res.goods_name}</div>
										<div>¥ {item.goods_res.current_price / 100}</div>
									</div>
								))
							) : (
									<div className="demo-list">
										{this.state.template_list.map(item => (
											<div className="goods" key={item}>
												<div className="pic">
													<Icon type="picture" />
												</div>
											</div>
										))}
									</div>
								)}
						</div>
					</Fragment>
				) : (
						<div className="goodsListWrap">
							<img className="goodsListBacImg" src={this.props.goods.thumbnail_url} />
							<div className="goodsListContent">
								{this.props.goods.activity_goods_res_list.map((item, index) => (
									<div key={index} className="goodsListItem">
										<img width="110" height="82" src={JSON.parse(item.goods_res.gallery_urls)[0]} alt="商品图" />
										<div className="listContentDetail">
											<p className="listContentTitle">{item.goods_res.goods_name}</p>
											<p className="listContentPriceWrap">
												<span className="nowPrice">¥{item.goods_res.current_price / 100}</span>
												<span className="originPrice">¥{item.goods_res.original_price / 100}</span>
											</p>
										</div>
									</div>
								))}
							</div>
						</div>
					)}
			</div>
		);
	}
}

export default CampaignGoodsSlider;
