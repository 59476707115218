import React from 'react'
import {Table,Form,Button,Input,Pagination,Icon,Tooltip,message} from 'antd'
import '@css/liveList.scss'
import NavBar from '@/view/component/common/NavBar.js'
import {connect} from "react-redux";
import cookie from 'react-cookies'
import {queryRoomInfoList,synWechatRoomInfo} from '@service/getData'
import config from "@config/config";
import {formatDate} from '@common/common'
class LiveList extends React.Component{
    constructor(){
        super()
        this.state={
            requestUrl:config.baseUrl+"/room/exportRoomInfoList?room_info_id=",
            columns:[
                {
                    title: "id",
                    dataIndex: "room_info_id"
                },{
                    title: "直播标题",
                    dataIndex: "name"
                },{
                    title: "开播时间",
                    dataIndex: "start_time",
                    render:(text)=>{
                        return formatDate(text*1000)
                    }
                },{
                    title: "结束时间",
                    dataIndex: "end_time",
                    render:(text)=>{
                        return formatDate(text*1000)
                    }
                },{
                    title: "主播昵称",
                    dataIndex: "anchor_name"
                },{
                    title: "直播状态",
                    dataIndex: "live_status_desc",
                    render:(text)=><span style={{color:text.color}}>{text.name}</span>
                },{
                    title:
                        <div>
                            订单数
                            <Tooltip title="开播后7天内数据">
                                <Icon type="question-circle" theme="twoTone" />
                            </Tooltip>
                        </div>,
                    dataIndex: "order_count"
                },{
                    title:
                        <div>
                            订单金额
                            <Tooltip title="开播后7天内数据">
                                <Icon type="question-circle" theme="twoTone" />
                            </Tooltip>
                        </div>,
                    dataIndex: "order_total_amount",
                    render:(text)=>{
                            return text/100
                    }

                },{
                    title: "操作",
                    dataIndex: "action",
                    render:(text,record)=>(<span className="text-note" onClick={this.onExport.bind(this,record.room_info_id)}>导出数据明细</span>)
                }
            ],
            page:1,
            page_size:10,
            total:0,
            room_info_res_list:[],
            live_status:{
                101: {
                    name:'直播中',
                    color:'#00CC66'
                },
                102: {
                    name:'未开始',
                    color:'#333333'
                },
                103: {
                    name:'已结束',
                    color:'#999999'
                },
                104: {
                    name:'禁播',
                    color:'#FF0000'
                },
                105: {
                    name:'暂停中',
                    color:'#FF9900'
                },
                106: {
                    name:'异常',
                    color:'#FF0000'
                },
                107: {
                    name:'已过期',
                    color:'#999999'
                }
            }
        }
    }
    onExport=(id)=>{
        window.open(`${this.state.requestUrl}${id}`)

    }
    queryRoomInfoList=async()=>{
        let form = this.props.form.getFieldsValue();
        let data={
            room_info_id_and_name:form.room_info_id_and_name,
            mini_app_id:cookie.load("mini_app_id"),
            page_size:this.state.page_size,
            page:this.state.page
        }
        let res=await queryRoomInfoList(data);
        if(res.success){
            let {room_info_res_list,total}=res.result;
            room_info_res_list.forEach(item=>{
                item.live_status_desc=this.state.live_status[item.live_status]
            })
            this.setState({
                room_info_res_list,
                total
            })
        }
    }
    synWechatRoomInfo=async()=>{
        let data={
            mini_app_id:cookie.load('mini_app_id')
        }
        let res=await synWechatRoomInfo(data);
        if(res.success){
            message.success("同步成功")
        }else{
            message.error(res.msg)
        }
        this.queryRoomInfoList()
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size:pageSize
        }, ()=> {
            this.queryRoomInfoList()
        })
    }
    onShowPageChange = (page) => {
        this.setState({
            page_no:page
        },()=> {
            this.queryRoomInfoList()
        })
    }
    clearFilter = () => {
        this.props.form.resetFields();
    }
    componentDidMount(){
        this.queryRoomInfoList()
    }
    render(){
        const { getFieldDecorator } = this.props.form;
        let {menu_code} = this.props
        return(
            <div className="live-list">
                <NavBar icon="icon-bofang"
                        child={menu_code.live_list}
                        router="marketSetting"
                />
                <div className="content">
                    <div className="filter-content">
                        <Form className="filter-form ant-vertical" layout="inline">
                            <Form.Item label="直播标题/id：">
                                {getFieldDecorator('room_info_id_and_name')(
                                    <Input  className="form-input" placeholder="输入直播ID" />
                                )}
                            </Form.Item>
                        </Form>
                        <div className="filter-action">
                            <Button shape="round" type="primary" onClick={this.queryRoomInfoList}>筛选</Button>
                            <span className="text-note" onClick={this.clearFilter} >清空筛选条件</span>
                        </div>
                    </div>
                    <div className="action">
                        <span className="text-note" onClick={this.synWechatRoomInfo}>一键同步直播</span>
                    </div>
                    <Table
                        columns={this.state.columns}
                        rowKey={(record,index) => index}
                        pagination={false}
                        dataSource={this.state.room_info_res_list}
                    />
                    <Pagination
                        className="pagenation"
                        onChange={this.onShowPageChange}
                        onShowSizeChange={this.onShowSizeChange}
                        defaultCurrent={this.state.page_no}
                        total={this.state.total}
                        showTotal={(total) =>  `共 ${total} 条`}
                        pageSizeOptions={["10","30","50","100"]}
                        showSizeChanger
                    />
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        menu_code: state.menuCode

    }
}
const LiveListForm = Form.create({ name: 'live_list_form' })(LiveList);
export  default connect(mapStateToProps) (LiveListForm)
