export {
  getQueryString,
  getUrlParams,
  formatDate,
  formatNumber,
  formatInt,
  compare,
  formatNotNumber,
  formatIntAndZero,
  mergeList,
  deleteItem,
  throttle,
  debounce
};

function getQueryString(name) {
  /*
    获取url"?"后面的code为"name"的值
  */
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"),
    search = window.location.href.split("?")[1];

  let r = search && search.match(reg);
  if (r != null) return decodeURI(r[2]);
  return null;
}

//获取url上的参
function getUrlParams(name) {
  let aQuery = window.location.href.split("?"); //取得Get参数
  let aGET = name ? "" : {};
  if (aQuery[1]) {
    let aBuf = aQuery[1].split("&");
    if (name) {
      for (let i = 0, iLoop = aBuf.length; i < iLoop; i++) {
        let aTmp = aBuf[i].split("="); //分离key与Value
        if (name === aTmp[0]) {
          aGET = aTmp[1];
        }
      }
      return decodeURI(aGET);
    } else {
      for (let i = 0, iLoop = aBuf.length; i < iLoop; i++) {
        let aTmp = aBuf[i].split("="); //分离key与Value
        aGET[aTmp[0]] = aTmp[1];
      }
      return aGET;
    }
  }
  return name ? null : {};
}

/**
 * 时间格式方法
 *
 * @param {any} timeStamp  时间戳，秒级/毫秒级
 * @param {any} type 格式化时间类型，默认  Y-M-D H:I:S
 * @returns {string} formatTime 格式化后的时间 例如： 2017-05-05 12:09:22
 */
function formatDate(timeStamp, type = "Y-M-D H:I:S", auto = false, short = false) {
  let time = (timeStamp + "").length === 10 ? new Date(parseInt(timeStamp) * 1000) : new Date(parseInt(timeStamp));
  let _year = time.getFullYear();
  _year = short ? +(_year + "").substring(2) : _year;
  let _month = time.getMonth() + 1 < 10 ? "0" + (time.getMonth() + 1) : time.getMonth() + 1;
  let _date = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
  let _hours = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
  let _minutes = time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
  let _secconds = time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
  let formatTime = "";
  let distinctTime = new Date().getTime() - time.getTime();

  if (auto) {
    if (distinctTime <= 1 * 60 * 1000) {
      // console.log('一分钟以内，以秒数计算');
      let _s = Math.floor((distinctTime / 1000) % 60);
      formatTime = _s + "秒前";
    } else if (distinctTime <= 1 * 3600 * 1000) {
      // console.log('一小时以内,以分钟计算');
      let _m = Math.floor((distinctTime / (60 * 1000)) % 60);
      formatTime = _m + "分钟前";
    } else if (distinctTime <= 24 * 3600 * 1000) {
      // console.log('一天以内，以小时计算');
      let _h = Math.floor((distinctTime / (60 * 60 * 1000)) % 24);
      formatTime = _h + "小时前";
    } else if (distinctTime <= 30 * 24 * 3600 * 1000) {
      let _d = Math.floor((distinctTime / (24 * 60 * 60 * 1000)) % 30);
      formatTime = _d + "天前";
      // console.log('30天以内,以天数计算');
    } else {
      // 30天以外只显示年月日
      formatTime = _year + "-" + _month + "-" + _date;
    }
  } else {
    switch (type) {
      case "Y-M-D H:I:S":
        formatTime = _year + "-" + _month + "-" + _date + " " + _hours + ":" + _minutes + ":" + _secconds;
        break;
      case "Y.M.D":
        formatTime = _year + "." + _month + "." + _date;
        break;
      case "M/D":
        formatTime = _month + "/" + _date;
        break;
      case "Y-M-D H:I:S zh":
        formatTime = _year + "年" + _month + "月" + _date + "日  " + _hours + ":" + _minutes + ":" + _secconds;
        break;
      case "Y-M-D":
        formatTime = _year + "-" + _month + "-" + _date;
        break;
      case "Y/M/D":
        formatTime = _year + "/" + _month + "/" + _date;
        break;
      case "Y-M-D zh":
        formatTime = _year + "年" + _month + "月" + _date + "日";
        break;
      case "Y/M/D H:I":
        formatTime = _year + "/" + _month + "/" + _date + " " + _hours + ":" + _minutes;
        break;
      case "H:I:S":
        formatTime = _hours + ":" + _minutes + ":" + _secconds;
        break;
      case "H:I":
        formatTime = _hours + ":" + _minutes;
        break;
      default:
        formatTime = _year + "-" + _month + "-" + _date + " " + _hours + ":" + _minutes + ":" + _secconds;
        break;
    }
  }
  // 返回格式化的日期字符串
  return formatTime;
}

function formatNumber(value) {
  value = value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
  value = value.replace(/^\./g, ""); //验证第一个字符是数字
  value = value.replace(/\.{2,}/g, "."); //只保留第一个, 清除多余的
  value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
  return value;
}
function formatNotNumber(value) {
  value = value.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
  value = value.replace(/^\./g, ""); //验证第一个字符是数字
  value = value.replace(/\.{2,}/g, "."); //只保留第一个, 清除多余的
  value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
  if (+value === 0) {
    return null;
  }
  return value;
}

function formatInt(value) {
  if (value.length === 1) {
    value = value.replace(/[^1-9]/g, "");
  } else {
    value = value.replace(/\D/g, "");
  }
  return value;
}
function formatIntAndZero(value) {
  if (value.length === 1) {
    value = value.replace(/[^0-9]/g, "");
  } else {
    value = value.replace(/\D/g, "");
  }
  return value;
}

function compare(property) {
  return function (a, b) {
    var value1 = a[property];
    var value2 = b[property];
    return value1 - value2;
  };
}

function mergeList(addList, list, attr) {
  const newsetList = addList.filter((item) => {
    return list.every((citem) => citem[attr] !== item[attr]);
  });
  return [...list, ...newsetList]
}

function deleteItem(list, id, max_attr, attr) {
  let current_list = JSON.parse(JSON.stringify(list));
  let current_index = current_list.findIndex(item => item[max_attr] === id);
  if (current_index > -1 && current_list[current_index][attr]) {
    current_list[current_index].status = 0;
  } else {
    current_list.splice(current_index, 1);
  }
  return current_list;
}

/* 
    节流函数
    高频事件函数，n秒内只会执行一次，所以节流函数会稀释函数的执行频率
    不想自己封装也可以直接引用lodash
*/
function throttle(fn, s = 500) {
  let canDo = true;
  return function (...args) {
    if (canDo) {
      canDo = false;
      setTimeout(() => {
        fn.apply(this, args);
        canDo = true;
      }, s);
    }
  };
}

/* 
    防抖函數
    触发高频函数n秒内只会执行一次，若n秒内再次触发，则重新计算时间
*/
function debounce(fn, s = 500) {
  let timer = null;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, s);
  };
}
