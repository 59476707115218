import React from "react";
import NavBar from "@/view/component/common/NavBar.js";
import { connect } from "react-redux";
import "@css/template.scss";
import { Form, Table, Switch, message, Modal, Dropdown} from "antd";
import cookie from "react-cookies";
import { getQueryString } from "@common/common";
import PropTypes from "prop-types";
import {
  queryTemplateList,
  queryTemplateInitList,
  switchTemplate,
  pushTemplateInit
} from "@service/getData";
class Template extends React.Component {
  constructor(props) {
    super();
    Template.contextTypes = {
      router: PropTypes.object.isRequired
    };
    this.state = {
      is_admin: 0,
      template_list: [],
      columns1: [
          {
              title: '小程序类目',
              dataIndex: 'category_desc',
              width:"7.5%",
              render: (value, row) => {
                  let obj = {
                      children: value,
                      props: {},
                  };
                  if (row.rowSpan != null) {
                      obj.props.rowSpan = row.rowSpan
                  }else{
                      obj.props.rowSpan = 0
                  }
                  return obj;
              }

          },
        {
          title: "模板ID",
          dataIndex: "wx_library_id"
        },
        {
          title: "模板消息名称",
          dataIndex: "wx_library_title"
        },
        {
          title: "关键词1",
          dataIndex: "keyword1"
        },
        {
          title: "关键词2",
          dataIndex: "keyword2"
        },
        {
          title: "关键词3",
          dataIndex: "keyword3"
        },
        {
          title: "关键词4",
          dataIndex: "keyword4"
        },
        {
          title: "状态",
          dataIndex: "push",
          render: text => <div>{text === 1 ? "已发布" : "未发布"}</div>
        },
        {
          title: "操作",
          dataIndex: "action",
          render: (text, record) => (
            <div>
              {+record.push === 0 ? (
                <span
                  className="text-note"
                  onClick={this.pushTemplateInit.bind(
                    this,
                    record.template_init_id
                  )}
                >
                  发布
                </span>
              ) : null}
            </div>
          )
        }
      ],
      columns2: [
        {
          title: "订阅消息名称",
          dataIndex: "wx_library_title"
        },
        {
          title: "状态",
          dataIndex: "turnon",
          render: (text, record) => (
            <div>
              <Switch
                defaultChecked={Boolean(record.turnon)}
                onChange={this.onChange.bind(this, record.template_id)}
              />
            </div>
          )
        },
        {
          title: "操作",
          dataIndex: "action",
          render: (text, record) => (
            <Dropdown
              overlay={<img alt="示例图片"src={record.desc} width="300" />}
              placement="bottomLeft"
            >
              <div className="topbar-item topbar-qrcode-item">
                <span className="text-note">查看示例</span>
              </div>
            </Dropdown>
          )
        }
      ]
    };
  }
  componentDidMount() {
    let load_is_admin = +cookie.load("is_admin"),
      is_admin = +getQueryString("is_admin");
    if (!load_is_admin && is_admin === 1) {
      Modal.error({
        title: "温馨提示",
        content: "这是管理员操作页面！",
        onOk: () => {
          this.context.router.history.goBack();
        }
      });
      return;
    }
    if (!cookie.load("shop_id") && +cookie.load("is_admin") === 1) {
      return;
    }
    this.setState({
      is_admin
    });
    if (is_admin) {
      this.queryTemplateInitList();
    } else {
      this.queryTemplateList();
    }
  }
  async queryTemplateInitList() {
    let res = await queryTemplateInitList({});
    let template_list=[...res.result.template_init_list]
      for(let i=0;i<template_list.length;i++){
        let item=template_list[i];
        let arr=template_list.filter(template=>item.category_id===template.category_id)
          item.rowSpan=arr.length;
          i+=arr.length-1
      }
    this.setState({
      template_list: [...res.result.template_init_list]
    });
  }
  async queryTemplateList() {
    let mini_app_id = cookie.load("mini_app_id");
    if (!mini_app_id) {
      message.error("没有店铺");
      return;
    }
    let res = await queryTemplateList({
      mini_app_id
    });
    this.setState({
      template_list: [...res.result.list]
    });
  }
  async onChange(template_id, bol) {
    let res = await switchTemplate({
      template_id,
      turnon: +bol
    });
    if (res.success) {
      let text = bol ? "已开启" : "已关闭";
        let list=this.state.template_list.filter(item=>item.turnon===1)
      if(bol&&list.length>15){
            return  Modal.warning({
                title: '温馨提示',
                content: '根据微信小程序模板消息管理规则，一次最多支持开通并发送15个小程序模板，如需开启更多消息可以关闭之前开启的其他模板消息。',
            });
      }
      message.success(text);
    }
  }
  async pushTemplateInit(template_init_id) {
    let res = await pushTemplateInit({
      template_init_id
    });
    if (res.success) {
      this.queryTemplateInitList();
      message.success("发布成功");
    }
  }
  render() {
    let { menu_code } = this.props;
    return (
      <div className="template">
        <div className="content">
          <NavBar
              icon={this.state.is_admin===0?"icon-shezhi":"icon-guanliyuanshezhi"}
              child={menu_code.template_message} />
          <div className="template-content">
            <div className="shopDataTitle">
              <i className="titleSign" />
                订阅消息管理
            </div>
            <div className="table-content">
              <Table
                rowKey={(record, index) => index}
                columns={
                  this.state.is_admin
                    ? this.state.columns1
                    : this.state.columns2
                }
                dataSource={this.state.template_list}
                pagination={false}
                bordered={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};

const TemplateForm = Form.create({ name: "template_form" })(Template);

export default connect(mapStateToProps)(TemplateForm);
