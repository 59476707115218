import React from "react";
import "@css/orderManage.scss";
import cookie from "react-cookies";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import config from "@config/config";
import { queryAdminOrderList, cancelOrder, queryCjAdminOrderList, shippingOutSelfOrder, getData } from "@service/getData";
import { Form, Input, Select, Button, Tabs, Table, message, Pagination, Spin, Icon, Modal } from "antd";
import { getQueryString } from "@common/common";
import SelfShipmentModal from '@/view/component/common/SelfShipmentModal'
import RangePicker from "@/view/component/search/RangePicker";

class Order extends React.Component {
  constructor() {
    super();
    Order.contextTypes = {
      router: PropTypes.object.isRequired
    };
    this.state = {
      loading: false,
      request_url: config.baseUrl + "/order/exportAdminOrderList",
      cj_request_url: config.baseUrl + "/cjGroup/exportCjAdminOrderList",
      order_status: "-2",
      page_size: 10,
      page: 1,
      total: 0,
      cj_refund_status: ["正常", "申请退款", "退款中", "退款成功", "退款失败"],
      columns: [
        {
          title: "商品",
          dataIndex: "goods_name",
          width: "30%",
          render: (value, row, index) => {
            let obj = {
              children: value,
              props: {}
            };
            if (row.children) {
              obj.children = (
                <div className="table-order-info">
                  <span className="mr20">订单号：{row.order_sn}</span>
                  <span className="mr20">下单时间：{row.order_time}</span>
                  <span className="mr20">订单类型：{(row.shipment_type === "group_shipment" ? `自提` : `邮寄`) + row.order_type_desc}</span>
                </div>
              );
              obj.props.colSpan = 7;
              obj.props.className = "row-header";
            } else {
              obj.children = (
                <div className="order-goods-info">
                  <img alt="" width="60" height="60" src={row.thumbnail_url} className="goods-image"></img>
                  <div className="goods-name-sku">
                    <div className="order-goods-name">{row.goods_name}</div>
                    <div className="sku">{row.sku}</div>
                  </div>
                </div>
              );
            }
            return obj;
          }
        },
        {
          title: "单价（元）/数量",
          width: "15%",
          dataIndex: "current_price",
          render: (value, row, index) => {
            let obj = {
              children: (
                <div className="text-right">
                  <div>¥{row.current_price / 100}</div>
                  {row.order_type === 'period_purchase' && row.period_purchase_res ? (
                    <span>一周一次，每次{row.period_purchase_res.quantity}件，共{row.period_purchase_res.period_num}次</span>
                  ) : (
                      <div>{row.quantity}件</div>
                    )}
                </div>
              ),
              props: {}
            };
            if (row.children) {
              obj.props.colSpan = 0;
            }
            return obj;
          }
        },
        {
          title: "售后状态",
          width: "8%",
          dataIndex: "order_goods_refund_status",
          render: (value, row) => {
            let obj = {
              children: (
                <div>{value ? (
                  <div>
                    <span>{this.props.refund_status_list[value].value}</span>
                    {value > 6 && value < 12 ? (
                      <p className="text-note" onClick={this.goRefundDetail.bind(this, row.order_id)}>退款详情</p>
                    ) : null}
                  </div>
                ) : null}</div>
              ),
              props: {}
            };
            if (row.children) {
              obj.props.colSpan = 0;
            }
            return obj;
          }
        },
        {
          title: "买家/收件人",
          dataIndex: "nick_name",
          width: "14.17%",
          render: (value, row) => {
            let obj = {
              children: (
                <div>
                  <div className="text-note ml0" onClick={this.goToClient.bind(this, row)}>
                    {row.nick_name}
                  </div>
                  <span>{row.mobile + (row.is_leader ? "(团长)" : "")}</span>
                </div>
              ),
              props: {}
            };
            if (row.children) {
              obj.props.colSpan = 0;
            } else if (row.rowSpan != null) {
              obj.props.rowSpan = row.rowSpan;
            }
            return obj;
          }
        },
        {
          title: "订单状态",
          dataIndex: "order_status_desc",
          width: "7.47%",
          render: (value, row) => {
            let obj = {
              children: (
                <div>
                  <p>{value}</p>
                  {row.order_type === 'period_purchase' && row.period_purchase_res ? (
                    <p style={{ color: '#2589ff' }}>{row.period_purchase_res.shipping_info}</p>
                  ) : null}
                </div>
              ),
              props: {}
            };
            if (row.children) {
              obj.props.colSpan = 0;
            } else if (row.rowSpan != null) {
              obj.props.rowSpan = row.rowSpan;
            }
            return obj;
          }
        },
        {
          title: "支付金额",
          dataIndex: "pay_amount",
          width: "7.56%",
          render: (value, row) => {
            let obj = {
              children: value / 100,
              props: {}
            };
            if (row.children) {
              obj.props.colSpan = 0;
            } else if (row.rowSpan != null) {
              obj.props.rowSpan = row.rowSpan;
            }
            return obj;
          }
        },
        {
          title: "操作",
          width: "6.86%",
          dataIndex: "action",
          render: (value, row) => {
            let obj = {
              children: (
                <div className="table-action">
                  <p className="text-note" onClick={this.gotoOrderDetail.bind(this, row.order_id)}>
                    查看详情
                  </p>
                  <p
                    style={{ display: row.order_status === 0 && this.props.index === "1" ? true : "none" }}
                    className="text-note"
                    onClick={this.goTocancelOrder.bind(this, row.order_id)}>
                    取消订单
                  </p>
                  <p
                    className="text-note"
                    onClick={this.showModal.bind(this, row)}
                    style={{ display: row.order_status === 1 && this.props.index === "1" && row.order_type !== 'period_purchase' ? true : "none" }}
                  >
                    发货
                  </p>
                  {row.order_type === 'period_purchase' ? (
                    <p className="text-note" onClick={this.showPeriodPurchaseRecord.bind(this, row.order_id)}>发货记录</p>
                  ) : null}
                </div>
              ),
              props: {}
            };
            if (row.children) {
              obj.props.colSpan = 0;
            } else if (row.rowSpan != null) {
              obj.props.rowSpan = row.rowSpan;
            }
            return obj;
          }
        }
      ],
      cj_columns: [
        {
          title: "订单号",
          width: "9.7%",
          dataIndex: "order_sn",
          render: (value, row) => {
            let obj = {
              children: value,
              props: {}
            };
            if (row.rowSpan != null) {
              obj.props.rowSpan = row.rowSpan;
            }
            return obj;
          }
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          width: "21.7%"
        },
        {
          title: "规格",
          dataIndex: "sku",
          width: "8.26%"
        },
        {
          title: "单价|数量",
          dataIndex: "num",
          width: "7.5%",
          render: (text, record) => (
            <div>
              <p>¥{record.current_price / 100}</p>
              <p>{record.quantity}件</p>
            </div>
          )
        },
        {
          title: "订单描述",
          dataIndex: "order_desc",
          width: "7.5%",
          render: (text, record) => (
            <div>
              <p>{this.state.cj_refund_status[record.refund_status]}</p>
              <p>{record.is_lucky ? "中奖" : "未开奖"}</p>
            </div>
          )
        },
        {
          title: "类型",
          dataIndex: "shipment_type",
          width: "6.78%",
          render: (value, row) => {
            let obj = {
              children: value === "group_shipment" ? "自提" : "邮寄",
              props: {}
            };
            if (row.rowSpan != null) {
              obj.props.rowSpan = row.rowSpan;
            }
            return obj;
          }
        },
        {
          title: "买家/收件人",
          dataIndex: "nick_name",
          width: "14.17%",
          render: (value, row) => {
            let obj = {
              children: (
                <div>
                  <div className="text-note ml0" onClick={this.goToClient.bind(this, row)}>
                    {row.nick_name}
                  </div>
                  <span>{row.mobile + (row.is_leader ? "(团长)" : "")}</span>
                </div>
              ),
              props: {}
            };
            if (row.rowSpan != null) {
              obj.props.rowSpan = row.rowSpan;
            }
            return obj;
          }
        },
        {
          title: "下单时间",
          dataIndex: "order_time",
          width: "9.39%",
          render: (value, row) => {
            let obj = {
              children: value ? (
                <div>
                  <div>{value.slice(0, 11)}</div>
                  <div>{value.slice(11, value.length)}</div>
                </div>
              ) : null,
              props: {}
            };
            if (row.rowSpan != null) {
              obj.props.rowSpan = row.rowSpan;
            }
            return obj;
          }
        },
        {
          title: "活动状态",
          dataIndex: "order_status_desc",
          width: "7.47%",
          render: (value, row) => {
            let obj = {
              children: value,
              props: {}
            };
            if (row.rowSpan != null) {
              obj.props.rowSpan = row.rowSpan;
            }
            return obj;
          }
        },
        {
          title: "支付金额",
          dataIndex: "pay_amount",
          width: "7.56%",
          render: (text, record) => <span>{text / 100}</span>
        },
        {
          title: "买家留言",
          dataIndex: "remark",
          width: "15%",
          render: (value, row) => {
            let obj = {
              children: value,
              props: {}
            };
            if (row.rowSpan != null) {
              obj.props.rowSpan = row.rowSpan;
            }
            return obj;
          }
        },
        {
          title: "操作",
          width: "6.86%",
          dataIndex: "action",
          render: (value, record) => {
            let obj = {
              children: (
                <div className="table-action">
                  <p className="text-note" onClick={this.gotoOrderDetail.bind(this, record.order_id)}>
                    查看详情
                  </p>
                  <p
                    style={{ display: record.order_status === 0 && this.props.index === "1" ? true : "none" }}
                    className="text-note"
                    onClick={this.goTocancelOrder.bind(this, record.order_id)}>
                    取消订单
                  </p>
                </div>
              ),
              props: {}
            };
            if (record.rowSpan != null) {
              obj.props.rowSpan = record.rowSpan;
            }
            return obj;
          }
        }
      ],
      cj_free_order_columns: [
        {
          title: "订单号",
          width: "9.7%",
          dataIndex: "order_sn",
          render: (value, row) => {
            let obj = {
              children: value,
              props: {}
            };
            if (row.rowSpan != null) {
              obj.props.rowSpan = row.rowSpan;
            }
            return obj;
          }
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          width: "21.7%"
        },
        {
          title: "规格",
          dataIndex: "sku",
          width: "8.26%"
        },
        {
          title: "单价|数量",
          dataIndex: "num",
          width: "7.5%",
          render: (text, record) => (
            <div>
              <p>¥{record.current_price / 100}</p>
              <p>{record.quantity}件</p>
            </div>
          )
        },
        {
          title: "已助力/总助力",
          dataIndex: "nick_name",
          width: "14.17%",
          render: (value, row) => {
            let obj = {
              children: `${row.customer_num} / ${row.group_member_num}`,
              props: {}
            };
            if (row.rowSpan != null) {
              obj.props.rowSpan = row.rowSpan;
            }
            return obj;
          }
        },
        {
          title: "活动状态",
          dataIndex: "order_status_desc",
          width: "7.47%",
          render: (value, row) => {
            let obj = {
              children: value,
              props: {}
            };
            if (row.rowSpan != null) {
              obj.props.rowSpan = row.rowSpan;
            }
            return obj;
          }
        },
        {
          title: "支付金额",
          dataIndex: "pay_amount",
          width: "7.56%",
          render: (text, record) => <span>{text / 100}</span>
        },
        {
          title: "返现金",
          dataIndex: "free_order_back_cash_amount",
          width: "7.56%",
          render: (text, record) => <span>{text / 100}</span>
        },
        {
          title: "发放红包",
          dataIndex: "free_order_back_redpack_amount",
          width: "7.56%",
          render: (text, record) => <span>{text / 100}</span>
        },

        {
          title: "操作",
          width: "6.86%",
          dataIndex: "action",
          render: (value, record) => {
            let obj = {
              children: (
                <div className="table-action">
                  <p className="text-note" onClick={this.gotoOrderDetail.bind(this, record.order_id)}>
                    查看详情
                  </p>
                  <p
                    style={{ display: record.order_status === 0 && this.props.index === "1" ? true : "none" }}
                    className="text-note"
                    onClick={this.goTocancelOrder.bind(this, record.order_id)}>
                    取消订单
                  </p>
                </div>
              ),
              props: {}
            };
            if (record.rowSpan != null) {
              obj.props.rowSpan = record.rowSpan;
            }
            return obj;
          }
        }
      ],
      orderList: [],
      start_order_time: null,
      end_order_time: null,
      cj_order_status_list: ["已失效", "下单", "未成团", "已成团", "已发货", "收货确认"],
      activity_id: null,
      order_type_list: {
        points: "积分兑换",
        general: "普通",
        free_order: "抢免单",
        lucky: "抽奖",
        gift_card: "权益卡订单",
        rank_lucky: '拼团订单',
        period_purchase: '周期购',
      },
      order_res_list: [],
      order: {},
      visible: false,
      goodsRemindVisible: false,
      channelSearchOptions: [],
      periodColumns: [
        {
          title: '期数',
          dataIndex: 'period_order',
        },
        {
          title: '物流信息',
          dataIndex: 'logistics',
        },
        {
          title: '物流单号',
          dataIndex: 'tracking_number',
        },
        {
          title: '发货时间',
          dataIndex: 'ship_time',
        },
      ],
      releaseRecords: [],
      canClick: true,
    };
  }
  showModal = (row) => {
    this.setState({
      visible: true,
      order: row
    })
  }
  showPeriodPurchaseRecord(order_id) {
    const url = '/period_purchase/shipList';
    const data = {
      order_id,
    };
    getData(url, data).then((res) => {
      if (res.success) {
        this.setState({
          releaseRecords: res.result,
        }, () => {
          this.setState({
            periodVisible: true,
          });
        });
      }
    })
  }
  hideModal = () => {
    this.setState({
      visible: false
    })
  }
  confirmShipment = (info) => {
    this.setState({
      canClick: false,
    }, () => {
      let data = {
        order_id: this.props.index === "1" ? this.state.order.order_id : null,
        cj_order_id: this.props.index === "2" ? this.state.order.order_id : null,
        logistics: info.logistics,
        tracking_number: info.tracking_number
      }
      this.shippingOutSelfOrder(data);
    });
  }
  async shippingOutSelfOrder(data) {
    let res = await shippingOutSelfOrder(data)
    if (res.success) {
      if (!res.result.goods_limit_notice_list.length) {
        message.success("发货成功");
        this.setState({
          visible: false,
          order: {},
          canClick: true,
        });
        this.filterOrder(1);
      } else {
        this.setState({
          goodsRemindVisible: true,
          canClick: true,
        });
      }
    } else {
      res.msg === '库存不足' && this.setState({
        goodsRemindVisible: true,
        canClick: true,
      });
    }
  }
  handleChangeOrderStatus = e => {
    this.setState(
      {
        order_status: e
      },
      () => {
        this.filterOrder();
      }
    );
  };
  gotoOrderDetail = order_id => {
    let arr = this.props.index === "1" ? "order_id" : "cj_order_id";
    if (this.props.index === "1") {
      this.context.router.history.push(`/orderDetail?${arr}=${order_id}&type=orderManage`);
    } else {
      window.open(`${window.location.origin}/orderDetail?${arr}=${order_id}&type=orderManage`, "_blank");
    }
  };
  goTocancelOrder = order_id => {
    let data = {
      order_id: order_id
    };
    this.cancelOrder(data);
  };

  goRefundDetail(order_id) {
    this.context.router.history.push(`/refundDetail?order_id=${order_id}`);
  }

  async queryAdminOrderList(data) {
    let res = await queryAdminOrderList(data);
    if (res.success) {
      res.result.order_res_list.forEach(item => {
        let sub_list = [];
        item.order_type_desc = this.state.order_type_list[item.order_type];
        item.order_goods_res_list.forEach((goods, index) => {
          let order = {
            order_id: item.order_id,
            order_sn: item.order_sn + " " + index,
            sku: "",
            order_time: item.order_time,
            order_status: item.order_status,
            transaction_id: item.transaction_id,
            order_status_desc: this.props.order_status_list[item.order_status].order_status_short_desc,
            pay_amount: item.pay_amount,
            nick_name: item.user_res.nick_name,
            mobile: item.user_res.mobile,
            shipment_type: item.shipment_type,
            quantity: goods.quantity,
            remark: item.remark,
            order_type: item.order_type,
            period_purchase_res: item.period_purchase_res,
            order_type_desc: this.state.order_type_list[item.order_type],
            order_goods_refund_status: goods.order_goods_refund_status,
          };
          order.is_leader = item.is_leader;
          order.address_res = item.address_res;
          order.user_id = item.user_id;
          order.goods_name = goods.goods_res.goods_name;
          order.thumbnail_url = goods.goods_res.thumbnail_url;
          order.current_price = goods.sku_res.current_price;
          goods.sku_res.attribute_value_res_list.forEach(qitem => {
            order.sku += qitem.attribute_value;
          });
          if (index === 0) {
            order.rowSpan = item.order_goods_res_list.length * 2 - 1;
            order.order_goods_list = item.order_goods_res_list
          } else {
            order.rowSpan = 0;
          }
          if (index === item.order_goods_res_list.length - 1) {
            order.show_remark = true;
          }
          sub_list.push(order);
        });
        // https://ant.design/components/table-cn/#components-table-demo-tree-data
        // 用到了树形结构。除了商品那一列。接下来到所有列的row取的都是children里的属性值。
        item.children = sub_list;
      });
      this.setState({
        // 默认展开必须强制先刷新，不要删除此操作，否则换页或者changeTab后，children里的UI展示不出来
        order_res_list: [],
      }, () => {
        this.setState({
          order_res_list: res.result.order_res_list,
          total: res.result.total || 0,
          loading: false
        });
      });
      window.scrollTo(0, 0);
    }
  }
  queryCjAdminOrderList = async data => {
    let res = await queryCjAdminOrderList(data);
    let orderList = [];
    if (res.success) {
      res.result.cj_order_res_list.forEach(item => {
        let order = {
          order_id: item.cj_order_id,
          order_sn: item.cj_order_sn,
          sku: "",
          order_time: item.order_time,
          transaction_id: item.transaction_id,
          order_status: item.order_status,
          order_status_desc: this.state.cj_order_status_list[item.order_status + 1],
          pay_amount: item.pay_amount,
          nick_name: item.user_res.nick_name,
          mobile: item.user_res.mobile,
          shipment_type: item.shipment_type,
          quantity: 1,
          refund_status: item.refund_status,
          remark: item.remark,
          user_id: item.user_id,
          goods_name: item.goods_res.goods_name,
          current_price: item.sku_res.current_price,
          is_lucky: item.is_lucky,
          free_order_back_cash_amount: item.free_order_back_cash_amount,
          free_order_back_redpack_amount: item.free_order_back_redpack_amount,
          customer_num: item.customer_num,
          group_member_num: item.group_member_num
        };
        order.is_leader = item.is_leader;
        item.sku_res.attribute_value_res_list.forEach(qitem => {
          order.sku += qitem.attribute_value;
        });
        orderList.push(order);
      });
      this.setState({
        orderList: orderList,
        total: res.result.total,
        loading: false
      });
      window.scrollTo(0, 0);
    }
  };

  filterOrder = () => {
    if (!cookie.load("shop_id") && cookie.load("is_admin") === 1) {
      return;
    }
    this.setState({
      loading: true
    });
    let form = this.props.form.getFieldsValue();
    let data = {
      shop_id: cookie.load("shop_id"),
      page_size: this.state.page_size,
      page_no: this.state.page
    };
    if (form.order_sn) {
      this.props.index === '1' ? (data.order_sn = form.order_sn) : (data.cj_order_sn = form.order_sn);
    }
    if (form.transaction_id) {
      data.transaction_id = form.transaction_id;
    }
    if (form.receiver_name) {
      data.receive_name = form.receiver_name;
    }
    if (form.mobile) {
      var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (!myreg.test(form.mobile)) {
        return message.error("手机号码格式错误");
      } else {
        data.tel_number = form.mobile;
      }
    }
    if (form.has_remark) {
      data.has_remark = form.has_remark === 'all' ? null : Boolean(form.has_remark);
    }
    if (form.order_type) {
      data.order_type = form.order_type === "all" ? null : form.order_type;
    }
    if (form.goods_name) {
      data.goods_name = form.goods_name;
    }
    if (form.order_shipment_type) {
      data.order_shipment_type = form.order_shipment_type === "all" ? null : form.order_shipment_type;
    }
    if (form.is_refund) {
      data.is_refund = form.is_refund === -1 ? null : form.is_refund;
    }
    if (form.channel) {
      data.channel_id = form.channel;
    }
    if (this.state.order_status != null) {
      data.order_status = this.state.order_status === "-2" ? null : this.state.order_status;
    }
    if (this.state.start_order_time) {
      data.start_order_time = this.state.start_order_time;
    }
    if (this.state.end_order_time) {
      data.end_order_time = this.state.end_order_time;
    }
    data.cj_order_type = this.props.activity_subtype;

    this.props.index === "1" ? this.queryAdminOrderList(data) : this.queryCjAdminOrderList(data);
  };
  onShowTimeChange = (date, dateStr) => {
    this.setState({
      start_order_time: dateStr[0],
      end_order_time: dateStr[1]
    });
  };
  onShowPageChange = page => {
    this.setState(
      {
        page: page
      },
      () => {
        this.filterOrder();
      }
    );
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        page_size: pageSize
      },
      () => {
        this.filterOrder();
      }
    );
  };
  onExport = () => {
    let form = this.props.form.getFieldsValue();
    let url = this.state.request_url + "?shop_id=" + cookie.load("shop_id");
    if (form.order_sn) {
      url += "&order_sn=" + form.order_sn;
    }
    if (form.receiver_name) {
      url += "&receive_name=" + form.receiver_name;
    }
    if (form.mobile) {
      var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (!myreg.test(form.mobile)) {
        return message.error("手机号码格式错误");
      } else {
        url += "&telNumber=" + form.mobile;
      }
    }
    if (form.has_remark && form.has_remark !== 'all') {
      url += '&has_remark=' + `${Boolean(form.has_remark)}`;
    }
    if (form.goods_name) {
      url += "&goods_name=" + form.goods_name;
    }
    if (form.order_shipment_type && form.order_shipment_type !== "all") {
      url += "&order_shipment_type=" + form.order_shipment_type;
    }
    if (form.order_type && form.order_type !== "all") {
      if (form.order_type === 'period_purchase') {
        url = config.baseUrl + "/order/exportPeriodOrder" + "?shop_id=" + cookie.load("shop_id");
      }
      url += "&order_type=" + form.order_type;
    }
    if (form.is_refund && form.is_refund !== "-2") {
      url += "&is_refund=" + form.is_refund;
    }
    if (form.channel) {
      url += '&channel_id=' + form.channel;
    }
    if (this.state.order_status != null && this.state.order_status !== "-2") {
      url += "&order_status=" + this.state.order_status;
    }

    if (this.state.start_order_time) {
      url += "&start_order_time=" + this.state.start_order_time;
    }
    if (this.state.end_order_time) {
      url += "&end_order_time=" + this.state.end_order_time;
    }
    window.open(url);
  };
  onCJExport = () => {
    let form = this.props.form.getFieldsValue();
    let url = this.state.cj_request_url + "?shop_id=" + cookie.load("shop_id");
    if (form.order_sn) {
      url += "&cj_order_sn=" + form.order_sn;
    }
    if (form.receiver_name) {
      url += "&receive_name=" + form.receiver_name;
    }
    if (form.mobile) {
      var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (!myreg.test(form.mobile)) {
        return message.error("手机号码格式错误");
      } else {
        url += "&telNumber=" + form.mobile;
      }
    }
    if (form.has_remark && form.has_remark !== 'all') {
      url += '&has_remark=' + `${Boolean(form.has_remark)}`;
    }
    if (form.goods_name) {
      url += "&goods_name=" + form.goods_name;
    }
    if (form.order_shipment_type && form.order_shipment_type !== "all") {
      url += "&order_shipment_type=" + form.order_shipment_type;
    }
    if (form.is_refund && form.is_refund !== -1) {
      url += "&is_refund=" + form.is_refund;
    }
    if (form.channel) {
      url += '&channel_id=' + form.channel;
    }
    if (this.state.order_status != null && this.state.order_status !== "-2") {
      url += "&order_status=" + this.state.order_status;
    }

    if (this.state.start_order_time) {
      url += "&start_order_time=" + this.state.start_order_time;
    }
    if (this.state.end_order_time) {
      url += "&end_order_time=" + this.state.end_order_time;
    }
    if (this.props.activity_subtype) {
      url += `&cj_order_type=${this.props.activity_subtype}`;
    }
    window.open(url);
  };

  async cancelOrder(data) {
    let res = await cancelOrder(data);
    if (res.success) {
      message.success("成功取消订单");
      this.filterOrder();
    } else {
      message.error(res.msg);
    }
  }
  clearFilter = () => {
    this.props.form.resetFields();
  };
  goToClient = client => {
    this.context.router.history.push("/client", client.user_id);
  };
  goBalanceManage() {
    this.setState({
      visible: false,
      goodsRemindVisible: false,
    }, () => {
      this.context.router.history.push("/balanceManage");
    });
  }
  async queryChannelSelection() {
    const url = '/channel/queryChannelSelection';
    // const url = '/channel/queryChannelList ';
    let data = {
      mini_app_id: cookie.load("mini_app_id"),
      page: this.state.page,
      page_size: this.state.page_size,
    }
    let res = await getData(url, data);
    if (res.success) {
      const channelSearchOptions = res.result.channel_res_list.map((item) => {
        return {
          channel_id: item.channel_id,
          channel_name: item.channel_name,
        }
      });
      this.setState({
        channelSearchOptions,
      });
    }
  }
  componentDidMount() {
    this.queryChannelSelection();
    let activity_id = getQueryString("activity_id");
    if (this.props.order_status) {
      this.setState({
        order_status: this.props.order_status
      });
    }
    if (this.props.activity_subtype === 'rank_lucky') {
      const arr = this.state.cj_columns.filter((cj) => cj.dataIndex !== 'order_desc');
      this.setState({
        cj_columns: arr,
      });
    }
    this.setState(
      {
        activity_id
      },
      () => {
        this.filterOrder();
      }
    );
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Spin spinning={this.state.loading} tip="加载中…">
        <div className="content order-list-content">
          <div className="filter-content">
            <Form className="ant-vertical" layout="inline">
              <Form.Item label="订单号">
                {getFieldDecorator("order_sn")(
                  <Input className="goods-id" placeholder="输入订单号" />
                )}
              </Form.Item>
              <Form.Item label="收货人">
                {getFieldDecorator("receiver_name")(
                  <Input
                    className="goods-id"
                    placeholder="输入收货人姓名"
                    prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                  />
                )}
              </Form.Item>
              <Form.Item label="手机号 ">
                {getFieldDecorator("mobile")(
                  <Input
                    className="goods-id"
                    placeholder="输入收货手机号码"
                    prefix={<Icon type="mobile" style={{ color: "rgba(0,0,0,.25)" }} />}
                  />
                )}
              </Form.Item>
              <Form.Item label="有无备注">
                {getFieldDecorator("has_remark")(
                  <Select className="goods-id">
                    <Select.Option value="all">全部</Select.Option>
                    <Select.Option value={1}>有备注</Select.Option>
                    <Select.Option value={0}>无备注</Select.Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="下单时间">
                {getFieldDecorator("date")(
                  <RangePicker allowClear={true} showTime={true} transferValue={this.onShowTimeChange} />
                )}
              </Form.Item>
              <Form.Item label="商品名称">
                {getFieldDecorator("goods_name")(<Input className="goods-name" placeholder="输入商品名称" />)}
              </Form.Item>
              <Form.Item label="订单类型：" style={{ display: this.props.index === "1" ? true : "none" }}>
                {getFieldDecorator("order_type")(
                  <Select className="goods-id">
                    <Select.Option value="all">全部</Select.Option>
                    <Select.Option value="points">积分兑换订单</Select.Option>
                    <Select.Option value="general">普通订单</Select.Option>
                    <Select.Option value="free_order">抢免单订单</Select.Option>
                    <Select.Option value="lucky">抽奖订单</Select.Option>
                    <Select.Option value="gift_card">权益卡订单</Select.Option>
                    <Select.Option value="rank_lucky">拼团订单</Select.Option>
                    <Select.Option value="period_purchase">周期购订单</Select.Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="配送方式：" style={{ display: this.props.index === "1" ? true : "none" }}>
                {getFieldDecorator("order_shipment_type")(
                  <Select className="goods-id">
                    <Select.Option value="all">全部</Select.Option>
                    <Select.Option value="self_shipment">快递邮寄</Select.Option>
                    <Select.Option value="group_shipment">到店自提</Select.Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="是否售后：" style={{ display: this.props.index === "1" ? true : "none" }}>
                {getFieldDecorator("is_refund")(
                  <Select className="goods-id">
                    <Select.Option value="-1">全部</Select.Option>
                    <Select.Option value="1">是</Select.Option>
                    <Select.Option value="0">否</Select.Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="订单渠道" style={{ display: this.props.index === "1" ? true : "none" }}>
                {getFieldDecorator('channel')(
                  <Select className="goods-id">
                    {this.state.channelSearchOptions.map((item, index) => (
                      <Select.Option key={index} value={item.channel_id}>{item.channel_name}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Form>
            <div className="action">
              <Button shape="round" type="primary" onClick={this.onShowPageChange.bind(this, 1)}>
                筛选
              </Button>
              <Button shape="round" onClick={this.props.index === "1" ? this.onExport : this.onCJExport}>
                导出
              </Button>
              <span className="text-note" onClick={this.clearFilter}>
                清空筛选条件
              </span>
            </div>
          </div>
          <Tabs
            defaultActiveKey={this.props.order_status || "-2"}
            onChange={this.handleChangeOrderStatus}
            style={{ display: this.props.index === "1" ? true : "none" }}>
            <Tabs.TabPane tab="全部" key="-2"></Tabs.TabPane>
            <Tabs.TabPane tab="待付款" key="0"></Tabs.TabPane>
            <Tabs.TabPane tab="待配送" key="1"></Tabs.TabPane>
            <Tabs.TabPane tab="已发货" key="2"></Tabs.TabPane>
            <Tabs.TabPane
              style={{ display: cookie.load("mini_app_type") === "flagship_version" ? "none" : true }}
              tab="待自提"
              key="3"></Tabs.TabPane>
            <Tabs.TabPane tab="已完成" key="4"></Tabs.TabPane>
            <Tabs.TabPane tab="已取消" key="5"></Tabs.TabPane>
          </Tabs>
          <Tabs defaultActiveKey="-2" onChange={this.handleChangeOrderStatus} style={{ display: this.props.index === "2" ? true : "none" }}>
            <Tabs.TabPane tab="全部" key="-2"></Tabs.TabPane>
            <Tabs.TabPane tab="活动中" key="1"></Tabs.TabPane>
            <Tabs.TabPane tab="已完成" key="2"></Tabs.TabPane>
            <Tabs.TabPane tab="已失效" key="-1"></Tabs.TabPane>
          </Tabs>
          {this.props.index === "1" ? (
            this.state.order_res_list && this.state.order_res_list.length ? (
              <Table
                rowKey={(record, index) => record.order_sn}
                columns={this.state.columns}
                dataSource={this.state.order_res_list}
                pagination={false}
                defaultExpandAllRows={true}
                expandIconAsCell={false}
                expandIconColumnIndex={-1}
                expandedRowRender={record => {
                  return record.show_remark && record.remark ? (
                    <div className="remark-line" style={{ margin: 0 }}>
                      <div className="remark-text">买家备注:{record.remark}</div>
                    </div>
                  ) : null;
                }}
              />
            ) : null
          ) : (
              <Table
                rowKey={(record, index) => index}
                columns={this.props.activity_subtype === "free_order" ? this.state.cj_free_order_columns : this.state.cj_columns}
                dataSource={this.state.orderList}
                pagination={false}
                bordered={true}
              />
            )}
          <Pagination
            className="pagenation"
            onChange={this.onShowPageChange}
            onShowSizeChange={this.onShowSizeChange}
            defaultCurrent={this.state.page}
            total={this.state.total}
            showTotal={total => `共 ${total} 条`}
            pageSizeOptions={["10", "30", "50", "100"]}
            showSizeChanger
          />
          <SelfShipmentModal
            canClick={this.state.canClick}
            visible={this.state.visible}
            handleSelfOk={this.confirmShipment}
            handleSelfCancel={this.hideModal}
            order={this.state.order}
          />
          <Modal
            visible={this.state.goodsRemindVisible}
            title='温馨提醒'
            cancelText='关闭'
            okText='去补库存'
            onOk={this.goBalanceManage.bind(this)}
            onCancel={() => this.setState({ goodsRemindVisible: false })}
          >
            <span>进销存不足，快去补充吧～</span>
          </Modal>
          <Modal
            visible={this.state.periodVisible}
            title="发货记录"
            footer={null}
            onCancel={() => this.setState({ periodVisible: false })}
          >
            <Table
              rowKey={(record, index) => index}
              columns={this.state.periodColumns}
              dataSource={this.state.releaseRecords}
            />
          </Modal>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu_code: state.menuCode,
    order_status_list: state.orderStatusList,
    cj_order_status_list: state.cjOrderStatusList,
    refund_status_list: state.refundStatusList
  };
};

const OrderForm = Form.create({ name: "order_form" })(Order);

export default connect(mapStateToProps)(OrderForm);
