import React from 'react'
import '@css/goodsDetailSetting.scss'
import {Table,Switch} from 'antd'
import {queryMiniAppPageConfigList,queryPageConfigList,createOrUpdatePageConfig,createOrUpdateMiniAppPageConfig} from '@service/getData'
import cookie from 'react-cookies'
class GoodsDetailSetting extends  React.Component{
    constructor(){
        super();
        this.state={
            columns:[
                {
                    title: "序列",
                    dataIndex: "page_config_id"
                },{
                    title: "可配置项",
                    dataIndex: "config_desc"
                },
                {
                    title: '个体户版',
                    dataIndex: 'base_version',

                    render:(text,record)=>(
                        <Switch
                            checked={Boolean(record.base_version)}
                            onChange={this.createOrUpdatePageConfig.bind(this,"base_version",record)} />
                    )
                },
                {
                    title: '商家版',
                    dataIndex: 'special_version',
                    render:(text,record)=>(
                        <Switch
                            checked={Boolean(record.special_version)}
                            onChange={this.createOrUpdatePageConfig.bind(this,"special_version",record)} />
                    )
                },
                {
                    title: '品牌版',
                    dataIndex: 'flagship_version',
                    render:(text,record)=>(
                        <Switch
                            checked={Boolean(record.flagship_version)}
                            onChange={this.createOrUpdatePageConfig.bind(this,"flagship_version",record)} />
                    )
                }
            ],
            user_columns:[{
                title: "序列",
                dataIndex: "page_config_id"
            },{
                title: "可配置项",
                dataIndex: "config_desc"
            },{
                title: "操作",
                dataIndex: "action",
                render:(text,record)=>(
                    <Switch
                        checked={Boolean(record.effect)}
                        onChange={this.createOrUpdateMiniAppPageConfig.bind(this,record.mini_app_page_config_id)} />
                )

            }],
            data:[]

        }
    }
    queryMiniAppPageConfigList =async ()=>{
        let data={
            mini_app_id:cookie.load("mini_app_id"),
            config_type:"goodsDetail"
        }
        let res=await queryMiniAppPageConfigList(data);
        if(res.success){
            this.setState({
                data:res.result.mini_app_page_config_res_list
            })
        }

    }

    queryPageConfigList=async ()=>{
        let res=await queryPageConfigList({})
        if(res.success){
            this.setState({
                data:res.result.page_config_res_list
            })
        }
    }
    createOrUpdatePageConfig= async (index,record,bol)=>{
        let data={
            page_config_req:{
                page_config_id:record.page_config_id

            }

        }
        data.page_config_req[index]=bol?1:0;
        let res=await createOrUpdatePageConfig(data);
        if(res.success){
            this.queryPageConfigList()
        }
    }
    createOrUpdateMiniAppPageConfig=async (mini_app_page_config_id,bol)=>{
        let data={
            mini_app_page_config_req:{
                mini_app_page_config_id:mini_app_page_config_id,
                effect:bol?1:0
            }
        }
        let res=await createOrUpdateMiniAppPageConfig(data)
        if(res.success){
            this.queryMiniAppPageConfigList()
        }

    }

    componentDidMount(){
        if(this.props.is_admin==="1"){
            this.queryPageConfigList()
        }else{
            this.queryMiniAppPageConfigList()
        }
    }
    render(){
        return(
            <div className="goods-detail-setting">
                <Table
                    rowKey={(record, index) => index}
                    dataSource={this.state.data}
                    columns={this.props.is_admin==="1"?this.state.columns:this.state.user_columns}
                    pagination={false}

                />
            </div>
        )
    }
}
export  default GoodsDetailSetting