import React, { Component } from 'react';
import '../scss/GoodsPoster.scss';

class GoodsPoster extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="goodsPosterWrap">
                <img className="poster" width="320" height="320" src={this.props.posterImg} alt="商品图片"/>
                <div className="goodsInfos">
                    <div className="infosLeft">
                        <p className="title">
                            {this.props.title}
                        </p>
                        <p className="price">
                            <span className="bold">&yen;</span>
                            <span>
                                {this.props.price / 100}
                            </span>
                        </p>
                        <p className="originPrice">
                            <span className="bold">&yen;</span>
                            <span>
                                {this.props.originalPrice / 100}
                            </span>
                        </p>
                    </div>
                    <div className="infosRight">
                        <img width="100" height="100" src={this.props.url} alt=""/>
                        <p className="tips">长按打开小程序购买</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default GoodsPoster;