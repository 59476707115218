import React from "react";
import { Form, Button, Modal, Icon, message, Upload, Card, Input } from "antd/lib/index";
import "@css/activity.scss";
import UploadButton from "@/view/component/common/UploadButton";
import cookie from "react-cookies";
import {
  createOrUpdateActivity,
  exchangeActivityGoodsSortNum,
  queryActivityDetail,
  queryGoodsList,
  topActivityGoods
} from "@service/getData";
import config from "@config/config";

class BannerContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      loading2: false,
      requestUrl: config.baseUrl + "/ossFile/upload",
      columns: [
        {
          title: "商品Id",
          dataIndex: "goods_id"
        },
        {
          title: "商品图片",
          dataIndex: "thumbnail_url",
          render: (text, record) => (
            <div>
              <img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="100" height="100" />
            </div>
          )
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          render: text => <div>￥{text}</div>
        },
        {
          title: "操作",
          dataIndex: "action",
          render: (text, record, index) => (
            <div>
              {index !== 0 ? (
                this.props.exchange_disabled && this.props.activity_id ? (
                  <Icon className="text-remind mr15" type="arrow-up" />
                ) : (
                    <Icon className="text-note mr15" onClick={this.exchangeGoodsPosition.bind(this, index, -1)} type="arrow-up" />
                  )
              ) : null}
              {index !== this.props.activity_goods_list.length - 1 ? (
                this.props.exchange_disabled && this.props.activity_id ? (
                  <Icon className="text-remind" type="arrow-down" />
                ) : (
                    <Icon onClick={this.exchangeGoodsPosition.bind(this, index, 1)} className="text-note" type="arrow-down" />
                  )
              ) : null}
              {index !== 0 ? (
                this.props.exchange_disabled && this.props.activity_id ? (
                  <p className="text-remind" type="arrow-up">
                    置顶
                  </p>
                ) : (
                    <p className="text-note" onClick={this.exchangeGoodsPosition.bind(this, index, 0)} type="arrow-up">
                      置顶
                    </p>
                  )
              ) : null}
              <p onClick={this.deleteGoods.bind(this, record, index)} className="text-note">
                删除
              </p>
            </div>
          )
        }
      ],
      campaign_activity_subtype_res_list: [],
      visible: false,
      search_value: null,
      goods_columns: [
        {
          title: "商品id",
          dataIndex: "goods_id"
        },
        {
          title: "商品图片",
          dataIndex: "thumbnail_url",
          render: (text, record) => (
            <div>
              <img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="100" height="100" />
            </div>
          )
        },
        {
          title: "商品名称",
          dataIndex: "goods_name"
        },
        {
          title: "价格",
          dataIndex: "current_price",
          render: text => <div>￥{text / 100}</div>
        }
      ],
      goods_res_list: [],
      selected_row_keys: [],
      select_goods_res: [],
      current_select_goods_res: [],
      start_time: null,
      end_time: null,
      activity_id: null,
      activity_res: {},
      exchange_disabled: false,
      preview_image: "",
      preview_gallery_image: "",
      review_gallery_image2: "",
      file_list: [],
      file_list2: [],
      gallery_list: [],
      gallery_list2: [],
      preview_gallery_visible: false,
      preview_gallery_visible2: false,
      show_another: ["advertisement", "topScroll", "endScroll"],
      loading: false,
      campaign_id: null,
      campaign_list: [],
      target_type_desc: {
        lucky: "抽奖活动",
        checkin: "签到",
        pointsStore: "积分商城",
        free_order: "抢免单",
        rank_lucky: '线上拼团',
      },
    };
  }

  createOrUpdateActivity = async data => {
    this.setState({
      loading: true
    });
    let res = await createOrUpdateActivity(data);

    if (res.success) {
      this.props.form.resetFields();
      if (this.state.type === "edit") {
        message.success("组件更新成功");
      } else {
        message.success("成功添加组件");
      }
      this.setState(
        {
          loading: false,
          visible: false,
          search_value: null,
          goods_res_list: [],
          selected_row_keys: [],
          select_goods_res: [],
          current_select_goods_res: [],
          start_time: null,
          end_time: null,
          activity_res: {},
          exchange_disabled: false,
          gallery_list: [],
          gallery_list2: []
        },
        () => {
          this.props.onOk();
          this.props.form.resetFields();
        }
      );
    } else {
      message.error(res.msg);
    }
  };
  queryActivityDetail = async () => {
    let data = {
      activity_id: this.props.activity_id
    };
    let res = await queryActivityDetail(data);
    if (res.success) {
      let select_goods_res = res.result.activity_res.activity_goods_res_list.map(item => {
        return {
          goods_id: item.goods_res.goods_id,
          goods_name: item.goods_res.goods_name,
          current_price: item.goods_res.current_price,
          start_time: item.start_time,
          end_time: item.end_time,
          activity_id: item.activity_id,
          activity_goods_id: item.activity_goods_id,
          status: 1,
          gallery_urls: item.goods_res.gallery_urls,
          loading: false
        };
      });
      this.props.submitForm(this.props.index, "activity_goods_list", select_goods_res);
    }
  };
  queryGoodsList = async () => {
    this.setState({
      loading2: true
    });
    let data = {
      shop_id: cookie.load("shop_id"),
      onsale: 1
    },
      value = this.state.search_value;
    if (isNaN(+value)) {
      data.goods_name = value;
    } else {
      data.goods_id = value;
    }
    let res = await queryGoodsList(data);
    if (res.success) {
      this.setState({
        loading2: false,
        goods_res_list: res.result.goods_res_list
      });
    }
  };
  exchangeActivityGoodsSortNum = async (index, isUp) => {
    let list = [...this.state.select_goods_res],
      from_activity_goods_id = list[index].activity_goods_id,
      to__activity_goods_id;

    if (isUp < 0) {
      to__activity_goods_id = list[index - 1].activity_goods_id;
    } else {
      to__activity_goods_id = list[index + 1].activity_goods_id;
    }
    let res = await exchangeActivityGoodsSortNum({
      from_activity_goods_id,
      to__activity_goods_id
    });
    if (res.success) {
      message.success("操作成功");
      this.queryActivityDetail();
    }
  };
  topActivityGoods = async activity_goods_id => {
    let res = await topActivityGoods({ activity_goods_id });
    if (res.success) {
      message.success("置顶成功");
      this.queryActivityDetail();
    }
  };
  handleCancel = () => this.setState({ visible: false });
  handleOk = () => {
    let select_goods_res = [...this.props.activity_goods_list],
      current_select_goods_res = [...this.state.current_select_goods_res];
    select_goods_res.forEach(item => {
      let index = current_select_goods_res.findIndex(row => row.goods_id === item.goods_id);
      if (index > -1) {
        current_select_goods_res.splice(index, 1);
      }
    });
    select_goods_res = select_goods_res.concat(current_select_goods_res);
    this.setState({
      visible: false
    });
    this.props.submitForm(this.props.index, "activity_goods_list", select_goods_res);
    if (this.props.activity_id) {
      this.props.submitForm(this.props.index, "exchange_disabled", true);
    }
  };
  searchHandle = e => {
    this.setState({
      search_value: e.target.value
    });
  };
  showModal = () => {
    this.queryGoodsList();
    this.setState({
      visible: true
    });
  };
  onSelectedRowKeysChange = (selected_row_keys, record) => {
    this.setState(
      {
        selected_row_keys: selected_row_keys,
        current_select_goods_res: record
      },
      () => {
        if (record.length === 1 && this.props.target_type === "goods") {
          this.handleOk();
        }
      }
    );
  };
  exchangeGoodsPosition = async (index, num) => {
    let list = [...this.props.activity_goods_list];
    let goods = list[index];
    if (this.props.activity_id && +num === 0) {
      this.topActivityGoods(goods.activity_goods_id);
    } else if (this.props.activity_id) {
      this.exchangeActivityGoodsSortNum(index, num);
    }

    if (num === -1) {
      if (index === 0) {
        return;
      }
      list.splice(index - 1, 1, ...list.splice(index, 1, list[index - 1]));
    } else if (num === 1) {
      if (index === list.length - 1) {
        return;
      }
      list.splice(index, 1, ...list.splice(index + 1, 1, list[index]));
    } else {
      if (index === 0) {
        return;
      }
      list.splice(0, 0, ...list.splice(index, 1));
    }
    this.props.submitForm(this.props.index, "activity_goods_list", list);
  };
  deleteGoods = goods => {
    let select_goods_res = [...this.state.select_goods_res];
    let index = select_goods_res.findIndex(item => item.goods_id === goods.goods_id);
    if (goods.activity_goods_id) {
      select_goods_res[index].status = 0;
    } else {
      select_goods_res.splice(index, 1);
    }
    this.setState(
      {
        select_goods_res,
        exchange_disabled: true
      },
      () => {
        this.props.submitForm(this.props.index, "activity_goods_list", select_goods_res);
      }
    );
  };
  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  handleGalleryCancel = () => {
    this.setState({
      preview_gallery_visible: false,
      preview_gallery_visible2: false
    });
  };
  handleGalleryChange = ({ fileList }) => {
    this.props.submitForm(this.props.index, "gallery_list", fileList);
  };
  handleGalleryPreview = file => {
    this.setState({
      preview_gallery_image: file.url || file.thumbUrl,
      preview_gallery_visible: true
    });
  };
  handleGalleryChange2 = ({ fileList }) => {
    this.props.submitForm(this.props.index, "gallery_list2", fileList);
  };
  handleGalleryPreview2 = file => {
    this.setState({
      preview_gallery_image2: file.url || file.thumbUrl,
      preview_gallery_visible2: true
    });
  };
  onChange = (key, value) => {
    this.props.submitForm(this.props.index, key, value);
  };
  onActivityName = e => {
    this.onChange("activity_name", e.target.value);
  };
  render() {
    return (
      <Card
        className="card-info"
        title={
          <span>
            {this.props.title + (+this.props.list_key + 1)}
            <span
              style={{ display: this.props.index === 0 || this.props.activity_subtype === "advertisement" ? "none" : true }}
              className="text-note"
              onClick={this.props.eidtActivityList.bind(this, this.props.index, 0, 1)}>
              删除
            </span>
          </span>
        }
        extra={
          <span>
            {this.props.list_key !== 0 ? (
              <i className="iconfont icon-shang-copy-copy" onClick={this.props.sortActivityList.bind(this, this.props.index, -1)} />
            ) : null}
            {this.props.list_key !== this.props.length - 1 ? (
              <i className="iconfont icon-xia" onClick={this.props.sortActivityList.bind(this, this.props.index, 1)} />
            ) : null}
            {this.props.list_key !== 0 ? (
              <i className="iconfont icon-zhiding1" onClick={this.props.sortActivityList.bind(this, this.props.index, 0)} />
            ) : null}
          </span>
        }>
        <Form>
          {this.props.activity_subtype === "guide" ? (
            <Form.Item label="导航图标">
              {!this.props.thumbnail_url ? (
                <span className="text-note" onClick={this.props.showIconChooseModal.bind(this, this.props.index)}>
                  选择图标
                </span>
              ) : (
                  <span>
                    <span>
                      <span className="icon-box">
                        <i className={`iconfont ${this.props.image_url}`} />
                      </span>
                    </span>
                    <span className="text-note" onClick={this.props.showIconChooseModal.bind(this, this.props.index)}>
                      修改
                  </span>
                  </span>
                )}
            </Form.Item>
          ) : (
              <Form.Item label={this.props.uploadText}>
                <div className="image-upload">
                  <Upload
                    accept="image/jpeg,image/jpg,image/png"
                    withCredentials={true}
                    action={this.state.requestUrl}
                    beforeUpload={this.beforeUpdate}
                    listType="picture-card"
                    fileList={this.props.gallery_list}
                    onPreview={this.handleGalleryPreview}
                    onChange={this.handleGalleryChange}>
                    {this.props.gallery_list && this.props.gallery_list.length >= 1 ? null : <UploadButton />}
                  </Upload>
                  <div className="text-remind">
                    {this.props.activity_subtype === 'vip'
                      ? '建议比例1:1，仅支持jpg,png 2种格式,单张图片大小不超过3.0MB'
                      : this.props.activity_subtype !== "iconLinkList"
                        ? "建议宽度375，高度不限，仅支持jpg,png 2种格式,单张图片大小不超过3.0MB"
                        : "图片建议尺寸38px，大小不超过3MB"}
                  </div>
                  <Modal visible={this.state.preview_gallery_visible} footer={null} onCancel={this.handleGalleryCancel}>
                    <img alt="example" style={{ width: "100%" }} src={this.state.preview_gallery_image} />
                  </Modal>
                </div>
              </Form.Item>
            )}

          {this.props.activity_subtype === "iconLinkList" || this.props.activity_subtype === "guide" || this.props.activity_subtype === "vip" ? (
            <Form.Item label={this.props.activity_subtype === "vip" ? '服务标题' : "导航标题"}>
              <Input maxLength={this.props.activity_subtype === "vip" ? 5 : 4} value={this.props.activity_name} onChange={this.onActivityName} />
            </Form.Item>
          ) : null}
          <Form.Item label={<span>{this.props.activity_subtype === "roomInfoList" ? "选择直播" : "跳转路径"}</span>}>
            {this.props.target_type === "" || this.props.target_type == null || this.props.target_type === "goodsList" ? (
              <Button className="btn-choose-targettype" onClick={this.props.showTargetTypeModal.bind(this, this.props.index)}>
                {this.props.activity_subtype === "roomInfoList" ? "选择直播" : "跳转路径"}
              </Button>
            ) : (
                <span>
                  <span className="target-type-show">
                    <span className="target-type-show-title"> {this.props.desc}</span>
                    <Icon type="close-circle" theme="filled" onClick={this.props.deleteTargetType.bind(this, this.props.index)} />
                  </span>
                  <span className="text-note" onClick={this.props.showTargetTypeModal.bind(this, this.props.index)}>
                    修改
                  </span>
                </span>
              )}
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
const bannerContentForm = Form.create({ name: "banner" })(BannerContent);

export default bannerContentForm;
