/*
* 时间范围选择组件
* 具体可选择到秒
* 支持快速删选
* “今天0点到现在”
* “昨天0点到24点”
* “近7天”
* “近30天”
*/
import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';

// 从某天0点到某天24点
const getBeforedays = (bs, be) => {
    // 减法 moment.subtract(number, String)
    const startDate = moment().subtract(bs, 'days').startOf('day');
    const endDate = moment().subtract(be, 'days').endOf("day");
    return [startDate, endDate];
}
class RangePicker extends React.Component {
    // 选择结束，向父组件传递值
    onchange(date, dateStr) {
        this.props.transferValue(date, dateStr)
    }
    render() {
        return (
            <div className="pickerWrap">
                <DatePicker.RangePicker
                locale={locale}
                showTime={this.props.showTime}
                allowClear={this.props.allowClear}
                ranges={{
                    "今天": [moment().startOf("day"), moment()],
                    "昨天": getBeforedays(1, 1),
                    "近7天": getBeforedays(7, 1),
                    "近30天": getBeforedays(30, 1),
                }}
                onChange={this.onchange.bind(this)}
                />
            </div>
        )
    }
}

export default RangePicker;