import React from "react";
import { Carousel } from "antd";
import "@css/campaignShow.scss";
// swiper.js
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// install Swiper components
SwiperCore.use([Autoplay, Pagination])

// 删除派生state,使组件完全受控。
// https://zh-hans.reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html
class CampaignBanner extends React.Component {
  render() {
    return (
      <div className="campain-banner">
        {this.props.pattern_type === 1 ? (
          <Carousel autoplay={true}>
            {this.props.url_list.map((item, index) => (
              <div className="banner-box" key={index}>
                <img
                  className="banner-pic"
                  src={item}
                />
              </div>
            ))}
            {this.props.url_list.length === 0
              ? this.props.default_url.map((item, index) => <img className="banner-pic" key={index} src={item} alt="图片" />)
              : null}
          </Carousel>
        ) : (
            <Swiper
              slidesPerView={1}
              grabCursor={true}
              autoplay={true}
              loop={true}
              pagination={{ clickable: true }}
              loopAdditionalSlides={2}
              updateOnImagesReady={true}
            >
              {this.props.url_list.map((item, index) => (
                <SwiperSlide key={index}>
                  <img
                    className="banner-pic-swiper"
                    src={item}
                  />
                </SwiperSlide>
              ))}
              {this.props.url_list.length === 0
                ? this.props.default_url.map((item, index) => (
                  <SwiperSlide key={index}>
                    <img className="banner-pic" src={item} alt="图片" />
                  </SwiperSlide>
                ))
                : null}
            </Swiper>
          )}
      </div>
    );
  }
}
export default CampaignBanner;
