import React from 'react'
import { Modal, Checkbox, Tooltip } from 'antd'
import { queryRegionDetail } from '@service/getData'
import '@css/checkAddress.scss'

class CheckAddress extends React.Component {
    constructor() {
        super()
        this.state = {
            checkedList: [],
            areaData: [],
            areaList: [],
            provinceList: [],
            cityList: [],
            districtList: []
        }
    }
    onChange = (checkedList, pronvinceList) => {
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < pronvinceList.length,
            checkAll: checkedList.length === pronvinceList.length,
        });
    };

    async queryRegionDetail() {
        let res = await queryRegionDetail({})
        let areaData = res.result.area_res_list;
        areaData.forEach(item => {
            item.region_res_list.forEach(province => {
                province.area_id = item.area_id;
                province.region_res_list.forEach(city => {
                    city.area_id = item.area_id;
                    city.province_id = province.region_id;
                    city.region_res_list.forEach(district => {
                        district.area_id = item.area_id;
                        district.province_id = province.region_id;
                        district.city_id = city.region_id
                    })
                })
            })
        })
        if (res.success) {
            this.setState({
                areaData: areaData
            })
        }
    }

    onCheckArea = (area_id, e) => {
        let checked = e.target.checked;
        let areaList = this.state.areaList.concat([]);
        let currentAreaList = this.state.areaData.filter(item =>
            item.area_id === area_id
        )
        if (checked) {
            areaList = areaList.concat(currentAreaList)
        } else {
            let index = areaList.findIndex(item => item.area_id === area_id)


            areaList.splice(index, 1)
        }
        let provinceList = this.state.provinceList.filter(item => item.area_id !== area_id)
        let cityList = this.state.cityList.filter(item => item.area_id !== area_id)
        let districtList = this.state.districtList.filter(item => item.area_id !== area_id)
        this.setState({
            areaList: areaList,
            provinceList: provinceList,
            cityList: cityList,
            districtList: districtList
        })
    }
    onCheckProvince = (area_id, region_id, e) => {
        let checked = e.target.checked;
        let area = this.state.areaData.find(item =>
            item.area_id === area_id
        )
        let province = area.region_res_list.find(item => item.region_id === region_id)
        let sameareaProvinceList = this.state.provinceList.filter(item => item.area_id === area_id)
        if (checked) {
            if (sameareaProvinceList.length + 1 === area.region_res_list.length) {
                let areaList = this.state.areaList.concat([area])
                let provinceList = this.state.provinceList.filter(item => item.area_id !== area_id)
                this.setState({
                    areaList: areaList,
                    provinceList: provinceList
                })
            } else {
                let provinceList = this.state.provinceList.concat([province])
                let cityList = this.state.cityList.filter(item => item.parent_id !== region_id)
                let districtList = this.state.districtList.filter(item => item.province_id !== region_id)

                this.setState({
                    provinceList,
                    cityList: cityList,
                    districtList: districtList
                })
            }
        } else {
            let currentArea = this.state.areaList.findIndex(item => item.area_id === area_id)
            if (currentArea > -1) {
                let areaList = this.state.areaList.filter(item => item.area_id !== area_id)
                let cityList = area.region_res_list.filter(item => item.region_id !== region_id)
                cityList = this.state.provinceList.concat(cityList)
                this.setState({
                    areaList: areaList,
                    provinceList: cityList
                })
            } else {
                let provinceList = this.state.provinceList.filter(item => item.region_id !== region_id)
                this.setState({
                    provinceList: provinceList
                })

            }
        }
        let cityList = this.state.cityList.filter(item => item.province_id !== region_id)
        let districtList = this.state.districtList.filter(item => item.province_id !== region_id)
        this.setState({
            districtList: districtList,
            cityList: cityList
        })

    }
    onCheckCity = (area_id, province_id, city_id, e) => {
        let checked = e.target.checked;
        let currentArea = this.state.areaData.find(item => item.area_id === area_id);
        let currrentProvince = currentArea.region_res_list.find(item => item.region_id === province_id)
        let currentCity = currrentProvince.region_res_list.find(item => item.region_id === city_id)
        let sameProvinceCityList = this.state.cityList.filter(item => item.parent_id === province_id);
        let sameAreaProvinceList = this.state.provinceList.filter(item => item.area_id === area_id)
        if (checked) {
            if (sameProvinceCityList.length + 1 === currrentProvince.region_res_list.length) {
                if (sameAreaProvinceList.length + 1 === currentArea.region_res_list.length) {
                    let areaList = this.state.areaList.concat([currentArea])
                    let provinceList = this.state.provinceList.filter(item => item.area_id !== area_id)
                    let cityList = this.state.cityList.filter(item => item.area_id !== area_id)
                    this.setState({
                        areaList: areaList,
                        provinceList: provinceList,
                        cityList: cityList
                    })
                } else {
                    let provinceList = this.state.provinceList.concat([currrentProvince])
                    let cityList = this.state.cityList.filter(city => city.province_id !== province_id)
                    this.setState({
                        cityList: cityList,
                        provinceList: provinceList
                    })
                }
            } else {
                let cityList = this.state.cityList.concat([currentCity])
                this.setState({
                    cityList: cityList
                })
            }
        } else {
            let areaIndex = this.state.areaList.findIndex(item => item.area_id === area_id)
            let provinceIndex = this.state.provinceList.findIndex(item => item.region_id === province_id)
            if (areaIndex > -1) {
                let areaList = this.state.areaList.filter(item => item.area_id !== area_id)
                let provinceList = currentArea.region_res_list.filter(item => item.region_id !== province_id)
                provinceList = this.state.provinceList.concat(provinceList)
                let cityList = currrentProvince.region_res_list.filter(item => item.region_id !== city_id)
                cityList = this.state.cityList.concat(cityList)
                this.setState({
                    areaList: areaList,
                    provinceList: provinceList,
                    cityList: cityList

                })
            } else if (provinceIndex > -1) {
                let provinceList = this.state.provinceList.filter(item => item.region_id !== province_id)
                let cityList = currrentProvince.region_res_list.filter(item => item.region_id !== city_id)
                cityList = this.state.cityList.concat(cityList)
                this.setState({
                    provinceList: provinceList,
                    cityList: cityList
                })
            } else {
                let cityList = this.state.cityList.filter(item => item.region_id !== city_id)
                this.setState({
                    cityList: cityList
                })
            }
        }
        let districtList = this.state.districtList.filter(item => item.city_id !== city_id)
        this.setState({
            districtList: districtList
        })

    }
    onCheckDistrict = (area_id, province_id, city_id, district_id, e) => {
        let checked = e.target.checked;
        let currentArea = this.state.areaData.find(item => item.area_id === area_id);
        let currrentProvince = currentArea.region_res_list.find(item => item.region_id === province_id)
        let currentCity = currrentProvince.region_res_list.find(item => item.region_id === city_id)
        let currentDistrict = currentCity.region_res_list.find(item => item.region_id === district_id)
        let sameCityDistrict = this.state.districtList.filter(item => item.parent_id === city_id)
        let sameProvinceCityList = this.state.cityList.filter(item => item.parent_id === province_id);
        let sameAreaProvinceList = this.state.provinceList.filter(item => item.area_id === area_id)
        if (checked) {
            if (sameCityDistrict.length + 1 === currentCity.region_res_list.length) {
                if (sameProvinceCityList.length + 1 === currrentProvince.region_res_list.length) {
                    if (sameAreaProvinceList.length + 1 === currentArea.region_res_list.length) {
                        let areaList = this.state.areaList.concat([currentArea])
                        let provinceList = this.state.provinceList.filter(item => item.area_id !== area_id)
                        let cityList = this.state.cityList.filter(item => item.parent_id !== province_id)
                        let districtList = this.state.districtList.filter(item => item.parent_id !== city_id)
                        this.setState({
                            areaList: areaList,
                            provinceList: provinceList,
                            cityList: cityList,
                            districtList: districtList
                        })
                    } else {
                        let provinceList = this.state.provinceList.concat([currrentProvince])
                        let cityList = this.state.cityList.filter(item => item.parent_id !== province_id)
                        let districtList = this.state.districtList.filter(item => item.parent_id !== city_id)
                        this.setState({
                            provinceList: provinceList,
                            cityList: cityList,
                            districtList: districtList
                        })
                    }

                } else {
                    let cityList = this.state.cityList.concat([currentCity])
                    let districtList = this.state.districtList.filter(item => item.parent_id !== city_id)
                    this.setState({
                        cityList: cityList,
                        districtList: districtList
                    })
                }
            } else {
                let districtList = this.state.districtList.concat([currentDistrict])
                this.setState({
                    districtList: districtList
                })
            }
        } else {
            let areaIndex = this.state.areaList.findIndex(item => item.area_id === area_id)
            let provinceIndex = this.state.provinceList.findIndex(item => item.region_id === province_id)
            let cityIndex = this.state.cityList.findIndex(item => item.region_id === city_id)
            if (areaIndex > -1) {
                let areaList = this.state.areaList.filter(item => item.area_id !== area_id)
                let provinceList = currentArea.region_res_list.filter(item => item.region_id !== province_id)
                provinceList = provinceList.concat(this.state.provinceList)
                let cityList = currrentProvince.region_res_list.filter(item => item.region_id !== city_id)
                cityList = cityList.concat(this.state.cityList)
                let districtList = currentCity.region_res_list.filter(item => item.region_id !== district_id)
                districtList = districtList.concat(this.state.districtList)
                this.setState({
                    areaList: areaList,
                    provinceList: provinceList,
                    cityList: cityList,
                    districtList: districtList
                })

            } else if (provinceIndex > -1) {
                let provinceList = this.state.provinceList.filter(item => item.region_id !== province_id)
                let cityList = currrentProvince.region_res_list.filter(item => item.region_id !== city_id)
                cityList = cityList.concat(this.state.cityList)
                let districtList = currentCity.region_res_list.filter(item => item.region_id !== district_id)
                districtList = districtList.concat(this.state.districtList)
                this.setState({
                    provinceList: provinceList,
                    cityList: cityList,
                    districtList: districtList
                })
            } else if (cityIndex > -1) {
                let cityList = this.state.cityList.filter(item => item.region_id !== city_id)
                let districtList = currentCity.region_res_list.filter(item => item.region_id !== district_id)
                districtList = districtList.concat(this.state.districtList)
                this.setState({
                    cityList: cityList,
                    districtList: districtList
                })

            } else {
                let districtList = this.state.districtList.filter(item => item.region_id !== district_id)
                this.setState({
                    districtList: districtList
                })
            }

        }


    }
    UNSAFE_componentWillReceiveProps(e) {
        this.setState({
            areaList: e.areaList,
            provinceList: e.provinceList,
            cityList: e.cityList,
            districtList: e.districtList
        })
    }
    componentDidMount() {
        this.queryRegionDetail()
    }


    render() {
        return (
            <Modal
                className="check-address"
                title={this.props.freeShipping ? '选择包邮区域' : '选择配送区域'}
                visible={this.props.visible}
                onOk={this.props.handleOk.bind(this, this.state.areaList, this.state.provinceList, this.state.cityList, this.state.districtList, this.props.shipping_template_item_id)}
                onCancel={this.props.handleCancel}
                destroyOnClose={true}
                okButtonProps={{ shape: 'round' }}
                cancelButtonProps={{ shape: 'round' }}
            >
                {
                    this.state.areaData.map((item, index) =>
                        (
                            <div className="area-item" key={index}>
                                <div className="region-item">
                                    <Checkbox
                                        indeterminate={this.state.provinceList.findIndex(province => province.area_id === item.area_id) > -1 || this.state.cityList.findIndex(city => city.area_id === item.area_id) > -1 || this.state.districtList.findIndex(district => district.area_id === item.area_id) > -1}
                                        onChange={this.onCheckArea.bind(this, item.area_id)}
                                        checked={this.state.areaList.findIndex(area => area.area_id === item.area_id) > -1 ? true : false}
                                    >
                                        {item.area_name}
                                    </Checkbox>
                                </div>
                                <div className="province-item">
                                    {

                                        item.region_res_list.map((province, index) => (
                                            <Tooltip key={index} title={
                                                province.region_res_list.map((city, index) => (
                                                    <Tooltip key={index} placement="right" title={
                                                        <div>
                                                            {
                                                                city.region_res_list.map((de, index) => (
                                                                    <div key={index}>
                                                                        <Checkbox
                                                                            onClick={this.onCheckDistrict.bind(this, item.area_id, province.region_id, city.region_id, de.region_id)}
                                                                            checked={this.state.areaList.findIndex(area => area.area_id === item.area_id) > -1 ? true : false || this.state.provinceList.findIndex(item => item.region_id === province.region_id) > -1 ? true : false || this.state.cityList.findIndex(item => item.region_id === city.region_id) > -1 ? true : false || this.state.districtList.findIndex(item => item.region_id === de.region_id) > -1 ? true : false}
                                                                        >
                                                                            {de.region_name}
                                                                        </Checkbox>
                                                                        <br />
                                                                    </div>


                                                                )
                                                                )
                                                            }
                                                        </div>
                                                    }>
                                                        <Checkbox
                                                            indeterminate={this.state.districtList.findIndex(district => district.city_id === city.region_id) > -1}
                                                            onClick={this.onCheckCity.bind(this, item.area_id, province.region_id, city.region_id)}
                                                            checked={this.state.areaList.findIndex(area => area.area_id === item.area_id) > -1 ? true : false || this.state.provinceList.findIndex(item => item.region_id === province.region_id) > -1 ? true : false || this.state.cityList.findIndex(item => item.region_id === city.region_id) > -1 ? true : false}
                                                        >
                                                            {city.region_name}
                                                        </Checkbox>
                                                        <br />
                                                    </Tooltip>
                                                ))

                                            }>
                                                <Checkbox
                                                    indeterminate={this.state.cityList.findIndex(city => city.province_id === province.region_id) > -1 || this.state.districtList.findIndex(district => district.province_id === province.region_id) > -1}
                                                    onClick={this.onCheckProvince.bind(this, item.area_id, province.region_id)}
                                                    checked={this.state.areaList.findIndex(area => area.area_id === item.area_id) > -1 ? true : false || this.state.provinceList.findIndex(item => item.region_id === province.region_id) > -1 ? true : false}
                                                >
                                                    {province.region_name}
                                                </Checkbox>
                                            </Tooltip>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                }
            </Modal>
        )
    }
}

export default CheckAddress
