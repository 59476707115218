import React from "react";
import "@css/goodsDragSort.scss";
import { Icon } from "antd";
import Sortable from "react-sortablejs";
class GoodsDragSort extends React.Component {
  constructor(props) {
    super();
    this.state = {
      options: {
        animation: 150,
        scroll: true,
        scrollSensitivity: 50
      }
    };
  }
  swapArray(arr, newIndex, oldIndex) {
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
  }
  onChange = (order, sortable, evt) => {
    let list = this.swapArray([...this.props.list], evt.newIndex, evt.oldIndex);
    this.props.changeList && this.props.changeList(list, evt.newIndex, evt.oldIndex);
  };
  deleteList = (index, e) => {
    e.stopPropagation();
    if (this.props.delete) {
      this.props.delete(index);
      return;
    }
    let list = [...this.props.list];
    list.splice(index, 1);
    this.props.changeList && this.props.changeList(list);
  };
  render() {
    const { list, editable } = this.props;
    return (
      <div className="goods-drag-sort">
        {editable ? (
          <Sortable options={this.state.options} className={"drag-sort " + this.props.size} onChange={this.onChange.bind(this)}>
            {list.map((item, index) => (
              <div title={item.goods_name} className="img-content" onClick={this.props.handlePreview.bind(this, item.url)} key={item.url || item.thumbnail_url}>
                <img alt="" className="goods-detail-img" src={item.url || item.thumbnail_url} />
                <Icon onClick={this.deleteList.bind(this, index)} className="img-close-icon" type="close-circle" theme="filled" />
                {item.onsale !== undefined && item.onsale !== 1 ? <div className="unsale">已下架</div> : null}
              </div>
            ))}
          </Sortable>
        ) : (
          <div className="drag-sort">
            {list.map((item, index) => (
              <div className="img-content" onClick={this.props.handlePreview.bind(this, item.url)} key={item.name}>
                <img alt="" className="goods-detail-img" src={item.url} />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
export default GoodsDragSort;
