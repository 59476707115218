import React from "react";
import { connect } from "react-redux";
import NavBar from "@/view/component/common/NavBar.js";
import "@css/adminMerchantManage.scss";
import {
  Form,
  Table,
  Input,
  Button,
  message,
  Pagination,
  Select,
  Switch,
  Popconfirm
} from "antd";
import PropTypes from "prop-types";
import {
  queryMchApplyList,
  modifyMchId,
  submitAuditResult,
  modifyRefundAuth,
  modifyWxApplyNo
} from "@service/getData";
const { Option } = Select;
class AdminMerchantManage extends React.Component {
  constructor(props) {
    super();
    AdminMerchantManage.contextTypes = {
      router: PropTypes.object.isRequired
    };
    this.state = {
      total: 0,
      page_no: 1,
      page_size: 10,
      mch_apply_id: null,
      name: null,
      mch_id: null,
      apply_status: null,
      merchant_list: [],
      apply_status_mapping: {
        1: "未提交",
        2: "已提交",
        3: "审核失败",
        4: "已入驻"
      },
      loading: false,
      current_mch_id: "",
      current_wx_apply_no: "",
      err_msg: null,
      columns: [
        {
          title: "账户名称",
          dataIndex: "mch_short_name"
        },
        {
          title: "申请单编号",
          dataIndex: "wx_apply_no",
          render: (text, record, index) => (
            <div>
              <span>{text}</span>
              <Popconfirm
                title={
                  <div className="goods-price-change">
                    <Input
                      value={this.state.current_wx_apply_no}
                      style={{ width: "84px", borderRadius: "100px" }}
                      onChange={this.changeCurrentId.bind(
                        this,
                        "current_wx_apply_no"
                      )}
                    />
                  </div>
                }
                onConfirm={this.confirmChangeCurrentWxApplyNo.bind(
                  this,
                  record,
                  index
                )}
                onCancel={this.cancelChange}
                okText="确认"
                cancelText="取消"
                placement="bottom"
                icon={null}
              >
                <i className="iconfont icon-bianji change-goods-price" />
              </Popconfirm>
            </div>
          )
        },
        {
          title: "商户号",
          dataIndex: "mch_id",
          render: (text, record, index) => (
            <div>
              <span>{text}</span>
              <Popconfirm
                title={
                  <div className="goods-price-change">
                    <Input
                      value={this.state.current_mch_id}
                      style={{ width: "84px", borderRadius: "100px" }}
                      onChange={this.changeCurrentId.bind(
                        this,
                        "current_mch_id"
                      )}
                    />
                  </div>
                }
                onConfirm={this.confirmChangeCurrentMchId.bind(
                  this,
                  record,
                  index
                )}
                onCancel={this.cancelChange}
                okText="确认"
                cancelText="取消"
                placement="bottom"
                icon={null}
              >
                <i className="iconfont icon-bianji change-goods-price" />
              </Popconfirm>
            </div>
          )
        },
        {
          title: "是否授权退款",
          dataIndex: "refund_auth",
          render: (text, record) => (
            <div>
              <Switch
                defaultChecked={Boolean(record.refund_auth)}
                onChange={this.modifyRefundAuth.bind(this, record.mch_apply_id)}
              />
            </div>
          )
        },
        {
          title: "申请时间",
          dataIndex: "apply_at"
        },
        {
          title: "状态",
          render: (text, record) => (
            <div className={record.apply_status === 3 ? "red" : ""}>
              {this.state.apply_status_mapping[record.apply_status]}
            </div>
          )
        },
        {
          title: "操作",
          dataIndex: "apply_status",
          render: (text, record) => (
            <div>
              <span
                className="text-note mr15"
                onClick={this.updateAppApply.bind(this, record.mch_apply_id)}
              >
                查看详情
              </span>
              <span
                className="text-note mr15"
                style={{ display: text === 1 ? "inline-block" : "none" }}
                onClick={this.submitAuditResult.bind(
                  this,
                  record.mch_apply_id,
                  2
                )}
              >
                提交
              </span>
              <span
                className="text-note mr15"
                style={{ display: text === 2 ? "inline-block" : "none" }}
                onClick={this.submitAuditResult.bind(
                  this,
                  record.mch_apply_id,
                  4
                )}
              >
                审核成功
              </span>
              <Popconfirm
                style={{ display: text === 2 ? "inline-block" : "none" }}
                title={
                  <div className="goods-price-change">
                    <Input
                      value={this.state.err_msg}
                      style={{ width: "84px", borderRadius: "100px" }}
                      onChange={this.changeErrorMsg}
                    />
                  </div>
                }
                onConfirm={this.submitAuditResult.bind(
                  this,
                  record.mch_apply_id,
                  3
                )}
                onCancel={this.cancelChange}
                okText="确认"
                cancelText="取消"
                placement="bottom"
                icon={null}
              >
                <span
                  className="text-note mr15"
                  style={{ display: text === 2 ? "inline-block" : "none" }}
                >
                  审核失败
                </span>
              </Popconfirm>
              <span
                className="text-note"
                style={{ display: text === 3 ? "inline-block" : "none" }}
                onClick={this.submitAuditResult.bind(
                  this,
                  record.mch_apply_id,
                  2
                )}
              >
                重新提交
              </span>
            </div>
          )
        }
      ]
    };
  }
  componentDidMount() {
    this.queryMchApplyList();
  }
  queryMchApplyList = async () => {
    this.setState({
      loading: true
    });
    let res = await queryMchApplyList({
      mch_apply_id: this.state.mch_apply_id,
      name: this.state.name,
      mch_id: this.state.mch_id,
      apply_status: this.state.apply_status,
      page_no: this.state.page_no,
      page_size: this.state.page_size
    });
    this.setState(
      {
        merchant_list: []
      },
      () => {
        this.setState({
          merchant_list: [...res.result.list],
          total: res.result.total,
          loading: false
        });
      }
    );
  };
  updateAppApply = mch_apply_id => {
    this.context.router.history.push(
      `/merchantAdd?mch_apply_id=${mch_apply_id}&disabled=1`
    );
  };
  async modifyRefundAuth(mch_apply_id, bol) {
    console.log(mch_apply_id, bol);
    let res = await modifyRefundAuth({
      mch_apply_id,
      refund_auth: +bol
    });
    if (res.success) {
      let text = bol ? "已开启" : "已关闭";
      message.success(text);
    }
  }
  submitAuditResult = async (mch_apply_id, apply_status) => {
    let data = {
      mch_apply_id,
      apply_status
    };
    if (this.state.err_msg) {
      data.err_msg = this.state.err_msg;
    }
    let res = await submitAuditResult(data);
    let text =
      apply_status === 2
        ? "提交成功"
        : apply_status === 4
        ? "审核成功操作已完成"
        : "审核失败操作已完成";
    if (res.success) {
      message.success(text);
      this.queryMchApplyList();
    } else {
      message.error(res.msg);
    }
  };
  onShowSizeChange = (current, page_size) => {
    this.setState({
      page_size
    });
    setTimeout(() => {
      this.queryMchApplyList();
    });
  };
  onShowPageChange = page_no => {
    this.setState({
      page_no
    });
    setTimeout(() => {
      this.queryMchApplyList();
    });
  };
  filterData = () => {
    let form = this.props.form.getFieldsValue();
    let data = {
      mch_apply_id: form.mch_apply_id || null,
      name: form.name || null,
      mch_id: form.mch_id || null,
      apply_status: form.apply_status || null
    };
    this.setState(data);
    setTimeout(() => {
      this.queryMchApplyList();
    });
  };
  clearFilter = () => {
    this.props.form.resetFields();
    let data = {
      mch_apply_id: null,
      name: null,
      mch_id: null,
      apply_status: null,
      page_no: 1
    };
    this.setState(data);
    setTimeout(() => {
      this.queryMchApplyList();
    });
  };
  changeCurrentId = (name, e) => {
    let obj = {};
    obj[name] = e.target.value;
    this.setState(obj);
  };
  changeErrorMsg = e => {
    this.setState({
      err_msg: e.target.value
    });
  };
  confirmChangeCurrentMchId = (record, index) => {
    let data = {
      mch_apply_id: record.mch_apply_id,
      mch_id: this.state.current_mch_id
    };
    this.modifyMchId(data, index);
  };
  modifyMchId = async (data, index) => {
    let res = await modifyMchId(data);
    if (res.success) {
      let list = [...this.state.merchant_list];
      list[index].mch_id = data.mch_id;
      this.setState({
        merchant_list: list
      });
      message.success("修改成功");
      this.cancelChange();
    }
  };
  confirmChangeCurrentWxApplyNo = (record, index) => {
    let data = {
      mch_apply_id: record.mch_apply_id,
      wx_apply_no: this.state.current_wx_apply_no
    };
    this.modifyWxApplyNo(data, index);
  };
  modifyWxApplyNo = async (data, index) => {
    let res = await modifyWxApplyNo(data);
    if (res.success) {
      let list = [...this.state.merchant_list];
      list[index].wx_apply_no = data.wx_apply_no;
      this.setState({
        merchant_list: list
      });
      message.success("修改成功");
      this.cancelChange();
    }
  };
  cancelChange = e => {
    this.setState({
      current_wx_apply_no: "",
      current_mch_id: "",
      err_msg: ""
    });
  };
  render() {
    let { menu_code } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="admin-merchant-manage">
        <div className="content">
          <NavBar
            icon="icon-guanliyuanshezhi"
            child={menu_code.merchant_manage}
          />
          <div className="admin-merchant-manage-content">
            <div className="filter-content">
              <Form className="filter-form ant-vertical" layout="inline">
                <Form.Item label="账号名称">
                  {getFieldDecorator("name")(<Input className="name" />)}
                </Form.Item>
                <Form.Item label="商户号">
                  {getFieldDecorator("mch_id")(<Input className="mch-id" />)}
                </Form.Item>
                <Form.Item label="申请单编号">
                  {getFieldDecorator("mch_apply_id")(
                    <Input className="mch-apply-id" />
                  )}
                </Form.Item>
                <Form.Item label="状态">
                  {getFieldDecorator("apply_status", {
                    initialValue: ""
                  })(
                    <Select style={{ width: 170 }}>
                      <Option value="">全部</Option>
                      <Option value="1">未提交</Option>
                      <Option value="2">已提交</Option>
                      <Option value="3">审核失败</Option>
                      <Option value="4">已入驻</Option>
                    </Select>
                  )}
                </Form.Item>
              </Form>
              <div className="line">
                <Button shape="round" type="primary" onClick={this.filterData}>
                  查询
                </Button>
                <span className="text-note" onClick={this.clearFilter}>
                  清空筛选条件
                </span>
              </div>
            </div>
            <div className="table-content">
              <Table
                loading={this.state.loading}
                rowKey={(record, index) => index}
                columns={this.state.columns}
                dataSource={this.state.merchant_list}
                pagination={false}
                bordered={true}
              />
              <Pagination
                className="pagenation"
                onChange={this.onShowPageChange}
                onShowSizeChange={this.onShowSizeChange}
                defaultCurrent={this.state.page_no}
                total={this.state.total}
                showTotal={total => `共 ${total} 条`}
                pageSizeOptions={["10", "30", "50", "100"]}
                showSizeChanger
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};

const AdminMerchantManageForm = Form.create({
  name: "admin_merchant_manage_form"
})(AdminMerchantManage);

export default connect(mapStateToProps)(AdminMerchantManageForm);
