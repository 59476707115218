import React, { Component } from 'react';
import { Spin, Table } from 'antd';
import NavBar from "@/view/component/common/NavBar.js";
import { getData } from '@service/getData';
import { getQueryString } from '@common/common';
import '../scss/refundDetail.scss';
import { connect } from "react-redux";

class RefundDetail extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            columns: [
                {
                    title: '售后订单号',
                    dataIndex: 'refund_order_sn',
                },
                {
                    title: '申请时间',
                    dataIndex: 'user_submit_time',
                },
                {
                    title: '金额',
                    dataIndex: 'refund_amount',
                    render: (text) => (
                        <span>{text / 100}</span>
                    )
                },
                {
                    title: '退款原因',
                    dataIndex: 'refund_reason_desc',
                },
                {
                    title: '退款状态',
                    dataIndex: 'refund_status',
                    render: (text) => {
                        if (text < 7 || text > 11) {
                            return null;
                        }
                        return (
                            <span>{this.props.refund_status_list[text].value}</span>
                        )
                    }
                },
            ],
            data: [],
            // 分页
            // page: 1,
            // page_size: 10,
            // total: 0,
            order_sn: '',
        };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        const url = '/refundOrder/queryAdminRefundDetail';
        const data = {
            order_id: +getQueryString('order_id'),
        };
        getData(url, data).then((res) => {
            if (res.success && res.result) {
                const { result } = res;
                this.setState({
                    data: result.refund_orders,
                    order_sn: result.order_sn,
                    // page: result.page_no,
                    // page_size: result.page_size,
                    // total: result.total,
                    loading: false,
                });
            }
        })
    }

    // onShowPageChange = (page) => {
    //     this.setState({
    //         page,
    //     }, () => {
    //         this.init();
    //     });
    // }

    // onShowSizeChange = (current, pageSize) => {
    //     this.setState({
    //         page_size: pageSize
    //     }, () => {
    //         this.init();
    //     })
    // }

    render() {
        return (
            <div className="refundDetail">
                <Spin spinning={this.state.loading}>
                    <NavBar
                        icon="icon-dingdan"
                        father='订单管理'
                        router='orderManage'
                        child='退款详情'
                    />
                    <div className="content">
                        <p className="orderSn">订单号：{this.state.order_sn}</p>
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            rowKey={(record, index) => index}
                            bordered
                            pagination={false}
                        />

                        {/* <Pagination
                            className="pagenation"
                            onChange={this.onShowPageChange}
                            onShowSizeChange={this.onShowSizeChange}
                            defaultCurrent={this.state.page}
                            total={this.state.total}
                            showTotal={(total) => `共 ${total} 条`}
                            pageSizeOptions={["10", "30", "50", "100"]}
                            showSizeChanger
                        /> */}
                    </div>
                </Spin>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        refund_status_list: state.refundStatusList
    };
};

export default connect(mapStateToProps)(RefundDetail);