import React from 'react'
import NavBar from '@/view/component/common/NavBar.js'
import {connect} from "react-redux";
import '@css/bonusSettle.scss'
import BonusStreamontent from '@/view/component/Money/BonusStreamontent'
import {queryShopBonusSummaryDetail} from '@service/getData'
import cookie from "react-cookies";
import PropTypes from "prop-types";
class  BonusStream extends React.Component{
    constructor(props){
        BonusStream.contextTypes = {
            router: PropTypes.object.isRequired
        };
        super(props)
        this.state={
            balance:0,
            reserve_balance:0,
            page:0,
            page_size:10,
            location:null
        }
    }
    async queryShopBonusSummaryDetail(){
        if(!cookie.load("shop_id")&&+cookie.load("is_admin")===1){
            return
        }
        let data={
            shop_id:cookie.load("shop_id")
        }
        let res=await queryShopBonusSummaryDetail(data)
        if(res.success){
            this.setState({
                balance:res.result.shop_bonus_summary.balance/100,
                reserve_balance:res.result.shop_bonus_summary.reserve_balance/100
            })
        }
    }
    componentDidMount(){
        let location = this.context.router.route.location.pathname.replace(/[/||\\]/g, '');
        this.setState({
            location
        }, () => {
            this.queryShopBonusSummaryDetail();
        })

    }
    render(){
        return(
            <div className="bonus-settle">
                <NavBar icon="icon-zichan" father="店铺资金"child={this.state.location==="rechargeHistory"?"充值记录":"提现记录"} router="assetsManage"/>
                <div className="content">
                    <BonusStreamontent/>
                </div>
            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        menu_code:state.menuCode

    }
}
export default connect(mapStateToProps) (BonusStream);
