let config = {

  imgBaseUrl: "http://xiaodian-prod.oss-cn-shanghai.aliyuncs.com/",
  baseUrl: "",
  wsUrl: "",
  frontUrl: "https://jike.geezdata.com/dashboard",
  component_appid: "wxe32f5b66c11a8d36",

};


if (process.env.NODE_ENV === "production") {
  //生产环境
  config.baseUrl = "https://jike.geezdata.com/api/admin";
  config.wsUrl = "wss://jike.geezdata.com/api/cm_websocket";
} else {
  //开发环境
  config.baseUrl = "";
  config.wsUrl = "wss://preprod.jike.geezdata.com/api/cm_websocket";
}

export default config;
