import React, { Fragment } from "react";
import { Modal, Tabs, Table, Input, Tag, Button, message, Divider, Form, Switch } from "antd";
import {
	queryCampaignList,
	queryGoodsList,
	queryTagList,
	queryActivityList,
	queryActivityDetail,
	queryCouponList,
	queryRoomInfoList
} from "@service/getData";
import cookie from "react-cookies";
import PropTypes from "prop-types";
import "@css/targetTypeModal.scss";
import { formatDate } from "@common/common";

class TargetTypeModal extends React.Component {
	constructor() {
		super();
		TargetTypeModal.contextTypes = {
			router: PropTypes.object.isRequired
		};
		this.state = {
			active_key: "1",
			active_key2: "1",
			columns: [
				{
					title: "页面名称",
					dataIndex: "campaign_name",
					render: (text, record) => (
						<div>
							{text}
							<span style={{ display: record.is_first_page === 0 ? "none" : true }} className="text-remind text-tag">
								<Tag color="#1890ff">店铺首页</Tag>
							</span>
						</div>
					)
				}
			],
			goods_columns: [
				{
					title: "商品id",
					dataIndex: "goods_id"
				},
				{
					title: "商品图片",
					dataIndex: "thumbnail_url",
					render: (text, record) => (
						<div>
							<img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="100" height="100" />
						</div>
					)
				},
				{
					title: "商品名称",
					dataIndex: "goods_name"
				},
				{
					title: "价格",
					width: "20%",
					dataIndex: "current_price",
					render: text => <div>￥{text / 100}</div>
				}
			],
			tag_columns: [
				{
					title: "商品列表页名称",
					dataIndex: "tag_name",
					key: "tag_name"
				},
				{
					title: "商品数",
					dataIndex: "goods_num",
					key: "goods_num"
				}
			],
			activity_columns: [
				{
					title: "活动名称",
					dataIndex: "activity_name",
					key: "activity_name"
				}
			],
			activity_goods_columns: [
				{
					title: "商品id",
					dataIndex: "goods_id"
				},
				{
					title: "商品图片",
					dataIndex: "thumbnail_url",
					render: (text, record) => (
						<div>
							<img alt="" className="object-cover" src={JSON.parse(record.gallery_urls)[0]} width="100" height="100" />
						</div>
					)
				},
				{
					title: "商品名称",
					dataIndex: "goods_name"
				}
			],
			coupon_columns: [
				{
					title: "id",
					dataIndex: "coupon_id",
					width: "15%"
				},
				{
					title: "优惠券名称",
					dataIndex: "coupon_name"
				},
				{
					title: "优惠内容",
					dataIndex: "use_min_amount",
					render: (text, record) => {
						return `满${text / 100}减${record.discount_amount / 100}`;
					}
				},
				{
					title: "适用商品",
					dataIndex: "coupon_category",
					render: text => {
						return text === "goods_coupon" ? "指定商品" : "全部商品";
					}
				},
				{
					title: "有效时间",
					dataIndex: "start_time",
					render: (text, record) => {
						return record.effect_day
							? `按领取之日起${record.effect_day}天内可用`
							: `${formatDate(new Date(text).getTime(), "Y-M-D")} 至 ${formatDate(new Date(record.end_time).getTime(), "Y-M-D")}`;
					}
				},
				{
					title: "剩余",
					dataIndex: "remain_num",
					width: "15%"
				},
				{
					title: "状态",
					dataIndex: "admin_coupon_status",
					render: text => {
						switch (text) {
							case 0:
								return "未开始";
							case 1:
								return <span className="text-green">进行中</span>;
							case 2:
								return <span className="text-remind">已过期</span>;
							case 3:
								return <span className="text-danger">已停止</span>;
							default:
								break;
						}
					}
				}
			],
			room_columns: [
				{
					title: "id",
					dataIndex: "room_info_id"
				},
				{
					title: "直播标题",
					dataIndex: "name"
				},
				{
					title: "开播时间",
					dataIndex: "start_time",
					render: text => {
						return formatDate(text * 1000);
					}
				},
				{
					title: "结束时间",
					dataIndex: "end_time",
					render: text => {
						return formatDate(text * 1000);
					}
				},
				{
					title: "主播昵称",
					dataIndex: "anchor_name"
				},
				{
					title: "直播状态",
					dataIndex: "live_status_desc",
					render: text => <span style={{ color: text.color }}>{text.name}</span>
				}
			],
			campaign_list: [],
			select_campaign_list: [],
			select_campaign_key_list: [],
			goods_list: [],
			select_goods_list: [],
			select_goods_key_list: [],
			select_tag_list: [],
			select_tag_key_list: [],
			tag_list: [],
			activity_list: [],
			select_activity_list: [],
			select_activity_key_list: [],
			activity_goods_list: [],
			select_activity_goods_list: [],
			select_activity_goods_key_list: [],
			coupon_list: [],
			select_coupon_list: [],
			select_coupon_key_list: [],
			lucky: 0,
			lucky_activity_id: null,
			free_order_activity_id: null,
			room_info_list: [],
			select_room_info_list: [],
			select_room_info_key_list: [],
			system_page_list: [],
			select_system_page_list: [],
			select_system_page_key_list: [],
			live_status: {
				101: {
					name: "直播中",
					color: "#00CC66"
				},
				102: {
					name: "未开始",
					color: "#333333"
				},
				103: {
					name: "已结束",
					color: "#999999"
				},
				104: {
					name: "禁播",
					color: "#FF0000"
				},
				105: {
					name: "暂停中",
					color: "#FF9900"
				},
				106: {
					name: "异常",
					color: "#FF0000"
				},
				107: {
					name: "已过期",
					color: "#999999"
				}
			},
			system_pages: [
				{
					campaign_name: " 店铺首页",
					type: "homePages",
					is_first_page: 0
				},
				{
					campaign_name: " 分类页面",
					type: "categoryPages",
					is_first_page: 0
				},
				{
					campaign_name: "购物车",
					type: "shopcarPages",
					is_first_page: 0
				},
				{
					campaign_name: "个人中心",
					type: "myPages",
					is_first_page: 0
				},
				{
					campaign_name: '会员中心',
					type: 'vip_center',
					is_first_page: 0,
				},
			],
			coupon_category: null,
			activity_coupon_columns: [
				{
					title: "活动名称",
					dataIndex: "coupon_name"
				},
				{
					title: "活动类型",
					dataIndex: "activity_type_str",
				},
				{
					title: "活动详情",
					dataIndex: "activity_detail_str",
					render: (text, row, index) => {
						const detailArr = text.split(';');
						return (
							<>
								{detailArr.map((item) => (
									<p>{item};</p>
								))}
							</>
						)
					}
				},
				{
					title: "状态",
					dataIndex: "status",
					render: text => {
						switch (text) {
							case 1:
								return <span className="text-green">进行中</span>;
							case 2:
								return <span className="text-danger">已失效</span>;
							case 3:
								return <span className="text-remind">已结束</span>;
							case 4:
								return <span className="text-remind">未生效</span>;
							default:
								return null;
						}
					}
				}
			],
			search_value: "",
			custom_path_url: "",
			// 是否是公众号文章
			isThePublic: false,
		};
	}

	queryCampaignList = async () => {
		let data = {
			mini_app_id: cookie.load("mini_app_id")
		};
		let res = await queryCampaignList(data);
		if (res.success) {
			if (res.success) {
				this.setState({
					campaign_list: res.result.campaign_res_list
				});
			}
		}
	};
	// 活动商品页面
	queryActivityDetail = async () => {
		let data = {
			mini_app_id: cookie.load("mini_app_id")
		};
		switch (this.state.lucky) {
			case 0:
				data.activity_id = this.state.lucky_activity_id;
				break;
			case 1:
				data.activity_id = this.state.free_order_activity_id;
				data.activity_type = "personCenter";
				break;
			case 2:
				data.activity_type = "personCenter";
				break;
			default:
				break;
		}
		let res = await queryActivityDetail(data);
		if (res.success) {
			if (res.success) {
				let select_goods_res = res.result.activity_res.activity_goods_res_list.map(item => {
					return {
						goods_id: item.goods_res.goods_id,
						goods_name: item.goods_res.goods_name,
						current_price: item.goods_res.current_price,
						start_time: item.start_time,
						end_time: item.end_time,
						draw_time: item.draw_time,
						activity_id: item.activity_id,
						activity_goods_id: item.activity_goods_id,
						status: 1,
						gallery_urls: item.goods_res.gallery_urls,
						loading: false,
						partake_price: item.partake_price / 100,
						group_member_num: item.group_member_num,
						buy_limit: item.buy_limit,
						gift_num: item.gift_num,
						points_amount: item.points_amount
					};
				});

				this.setState({
					activity_goods_list: select_goods_res
				});
			}
		}
	};
	queryGoodsList = async () => {
		this.setState({
			loading2: true
		});
		let data = {
			shop_id: cookie.load("shop_id"),
			onsale: 1
		},
			value = this.state.search_value;
		if (isNaN(+value)) {
			data.goods_name = value;
		} else {
			data.goods_id = value;
		}
		let res = await queryGoodsList(data);
		if (res.success) {
			this.setState({
				loading2: false,
				goods_list: res.result.goods_res_list
			});
		}
	};
	onChange2 = key => {
		this.onChange(key, 1);
	};
	onChange = (key, is_sub = 0) => {
		if (!is_sub) {
			this.setState({
				active_key: key
			});
		}
		this.setState({
			active_key2: key,
			select_goods_list: [],
			select_campaign_key_list: [],
			select_tag_key_list: [],
			select_activity_key_list: [],
			select_activity_goods_key_list: [],
			select_activity_coupon_key_list: [],
			select_system_page_list: [],
			select_system_page_key_list: []
		});
	};
	onSelectCampaignChange = (selectedRowKeys, selectedRows) => {
		this.setState({
			select_campaign_key_list: selectedRowKeys,
			select_campaign_list: selectedRows
		});
	};
	onSelectGoodsChange = (selectedRowKeys, selectedRows) => {
		this.setState({
			select_goods_key_list: selectedRowKeys,
			select_goods_list: selectedRows
		});
	};
	onSelectTagChange = (selectedRowKeys, selectedRows) => {
		this.setState({
			select_tag_key_list: selectedRowKeys,
			select_tag_list: selectedRows
		});
	};
	onSelectActivityChange = (selectedRowKeys, selectedRows) => {
		this.setState({
			select_activity_key_list: selectedRowKeys,
			select_activity_list: selectedRows
		});
	};
	onSelectActivityGoodsChange = (selectedRowKeys, selectedRows) => {
		this.setState({
			select_activity_goods_key_list: selectedRowKeys,
			select_activity_goods_list: selectedRows
		});
	};
	onSelectCouponChange = (selectedRowKeys, selectedRows) => {
		this.setState({
			select_coupon_key_list: selectedRowKeys,
			select_coupon_list: selectedRows
		});
	};
	onSelectRoomChange = (selectedRowKeys, selectedRows) => {
		this.setState({
			select_room_info_key_list: selectedRowKeys,
			select_room_info_list: selectedRows
		});
	};
	onSelectSystemPageChange = (selectedRowKeys, selectedRows) => {
		this.setState({
			select_system_page_list: selectedRows,
			select_system_page_key_list: selectedRowKeys
		});
	};
	onOk = () => {
		let data = {};
		let active_key = this.props.showGoods ? "3" : this.state.active_key2 || this.state.active_key;
		active_key = this.props.activity_subtype ? "9" : active_key;
		switch (active_key) {
			case "1":
				if (this.state.select_campaign_key_list.length === 0) return message.error("请选择店铺页面");
				data.target_type = "campaign";
				data.dest_campaign_id = this.state.select_campaign_key_list[0];
				data.desc = `店铺页面:${this.state.select_campaign_list[0].campaign_name}`;
				break;
			case "2":
				data.target_type = "goods";
				if (this.state.select_goods_key_list.length === 0) return message.error("请选择商品");
				data.goods_id = this.state.select_goods_key_list[0];
				data.desc = `商品:${this.state.select_goods_list[0].goods_name}`;
				break;
			case "3":
				data.target_type = "tag";
				if (this.state.select_tag_key_list.length === 0) return message.error("请选择商品列表页");
				data.tag_id = this.state.select_tag_key_list[0];
				data.desc = `商品列表页:${this.state.select_tag_list[0].tag_name}`;
				break;
			case "4":
				if (this.state.select_activity_list.length === 0) return message.error("请选择营销活动");
				data.target_type = this.state.select_activity_list[0].activity_subtype;
				data.desc = `营销活动:${this.state.select_activity_list[0].activity_name}`;
				break;
			case "5":
				if (this.state.select_activity_goods_list.length === 0) return message.error("请选择活动商品");
				data.target_type = "activity_goods";
				data.activity_goods_id = this.state.select_activity_goods_list[0].activity_goods_id;
				data.desc = `活动商品:${this.state.select_activity_goods_list[0].goods_name}`;
				break;
			case "6":
				if (this.state.select_coupon_list.length === 0) return message.error("请选择营销活动");
				data.target_type = "coupon";
				data.coupon_id = this.state.select_coupon_list[0].coupon_id;
				data.dest_coupon_id = this.state.select_coupon_list[0].coupon_id;
				data.desc = `优惠券:${this.state.select_coupon_list[0].coupon_name}`;
				if (this.state.coupon_category === "activity_coupon") {
					data.target_type = "activity_coupon";
					data.desc = `满减/满赠:${this.state.select_coupon_list[0].coupon_name}`;
				}
				break;
			case "7":
				if (this.state.select_room_info_key_list.length === 0) return message.error("请选择直播");
				data.target_type = "roomInfo";
				data.room_info_id = this.state.select_room_info_key_list[0];
				data.desc = `直播:${this.state.select_room_info_list[0].name}`;
				break;
			case "8":
				if (this.state.select_system_page_key_list.length === 0) return message.error("请选择系统页面");
				data.target_type = this.state.select_system_page_list[0].type;

				data.desc = `系统页面:${this.state.select_system_page_list[0].campaign_name}`;
				break;
			case "9":
				if (this.state.select_room_info_key_list.length === 0) return message.error("请选择直播");
				data.target_type = "roomInfoList";
				data.room_info_id = this.state.select_room_info_key_list[0];
				data.desc = `直播:${this.state.select_room_info_list[0].name}`;
				break;
			case "10":
				if (!this.state.custom_path_url) return message.error("请填写路径");
				data.target_type = "customPages";
				data.path_url = this.state.custom_path_url;
				data.desc = `自定义路径${this.state.custom_path_url}`;
				if (this.state.isThePublic) {
					data.path_url = `pages/webView/index?src=${data.path_url}`;
				}
				break;
			default:
				break;
		}
		this.props.onOK(data);
		this.setState({
			select_goods_key_list: [],
			select_goods_list: [],
			select_campaign_key_list: [],
			select_campaign_list: [],
			select_tag_key_list: [],
			select_tag_list: [],
			select_activity_list: [],
			select_activity_key_list: [],
			select_activity_goods_list: [],
			select_activity_goods_key_list: [],
			select_coupon_list: [],
			select_coupon_key_list: [],
			select_room_info_list: [],
			select_room_info_key_list: [],
			select_system_page_list: [],
			select_system_page_key_list: [],
			search_value: "",
			custom_path_url: "",
			isThePublic: false,
		});
	};
	queryTagList = async () => {
		let data = {
			mini_app_id: cookie.load("mini_app_id")
		};
		let res = await queryTagList(data);
		if (res.success) {
			this.setState({
				tag_list: res.result.tag_res_list
			});
		}
	};
	queryActivityList = async () => {
		let data = {
			mini_app_id: cookie.load("mini_app_id"),
			activity_type_list: ["personCenter", "booth"]
		};
		let res = await queryActivityList(data);
		if (res.success) {
			let lucky_activity = res.result.activity_res_list.find(item => item.activity_subtype === "lucky");
			let free_order_activity = res.result.activity_res_list.find(item => item.activity_subtype === "free_order");
			this.setState(
				{
					activity_list: res.result.activity_res_list,
					lucky_activity_id: lucky_activity.activity_id,
					free_order_activity_id: free_order_activity.activity_id
				},
				() => {
					this.queryActivityDetail();
				}
			);
		}
	};
	queryCouponList = async () => {
		let form = this.props.form.getFieldsValue();
		let data = {
			mini_app_id: cookie.load("mini_app_id"),
			// 5: 进行中 和 未开始
			// 1: 进行中
			status: 5,
			like_condition: form.like_condition,
			coupon_category: this.state.coupon_category
		};
		let res = await queryCouponList(data);
		if (res.success) {
			this.setState({
				coupon_list: res.result.coupon_res_list
			});
		}
	};
	goToGoodsListPage = () => {
		window.open(window.location.origin + "/goodsListPage", "_blank");
	};
	changeLucky = (type, value) => {
		this.setState(
			{
				[type]: value
			},
			() => {
				if (type === "lucky") {
					this.queryActivityDetail();
				} else {
					this.queryCouponList();
				}
			}
		);
	};
	queryRoomInfoList = async () => {
		let form = this.props.form.getFieldsValue();
		let data = {
			room_info_id_and_name: form.room_info_id_and_name,
			mini_app_id: cookie.load("mini_app_id"),
			live_filter: 1
		};
		let res = await queryRoomInfoList(data);
		if (res.success) {
			let { room_info_res_list } = res.result;
			room_info_res_list.forEach(item => {
				item.live_status_desc = this.state.live_status[item.live_status];
			});
			this.setState({
				room_info_list: room_info_res_list
			});
		}
	};
	goToCouponList = () => {
		let pathname = this.state.coupon_category === "activity_coupon" ? "/market" : "/couponManage";
		window.open(window.location.origin + pathname, "_blank");
	};
	UNSAFE_componentWillReceiveProps(props) {
		if (this.props.visible !== props && props.visible === true) {
			if (this.props.activity_subtype === "roomInfoList") {
				this.queryRoomInfoList();
			} else {
				// 店铺页面
				this.queryCampaignList();
				// 商品页面
				this.queryGoodsList();
				// 商品列表页面
				this.queryTagList();
				// 营销活动页面。
				// 回调里调用 queryActivityDetail  =>  活动商品页面
				this.queryActivityList();
				// 促销工具
				this.queryCouponList();
				// 直播间页面
				this.queryRoomInfoList();
			}
		}
	}
	searchHandle = e => {
		this.setState({
			search_value: e.target.value
		});
	};
	changePathUrl = e => {
		this.setState({
			custom_path_url: e.target.value
		});
	};
	onChangeSwitch = (checked) => {
		this.setState({
			isThePublic: checked,
		});
	}
	render() {
		const rowCampaignSelection = {
			selectedRowKeys: this.state.select_campaign_key_list,
			onChange: this.onSelectCampaignChange,
			hideDefaultSelections: true,
			type: "radio"
		};
		const rowGoodsSelection = {
			selectedRowKeys: this.state.select_goods_key_list,
			onChange: this.onSelectGoodsChange,
			hideDefaultSelections: true,
			type: "radio"
		};
		const rowTagSelection = {
			selectedRowKeys: this.state.select_tag_key_list,
			onChange: this.onSelectTagChange,
			hideDefaultSelections: true,
			type: "radio"
		};
		const rowActivitySelection = {
			selectedRowKeys: this.state.select_activity_key_list,
			onChange: this.onSelectActivityChange,
			hideDefaultSelections: true,
			type: "radio"
		};
		const rowActivityGoodsSelection = {
			selectedRowKeys: this.state.select_activity_goods_key_list,
			onChange: this.onSelectActivityGoodsChange,
			hideDefaultSelections: true,
			type: "radio"
		};
		const rowCouponSelection = {
			selectedRowKeys: this.state.select_coupon_key_list,
			onChange: this.onSelectCouponChange,
			hideDefaultSelections: true,
			type: "radio"
		};
		const rowRoomSelection = {
			selectedRowKeys: this.state.select_room_info_key_list,
			onChange: this.onSelectRoomChange,
			hideDefaultSelections: true,
			type: "radio"
		};
		const rowSystemPageSelection = {
			selectedRowKeys: this.state.select_system_page_key_list,
			onChange: this.onSelectSystemPageChange,
			hideDefaultSelections: true,
			type: "radio"
		};
		const { getFieldDecorator } = this.props.form;
		return (
			<Modal
				className="target-type-modal"
				title={this.props.title || "跳转路径"}
				visible={this.props.visible}
				onCancel={this.props.onCancel}
				onOk={this.onOk}
				destroyOnClose={true}
				okButtonProps={{ shape: "round" }}
				cancelButtonProps={{ shape: "round" }}>
				{this.props.activity_subtype === "roomInfoList" ? (
					<Fragment>
						<Form layout="inline">
							<Form.Item label="直播标题/id：">
								{getFieldDecorator("room_info_id_and_name")(<Input className="form-input" placeholder="输入直播ID" />)}
							</Form.Item>
							<Form.Item>
								<Button size="small" type="primary" shape="round" onClick={this.queryRoomInfoList}>
									搜索
                				</Button>
							</Form.Item>
						</Form>
						<span className="text-note" onClick={this.queryRoomInfoList}>
							刷新
            			</span>
						<Table
							columns={this.state.room_columns}
							rowKey={record => record.room_info_id}
							dataSource={this.state.room_info_list}
							bordered={true}
							rowSelection={rowRoomSelection}
						/>
					</Fragment>
				) : this.props.showGoods ? (
					<Tabs activeKey="3" onChange={this.onChange}>
						<Tabs.TabPane tab="商品列表页" key="3">
							<div className="action">
								<Button onClick={this.goToGoodsListPage} type="primary" shape="round">
									管理商品列表
                				</Button>
								<span className="text-note" onClick={this.queryTagList}>
									刷新
                				</span>
							</div>
							<Table
								rowKey={record => record.tag_id}
								columns={this.state.tag_columns}
								dataSource={this.state.tag_list}
								bordered={true}
								rowSelection={rowTagSelection}
							/>
						</Tabs.TabPane>
					</Tabs>
				) : (
							<Tabs activeKey={this.state.active_key} onChange={this.onChange}>
								<Tabs.TabPane tab="店铺页面" key="1">
									<Table
										rowKey={record => record.campaign_id}
										columns={this.state.columns}
										dataSource={this.state.campaign_list}
										bordered={true}
										rowSelection={rowCampaignSelection}
									/>
								</Tabs.TabPane>
								<Tabs.TabPane tab="商品" key="2">
									<Tabs activeKey={this.state.active_key2} onChange={this.onChange2}>
										<Tabs.TabPane tab="商品" key="2">
											<div className="table-header">
												<Form
													labelCol={{
														span: 12
													}}
													wrapperCol={{
														span: 12
													}}
													layout="inline">
													<Form.Item label="商品名称/商品id">
														<Input value={this.state.search_value} onChange={this.searchHandle} />
													</Form.Item>
													<Form.Item>
														<Button shape="round" onClick={this.queryGoodsList.bind(this)}>
															搜索
													</Button>
													</Form.Item>
												</Form>
											</div>
											<Table
												rowKey={record => record.goods_id}
												columns={this.state.goods_columns}
												dataSource={this.state.goods_list}
												bordered={true}
												rowSelection={rowGoodsSelection}
											/>
										</Tabs.TabPane>
										<Tabs.TabPane tab="商品列表页" key="3">
											<div className="action">
												<Button onClick={this.goToGoodsListPage} type="primary" shape="round">
													管理商品列表
										</Button>
												<span className="text-note" onClick={this.queryTagList}>
													刷新
											</span>
											</div>
											<Table
												rowKey={record => record.tag_id}
												columns={this.state.tag_columns}
												dataSource={this.state.tag_list}
												bordered={true}
												rowSelection={rowTagSelection}
											/>
										</Tabs.TabPane>
									</Tabs>
								</Tabs.TabPane>
								<Tabs.TabPane tab="营销活动" key="4">
									<Table
										rowKey={record => record.activity_id}
										columns={this.state.activity_columns}
										dataSource={this.state.activity_list}
										bordered={true}
										rowSelection={rowActivitySelection}
									/>
								</Tabs.TabPane>
								<Tabs.TabPane tab="活动商品" key="5">
									<div className="market-type">
										<span className="market-type-title">营销类别：</span>
										<span className={`${this.state.lucky === 0 ? "text-note" : null}`} onClick={this.changeLucky.bind(this, "lucky", 0)}>
											抽奖活动
									</span>
										<Divider type="vertical" />
										<span className={`${this.state.lucky === 1 ? "text-note" : null}`} onClick={this.changeLucky.bind(this, "lucky", 1)}>
											抢免单
									</span>
										<Divider type="vertical" />
										<span className={`${this.state.lucky === 2 ? "text-note" : null}`} onClick={this.changeLucky.bind(this, "lucky", 2)}>
											积分兑换
									</span>
									</div>
									<Table
										rowKey={record => record.activity_goods_id}
										columns={this.state.activity_goods_columns}
										dataSource={this.state.activity_goods_list}
										bordered={true}
										rowSelection={rowActivityGoodsSelection}
									/>
								</Tabs.TabPane>
								<Tabs.TabPane tab="促销工具" key="6">
									<div className="market-type">
										<span className="market-type-title">营销类别：</span>
										<span
											className={`${this.state.coupon_category !== "activity_coupon" ? "text-note" : null}`}
											onClick={this.changeLucky.bind(this, "coupon_category", null)}>
											优惠券
									</span>
										<Divider type="vertical" />
										<span
											className={`${this.state.coupon_category === "activity_coupon" ? "text-note" : null}`}
											onClick={this.changeLucky.bind(this, "coupon_category", "activity_coupon")}>
											满减/满赠/满折
									</span>
									</div>
									<Form layout="inline">
										<Form.Item label={<span>{this.state.coupon_category === "activity_coupon" ? "满减/满赠" : "优惠券"}名称/序号</span>}>
											{getFieldDecorator("like_condition")(<Input className="form-input" />)}
										</Form.Item>
										<Form.Item>
											<Button shape="round" onClick={this.queryCouponList}>
												搜索
										</Button>
										</Form.Item>
									</Form>
									<div className="action">
										<Button type="primary" shape="round" onClick={this.goToCouponList}>
											管理
										{this.state.coupon_category === "activity_coupon" ? "满减/满赠" : "优惠券"}
										</Button>
										<span className="text-note" onClick={this.queryCouponList}>
											刷新
									</span>
									</div>
									<Table
										rowKey={record => record.coupon_id}
										columns={this.state.coupon_category === "activity_coupon" ? this.state.activity_coupon_columns : this.state.coupon_columns}
										dataSource={this.state.coupon_list}
										bordered={true}
										rowSelection={rowCouponSelection}
									/>
								</Tabs.TabPane>
								<Tabs.TabPane tab="系统页面" key="8">
									<Table
										rowKey={record => record.type}
										columns={this.state.columns}
										dataSource={this.state.system_pages}
										bordered={true}
										rowSelection={rowSystemPageSelection}
									/>
								</Tabs.TabPane>
								<Tabs.TabPane tab="直播间" key="7">
									<Form layout="inline">
										<Form.Item label="直播标题/id：">
											{getFieldDecorator("room_info_id_and_name")(<Input className="form-input" placeholder="输入直播ID" />)}
										</Form.Item>
										<Form.Item>
											<Button size="small" type="primary" shape="round" onClick={this.queryRoomInfoList}>
												搜索
										</Button>
										</Form.Item>
									</Form>
									<span className="text-note" onClick={this.queryRoomInfoList}>
										刷新
								</span>
									<Table
										columns={this.state.room_columns}
										rowKey={record => record.room_info_id}
										dataSource={this.state.room_info_list}
										bordered={true}
										rowSelection={rowRoomSelection}
									/>
								</Tabs.TabPane>
								<Tabs.TabPane tab="自定义" key="10">
									<Form wrapperCol={{ span: 20 }} labelCol={{ span: 4 }}>
										<Form.Item label="是否为公众号文章">
											<Switch
												checkedChildren="是"
												unCheckedChildren="否"
												onChange={this.onChangeSwitch}
												checked={this.state.isThePublic}
											/>
										</Form.Item>
										<Form.Item label="自定义路径：">
											<Input
												className="form-input"
												placeholder="输入自定义路径"
												value={this.state.custom_path_url}
												onChange={this.changePathUrl}
											/>
										</Form.Item>
									</Form>
								</Tabs.TabPane>
							</Tabs>
						)
				}
			</Modal>
		);
	}
}

const TargetTypeModalForm = Form.create({ name: "target_type_modal_form" })(TargetTypeModal);
export default TargetTypeModalForm;
