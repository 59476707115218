import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Input, Select, Button, Table, Pagination, Spin, Modal, Tooltip, Icon } from "antd";
import { getUserGiftCardList, cancelUserGiftCard } from "@service/getData";
import cookie from "react-cookies";
class CardReceiveRecord extends React.Component {
  constructor(props) {
    super(props);
    CardReceiveRecord.contextTypes = {
      router: PropTypes.object.isRequired
    };
    this.state = {
      loading: false,
      user_gift_card_list: [],
      page: 1,
      page_size: 10,
      total: 0,
      status_mapping: {
        0: "未激活",
        1: "待使用",
        2: "使用中",
        3: "已使用",
        4: "已失效",
        5: "已退卡"
      },
      color_mapping: {
        0: "text-danger",
        1: "text-green",
        4: "text-remind"
      },
      columns: [
        {
          title: "领取时间",
          dataIndex: "receive_time"
        },
        {
          title: "权益卡名称",
          dataIndex: "gift_card_name"
        },
        {
          title: "客户",
          dataIndex: "mobile",
          render: (text, record) => (
            <div>
              <span>{text}</span>
              <div className="text-note" onClick={this.goToClient.bind(this, record)}>
                {record.user_name || "未授权"}
              </div>
            </div>
          )
        },
        {
          title: "卡号",
          dataIndex: "gift_card_number"
        },
        {
          title: "状态",
          dataIndex: "status",
          render: text => (
            <div>
              <span className={this.state.color_mapping[text]}>{this.state.status_mapping[text]}</span>
              {text === 0 ? (
                <Tooltip
                  overlayClassName="post-desc"
                  style={{ width: "400px" }}
                  title={<div>未激活：客户已经购买权益卡，但尚未绑定至账户</div>}>
                  <Icon type="question-circle" theme="twoTone" />
                </Tooltip>
              ) : null}
            </div>
          )
        },
        {
          title: "操作",
          dataIndex: "action",
          render: (text, record) => (
            <div>
              {record.status !== 5 ? (
                <span onClick={this.showRefundConfirm.bind(this, record.user_gift_card_id)} className="text-note">
                  退卡
                </span>
              ) : null}
              {record.oms_order && record.oms_order.order_id ? (
                <span className="text-note" onClick={this.gotoOrderDetail.bind(this, record.oms_order)}>
                  详情
                </span>
              ) : null}
            </div>
          )
        }
      ]
    };
  }
  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.getUserCardList();
  }
  onShowPageChange = page => {
    this.setState(
      {
        page: page
      },
      () => {
        this.getUserCardList();
      }
    );
  };
  onShowSizeChange = (current, pageSize) => {
    this.setState(
      {
        page_size: pageSize
      },
      () => {
        this.getUserCardList();
      }
    );
  };
  clearFilter = () => {
    this.props.form.resetFields();
  };
  getUserCardList = async () => {
    let form = this.props.form.getFieldsValue();
    let data = {
      mini_app_id: cookie.load("mini_app_id"),
      page_no: this.state.page,
      page_size: this.state.page_size,
      gift_card_name: form.gift_card_name,
      gift_card_number: form.gift_card_number,
      order_sn: form.order_sn,
      mobile: form.mobile,
      status: form.status,
      order_detail_ext: true
    };
    this.setState({
      loading: true
    });
    let res = await getUserGiftCardList(data);
    let list = res.result.user_gift_card_list;
    this.setState({
      user_gift_card_list: list,
      total: res.result.total,
      loading: false
    });
  };
  gotoOrderDetail = oms_order => {
    (oms_order && oms_order.order_id) && window.open(`${window.location.origin}/orderDetail?order_id=${oms_order.order_id}&type=orderManage`, "_blank")
  };
  goToClient = client => {
    this.context.router.history.push("/client", client.user_id);
  };
  showRefundConfirm = user_gift_card_id => {
    Modal.confirm({
      title: "确定退卡?",
      onOk: () => {
        this.cancelUserGiftCard(user_gift_card_id);
      }
    });
  };
  cancelUserGiftCard = async user_gift_card_id => {
    await cancelUserGiftCard({
      user_gift_card_id
    });
    this.getUserCardList();
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Spin spinning={this.state.loading}>
        <div className="card-content">
          <div className="filter-content">
            <Form className="ant-vertical flex" layout="inline">
              <Form.Item label="权益卡名称">
                {getFieldDecorator("gift_card_name", {
                  initialValue: this.props.params && this.props.params.gift_card_name ? this.props.params.gift_card_name : null
                })(<Input className="card-name" placeholder="输入权益卡名称" />)}
              </Form.Item>
              <Form.Item label="权益卡卡号">
                {getFieldDecorator("gift_card_number")(<Input className="card-number" placeholder="输入权益卡卡号" />)}
              </Form.Item>
              <Form.Item label="客户手机号">
                {getFieldDecorator("mobile")(<Input className="card-user" placeholder="输入手机号" />)}
              </Form.Item>
              <Form.Item label="状态">
                {getFieldDecorator("status", {
                  initialValue: this.props.params && this.props.params.status ? this.props.params.status : null
                })(
                  <Select className="card-status">
                    {Object.keys(this.state.status_mapping).map(item => (
                      <Select.Option value={item} key={item}>
                        {this.state.status_mapping[item]}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label="原始订单号">
                {getFieldDecorator("order_sn")(<Input className="order-number" placeholder="输入订单号" />)}
              </Form.Item>
            </Form>
            <div className="action">
              <Button shape="round" type="primary" onClick={this.getUserCardList}>
                筛选
              </Button>
              <span className="text-note" onClick={this.clearFilter}>
                清空筛选条件
              </span>
            </div>
          </div>
          <Table
            rowKey={(record, index) => index}
            columns={this.state.columns}
            dataSource={this.state.user_gift_card_list}
            pagination={false}
            bordered={true}
          />
          <Pagination
            className="pagenation"
            onChange={this.onShowPageChange}
            onShowSizeChange={this.onShowSizeChange}
            defaultCurrent={this.state.page}
            total={this.state.total}
            showTotal={total => `共 ${total} 条`}
            pageSizeOptions={["10", "30", "50", "100"]}
            showSizeChanger
          />
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};
const CardReceiveRecordForm = Form.create({ name: "card_receive_record" })(CardReceiveRecord);
export default connect(mapStateToProps)(CardReceiveRecordForm);
