import React from "react";
import TopBar from "../view/component/common/TopBar";
import SideBar from "../view/component/common/SideBar";
import Message from "../view/component/Message/Message";
export default class Main extends React.Component {
	constructor() {
		super();
		this.state = {
			move_x: 0,
			move_y: 0,
			onlineX: 0,
			onlineY: 0,
		};
	}
	dragover_handler = ev => {
		ev.persist();
		ev.preventDefault();
		ev.dataTransfer.dropEffect = "move";
	};
	drop_handler = ev => {
		ev.persist();
		ev.preventDefault();
		let start_x = ev.dataTransfer.getData("start_x"),
			start_y = ev.dataTransfer.getData("start_y"),
			left = ev.dataTransfer.getData("left"),
			top = ev.dataTransfer.getData("top");
		let end_x = ev.pageX,
			end_y = ev.pageY;
		let dragEle = ev.dataTransfer.getData('dragEle');
		let move_to_x;
		let move_to_y;
		let onlineX;
		let onlineY;
		if (dragEle !== 'olineServiceWrap') {
			move_to_x = +left + +end_x - +start_x;
			move_to_y = +top + +end_y - +start_y;
			this.setState({
				move_to_x,
				move_to_y,
			});
		} else {
			onlineX = ev.dataTransfer.getData('olineLeft') - (ev.dataTransfer.getData('olineStartX') - end_x);
			onlineY = ev.dataTransfer.getData('olineTop') - (ev.dataTransfer.getData('olineStartY') - end_y);
			this.setState({
				onlineX,
				onlineY,
			});
		}
	};
	render() {
		return (
			<div className="main">
				<TopBar className="userDashboardTopBar" />
				<div className="content" onDrop={this.drop_handler} onDragOver={this.dragover_handler}>
					<SideBar className="userDashboardSideBar" />
					<Message
						move_to_x={this.state.move_to_x}
						move_to_y={this.state.move_to_y}
						onlineX={this.state.onlineX}
						onlineY={this.state.onlineY}
					/>
					{this.props.children}
				</div>
			</div>
		);
	}
}
