import React from 'react'
import { Select, Form, AutoComplete, Tooltip, Checkbox } from 'antd'
import '@css/goodsAttr.scss';
import SkuPhoto from '@/view/component/common/SkuPhoto'

class GoodsAttr extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: null,
            time: [{
                attribute_value_id: null,
                isValue: null,
                value: null
            }],
            value: null,
            attr: null,
            visible: false,
            fileList: [],
            attr_name: null
        }

    }

    UNSAFE_componentWillReceiveProps(e) {
        let data = []
        e.arrValue.map(item => {
            data.push(item.attribute_value_id + "")
        })
        let attr = [];
        e.attr.map(item => {
            if (item.attribute_id == e.attr_id) {
                attr = item.attribute_value_res_list
            }
        }
        )
        let options = []
        attr.map(item => {
            if (data.indexOf(item.attribute_value_id + "") < 0) {
                options.push((
                    <AutoComplete.Option
                        key={item.attribute_value_id}
                        value={"?" + item.attribute_value_id + ""}>
                        {item.attribute_value}
                    </AutoComplete.Option>
                ))
            }
        }
        );
        let time = this.props.arrValue.concat([])
        time.map(item => {
            if (item.isValue) {
                attr.forEach(option => {
                    if (option.attribute_value == item.attribute_value_id) {
                        item.attribute_value_id = option.attribute_value_id
                        this.props.valueChange(this.props.index, time)
                    }
                    if (option.attribute_value_id == item.attribute_value_id) {
                        item.value = option.attribute_value

                    }
                })
            }
            attr.forEach(option => {
                if (option.attribute_value_id == item.attribute_value_id) {
                    item.value = option.attribute_value

                }
            })
        })
        if (time.length === 0) {
            time = [{
                attribute_value_id: null
            }]
        }
        this.setState({
            options: options,
            time: time,
            attr: attr,
            attr_name: this.props.attr_id
        })

    }

    blur = (index, value) => {
        let time = [].concat(this.state.time)
        if (value == null || value == undefined) {
            time[index] = {
                attribute_value_id: null,
                isValue: false,
                value: null
            }
            this.setState({
                time: time
            }, () => { this.props.valueChange(this.props.index, time) })
            return
        }

        if (value[0] != "?") {
            for (let i = 0; i < index; i++) {
                if ((value + "").replace(/(^\s*)|(\s*$)/g, "") == time[i].value) {
                    time[index].value = null
                    this.setState({
                        time: time
                    })
                    return
                }
            }
            for (let i = 0; i < this.state.attr.length; i++) {
                if (this.state.attr[i].attribute_value == (value + "").replace(/(^\s*)|(\s*$)/g, "")) {
                    let time = this.state.time.concat([])
                    time[index].attribute_value_id = this.state.attr[i].attribute_value_id
                    this.setState({
                        time: time
                    }, () => { this.props.valueChange(this.props.index, time) })
                    return
                }
            }
            this.props.addAttrValue(this, this.props.attr_id, value)
            time[index].attribute_value_id = value
            time[index].isValue = true
            time[index].value = value
        } else {
            time[index].attribute_value_id = value.replace("?", "");
            time[index].isValue = false
        }

        this.setState({
            time: time
        }, () => { this.props.valueChange(this.props.index, time) })
    }
    addTime = () => {
        let time = [].concat(this.state.time)
        time.push({
            attribute_value_id: null
        })
        this.setState({
            time: time
        })
    }
    deleteAttrValue = (index) => {
        let time = this.state.time.concat([])
        time.splice(index, 1)
        this.setState({
            time: time
        }, () => { this.props.valueChange(this.props.index, time) })

    }


    handleSearch = (value) => {
        this.setState({
            dataSource: !value ? [] : [
                value,
                value + value,
                value + value + value,
            ],
        });
    }
    handleChange = (index, e) => {
        let time = this.state.time.concat([])
        time[index].value = e
        this.setState({
            time: time
        })

    }
    handleCancel = () => {
        this.setState({
            visible: false
        })
    }
    handelOK = (url, index) => {
        let time = this.state.time.concat([])
        time[index].goods_attribute_value_thumbnail = url
        this.setState({
            visible: false,
            time: time
        }, () => { this.props.modifyThumbnail(this.props.index, time) })
    }
    changeCheck(e) {
        const time = this.state.time.map((item) => {
            item.goods_attribute_value_thumbnail = '';
            return item;
        });
        if (!e.target.checked) {
            this.setState({
                time,
            }, () => {
                this.props.modifyThumbnail(this.props.index, time);
            });
        }
        this.props.onChangeChecked(e);
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="goods-attr">
                <div className="attr-name">
                    <Form layout="inline">
                        <Form.Item label="规格名">
                            <Select
                                className="goods-attr-content"
                                onChange={this.props.change}
                                value={this.props.attr_id}
                                disabled={!this.props.editable}
                            >
                                {this.props.attr.map((item, index) =>
                                    this.props.attrList.some((attr, attr_index) => {
                                        return this.props.index != attr_index && attr.attribute_id == item.attribute_id
                                    }) ? null :
                                        (
                                            <Select.Option
                                                key={index}
                                                value={item.attribute_id}
                                            >
                                                {item.attribute_name}
                                            </Select.Option>
                                        )
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item className={this.props.index === 0 ? null : "hidden"}>
                            <Checkbox
                                disabled={!this.props.editable}
                                checked={this.props.checked}
                                onChange={this.changeCheck.bind(this)}>
                                <span className="text-remind">添加规格图片，建议尺寸：800*800像素</span>
                            </Checkbox>
                        </Form.Item>
                    </Form>
                </div>
                <Form className="attr-value" layout="inline">
                    <Form.Item label="规格值">
                        <div>
                            {this.state.time.map((item, index) => (
                                <Tooltip
                                    key={index}
                                    overlayClassName="attr-tooltip"
                                    key={index}
                                    placement="bottom"
                                    visible={this.props.checked}
                                    title={(
                                        <SkuPhoto visible={this.state.visible}
                                            handelCancel={this.handleCancel}
                                            index={index}
                                            handelOK={this.handelOK}
                                            fileList={!!item.goods_attribute_value_thumbnail ? [{
                                                key: 0,
                                                uid: '-1',
                                                status: 'done',
                                                url: item.goods_attribute_value_thumbnail
                                            }] : []}
                                        />
                                    )}>
                                    <span key={"sapn" + index} className="value-show">
                                        <AutoComplete
                                            disabled={!this.props.editable}
                                            key={index}
                                            value={item.value}
                                            onChange={this.handleChange.bind(this, index)}
                                            dataSource={this.state.options}
                                            style={{ width: "100px", marginRight: "30px", marginBottom: this.props.checked ? '130px' : null }}
                                            dropdownMenuStyle={{ zIndex: 99999 }}
                                            onSelect={this.blur.bind(this, index)}
                                            onSearch={this.handleSearch}
                                            onBlur={this.blur.bind(this, index)}

                                        />
                                        <i
                                            key={"delete" + index}
                                            className={` iconfont icon-shanchu1  value-delete ${!this.props.editable ? 'hidden' : null}`}
                                            onClick={this.deleteAttrValue.bind(this, index)}></i>
                                    </span>
                                </Tooltip>
                            ))}
                            <span className={`text-note  attr-value-add ${this.state.time.length > 40 || !this.props.editable ? 'hidden' : null}`} onClick={this.addTime}>添加规格值</span>
                        </div>
                    </Form.Item>
                </Form>


                <div className={` attr-name-delete ${!this.props.editable ? 'hidden' : null}`}>
                    <i
                        className="iconfont icon-shanchu1 delete-show" onClick={this.props.deleteAttr}></i>
                </div>
            </div>
        )
    }
}

const GoodsAttrForm = Form.create({ name: 'goods_add' })(GoodsAttr);

export default GoodsAttrForm;
