import React from 'react'
import { Button, Modal, Form, Input, Radio, Table, Collapse, Pagination, message } from 'antd'
import "@css/shippingManage.scss";
import CheckAddress from '@/view/component/common/CheckAddress'
import {
    queryShippingTemplateList,
    createOrUpdateShippingTemplateAndDetail,
    queryRegionDetail
} from '@service/getData'
import cookie from 'react-cookies'
import { formatNumber } from "@common/common";
import NavBar from "@/view/component/common/NavBar.js";
import config from "@config/config";
import { connect } from "react-redux";

class ShippingManage extends React.Component {
    constructor() {
        super()
        this.state = {
            requestUrl: config.baseUrl + "/file/download?url=",
            visible: false,
            adressVisible: false,
            numsColumns: [
                {
                    title: "可配送区域",
                    dataIndex: "shipping_template_item_desc",
                    render: (text, record, index) => {
                        return record.shipping_template_item_id ? text : (
                            <div className="shipment-template-info">
                                <div className="shipment-template-desc">
                                    {text}
                                </div>
                                <div className="shipment-template-action">
                                    <span className="text-note"
                                        onClick={this.modifyTemplate.bind(this, record, index)}>修改</span>
                                    <span className="text-note" onClick={this.deleteTemplate.bind(index)}>删除</span>
                                </div>
                            </div>
                        )
                    }
                }
                ,
                {
                    title: "首件(个)",
                    dataIndex: "first_unit",
                    render: (text, record, index) => {
                        return record.shipping_template_item_id ? text : (
                            <Input onChange={this.changeValue.bind(this, index, "first_unit")}
                                className="goods-add-input" value={text} />)
                    }

                },
                {
                    title: "运费(元)",
                    dataIndex: "first_unit_fee",
                    render: (text, record, index) => {
                        return record.shipping_template_item_id ? text : (
                            <Input onChange={this.changeValue.bind(this, index, "first_unit_fee")}
                                className="goods-add-input" value={text} />)
                    }
                },
                {
                    title: "续件(个)",
                    dataIndex: "additional_unit",
                    render: (text, record, index) => {
                        return record.shipping_template_item_id ? text : (
                            <Input onChange={this.changeValue.bind(this, index, "additional_unit")}
                                className="goods-add-input" value={text} />)
                    }
                },
                {
                    title: "续费(元)",
                    dataIndex: "additional_unit_fee",
                    render: (text, record, index) => {
                        return record.shipping_template_item_id ? text : (
                            <Input onChange={this.changeValue.bind(this, index, "additional_unit_fee")}
                                className="goods-add-input" value={text} />)
                    }
                }
            ],
            weightColumns: [
                {
                    title: "可配送区域",
                    dataIndex: "shipping_template_item_desc",
                    render: (text, record, index) => {
                        return record.shipping_template_item_id ? text : (
                            <div className="shipment-template-info">
                                <div className="shipment-template-desc">
                                    {text}
                                </div>
                                <div className="shipment-template-action">
                                    <span className="text-note"
                                        onClick={this.modifyTemplate.bind(this, record, index)}>修改</span>
                                    <span className="text-note"
                                        onClick={this.deleteTemplate.bind(this, index)}>删除</span>
                                </div>
                            </div>
                        )
                    }
                },
                {
                    title: "首重(Kg)",
                    dataIndex: "first_unit",
                    render: (text, record, index) => {
                        return record.shipping_template_item_id ? text : (
                            <Input onChange={this.changeValue.bind(this, index, "first_unit")}
                                className="goods-add-input" value={text} />)
                    }
                    ,
                },
                {
                    title: "运费(元)",
                    dataIndex: "first_unit_fee",
                    render: (text, record, index) => {
                        return record.shipping_template_item_id ? text : (
                            <Input onChange={this.changeValue.bind(this, index, "first_unit_fee")}
                                className="goods-add-input" value={text} />)
                    }
                },
                {
                    title: "续重(Kg)",
                    dataIndex: "additional_unit",
                    render: (text, record, index) => {
                        return record.shipping_template_item_id ? text : (
                            <Input onChange={this.changeValue.bind(this, index, "additional_unit")}
                                className="goods-add-input" value={text} />)
                    }
                },
                {
                    title: "续费(元)",
                    dataIndex: "additional_unit_fee",
                    render: (text, record, index) => {
                        return record.shipping_template_item_id ? text : (
                            <Input onChange={this.changeValue.bind(this, index, "additional_unit_fee")}
                                className="goods-add-input" value={text} />)
                    }
                }
            ],
            page: 1,
            total: 0,
            page_size: 10,
            templateList: [],
            addTemplateList: [],
            template: {
                area_list: [],
                province_list: [],
                city_list: [],
                district_list: [],
            },
            modifyAddress: false,
            areaData: [],
            modifyTemplate: null

        }
    }

    async queryShippingTemplateList() {
        let data = {
            shop_id: cookie.load("shop_id"),
            page_size: this.state.page_size,
            page_no: this.state.page
        }
        let res = await queryShippingTemplateList(data)

        if (res.success) {
            let templateList = res.result.shipping_template_res_list
            templateList.forEach(templateItem => {
                templateItem.shipping_template_item_res_list.forEach(oItem => {
                    let areaList = [], provinceList = [], cityList = [], districtList = [];
                    oItem.shipping_template_item_detail_res.forEach(item => {
                        let currentArea = this.state.areaData.find(area => area.area_id === item.area_id)
                        let sameAreaProvinceList = provinceList.filter(province => province.area_id === item.area_id)
                        let currentProvince = currentArea.region_res_list.find(province => province.region_id === item.province_id)
                        if (item.city_id === null) {
                            if (sameAreaProvinceList.length + 1 === currentArea.region_res_list.length) {
                                provinceList = provinceList.filter(province => province.area_id !== item.area_id)
                                areaList.push(currentArea)
                            } else {
                                provinceList.push(currentProvince)
                            }
                        } else if (item.city_id && item.district_id === null) {
                            let currentCity = currentProvince.region_res_list.find(city => city.region_id === item.city_id);
                            let sameProvinceCityList = cityList.filter(city => city.provicne_id === item.province_id);
                            if (sameProvinceCityList.length + 1 === currentProvince.region_res_list.length) {
                                cityList = cityList.filter(city => city.province_id !== item.province_id);
                                if (sameAreaProvinceList.length + 1 === currentArea.region_res_list.length) {
                                    provinceList = provinceList.filter(province => province.area_id !== item.area_id)
                                    areaList.push(currentArea)
                                } else {
                                    provinceList.push(currentProvince)
                                }
                            } else {
                                cityList.push(currentCity)
                            }
                        } else if (item.district_id) {
                            let currentCity = currentProvince.region_res_list.find(city => city.region_id === item.city_id);
                            let currentDistrict = currentCity.region_res_list.find(district => district.region_id === item.district_id)
                            let sameProvinceCityList = cityList.filter(city => city.provicne_id === item.province_id);
                            let sameCityDistrictList = districtList.filter(district => district.city_id === item.city_id);
                            if (sameCityDistrictList.length + 1 === currentCity.region_res_list) {
                                districtList = districtList.filter(district => district.city_id !== item.city_id);
                                if (sameProvinceCityList.length + 1 === currentProvince.region_res_list.length) {
                                    cityList = cityList.filter(city => city.province_id !== item.province_id);
                                    if (sameAreaProvinceList.length + 1 === currentArea.region_res_list.length) {
                                        provinceList = provinceList.filter(province => province.area_id !== item.area_id)
                                        areaList.push(currentArea)
                                    } else {
                                        provinceList.push(currentProvince)
                                    }
                                } else {
                                    cityList.push(currentCity)
                                }
                            } else {
                                districtList.push(currentDistrict)
                            }
                        }
                    })
                    oItem.area_list = areaList;
                    oItem.province_list = provinceList;
                    oItem.city_list = cityList;
                    oItem.district_list = districtList
                    oItem.first_unit_fee = oItem.first_unit_fee / 100;
                    oItem.additional_unit_fee = oItem.additional_unit_fee / 100;
                    if (templateItem.shipping_fee_type === "weight") {
                        oItem.first_unit = oItem.first_unit / 1000;
                        oItem.additional_unit = oItem.additional_unit / 1000;
                    }
                })
            })
            this.setState({
                templateList: templateList,
                total: res.result.total
            })

        }
    }

    async createOrUpdateShippingTemplateAndDetail(data) {
        let res = await createOrUpdateShippingTemplateAndDetail(data);
        if (res.success) {
            message.success("操作成功")
            this.setState({
                visible: false,
                addTemplateList: [],
                template: {
                    area_list: [],
                    province_list: [],
                    city_list: [],
                    district_list: [],
                    modifyTemplate: null,
                    addTemplateList: []
                },

            }, () => {
                this.queryShippingTemplateList()
            });
        } else {
            message.error(res.msg)
        }
    }

    async queryRegionDetail() {
        let res = await queryRegionDetail({})
        let areaData = res.result.area_res_list;
        areaData.forEach(item => {
            item.region_res_list.forEach(province => {
                province.area_id = item.area_id;
                province.region_res_list.forEach(city => {
                    city.area_id = item.area_id;
                    city.province_id = province.region_id;
                    city.region_res_list.forEach(district => {
                        district.area_id = item.area_id;
                        district.province_id = province.region_id;
                        district.city_id = city.region_id
                    })
                })
            })
        })
        if (res.success) {
            this.setState({
                areaData: areaData
            }, () => {
                this.queryShippingTemplateList()
            })
        }
    }

    showModal = e => {
        this.props.form.resetFields();
        this.setState({
            visible: true,
            addTemplateList: [],
            modifyTemplate: null
        });
    };
    handleOk = e => {
        let form = this.props.form.getFieldsValue();
        let data = {
            shop_id: cookie.load("shop_id"),
            shipping_fee_type: form.shipping_fee_type,
            status: 1,

        }
        if (!form.shipping_template_name) {
            return message.error("请填写模版名称")
        }
        let flag = false;
        this.state.addTemplateList.forEach(item => {
            if (!item.first_unit.toString() || !item.first_unit_fee.toString() || !item.additional_unit.toString() || !item.additional_unit_fee.toString()) {
                flag = true
            }
        })

        if (!this.state.addTemplateList.length || flag) {
            return message.error("请配置区域")
        }
        let addTemplateList = this.state.addTemplateList.concat([]);
        const isRightUnit = addTemplateList.every((unit) => {
            if (+unit.additional_unit === 0) {
                return false;
            }
            return true;
        });
        if (!isRightUnit) {
            message.error('续重或者续件不能为0');
            return;
        }
        addTemplateList.forEach(item => {
            item.first_unit_fee = Math.round(item.first_unit_fee * 100)
            item.additional_unit_fee = Math.round(item.additional_unit_fee * 100)
            if (form.shipping_fee_type === "weight") {
                item.first_unit = Math.round(item.first_unit * 1000);
                item.additional_unit = Math.round(item.additional_unit * 1000);
            }
        })
        data.shipping_template_name = form.shipping_template_name;
        data.shipping_template_item_req_list = this.state.addTemplateList
        if (this.state.modifyTemplate) {
            data.shipping_template_id = this.state.modifyTemplate.shipping_template_id;
            data.status = 1;
        }
        this.createOrUpdateShippingTemplateAndDetail({ shipping_template_req: data })
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };
    showAdressModal = e => {
        this.setState({
            adressVisible: true,
            template: {
                area_list: [],
                province_list: [],
                city_list: [],
                district_list: [],
                modifyTemplate: null,
                addTemplateList: []
            }
        });
    };
    handleAdressOk = (areaList, provinceList, cityList, districtList) => {
        let shipment_template_item_req_list = []
        let shipping_template_item_desc = []
        areaList.forEach(item => {
            item.region_res_list.forEach(province => {
                shipping_template_item_desc.push(province.region_name)
                shipment_template_item_req_list.push({ province_id: province.region_id })
            })
        })
        provinceList.forEach(province => {
            shipping_template_item_desc.push(province.region_name)
            shipment_template_item_req_list.push({ province_id: province.region_id })

        })
        cityList.forEach(city => {
            shipping_template_item_desc.push(city.region_name)
            shipment_template_item_req_list.push({ city_id: city.region_id })
        })
        districtList.forEach(district => {
            shipping_template_item_desc.push(district.region_name)
            shipment_template_item_req_list.push({ district_id: district.region_id })
        })
        let addTemplateList = this.state.addTemplateList.concat([])
        if (this.state.modifyAddress) {
            addTemplateList[this.state.template.index].shipping_template_item_detail_req = shipment_template_item_req_list;
            addTemplateList[this.state.template.index].area_list = areaList;
            addTemplateList[this.state.template.index].province_list = provinceList;
            addTemplateList[this.state.template.index].city_list = cityList;
            addTemplateList[this.state.template.index].district_list = districtList;
            addTemplateList[this.state.template.index].shipping_template_item_desc = shipping_template_item_desc.join(",")

        } else {
            let data = {
                shipping_template_item_detail_req: shipment_template_item_req_list,
                first_unit: null,
                first_unit_fee: null,
                additional_unit: null,
                additional_unit_fee: null,
                area_list: areaList,
                province_list: provinceList,
                city_list: cityList,
                district_list: districtList,
                shipping_template_item_desc: shipping_template_item_desc.join(",")
            }
            addTemplateList.push(data)
        }
        this.setState({
            adressVisible: false,
            addTemplateList: addTemplateList,
            modifyAddress: false,
            template: {
                area_list: [],
                province_list: [],
                city_list: [],
                district_list: [],
            },
        });
    };

    handleAdressCancel = e => {
        this.setState({
            adressVisible: false,
            template: {
                area_list: [],
                province_list: [],
                city_list: [],
                district_list: [],
            },
            modifyAddress: false
        });
    };
    onShowPageChange = (page) => {
        this.setState({
            page: page
        }, () => {
            this.queryShippingTemplateList()
        })
    }
    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.queryShippingTemplateList()
        })
    }
    changeValue = (index, valueName, e) => {
        e.persist()
        let value = e.target.value
        value = formatNumber(value)
        let addTemplateList = this.state.addTemplateList.concat([])
        addTemplateList[index][valueName] = value

        this.setState({
            addTemplateList: addTemplateList
        })
    }
    deleteTemplate = (index) => {
        let addTemplateList = this.state.addTemplateList.concat([])
        addTemplateList.splice(index, 1)
        this.setState({
            addTemplateList: addTemplateList
        })

    }

    modifyTemplate(record, index) {
        record.index = index;
        this.setState({
            template: record,
            adressVisible: true,
            modifyAddress: true
        })
    }

    modifyCurrentTemplate = (item, e) => {
        window.event ? window.event.cancelBubble = true : e.stopPropagation();
        let addTemplateList = [];
        item.shipping_template_item_res_list.forEach(item => {
            let shipping_template_item_detail_req = []
            item.shipping_template_item_detail_res.forEach(item => {
                let data = {
                    province_id: item.province_id,
                    city_id: item.city_id,
                    district_id: item.district_id
                }
                shipping_template_item_detail_req.push(data)
            })
            let templateItem = {
                shipping_template_item_desc: item.shipping_template_item_desc,
                first_unit: item.first_unit,
                first_unit_fee: item.first_unit_fee,
                additional_unit: item.additional_unit,
                additional_unit_fee: item.additional_unit_fee,
                area_list: item.area_list,
                province_list: item.province_list,
                city_list: item.city_list,
                district_list: item.district_list,
                shipping_template_item_detail_req: shipping_template_item_detail_req
            }
            addTemplateList.push(templateItem)
        })
        this.setState({
            addTemplateList: addTemplateList,
            visible: true,
            modifyTemplate: item

        })
    }
    deleteCurrentTemplate = (item, e) => {
        window.event ? window.event.cancelBubble = true : e.stopPropagation();
        let data = {
            shipping_template_id: item.shipping_template_id,
            status: 0
        }
        this.createOrUpdateShippingTemplateAndDetail({ shipping_template_req: data })

    }
    copyCurrentTemplate = (template, e) => {
        window.event ? window.event.cancelBubble = true : e.stopPropagation();
        let data = {
            shipping_fee_type: template.shipping_fee_type,
            shipping_template_name: template.shipping_template_name,
            status: 1,
            shop_id: cookie.load("shop_id")
        }
        let shipment_template_item_req_list = []
        template.shipping_template_item_res_list.forEach(item => {
            let data = {
                first_unit: item.first_unit,
                first_unit_fee: Math.round(item.first_unit_fee * 100),
                additional_unit: item.additional_unit,
                additional_unit_fee: Math.round(item.additional_unit_fee * 100)
            }
            if (template.shipping_fee_type === "weight") {
                data.first_unit = Math.round(item.first_unit * 1000)
                data.additional_unit = Math.round(item.additional_unit * 1000)
            }
            let shipping_template_item_detail_res = [];
            item.shipping_template_item_detail_res.forEach(item => {
                let data = {
                    province_id: item.province_id,
                    city_id: item.city_id,
                    district_id: item.district_id
                }
                shipping_template_item_detail_res.push(data)
            })
            data.shipping_template_item_detail_req = shipping_template_item_detail_res;
            shipment_template_item_req_list.push(data)

        })
        data.shipping_template_item_req_list = shipment_template_item_req_list;
        this.createOrUpdateShippingTemplateAndDetail({ shipping_template_req: data })
    }


    componentDidMount() {
        this.queryRegionDetail()
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div className="shipping-manage">
                <NavBar
                    icon="icon-fahuo"
                    child={"快递模版"}
                />
                <div className="content">
                    <div className="action">
                        <Button type="primary" shape="round" onClick={this.showModal}>添加快递模版</Button>
                        <span className="text-note"><a
                            href="/shippingTemplete" target="_blank">运费计费规则</a></span>
                    </div>
                    <div>
                        <Collapse defaultActiveKey={[]}>
                            {
                                this.state.templateList.map((item, index) => {
                                    return (
                                        <Collapse.Panel header={(
                                            <div className="template-action">
                                                <span className="template-name">{item.shipping_template_name}</span>
                                                <span className="text-note"
                                                    onClick={this.deleteCurrentTemplate.bind(this, item)}>删除</span>
                                                <span className="text-note"
                                                    onClick={this.modifyCurrentTemplate.bind(this, item)}>修改</span>
                                                <span className="text-note"
                                                    onClick={this.copyCurrentTemplate.bind(this, item)}>复制模版</span>
                                            </div>)} key={index}>
                                            <Table rowKey={(record, index) => index}
                                                columns={item.shipping_fee_type === "number" ? this.state.numsColumns : this.state.weightColumns}
                                                dataSource={item.shipping_template_item_res_list}
                                                pagination={false}
                                            />

                                        </Collapse.Panel>
                                    )
                                })
                            }
                        </Collapse>
                        <Pagination
                            className="pagenation"
                            onChange={this.onShowPageChange}
                            onShowSizeChange={this.onShowSizeChange}
                            defaultCurrent={this.state.page}
                            total={this.state.total}
                            showTotal={(total) => `共 ${total} 条`}
                            pageSizeOptions={["10", "30", "50", "100"]}
                            showSizeChanger
                        />
                    </div>
                    <Modal
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        title={this.state.modifyTemplate == null ? "创建运费模版" : "修改运费模版"}
                        visible={this.state.visible}
                        className="template-modal"
                        destroyOnClose={true}
                        okButtonProps={{ shape: 'round' }}
                        cancelButtonProps={{ shape: 'round' }}
                    >
                        <Form>
                            <Form.Item label="模板名称">
                                {getFieldDecorator('shipping_template_name', {
                                    initialValue: this.state.modifyTemplate == null ? null : this.state.modifyTemplate.shipping_template_name
                                })(
                                    <Input className="input-name" />
                                )}
                            </Form.Item>
                            <Form.Item label="计费方式">
                                {getFieldDecorator('shipping_fee_type', {
                                    initialValue: this.state.modifyTemplate == null ? "number" : this.state.modifyTemplate.shipping_fee_type
                                })(
                                    <Radio.Group>
                                        <Radio value={"number"}>按件数</Radio>
                                        <Radio value={"weight"}>按重量</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                            <Form.Item label="配送区域">
                                <Table
                                    columns={this.props.form.getFieldsValue().shipping_fee_type === "number" ? this.state.numsColumns : this.state.weightColumns}
                                    dataSource={this.state.addTemplateList}
                                    rowKey={(record, index) => index}
                                />
                            </Form.Item>
                        </Form>
                        <div className="action">
                            <Button type="primary" shape="round" onClick={this.showAdressModal}>指定配送区域和运费</Button>
                        </div>
                    </Modal>
                    <CheckAddress
                        visible={this.state.adressVisible}
                        handleOk={this.handleAdressOk}
                        handleCancel={this.handleAdressCancel}
                        areaList={this.state.template.area_list}
                        provinceList={this.state.template.province_list}
                        cityList={this.state.template.city_list}
                        districtList={this.state.template.district_list}
                        modifyAdress={this.state.modifyAddress}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        menu_code: state.menuCode
    };
};

const ShippingForm = Form.create({ name: 'shipping_form' })(ShippingManage);
export default connect(mapStateToProps)(ShippingForm)
