import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import CacheRoute, { CacheSwitch } from "react-router-cache-route";
import Main from "./main";
import Login from "../view/container/login/Login";
import Home from "../view/container/home/index";
import Official from "../view/container/official/Official";
import UserDashboard from "../view/container/dashboard/UserDashboard";
import ShippingTemplete from "../view/container/shippingTemplete/ShippingTemplete"
import CodeManage from "../view/container/codeManage/CodeManage";
import CodeDetail from "../view/container/codeManage/children/CodeDetail";
import GoodsManage from "../view/container/goods/GoodsManage";
import BalanceManage from "../view/container/balance/BalanceManage";
import GoodsAdd from "../view/container/goods/children/GoodsAdd";
import GoodsDetail from "../view/container/goods/children/GoodsDetail";
import GoodsGroupDetail from "../view/container/goods/children/GoodsGroupDetail";
import GroupManage from "../view/container/group/GroupManage";
import GroupAdd from "../view/container/group/children/GroupAdd";
import GroupDetail from "../view/container/group/children/GroupDetail";
import OrderManage from "../view/container/order/OrderManage";
import OrderDetail from "../view/container/order/children/OrderDetail";
import OrderShipmentHistory from "../view/container/order/children/OrderShipmentHistory";
import GroupOrderShipmentHistory from "../view/container/order/children/GroupOrderShipmentHistory";
import OrderShipment from "../view/container/order/children/OrderShipment";
import BatchShipment from "../view/container/order/children/BatchShipment";
import Template from "../view/container/template/Template";
import AfterSaleManage from "../view/container/afterSale/AfterSaleManage";
import UserManage from "../view/container/userManage/UserManage";
import AppletManage from "../view/container/appletManage/AppletManage";
import MerchantManage from "../view/container/merchantManage/MerchantManage";
import MerchantAdd from "../view/container/merchantManage/children/MerchantAdd";
import AdminMerchantManage from "../view/container/adminMerchantManage/AdminMerchantManage";
import AppletApplyManage from "../view/container/appletApplyManage/AppletApplyManage";
import BonusRecharge from "../view/container/money/BonusRecharge";
import BonusSettle from "../view/container/money/BonusSettle";
import BonusStream from "../view/container/money/BonusStream";
import DriverManage from "../view/component/Order/DriverManage";
import LeaderManage from "../view/container/setting/LeaderManage";
import LeaderInterview from "../view/container/setting/LeaderInterview";
import LeaderBonus from "../view/container/setting/LeaderBonus";
import ModifyPassword from "../view/container/login/ModifyPassword";
import AppOrder from "../view/container/appOrder/AppOrder";
import ActivitySetting from "../view/container/activitySetting/ActivitySetting";
import CampainSetting from "../view/container/activitySetting/CampainSetting";
import Activity from "../view/container/activitySetting/children/Activity";
import ActivityTypeSetting from "../view/container/activityTypeSetting/ActivityTypeSetting";
import GroupGoodsManage from "../view/container/groupGoodsManage/GroupGoodsManage";
import PageManage from "../view/container/pageManage/PageManage";
import UserPageManage from "../view/container/userPageManage/UserPageManage";
import ShippingManage from "../view/container/shopManage/children/ShippingManage";
import MarketSetting from "../view/container/marketSetting/MarketSetting";
import Market from "../view/container/marketSetting/children/Market";
import StyleSetting from "../view/container/shopManage/children/StyleSetting";
import ShopPaintingV2 from "../view/container/shopPainting/ShopPaintingV2";
import IntegralManage from "../view/container/integralManage/IntegralManage";
import GoodsListPage from "../view/container/goods/GoodsListPage";
import GoodsListPageDetail from "../view/container/goods/children/GoodsListPageDetail";
import CouponManage from "../view/container/couponManage/CouponManage";
import Coupon from "../view/container/couponManage/children/coupon";
import MarketTab from "../view/container/marketSetting/MarketTab";
import AssetsManage from "../view/container/assetsManage/AssetsManage";
import IntegralTab from "../view/container/integralManage/IntegralTab";
import PopUpPromotion from "../view/container/marketSetting/children/PopUpPromotion";
import CreateOrUpdatePopUp from "../view/container/marketSetting/children/CreateOrUpdatePopUp";
import PromoteManage from "../view/container/promote/PromoteManage";
import PromoteChanel from "../view/container/promote/PromoteChannel";
import PromoteMonitor from "../view/container/promote/children/PromoteMonitor";
import DataStatistics from "../view/container/promote/children/DataStatistics";
import LiveList from "../view/container/marketSetting/children/LiveList";
import ClientManage from "../view/container/client/ClientManage";
import CardManage from "../view/container/client/CardManage";
import Client from "../view/container/client/children/Client";
import CreateOrUpdateCard from "../view/container/client/children/CreateOrUpdateCard";
import Integral from "../view/container/integralManage/children/Integral";
import FullReduction from "../view/container/marketSetting/children/FullReduction";
import FissionCoupon from '../view/container/fissionCoupon/FissionCoupon';
import FissionCouponSetting from '../view/container/fissionCoupon/children/FissionCouponSetting';
import ModifyAppletInfo from '../view/container/modifyAppletInfo/ModifyAppletInfo';
import BasicInfo from '../view/container/basicInfo/BasicInfo';
import ImageUpload from '../view/container/imageUpload/imageUpload';
import GroupActivitys from '../view/container/groupActivitys/GroupActivitys';
import InvoiceManage from '../view/container/invoiceManage/invoiceManage';
import GradeManage from '../view/container/client/GradeManage';
import EditLevel from '../view/container/client/EditLevel';
import CyclePurchase from '../view/container/CyclePurchase/CyclePurchase';
import CycleSetting from '../view/container/CyclePurchase/children/CycleSetting';
import RefundDetail from '../view/container/order/children/RefundDetail';
import RefundOrderDetail from '../view/container/order/children/RefundOrderDetail';
import SceneMarketing from '../view/container/sceneMarketing/SceneMarketing';
import SceneMarketingDetail from '../view/container/sceneMarketing/SceneMarketingDetail';
import VipMarketing from "../view/container/vipMarketing/index";

const BasicRoute = () => (
  <BrowserRouter>
    <CacheSwitch>
      <Route exact path="/" component={Login} />
      <Route exact path="/official" component={Official} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/imageUpload" component={ImageUpload} />
      <Route
        path="/"
        render={() => (
          <Main>
            <Route exact path="/dashboard" component={UserDashboard} />
            <Route exact path="/shippingTemplete" component={ShippingTemplete} />
            <Route exact path="/home" component={Home} />
            <CacheRoute className="cache-content" path="/codeManage" component={CodeManage} />
            <Route path="/codeDetail" component={CodeDetail} />
            <Route path="/goodsManage" component={GoodsManage} />
            <CacheRoute className="cache-content" path="/balanceManage" component={BalanceManage} />
            <Route path="/goodsAdd" component={GoodsAdd} />
            <Route path="/goodsDetail" component={GoodsDetail} />
            <Route path="/goodsGroupDetail" component={GoodsGroupDetail} />
            <CacheRoute className="cache-content" path="/groupManage" component={GroupManage} />
            <Route path="/groupAdd" component={GroupAdd} />
            <Route path="/groupDetail" component={GroupDetail} />
            <CacheRoute className="cache-content" path="/orderManage" component={OrderManage} />
            <Route path="/orderDetail" component={OrderDetail} />
            <CacheRoute className="cache-content" path="/orderShipmentHistory" component={OrderShipmentHistory} />
            <CacheRoute className="cache-content" path="/groupOrderShipmentHistory" component={GroupOrderShipmentHistory} />
            <CacheRoute className="cache-content" path="/orderShipment" component={OrderShipment} />
            <Route path="/batchShipment" component={BatchShipment} />
            <Route path="/template" component={Template} />
            <CacheRoute className="cache-content" path="/afterSaleManage" component={AfterSaleManage} />
            <Route path="/userManage" component={UserManage} />
            <Route path="/appletManage" component={AppletManage} />
            <Route path="/merchantManage" component={MerchantManage} />
            <Route path="/merchantAdd" component={MerchantAdd} />
            <CacheRoute className="cache-content" path="/adminMerchantManage" component={AdminMerchantManage} />
            <Route path="/appletApplyManage" component={AppletApplyManage} />
            <Route path="/bonusRecharge" component={BonusRecharge} />
            <Route path="/bonusSettle" component={BonusSettle} />
            <Route path="/driverManage" component={DriverManage} />
            <Route path="/rechargeHistory" component={BonusStream} />
            <Route path="/withdrawHistory" component={BonusStream} />
            <CacheRoute className="cache-content" path="/leaderManage" component={LeaderManage} />
            <Route path="/leaderInterview" component={LeaderInterview} />
            <Route path="/leaderBonus" component={LeaderBonus} />
            <Route exact path="/modifyPassword" component={ModifyPassword} />
            <Route exact path="/appOrder" component={AppOrder} />
            <Route exact path="/activitySetting" component={ActivitySetting} />
            <Route exact path="/activity" component={Activity} />
            <Route exact path="/activityTypeSetting" component={ActivityTypeSetting} />
            <Route exact path="/groupGoodsManage" component={GroupGoodsManage} />
            <Route exact path="/pageManage" component={PageManage} />
            <Route exact path="/campainSetting" component={CampainSetting} />
            <Route exact path="/userPageManage" component={UserPageManage} />
            <Route exact path="/shippingManage" component={ShippingManage} />
            <Route exact path="/marketSetting" component={MarketSetting} />
            <Route exact path="/market" component={Market} />
            <Route exact path="/styleSetting" component={StyleSetting} />
            <Route exact path="/shopPainting" component={ShopPaintingV2} />
            <Route exact path="/integralManage" component={IntegralManage} />
            <Route exact path="/goodsListPage" component={GoodsListPage} />
            <Route exact path="/goodsListPageDetail" component={GoodsListPageDetail} />
            <Route exact path="/couponManage" component={CouponManage} />
            <Route exact path="/coupon" component={Coupon} />
            <Route exact path="/marketTab" component={MarketTab} />
            <Route exact path="/redBagManage" component={IntegralManage} />
            <Route exact path="/commissionManage" component={IntegralManage} />
            <Route exact path="/assetsManage" component={AssetsManage} />
            <Route exact path="/cashManage" component={IntegralTab} />
            <Route exact path="/popUpPromotion" component={PopUpPromotion} />
            <Route exact path="/createOrUpdatePopUp" component={CreateOrUpdatePopUp} />
            <Route exact path="/promoteManage" component={PromoteManage} />
            <Route exact path="/promoteChanel" component={PromoteChanel} />
            <Route exact path="/promoteMonitor" component={PromoteMonitor} />
            <Route exact path="/dataStatistics" component={DataStatistics} />
            <Route exact path="/liveList" component={LiveList} />
            <Route exact path="/clientManage" component={ClientManage} />
            <Route exact path="/createOrUpdateCard" component={CreateOrUpdateCard} />
            <Route exact path="/cardManage" component={CardManage} />
            <Route exact path="/client" component={Client} />
            <Route exact path="/integral" component={Integral} />
            <Route exact path="/fullReduction" component={FullReduction} />
            <Route exact path="/fissionCoupon" component={FissionCoupon} />
            <Route exact path="/fissionCouponSetting" component={FissionCouponSetting} />
            <Route exact path="/modifyAppletInfo" component={ModifyAppletInfo} />
            <Route exact path="/basicInfo" component={BasicInfo} />
            <Route exact path="/groupActivitys" component={GroupActivitys} />
            <Route exact path="/invoiceManage" component={InvoiceManage} />
            <Route exact path="/gradeManage" component={GradeManage} />
            <Route exact path="/editLevel" component={EditLevel} />
            <Route exact path="/cyclePurchase" component={CyclePurchase} />
            <Route exact path="/cycleSetting" component={CycleSetting} />
            <Route exact path="/refundDetail" component={RefundDetail} />
            <Route exact path="/refundOrderDetail" component={RefundOrderDetail} />
            <Route exact path="/sceneMarketing" component={SceneMarketing} />
            <Route exact path="/sceneMarketingDetail" component={SceneMarketingDetail} />
            <Route exact path="/vipMarketing" component={VipMarketing} />
          </Main>
        )}
      />
    </CacheSwitch>
  </BrowserRouter>
);

export default BasicRoute;
