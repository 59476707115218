import React from "react";
import NavBar from "@/view/component/common/NavBar.js";
import { Form, Select, Input, DatePicker, Button, Divider, message, Tooltip, Radio } from "antd";
import { connect } from "react-redux";
import { createOrUpdateCoupon, queryCouponDetail } from "@service/getData";
import "@css/coupon.scss";
import cookie from "react-cookies";
import { formatNumber, formatInt, getQueryString } from "@common/common";
import PropTypes from "prop-types";
import moment from "moment";
import GoodsChoose from "@/view/component/Goods/GoodsChoose";
import GoodsDragSort from "@/view/component/GoodsDragSort/index";
class Coupon extends React.Component {
  constructor() {
    Coupon.contextTypes = {
      router: PropTypes.object.isRequired
    };
    super();
    this.state = {
      start_time: null,
      end_time: null,
      use_min_amount: null,
      discount_amount: null,
      coupon_id: null,
      total_num: null,
      coupon: {},
      already_get: 0,
      receive_limit_num: null,
      effect_day: null,
      visible: false,
      coupon_goods_req_list: [],
      receive_limit_flag: null,
      use_time: "0",
      showInDetail: 1,
    };
  }
  createOrUpdateCoupon = async data => {
    let res = await createOrUpdateCoupon(data);
    let coupon_id = null;
    if (res.success) {
      if (!data.coupon_req.coupon_id) {
        message.success("成功创建优惠券");
      } else {
        message.success("成功编辑优惠券");
      }
      coupon_id = res.result.coupon_id;
      this.clear(coupon_id);
    }
  };
  queryCouponDetail = async () => {
    let data = {
      coupon_id: this.state.coupon_id,
      mini_app_id: cookie.load("mini_app_id")
    };
    let res = await queryCouponDetail(data);
    if (res.success) {
      this.props.form.setFieldsValue({ coupon_category: res.result.coupon_res.coupon_category });
      this.props.form.setFieldsValue({ use_time: res.result.coupon_res.effect_day ? "1" : "0" });
      this.props.form.setFieldsValue({ coupon_name: res.result.coupon_res.coupon_name });
      this.props.form.setFieldsValue({ total_num: res.result.coupon_res.total_num });
      this.props.form.setFieldsValue({ receive_limit_flag: res.result.coupon_res.receive_limit_flag.toString() });
      this.props.form.setFieldsValue({ new_user_receive: res.result.coupon_res.new_user_receive.toString() });
      this.props.form.setFieldsValue({ transfer_title: res.result.coupon_res.transfer_title });
      let list = res.result.coupon_goods_res_list.map(item => {
        return {
          coupon_goods_id: item.coupon_goods_id,
          goods_id: item.goods_id,
          ...item.group_res.goods_res
        };
      });
      this.setState({
        use_min_amount: res.result.coupon_res.use_min_amount / 100,
        discount_amount: res.result.coupon_res.discount_amount / 100,
        start_time: res.result.coupon_res.start_time,
        end_time: res.result.coupon_res.end_time,
        total_num: res.result.coupon_res.total_num,
        already_get: res.result.coupon_res.total_num - res.result.coupon_res.remain_num,
        effect_day: res.result.coupon_res.effect_day,
        receive_limit_num: res.result.coupon_res.receive_limit_flag === 1 ? res.result.coupon_res.receive_limit_num : null,
        coupon_goods_req_list: list,
        use_time: res.result.coupon_res.effect_day ? "1" : "0",
        receive_limit_flag: res.result.coupon_res.receive_limit_flag.toString(),
        showInDetail: res.result.coupon_res.show_in_detail,
      });
    }
  };
  onShowTimeChange = (date, dateStr) => {
    this.setState({
      start_time: dateStr[0],
      end_time: dateStr[1]
    });
  };
  onUseMinAmountChange = e => {
    this.setState({
      use_min_amount: formatNumber(e.target.value)
    });
  };
  onDisCountAmountChange = e => {
    this.setState({
      discount_amount: formatNumber(e.target.value)
    });
  };
  onTotalNumChange = e => {
    let value = formatInt(e.target.value);
    this.props.form.setFieldsValue({ total_num: value === "" ? null : value });
    this.setState({
      total_num: value === "" ? null : value
    });
  };
  createCoupon = () => {
    if (!this.state.discount_amount) {
      this.props.form.setFieldsValue({ use_condition: null });
    } else {
      this.props.form.setFieldsValue({ use_condition: true });
    }
    if (this.state.coupon_goods_req_list.filter(item => item.status === 1).length > 0) {
      this.props.form.setFieldsValue({ "choose-goods": true });
    } else {
      this.props.form.setFieldsValue({ "choose-goods": null });
    }
    if (this.state.receive_limit_flag === "1" && !this.state.receive_limit_num) {
      this.props.form.setFieldsValue({ receive_limit_flag: null });
    } else {
      this.props.form.setFieldsValue({ receive_limit_flag: true });
    }
    if (!this.state.effect_day && !this.state.start_time) {
      this.props.form.setFieldsValue({ use_time: null });
    } else {
      this.props.form.setFieldsValue({ use_time: true });
    }
    let form = this.props.form.getFieldsValue();
    let flag = false;
    this.props.form.validateFields((err, values) => {
      if (err) {
        flag = true;
      }
    });
    if (flag || this.state.already_get > this.state.total_num) {
      return;
    }
    let data = {
      coupon_req: {
        transfer_title: form.transfer_title,
        coupon_id: this.state.coupon_id,
        mini_app_id: cookie.load("mini_app_id"),
        coupon_name: form.coupon_name,
        coupon_category: form.coupon_category,
        coupon_type: "full_reduce",
        use_min_amount: Math.floor(this.state.use_min_amount * 100),
        discount_amount: Math.floor(this.state.discount_amount * 100),
        start_time: this.state.use_time.toString() === "0" ? this.state.start_time : null,
        end_time: this.state.use_time.toString() === "0" ? this.state.end_time : null,
        total_num: form.total_num,
        new_user_receive: form.new_user_receive,
        receive_limit_flag: this.state.receive_limit_flag,
        coupon_goods_req_list: form.coupon_category === "goods_coupon" ? this.state.coupon_goods_req_list : null,
        effect_day: this.state.use_time.toString() === "1" ? this.state.effect_day : null,
        status: 1,
        receive_limit_num: this.state.receive_limit_flag === "1" ? this.state.receive_limit_num : null,
        show_in_detail: this.state.showInDetail,
      }
    };
    this.createOrUpdateCoupon(data);
  };
  clear = coupon_id => {
    this.props.form.resetFields();
    this.setState({
      start_time: null,
      end_time: null,
      use_min_amount: null,
      discount_amount: null,
      total_num: null
    });
    this.context.router.history.push({ pathname: "/couponManage", coupon_id });
  };
  changeCouponType = type => {
    this.props.form.setFieldsValue({ coupon_category: type });
  };
  changeEffectDay = e => {
    this.setState({
      effect_day: formatInt(e.target.value)
    });
  };
  changeReceive = e => {
    this.setState({
      receive_limit_num: formatInt(e.target.value)
    });
  };
  handleOk = list => {
    let now_list = [].concat(this.state.coupon_goods_req_list);
    let add_list = [];
    list.forEach(item => {
      let index = now_list.findIndex(goods => goods.goods_id === item.goods_id);
      let current = now_list.find(goods => goods.goods_id === item.goods_id);
      if (index < 0) {
        add_list.push(item);
      } else {
        current.status = 1;
      }
    });
    add_list = now_list.concat(add_list);
    this.setState({
      coupon_goods_req_list: add_list,
      visible: false
    });
  };
  handleCancel = () => {
    this.setState({
      visible: false
    });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  getTitle = () => {
    return <div className="table-title">已选商品</div>;
  };
  changeLimit = e => {
    this.setState({
      receive_limit_flag: e.target.value
    });
  };
  changeUseTime = e => {
    this.setState({
      use_time: e.target.value
    });
  };
  deleteGoods = index => {
    let list = JSON.parse(JSON.stringify(this.state.coupon_goods_req_list));
    let item = list[index];
    if (item.coupon_goods_id) {
      item.status = 0;
    } else {
      list = list.splice(index, 1);
    }
    this.setState({
      coupon_goods_req_list: list
    });
  };
  changeSortGoodsList = async (list, toIndex, fromIndex) => {
    this.setState({
      coupon_goods_req_list: list
    });
  };
  onShowInDetailChange(e) {
    this.setState({
      showInDetail: e.target.value,
    });
  }
  componentDidMount() {
    let coupon_id = getQueryString("coupon_id");
    if (coupon_id) {
      this.setState(
        {
          coupon_id
        },
        () => {
          this.queryCouponDetail();
        }
      );
    }
  }
  render() {
    let { menu_code } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="page coupon">
        <NavBar
          icon="icon-youhuiquan"
          father={menu_code.coupon_manage}
          child={this.state.coupon_id ? "编辑优惠券" : "新建优惠券"}
          router="couponManage"
        />
        <div className="content">
          <div className="shopDataTitle">
            <i className="titleSign" />
            优惠券信息
          </div>
          <Divider />
          <Form className="form">
            <Form.Item
              label="优惠券类型"
              extra={
                this.props.form.getFieldValue("coupon_category") === "shop_coupon"
                  ? "本券适用于全店所有商品，领取优惠券后，消费金额达到使用条件即可使用本券并减免."
                  : "本券适用于部分商品，领取优惠券后，消费金额达到使用条件即可使用本券并减免，且不与通用券叠加"
              }>
              {getFieldDecorator("coupon_category", {
                initialValue: "shop_coupon",
                rules: [
                  {
                    required: true,
                    message: "请输入优惠券类型!"
                  }
                ]
              })(
                <div>
                  <Select
                    disabled={this.state.coupon_id ? true : false}
                    value={this.props.form.getFieldValue("coupon_category")}
                    className="form-long-content"
                    onChange={this.changeCouponType}>
                    <Select.Option value="shop_coupon">全店通用券</Select.Option>
                    <Select.Option value="goods_coupon">商品优惠券</Select.Option>
                  </Select>
                </div>
              )}
            </Form.Item>
            <Form.Item label="优惠券名称" extra={"15个字以内"}>
              {getFieldDecorator("coupon_name", {
                initialValue: null,
                rules: [
                  {
                    required: true,
                    message: <span className="text-danger">请输入优惠券名称</span>
                  }
                ]
              })(<Input className="form-long-content" maxLength={15} />)}
            </Form.Item>
            {this.props.form.getFieldValue("coupon_category") === "goods_coupon" ? (
              <Form.Item label="适用范围">
                {getFieldDecorator("choose-goods", {
                  initialValue: null,
                  rules: [
                    {
                      required: true,
                      message: <span className="text-danger">请选择适用范围</span>
                    }
                  ]
                })(
                  <div>
                    <span className="text-remind">指定商品可用</span>
                    <Button type="primary" shape="round" onClick={this.showModal}>
                      选择商品
                    </Button>
                    <div className="goods-image-list">
                      <GoodsDragSort
                        list={this.state.coupon_goods_req_list.filter(item => item.status !== 0)}
                        handlePreview={() => { }}
                        editable={true}
                        delete={this.deleteGoods}
                        changeList={this.changeSortGoodsList}
                      />
                    </div>
                  </div>
                )}
              </Form.Item>
            ) : null}
            <Form.Item
              label="发放数量"
              extra={
                <span className={this.state.total_num ? "text-danger" : "text-remind"}>
                  {this.state.total_num < this.state.already_get
                    ? `发放的数量不可小于当前领取数量，当前领取数量为${this.state.already_get}`
                    : ""}
                </span>
              }>
              {getFieldDecorator("total_num", {
                initialValue: null,
                rules: [
                  {
                    required: true,
                    message: <span className="text-danger">请输入发放数量</span>
                  }
                ]
              })(
                <div>
                  <Input value={this.state.total_num} className="form-long-content" onChange={this.onTotalNumChange} suffix="张" />
                </div>
              )}
            </Form.Item>
            <Form.Item label="使用条件" className="use-condition">
              {getFieldDecorator("use_condition", {
                rules: [
                  {
                    required: true,
                    message: <span className="text-danger">请输入使用条件, 减免金额不得为0</span>
                  }
                ]
              })(
                <div>
                  <span className="text-use">消费金额满</span>
                  <Input
                    disabled={this.state.coupon_id ? true : false}
                    value={this.state.use_min_amount}
                    className="input-mid"
                    onChange={this.onUseMinAmountChange}
                    suffix="元"
                  />
                  <br />
                  <span className="text-use">减免金额</span>
                  <Input
                    disabled={this.state.coupon_id ? true : false}
                    value={this.state.discount_amount}
                    className="input-mid"
                    onChange={this.onDisCountAmountChange}
                    suffix="元"
                  />
                </div>
              )}
            </Form.Item>
            <Form.Item label="使用时间">
              {getFieldDecorator("use_time", {
                initialValue: "0",
                rules: [
                  {
                    required: true,
                    message: <span className="text-danger">请输入使用时间</span>
                  }
                ]
              })(
                <div>
                  <Radio.Group disabled={this.state.coupon_id ? true : false} value={this.state.use_time} onChange={this.changeUseTime}>
                    <Radio value="0">
                      <DatePicker.RangePicker
                        value={
                          this.state.start_time
                            ? [moment(this.state.start_time, "YYYY-MM-DD"), moment(this.state.end_time, "YYYY-MM-DD")]
                            : null
                        }
                        disabled={this.state.coupon_id ? true : false}
                        className="form-long-content"
                        format="YYYY-MM-DD"
                        onChange={this.onShowTimeChange}
                      />
                    </Radio>
                    <Radio value="1">
                      按领取之日起
                      <Input
                        disabled={this.state.coupon_id ? true : false || this.props.form.getFieldValue("use_time") === "0"}
                        className="input-short"
                        suffix="天"
                        value={this.state.effect_day}
                        onChange={this.changeEffectDay}
                      />
                      内可用
                      <Tooltip
                        placement="bottom"
                        title="有效期按自然天计算。如设置按领券之日起3天内可用，用户在11月11日11:11:11时领取优惠券，则该优惠券的可用时间为11月11日的11:11:11至11月13日的23:59:59。">
                        <i className="iconfont icon-bangzhu" />
                      </Tooltip>
                    </Radio>
                  </Radio.Group>
                </div>
              )}
            </Form.Item>
          </Form>
          <div className="shopDataTitle">
            <i className="titleSign" />
            领取和使用规则
          </div>
          <Divider />
          <Form className="form">
            <Form.Item label="领取人限制">
              {getFieldDecorator("new_user_receive", {
                rules: [
                  {
                    required: true,
                    message: <span className="text-danger">请选择领取人限制</span>
                  }
                ]
              })(
                <Radio.Group>
                  <Radio value="0">不限制，所有人可用</Radio>
                  <Radio value="1">
                    仅限新用户可用
                    <Tooltip placement="bottom" title="新用户定义为未支付过订单的用户">
                      <i className="iconfont icon-bangzhu" />
                    </Tooltip>
                  </Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item label="每人限领次数">
              {getFieldDecorator("receive_limit_flag", {
                rules: [
                  {
                    required: true,
                    message: <span className="text-danger">请选择每人限领次数</span>
                  }
                ]
              })(
                <div>
                  <Radio.Group value={this.state.receive_limit_flag} onChange={this.changeLimit}>
                    <Radio value="0">
                      不限次数
                      <Tooltip placement="bottom" title="一张券最多可领取500次">
                        <i className="iconfont icon-bangzhu" />
                      </Tooltip>
                    </Radio>
                    <Radio value="1">
                      <Input
                        className="input-short"
                        disabled={this.state.receive_limit_flag === "0"}
                        value={this.state.receive_limit_num}
                        onChange={this.changeReceive}
                        suffix="次"
                      />
                    </Radio>
                  </Radio.Group>
                </div>
              )}
            </Form.Item>
          </Form>
          <div className="shopDataTitle">
            <i className="titleSign" />
            展示设置
          </div>
          <Divider />
          <Form className="form">
            <Form.Item label="是否在商品详情页展示">
              <Radio.Group onChange={this.onShowInDetailChange.bind(this)} value={this.state.showInDetail}>
                <Radio value={1}>是</Radio>
                <Radio value={0}>否</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
          <div className="shopDataTitle">
            <i className="titleSign" />
            分享文案
          </div>
          <Divider />
          <Form className="form">
            <Form.Item label="分享文案">{getFieldDecorator("transfer_title", {})(<Input.TextArea className="remarks" />)}</Form.Item>
          </Form>
          <footer>
            <Button type="primary" shape="round" onClick={this.createCoupon}>
              保存
            </Button>
            <Button shape="round" onClick={this.clear.bind(this, null)}>
              取消
            </Button>
          </footer>
        </div>
        <GoodsChoose visible={this.state.visible} handleOk={this.handleOk} handleCancel={this.handleCancel} />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    menu_code: state.menuCode
  };
};
const CouponForm = Form.create({ name: "coupon_form" })(Coupon);
export default connect(mapStateToProps)(CouponForm);
