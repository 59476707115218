import { Spin, Popover, Button, Table, Pagination, Divider, message, Modal, Popconfirm } from 'antd';
import React, { Component } from 'react';
import NavBar from "@/view/component/common/NavBar.js";
import './cyclePurchase.scss';
import PropTypes from "prop-types";
import { getData, getCode, getBase64ImageUrl } from '@service/getData';
import cookie from 'react-cookies';
import Poster from '@/view/component/Poster/Poster';
import html2canvas from 'html2canvas';
import GoodsPoster from './children/GoodsPoster';
import GoodsButton from './children/GoodsButton';

class CyclePurchase extends Component {
    constructor() {
        super();
        CyclePurchase.contextTypes = {
            router: PropTypes.object.isRequired,
        };
        this.state = {
            loading: true,
            columns: [
                {
                    title: 'id',
                    dataIndex: 'period_purchase_id',
                },
                {
                    title: '活动名称',
                    dataIndex: 'name',
                },
                {
                    title: '配送周期',
                    dataIndex: 'c',
                    render: () => (
                        <span>一周一期</span>
                    )
                },
                {
                    title: '创建时间',
                    dataIndex: 'created_at',
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    render: (text) => {
                        return text === 0 ? (
                            <span style={{ color: 'green' }}>进行中</span>
                        ) : (
                                <span style={{ color: 'red' }}>已停止</span>
                            )
                    }
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    render: (text, row, index) => {
                        const id = row.period_purchase_id;
                        return row.status === 1 ? (
                            <Popconfirm
                                title="确定删除?"
                                onConfirm={this.stopOrDelete.bind(this, id, -1)}
                                okText="确认"
                                cancelText="取消"
                            >
                                <span className="text-note">删除</span>
                            </Popconfirm>
                        ) : (
                                <div>
                                    <span onClick={this.goSetting.bind(this, id)} className="text-note">编辑</span>
                                    <Divider type="vertical" />
                                    <span onClick={this.showConfirm.bind(this, id)} className="text-note">停止</span>
                                    <Divider type="vertical" />
                                    <span onClick={this.showPromoteModal.bind(this, row.goods_res, id)} className="text-note">推广</span>
                                </div>
                            )
                    }
                },
            ],
            data: [],
            promoteVisible: false,
            // 商品信息
            posterInfo: {},
            url: '',
            posterImg: '',
            // 分页
            page: 1,
            page_size: 10,
            total: 0,
            period_purchase_id: null,
            confirmModal: false,
        };
    }

    componentDidMount() {
        this.search();
    }

    async search() {
        const url = '/period_purchase/list';
        const param = {
            mini_app_id: cookie.load('mini_app_id'),
            page_size: this.state.page_size,
            page_no: this.state.page,
        };
        let res = await getData(url, param);
        if (res.success && res.result) {
            const result = res.result;
            this.setState({
                page: result.page_no,
                page_size: result.page_size,
                total: result.total,
                data: result.period_purchase_res_list,
                loading: false,
            });
        }
    }

    goSetting(id) {
        this.context.router.history.push(`/cycleSetting${id ? `?period_purchase_id=${id}` : ''}`);
    }

    async stopOrDelete(id, status) {
        const url = '/period_purchase/stopOrDelete';
        const data = {
            period_purchase_id: id,
            status,
        };
        let res = await getData(url, data);
        if (res.success) {
            const msg = status === 1 ? '已停止该周期购活动' : '已删除该周期购活动';
            message.success(msg, 1, () => {
                this.setState({
                    confirmModal: false,
                });
                this.search();
            });
        }
    }


    showPromoteModal(record, period_purchase_id) {
        this.setState({
            promoteVisible: true,
            posterInfo: record,
            period_purchase_id,
        }, () => {
            this.getCode();
            this.getBase64ImageUrl(record);
        });
    }

    async getCode() {
        const data = {
            shop_id: cookie.load('shop_id'),
            period_purchase_id: this.state.period_purchase_id,
            page: 'pages/shareGoods/index',
        }
        let res = await getCode(data);
        this.setState({
            url: res,
        });
    }

    async getBase64ImageUrl(record) {
        let res = await getBase64ImageUrl(JSON.parse(record.gallery_urls)[0]);
        this.setState({
            posterImg: res,
        });
    }

    showConfirm(id) {
        this.setState({
            confirmModal: true,
            period_purchase_id: id,
        });
    }

    onShowPageChange = (page) => {
        this.setState({
            page,
        }, () => {
            this.search();
        });
    }


    onShowSizeChange = (current, pageSize) => {
        this.setState({
            page_size: pageSize
        }, () => {
            this.search();
        })
    }

    postCancel() {
        this.setState({
            promoteVisible: false,
        });
    }

    downLoadPost = () => {
        window.pageYOffset = 0;
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        html2canvas(document.querySelector('.goodsPosterWrap'), {
            useCORS: true, // 【重要】开启跨域配置
            taintTest: false,//是否在渲染前测试图片,
            dpi: 192,
            scale: 8,
        }).then(function (canvas) {
            var context = canvas.getContext('2d');
            context.mozImageSmoothingEnabled = false;
            context.webkitImageSmoothingEnabled = false;
            context.msImageSmoothingEnabled = false;
            context.imageSmoothingEnabled = false;
            var imgUri = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream"); // 获取生成的图片的url
            var a = document.createElement("a");
            a.href = imgUri;
            a.download = 'qrcode.png';
            a.click();
        })
    }

    render() {
        return (
            <div className="cyclePurchase">
                <Spin spinning={this.state.loading}>
                    <NavBar
                        icon="icon-yingxiao"
                        father='营销活动'
                        router='marketSetting'
                        child='周期购'
                    />
                    <div className="content">
                        <div className="introduction">
                            <div className="iconWrap">
                                <i className="iconfont icon-zhouqigou"></i>
                            </div>
                            <div className="incRight">
                                <p className="rightTop">
                                    <span className="shareCoupon">周期购</span>
                                    <Popover content={
                                        <img src="http://udh.oss-cn-hangzhou.aliyuncs.com/23738c3c-f884-4291-aaad-1ba221b975b51604900127829.png" width="250" height="540" />
                                    }>
                                        <span className="shili">示例图片</span>
                                    </Popover>
                                </p>
                                <p className="rightBot">客户一次下单，商家周期配送商品</p>
                            </div>
                        </div>
                        <Button className="newBtn" shape="round" onClick={this.goSetting.bind(this, null)} type="primary">新建周期购</Button>
                        <Table
                            rowKey={(record, index) => index}
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            bordered
                            pagination={false}
                        />
                        <Pagination
                            className="pagenation"
                            onChange={this.onShowPageChange}
                            onShowSizeChange={this.onShowSizeChange}
                            defaultCurrent={this.state.page}
                            total={this.state.total}
                            showTotal={(total) => `共 ${total} 条`}
                            pageSizeOptions={["10", "30", "50", "100"]}
                            showSizeChanger
                        />
                    </div>
                </Spin>
                <Modal
                    className="coupon-post"
                    visible={this.state.promoteVisible}
                    footer={null}
                    onCancel={this.postCancel.bind(this)}
                    destroyOnClose={true}
                    okButtonProps={{ shape: 'round' }}
                    cancelButtonProps={{ shape: 'round' }}
                >
                    <Poster
                        goods_id={this.state.posterInfo.goods_id}
                        left={
                            <GoodsPoster
                                posterImg={this.state.posterImg}
                                title={this.state.posterInfo.goods_name}
                                price={this.state.posterInfo.current_price}
                                originalPrice={this.state.posterInfo.original_price}
                                url={this.state.url}
                            />
                        }
                        right={
                            <GoodsButton
                                period_purchase_id={this.state.period_purchase_id}
                                url={this.state.url}
                                downLoadPost={this.downLoadPost.bind(this)}
                            />
                        }
                    />
                </Modal>
                <Modal
                    visible={this.state.confirmModal}
                    title="停止周期购提示"
                    okButtonProps={{ shape: 'round' }}
                    cancelButtonProps={{ shape: 'round' }}
                    onOk={this.stopOrDelete.bind(this, this.state.period_purchase_id, 1)}
                    onCancel={() => this.setState({ confirmModal: false })}
                    okText="确认"
                    cancelText="取消"
                >
                    <span>停止后，客户将无法购买周期购商品，已购买周期购商品的订单将继续发货，确认停止？</span>
                </Modal>
            </div>
        );
    }
}

export default CyclePurchase;