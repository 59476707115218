import React from 'react'
import { Input } from 'antd'
import '@css/capcha.scss'

class Captcha extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            expression: null,
            capcha: null,
            status: null
        }
    }
    componentDidMount() {
        this.renderCode();
    }
    UNSAFE_componentWillReceiveProps(e) {
        if (e.status !== undefined && this.state.status !== e.status) {
            this.renderCode()
            this.setState({
                capcha: null,
                status: e.status
            })
        }

    }
    renderCode = () => {
        let result = '';
        var codeNormal = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';//字母库
        for (var i = 0; i < 4; i++) {
            result = result + codeNormal[Math.round(Math.random() * (codeNormal.length - 1))];
        }//随机获取字母四个
        let expression = result;
        this.setState({
            expression: expression,
            capcha: null
        })

    }
    change = (e) => {
        this.setState({
            capcha: e.target.value.toLocaleString()
        }, () => {
            this.props.change(this.state.capcha, this.state.expression)
        })
    }
    confirm = e => {
        this.props.confirm && this.props.confirm(e);
    };
    render() {
        return (
            <div className="capcha">
                <Input value={this.state.capcha} style={{ width: "270px" }} onChange={this.change} onPressEnter={this.confirm} />
                <div>
                    <p className="capcha-pic" onClick={this.renderCode}>{this.state.expression}</p>
                    <p onClick={this.renderCode} className="text-note">看不清换一张</p>
                </div>
            </div>
        )
    }


}
export default Captcha