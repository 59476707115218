import React, { Component } from 'react';
import NavBar from "@/view/component/common/NavBar.js";
import './invoiceManage.scss';
import { Tabs } from 'antd';
import SearchAndTable from './searchAndTable';

class invoiceManage extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div className="invoiceManage">
                <NavBar
                    icon="icon-fapiaoguanli"
                    child='发票管理'
                />
                <div className="content">
                    <Tabs>
                        <Tabs.TabPane
                            tab="待开票"
                            key="1"
                        >
                            <SearchAndTable
                                type={1}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab="已开票"
                            key="2"
                        >
                            <SearchAndTable
                                type={2}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default invoiceManage;