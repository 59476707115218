import React from 'react'
import {Modal,Form,Input,message} from 'antd'
import {createOrUpdateChannel} from '@service/getData'
import cookie from 'react-cookies'
class ChannelModal extends React.Component{
    createOrUpdateChannel=async()=>{
        let form = this.props.form.getFieldsValue();
        let flag=false;
        this.props.form.validateFields(err => {
            if (err) {
                flag = true
            }
        });
        if (flag) return
        let data={
            channel_req:{
                channel_name:form.channel_name,
                mini_app_id:cookie.load("mini_app_id")
            }

        }
        let res=await createOrUpdateChannel(data);
        if(res.success){
            this.props.onOk()
        }else{
            message.error(res.msg)
        }

    }
    render(){
        const {getFieldDecorator} = this.props.form;
        return (
            <Modal
                visible={this.props.visible}
                onOk={this.createOrUpdateChannel}
                onCancel={this.props.onCancel}
                okButtonProps={{shape:'round'}}
                cancelButtonProps={{shape:'round'}}
            >
                <Form>
                    <Form.Item label="推广渠道名称">
                        {getFieldDecorator('channel_name',{
                            rules: [
                                {
                                    required: true,
                                    message: "请输入推广渠道名称！"
                                }
                            ],
                        })(
                            <Input className="goods-id" placeholder="输入推广渠道名称"/>
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

const ChanelModalForm = Form.create({name: 'chanel_modal_form'})(ChannelModal);

export default ChanelModalForm;
